import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { usePatchInvitation } from '@api/invitations';
import TextScreen from '@components/TextScreen';
import { renderErrorToast } from '@components/Toast';
import TwoColumnDecorativeComponent from '@components/TwoColumnDecorativeComponent';
import TwoColumnLayout from '@components/TwoColumnLayout';
import MetadataDecorator from '@utils/MetadataDecorator';

const RequestForInvitation: React.FC = () => {
  const location: { state: { inviteLink: string } } = useLocation();
  const inviteLink = location?.state?.inviteLink;
  const [showSuccessScreen, setShowSuccessScreen] = useState<boolean>(false);
  const { isLoading, mutate } = usePatchInvitation(inviteLink, {
    onError: () => {
      renderErrorToast('Error in sending invitation request');
    },
    onSuccess: () => {
      setShowSuccessScreen(true);
    },
  });

  const requestForInvite = () => {
    mutate(null);
  };

  let leftComponent = (
    <TextScreen
      buttonText="Request an invite to join"
      disabled={isLoading}
      onClick={requestForInvite}
      text="Looks like an organization for your company already exists"
      title="Please request for access"
    />
  );

  if (showSuccessScreen) {
    leftComponent = (
      <TextScreen
        buttonText="Back to Sign In"
        onClick={() => (window.location.href = '/login')}
        text="We have emailed organization admin that you have requested to join Select Star. You shall get an invitation email when they accept the request."
      />
    );
  }

  return (
    <>
      <MetadataDecorator title="Request for Invitation" />
      <TwoColumnLayout left={leftComponent} right={<TwoColumnDecorativeComponent />} />
    </>
  );
};

export default RequestForInvitation;
