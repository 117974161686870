import React from 'react';

import Box from '@components/Box';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

interface HeaderWithInfoProps {
  description: string;
  name: string;
}

const HeaderWithInfo = ({ description, name }: HeaderWithInfoProps) => {
  return (
    <Box alignItems="center" as="span" compDisplay="inline-flex" gap={0.25}>
      {name}
      <Tooltip content={description}>
        <Icon color={theme.colors.gray[500]} name="info-outline" size="16px" />
      </Tooltip>
    </Box>
  );
};

export default HeaderWithInfo;
