import { useLocation } from 'react-router-dom';

const ENABLED_BOOLEAN_PARAM_VALUE = '1';
const DEFAULT_MIN_LEVELS = 3;

export interface UseGetConfigQueryParams {
  enableBorderlineEdges: boolean;
  enableColumnLevelTraversal: boolean;
  enableHorizontalGroups: boolean;
  minLevels: number;
  shouldHideFilterLineage: boolean;
  shouldSidebarStartCollapsed: boolean;
}

export interface ExploreParamsData {
  defaultValue: boolean | number | string;
  key: string;
  options?: {
    minValue?: number;
    values?: string[];
  };
  type: 'boolean' | 'odd-number' | 'option';
}

export const EXPLORE_PARAMS_MAP: Record<string, ExploreParamsData> = {
  enableBorderlineEdges: { defaultValue: false, key: 'borderline_edges', type: 'boolean' },
  enableColumnLevelTraversal: { defaultValue: false, key: 'columns_transversal', type: 'boolean' },
  enableHorizontalGroups: { defaultValue: true, key: 'hgroups', type: 'boolean' },
  minLevels: {
    defaultValue: DEFAULT_MIN_LEVELS,
    key: 'min_levels',
    options: { minValue: 3 },
    type: 'odd-number',
  },
  shouldHideFilterLineage: { defaultValue: true, key: 'hide_filter', type: 'boolean' },
  shouldSidebarStartCollapsed: { defaultValue: false, key: 'sidebar_collapsed', type: 'boolean' },
};

const getClosestOddNumber = (number: number): number => {
  const closestOddNumber = number % 2 === 0 ? number + 1 : number;

  return Math.max(DEFAULT_MIN_LEVELS, closestOddNumber);
};

const getBooleanParamValue = (paramName: string, paramValue: string | null) => {
  if (!paramValue) {
    return Boolean(EXPLORE_PARAMS_MAP[paramName].defaultValue);
  }
  return paramValue === ENABLED_BOOLEAN_PARAM_VALUE;
};

const useGetConfigQueryParams = (): UseGetConfigQueryParams => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const enableHorizontalGroups = searchParams.get('hgroups');
  const enableColumnLevelTraversal = searchParams.get('columns_transversal');
  const enableBorderlineEdges = searchParams.get('borderline_edges');
  const shouldHideFilterLineage = searchParams.get('hide_filter');
  const shouldSidebarStartCollapsed = searchParams.get('sidebar_collapsed');
  const minLevels = searchParams.get('min_levels');

  return {
    enableBorderlineEdges: getBooleanParamValue('enableBorderlineEdges', enableBorderlineEdges),
    enableColumnLevelTraversal: getBooleanParamValue(
      'enableColumnLevelTraversal',
      enableColumnLevelTraversal,
    ),
    enableHorizontalGroups: getBooleanParamValue('enableHorizontalGroups', enableHorizontalGroups),
    minLevels: minLevels ? getClosestOddNumber(Number(minLevels)) : DEFAULT_MIN_LEVELS,
    shouldHideFilterLineage: getBooleanParamValue(
      'shouldHideFilterLineage',
      shouldHideFilterLineage,
    ),
    shouldSidebarStartCollapsed: getBooleanParamValue(
      'shouldSidebarStartCollapsed',
      shouldSidebarStartCollapsed,
    ),
  };
};

export default useGetConfigQueryParams;
