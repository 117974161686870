import React from 'react';

import { useFetchSlackTeam, useFetchSlackUser } from '@api/slack';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import CircularLoader from '@components/CircularLoader';
import Link from '@components/Link';
import Toggle from '@components/Toggle';
import Icon from '@components/UI/Icon';
import { useUserContext } from '@context/User';
import { ConnectableApps, useConnectableApps } from '@hooks/useConnectableApps';
import { SLACKAPP_INSTALL_URL } from '@pages/Admin/AdminSlackPage/AdminSlackPage';
import theme from '@styles/theme';

import { AppSettingsInputOptionTitle } from './AppSettingsInput/AppSettingsInput.styles';
import { StyledUserSettingsPageInputContainer } from './UserSettingsPage.styles';

interface SlackInputProps {
  checked: boolean;
  onChange: (newValue: boolean) => void;
}

const SlackInput = ({ checked, onChange }: SlackInputProps) => {
  const { user } = useUserContext();
  const { connectedApps, isLoading } = useConnectableApps();
  const hasSlackSubscriber = connectedApps?.includes(ConnectableApps.slack);

  const {
    data: slackTeam,
    isError: isSlackTeamError,
    isLoading: loadingSlackTeam,
  } = useFetchSlackTeam({
    retry: false,
  });

  const {
    data: slackUser,
    error,
    isLoading: loadingSlackUser,
  } = useFetchSlackUser(user!.guid, {
    retry: false,
  });

  const foundNotUser = error && error.status === 404;
  const loading = loadingSlackUser || isLoading || loadingSlackTeam;
  const showToggle = !loading && hasSlackSubscriber && slackUser;
  const shouldShowConnectButton = !loading && !hasSlackSubscriber;
  const shouldShowDocsLink = !loading && foundNotUser && isSlackTeamError;
  const shouldShowLinkButton = !loading && foundNotUser && hasSlackSubscriber && slackTeam;

  return (
    <StyledUserSettingsPageInputContainer>
      <Box alignItems="center" compDisplay="flex" gap={1}>
        <AppSettingsInputOptionTitle>Slack</AppSettingsInputOptionTitle>
        <Icon name="slack" size="16px" />
      </Box>
      {showToggle && <Toggle checked={checked} onChange={() => onChange(!checked)} />}
      <Box>
        {shouldShowConnectButton && (
          <Button
            endIcon={<Icon name="open-new-tab" />}
            href={SLACKAPP_INSTALL_URL}
            mr={1}
            target="_blank"
            variant="outlined"
          >
            Connect
          </Button>
        )}
        {shouldShowDocsLink && (
          <>
            Follow the{' '}
            <Link as="span" underlineOnHover>
              documentation
            </Link>{' '}
            to connect your user account.
          </>
        )}
        {shouldShowLinkButton && (
          <Button
            endIcon={<Icon name="open-new-tab" />}
            href={encodeURI(
              `slack://app?team=${slackTeam.slackTeamId}&id=${window.env.SLACK_APP_ID}`,
            )}
            variant="outlined"
          >
            Link User Account
          </Button>
        )}
        {loading && (
          <CircularLoader borderWidth={2} color={theme.colors.gray[500]} compSize={1.75} />
        )}
      </Box>
    </StyledUserSettingsPageInputContainer>
  );
};

export default SlackInput;
