import React, { useEffect } from 'react';

import { LineageInteractions } from '@components/Explore.v1/Explore.types';
import useColumnLevelLineage from '@components/Explore.v1/useColumnLevelLineage';
import { useExplore } from '@components/Explore.v1/useExplore';

import useLineageInteractions from './useLineageInteractions';

interface LineageInteractionsWrapperProps {
  children: React.ReactNode;
}

/**
 * We should use this wrapper to trigger interactions that depend on some new data being available.
 * This way we can ensure that the interaction is triggered after the data is up-to-date.
 */
const LineageInteractionsWrapper = ({ children }: LineageInteractionsWrapperProps) => {
  const { isColumnLevelLineage, nextUserInteraction, setNextUserInteraction, stackData } =
    useExplore();
  const { toggleColumnLevelLineage } = useColumnLevelLineage();
  const { fitLineageView, focusOnNode } = useLineageInteractions();

  useEffect(() => {
    const { payload, type } = nextUserInteraction?.current ?? {};
    if (stackData && type) {
      if (type === LineageInteractions.ToggleColumnLevelLineage && payload) {
        if (!isColumnLevelLineage) {
          toggleColumnLevelLineage(payload!.nodeKey as string, payload!.expansionTrigger);
          setNextUserInteraction({
            type: LineageInteractions.FitLineageView,
          });
        }
      }

      if (type === LineageInteractions.FitLineageView) {
        fitLineageView(payload?.duration);
        setNextUserInteraction(undefined);
      }

      if (type === LineageInteractions.FocusOnNode && payload) {
        focusOnNode(payload.nodeKey);
      }
    }
  }, [stackData]);

  return <>{children}</>;
};

export default LineageInteractionsWrapper;
