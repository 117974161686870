import { Expose, Transform } from 'class-transformer';
import moment from 'moment';

import { DSUserData, DsUserModel } from '@api/dsusers/DsUserModel';

export interface FrequentDSUserData extends DSUserData {
  lastQueried?: moment.Moment;
  queryCount?: number;
}

export class FrequentDSUserModel extends DsUserModel {
  @Expose({ name: 'last_queried' })
  @Transform((value) => (value ? moment(value) : value))
  lastQueried?: moment.Moment;

  @Expose({ name: 'query_count' })
  queryCount?: number;
}
