import React from 'react';
import { DEFAULT_DOCS_URL } from '@constants';

import { TextLink } from '@components/Text';
import Icon from '@components/UI/Icon';

import type { StyledAddDSHeaderProps } from './AddDSHeader.styles';
import { StyledAddDSHeader } from './AddDSHeader.styles';

interface AddDSHeaderProps extends StyledAddDSHeaderProps {}

const AddDSHeader: React.FC<AddDSHeaderProps> = (props) => {
  return (
    <StyledAddDSHeader {...props}>
      Add Data Source
      <TextLink href={`${DEFAULT_DOCS_URL}/integrations`} rel="noopener noreferrer" target="_blank">
        <Icon name="help" size="20px" />
      </TextLink>
    </StyledAddDSHeader>
  );
};
export default AddDSHeader;
