import React from 'react';
import * as Sentry from '@sentry/react';

import { DataSourcesMutatePayload } from '@api/dataSources';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import { DropzoneFormElement } from '@components/Dropzone';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import useDataSourceMutation from '../useDataSourceMutation';

import { DataSourceFormProps } from './types';

const BigQueryForm: React.FC<DataSourceFormProps> = ({
  children,
  dataSource,
  dataType,
  name = '',
  onSuccess,
  renderBefore,
}) => {
  const segment = useSegmentContext();
  const { error, isLoading, mutate } = useDataSourceMutation({ dataSource, onSuccess });

  const { handleChange, handleSubmit, setValues, values } = useForm({
    initialValues: {
      authProviderx509CertUrl: 'https://www.googleapis.com/oauth2/v1/certs',
      authUri: 'https://accounts.google.com/o/oauth2/auth',
      clientEmail: '',
      clientId: '',
      clientx509CertUrl: '',
      name: dataSource?.name || name,
      privateKey: '',
      privateKeyId: '',
      projectId: '',
      tokenUri: 'https://oauth2.googleapis.com/token',
      type: 'service_account',
    },
    onSubmit: (val) => {
      const payload = {} as DataSourcesMutatePayload;
      if (dataSource) {
        payload.guid = dataSource.guid;
        payload.type = dataSource.type;
      } else {
        payload.type = dataType;
      }
      payload.name = val.name;
      payload.credentials = {
        auth_provider_x509_cert_url: val.authProviderx509CertUrl,
        auth_uri: val.authUri,
        client_email: val.clientEmail,
        client_id: val.clientId,
        client_x509_cert_url: val.clientx509CertUrl,
        private_key: val.privateKey,
        private_key_id: val.privateKeyId,
        project_id: val.projectId,
        token_uri: val.tokenUri,
        type: val.type,
      };

      mutate(payload);

      segment?.track(SegmentTrackEventName.CreateServiceAccountConnectButtonClicked, { dataType });
    },
  });

  const handleOnFileAccept = (newServiceAcct?: string) => {
    if (newServiceAcct !== undefined) {
      try {
        const serviceAcctObj = JSON.parse(newServiceAcct);
        setValues((oldValues) => ({
          ...oldValues,
          authProviderx509CertUrl: serviceAcctObj.auth_provider_x509_cert_url,
          authUri: serviceAcctObj.auth_uri,
          clientEmail: serviceAcctObj.client_email,
          clientId: serviceAcctObj.client_id,
          clientx509CertUrl: serviceAcctObj.client_x509_cert_url,
          privateKey: serviceAcctObj.private_key,
          privateKeyId: serviceAcctObj.private_key_id,
          projectId: serviceAcctObj.project_id,
          tokenUri: serviceAcctObj.token_uri,
          type: serviceAcctObj.type,
        }));
      } catch (err) {
        Sentry.captureException(err);
      }
    }
  };

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        {renderBefore?.({ error, loading: isLoading })}
        <StyledLabel>
          Display Name
          <Input
            error={error?.data?.name}
            helperText={error?.data?.name}
            maxLength={50}
            name="name"
            onChange={handleChange}
            placeholder="BigQuery"
            type="text"
            value={values.name}
          />
        </StyledLabel>
        <StyledLabel as="div">
          Service Account
          <DropzoneFormElement
            isComplete={values.projectId !== ''}
            onFileAccept={handleOnFileAccept}
            text="Drag & Drop your Service Account API key in .json here"
          />
        </StyledLabel>
        <StyledLabel>
          Account Type
          <Input
            error={error?.data?.type}
            helperText={error?.data?.type}
            name="type"
            placeholder="service_account"
            readOnly
            type="text"
            value={values.type}
          />
        </StyledLabel>
        <StyledLabel>
          Project ID
          <Input
            error={error?.data?.project_id}
            helperText={error?.data?.project_id}
            name="projectId"
            placeholder="Project ID"
            readOnly
            type="text"
            value={values.projectId}
          />
        </StyledLabel>
        <StyledLabel>
          Private Key ID
          <Input
            error={error?.data?.private_key_id}
            helperText={error?.data?.private_key_id}
            name="privateKeyId"
            placeholder="Private Key ID"
            readOnly
            type="text"
            value={values.privateKeyId}
          />
        </StyledLabel>
        <StyledLabel>
          Private Key
          <Input
            error={error?.data?.private_key}
            helperText={error?.data?.private_key}
            name="privateKey"
            placeholder="Private Key"
            readOnly
            type="password"
            value={values.privateKey}
          />
        </StyledLabel>
        <StyledLabel>
          Client Email
          <Input
            error={error?.data?.client_email}
            helperText={error?.data?.client_email}
            name="clientEmail"
            placeholder="Client Email"
            readOnly
            type="text"
            value={values.clientEmail}
          />
        </StyledLabel>
        <StyledLabel>
          Client ID
          <Input
            error={error?.data?.client_id}
            helperText={error?.data?.client_id}
            name="clientId"
            placeholder="Client ID"
            readOnly
            type="text"
            value={values.clientId}
          />
        </StyledLabel>
        <StyledLabel>
          Auth URI
          <Input
            error={error?.data?.auth_uri}
            helperText={error?.data?.auth_uri}
            name="authUri"
            placeholder="Auth URI"
            readOnly
            type="text"
            value={values.authUri}
          />
        </StyledLabel>
        <StyledLabel>
          Token URI
          <Input
            error={error?.data?.token_uri}
            helperText={error?.data?.token_uri}
            name="tokenUri"
            placeholder="Token URI"
            readOnly
            type="text"
            value={values.tokenUri}
          />
        </StyledLabel>
        <StyledLabel>
          Auth Provider x509 Cert URL
          <Input
            error={error?.data?.auth_provider_x509_cert_url}
            helperText={error?.data?.auth_provider_x509_cert_url}
            name="authProviderx509CertUrl"
            placeholder="Auth Provider x509 Cert URL"
            readOnly
            type="text"
            value={values.authProviderx509CertUrl}
          />
        </StyledLabel>
        <StyledLabel>
          Client x509 Cert URL
          <Input
            error={error?.data?.client_x509_cert_url}
            helperText={error?.data?.client_x509_cert_url}
            name="clientx509CertUrl"
            placeholder="client Provider x509 Cert URL"
            readOnly
            type="password"
            value={values.clientx509CertUrl}
          />
        </StyledLabel>
      </StyledFormHorizontalLabelGrid>
      {children?.({ error, loading: isLoading })}
    </Form>
  );
};

export default BigQueryForm;
