import React from 'react';

import {
  customAttributesCacheKeys,
  usePatchCustomAttribute,
  usePostCustomAttribute,
} from '@api/customAttributes';
import {
  CustomAttributeDataSourceType,
  CustomAttributeModel,
} from '@api/customAttributes/CustomAttributeModel';
import { customAttributesWithValueCacheKeys } from '@api/customAttributesWithValue';
import { customAttributeValuesCacheKeys } from '@api/customAttributeValues';
import { renderInfoToast } from '@components/Toast';
import Modal, { ModalHeader } from '@components/UI/Modal';
import fetchClient from '@lib/fetchClient';

import CustomAttributeForm, { FormValues } from './CustomAttributeForm/CustomAttributeForm';

interface AddCustomAttributeModalProps {
  initialData?: CustomAttributeModel;
  onClose: () => void;
}

const AddCustomAttributeModal: React.FC<AddCustomAttributeModalProps> = ({
  initialData,
  onClose,
}) => {
  const {
    error: creatingError,
    isError: isCreatingError,
    isLoading: isCreatingLoading,
    mutate: createCustomAttribute,
  } = usePostCustomAttribute({
    onSuccess: () => {
      fetchClient.invalidateQueries(customAttributesCacheKeys.customAttributes);
      fetchClient.invalidateQueries(customAttributeValuesCacheKeys.customAttributeValues);
      fetchClient.invalidateQueries(customAttributesWithValueCacheKeys.customAttributesWithValues);
      renderInfoToast('Custom attribute has been created.');
      onClose();
    },
  });

  const {
    error: updatingError,
    isError: isUpdatingError,
    isLoading: isUpdatingLoading,
    mutate: updateCustomAttribute,
  } = usePatchCustomAttribute(initialData?.guid || '', {
    onSuccess: () => {
      fetchClient.invalidateQueries(customAttributesCacheKeys.customAttributes);
      renderInfoToast('Custom attribute has been updated.');
      onClose();
    },
  });

  const handleSubmit = (submitValues: FormValues) => {
    const customAttributeParams: {
      data_source_types: string[];
      data_types: string[];
      name?: string;
      object_types: string[];
      type?: string;
    } = {
      data_source_types: [],
      data_types: [],
      name: submitValues.attributeName ? submitValues.attributeName : undefined,
      object_types: [],
      type: submitValues.type?.[0] ? (submitValues.type?.[0].value as string) : undefined,
    };
    if (submitValues.selectedAssets) {
      Object.keys(submitValues.selectedAssets).forEach((dataSource) => {
        const dataSourceAssets =
          submitValues.selectedAssets?.[dataSource as CustomAttributeDataSourceType];

        dataSourceAssets?.forEach((asset) => {
          const [objectType, dataType] = asset.split(',');
          customAttributeParams.data_source_types.push(dataSource);
          customAttributeParams.object_types.push(objectType);
          customAttributeParams.data_types.push(dataType);
        });
      });

      if (initialData) {
        updateCustomAttribute(customAttributeParams);
      } else {
        createCustomAttribute(customAttributeParams);
      }
    }
  };

  return (
    <Modal closeOnEscape onClose={onClose} open size="medium" useContext>
      <ModalHeader
        onClose={onClose}
        title={initialData ? 'Edit Custom Attribute' : 'Create Custom Attribute'}
      />
      <CustomAttributeForm
        error={creatingError || updatingError}
        initialData={initialData}
        isError={isCreatingError || isUpdatingError}
        isLoading={isCreatingLoading || isUpdatingLoading}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

export default AddCustomAttributeModal;
