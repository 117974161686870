import React from 'react';

import HeaderWithInfo from '../../Headers/HeaderWithInfo';

export const POPULARITY_DESCRIPTION =
  'The popularity is determined by the usage over the last 3 months.';

const PopularityCellHeader = () => {
  return <HeaderWithInfo description={POPULARITY_DESCRIPTION} name="Popularity" />;
};

export default PopularityCellHeader;
