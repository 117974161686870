import React from 'react';

import invalidateCache from '@api/invalidateCache';
import { useFetchTableauDataSources } from '@api/tableau';
import type { TableauDataSourceModel } from '@api/tableau/TableauDataSourceModel';
import Box from '@components/Box';
import BulkButtons from '@components/BulkButtons/BulkButtons';
import { FiltersSidebar } from '@components/FiltersSidebar';
import { GridContainer } from '@components/Grid';
import { ToggleHideItemsModal } from '@components/Modal';
import PageHeaderBar from '@components/PageHeaderBar';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import { StyledTablesPage } from '@pages/TablesPage/TablesPage.styles';
import type { FilterOptions } from '@utils/filters';
import { setParams, useUpdateFilters } from '@utils/filters';
import getItemsCount from '@utils/getItemsCount';
import MetadataDecorator from '@utils/MetadataDecorator';

import { DATA_TYPE_OPTIONS } from './constants';
import DataSourceTable, {
  DATA_SOURCE_TABLE_SEARCH_CONFIG,
  DATA_SOURCE_TABLE_SORT_CONFIG,
} from './DataSourceTable';
import type { ProjectPageViewProps } from './types';

const COUNT_ITEMS = [{ key: 'count', text: 'Datasource' }];

const DATA_SOURCE_REQUEST_CONFIG: FilterOptions = {
  order: '-popularity',
  page: 1,
  page_size: 100,
};

interface DataSourcePageProps extends ProjectPageViewProps {
  dataTypeInitialSelected?: string;
}

const DataSourcePage: React.FC<DataSourcePageProps> = ({
  dataTypeInitialSelected,
  dataTypes,
  dsGuid,
  guid,
  onSelectDataType,
  subtitle,
  title,
}) => {
  const { MODAL_IDS, checkModalOpened } = useModal();
  const { reset, selected, tagsCounts, toggleAll, toggleItem } =
    useBulkEditSelected<TableauDataSourceModel>({
      key: `${dsGuid} ${guid}`,
    });

  const filterService = useUpdateFilters(
    DATA_SOURCE_REQUEST_CONFIG,
    DATA_SOURCE_TABLE_SEARCH_CONFIG,
    DATA_SOURCE_TABLE_SORT_CONFIG,
    DATA_SOURCE_REQUEST_CONFIG.order,
  );
  const { filter } = filterService;
  const { isPbac, permissions } = useObjectPermissionsContext({ id: dsGuid });
  const selectedEditableItems = selected.items.filter((item) =>
    isPbac ? permissions[item.guid]?.isEditable : true,
  );

  const { data, isLoading } = useFetchTableauDataSources({
    params: {
      ...setParams(filter),
      bifolders: guid,
      datasources: dsGuid,
    },
  });

  const refetchDashboards = () => {
    invalidateCache((keys) => [keys.tableau.all]);
  };

  const titleSuffix = !isLoading && getItemsCount({ countItems: COUNT_ITEMS, ref: data });

  return (
    <Box compDisplay="flex">
      <MetadataDecorator title={title} />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <StyledTablesPage>
          <PageHeaderBar
            icon={dataTypes && <Icon name={dataTypes.icons.dataType} />}
            sticky
            supIcon={dataTypes && <Icon name={dataTypes.icons.dataSource} />}
            supTitle={subtitle}
            title={title}
            titleSuffix={titleSuffix}
          />
          <BulkButtons
            canEditTags
            canRemove={!filter.is_hidden}
            canUnHide={filter.is_hidden}
            isDataSourceEditable
            selectedEditableItems={selectedEditableItems}
            selectedItems={selected.items}
            showText
            tagsCounts={tagsCounts}
          />
          <Box mb={0.5}>
            <DataSourceTable
              data={data}
              filterService={filterService}
              isDataSourceEditable
              isLoading={isLoading}
              selectedRowIds={selected.ids}
              toggleAll={(checked) => {
                toggleAll(data?.results!, checked);
              }}
              toggleItem={toggleItem}
            />
          </Box>
        </StyledTablesPage>
      </GridContainer>
      {checkModalOpened(MODAL_IDS.hideItems) && (
        <ToggleHideItemsModal
          isShowItemsModal={filter.is_hidden}
          reloadData={() => {
            refetchDashboards();
            reset();
          }}
          selectedItems={selectedEditableItems}
        />
      )}
      <FiltersSidebar
        dataTypeInitialSelected={dataTypeInitialSelected}
        dataTypeOptions={DATA_TYPE_OPTIONS}
        filterService={filterService}
        isCategoryTags
        isPopularity
        isSelectStarDetected
        isStatusTags
        onSelectDataType={onSelectDataType}
        showHidden
      />
    </Box>
  );
};

export default React.memo(DataSourcePage);
