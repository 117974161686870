import styled from '@emotion/styled';

export const StyledSearchBar = styled.div`
  z-index: 1;
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  height: 40px;

  .ui.search {
    width: 100%;
  }

  .ui.icon {
    width: 100%;
    position: relative;
    z-index: 1;
  }

  .ui.icon.input > input {
    padding: 0 2.75rem;
    height: 2.5rem;
    background-color: #443561;
    border-radius: 6px;
    border: 0;
    font-size: 1rem;
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    color: #fff;
    width: 100%;

    &::placeholder {
      color: #fff;
      letter-spacing: 0;
      font-size: 1rem;
      opacity: 0.6;
    }

    &::selection {
      color: #fff;
      background: #261447;
    }
  }

  .with-results .ui.icon.input > input {
    border-radius: 6px 6px 0 0;
  }

  .search-icon {
    width: 1.06rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1rem;
  }

  .search-clear {
    font-size: 1.5625rem;
    fill: #fff;
    position: absolute;
    right: 0.9375rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
  }

  .ui.search > .results {
    position: absolute;
    background: #fff;
    width: 100%;
    margin-top: 0;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    border-radius: 0 0 5px 5px;
    border: 0;
    letter-spacing: 0.3px;

    .category {
      background-color: ${({ theme }) => theme.colors.white};
      display: block;
      box-shadow: none;

      &.active {
        background-color: ${({ theme }) => theme.colors.white};
      }
    }

    .message {
      padding: 0;

      .header,
      .description {
        padding: 1.25rem 1.5rem;
        border-bottom: 1px solid #e2e9f0;
      }

      .header {
        font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
        font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
        padding-left: 2rem;
      }

      .description {
        font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
      }
    }
  }

  .search-result .list {
    margin-bottom: 0;
  }

  .topsearch .search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  .ui.search > .results .result {
    padding: 1rem;
  }
`;

export const StyledBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 1;
`;

export const StyledSearchTabsScrollContainer = styled.div`
  height: 50px;
  width: 100%;
  z-index: 1;
  border-bottom: 1px solid ${({ theme }) => theme.colors.v1.gray[100]};
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledSearchBarResultsWrapper = styled.div`
  overflow-y: auto;
  max-height: 27rem;
`;

export const StyledGradient = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  &::after {
    content: '';
    width: 25px;
    height: inherit;
    position: absolute;
    right: 0;
    top: 0;
    background: linear-gradient(to right, transparent, white);
    pointer-events: none;
  }
`;
