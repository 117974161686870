import * as activity from './activity/cacheKeys';
import * as analytics from './analytics/cacheKeys';
import * as biColumns from './biColumns/cacheKeys';
import * as biDashboardElements from './biDashboardElements/cacheKeys';
import * as biNotebooks from './biNotebooks/cacheKeys';
import * as biTables from './biTables/cacheKeys';
import * as chatbot from './chatbot/cacheKeys';
import * as clientApiToken from './clientApiToken/cacheKeys';
import * as columns from './columns/cacheKeys';
import * as connectionAlerts from './connectionAlerts/cacheKeys';
import * as dashboards from './dashboards/cacheKeys';
import * as databases from './databases/cacheKeys';
import * as datasets from './datasets/cacheKeys';
import * as dataSources from './dataSources/cacheKeys';
import * as dbt from './dbt/cacheKeys';
import * as documents from './documents/cacheKeys';
import * as explores from './explores/cacheKeys';
import * as fields from './fields/cacheKeys';
import * as folders from './folder/cacheKeys';
import * as indexes from './indexes';
import * as ingestion from './ingestion/cacheKeys';
import * as lineage from './lineage/cacheKeys';
import * as lookML from './lookML/cacheKeys';
import * as metadata from './metadata/cacheKeys';
import * as metrics from './metrics/cacheKeys';
import * as organizations from './organizations/cacheKeys';
import * as organizationUser from './organizationUser/cacheKeys';
import * as queries from './queries/cacheKeys';
import * as schemas from './schema/cacheKeys';
import * as tableau from './tableau/cacheKeys';
import * as tables from './tables/cacheKeys';
import * as taggedItems from './taggedItems/cacheKeys';
import * as tags from './tags/cacheKeys';
import * as teams from './teams/cacheKeys';
import * as terms from './terms/cacheKeys';
import * as user from './user/cacheKeys';
import * as views from './views/cacheKeys';

const allCacheKeys = {
  activity,
  analytics,
  biColumns,
  biDashboardElements,
  biNotebooks,
  biTables,
  chatbot,
  clientApiToken,
  columns,
  connectionAlerts,
  dashboards,
  dataSources,
  databases,
  datasets,
  dbt,
  documents,
  explores,
  fields,
  folders,
  indexes,
  ingestion,
  lineage,
  lookML,
  metadata,
  metrics,
  organizationUser,
  organizations,
  queries,
  schemas,
  tableau,
  tables,
  taggedItems,
  tags,
  teams,
  terms,
  user,
  views,
};

export type CacheKeyType = typeof allCacheKeys;

export default allCacheKeys;
