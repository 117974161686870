import React from 'react';

import invalidateCache from '@api/invalidateCache';
import { usePatchUserSettings } from '@api/settings';
import Button from '@components/Button/Button';
import Form from '@components/Form';
import { FormHorizontalLabelGrid } from '@components/Form/Form.styles';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import InputLabel from '@components/UI/Form/InputLabel';
import Modal, { ModalFooter, ModalHeader } from '@components/UI/Modal';

import { renderInfoToast } from '../Toast';

interface ChangePasswordModalProps {
  onClose: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ onClose }) => {
  const [isConfirmError, setConfirmError] = React.useState(false);

  const { error, isLoading, mutate } = usePatchUserSettings({
    onSuccess: () => {
      renderInfoToast('Password changed.');
      invalidateCache((keys) => [
        keys.organizationUser.all,
        keys.organizationUser.organizationUser('me'),
        keys.user.me,
      ]);
      onClose();
    },
  });

  const { handleChange, handleSubmit } = useForm({
    initialValues: {
      confirmPassword: '',
      newPassword: '',
      password: '',
    },
    onSubmit: ({ confirmPassword, newPassword, password }) => {
      if (newPassword !== confirmPassword) {
        setConfirmError(true);
        return;
      }
      setConfirmError(false);
      mutate({ new_password: newPassword, password });
    },
  });

  return (
    <Modal onClose={onClose} open useContext>
      <ModalHeader onClose={onClose} title="Change Password" />
      <Form isLoading={isLoading} onSubmit={handleSubmit}>
        <FormHorizontalLabelGrid>
          <InputLabel compDisplay="contents" variantColor="modalForm">
            Password
            <Input
              error={error?.data?.password}
              helperText={error?.data?.password}
              name="password"
              onChange={handleChange}
              placeholder="Current Password"
              type="password"
            />
          </InputLabel>
          <InputLabel compDisplay="contents" variantColor="modalForm">
            New Password
            <Input
              error={error?.data?.new_password}
              helperText={error?.data?.new_password}
              name="newPassword"
              onChange={handleChange}
              placeholder="New Password"
              type="password"
            />
          </InputLabel>
          <Input
            aria-label="Confirm New Password"
            error={isConfirmError}
            gridColumnStart={2}
            helperText={isConfirmError && "Passwords don't match."}
            name="confirmPassword"
            onChange={handleChange}
            placeholder="Confirm New Password"
            type="password"
          />
        </FormHorizontalLabelGrid>
        <ModalFooter>
          <Button type="submit">Save</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
