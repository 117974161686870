import React, { useRef } from 'react';

import { DataSourcesMutatePayload } from '@api/dataSources';
import {
  ErrorText,
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import Textarea from '@components/UI/Form/Textarea';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { DataStudioCredentials } from '@models/DataSourceCredentials';

import useDataSourceMutation from '../useDataSourceMutation';

import { DataSourceFormProps } from './types';

interface CookieBag {
  [key: string]: string;
}

export const parseCurl = (currentValue: string): CookieBag => {
  const value = currentValue.trim();
  if (!value) return {};
  if (!value.startsWith('curl ')) {
    throw new Error('Only the curl command is supported for exporting cookies');
  }
  if (!value.includes("curl 'https://lookerstudio.google.com")) {
    throw new Error("The command must be for the 'lookerstudio.google.com' domain");
  }
  const match = value.match(" -H '[Cc]ookie: (.+?)'");
  if (!match) {
    throw new Error('Unable to find cookies in the command. Contact support.');
  }
  const cookieValues: CookieBag = {};
  match[1].split('; ').forEach((field) => {
    const f = field.indexOf('=');
    if (f >= 0) {
      const cookieName = field.substring(0, f);
      const cookieValue = field.substring(f + 1);
      cookieValues[cookieName] = cookieValue;
    }
  });
  return cookieValues;
};

const DataStudioForm: React.FC<DataSourceFormProps> = ({
  children,
  dataSource,
  dataType,
  name = '',
  onSuccess,
  renderBefore,
}) => {
  const segment = useSegmentContext();
  const lastCredentials = useRef<DataStudioCredentials | undefined>();
  const [parseError, setParseError] = React.useState('');

  const { error, isLoading, mutate } = useDataSourceMutation({
    dataSource,
    onSuccess: (ds) => {
      onSuccess({ ...ds, credentials: lastCredentials.current });
    },
  });

  const { handleChange, handleSubmit, setValues, values } = useForm({
    initialValues: {
      cookies: '',
      name: dataSource?.name || name,
    },
    onSubmit: (val) => {
      const payload = {} as DataSourcesMutatePayload;

      if (dataSource) {
        payload.guid = dataSource.guid;
        payload.type = dataSource.type;
      } else {
        payload.type = dataType;
      }
      payload.name = val.name;
      const newCredentials: DataStudioCredentials = {
        cookies: JSON.stringify(parseCurl(val.cookies)),
      };

      payload.credentials = newCredentials;
      lastCredentials.current = newCredentials;
      mutate(payload);
      segment?.track(SegmentTrackEventName.CreateServiceAccountConnectButtonClicked, { dataType });
    },
  });

  const updateCookie = (value: string) => {
    try {
      parseCurl(value);
    } catch (err) {
      setParseError(`${err}`);
      return;
    }
    setValues((prev) => ({
      ...prev,
      cookies: value,
    }));
    setParseError('');
  };

  const textareaError = parseError ?? error?.data?.cookies;

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        {renderBefore?.({ error, loading: isLoading })}
        <StyledLabel>
          Display Name
          <Input
            error={error?.data?.name}
            helperText={error?.data?.name}
            maxLength={50}
            name="name"
            onChange={handleChange}
            placeholder="Looker Studio"
            required
            type="text"
            value={values.name}
          />
        </StyledLabel>
        <StyledLabel>
          CURL command with cookies
          <Textarea
            isError={Boolean(textareaError)}
            name="cookies"
            onChange={(e) => updateCookie(e.target.value)}
            placeholder="curl https://lookerstudio.google.com/...."
            required
          />
          {textareaError && <ErrorText gridColumn="2/3">{textareaError}</ErrorText>}
        </StyledLabel>
      </StyledFormHorizontalLabelGrid>
      {children?.({ error, loading: isLoading })}
    </Form>
  );
};

export default DataStudioForm;
