import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import { PaginatedResponse } from '@models/PaginatedResponse';

import paginatedTransform from '../paginatedTransform';
import rawTransform from '../rawTransform';
import { UserModel } from '../user/UserModel';

import { UserListModel } from './UserListModel';

export const userGroupsCacheKeys = {
  itemUserLists: (itemId: string) => ['userLists', itemId],
  userListUsers: (userListId: string, itemId: string) => ['userLists', userListId, itemId],
  userLists: ['userLists'],
};

const fetchUserListsSelect = paginatedTransform(UserListModel);
export const useFetchUserLists = (options?: UseFetchOptions<PaginatedResponse<UserListModel>>) => {
  return useFetch<PaginatedResponse<UserListModel>>({
    ...options,
    queryKey: [...userGroupsCacheKeys.userLists, options?.params],
    select: fetchUserListsSelect,
    url: '/user-lists/',
  });
};

export const fetchItemUserListsSelect = rawTransform(UserListModel);
export const useFetchItemUserLists = (
  itemId: string,
  options?: UseFetchOptions<UserListModel[]>,
) => {
  return useFetch<UserListModel[]>({
    ...options,
    queryKey: [...userGroupsCacheKeys.itemUserLists(itemId), options?.params],
    select: fetchItemUserListsSelect,
    url: `/user-lists/users/${itemId}/`,
  });
};

const fetchUserListUsersSelect = paginatedTransform(UserModel);
export const useFetchUserListUsers = (
  userListId: string,
  itemId: string,
  options?: UseFetchOptions<PaginatedResponse<UserModel>>,
) => {
  return useFetch<PaginatedResponse<UserModel>>({
    ...options,
    queryKey: [...userGroupsCacheKeys.userListUsers(userListId, itemId), options?.params],
    select: fetchUserListUsersSelect,
    url: `/user-lists/${userListId}/users/${itemId}/`,
  });
};
