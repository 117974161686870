import type { IconProps } from '@components/UI/Icon';

export const tagColorMap = {
  blue: '#33b1ff',
  bolt: '#f38816',
  bronze: '#dea159',
  bug: '#ca3544',
  darkBlue: '#2e53cc',
  deprecated: '#ca3544',
  endorsed: '#549f93',
  gold: '#ffc700',
  gray: '#6f747c',
  green: '#386b63',
  inMaintenance: '#f38816',
  maroon: '#8a194a',
  navy: '#261447',
  orange: '#f38816',
  pii: '#f38816',
  pink: '#e3649b',
  purple: '#4c288f',
  red: '#ca3544',
  reviewing: '#549f93',
  setting: '#6f747c',
  silver: '#6f747c',
  teal: '#79b9ae',
  toBeDeprecated: '#f38816',
  yellow: '#f1cb00',
} as const;

export type TagIconOption = { color: string; icon: IconProps['name'] };

export const categoryIcons: Array<TagIconOption> = [
  { color: tagColorMap.blue, icon: 'tag' },
  { color: tagColorMap.yellow, icon: 'tag' },
  { color: tagColorMap.gray, icon: 'tag' },
  { color: tagColorMap.darkBlue, icon: 'tag' },
  { color: tagColorMap.green, icon: 'tag' },
  { color: tagColorMap.maroon, icon: 'tag' },
  { color: tagColorMap.navy, icon: 'tag' },
  { color: tagColorMap.orange, icon: 'tag' },
  { color: tagColorMap.pink, icon: 'tag' },
  { color: tagColorMap.purple, icon: 'tag' },
  { color: tagColorMap.red, icon: 'tag' },
  { color: tagColorMap.teal, icon: 'tag' },
];

export const statusIcons: Array<TagIconOption> = [
  { color: tagColorMap.endorsed, icon: 'endorsed' },
  { color: tagColorMap.reviewing, icon: 'reviewing' },
  { color: tagColorMap.orange, icon: 'toBeDeprecated' },
  { color: tagColorMap.orange, icon: 'inMaintenance' },
  { color: tagColorMap.orange, icon: 'bolt' },
  { color: tagColorMap.orange, icon: 'pii' },
  { color: tagColorMap.gray, icon: 'setting' },
  { color: tagColorMap.bronze, icon: 'medal' },
  { color: tagColorMap.silver, icon: 'medal' },
  { color: tagColorMap.gold, icon: 'medal' },
  { color: tagColorMap.red, icon: 'bug' },
  { color: tagColorMap.red, icon: 'deprecated' },
  { color: tagColorMap.gray, icon: 'dbt' },
];

interface statusIconTagMapInterface {
  [index: string]: string;
}

export const statusIconTagMap: statusIconTagMapInterface = {
  bolt: 'tags/tag-bolt',
  bug: 'tags/tag-bug',
  dbt: 'dbt',
  deprecated: 'tags/tag-deprecated',
  endorsed: 'tags/tag-endorsed',
  inMaintenance: 'tags/tag-in-maintenance',
  medalBronze: 'tags/tag-bronze',
  medalGold: 'tags/tag-gold',
  medalSilver: 'tags/tag-silver',
  pii: 'tags/tag-PII',
  reviewing: 'tags/tag-reviewing',
  setting: 'tags/tag-setting',
  toBeDeprecated: 'tags/tag-to-be-deprecated',
};
