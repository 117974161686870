import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Box from '@components/Box';
import Button from '@components/Button/Button';
import useGetConfigQueryParams from '@components/Explore.v1/useGetConfigQueryParams';
import { EXPLORE_PARAMS_MAP } from '@components/Explore.v1/useGetConfigQueryParams/useGetConfigQueryParams';
import IconButton from '@components/IconButton';
import Checkbox from '@components/UI/Form/Checkbox';
import InputLabel from '@components/UI/Form/InputLabel';
import RadioButton from '@components/UI/Form/RadioButton';
import Icon from '@components/UI/Icon/Icon';
import theme from '@styles/theme';

import { StyledInput } from './ExploreDevOptions.styles';

type ParamsValue = Record<string, string | number | boolean>;

const ExploreDevOptions: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const initialParamValues = useGetConfigQueryParams() as unknown as ParamsValue;
  const [params, setParams] = useState<ParamsValue>(initialParamValues);
  const [expandDevOptions, setExpandDevOptions] = useState(true);

  const toggleDevOptions = () => {
    setExpandDevOptions((prevExpandDevOptions) => !prevExpandDevOptions);
  };

  const handleCheckboxChange = (e: any) => {
    const { name } = e.target;

    setParams((prevParams) => ({
      ...prevParams,
      [name]: !prevParams[name],
    }));
  };

  const handleOptionChange = (paramName: string, option: string) => {
    setParams((prevParams) => ({
      ...prevParams,
      [paramName]: option,
    }));
  };

  const handleOddNumberChange = (e: any) => {
    const { name, value } = e.target;
    let newValue = Number(value);

    if (value % 2 === 0) {
      newValue += value > params[name] ? 1 : -1;
    }

    if (
      EXPLORE_PARAMS_MAP[name]?.options?.minValue &&
      newValue < (EXPLORE_PARAMS_MAP[name]?.options?.minValue ?? 1)
    ) {
      newValue = EXPLORE_PARAMS_MAP[name]?.options?.minValue ?? 1;
    }

    setParams((prevParams) => ({
      ...prevParams,
      [name]: newValue,
    }));
  };

  const handleSave = () => {
    let newParams = '';

    Object.keys(params).forEach((valueKey) => {
      if (params[valueKey] !== EXPLORE_PARAMS_MAP[valueKey].defaultValue) {
        const paramName = EXPLORE_PARAMS_MAP[valueKey].key;
        let paramValue = '';

        if (EXPLORE_PARAMS_MAP[valueKey].type === 'boolean') {
          paramValue = params[valueKey] ? '1' : '0';
        } else {
          paramValue = String(params[valueKey]);
        }

        newParams = `${newParams}&${paramName}=${paramValue}`;
      }
    });

    history.push(`${location.pathname}?version=v0${newParams ?? `&${newParams}`}`);
    window.location.reload();
  };

  const handleReset = () => {
    history.push(`${location.pathname}?version=v0`);
    window.location.reload();
  };

  return (
    <Box
      backgroundColor="white"
      left="10px"
      maxWidth="300px"
      position="absolute"
      top="10px"
      zIndex={4}
    >
      <IconButton label="Dev Options" onClick={toggleDevOptions} role="button">
        <Icon
          color={theme.colors.v1.gray[400]}
          name={expandDevOptions ? 'up' : 'down'}
          size="20px"
        />
      </IconButton>
      {expandDevOptions && (
        <Box p={1}>
          {Object.keys(EXPLORE_PARAMS_MAP).map((paramName) => {
            switch (EXPLORE_PARAMS_MAP[paramName].type) {
              case 'boolean':
                return (
                  <InputLabel key={paramName} compHeight="100%" compWidth="100%" fontSize="body1">
                    <Checkbox
                      checked={Boolean(params[paramName])}
                      name={paramName}
                      onChange={handleCheckboxChange}
                    />
                    {paramName}
                  </InputLabel>
                );
              case 'option':
                return (
                  <Box alignItems="flex-start" compDisplay="flex" gap={1}>
                    {EXPLORE_PARAMS_MAP[paramName].key}
                    {EXPLORE_PARAMS_MAP[paramName]?.options?.values?.map((option) => (
                      <Box key={option}>
                        <InputLabel key={option} alignItems="flex-start" compDisplay="flex" gap={1}>
                          <RadioButton
                            checked={option === params[paramName]}
                            name={option}
                            onChange={() => handleOptionChange(paramName, option)}
                          />
                          {option}
                        </InputLabel>
                      </Box>
                    ))}
                  </Box>
                );
              case 'odd-number':
                return (
                  <Box alignItems="flex-start" as="label" compDisplay="flex" gap={1}>
                    <StyledInput
                      name={paramName}
                      onChange={handleOddNumberChange}
                      type="number"
                      value={Number(params[paramName])}
                    />
                    {EXPLORE_PARAMS_MAP[paramName].key}
                  </Box>
                );
              default:
                return null;
            }
          })}
          <Box compDisplay="flex" gap={0.5} justifyContent="flex-end">
            <Button compSize="xs" onClick={handleReset}>
              Reset
            </Button>
            <Button compSize="xs" onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ExploreDevOptions;
