import React, { useEffect } from 'react';
import { DropdownProps } from 'semantic-ui-react';

import { DataSourcesMutatePayload } from '@api/dataSources';
import { useFetchDbtProject } from '@api/dbt/dbt';
import { DbtTypes } from '@api/dbt/types';
import Alert from '@components/Alert';
import Box from '@components/Box';
import { StyledText } from '@components/DataSourceSetup/components/LookMLProjectSetup/LookMLProjectSetup';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Dropdown from '@components/Dropdown';
import { DropzoneFormElement } from '@components/Dropzone';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import Icon from '@components/UI/Icon';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { useUserContext } from '@context/User';
import { isTransformerType, isWarehouseType } from '@models/DataSourceCredentials';
import { DataSourceModel } from '@models/DataSourceModel';
import { WarehouseOptions } from '@models/DataSourceOptions';

import useDataSourceMutation from '../useDataSourceMutation';

import { DataSourceFormProps } from './types';

const DEFAULT_URL = 'https://cloud.getdbt.com';

const DbtForm: React.FC<DataSourceFormProps> = ({
  children,
  dataSource,
  dataType,
  name = '',
  onSuccess,
  renderBefore,
}) => {
  const segment = useSegmentContext();
  const { dataSources: allDataSources } = useUserContext();
  const isPatch = Boolean(dataSource?.guid);

  const {
    error,
    isLoading: isSavingDataSource,
    mutate,
  } = useDataSourceMutation({ dataSource, onSuccess });

  const {
    data: dbtProjectResponse,
    isLoading,
    isRefetching,
  } = useFetchDbtProject(dataSource?.guid ?? '', {
    enabled: Boolean(dataSource?.guid),
    // we don't need to fetch the project if we're creating a new data source
    onSuccess: (d) => {
      const dbtProject = dataSource?.guid === d?.dataSource ? d : undefined;

      if (dbtProject && dbtProject?.dataSource === dataSource?.guid) {
        setValues((currentValues) => {
          return {
            ...currentValues,
            dialect: dbtProject.dialect,
            formType: dbtProject.type === DbtTypes.core ? DbtTypes.core : DbtTypes.cloud,
            targetDataSource: dbtProject.targetDataSource,
          };
        });
      }
    },

    refetchOnMount: true,
    staleTime: 0,
  });

  const loadingDbtProject = (isLoading || isRefetching) && dataSource !== undefined;

  const dbtProject =
    dataSource?.guid === dbtProjectResponse?.dataSource ? dbtProjectResponse : undefined;

  const warehouseDataSources =
    Object.values(allDataSources ?? {})
      ?.filter(
        (ds: DataSourceModel) =>
          ds.isEmailSent === true && isWarehouseType(ds.type) && !isTransformerType(ds.type),
      )
      ?.map((ds: DataSourceModel) => {
        return {
          dialect: ds.type,
          image: (
            <Icon
              compDisplay="inline-flex"
              mr={0.5}
              name={ds.dataTypes?.icons.dataSource!}
              size="16px"
            />
          ),
          key: ds.guid,
          text: ds.name,
          value: ds.guid,
        };
      }) ?? [];

  const dialectMap: { [dsType: string]: string } = warehouseDataSources.reduce((obj, ds) => {
    return { ...obj, [ds.value]: ds.dialect };
  }, {});

  const { handleChange, handleSubmit, setValues, values } = useForm({
    initialValues: {
      baseUrl: isPatch ? '' : DEFAULT_URL,
      catalogJson: undefined as string | undefined,
      dialect: 'snowflake',
      formType: DbtTypes.cloud,
      jobId: undefined as string | undefined,
      manifestJson: undefined as string | undefined,
      name: dataSource?.name || name,
      runResultsJson: undefined as string | undefined,
      serviceToken: undefined as string | undefined,
      targetDataSource: dbtProject?.targetDataSource,
    },
    onSubmit: (val) => {
      const payload = {} as DataSourcesMutatePayload;

      if (dataSource) {
        payload.guid = dataSource.guid;
        payload.type = dataSource.type;
      } else {
        payload.type = dataType;
      }
      payload.name = val.name;

      const PAYLOAD_CONFIG = {
        both: {},
        cloud: {
          base_url: val.baseUrl,
          job_id: val.jobId,
          service_token: val.serviceToken,
        },
        core: {
          catalog_json: val.catalogJson,
          manifest_json: val.manifestJson,
          run_results_json: val.runResultsJson,
        },
        empty: {},
      };

      payload.credentials = {
        configure: Boolean(dataSource),
        dialect: val.dialect,
        target_data_source: val.targetDataSource,
        ...PAYLOAD_CONFIG[val.formType],
      };
      mutate(payload);

      segment?.track(SegmentTrackEventName.CreateServiceAccountConnectButtonClicked, { dataType });
    },
  });

  useEffect(() => {
    /**
     * setup defaults based on data sources list
     * this can't be done before when initializing the form
     * because the data source list may not be loaded yet
     */
    if (warehouseDataSources.length !== 0 && !values.targetDataSource) {
      setValues({
        ...values,
        dialect: warehouseDataSources[0].dialect,
        targetDataSource: warehouseDataSources[0].value,
      });
    }
  }, [warehouseDataSources]);

  const onAddManifestJSON = (newValue?: string) =>
    setValues((oldValues) => ({ ...oldValues, manifestJson: newValue }));

  const onAddCatalogJSON = (newValue?: string) =>
    setValues((oldValues) => ({ ...oldValues, catalogJson: newValue }));

  const onAddRunResultsJSON = (newValue?: string) =>
    setValues((oldValues) => ({ ...oldValues, runResultsJson: newValue }));

  const dataSourceSelector = (() => {
    /*
     * are we creating a new data source (true)
     * or configuring an existing one (false)
     */
    const isCreate = !dataSource;

    // if data source has a target data source already
    const hasTargetDataSource = !!dbtProject?.targetDataSource;

    // if org already has data sources
    const hasWarehouseDataSources = warehouseDataSources.length !== 0;

    /*
     * if we are creating a new data source, then we should check the list of target data sources
     * if we are configuring an existing data source, then we should just use what's given to us
     */
    const hasPotentialTargetDataSource = isCreate ? hasWarehouseDataSources : hasTargetDataSource;

    if (hasPotentialTargetDataSource) {
      return (
        <StyledLabel as="div">
          Dialect
          <Dropdown
            disabled={Boolean(dataSource)}
            error={error?.data && error.data?.target_data_source}
            icon="dropdown"
            onChange={(_, d: DropdownProps) => {
              const guid: string = d.value as string;
              setValues({
                ...values,
                dialect: dialectMap[guid],
                targetDataSource: guid,
              });
            }}
            options={warehouseDataSources}
            placeholder={warehouseDataSources[0].text}
            selection
            value={values.targetDataSource}
          />
        </StyledLabel>
      );
    }

    return (
      <StyledLabel as="div">
        Dialect
        <Dropdown
          aria-label="dialect"
          disabled={Boolean(dataSource)}
          error={error?.data && error.data?.dialect}
          icon="dropdown"
          onChange={(_, d) => {
            setValues((oldValues) => ({
              ...oldValues,
              dialect: d.value as string,
            }));
          }}
          options={WarehouseOptions}
          placeholder={WarehouseOptions[0].text}
          selection
          value={values?.dialect}
        />
      </StyledLabel>
    );
  })();

  return (
    <Form isLoading={isSavingDataSource || loadingDbtProject} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        {Boolean(dataSource) && (
          <Box gridColumn="1/3">
            <Alert type="info">
              <StyledText as="span" display="inline">
                Since you are configuring an existing data source, you can adjust the ingestion
                settings without needing to put in dbt Cloud credentials or dbt Core files again.
              </StyledText>
            </Alert>
          </Box>
        )}
        {renderBefore?.({ error, loading: isSavingDataSource })}
        <StyledLabel>
          Display Name
          <Input
            error={error?.data?.name}
            helperText={error?.data?.name}
            maxLength={50}
            name="name"
            onChange={handleChange}
            placeholder="dbt"
            type="text"
            value={values.name}
          />
        </StyledLabel>
        {dataSourceSelector}
        <StyledLabel as="div">
          dbt Type
          <Dropdown
            aria-label="dbt type"
            icon="dropdown"
            onChange={(_, d) => {
              setValues({
                ...values,
                formType: d.value as DbtTypes,
              });
            }}
            options={[
              {
                key: DbtTypes.cloud,
                text: 'dbt Cloud',
                value: DbtTypes.cloud,
              },
              {
                key: DbtTypes.core,
                text: 'dbt Core (open source)',
                value: DbtTypes.core,
              },
            ]}
            selection
            value={values.formType}
          />
        </StyledLabel>
        {values.formType === DbtTypes.core && (
          <StyledLabel as="div">
            manifest.json
            <DropzoneFormElement
              isComplete={values.manifestJson !== undefined}
              onFileAccept={onAddManifestJSON}
              text="Drag & Drop your manifest.json here"
            />
          </StyledLabel>
        )}
        {values.formType === DbtTypes.core && (
          <StyledLabel as="div">
            catalog.json
            <DropzoneFormElement
              isComplete={values.catalogJson !== undefined}
              onFileAccept={onAddCatalogJSON}
              text="Drag & Drop your catalog.json here"
            />
          </StyledLabel>
        )}
        {values.formType === DbtTypes.core && (
          <StyledLabel as="div">
            run_results.json
            <DropzoneFormElement
              isComplete={values.runResultsJson !== undefined}
              onFileAccept={onAddRunResultsJSON}
              text="Drag & Drop your run_results.json here"
            />
          </StyledLabel>
        )}
        {values.formType === DbtTypes.cloud && (
          <>
            <StyledLabel>
              API Key
              <Input
                name="serviceToken"
                onChange={handleChange}
                placeholder="API Key"
                type="password"
                value={values.serviceToken}
              />
            </StyledLabel>
            <StyledLabel>
              dbt Job Id
              <Input
                name="jobId"
                onChange={handleChange}
                placeholder="12345"
                value={values.jobId}
              />
            </StyledLabel>
            <StyledLabel>
              Access URL
              <Input
                error={error?.data?.base_url}
                helperText={error?.data?.base_url}
                name="baseUrl"
                onChange={handleChange}
                placeholder={DEFAULT_URL}
                value={values.baseUrl}
              />
            </StyledLabel>
          </>
        )}
        <Box gridColumn="1/3">
          {error?.data.manifest_json && (
            <Alert title="manifest.json" type="error">
              {error?.data.manifest_json}
            </Alert>
          )}
          {error?.data.catalog_json && (
            <Alert title="catalog.json" type="error">
              {error?.data.catalog_json}
            </Alert>
          )}
        </Box>
      </StyledFormHorizontalLabelGrid>
      {children?.({ error, loading: isLoading })}
    </Form>
  );
};

export default DbtForm;
