import styled from '@emotion/styled';

import Box from '@components/Box';
import Form from '@components/Form';
import InputLabel from '@components/UI/Form/InputLabel';
import theme from '@styles/theme';

export const StyledRBACPolicyModalForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledRBACPolicyModalFieldLabel = styled(InputLabel)``;

StyledRBACPolicyModalFieldLabel.defaultProps = {
  alignItems: 'baseline',
  as: 'div' as const,
  color: 'gray.700',
  compWidth: '100%',
  cursor: 'default',
  fontWeight: 'medium',
  gap: 1,
};

export const StyledRBACPolicyModalPillContainer = styled(Box)``;

StyledRBACPolicyModalPillContainer.defaultProps = {
  compDisplay: 'flex',
  flexDirection: 'column',
  gap: 1,
  maxHeight: theme.space(30),
  noDefault: true,
  overflow: 'auto',
};
