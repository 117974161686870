import { Expose } from 'class-transformer';

export interface OrganizationTeamData {
  fullName: string;
  guid: string;
  membersCount: number;
  name: string;
}

export class OrganizationTeamModel {
  static objectType: string = 'organizationteam';

  objectType: string = OrganizationTeamModel.objectType;

  static typeDisplay: string = 'OrganizationTeam';

  typeDisplay: string = OrganizationTeamModel.typeDisplay;

  @Expose()
  guid?: string;

  @Expose()
  name?: string;

  @Expose({ name: 'members_count' })
  membersCount?: number;

  get fullName() {
    return this.name;
  }

  get mappedAvatar() {
    return {
      guid: this.guid,
      name: this.fullName,
    };
  }
}
