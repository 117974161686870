import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { StyledLink } from './ProfileMenu.styles';

export interface ProfileMenuItemsProps {
  externalLink?: boolean;
  icon?: JSX.Element;
  link: string;
  name: string;
  newTab?: boolean;
}

const ProfileMenuItem: React.FC<ProfileMenuItemsProps> = ({
  externalLink,
  icon,
  link,
  name,
  newTab,
}) => {
  if (externalLink) {
    return (
      <StyledLink href={link} rel="noopener noreferrer" target="_blank">
        {icon}
        {name}
      </StyledLink>
    );
  }

  return (
    <RouterLink to={link} {...(newTab ? { target: '_blank' } : {})}>
      {icon}
      {name}
    </RouterLink>
  );
};

export default ProfileMenuItem;
