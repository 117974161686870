export type Radius = typeof radius;

const radius = {
  default: '4px',
  lg: '8px',
  md: '6px',
  sm: '3px',
  xl: '12px',
  xs: '2px',
};

export default radius;
