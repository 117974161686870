import React from 'react';

import { useFetchItemUserLists } from '@api/userLists';
import { Option } from '@components/UI/Select.v1/types';

import GroupParticipantsTooltip from '../GroupParticipantsTooltip';

import UserListLabel from './UserListLabel';

interface UseUserLists {
  isLoading: boolean;
  userLists: Option[];
}

const useUserLists = (itemGuid: string): UseUserLists => {
  const { data, isLoading } = useFetchItemUserLists(itemGuid, {
    params: {
      'Top Dashboard Users': { require_email: true },
      'Top Table Users': { require_email: true },
      user_types: 'user,table,bi,any',
    },
  });

  const userLists = (data || [])
    .filter((list) => list.users && list.users?.length > 0)
    .map((list) => {
      const listName = `${list.name} (${list.users?.length})`;
      const customLabel = (
        <UserListLabel listName={list.name} usersCount={Number(list.users?.length)} />
      );
      return {
        customLabel,
        icon: 'team',
        text: listName,
        tooltip: {
          content: <GroupParticipantsTooltip participants={list.users!} />,
          title: customLabel,
        },
        value: list.guid,
      };
    }) as Option[];

  return {
    isLoading,
    userLists,
  };
};

export default useUserLists;
