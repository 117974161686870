import React, { useRef } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { Redirect } from 'react-router-dom';

import client from '@api';

import UnexpectedError from './Error/UnexpectedError';

interface PublicRouteProps {
  shouldRedirect?: boolean;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children, shouldRedirect = true }) => {
  const { current: isAuthedOnMount } = useRef(client.isAuthed());

  if (isAuthedOnMount && shouldRedirect) {
    return <Redirect to="/" />;
  }
  return <ErrorBoundary fallback={UnexpectedError}>{children}</ErrorBoundary>;
};

export default PublicRoute;
