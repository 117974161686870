import styled from '@emotion/styled';

import Box from '@components/Box';

export const StyledTagsontainer = styled(Box)`
  margin: ${({ theme }) => theme.space(0, 0.5)};

  ${({ theme }) => theme.media.down('md')`
    margin-left: 0;
    margin-top: ${theme.space(0.5)};

    & :first-child {
      margin-left: 0;
    }
  `}
`;
