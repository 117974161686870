import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

export const DataSourceLink = styled(RouterLink)`
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: underline;
  }
`;
