import React, { useEffect, useState } from 'react';

import { dsUsersCacheKeys, useFetchDsUsers, usePatchDsUser } from '@api/dsusers';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import Select from '@components/UI/Select.v1/Select';
import { Option, SelectValue } from '@components/UI/Select.v1/types';
import useUserContext from '@context/User/User';
import fetchClient from '@lib/fetchClient';
import { isWarehouseType } from '@models/DataSourceCredentials';

interface AddConnectionRowProps {
  dsType: string;
  onClose: () => void;
}

interface ConnectionForm {
  dataSource?: SelectValue;
  dsUser?: SelectValue;
}

const AddConnectionRow: React.FC<AddConnectionRowProps> = ({ dsType, onClose }) => {
  const { dataSources } = useUserContext();
  const [dsUsers, setDsUsers] = useState<DsUserModel[]>([]);

  const userOptions = dsUsers.map((dsUser) => ({
    avatar: dsUser.mappedAvatar,
    text: dsUser.name ?? '',
    value: dsUser.guid,
  }));

  const databaseConnections = dataSources
    ? Object.keys(dataSources)
        .map((dsGuid) => dataSources[dsGuid])
        .filter((dsConn) => isWarehouseType(dsConn.type))
    : [];

  const dataSourceOptions = databaseConnections.map((connection) => ({
    icon: connection.dataTypes?.icons.dataSource!,
    text: connection.name,
    value: connection.guid,
  }));

  const getServiceAccountFilterMap = (type: string) => {
    if (type === 'periscope') {
      return { is_periscope_service_account: true };
    }

    if (type === 'sigma') {
      return { is_sigma_service_account: true };
    }

    return { is_mode_service_account: true };
  };

  const { mutate: updateDsUser } = usePatchDsUser('', {
    onError: () => {
      renderErrorToast('There was an error adding the data source user.');
      onClose();
    },
    onSuccess: () => {
      renderInfoToast('Data source user added successfully.');
      fetchClient.invalidateQueries(dsUsersCacheKeys.dsUsers);
      onClose();
    },
  });

  const { handleSubmit, setValues, values } = useForm<ConnectionForm>({
    initialValues: {
      dataSource: undefined,
      dsUser: undefined,
    },
    onSubmit: (data) => {
      const [newDsUserOption] = data.dsUser ?? [];
      updateDsUser({
        httpClientUrl: `/dsusers/${newDsUserOption.value}/`,
        ...getServiceAccountFilterMap(dsType),
      });
    },
  });

  const { data: dsUserResponse, isLoading: loadingDsUsers } = useFetchDsUsers({
    enabled: Boolean(values.dataSource?.[0]?.value),
    params: {
      datasources: values.dataSource?.[0]?.value ?? '',
    },
  });

  const filterByServiceAccount = (user: DsUserModel, type: string) => {
    if (type === 'periscope') {
      return !user.isPeriscopeServiceAccount;
    }
    if (type === 'sigma') {
      return !user.isSigmaServiceAccount;
    }
    return !user.isModeServiceAccount;
  };

  useEffect(() => {
    if (values.dataSource?.[0]?.value && dsUserResponse) {
      setDsUsers(dsUserResponse.results.filter((dsUser) => filterByServiceAccount(dsUser, dsType)));
    }
  }, [dsUserResponse, values, dsType]);

  const handleFieldChange = (field: keyof ConnectionForm, newValue: SelectValue) => {
    const getNewValues = (prevValues: ConnectionForm) => {
      const newValues = {
        ...prevValues,
        [field]: newValue as Option[],
      };

      if (field === 'dataSource') {
        newValues.dsUser = undefined;
      }

      return newValues;
    };

    setValues(getNewValues);
  };

  return (
    <Form compDisplay="flex" compWidth="100%" flexDirection="row" onSubmit={handleSubmit}>
      <Box compWidth="4rem" />
      <Box compWidth="50%" p={1}>
        <Select
          onChange={(newValue) => handleFieldChange('dataSource', newValue)}
          options={dataSourceOptions}
          placeholder="Select Data Source"
          value={values.dataSource}
        />
      </Box>
      <Box alignItems="flex-start" compDisplay="flex" compWidth="50%" p={1}>
        <Select
          isDisabled={!values.dataSource}
          isLoading={loadingDsUsers}
          onChange={(newValue) => handleFieldChange('dsUser', newValue)}
          options={userOptions}
          placeholder={!values.dataSource ? 'Please select a DB connection first.' : ''}
          showClearButton
          value={values.dsUser}
        />
        <Box ml={1} mt={0.15}>
          <Button disabled={!values.dsUser?.[0]?.value} type="submit">
            Add
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

export default AddConnectionRow;
