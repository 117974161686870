import React from 'react';

import { TagModel } from '@api/tags/TagModel';
import AppTag from '@components/AppTag';
import Box from '@components/Box';
import Button from '@components/Button';
import Text from '@components/Text';
import Modal, { ModalContent } from '@components/UI/Modal';

export interface CreateAnotherTagModalProps {
  newTag?: TagModel;
  onClose: () => void;
  onCreateAnother: () => void;
}

const CreateAnotherTagModal: React.FC<CreateAnotherTagModalProps> = ({
  newTag,
  onClose,
  onCreateAnother,
}) => {
  if (!newTag) return null;

  return (
    <Modal id="create-another-tag" open useContext>
      <ModalContent
        compDisplay="flex"
        flexDirection="column"
        gap={2}
        justifyContent="space-between"
        minHeight="unset"
        py={3}
      >
        <Text
          data-testid="create-another-tag-content"
          display="unset"
          fontSize="body1"
          lineHeight="28px"
          m={0}
          verticalAlign="middle"
        >
          Tag{' '}
          <Box as="span" verticalAlign="sub">
            <AppTag noLink tag={newTag} variant="outlined" />
          </Box>{' '}
          was created successfully. Would you like to create another?
        </Text>
        <Box compDisplay="flex" compWidth="100%" gap={1.5} justifyContent="flex-end">
          <Button onClick={onClose} variant="outlined">
            Do it later
          </Button>
          <Button onClick={onCreateAnother}>Create another one</Button>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default CreateAnotherTagModal;
