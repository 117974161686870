import React from 'react';
import getIcons from '@configs/icons/getIcons';
import { Cell } from 'react-table';

import { RepositoryModel } from '@api/syncBack/RepositoryModel';
import Box from '@components/Box';
import DateTime from '@components/DateTime/DateTime';
import IconButton from '@components/IconButton/IconButton';
import Link from '@components/Link';
import type { ColumnConfig } from '@components/Table/Table/types';
import type { IconProps } from '@components/UI/Icon';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import theme from '@styles/theme';
import { Filter } from '@utils';

import UserCell from '../Cells/UserCell/UserCell';
import Table from '../Table';
import TableStyled from '../TableStyled';

const INITIAL_SORT_STATE = [
  {
    desc: true,
    id: 'name',
  },
];

interface AdminGitReposTableProps {
  data?: RepositoryModel[];
  filterService: Filter.FilterServiceInterface;
  itemCount?: number;
  loading?: boolean;
  totalPages?: number;
}

const AdminGitReposTable: React.FC<AdminGitReposTableProps> = ({
  data,
  filterService,
  itemCount = 0,
  loading,
  totalPages,
}) => {
  const { MODAL_IDS, openModal } = useModal();
  const { changePage, filter, sort } = filterService;
  const columns: ColumnConfig<RepositoryModel>[] = React.useMemo(
    () => [
      {
        Cell: ({ row }: Cell<RepositoryModel>) => {
          return (
            <Box alignItems="center" compDisplay="flex">
              <Icon mr={1} name={row.original.provider as IconProps['name']} size="16px" />
              <Link
                color="inherit"
                fontSize="body2"
                href={row.original.url}
                rel="noopener noreferrer"
                target="_blank"
                underline
                underlineOnHover
              >
                {row.original.name}
              </Link>
            </Box>
          );
        },
        Header: `Name (${itemCount})`,
        accessor: (t) => t?.name,
        disableFilters: true,
        disableHiding: true,
        id: 'name',
        width: '116%',
      },
      {
        Cell: ({ row }: Cell<RepositoryModel>) => {
          return (
            <Box alignItems="center" compDisplay="flex" gap={0.5}>
              <Icon
                name={getIcons({ dataSourceType: row.original.dataSource.type }).dataSource}
                size="16px"
              />
              {row.original.dataSource.name}
            </Box>
          );
        },
        Header: 'Data source',
        accessor: (t) => t?.dataSource?.name,
        id: 'dataSource',
        width: '118%',
      },
      {
        Cell: ({ row }: Cell<RepositoryModel>) => {
          return <DateTime datetime={row.original.lastSuccessfulSync} format="fullDateFormat" />;
        },
        Header: 'Last Successful Sync',
        accessor: (t) => t?.lastSuccessfulSync,
        id: 'lastSuccessfulSync',
        width: '116%',
      },
      {
        Cell: (props: Cell<RepositoryModel>) => {
          const { row } = props;
          return <UserCell {...props} user={row.original.creator} />;
        },
        Header: 'Creator',
        accessor: (t) => t?.creator?.fullName,
        id: 'creator',
        width: '150%',
      },
      {
        Cell: ({ row }: Cell<RepositoryModel>) => (
          <Box alignItems="center" compDisplay="flex" justifyContent="center">
            <IconButton
              iconColor={theme.colors.v1.gray[600]}
              iconName="edit-outline"
              iconSize={theme.space(2.5)}
              onClick={() =>
                openModal?.(MODAL_IDS.createGithubRepository, { repository: row.original })
              }
              variant="clear"
            />
          </Box>
        ),
        disableResizing: true,
        disableSortBy: true,
        id: 'edit',
        width: 30,
      },
      {
        Cell: ({ row }: Cell<RepositoryModel>) => {
          return (
            <Box alignItems="center" compDisplay="flex" justifyContent="center">
              <IconButton
                iconColor={theme.colors.v1.gray[600]}
                iconName="trash"
                iconSize={theme.space(2.5)}
                onClick={() =>
                  openModal?.(MODAL_IDS.deleteGithubRepository, { repository: row.original })
                }
                variant="clear"
              />
            </Box>
          );
        },
        disableFilters: true,
        disableSortBy: true,
        id: 'delete',
        width: 60,
      },
    ],
    [MODAL_IDS, itemCount, openModal],
  );

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        data={data || []}
        disableColumnFiltering
        disablePagination
        disableRowSelect
        initialState={{
          pageIndex: filter.page ? filter.page - 1 : 0,
          sortBy: INITIAL_SORT_STATE,
        }}
        loading={loading}
        manualPagination
        manualSortBy
        setSortBy={sort}
        singleLine
        sortable
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default AdminGitReposTable;
