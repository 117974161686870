import React, { useRef } from 'react';

import type { OrderBy, OrderByButtonStylesProps } from './OrderByButton.styles';
import { StyledOrderByButton } from './OrderByButton.styles';

interface OrderByButtonProps {
  onClick: (orderBy: OrderBy) => void;
}

const orderBySteps = ['default', 'asc', 'desc'] as const;

const OrderByButton: React.FC<OrderByButtonProps & OrderByButtonStylesProps> = ({
  children,
  onClick,
  orderBy = 'default',
}) => {
  const clickCount = useRef(orderBySteps.indexOf(orderBy));

  const handleClick = () => {
    if (clickCount.current < 2) {
      clickCount.current += 1;
    } else {
      clickCount.current = 0;
    }

    onClick?.(orderBySteps[clickCount.current]);
  };

  return (
    <StyledOrderByButton onClick={handleClick} orderBy={orderBy} title="Toggle SortBy">
      {children}
    </StyledOrderByButton>
  );
};

export default OrderByButton;
