import styled from '@emotion/styled';
import * as CSS from 'csstype';
import { Message } from 'semantic-ui-react';

import makeSpace, { MakeSpaceProps } from '@styles/mixins/makeSpace';

export interface StyledMessageProps {
  backgroundColor?: CSS.Property.BackgroundColor;
  display?: CSS.Property.Display;
  fontSize?: CSS.Property.FontSize;
  fontWeight?: CSS.Property.FontWeight;
  lineHeight?: CSS.Property.LineHeight;
  textAlign?: CSS.Property.TextAlign;
  textColor?: CSS.Property.Color;
}

/**
 * @deprecated Use <Alert /> instead.
 */
const StyledMessage = styled(Message)<StyledMessageProps & MakeSpaceProps>`
  &.ui.message {
    ${({ theme, ...props }) => makeSpace(theme, props)};
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    width: 100%;
    box-shadow: none;
    font-size: ${({ fontSize = '16px' }) => fontSize};
    font-weight: ${({ fontWeight = 500 }) => fontWeight};
    color: ${({ textColor = 'white' }) => textColor};
    text-align: ${({ textAlign = 'left' }) => textAlign};
    display: ${({ display = 'inline-flex' }) => display};
    line-height: ${({ lineHeight = '1.185rem' }) => lineHeight};
    background-color: ${({ backgroundColor = '#f6f9fc' }) => backgroundColor};
  }
`;

export default StyledMessage;

export const errorMessageStyles: StyledMessageProps = {
  backgroundColor: 'transparent',
  display: 'block',
  fontSize: '13px',
  fontWeight: 400,
  textColor: '#D12771',
};
