import styled from '@emotion/styled';

import Box from '@components/Box';
import rootTheme from '@styles/theme';

interface StyledUsageFieldFilterAnchorProps {
  disabled?: boolean;
}

export const StyledUsageFieldFilterAnchor = styled(Box)<StyledUsageFieldFilterAnchorProps>`
  border: 1px solid ${({ theme }) => theme.colors.v1.gray[300]};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

StyledUsageFieldFilterAnchor.defaultProps = {
  alignItems: 'center',
  backgroundColor: 'white',
  borderRadius: 'default',
  color: 'gray.800',
  compDisplay: 'flex',
  compHeight: rootTheme.space(4),
  fontSize: 'sm',
  gap: 1,
  justifyContent: 'center',
  px: 1.5,
};

export const StyledUsageFieldFilterActionContainer = styled(Box)`
  border-top: ${({ theme }) => `1px solid ${theme.colors.v1.gray[300]}`};
`;

StyledUsageFieldFilterActionContainer.defaultProps = {
  compDisplay: 'flex',
  gap: 1,
  justifyContent: 'flex-end',
  px: 1.25,
  py: 1,
};

export const StyledUsageFieldFilterOverlay = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;
