import useLineageInteractions from '@components/Explore.v1/components/LineageInteractionsWrapper/useLineageInteractions';
import { cloneLineageData } from '@components/Explore.v1/Explore.v1.utils';
import parseLineageNodes from '@components/Explore.v1/useCreateNodesEdges/algorithm/parseLineageNodes';
import { EdgesById } from '@components/Explore.v1/useCreateNodesEdges/algorithm/types';
import { createRedirectedEdgesToCollapsedNode } from '@components/Explore.v1/useCreateNodesEdges/utils/createRedirectedEdges';
import { useExplore } from '@components/Explore.v1/useExplore';
import useGetConfigQueryParams from '@components/Explore.v1/useGetConfigQueryParams';
import { useUndoRedo } from '@components/Explore.v1/useUndoRedo';

const useCollapseAllNodes = () => {
  const { shouldHideFilterLineage } = useGetConfigQueryParams();
  const {
    edgesById: currentEdgesById,
    initialPosition,
    inputNodesById,
    isColumnLevelLineage,
    nodesByStack,
    setEdgesById,
    setIsCollapseAllButtonEnabled,
    setNodesById,
    setStacksData,
    stackData,
  } = useExplore();
  const { fitLineageView } = useLineageInteractions();
  const { saveExploreState } = useUndoRedo();

  const collapseAllNodes = () => {
    if (stackData?.nodesById) {
      const newStacksData = cloneLineageData(stackData);
      const nodesByIdKeys = Object.keys(newStacksData.nodesById);

      for (let i = 0; i < nodesByIdKeys.length; i += 1) {
        const nodeKey = nodesByIdKeys[i];
        const node = newStacksData.nodesById[nodeKey];
        node.data.isOpen = false;
      }

      let collapsedEdges: EdgesById = { ...currentEdgesById };

      const groupNodes = Object.values(nodesByStack).flatMap((stack) => Array.from(stack));

      groupNodes.forEach((nodeKey) => {
        const { edges: collapsedNodeEdges } = createRedirectedEdgesToCollapsedNode({
          edgesById: currentEdgesById,
          inputNodeId: nodeKey,
          nodesById: newStacksData.nodesById,
        });

        collapsedEdges = { ...collapsedEdges, ...collapsedNodeEdges };
      });

      const parsingResult = parseLineageNodes({
        edgesById: collapsedEdges,
        initialPosition,
        isColumnLevelLineage,
        nodesById: newStacksData?.nodesById ?? {},
        shouldHideFilterLineage,
        stackGroups: newStacksData?.stackGroups,
      });

      setNodesById(parsingResult.nodesById);
      setEdgesById(parsingResult.edgesById);
      setIsCollapseAllButtonEnabled(false);
      setStacksData(newStacksData);
      fitLineageView();

      saveExploreState({
        biggestConflictEndPerStack: parsingResult.biggestConflictEndPerStack,
        edgesById: parsingResult.edgesById,
        initialPosition,
        inputNodesById: inputNodesById ?? {},
        isCollapseAllButtonEnabled: false,
        isColumnLevelLineage,
        nodesById: parsingResult.nodesById,
        nodesByStack: parsingResult.nodesByStack,
        stackData: newStacksData,
      });
    }
  };

  return { collapseAllNodes };
};

export default useCollapseAllNodes;
