import React from 'react';
import { Cell } from 'react-table';

import { JoinModel } from '@api/joins/JoinModel';
import { TableModel } from '@api/tables/TableModel';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import { getPopularityNormalized } from '@utils/popularity';

import { JoinConditions } from './components';
import PopularJoinTableStyled from './PopularJoinTableStyled';

interface Props {
  // We need to know which table is active to display "the other one" in Table Name column
  activeTable: TableModel;
  basic?: boolean;
  data?: JoinModel[];
  itemCount?: number;
  loading?: boolean;
}

const PopularJoinTable: React.FC<Props> = ({
  activeTable,
  basic,
  data,
  itemCount = 0,
  loading,
}) => {
  const columns: ColumnConfig<JoinModel>[] = React.useMemo(() => {
    const cols: ColumnConfig<JoinModel>[] = [
      {
        Cell: (props: Cell<JoinModel>) => {
          const join = props.row.original;
          const joinedTable =
            join.sourceTable.guid === activeTable.guid ? join.joinedTable : join.sourceTable;

          return <LinkedCell {...props} item={joinedTable} noBold showIcon />;
        },
        Header: `Table (${itemCount})`,
        accessor: (d) =>
          d?.sourceTable?.guid === activeTable.guid
            ? d.joinedTable?.fullName
            : d.sourceTable?.fullName,
        disableHiding: true,
        id: 'name',
        width: '140%',
      },
      {
        Cell: (props: Cell<JoinModel>) => {
          const join = props.row.original;
          return <JoinConditions conditions={join.conditions} query={join.sampleQuery} />;
        },
        Header: 'Join Condition',
        id: 'joinConditions',
        width: '160%',
      },
      {
        Cell: (props: Cell<JoinModel>) => {
          const join = props.row.original;
          return <PopularityCell {...props} isJoin popularity={join.popularity} />;
        },
        Header: PopularityCellHeader,
        accessor: (d) => getPopularityNormalized(d?.popularity?.popularity),
        disableFilters: true,
        disableResizing: true,
        id: 'popularity',
        sortDescFirst: true,
        width: 120,
      },
    ];

    if (basic) {
      cols.splice(2);
    }

    return cols;
  }, [itemCount, basic]);

  const getRowId = React.useCallback((row: Partial<JoinModel>) => row.guid!, []);

  return (
    <PopularJoinTableStyled>
      <Table
        basic="very"
        className="table-full"
        columns={columns}
        compact
        data={data || []}
        disableColumnFiltering
        disableFilters
        disablePagination
        disableRowSelect
        disableSortBy
        getRowId={getRowId}
        loading={loading}
        selectable
        sortable
        unstackable
      />
    </PopularJoinTableStyled>
  );
};

export default React.memo(PopularJoinTable);
