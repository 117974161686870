import React from 'react';
import { Cell } from 'react-table';

import { JoinModel } from '@api/joins/JoinModel';
import { QueryModel } from '@api/queries/QueryModel';
import Box from '@components/Box';
import DateTime from '@components/DateTime';
import Link from '@components/Link';
import QueryModalWithTargetBox from '@components/Modal/QueryModal/QueryModalWithTargetBox';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import UserCell from '@components/Table/Cells/UserCell';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import Icon from '@components/UI/Icon';
import { getPopularityNormalized } from '@utils/popularity';

interface Props {
  basic?: boolean;
  data?: QueryModel[];
  loading?: boolean;
}

const PopularJoinTable: React.FC<Props> = ({ basic, data, loading }) => {
  const columns: ColumnConfig<QueryModel>[] = React.useMemo(() => {
    const cols: ColumnConfig<QueryModel>[] = [
      {
        Cell: (props: Cell<QueryModel>) => {
          const query = props.row.original;

          return (
            <Box alignItems="center" compDisplay="flex" spacing={0.5}>
              <Icon name="query" size="15px" />
              <QueryModalWithTargetBox
                codeString={query?.rawSql}
                dataSourceType={query?.database?.type}
                query={query}
              >
                <Link as="span" color="inherit" fontSize="inherit" role="button" underlineOnHover>
                  {query?.displayName}
                </Link>
              </QueryModalWithTargetBox>
            </Box>
          );
        },
        Header: 'Query Name/ Sample',
        accessor: (d) => d?.rawSql,
        disableHiding: true,
        id: 'name',
        width: '170%',
      },
      {
        Cell: (props: Cell<QueryModel>) => {
          const query = props.row.original;
          return <UserCell {...props} user={query?.mainUser || query?.mainDSUser} />;
        },
        Header: 'Main User',
        id: 'mainUser',
      },
      {
        Cell: (props: Cell<QueryModel>) => {
          const query = props.row.original;
          return query.lastExecutedOn ? (
            <DateTime datetime={query.lastExecutedOn} format="textMonthFormat" />
          ) : (
            'never'
          );
        },
        Header: 'Last Run',
        id: 'lastRun',
      },
      {
        Cell: (props: Cell<QueryModel>) => {
          const query = props.row.original;
          return <PopularityCell {...props} isJoin popularity={query.popularity} />;
        },
        Header: PopularityCellHeader,
        accessor: (d) => getPopularityNormalized(d?.popularity?.popularity),
        disableFilters: true,
        disableResizing: true,
        id: 'popularity',
        sortDescFirst: true,
        width: 120,
      },
    ];

    if (basic) {
      cols.splice(1);
    }

    return cols;
  }, [basic]);

  const getRowId = React.useCallback((row: Partial<JoinModel>) => row.guid!, []);

  return (
    <TableStyled>
      <Table
        basic="very"
        className="table-full"
        columns={columns}
        compact
        data={data || []}
        disableColumnFiltering
        disableFilters
        disablePagination
        disableRowSelect
        disableSortBy
        getRowId={getRowId}
        loading={loading}
        selectable
        sortable
        unstackable
      />
    </TableStyled>
  );
};

export default React.memo(PopularJoinTable);
