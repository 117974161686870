import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import client from '@api';
import { clearChromeAuthData } from '@pages/LoginPage/ExtensionLoginPage.utils';

const PrivateRoute: React.FC = ({ children }) => {
  const location = useLocation();
  const [authed, setAuthed] = useState(client.isAuthed());

  const deauthorized = () => {
    setAuthed(false);
  };
  useEffect(() => {
    client.registerDeauthorizedCallback(deauthorized);
    return () => {
      client.unregisterDeauthorizedCallback(deauthorized);
    };
  });

  if (!authed && !client.isAuthed()) {
    if (process.env.REACT_APP_CHROME_EXTENSION_BUILD) {
      clearChromeAuthData();
    }

    const redirect = location.pathname + location.search;
    return <Redirect to={`/login?redirect=${encodeURIComponent(redirect)}`} />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
