import React from 'react';
import { useHistory } from 'react-router-dom';

import { DashboardModel } from '@api/dashboards/DashboardModel';
import type { DatasetModel } from '@api/datasets/DatasetModel.v1';
import { LookerExploreModel } from '@api/explores/LookerExploreModel';
import { TableauDataSourceModel } from '@api/tableau/TableauDataSourceModel';
import { TableModel } from '@api/tables/TableModel';
import Box from '@components/Box';
import RelatedDashboards from '@components/TabContent/RelatedDashboards';
import SourceTablesTab from '@components/TabContent/SourceTablesTab';
import { TabContentProps } from '@components/Tabs/types';
import type { TabRoutesConfig } from '@components/TabsRouter';
import TabsRouter from '@components/TabsRouter';
import Icon from '@components/UI/Icon';
import { useUserContext } from '@context/User';
import { DataSourceTypesType } from '@models/DataSourceCredentials';
import wrapString from '@utils/wrapString/wrapString';

import ExploreTab from './ExploreTab';
import UpstreamDownstreamTab from './UpstreamDownstreamTab';

interface TabbedLineageProps extends TabContentProps {
  dataSourceType?: DataSourceTypesType;
  guid: string;
  itemMetadata?:
    | TableModel
    | DashboardModel
    | LookerExploreModel
    | TableauDataSourceModel
    | DatasetModel;
  objectType?: 'explore' | 'table' | 'column' | 'dashboard' | 'lookmlview' | 'tableaudatasource';
  showDownstream?: boolean;
  showDownstreamDashboards?: boolean;
  showModeLineage?: boolean;
  sourceTables?: boolean;
  stickyHeader?: boolean;
}

const TabbedLineage: React.FC<TabbedLineageProps> = ({
  dataSourceType,
  guid,
  itemMetadata,
  objectType,
  showDownstream = true,
  showDownstreamDashboards = true,
  showModeLineage = true,
  sourceTables = false,
  stickyHeader,
}) => {
  const history = useHistory();
  const { organization, user } = useUserContext();
  const upstreamCount = itemMetadata?.upstreamObjectsCounts?.total;
  const downstreamCount = itemMetadata?.downstreamObjectsCounts?.total;
  const dashboardsCount = itemMetadata?.downstreamObjectsCounts?.dashboard;
  const sourceTablesCount = itemMetadata?.upstreamObjectsCounts?.sourceTable;
  const useNewLineage = organization?.settings?.useNewLineage && user?.settings?.enableNewLineage;
  const newLineageSearchParam = history.location.search
    ? `${history.location.search}&version=v0`
    : '?version=v0';

  const tabsRouterConfig: TabRoutesConfig[] = [
    ...(sourceTables
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => <SourceTablesTab guid={guid} />,
            contentOffset: 2,
            default: sourceTables,
            label: `Source Tables ${wrapString(sourceTablesCount)}`,
            route: '/source-tables',
          },
        ]
      : []),
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <UpstreamDownstreamTab
          assetName={itemMetadata?.name ?? ''}
          direction="left"
          nodeKey={guid}
          requestParamsExtraProps={{ enable_description: true }}
          tableOrColumnId={guid}
        />
      ),
      default: !sourceTables,
      label: `Upstream ${wrapString(upstreamCount)}`,
      route: '/upstream',
    },
    ...(showDownstream
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => (
              <UpstreamDownstreamTab
                assetName={itemMetadata?.name ?? ''}
                direction="right"
                nodeKey={guid}
                requestParamsExtraProps={{ enable_description: true }}
                tableOrColumnId={guid}
              />
            ),
            label: `Downstream ${wrapString(downstreamCount)}`,
            route: '/downstream',
          },
        ]
      : []),
    ...(showDownstreamDashboards
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => (
              <RelatedDashboards
                assetName={itemMetadata?.name ?? ''}
                dataTypes={itemMetadata?.dataTypes}
                guid={guid}
                objectType={objectType}
                stickyHeader
              />
            ),
            label: `Downstream Dashboards ${wrapString(dashboardsCount)}`,
            route: '/related-dashboards',
          },
        ]
      : []),
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <ExploreTab
          dataSourceType={dataSourceType}
          downstreamCount={downstreamCount}
          guid={guid}
          showModeLineage={showModeLineage}
          upstreamCount={upstreamCount}
        />
      ),
      label: (
        <Box alignItems="center" compDisplay="flex" gap={0.5}>
          Explore <Icon color="currentColor" name="open-new-tab" size="14px" />
        </Box>
      ),
      openInNewTab: Boolean(process.env.REACT_APP_CHROME_EXTENSION_BUILD),
      route: '/explore',
      search: useNewLineage ? newLineageSearchParam : undefined,
      variant: 'filled' as TabContentProps['variant'],
    },
  ];

  return <TabsRouter config={tabsRouterConfig} sticky={stickyHeader} subTabs />;
};

export default React.memo<TabbedLineageProps>(TabbedLineage);
