import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useFetchBillingCustomer } from '@api/billing';
import { GridContainer } from '@components/Grid';
import PageHeaderBar from '@components/PageHeaderBar';
import type { TabRoutesConfig } from '@components/TabsRouter';
import TabsRouter from '@components/TabsRouter';
import Icon from '@components/UI/Icon';
import flags from '@features';
import MetadataDecorator from '@utils/MetadataDecorator';

import CurrentPlanTab from './Tabs/CurrentPlanTab';
import CustomerPortalTab from './Tabs/CustomerPortalTab';

const stripePromise = loadStripe(window?.env?.STRIPE_PUBLIC_KEY);

const AdminBillingPage: React.FC = () => {
  const { data: billingCustomer, isLoading } = useFetchBillingCustomer();

  if (!flags.billing) return null;

  const tabsRouterConfig: TabRoutesConfig[] = [
    {
      component: () => <CurrentPlanTab billingCustomer={billingCustomer} />,
      default: true,
      label: 'Current Plan',
      route: '/current-plan',
    },
    ...(billingCustomer?.stripePriceId
      ? [
          {
            component: () => <CustomerPortalTab />,
            label: (
              <>
                Billing Details <Icon color="currentColor" name="copy" size="14px" />
              </>
            ),
            route: '/billing-details',
          },
        ]
      : []),
  ];

  return (
    <Elements stripe={stripePromise}>
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <MetadataDecorator title="Billing Admin" />
        <PageHeaderBar backgroundColor="white" minHeight="90px" sticky title="Billing" />
        <TabsRouter config={tabsRouterConfig} isLoading={isLoading} />
      </GridContainer>
    </Elements>
  );
};

export default AdminBillingPage;
