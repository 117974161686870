import { Expose } from 'class-transformer';

export interface OrganizationBillingDataData {
  dataSourceCount: number;
  guid: string;
  name: string;
  tableCount: number;
  userCount: number;
}

export class OrganizationBillingDataModel {
  static objectType: string = 'organizationBillingData';

  objectType: string = OrganizationBillingDataModel.objectType;

  static typeDisplay: string = 'Organization Billing Data';

  typeDisplay: string = OrganizationBillingDataModel.typeDisplay;

  guid!: string;

  name!: string;

  @Expose({ name: 'user_count' })
  userCount!: number;

  @Expose({ name: 'table_count' })
  tableCount!: number;

  @Expose({ name: 'data_source_count' })
  dataSourceCount!: number;
}
