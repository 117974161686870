import styled from '@emotion/styled';

import { GridContainer } from '@components/Grid';

export const StyledGridContainer = styled(GridContainer)`
  height: auto;
  ${({ theme }) => theme.media.down('md')`
     padding: ${theme.space(2)}
  `}
`;
