import React from 'react';

import Box from '@components/Box';
import theme from '@styles/theme';

import { EditableHeaderHiddenText, EditableHeaderInput } from './EditableHeader.styles';

interface EditableHeaderProps {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  onSave?: () => void;
  placeholder?: string;
  value?: string;
}

const EditableHeader: React.FC<EditableHeaderProps> = ({
  autoFocus,
  className,
  disabled,
  onChange,
  onSave,
  placeholder,
  value,
}) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSave?.();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value);
  };

  return (
    <Box
      as="span"
      className={className}
      compDisplay="inline-flex"
      compHeight="100%"
      minHeight="100%"
      minWidth="5rem"
      overflow="hidden"
      position="relative"
    >
      <EditableHeaderHiddenText>{value?.replace(/ /g, '\u00a0')}</EditableHeaderHiddenText>
      <EditableHeaderInput
        autoFocus={autoFocus}
        color={value === 'Untitled' ? theme.colors.gray[400] : undefined}
        data-testid="editable-header-input"
        disabled={disabled}
        maxLength={255}
        onBlur={onSave}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={value}
      />
    </Box>
  );
};

export default EditableHeader;
