import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { UserData, UserModel } from '@api/user/UserModel';
import { MetadataData, MetadataModel } from '@models/MetadataModel';

import { ActivityMessageModel } from '../activity/ActivityMessageModel';

export interface NotificationData {
  actionObject?: MetadataData;
  actor: UserData;
  description?: string;
  id: number;
  recipient: UserData;
  target: MetadataData;
  timestamp: moment.Moment;
  unread: boolean;
  verb: string;
}

export enum NotificationType {
  system = 'system',
  user = 'user',
}
export class NotificationModel {
  static objectType: string = 'notification';

  objectType: string = NotificationModel.objectType;

  static typeDisplay: string = 'Notification';

  typeDisplay: string = NotificationModel.typeDisplay;

  id!: number;

  @Type(() => UserModel)
  actor!: UserModel;

  @Type(() => UserModel)
  recipient!: UserModel;

  verb!: string;

  unread!: boolean;

  @Type(() => MetadataModel)
  target!: MetadataModel;

  @Transform((value) => moment(value))
  timestamp!: moment.Moment;

  @Type(() => MetadataModel)
  @Expose({ name: 'action_object' })
  actionObject?: MetadataModel;

  description?: string;

  /**
   * System notifications will have a message field
   * coming from the BE copied from activity model
   * so we can use the same format of message
   */
  @Type(() => ActivityMessageModel)
  message?: ActivityMessageModel;

  @Expose({ name: 'notification_type' })
  notificationType: NotificationType = NotificationType.user;
}
