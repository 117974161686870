import React, { useMemo, useState } from 'react';
import type { Filter } from '@utils';
import { Cell } from 'react-table';

import type { BiNotebookModel } from '@api/biNotebooks/biNotebookModel.v1';
import DateTime from '@components/DateTime';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import EditableTaggedItemCell from '@components/Table/Cells/EditableTaggedItemCell';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import RelatedObjectsCountCell from '@components/Table/Cells/RelatedObjectsCountsCell';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import type { TableProps } from '@components/Table/Table';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import Tooltip from '@components/Tooltip';
import type { PaginatedResponse } from '@models/PaginatedResponse';
import type { FilterOptions } from '@utils/filters';

enum ColumnKey {
  createdAt = 'createdAt',
  description = 'description',
  downstreamObjectsCounts = 'downstreamObjectsCounts',
  lastRun = 'lastRun',
  name = 'name',
  search = 'search',
  tags = 'tags',
  updatedAt = 'updatedAt',
  upstreamObjectsCounts = 'upstreamObjectsCounts',
}

const DEFAULT_COLUMNS = [
  ColumnKey.search,
  ColumnKey.name,
  ColumnKey.description,
  ColumnKey.tags,
  ColumnKey.createdAt,
  ColumnKey.downstreamObjectsCounts,
  ColumnKey.upstreamObjectsCounts,
  ColumnKey.updatedAt,
  ColumnKey.lastRun,
];

export const BI_NOTEBOOKS_TABLE_SEARCH_CONFIG: PartialRecord<ColumnKey, keyof FilterOptions> = {
  [ColumnKey.description]: 'search_description',
  [ColumnKey.name]: 'search_name',
  [ColumnKey.tags]: 'search_tags',
};

export const BI_NOTEBOOKS_TABLE_SORT_CONFIG = {
  [ColumnKey.description]: 'description',
  [ColumnKey.name]: 'name',
  [ColumnKey.createdAt]: 'external_created_on',
  [ColumnKey.downstreamObjectsCounts]: 'downstream_objects_total',
  [ColumnKey.upstreamObjectsCounts]: 'upstream_objects_total',
  [ColumnKey.updatedAt]: 'external_updated_on',
  [ColumnKey.lastRun]: 'last_run',
};

export interface BiNotebooksTableProps extends Pick<TableProps, 'selectedRowIds'> {
  data?: Pick<PaginatedResponse<BiNotebookModel>, 'count' | 'results'>;
  filterService: Filter.FilterServiceInterface;
  isDataSourceEditable: boolean;
  isLoading?: boolean;
  toggleAll?: (checked: boolean) => void;
  toggleItem?: (item: BiNotebookModel, checked: boolean) => void;
  visibleColumns?: Array<ColumnKey>;
}

const BiNotebooksTable: React.FC<BiNotebooksTableProps> = ({
  data,
  filterService,
  isDataSourceEditable,
  isLoading,
  selectedRowIds,
  toggleAll,
  toggleItem,
  visibleColumns = DEFAULT_COLUMNS,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const { changePage, filter, initialTableSortState, search, sort } = filterService;

  const columns = useMemo(() => {
    const all: Record<ColumnKey, ColumnConfig<BiNotebookModel>> = {
      [ColumnKey.createdAt]: {
        Cell: ({ row: { original } }: Cell<BiNotebookModel>) => (
          <DateTime datetime={original.createdAt} />
        ),
        Header: 'Created',
        accessor: (d) => (d?.createdAt?.isValid() ? d.createdAt.toDate() : 'unknown'),
        disableFilters: true,
        id: ColumnKey.createdAt,
        sortDescFirst: true,
        width: '115%',
      },
      [ColumnKey.description]: {
        Cell: ({ column, row: { original }, state }: Cell<BiNotebookModel>) => (
          <DescriptionCell
            {...original}
            column={column}
            dataSourceType={original.dataTypes?.dataSourceType}
            isDataSourceEditable={isDataSourceEditable}
            state={state}
          />
        ),
        Header: 'Description',
        accessor: ColumnKey.description,
        id: ColumnKey.description,
        width: '150%',
      },
      [ColumnKey.downstreamObjectsCounts]: {
        Cell: ({ row: { original } }: Cell<BiNotebookModel>) => (
          <RelatedObjectsCountCell counts={original.downstreamObjectsCounts} />
        ),
        Header: (
          <Tooltip content="Count of downstream tables and dashboards from data lineage">
            <span>Downstream</span>
          </Tooltip>
        ),
        accessor: (d) => d.downstreamObjectsCounts?.total,
        disableFilters: true,
        dropdownCheckboxLabel: 'Upstream',
        id: ColumnKey.downstreamObjectsCounts,
        sortDescFirst: true,
        width: 100,
      },
      [ColumnKey.name]: {
        Cell: ({ column, row: { original }, state }: Cell<BiNotebookModel>) => (
          <LinkedCell
            column={column}
            customUrl={original.routePath}
            item={original}
            showIcon
            state={state}
          />
        ),
        Header: `Name (${data?.count ?? 0})`,
        accessor: ColumnKey.name,
        disableHiding: true,
        id: ColumnKey.name,
        width: '130%',
      },
      [ColumnKey.search]: {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: ColumnKey.search,
        width: 32,
      },
      [ColumnKey.tags]: {
        Cell: ({ row: { original } }: Cell<BiNotebookModel>) => (
          <EditableTaggedItemCell isDataSourceEditable={isDataSourceEditable} obj={original} />
        ),
        Header: 'Tags',
        accessor: 'taggedItems',
        disableSortBy: true,
        id: ColumnKey.tags,
        width: '130%',
      },
      [ColumnKey.updatedAt]: {
        Cell: ({ row: { original } }: Cell<BiNotebookModel>) => (
          <DateTime datetime={original.updatedAt} />
        ),
        Header: 'Last Modified',
        accessor: ColumnKey.updatedAt,
        disableFilters: true,
        id: ColumnKey.updatedAt,
        sortDescFirst: true,
        width: '115%',
      },
      [ColumnKey.lastRun]: {
        Cell: ({ row: { original } }: Cell<BiNotebookModel>) => (
          <DateTime datetime={original.lastRun} />
        ),
        Header: 'Last Run',
        accessor: (d: Partial<BiNotebookModel>) => d?.lastRun?.toDate() ?? 'N/A',
        disableFilters: true,
        id: 'lastRun',
        width: '115%',
      },
      [ColumnKey.upstreamObjectsCounts]: {
        Cell: ({ row: { original } }: Cell<BiNotebookModel>) => (
          <RelatedObjectsCountCell counts={original.upstreamObjectsCounts} />
        ),
        Header: (
          <Tooltip content="Count of upstream objects from data lineage">
            <span>Upstream</span>
          </Tooltip>
        ),
        accessor: (d) => d.upstreamObjectsCounts?.total,
        disableFilters: true,
        dropdownCheckboxLabel: 'Upstream',
        id: ColumnKey.upstreamObjectsCounts,
        sortDescFirst: true,
        width: 100,
      },
    };

    return visibleColumns.map((col) => all[col]);
  }, [data?.count, isDataSourceEditable, visibleColumns]);

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        columns={columns}
        compact
        data={data?.results ?? []}
        initialState={{
          hiddenColumns: [ColumnKey.updatedAt, ColumnKey.lastRun],
          pageIndex: filter.page ? filter.page - 1 : 0,
          ...(selectedRowIds && { selectedRowIds }),
          sortBy: initialTableSortState,
        }}
        loading={isLoading}
        manualFilters
        manualPagination
        manualSortBy
        rangeSelectConfig={{
          onCellCheckChange: toggleItem,
          onHeaderCheckChange: toggleAll,
        }}
        setFilters={search}
        setSortBy={sort}
        showFilter={showFilter}
        singleLine
        sortable
        stickyHeader
        toggleFilter={() => setShowFilter((prev) => !prev)}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default React.memo(BiNotebooksTable);
