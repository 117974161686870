import { Expose } from 'class-transformer';

import { DbtTypes } from './types';

class DbtProjectModel {
  guid!: string;

  @Expose({ name: 'data_source' })
  dataSource!: string;

  dialect!: string;

  @Expose({ name: 'target_data_source' })
  targetDataSource!: string;

  @Expose({ name: 'sync_docs_with_target' })
  syncDocsWithTarget!: boolean;

  @Expose({ name: 'show_new_data_source' })
  showNewDataSource!: boolean;

  @Expose({ name: 'dbt_type' })
  type!: DbtTypes;
}

export default DbtProjectModel;
