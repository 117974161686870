import styled from '@emotion/styled';

export const StyledFormWrapper = styled.div`
  .ui.form {
    .field {
      width: 100%;
      > label {
        display: inline-block;
        min-width: 4rem;
      }
      .prompt {
        margin-left: 4rem;
      }
      .ui.input,
      .ui.selection.dropdown {
        width: calc(100% - 5rem);
      }
      .ui.input input {
        font-size: 0.9375rem;
        padding: 0.5rem 1rem;
      }
    }
    .error {
      .ui.dropdown {
        background: #fff6f6 !important;
        .text,
        .default.text {
          color: #9f3a38;
        }
      }
    }
  }
`;
