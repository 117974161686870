import { DataSourceTypesType } from '@models/DataSourceCredentials';

import { NodeColor } from './types';

export const SIZES = {
  borderWidth: {
    column: 0.5,
    columnLeaf: 5,
    database: 2,
    initialNode: 2,
    integration: 1,
    table: 1,
    tableLeaf: 7,
  },
  columnGap: {
    columnLevelStack: 65,
    tableLevelStack: 65,
  },
  contentMargin: {
    database: 6,
    schema: 6,
    showMoreButton: 4,
    table: 12,
  },
  height: {
    column: 24,
    columnsSearchBar: 22,
    database: 38,
    noMatchedDataBadge: 28,
    schema: 30,
    showMoreButton: 24,
    table: 38,
  },
  openedContentHeight: {
    database: 34,
    schema: 28,
  },
  paddingBottom: {
    bi: 10,
    database: 6,
    schema: 4,
    table: 6,
  },
  paddingLeft: {
    bi: 8,
    column: 4,
    database: 8,
    schema: 12,
    table: 6,
  },
  paddingRight: {
    bi: 8,
    column: 4,
    database: 8,
    schema: 12,
    table: 6,
  },
  paddingTop: {
    bi: 10,
    database: 6,
    schema: 4,
    table: 6,
  },
  rowGap: {
    column: 0,
    database: 30,
    schema: 8,
    table: 8,
  },
  width: {
    bi: 304,
    biColumn: 269,
    column: 246,
    database: 304,
    schema: 286,
    table: 262,
  },
};

export const NODE_COLORS: PartialRecord<DataSourceTypesType, NodeColor> = {
  bigquery: {
    primary: '#5086EC',
    secondary: '#F6F7FB',
  },
  data_studio: {
    primary: '#1A73E8',
    secondary: '#EEF6FC',
  },
  databricks: {
    primary: '#475467',
    secondary: '#F2F5F9',
  },
  dbt: {
    primary: '#FB6514',
    secondary: '#FFF6ED',
  },
  glue: {
    primary: '#FB6514',
    secondary: '#FFF6ED',
  },
  looker: {
    primary: '#33059F',
    secondary: '#F9F8FB',
  },
  metabase: {
    primary: '#227DE1',
    secondary: '#EEF6FC',
  },
  mode: {
    primary: '#1D5243',
    secondary: '#EEF2E3',
  },
  mssql: {
    primary: '#EA1B22',
    secondary: '#EFF2F3',
  },
  mysql: {
    primary: '#00546B',
    secondary: '#FFF6ED',
  },
  oracle: {
    primary: '#EA1B22',
    secondary: '#EFF2F3',
  },
  periscope: {
    primary: '#F79009',
    secondary: '#FEF0C7',
  },
  postgres: {
    primary: '#336791',
    secondary: '#EFF2F3',
  },
  power_bi: {
    primary: '#F79009',
    secondary: '#FEF0C7',
  },
  quicksight: {
    primary: '#00B7F4',
    secondary: '#EEF6FC',
  },
  redshift: {
    primary: '#205B99',
    secondary: '#F6F7FB',
  },
  salesforce: {
    primary: '#00A1E0',
    secondary: '#CFECFF',
  },
  sigma: {
    primary: '#101828',
    secondary: '#F1FFFD',
  },
  snowflake: {
    primary: '#29B5E8',
    secondary: '#E7F6FD',
  },
  tableau: {
    primary: '#2A5875',
    secondary: '#F5F5F5',
  },
  thoughtspot: {
    primary: '#4E55FD',
    secondary: '#E8EFFD',
  },
};

export const REACT_FLOW_CONTAINER_SELECTOR = '.react-flow__container';
