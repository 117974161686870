import React from 'react';
import type { LinkProps } from 'react-router-dom';

import AnalyticsUserModel from '@api/analytics/AnalyticsUserModel';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import { TeamModel } from '@api/teams/TeamModel';
import { UserModel } from '@api/user/UserModel';
import Box from '@components/Box';
import Highlighter from '@components/Highlighter';
import Tooltip from '@components/Tooltip';
import { DeletedModel } from '@models/DeletedModel';
import { OrganizationTeamModel } from '@models/OrganizationTeamModel';
import { urlFor } from '@utils/routing';

import { PageText, StyledLink } from './components';

interface Props {
  bold?: boolean;
  className?: string;
  color?: string;
  item:
    | UserModel
    | DsUserModel
    | TeamModel
    | OrganizationTeamModel
    | AnalyticsUserModel
    | DeletedModel;
  lowLine?: boolean;
  searchTermsToHighlight?: string;
  to?: LinkProps['to'];
  underline?: boolean;
}

const PageLink: React.FC<Props> = ({
  bold,
  className,
  color,
  item,
  lowLine,
  searchTermsToHighlight,
  to,
  underline,
}) => {
  const teamName = (item as UserModel)?.team?.name;
  const text = item.fullName;
  const toolTipText =
    teamName && item.fullName ? `${item.fullName}, ${teamName}` : item.fullName || teamName;
  const url = urlFor(item, true);

  if (url === '#' && !to) {
    return (
      <Box compWidth="100%" overflow="hidden" textOverflow="ellipsis">
        <PageText bold={bold} className={className} color={color}>
          <Highlighter
            autoEscape
            searchWords={searchTermsToHighlight?.split(/[.| ]+/) || []}
            textToHighlight={text || ''}
          />
        </PageText>
      </Box>
    );
  }

  return (
    <StyledLink className={className} lowLine={lowLine} to={to ?? url} underline={underline}>
      <Tooltip content={toolTipText}>
        <PageText bold={bold} color={color}>
          <Highlighter
            autoEscape
            searchWords={searchTermsToHighlight?.split(/[.| ]+/) || []}
            textToHighlight={text || ''}
          />
        </PageText>
      </Tooltip>
    </StyledLink>
  );
};

export default PageLink;
