import React, { useRef } from 'react';

import { DataSourcesMutatePayload } from '@api/dataSources';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { DataSourceCredentials } from '@models/DataSourceCredentials';

import useDataSourceMutation from '../useDataSourceMutation';

import { DataSourceFormProps } from './types';

const MetabaseForm: React.FC<DataSourceFormProps> = ({
  children,
  dataSource,
  dataType,
  name = '',
  onSuccess,
  renderBefore,
}) => {
  const segment = useSegmentContext();
  const lastCredentials = useRef<DataSourceCredentials | undefined>();

  const { error, isLoading, mutate } = useDataSourceMutation({
    dataSource,
    onSuccess: (ds) => {
      onSuccess({ ...ds, credentials: lastCredentials.current });
    },
  });

  const { handleChange, handleSubmit, values } = useForm({
    initialValues: {
      baseUrl: '',
      name: dataSource?.name || name,
      password: '',
      username: '',
    },
    onSubmit: (val) => {
      const payload = {} as DataSourcesMutatePayload;
      if (dataSource) {
        payload.guid = dataSource.guid;
        payload.type = dataSource.type;
      } else {
        payload.type = dataType;
      }
      payload.name = val.name;
      const newCredentials = {
        base_url: val.baseUrl,
        password: val.password,
        username: val.username,
      };

      payload.credentials = newCredentials;
      lastCredentials.current = newCredentials;
      mutate(payload);

      segment?.track(SegmentTrackEventName.CreateServiceAccountConnectButtonClicked, { dataType });
    },
  });

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        {renderBefore?.({ error, loading: isLoading })}
        <StyledLabel>
          Display Name
          <Input
            error={error?.data?.name}
            helperText={error?.data?.name}
            maxLength={50}
            name="name"
            onChange={handleChange}
            placeholder="Metabase"
            type="text"
            value={values.name}
          />
        </StyledLabel>
        <StyledLabel>
          Url
          <Input
            error={error?.data?.base_url}
            helperText={error?.data?.base_url}
            name="baseUrl"
            onChange={handleChange}
            placeholder="https://example.metabaseapp.com"
            type="text"
            value={values.baseUrl}
          />
        </StyledLabel>
        <StyledLabel>
          Username
          <Input
            error={error?.data?.username}
            helperText={error?.data?.username}
            name="username"
            onChange={handleChange}
            placeholder="Username"
            type="text"
            value={values.username}
          />
        </StyledLabel>
        <StyledLabel>
          Password
          <Input
            error={error?.data?.password}
            helperText={error?.data?.password}
            name="password"
            onChange={handleChange}
            placeholder="Password"
            type="password"
            value={values.password}
          />
        </StyledLabel>
      </StyledFormHorizontalLabelGrid>
      {children?.({ error, loading: isLoading })}
    </Form>
  );
};

export default MetabaseForm;
