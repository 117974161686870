import React, { useEffect, useState } from 'react';

import {
  CustomAttributeDataSourceType,
  CustomAttributeModel,
} from '@api/customAttributes/CustomAttributeModel';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { renderErrorToast } from '@components/Toast';
import InputLabel from '@components/UI/Form/InputLabel';
import { ModalContent, ModalFooter } from '@components/UI/Modal';
import Select from '@components/UI/Select.v1/Select';
import { Option, SelectValue } from '@components/UI/Select.v1/types';
import { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import CustomAttributesAssets from '../CustomAttributesAssets';
import { AssetsMap } from '../CustomAttributesAssets/CustomAttributesAssets';

interface CustomAttributeFormProps {
  error: EnhancedErrorResult | null;
  initialData?: CustomAttributeModel;
  isError: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (values: FormValues) => void;
}

export interface FormValues {
  attributeName: string;
  selectedAssets: AssetsMap;
  type: SelectValue;
}

const TYPE_OPTIONS: Option[] = [
  {
    icon: 'attribute-text',
    text: 'Text',
    value: 'text',
  },
];

const CONTAINER_ID = 'add-custom-attribute-modal-content';

const buildSelectedAssets = (initialData: CustomAttributeModel): AssetsMap => {
  const selectedAssets: {
    [key in CustomAttributeDataSourceType]?: string[];
  } = {};
  initialData.dataSourceTypes.forEach((dataSource, index) => {
    if (!selectedAssets[dataSource]) {
      Object.assign(selectedAssets, { [dataSource]: [] });
    }
    selectedAssets[dataSource]?.push(
      `${initialData.objectTypes[index]},${initialData.dataTypes[index] ?? ''}`,
    );
  });

  return selectedAssets;
};

const CustomAttributeForm: React.FC<CustomAttributeFormProps> = ({
  error,
  initialData,
  isError,
  isLoading,
  onClose,
  onSubmit,
}) => {
  const [initialAssets] = useState<AssetsMap | undefined>(
    initialData ? buildSelectedAssets(initialData) : undefined,
  );
  const { handleChange, handleSubmit, setValues, values } = useForm<FormValues>({
    initialValues: {
      attributeName: initialData?.name || '',
      selectedAssets: initialData?.dataSourceTypes ? buildSelectedAssets(initialData) : {},
      type: [TYPE_OPTIONS.find((type) => type.value === initialData?.type) ?? TYPE_OPTIONS[0]],
    },
    onSubmit: (submitValues) => {
      onSubmit(submitValues);
    },
  });

  const handleAssetsChange = (assets: AssetsMap) => {
    setValues((prevValues) => ({ ...prevValues, selectedAssets: assets }));
  };

  useEffect(() => {
    if (!isLoading && isError) {
      if (error?.data.data_source_types) {
        renderErrorToast('Select at least one asset type.');
      } else {
        renderErrorToast('There was an error adding the custom attribute.');
      }
    }
  }, [error?.data, isError, isLoading]);

  const hasAssetSelected = Object.values(values.selectedAssets).filter(
    (dataSourceAssets) => dataSourceAssets.length,
  ).length;

  const isFormValid = values.attributeName && values.type && hasAssetSelected;

  const handleTypeChange = (newValue: SelectValue) => {
    setValues((prevValues) => ({
      ...prevValues,
      type: newValue as Option[],
    }));
  };

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <ModalContent id={CONTAINER_ID} py={2.5}>
        <Box compDisplay="flex" flexDirection="column" gap={2}>
          <Box
            compDisplay="grid"
            compWidth="100%"
            gap={2}
            gridTemplateColumns="repeat(2, 1fr)"
            minWidth={0}
          >
            <InputLabel
              alignItems="flex-start"
              color="gray.700"
              compDisplay="flex"
              flexDirection="column"
              fontWeight="medium"
              minWidth={0}
            >
              Attribute Name
              <Input
                compWidth="100%"
                error={Boolean(error?.data.name)}
                helperText={error?.data.name}
                name="attributeName"
                onChange={handleChange}
                placeholder="Attribute Name"
                type="text"
                value={values.attributeName}
              />
            </InputLabel>
            <Box>
              <InputLabel
                as="div"
                color="gray.700"
                compDisplay="grid"
                cursor="default"
                fontWeight="medium"
              >
                Attribute Type
                <Select
                  containerId={CONTAINER_ID}
                  error={Boolean(error?.data.type)}
                  helperText={error?.data.type}
                  onChange={handleTypeChange}
                  options={TYPE_OPTIONS}
                  value={values.type}
                />
              </InputLabel>
            </Box>
          </Box>
          <Box compDisplay="flex" flexDirection="column" gap={2}>
            <InputLabel
              as="div"
              color="gray.700"
              compDisplay="grid"
              cursor="default"
              fontWeight="medium"
            >
              Select Asset Type to apply
            </InputLabel>
            <CustomAttributesAssets
              initialAssets={initialAssets}
              onAssetsChange={handleAssetsChange}
            />
          </Box>
        </Box>
      </ModalContent>
      <ModalFooter>
        <Button onClick={onClose} type="button" variant="outlined">
          Cancel
        </Button>
        <Button disabled={!isFormValid} type="submit">
          {initialData ? 'Save' : 'Add Attribute'}
        </Button>
      </ModalFooter>
    </Form>
  );
};

export default CustomAttributeForm;
