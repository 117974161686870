export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type Breakpoints<T = number> = Record<Breakpoint, T>;

const breakpoints: Breakpoints = {
  lg: 992,
  md: 768,
  sm: 576,
  xl: 1200,
  xs: 0,
};

export default breakpoints;
