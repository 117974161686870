import React from 'react';
import { useParams } from 'react-router-dom';

import { useFetchDsUser } from '@api/dsusers';
import CircularLoader from '@components/CircularLoader';
import NotFoundError from '@components/Error/NotFoundError';
import { GridContainer } from '@components/Grid';

import BIDSUserPage from './BIDSUserPage';
import DSUserPage from './DSUserPage';

const DSUserPageRouted: React.FC = () => {
  const { guid } = useParams<{ guid: string }>();
  const { data, isError, isLoading } = useFetchDsUser(guid, {
    enabled: Boolean(guid),
  });

  if (!guid || isError) {
    return <NotFoundError />;
  }

  if (!data || isLoading) {
    return (
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <CircularLoader centered cover />
      </GridContainer>
    );
  }

  if (
    ['mode', 'looker', 'tableau', 'periscope', 'sigma', 'metabase', 'data_studio'].includes(
      data?.dataSource?.type || '',
    )
  ) {
    return <BIDSUserPage dataSourceType={data?.dataSource?.type} dsuser={data} guid={guid} />;
  }

  return <DSUserPage dsuser={data} guid={guid} />;
};

export default DSUserPageRouted;
