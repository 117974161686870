import React from 'react';
import { useHistory } from 'react-router-dom';

import { AcceptTOS } from '@components/TermsOfService';
import TwoColumnDecorativeComponent from '@components/TwoColumnDecorativeComponent';
import TwoColumnLayout from '@components/TwoColumnLayout';
import MetadataDecorator from '@utils/MetadataDecorator';

const TOSPage: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <MetadataDecorator title="Sign Up" />
      <TwoColumnLayout
        left={<AcceptTOS onSuccess={() => history.push('/')} />}
        right={<TwoColumnDecorativeComponent />}
      />
    </>
  );
};

export default TOSPage;
