import React, { useEffect, useState } from 'react';

import { DataSourcesMutatePayload, useFetchDataSources } from '@api/dataSources';
import { useFetchDsUsers, usePatchDsUser } from '@api/dsusers';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Dropdown from '@components/Dropdown';
import {
  getDatasourceOptions,
  getDsusersOptions,
  getStringOptions,
} from '@components/Dropdown/helpers';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { isWarehouseType } from '@models/DataSourceCredentials';
import { DataSourceModel } from '@models/DataSourceModel';

import useDataSourceMutation from '../useDataSourceMutation';

import { DataSourceFormProps } from './types';

const SigmaForm: React.FC<DataSourceFormProps> = ({
  children,
  dataSource,
  dataType,
  name = '',
  onSuccess,
  renderBefore,
}) => {
  const segment = useSegmentContext();
  const [datasourceConnections, setDatasourceConnections] = useState<DataSourceModel[]>([]);
  const [selectedDSConnection, setSelectedDSConnection] = useState<string>();
  const [selectedCloudProvider, setSelectedCloudProvider] = useState<string>();
  const [dsUsers, setDsUsers] = useState<DsUserModel[]>([]);
  const [selectedDsUser, setSelectedDsUser] = useState<string>();
  const [dsUserConfig, setDsUserConfig] = useState<{}>({});

  const { mutate: updateDsUser } = usePatchDsUser(selectedDsUser || '');

  const { error, isLoading, mutate } = useDataSourceMutation({
    dataSource,
    onSuccess: (ds) => {
      onSuccess(ds);
      if (selectedDsUser) {
        updateDsUser({ data: { is_sigma_service_account: true } });
      }
    },
  });

  const {
    data: dataSourcesResponse,
    error: dataSourcesError,
    isLoading: isLoadingDataSources,
  } = useFetchDataSources();

  const {
    data: dsUserResponse,
    error: dsUsersError,
    isLoading: loadingDsUsers,
  } = useFetchDsUsers({
    ...dsUserConfig,
  });

  const { handleChange, handleSubmit, values } = useForm({
    initialValues: {
      apiServer: '',
      apiToken: '',
      clientId: '',
      name: dataSource?.name || name,
    },
    onSubmit: (val) => {
      const payload = {} as DataSourcesMutatePayload;
      if (dataSource) {
        payload.guid = dataSource.guid;
        payload.type = dataSource.type;
      } else {
        payload.type = dataType;
      }
      payload.name = val.name;
      payload.credentials = {
        api_server: selectedCloudProvider!,
        api_token: val.apiToken,
        client_id: val.clientId,
      };
      mutate(payload);

      segment?.track(SegmentTrackEventName.CreateServiceAccountConnectButtonClicked, { dataType });
    },
  });

  useEffect(() => {
    if (selectedDSConnection) {
      setDsUserConfig({
        params: {
          datasources: selectedDSConnection,
        },
      });
    }
  }, [selectedDSConnection]);

  useEffect(() => {
    if (dataSourcesResponse) {
      setDatasourceConnections(
        dataSourcesResponse.results.filter((ds: DataSourceModel) => isWarehouseType(ds.type)),
      );
    }
  }, [dataSourcesResponse]);

  useEffect(() => {
    if (selectedDSConnection && dsUserResponse) {
      setDsUsers(dsUserResponse.results);
    }
  }, [dsUserResponse]);

  const handleDatasourceSelection = (_e: React.SyntheticEvent, { value }: any) => {
    setSelectedDSConnection(value);
    if (!value) {
      setSelectedDsUser('');
    }
  };

  const handleCloudProviderSelection = (_e: React.SyntheticEvent, { value }: any) => {
    setSelectedCloudProvider(value);
    if (!value) {
      setSelectedCloudProvider('');
    }
  };

  const handleDsUserSelection = (_e: React.SyntheticEvent, { value }: any) => {
    setSelectedDsUser(value);
  };

  const dataSourceOptions = getDatasourceOptions(datasourceConnections);
  const userOptions = getDsusersOptions(dsUsers);

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        {renderBefore?.({ error, loading: isLoading })}
        <StyledLabel>
          Display Name
          <Input
            error={error?.data?.name}
            helperText={error?.data?.name}
            maxLength={50}
            name="name"
            onChange={handleChange}
            placeholder="Sigma"
            type="text"
            value={values.name}
          />
        </StyledLabel>
        <StyledLabel>
          Client ID
          <Input
            error={error?.data?.client_id}
            helperText={error?.data?.client_id}
            name="clientId"
            onChange={handleChange}
            placeholder="Client ID"
            type="text"
            value={values.clientId}
          />
        </StyledLabel>
        <StyledLabel>
          Client API Token
          <Input
            error={error?.data?.api_token}
            helperText={error?.data?.api_token}
            name="apiToken"
            onChange={handleChange}
            placeholder="Client API Token"
            type="password"
            value={values.apiToken}
          />
        </StyledLabel>
        <StyledLabel as="div">
          API Server
          <Dropdown
            clearable
            onChange={handleCloudProviderSelection}
            options={getStringOptions([
              'https://api.sigmacomputing.com/v2',
              'https://aws-api.sigmacomputing.com/v2',
            ])}
            search
            selection
            selectOnNavigation={false}
            upward
            value={selectedCloudProvider}
          />
        </StyledLabel>
        {datasourceConnections.length > 0 && (
          <>
            <StyledLabel as="div">
              DB Connection
              <Dropdown
                clearable
                error={Boolean(dataSourcesError)}
                loading={isLoadingDataSources}
                onChange={handleDatasourceSelection}
                options={dataSourceOptions}
                search
                selection
                selectOnBlur={false}
                selectOnNavigation={false}
                upward
                value={selectedDSConnection}
              />
            </StyledLabel>
            <StyledLabel as="div">
              DB Username
              <Dropdown
                clearable
                disabled={!selectedDSConnection && !selectedDsUser}
                error={Boolean(dsUsersError)}
                loading={loadingDsUsers}
                onChange={handleDsUserSelection}
                options={userOptions}
                placeholder={!selectedDSConnection ? 'Please select a DB connection first.' : ''}
                search
                selection
                selectOnBlur={false}
                selectOnNavigation={false}
                upward
                value={selectedDsUser}
              />
            </StyledLabel>
          </>
        )}
      </StyledFormHorizontalLabelGrid>
      {children?.({ error, loading: isLoading })}
    </Form>
  );
};

export default SigmaForm;
