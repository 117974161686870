import React, { forwardRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import NotApplicable from '@components/NotApplicable';
import type { TooltipProps } from '@components/Tooltip';
import Tooltip from '@components/Tooltip';
import type DataTypesModel from '@models/DataTypesModel';

import type { StyledPopularityProps } from './PopularityStyle';
import { StyledPopularity, StyledPopularityItem } from './PopularityStyle';

const priorityColor = [
  { color: '#e2e9f0', id: 1 },
  { color: '#e2e9f0', id: 2 },
  { color: '#7490be', id: 3 },
  { color: '#7490be', id: 4 },
  { color: '#0c3476', id: 5 },
  { color: '#0c3476', id: 6 },
];
export const NO_POPULARITY_OBJECTS = ['document', 'metric', 'term', 'tag'];
export interface PopularityProps extends StyledPopularityProps {
  className?: string;
  dataTypes?: DataTypesModel;
  priority?: number;
  text?: TooltipProps['content'];
  topUsersLink?: boolean;
}

const Popularity = forwardRef<HTMLSpanElement, PopularityProps>(
  ({ className, compWidth, dataTypes, priority, text, topUsersLink, ...other }, ref) => {
    const history = useHistory();
    const { url } = useRouteMatch();

    const handleClick = () => {
      if (topUsersLink) {
        history.push(url.replace('/overview', '/topusers'));
      }
    };

    if (NO_POPULARITY_OBJECTS.includes(dataTypes?.objectType!)) {
      return null;
    }

    if (priority === undefined || priority === 0) {
      return <NotApplicable />;
    }

    return (
      <Tooltip content={text}>
        <StyledPopularity
          {...other}
          ref={ref}
          className={className}
          compWidth={compWidth}
          data-testid="popularity"
          onClick={handleClick}
          style={topUsersLink ? { cursor: 'pointer' } : {}}
        >
          {priorityColor.map((color, index) => {
            if (index <= priority) {
              return <StyledPopularityItem key={color.id} color={color.color} />;
            }
            return null;
          })}
        </StyledPopularity>
      </Tooltip>
    );
  },
);

export default Popularity;
