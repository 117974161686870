import React from 'react';
import { useHistory } from 'react-router-dom';
import { Cell } from 'react-table';

import { TeamModel } from '@api/teams/TeamModel';
import IconButton from '@components/IconButton';
import TeamOwnershipMigrationModal from '@components/Modal/TeamOwnershipMigrationModal';
import UserCell from '@components/Table/Cells/UserCell';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { useModal } from '@context/Modal';

interface AdminTeamsTableProps {
  itemCount?: number;
  memberCount?: number;
  teams?: TeamModel[];
}

const AdminTeamsTable: React.FC<AdminTeamsTableProps> = ({
  itemCount = 0,
  memberCount = 0,
  teams,
}) => {
  const history = useHistory();
  const { MODAL_IDS, checkModalOpened, closeModal, getModalContent, openModal } = useModal();

  const columns: ColumnConfig<TeamModel>[] = React.useMemo(
    () => [
      {
        Cell: (props: Cell<TeamModel>) => {
          const team = props.row.original;
          return <UserCell {...props} to={`/teams/${team.guid}`} user={team} />;
        },
        Header: `Team (${itemCount})`,
        accessor: (d) => d?.fullName,
        id: 'team',
        width: '50%',
      },
      {
        Header: `Members (${memberCount})`,
        accessor: 'membersCount',
        id: 'members',
        width: '50%',
      },
      {
        Cell: ({ row }: Cell<TeamModel>) => {
          const team = row.original;

          return (
            <IconButton
              iconColor="gray.600"
              iconName="edit-outline"
              iconSize="20px"
              onClick={() => history.push(`/settings/teams/${team.guid}`)}
              variant="clear"
              verticalAlign="middle"
            />
          );
        },
        disableResizing: true,
        disableSortBy: true,
        id: 'rename',
        width: '2rem',
      },
      {
        Cell: ({ row }: Cell<TeamModel>) => {
          const team = row.original;

          return (
            <IconButton
              iconColor="gray.600"
              iconName="trash"
              iconSize="20px"
              onClick={() => openModal(MODAL_IDS.teamOwnershipMigration, { team })}
              variant="clear"
              verticalAlign="middle"
            />
          );
        },
        disableResizing: true,
        disableSortBy: true,
        id: 'delete',
        width: '2rem',
      },
    ],
    [itemCount, memberCount, MODAL_IDS, openModal],
  );

  const handleCloseModal = () => closeModal(MODAL_IDS.teamOwnershipMigration);
  const teamOwnershipMigrationModalProps = getModalContent(MODAL_IDS.teamOwnershipMigration);

  return (
    <>
      <TableStyled>
        <Table
          basic="very"
          className="table-full"
          columns={columns}
          compact
          data={teams || []}
          disableColumnFiltering
          disablePagination
          disableRowSelect
          initialState={{
            sortBy: [
              {
                desc: false,
                id: 'team',
              },
            ],
          }}
          loading={teams === undefined}
          singleLine
          sortable
          unstackable
        />
      </TableStyled>
      {checkModalOpened(MODAL_IDS.teamOwnershipMigration) && teamOwnershipMigrationModalProps && (
        <TeamOwnershipMigrationModal
          onClose={handleCloseModal}
          team={teamOwnershipMigrationModalProps.team}
        />
      )}
    </>
  );
};

export default AdminTeamsTable;
