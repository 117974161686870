import styled from '@emotion/styled';

const AboveSearchContainer = styled.div`
  width: 100%;
  min-height: 36px;
  z-index: ${({ theme }) => theme.zIndex.floatingElement};
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.space(0.5, 2.5)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.v1.gray[100]};
  color: ${({ theme }) => theme.colors.primary};
  align-items: center;
  outline: none;
  flex-wrap: wrap;
`;

export default AboveSearchContainer;
