import { DataTypes } from '@configs/dataTypes/types';

export const DWH_GROUP_REGEX = /^-?\d-db.*$/;

export const BI_GROUPS_ORDER: Array<DataTypes['dataSourceType']> = [
  'power_bi',
  'tableau',
  'looker',
  'thoughtspot',
  'quicksight',
  'mode',
  'sigma',
  'metabase',
  'data_studio',
  'periscope',
];

export const GROUP_ORDER_BY_DATA_TYPE = BI_GROUPS_ORDER.reduce((acc, cur, index) => {
  return { ...acc, [cur]: index };
}, {} as Record<DataTypes['dataSourceType'], number>);

/**
 * These constants below are used to create the BI group node id
 * To avoid any problems when parsing the nodes, some rules should be followed:
 *   1. They can't be the same
 *   2. They can't be a substring of dataType, objectType or dataSourceType values
 */
export const BI_GROUP_SEPARATOR = '&';
export const BI_GROUP_STACK_INDICATOR = '#';
