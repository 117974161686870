import React from 'react';

import { TagType } from '@api/tags/TagModel';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import Text from '@components/Text';
import Icon from '@components/UI/Icon';
import Image from '@components/UI/Image';

export interface TagsSectionEmptyStateProps {
  onAddNewTag?: () => void;
  type: TagType;
}

const TagsSectionEmptyState = ({ onAddNewTag, type }: TagsSectionEmptyStateProps) => {
  return (
    <Box
      alignItems="center"
      compDisplay="flex"
      flexDirection="column"
      gap={3}
      justifyContent="start"
      mb={5}
      mt={4}
    >
      <Box
        alignItems="center"
        compDisplay="flex"
        compWidth="100%"
        flexDirection="column"
        gap={1}
        justifyContent="center"
      >
        <Image alt="No content" height="auto" src="/images/empty-content.png" width="120px" />
        <Text color="gray.500" fontSize="body2">
          You have no {type} tags yet
        </Text>
      </Box>
      <Button
        onClick={onAddNewTag}
        startIcon={<Icon color="currentcolor" name="plus" size="20px" />}
      >
        Create a new tag
      </Button>
    </Box>
  );
};

export default TagsSectionEmptyState;
