import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import type { BreadcrumbPayload } from '@api/breadcrumbs/BreadcrumbModel';
import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import type { DescriptionSource } from '@api/description/description.types';
import { QueryData, QueryModel } from '@api/queries/QueryModel';
import { TableData, TableModel } from '@api/tables/TableModel';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { ObjectType } from '@api/types';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import type { DataSourceTypesType } from '@models/DataSourceCredentials';
import type { DataTypesPayload } from '@models/DataTypesModel';
import DataTypesModel from '@models/DataTypesModel';
import { ForeignKeyData, ForeignKeyModel } from '@models/ForeignKeyModel';
import { MetadataModel } from '@models/MetadataModel';
import { PopularityData, PopularityModel } from '@models/PopularityModel';
import { Reference } from '@models/Reference';
import RelatedObjectsCountsModel from '@models/RelatedObjectsCountsModel';
import { urlFor } from '@utils/routing';

export interface ColumnData {
  dataType: string;
  dataTypeIcon: string;
  description?: string;
  externalType?: string;
  foreignKeys?: ForeignKeyData[];
  fullName?: string;
  guid: string;
  isForeignKey: boolean;
  isJoinKey: boolean;
  isPrimaryKey: boolean;
  name: string;
  ordinal: number;
  popularity?: PopularityData;
  richtext_description?: string;
  sampleJoinQuery?: QueryData;
  suggestedDescription?: string;
  table: TableData;
  typeDisplay: string;
}

export interface ColumnPayload {
  breadcrumbs: Array<BreadcrumbPayload>;
  data_types: DataTypesPayload;
  external_type: 'string';
  guid: 'string';
  is_foreign_key: true;
  is_nullable: true;
  is_primary_key: true;
  is_unique: true;
  name: 'string';
  search_name: 'string';
}

export class ColumnModel {
  static objectType: ObjectType = 'column';

  objectType: ObjectType = ColumnModel.objectType;

  static typeDisplay: string = 'Column';

  typeDisplay: string = ColumnModel.typeDisplay;

  guid!: string;

  name!: string;

  ordinal?: number;

  description?: string;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  // TODO: Remove transform https://app.shortcut.com/select-star/story/55620
  @Expose({ name: 'richtext_description' })
  @Transform((richtextDescription) =>
    isEmptyRichText(richtextDescription) ? '' : richtextDescription,
  )
  richtextDescription?: string;

  @Expose({ name: 'suggested_description' })
  suggestedDescription?: string;

  @Expose({ name: 'suggested_description_source' })
  suggestedDescriptionSource?: string;

  @Expose({ name: 'suggested_description_source_object' })
  @Type(() => Reference)
  suggestedDescriptionSourceObject?: Reference<MetadataModel>;

  @Expose({ name: 'is_join_key' })
  isJoinKey!: boolean;

  @Expose({ name: 'is_metric' })
  isMetric?: boolean;

  @Expose({ name: 'is_mention' })
  isMention?: boolean;

  @Expose({ name: 'is_primary_key' })
  isPrimaryKey!: boolean;

  @Expose({ name: 'is_foreign_key' })
  isForeignKey!: boolean;

  @Expose({ name: 'is_unique' })
  isUnique!: boolean;

  @Expose({ name: 'is_nullable' })
  isNullable!: boolean;

  @Expose({ name: 'data_type' })
  dataType?: string;

  @Type(() => PopularityModel)
  popularity?: PopularityModel;

  @Expose({ name: 'short_name' })
  shortName?: string;

  @Expose({ name: 'table_name' })
  tableName?: string;

  @Expose({ name: 'search_name' })
  fullName?: string;

  @Expose({ name: 'tagged_items' })
  @Type(() => TaggedItemModel)
  taggedItems?: TaggedItemModel[];

  @Expose({ name: 'calculation' })
  calculation?: string;

  @Expose({ name: 'foreign_keys' })
  @Type(() => ForeignKeyModel)
  foreignKeys?: ForeignKeyModel[];

  @Expose({ name: 'external_type' })
  externalType?: string;

  @Expose({ name: 'deactivation_scheduled_on' })
  @Transform((value) => (value ? moment(value) : value))
  deactivationScheduledOn?: moment.Moment;

  @Expose({ name: 'downstream_objects_counts' })
  @Type(() => RelatedObjectsCountsModel)
  downstreamObjectsCounts?: RelatedObjectsCountsModel;

  @Expose({ name: 'upstream_objects_counts' })
  @Type(() => RelatedObjectsCountsModel)
  upstreamObjectsCounts?: RelatedObjectsCountsModel;

  @Expose({ name: 'data_source_type' })
  dataSourceType?: DataSourceTypesType;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  @Type(() => TableModel)
  table?: TableModel;

  @Expose({ name: 'sample_join_query' })
  @Type(() => QueryModel)
  sampleJoinQuery?: QueryModel;

  @Expose({ name: 'parent_guid' })
  @Transform((value, obj) => (obj.table ? obj.table?.guid : value))
  parentGuid?: string;

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }

  get formattedTaggedItems() {
    return this.taggedItems ? this.taggedItems.map((item) => item.tag.name).join(',') : '';
  }

  get routePath() {
    return urlFor({
      dataTypes: this.dataTypes,
      guid: this.guid,
      objectType: this.objectType,
      parentGuid: this.parentGuid,
    });
  }

  externalUrl = undefined;

  @Expose({ name: 'ai_description' })
  aiDescription?: string;

  @Expose({ name: 'ingested_description' })
  ingestedDescription?: string;

  @Expose({ name: 'description_source' })
  descriptionSource?: DescriptionSource;

  @Expose({ name: 'user_description' })
  userDescription?: string;
}
