import React, { KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { Link as RouterLink } from 'react-router-dom';
import { Descendant } from 'slate';

import { CommentModel } from '@api/comments/CommentModel';
import { usePostQuickSearch } from '@api/search';
import { SearchModel } from '@api/search/SearchModel';
import type { SearchResult } from '@api/search/types';
import Button from '@components/Button/Button';
import DropdownButton from '@components/DropdownButton/DropdownButton';
import InlineUnexpectedError from '@components/Error/InlineUnexpectedError';
import type { MentionType } from '@components/Mention/Mention.types';
import { useNotificationContext } from '@components/Modal/NotifyUsersModal/Notification.context';
import NavigationBlocker from '@components/NavigationBlocker';
import RichTextEditor from '@components/RichTextEditor';
import convertStringToSlate from '@components/RichTextEditor/helpers/convertStringToSlate';
import { resetEditorValue } from '@components/RichTextEditor/helpers/resetEditorValue';
import {
  DEFAULT_SLATE_STATE,
  serializeSlateToPlainText,
} from '@components/RichTextEditor/helpers/serializationHelpers';
import useSlateEditor from '@components/RichTextEditor/Hooks/useSlateEditor';
import useMentions from '@components/RichTextEditor/Mentions/useMentions';
import Avatar from '@components/UI/Avatar';
import { useFetchedMentions } from '@context/FetchedMentions';
import FetchedMention from '@context/FetchedMentions/FetchedMention';
import { useUserContext } from '@context/User';

import {
  CommentEditorControls,
  StyledCommentEditor,
  StyledCommentTextBox,
} from './CommentTextBox.styles';
import { CommentPayload } from './CommentTextBox.types';

interface CommentTextBoxProps {
  editCommentGuid?: string;
  editing?: boolean;
  enableNotifications?: boolean;
  inReplyTo?: string;
  initialValue?: string;
  isNewMessage?: boolean;
  onCancel?: () => void;
  onSave: (payload: CommentPayload) => void;
  onUpdate?: (payload: CommentPayload) => void;
  parentGuid: string;
  shouldBlockNavigation?: boolean;
}

const CommentTextBox: React.FC<CommentTextBoxProps> = ({
  editCommentGuid,
  editing,
  enableNotifications,
  inReplyTo,
  initialValue = '',
  isNewMessage,
  onCancel,
  onSave,
  onUpdate,
  parentGuid: targetGuid,
  shouldBlockNavigation,
}) => {
  const { organization, user } = useUserContext();
  const useDownstreamNotifications = organization?.settings?.useDownstreamNotifications;
  const { getMentionFromCacheById } = useFetchedMentions();
  const [editor] = useSlateEditor();
  const [cancelState, setCancelState] = useState(initialValue);
  const initialSlateState = useMemo(() => convertStringToSlate(initialValue), [initialValue]);
  const { applyDraftComment, currentComment, setDraftComment } = useNotificationContext();
  const [slateEditorState, setSlateEditorState] = useState<Descendant[]>(
    initialSlateState ?? DEFAULT_SLATE_STATE,
  );

  const sortResultsByRelevance = useCallback((results: MentionType[]): MentionType[] => {
    const resultsRelatedToTarget: MentionType[] = [];
    const unRelatedResults: MentionType[] = [];

    results.forEach((result) => {
      const { exploreGuid, id, parentGuid, tableGuid } = result;
      if ([tableGuid, exploreGuid, parentGuid, id].includes(targetGuid)) {
        resultsRelatedToTarget.push(result);
      } else {
        unRelatedResults.push(result);
      }
    });

    return [...resultsRelatedToTarget, ...unRelatedResults];
  }, []);

  const [mentionResults, setMentionResults] = useState<SearchResult<SearchModel>>();
  const { mutate: postQuickSearch } = usePostQuickSearch({
    onSuccess: (data) => {
      setMentionResults(data);
    },
  });

  const { mentionSuggestionElement, onChangeWithMentions, onKeyDownWithMentions } = useMentions({
    editor,
    onBeforeResults: sortResultsByRelevance,
    onSearch: (searchTerm, filters) =>
      postQuickSearch({
        all_buckets_search: true,
        index_filters: filters,
        page_size: 50,
        query: searchTerm,
      }),
    searchResults: mentionResults,
  });

  const hasUnsavedChanges = useMemo(
    () =>
      Boolean(editor.children.length) &&
      JSON.stringify(editor.children) !== JSON.stringify(initialSlateState),
    [editor.children, initialValue, initialSlateState],
  );

  const editable = editing || isNewMessage;
  const isEditing = editing || hasUnsavedChanges;
  const getPlaceHolder = () => {
    if (inReplyTo) {
      return 'Add reply';
    }
    if (isNewMessage) {
      return 'Write your comment here';
    }
    return '';
  };

  const getMessageFromEditorNodes = (nodes: Descendant[]) => {
    return serializeSlateToPlainText({
      getMentionFromCacheById,
      nodes,
    });
  };

  const { savingMessage, savingPayload } = useMemo(() => {
    const richtextMessage = JSON.stringify(editor.children);
    const message = getMessageFromEditorNodes(editor.children);
    const payload: CommentPayload = {
      in_reply_to: inReplyTo,
      message,
      richtext_message: richtextMessage,
      target: targetGuid,
    };

    return { savingMessage: message, savingPayload: payload };
  }, [editor.children, inReplyTo, targetGuid]);

  const handleSetSlateState = (state: Descendant[]) => {
    onChangeWithMentions(state, (newState: Descendant[]) => {
      const message = getMessageFromEditorNodes(newState);
      setDraftComment({
        message,
        richtextMessage: JSON.stringify(newState),
      } as CommentModel);
      setSlateEditorState(newState);
    });
  };

  const handleSave = () => {
    if (!hasUnsavedChanges) return;
    if (editCommentGuid && onUpdate) {
      onUpdate(savingPayload);
    } else {
      onSave(savingPayload);
      // Clear the editor if creating a new message.
      resetEditorValue(editor, DEFAULT_SLATE_STATE);
    }

    setCancelState(savingMessage);
  };

  const handleNotify = () => {
    applyDraftComment();
    resetEditorValue(editor, DEFAULT_SLATE_STATE);
    setCancelState(savingMessage);
  };

  const handleCancel = useCallback(() => {
    if (isNewMessage || editing) {
      onCancel?.();
    }

    const slateCancelState = convertStringToSlate(cancelState);
    resetEditorValue(editor, slateCancelState);
    setSlateEditorState(slateCancelState);
  }, [editor, editing, onCancel, isNewMessage, cancelState]);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    const { key } = event;
    switch (key) {
      case 'Escape':
        handleCancel();
        return;
      case 'Enter':
        if (event.ctrlKey) {
          event.preventDefault();
          event.stopPropagation();
          handleSave();
        }
        break;
      default:
    }
  };

  const clearEditorStateOnNotify = useCallback(() => {
    if (currentComment && isEditing && !editCommentGuid) {
      resetEditorValue(editor, DEFAULT_SLATE_STATE);
    }
  }, [currentComment, editor, isEditing, editCommentGuid]);

  useEffect(() => {
    clearEditorStateOnNotify();
  }, [clearEditorStateOnNotify]);

  return (
    <ErrorBoundary fallback={InlineUnexpectedError}>
      <StyledCommentTextBox gap={1.8}>
        {isNewMessage && user && (
          <Avatar
            {...user.mappedAvatar}
            as={RouterLink}
            size="40px"
            to={`/profiles/${user.guid}`}
          />
        )}
        <StyledCommentEditor editable={editable} isNewMessage={isNewMessage}>
          <RichTextEditor
            editable={editable}
            editor={editor}
            elementMap={{ mention: FetchedMention }}
            fluid={false}
            initialState={slateEditorState}
            mentionSuggestionElement={mentionSuggestionElement}
            mentionTriggerHandler={onKeyDownWithMentions}
            onCancel={handleCancel}
            onChange={handleSetSlateState}
            onKeyDown={handleKeyDown}
            placeholder={getPlaceHolder()}
            showToolbar={false}
          />
          {editable && (
            <CommentEditorControls>
              {(inReplyTo || editing) && (
                <Button compSize="sm" onClick={handleCancel} variant="outlined">
                  Cancel
                </Button>
              )}
              {useDownstreamNotifications && enableNotifications ? (
                <DropdownButton
                  onClick={handleSave}
                  options={[
                    {
                      id: '0',
                      label: 'Create Notification',
                      name: 'create-notification',
                      onClick: handleNotify,
                    },
                  ]}
                  tooltipMessage="Create Notification"
                >
                  Comment
                </DropdownButton>
              ) : (
                <Button compSize="sm" onClick={handleSave}>
                  Save
                </Button>
              )}
            </CommentEditorControls>
          )}
        </StyledCommentEditor>
        <NavigationBlocker shouldBlockNavigation={shouldBlockNavigation && isEditing} />
      </StyledCommentTextBox>
    </ErrorBoundary>
  );
};

export default CommentTextBox;
