import getIcons from '@configs/icons/getIcons';
import { Expose, Type } from 'class-transformer';
import moment from 'moment';

import { TeamData, TeamModel } from '@api/teams/TeamModel';
import { ObjectType } from '@api/types';
import { UserData, UserModel } from '@api/user/UserModel';
import { DataSourceData, DataSourceModel } from '@models/DataSourceModel';
import DataTypesModel from '@models/DataTypesModel';
import mapObjectToAvatar, { MapObjectToAvatarReturnType } from '@models/mapObjectToAvatar';
import formatNumber from '@utils/formatNumber';

export interface DSUserData {
  avatar?: string;
  dataSource?: DataSourceData;
  displayName?: string;
  email?: string;
  fullName: string;
  guid: string;
  isActive?: boolean;
  isModeServiceAccount?: boolean;
  isPeriscopeServiceAccount?: boolean;
  isServiceAccount?: boolean;
  isSigmaServiceAccount?: boolean;
  lastQueried?: moment.Moment;
  mappedAvatar?: MapObjectToAvatarReturnType;
  name: string;
  queryCount?: number;
  team?: TeamData;
  user?: UserData;
}

export class DsUserModel {
  static objectType: ObjectType = 'dsuser';

  objectType: ObjectType = DsUserModel.objectType;

  static typeDisplay: string = 'Data Source User';

  typeDisplay: string = DsUserModel.objectType;

  guid!: string;

  name?: string;

  email?: string;

  get fullName() {
    // full name of the DBUser
    if (this.displayName) {
      return this.displayName;
    }
    return this.name;
  }

  get mappedAvatar() {
    const iconName =
      this.dataTypes?.icons.dataSource ??
      getIcons({ dataSourceType: this.dataSource?.type }).dataSource;
    return {
      ...mapObjectToAvatar(this),
      icon: { name: iconName, size: '90%' }, // avatar v1
    };
  }

  get validOwner(): DsUserModel | UserModel {
    if (this?.user?.isActive && this?.user?.fullName) {
      return this.user;
    }

    return this;
  }

  avatar = undefined;

  @Type(() => TeamModel)
  team?: TeamModel;

  @Type(() => UserModel)
  user?: UserModel;

  @Expose({ name: 'is_service_account' })
  isServiceAccount?: boolean;

  @Expose({ name: 'is_mode_service_account' })
  isModeServiceAccount?: boolean;

  @Expose({ name: 'is_periscope_service_account' })
  isPeriscopeServiceAccount?: boolean;

  @Expose({ name: 'is_sigma_service_account' })
  isSigmaServiceAccount?: boolean;

  @Expose({ name: 'is_active' })
  isActive?: boolean;

  @Expose({ name: 'data_source' })
  @Type(() => DataSourceModel)
  dataSource?: DataSourceModel;

  @Expose({ name: 'display_name' })
  displayName?: string;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel;

  @Expose({ name: 'total_run' })
  totalRun: number = 0;

  get formattedTotalRun() {
    return formatNumber(this.totalRun);
  }

  @Expose({ name: 'total_credits_used' })
  totalCreditsUsed?: number = 0;

  get formattedTotalCreditsUsed() {
    return formatNumber(this.totalCreditsUsed);
  }

  @Expose({ name: 'average_credits_used' })
  averageCreditsUsed?: number = 0;

  get formattedAverageCreditsUsed() {
    return formatNumber(this.averageCreditsUsed);
  }

  @Expose({ name: 'downstream_tables' })
  downstreamTables?: number = 0;

  @Expose({ name: 'downstream_dashboards' })
  downstreamDashboards?: number = 0;
}
