import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Box from '@components/Box';
import SearchResultIcon from '@components/SearchBar/SearchResult/SearchResultIcon';
import SearchTopIcon from '@components/SearchBar/SearchResult/SearchTopIcon';
import Skeleton from '@components/Skeleton';
import Tooltip from '@components/Tooltip';
import { useTagContext } from '@context/Tag';
import DataTypesModel from '@models/DataTypesModel';
import { urlFor } from '@utils/routing';
import truncateDescription from '@utils/truncateDescription';

import { StyledBreadcrumbs, StyledMention } from './Mention.styles';

export interface MentionData {
  [x: string]: any;
  avatar?: string;
  id?: null | string | number;
  link?: string;
  name: string;
}

interface MentionProps {
  avatar?: string;
  children?: React.ReactNode;
  className?: string;
  dataTypes?: Omit<DataTypesModel, 'findConfig'>;
  description?: string;
  guid?: string;
  isActive?: boolean;
  link?: string;
  loading?: boolean;
  name?: string;
  parentGuid?: string;
}

const Mention: React.FC<MentionProps> = ({
  avatar,
  children,
  className,
  dataTypes,
  description,
  guid,
  isActive = true,
  link,
  loading,
  name,
  parentGuid,
}) => {
  const { findTag } = useTagContext();
  const shortDescription = useMemo(() => truncateDescription(description, 300), [description]);

  const mappedAvatar =
    dataTypes?.objectType === 'user'
      ? {
          guid,
          isActive,
          isDeleted: false,
          name,
          src: avatar,
        }
      : undefined;

  if (loading) {
    return (
      <StyledMention>
        <Skeleton compHeight="10px" compWidth="80px" />
      </StyledMention>
    );
  }

  if (!guid) {
    return null;
  }

  const isTag = dataTypes?.objectType === 'tag';
  const hideDataSourceIcon = [
    'user',
    'tag',
    'document',
    'metric',
    'team',
    'comment',
    'term',
    'datasource',
  ];
  const tag = (isTag && findTag?.(guid)) || undefined;

  return (
    <Tooltip
      content={shortDescription}
      followCursor={false}
      offset={[0, 2]}
      placement="bottom-start"
    >
      <StyledMention>
        <RouterLink
          className={className}
          to={
            link ||
            urlFor({
              dataType: dataTypes?.dataType,
              guid,
              objectType: dataTypes?.objectType,
              parentGuid,
            })
          }
        >
          {!hideDataSourceIcon.includes(dataTypes?.objectType!) && (
            <SearchTopIcon dataTypes={dataTypes} mr={0.2} />
          )}
          <SearchResultIcon
            dataTypes={dataTypes}
            guid={guid}
            mappedAvatar={mappedAvatar}
            name={name}
          />
          {tag ? (
            <StyledBreadcrumbs
              fontWeight="normal"
              items={tag.breadcrumbLabelList}
              showTrailingSlash={false}
            />
          ) : (
            <Box as="span" maxWidth="100%">
              {children ?? name}
            </Box>
          )}
        </RouterLink>
      </StyledMention>
    </Tooltip>
  );
};

export default Mention;
