import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { useSegmentContext } from '@context/Segment';
import { useUserContext } from '@context/User';
import flags from '@features';

const Boot: React.FC = ({ children }) => {
  const { organization, user } = useUserContext();
  const segment = useSegmentContext();
  const { boot, shutdown, update } = useIntercom();
  const location = useLocation();

  useEffect(() => {
    if (flags.enable_intercom) {
      if (user) {
        boot({
          customAttributes: {
            organization: organization ? organization.fullName : undefined,
            team: user.team?.fullName,
          },
          email: user.email,
          name: user.fullName,
        });
      } else {
        boot();
      }
    }

    return () => {
      if (flags.enable_intercom) {
        shutdown();
      }
    };
  }, [user]);

  useEffect(() => {
    if (location.pathname && user) {
      update({
        customAttributes: {
          organization: organization ? organization.fullName : undefined,
          team: user.team?.fullName,
        },
        email: user.email,
        name: user.fullName,
      });
    }
  }, [location.pathname, user, organization, update]);

  useEffect(() => {
    segment?.reload();
  }, [location.pathname, segment]);

  return <>{children}</>;
};

export default Boot;
