import React from 'react';

import { usePatchRbacPolicy } from '@api/rbacPolicy';
import { PolicyStatusType, RBACPolicyModel } from '@api/rbacPolicy/rbacPolicyModel';
import { renderInfoToast } from '@components/Toast';
import Select from '@components/UI/Select.v1/Select';
import { Option, SelectValue } from '@components/UI/Select.v1/types';

const statusOptions = Object.keys(PolicyStatusType).map((key) => ({
  key,
  text: key.replace(/^\w/, (c) => c.toUpperCase()),
  value: key,
}));

interface StatusDropdownProps {
  rbacPolicyItem: RBACPolicyModel;
}

const StatusDropdown: React.FC<StatusDropdownProps> = ({ rbacPolicyItem }) => {
  const { isLoading, mutate } = usePatchRbacPolicy(rbacPolicyItem.guid, {
    onSuccess: () => {
      renderInfoToast('Policy Updated');
    },
  });

  const currentStatus = statusOptions.find((option) => option.value === rbacPolicyItem.status);

  const onChangeStatus = (newStatus: SelectValue) => {
    const [option] = newStatus as Option[];
    mutate({ status: option.value === 'init' ? '' : option.value });
  };

  return (
    <Select
      isLoading={isLoading}
      onChange={onChangeStatus}
      options={statusOptions}
      value={currentStatus ? [currentStatus] : undefined}
    />
  );
};

export default StatusDropdown;
