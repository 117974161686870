import React from 'react';
import { dataSources } from '@configs/dataSources/config';

import {
  StyledDataSourceSetupContent,
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import useDataSourceMutation from '../useDataSourceMutation';

import { DataSourceFormProps } from './types';

enum FieldKey {
  apiVersion = 'api_version',
  domain = 'domain',
  name = 'name',
  password = 'password',
  securityToken = 'security_token',
  user = 'user',
}

interface FormValues {
  [FieldKey.name]?: string;
  [FieldKey.password]?: string;
  [FieldKey.user]?: string;
  [FieldKey.securityToken]?: string;
  [FieldKey.apiVersion]?: string;
  [FieldKey.domain]?: string;
}

const REQUIRED_FIELDS = [FieldKey.password, FieldKey.user, FieldKey.securityToken];

const SalesforceForm: React.FC<DataSourceFormProps> = ({
  children,
  dataSource,
  name: dsName,
  onSuccess,
  renderBefore,
}) => {
  const isPatch = Boolean(dataSource?.guid);
  const segment = useSegmentContext();
  const { error, isLoading, mutate } = useDataSourceMutation({ dataSource, onSuccess });
  const { handleChange, handleSubmit, values } = useForm<FormValues>({
    initialValues: {
      name: dataSource?.name ?? dsName,
    },
    onSubmit: ({ name, ...credentials }) => {
      mutate({
        credentials,
        name,
        type: dataSources.salesforce.value,
      } as any);
      segment?.track(SegmentTrackEventName.CreateServiceAccountConnectButtonClicked, {
        dataType: dataSources.salesforce.value,
      });
    },
    syncInitialValues: true,
  });

  const INPUT_CONFIG = {
    [FieldKey.name]: {
      Component: Input,
      key: FieldKey.name,
      label: 'Display Name',
      maxLength: 50,
      onChange: handleChange,
      placeholder: dsName,
    },
    [FieldKey.user]: {
      Component: Input,
      key: FieldKey.user,
      label: 'Username',
      onChange: handleChange,
    },
    [FieldKey.password]: {
      Component: Input,
      key: FieldKey.password,
      label: 'Password',
      onChange: handleChange,
      type: 'password',
    },
    [FieldKey.securityToken]: {
      Component: Input,
      key: FieldKey.securityToken,
      label: 'Security Token',
      onChange: handleChange,
      type: 'password',
    },
    [FieldKey.apiVersion]: {
      Component: Input,
      key: FieldKey.apiVersion,
      label: 'Salesforce API Version',
      onChange: handleChange,
      placeholder: '42.0',
    },
    [FieldKey.domain]: {
      Component: Input,
      key: FieldKey.domain,
      label: 'Salesforce Domain',
      onChange: handleChange,
    },
  };

  const isInvalid = isPatch ? false : REQUIRED_FIELDS.some((key) => Boolean(values[key]) === false);

  return (
    <StyledDataSourceSetupContent borderRadius="inherit">
      <Form isLoading={isLoading} onSubmit={handleSubmit}>
        <StyledFormHorizontalLabelGrid>
          {renderBefore?.({ error, loading: isLoading })}
          {Object.values(INPUT_CONFIG).map(({ Component, key, label, ...other }) => (
            <StyledLabel key={key}>
              {label}
              <Component
                error={error?.data?.[key]}
                helperText={error?.data?.[key]}
                name={key}
                placeholder={label}
                value={values[key]}
                {...other}
              />
            </StyledLabel>
          ))}
        </StyledFormHorizontalLabelGrid>
        {children?.({ error, loading: isLoading || isInvalid })}
      </Form>
    </StyledDataSourceSetupContent>
  );
};

export default SalesforceForm;
