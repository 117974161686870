import React, { useEffect, useMemo, useState } from 'react';
import {
  DEFAULT_BILLING_USAGE_TOTALS,
  STRIPE_SUBSCRIPTION_OPTIONS,
  STRIPE_TEST_SUBSCRIPTION_OPTIONS,
} from '@constants';
import * as Sentry from '@sentry/react';
import { useStripe } from '@stripe/react-stripe-js';

import { usePostBillingSession, usePostCustomerPortal } from '@api/billing';
import { useFetchOrganizationBillingData } from '@api/organizations/organizations';
import Alert from '@components/Alert';
import Box from '@components/Box';
import ToggleButton from '@components/Button/ToggleButton';
import CircularLoader from '@components/CircularLoader';
import NotFoundError from '@components/Error/NotFoundError';
import TabError from '@components/TabContent/TabError';
import { TabContentProps } from '@components/Tabs/types';
import Text, { defaultParagraphStyles } from '@components/Text';
import { useUserContext } from '@context/User';
import { BillingCustomerModel } from '@models/BillingCustomerModel';
import { dateFormat } from '@utils/moment';

import { Subscription } from '../../AdminBillingPage.types';

import SubscriptionCard from './SubscriptionCard';
import UsageCard from './UsageCard';

const subscriptionOptions =
  window.env?.ENVIRONMENT === 'production'
    ? STRIPE_SUBSCRIPTION_OPTIONS
    : STRIPE_TEST_SUBSCRIPTION_OPTIONS;

interface CurrentPlanTabProps extends TabContentProps {
  billingCustomer?: BillingCustomerModel;
}

const CurrentPlanTab: React.FC<CurrentPlanTabProps> = ({ billingCustomer }) => {
  const stripe = useStripe();
  const { organization } = useUserContext();

  const startYearly = useMemo(
    () =>
      subscriptionOptions
        .map((sub) => sub.yearlyPriceId)
        .filter(Boolean)
        .includes(billingCustomer?.stripePriceId),
    [billingCustomer?.stripePriceId],
  );

  const [showYearlyPrices, setShowYearlyPrices] = useState(startYearly);
  const {
    data: billingData,
    isError,
    isLoading: loadingBillingData,
  } = useFetchOrganizationBillingData();

  const { data: portalData } = usePostCustomerPortal();

  useEffect(() => {
    setShowYearlyPrices(startYearly);
  }, [startYearly, setShowYearlyPrices]);

  const { mutate } = usePostBillingSession({
    onSuccess: (d) => {
      try {
        stripe?.redirectToCheckout({
          sessionId: d.session_id,
        });
      } catch (err) {
        Sentry.captureException(err);
      }
    },
  });

  const handleButtonClick = (
    priceId?: string,
    subscriptionName?: string,
    isChangePlanButton?: boolean,
  ) => {
    if (subscriptionName === 'Enterprise') {
      window?.open('https://calendly.com/selectstar/next-steps', '_blank', 'noopener,noreferrer');
      return;
    }
    if (isChangePlanButton) {
      if (portalData?.session_url && window.location) {
        window.location.href = portalData.session_url;
      }
      return;
    }
    mutate({ price_id: priceId });
  };

  if (!organization) {
    return <NotFoundError />;
  }

  const {
    daysLeftInSubscription,
    isSubscriptionEnded,
    isTrialExpired,
    subscriptionEndsAt,
    trialEndsAt,
  } = organization;
  const subscriptionEndDateFormatted = subscriptionEndsAt?.format(dateFormat(true));
  const trialEndDateFormatted = trialEndsAt?.format(dateFormat(true));

  const getWarningText = () => {
    if (isSubscriptionEnded) {
      return `Your plan ended on ${subscriptionEndDateFormatted}. Please choose one of the subscription plans below.`;
    }
    if (subscriptionEndsAt && daysLeftInSubscription <= 7) {
      return `Your plan ends on ${subscriptionEndDateFormatted}. Please choose one of the subscription plans below.`;
    }
    if (isTrialExpired) {
      return `Trial Expired on ${trialEndDateFormatted}. Please select a plan to continue using Select Star.`;
    }
    if (trialEndsAt) {
      return `Trial ends on ${trialEndDateFormatted}. Please select a plan to continue using Select Star after this date.`;
    }
    return null;
  };

  const warningText = getWarningText();

  if (loadingBillingData) return <CircularLoader compDisplay="block" mx="auto" my={2} />;
  if (isError) return <TabError />;

  return (
    <Box compDisplay="flex" compWidth="100%" flexDirection="column">
      <Box compDisplay="flex" compWidth="100%" flexDirection="column" mb={2} p={2} pl={0}>
        <Text as="h2" fontSize="20px" fontWeight="bold" mb={3}>
          Current Usage
        </Text>
        <Text as="div" {...defaultParagraphStyles} fontSize="14px" lineHeight={2}>
          <Box
            compDisplay="flex"
            compWidth="100%"
            flexDirection="row"
            gap={8}
            justifyContent="flex-start"
          >
            <UsageCard
              currentValue={billingData?.dataSourceCount}
              title="Data Sources"
              totalValue={
                billingCustomer?.maxAllowedDataSources || DEFAULT_BILLING_USAGE_TOTALS.datasources
              }
            />
            <UsageCard
              currentValue={billingData?.tableCount}
              title="Tables"
              totalValue={billingCustomer?.maxAllowedTables || DEFAULT_BILLING_USAGE_TOTALS.tables}
            />
            <UsageCard
              currentValue={billingData?.userCount}
              title="Users"
              totalValue={billingCustomer?.maxAllowedUsers || DEFAULT_BILLING_USAGE_TOTALS.users}
            />
          </Box>
        </Text>
      </Box>
      <Text as="h2" fontSize="20px" fontWeight="bold" mb={3}>
        Plans
      </Text>
      {warningText && (
        <Box mb={2}>
          <Alert title={warningText} type="warning" />
        </Box>
      )}
      <Box mb={2}>
        <ToggleButton
          labelOptionOne="Monthly"
          labelOptionTwo="Yearly"
          onToggle={() => setShowYearlyPrices(!showYearlyPrices)}
          toggled={showYearlyPrices}
        />
      </Box>
      <Box compDisplay="flex" compWidth="100%" flexDirection="row" gap={2}>
        {subscriptionOptions.map((subscription: Subscription) => (
          <SubscriptionCard
            key={subscription.name}
            activePriceId={isSubscriptionEnded ? undefined : billingCustomer?.stripePriceId}
            onButtonClick={handleButtonClick}
            subscription={subscription}
            yearlyPrices={showYearlyPrices}
          />
        ))}
      </Box>
    </Box>
  );
};

export default React.memo(CurrentPlanTab);
