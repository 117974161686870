import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { useFetchExploreFields } from '@api/explores';
import { useFetchTableauFields } from '@api/tableau';
import Box from '@components/Box';
import { ExploreFieldTable } from '@components/Table';
import TableauFieldsTable from '@components/Table/TableauFieldsTable';
import TableStyled from '@components/Table/TableStyled';
import { Filter } from '@utils';

const SelectTableStyled = styled(TableStyled)`
  .ui.table {
    th {
      &:hover {
        background: rgba(242, 242, 242, 1) !important;
      }
    }

    th.sorted {
      &.current-selected {
        background: rgba(242, 242, 242, 1) !important;
      }
    }
  }

  .ui.sortable.table th.sorted {
    background: rgba(242, 242, 242, 1) !important;
  }
`;

const defaultFieldsConfig: Filter.FilterOptions = {
  field_types: ['dimension'],
  order: '-popularity',
  page_size: 50,
  role_types: ['dimension'],
  sortColumn: 'popularity',
  sortDirection: 'descending',
};

const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  name: 'search_name',
  view: 'search_view',
};

const sortConfig: { [key: string]: string } = {
  name: 'name',
  popularity: 'popularity',
  view: 'view',
};

interface Props {
  initialDimensions?: string[];
  objectType: string;
  parentGuid?: string;
  setSelectedDimensions: React.Dispatch<React.SetStateAction<string[]>>;
}

const DimensionSelect: React.FC<Props> = ({
  initialDimensions,
  objectType,
  parentGuid,
  setSelectedDimensions,
}) => {
  const [selectedRowIds, setSelectedRowIds] = useState(() => {
    const selectedMap: { [key: string]: boolean } = {};
    initialDimensions?.forEach((dimension) => {
      selectedMap[dimension] = true;
    });
    return selectedMap;
  });

  const FilterService = Filter.useUpdateFilters(defaultFieldsConfig, searchConfig, sortConfig);
  const { filter } = FilterService;

  const effectiveConfig = React.useMemo(() => {
    return { params: Filter.setParams({ ...filter, datasources: undefined }) };
  }, [filter]);

  const { data: explorefieldResponse } = useFetchExploreFields(parentGuid || '', {
    enabled: objectType === 'explorefield' && Boolean(parentGuid),
    params: effectiveConfig.params,
  });

  const { data: tableauFieldsResponse } = useFetchTableauFields(parentGuid || '', {
    enabled: objectType === 'tableaufield' && Boolean(parentGuid),
    params: effectiveConfig.params,
  });

  const selectedRowKeys = React.useMemo(() => Object.keys(selectedRowIds), [selectedRowIds]);

  useEffect(() => {
    setSelectedDimensions(selectedRowKeys);
  }, [selectedRowKeys, setSelectedDimensions]);

  return (
    <Box compHeight="16rem" compWidth="100%" maxHeight="11.2rem" noDefault overflow="auto">
      <SelectTableStyled>
        {objectType === 'explorefield' ? (
          <ExploreFieldTable
            data={explorefieldResponse?.results}
            filterService={FilterService}
            isDataSourceEditable={false}
            isDimensionSelect
            itemCount={explorefieldResponse?.count}
            selectedRowIds={selectedRowIds}
            setSelectedRowIds={setSelectedRowIds}
          />
        ) : (
          <TableauFieldsTable
            data={!parentGuid ? [] : tableauFieldsResponse?.results}
            filterService={FilterService}
            isDataSourceEditable={false}
            isDimensionSelect
            itemCount={tableauFieldsResponse?.count}
            selectedRowIds={selectedRowIds}
            setSelectedRowIds={setSelectedRowIds}
          />
        )}
      </SelectTableStyled>
    </Box>
  );
};

export default DimensionSelect;
