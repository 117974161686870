import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { ActivityModel } from '@api/activity/ActivityModel';
import ActivityMessage from '@components/ActivityMessage';
import Box from '@components/Box';
import Text from '@components/Text';
import Avatar from '@components/UI/Avatar';
import theme from '@styles/theme';
import { shortFromNow } from '@utils/moment';

import DateTime from '../DateTime';

interface ActivityItemProps {
  activity: ActivityModel;
  timeFormat?: 'fromNow' | 'datetime';
}

export const ActivityItem: React.FC<ActivityItemProps> = ({ activity, timeFormat = 'fromNow' }) => {
  return (
    <Box
      alignItems="center"
      backgroundColor={theme.colors.v1.gray[100]}
      border="none"
      borderRadius="6px"
      compDisplay="flex"
      compWidth="100%"
      justifyContent="space-between"
      mb={0.8}
      p={1.5}
      pl={2}
    >
      <Box compDisplay="flex" compHeight="fit-content" gap={1}>
        {activity.generatedBy === 'ingestion' ? (
          <Avatar
            backgroundColor={theme.colors.white}
            icon={{ name: 'select-star-colored', size: '18px' }}
            mt={0.25}
            size={theme.space(4)}
          />
        ) : (
          <Avatar
            mt={0.25}
            {...activity.user?.mappedAvatar}
            as={RouterLink}
            initialSize="md"
            size={theme.space(4)}
            to={`/profiles/${activity.user?.guid}`}
          />
        )}
        <ActivityMessage hideUserAvatar isShownOnTable={false} message={activity.message} />
      </Box>
      {timeFormat === 'fromNow' ? (
        <Text
          as="span"
          color="gray.400"
          fontSize={theme.typography.fontSizes.body2}
          lineHeight="20px"
          wordBreak="normal"
        >
          {shortFromNow(activity.performedOn)}
        </Text>
      ) : (
        <Box alignItems="flex-end" compDisplay="flex" flexDirection="column" flexShrink={0}>
          <Text
            as="span"
            color="gray.400"
            fontSize={theme.typography.fontSizes.body1}
            lineHeight="20px"
          >
            <DateTime datetime={activity.performedOn} format="timeFormat" />
          </Text>
          <Text
            as="span"
            color="gray.400"
            fontSize={theme.typography.fontSizes.body1}
            lineHeight="20px"
          >
            <DateTime datetime={activity.performedOn} format="dateFormat" />
          </Text>
        </Box>
      )}
    </Box>
  );
};

interface RecentActivityProps {
  data?: ActivityModel[];
}

const RecentActivity: React.FC<RecentActivityProps> = ({ data }) => {
  return (
    <>
      <Text
        color={theme.colors.gray[600]}
        fontSize="1rem"
        fontWeight="regular"
        lineHeight="1.185rem"
        mb={3}
      >
        Recent Activity
      </Text>
      {!data && <span>Loading recent activity...</span>}
      {data?.length === 0 && <span>No recent activity.</span>}
      {data &&
        data?.length > 0 &&
        data.map((item) => {
          return <ActivityItem key={item.guid} activity={item} />;
        })}
    </>
  );
};

export default RecentActivity;
