import React, { memo } from 'react';

import Box from '@components/Box';
import { ExploreNode } from '@components/Explore.v1/Explore.types';
import useExpandExploreNode from '@components/Explore.v1/useExpandExploreNode';
import Icon from '@components/UI/Icon';
import flags from '@features';
import DataTypesModel from '@models/DataTypesModel';
import { SPACE } from '@styles/theme/grid';

import NodeHandles from '../../EdgeHandle/NodeHandles';
import LineageTooltip from '../../LineageTooltip';
import { SIZES } from '../config';
import { getColorsBasedOnDataTypes } from '../Nodes.utils';

import {
  StyledSchemaColumnCount,
  StyledSchemaNode,
  StyledSchemaNodeCaret,
  StyledSchemaNodeCaretContainer,
  StyledSchemaNodeContent,
  StyledSchemaNodeTitle,
} from './SchemaNode.styles';

const SchemaNode: React.FC<ExploreNode> = ({ children, data }) => {
  const { childrenCount = 0, dataTypes, isOpen = false, isUnfocused, key, name } = data;

  const colors = getColorsBasedOnDataTypes(dataTypes as DataTypesModel);

  const { updateNodesOnExpand } = useExpandExploreNode();

  const handleNodeClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (key) {
      updateNodesOnExpand({ nodeKey: key, shouldEnableFocusedLineage: false });
    }
  };

  return (
    <StyledSchemaNode isUnfocused={isUnfocused} onClick={handleNodeClick}>
      <NodeHandles height="schema" />
      <Box alignItems="center" compDisplay="flex" compWidth="100%" gap={0.5}>
        <Box alignItems="center" compDisplay="flex" compWidth="100%" gap={0.5} overflow="hidden">
          <Icon color={colors.primary} name="schema" size="16px" />
          <LineageTooltip content={name} offset={[8, 15]} placement="bottom-start">
            <StyledSchemaNodeTitle>
              {flags.new_lineage_debug_mode ? key : name}
            </StyledSchemaNodeTitle>
          </LineageTooltip>
          <StyledSchemaColumnCount>{childrenCount.toLocaleString()}</StyledSchemaColumnCount>
        </Box>
        <StyledSchemaNodeCaretContainer hoverColor={colors.secondary}>
          <StyledSchemaNodeCaret
            color={colors.primary}
            isOpen={isOpen}
            name="down"
            role="button"
            size="18px"
          />
        </StyledSchemaNodeCaretContainer>
      </Box>
      <StyledSchemaNodeContent>
        <Box compDisplay="flex" flexDirection="column" gap={SIZES.rowGap.table / SPACE}>
          {children}
        </Box>
      </StyledSchemaNodeContent>
    </StyledSchemaNode>
  );
};

const MemoizedSchemaNode = memo<typeof SchemaNode>(SchemaNode);

export default MemoizedSchemaNode;
