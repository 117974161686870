import { CustomAttributeModel } from '@api/customAttributes/CustomAttributeModel';
import { customAttributesWithValueCacheKeys } from '@api/customAttributesWithValue';
import {
  customAttributeValuesCacheKeys,
  usePatchCustomAttributeValue,
  usePostCustomAttributeValue,
} from '@api/customAttributeValues';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import type { UseMutationOptions } from '@hooks/useMutation';
import fetchClient from '@lib/fetchClient';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

export const MAX_LENGTH = 256;
interface UseEditCustomAttributeValueOptions
  extends Omit<UseMutationOptions<never, EnhancedErrorResult, any>, 'onSuccess' | 'onError'> {
  customAttribute: CustomAttributeModel;
  guid?: string | undefined;
  onError?: (error: EnhancedErrorResult) => void;
  onSuccess?: () => void;
}

const useEditCustomAttributeValueForm = ({
  customAttribute,
  guid = '',
  onError,
  onSuccess,
  ...other
}: UseEditCustomAttributeValueOptions) => {
  const handleSuccess = () => {
    fetchClient.invalidateQueries(customAttributeValuesCacheKeys.customAttributeValues);
    fetchClient.invalidateQueries(customAttributesWithValueCacheKeys.customAttributesWithValues);
    renderInfoToast(`${customAttribute?.name} has been updated.`);
    onSuccess?.();
  };

  const handleError = (error: EnhancedErrorResult) => {
    if (error?.data.value) {
      renderErrorToast(`There is a limit of ${MAX_LENGTH} characters`);
    } else {
      renderErrorToast(`There was an error updating ${customAttribute?.name}.`);
    }
    onError?.(error);
  };

  const patchQuery = usePatchCustomAttributeValue(guid, {
    onError: handleError,
    onSuccess: handleSuccess,
    ...other,
  });

  const postQuery = usePostCustomAttributeValue({
    onError: handleError,
    onSuccess: handleSuccess,
    ...other,
  });

  return { patchQuery, postQuery };
};

export default useEditCustomAttributeValueForm;
