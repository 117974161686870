import { DataTypesConfig } from '@configs/dataTypes/types';

export const INTEGRATION_NAME_CONFIG: DataTypesConfig<string> = {
  data_studio: {
    dashboard: {
      data_studio_dashboard: 'Dashboards',
      default: 'Dashboards',
    },
    default: 'Dashboards',
    page: {
      default: 'Pages',
      page: 'Pages',
    },
  },
  databricks: {
    default: 'Dashboards',
    notebook: { default: 'Notebooks' },
  },
  looker: {
    dashboard: {
      default: 'Dashboards',
      look: 'Looks',
    },
    default: 'Dashboards',
    explore: {
      default: 'Explores',
    },
    explorefield: {
      default: 'Fields',
    },
    lookmlview: {
      default: 'Views',
    },
  },
  metabase: {
    dashboard: {
      default: 'Dashboards',
      metabase_dashboard: 'Dashboards',
    },
    default: 'Dashboards',
    metabasetable: {
      default: 'Tables',
      model: 'Models',
      question: 'Questions',
    },
  },
  mode: {
    dashboard: {
      default: 'Reports',
      report: 'Reports',
    },
    default: 'Reports',
  },
  periscope: {
    dashboard: {
      default: 'Dashboards',
      periscope_dashboard: 'Dashboards',
    },
    default: 'Dashboards',
  },
  power_bi: {
    bifolder: {
      default: 'Folders',
    },
    dashboard: {
      default: 'Dashboards',
      power_bi_report: 'Reports',
    },
    default: 'Dashboards',
    powerbidataset: {
      default: 'Datasets',
      powerbidataflow: 'Dataflows',
    },
    powerbitable: {
      default: 'Tables',
      powerbitable: 'Tables',
    },
    reportquery: {
      default: 'Report Queries',
      page: 'Pages',
      tile: 'Tiles',
    },
  },
  quicksight: {
    bicolumn: {
      default: 'Fields',
    },
    bidashboardelement: {
      default: 'Visuals',
    },
    bidataset: {
      default: 'Datasets',
    },
    dashboard: {
      dashboard: 'Dashboards',
      default: '',
      report: 'Analyses',
    },
    default: '',
  },
  sigma: {
    dashboard: {
      default: 'Workbooks',
      sigma_dashboard: 'Workbooks',
    },
    default: 'Workbooks',
    reportquery: {
      column: 'Columns',
      default: 'Queries',
      visual: 'Charts',
    },
  },
  tableau: {
    bifolder: {
      default: 'Folders',
    },
    dashboard: {
      default: 'Dashboards',
      workbook: 'Workbooks',
    },
    default: 'Dashboards',
    tableaucolumn: {
      default: 'Columns',
    },
    tableaudatasource: {
      default: 'Data Sources',
      published: 'Datasources - Published',
    },
    tableaufield: {
      default: 'Fields',
    },
    tableautable: {
      'custom-sql': 'Custom SQL Queries',
      default: 'Tables',
      'excel-direct': 'Excel direct',
    },
    tableauview: {
      dashboard: 'Dashboards',
      default: 'Views',
      sheet: 'Sheets',
    },
  },
  thoughtspot: {
    bifolder: {
      default: 'Folders',
    },
    dashboard: {
      answer: 'Answers',
      default: 'Dashboards',
      liveboard: 'Liveboards',
    },
    datasource: {
      default: 'Datasources',
    },
    default: 'Dashboards',
    thoughtspottable: {
      default: 'Tables',
      one_to_one_logical: 'Tables',
      sql_view: 'Views',
      worksheet: 'Worksheets',
    },
  },
};
