import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAxios } from '@api';
import { usePostSSOCallback } from '@api/sso';
import CircularLoader from '@components/CircularLoader';
import TextScreen from '@components/TextScreen';
import TwoColumnDecorativeComponent from '@components/TwoColumnDecorativeComponent';
import TwoColumnLayout from '@components/TwoColumnLayout';
import useUrlSearchParams from '@hooks/useUrlSearchParams';
import fetchClient from '@lib/fetchClient';
import MetadataDecorator from '@utils/MetadataDecorator';

const SSORedirect: React.FC = () => {
  const query = useUrlSearchParams();
  const history = useHistory();
  const oneTimeCode: string | null = query.get('code');
  const errorMessage: string | null = query.get('error_description');
  const state: string | null = query.get('state') || '/';
  const { loadCache } = useAxios;

  const {
    error,
    isLoading,
    mutate: loginSSO,
  } = usePostSSOCallback({
    onSuccess: () => {
      loadCache([]);
      fetchClient.clear();
      history.push(state);
    },
    retry: false,
  });

  const errorDetails =
    error?.status === 401
      ? "The login information doesn't match with the SSO server. Please contact support"
      : error?.message;

  useEffect(() => {
    loginSSO({ oneTimeCode });
  }, [loginSSO, oneTimeCode]);

  let leftComponent = <CircularLoader centered />;

  if (!isLoading && !error) {
    leftComponent = <TextScreen text="Signing you in!" title="Sign in successful" />;
  }

  if (error) {
    leftComponent = <TextScreen text={errorDetails} title="SSO sign in was not successful" />;
  }

  if (errorMessage) {
    leftComponent = (
      <TextScreen
        text={`${errorMessage} Please contact support`}
        title="SSO sign in was not successful"
      />
    );
  }

  return (
    <>
      <MetadataDecorator title="SSO Redirect" />
      <TwoColumnLayout left={leftComponent} right={<TwoColumnDecorativeComponent />} />
    </>
  );
};

export default SSORedirect;
