import React, { useCallback, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { usePostQuickSearch } from '@api/search';
import Select from '@components/UI/Select.v1/Select';
import { removeDuplicatedOptions } from '@components/UI/Select.v1/Select.utils';
import { Option, SelectValue } from '@components/UI/Select.v1/types';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import { RECIPIENTS_SEARCH_FILTERS } from '../Modal/NotifyUsersModal/constants';
import { SelectProps } from '../UI/Select.v1/Select/Select';

import { NO_OWNER_OPTION } from './constants';
import { mapSearchToOptions } from './utils';

export interface BaseOwnerSelectProps {
  defaultOptions?: Option[];
  fitAnchorWidth?: boolean;
  hideCaret?: SelectProps['hideCaret'];
  isDisabled?: boolean;
  isLoading?: boolean;
  label?: SelectProps['label'];
  leftIcon?: SelectProps['leftIcon'];
  onChange: SelectProps['onChange'];
  ownerType?: 'user' | 'team' | 'all';
  placeholder?: string;
  popperConfigProps?: SelectProps['popperConfigProps'];
  renderCustomAnchor?: SelectProps['renderCustomAnchor'];
  showClearButton?: boolean;
  showClearSelection?: boolean;
  showNoOwnerOption?: boolean;
  showSearchOnOptions?: boolean;
  value: SelectValue;
}

const BaseOwnerSelect: React.FC<BaseOwnerSelectProps> = ({
  defaultOptions = [],
  fitAnchorWidth = false,
  hideCaret,
  isDisabled,
  isLoading,
  label,
  leftIcon,
  onChange,
  ownerType = 'all',
  placeholder = 'Search for users and teams',
  popperConfigProps,
  renderCustomAnchor,
  showClearButton = false,
  showClearSelection = false,
  showNoOwnerOption = false,
  showSearchOnOptions = false,
  value,
}) => {
  const isSearching = useRef(true);
  const [searchText, setSearchText] = useState('');
  const [searchRecipients, setSearchRecipients] = useState<Option[]>([]);
  const segment = useSegmentContext();

  const { isLoading: isSearchingOptions, mutate: search } = usePostQuickSearch({
    onSuccess: (result) => {
      const mappedRecipients = mapSearchToOptions(result.data, searchRecipients);
      setSearchRecipients((prevValues) => [...prevValues, ...mappedRecipients]);
      isSearching.current = false;
    },
  });

  const options = removeDuplicatedOptions([
    ...(showNoOwnerOption ? [NO_OWNER_OPTION] : []),
    ...defaultOptions,
    ...searchRecipients,
  ]);

  const debouncedSearch = useDebouncedCallback(
    (term: string) =>
      search({
        all_buckets_search: true,
        index_filters: RECIPIENTS_SEARCH_FILTERS[ownerType],
        no_external_tags: false,
        query: term,
      }),
    500,
  );

  const handleSearchChange = useCallback(
    (searchValue = '') => {
      setSearchText(searchValue);
      if (searchValue) {
        isSearching.current = true;
        debouncedSearch(searchValue);
      }
    },
    [debouncedSearch],
  );

  const handleChange = (newOwner: SelectValue) => {
    if (newOwner && newOwner?.length > 0) {
      const [selectedItem] = newOwner;
      segment?.track(SegmentTrackEventName.QuickSearchResultClicked, {
        guid: selectedItem.value,
        rank: selectedItem.props?.rank,
      });
    }

    onChange?.(newOwner);
  };

  const isSelectLoading = (searchText && isSearching.current) || isLoading || isSearchingOptions;

  return (
    <Select
      hideCaret={hideCaret && !isLoading}
      inputProps={{
        placeholder,
      }}
      isDisabled={isDisabled}
      isLoading={isSelectLoading}
      label={label}
      leftIcon={leftIcon}
      loadingPosition={isLoading ? 'input' : 'options-list'}
      onChange={handleChange}
      onSearchValueChange={handleSearchChange}
      options={options}
      optionsFitAnchorWidth={fitAnchorWidth}
      placeholder={placeholder}
      popperConfigProps={popperConfigProps}
      renderCustomAnchor={renderCustomAnchor}
      searchValue={searchText}
      shouldBlockOnLoading={false}
      showClearButton={showClearButton}
      showClearSelection={showClearSelection}
      showSearchOnOptions={showSearchOnOptions}
      value={value}
    />
  );
};

export default BaseOwnerSelect;
