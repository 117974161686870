import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

import type { IconProps } from '@components/UI/Icon';
import Icon from '@components/UI/Icon';

import Tooltip from '../Tooltip';

import { ItemLinkText, ItemStyled } from './ItemLinkStyled';

interface Props {
  block?: boolean;
  icon: IconProps['name'];
  iconTooltip?: string;
  linkUrl?: string;
  text?: string;
}

const ItemLink: React.FC<Props> = ({ block, icon, iconTooltip, linkUrl, text }) => {
  return (
    <ItemStyled as={linkUrl ? RouterLink : 'span'} className={clsx(block && 'block')} to={linkUrl}>
      <Tooltip content={iconTooltip}>
        <Icon mr={0.5} name={icon} size="16px" />
      </Tooltip>
      {text && <ItemLinkText>{text}</ItemLinkText>}
    </ItemStyled>
  );
};

export default ItemLink;
