import React, { useMemo, useState } from 'react';
import { FilterValue, SortingRule } from 'react-table';
import { useSetRecoilState } from 'recoil';

import { MetadataObjectType, metadataType } from '@atoms';

import { SortDirection } from './sortableTableLocally';

/*
 * This filters interface is primarily created for Full Tables Page
 * Interface:
 * The interface includes all options the tables can be filtered.
 * This interface is used for searching and filtering
 */

export interface FilterOptions {
  // used for elastic search
  active?: boolean;
  // used in multiple places
  bifolders?: string[];
  bytes?: number;
  // sidebar fitlers
  category_tags?: string[];
  // Glue source type
  cloud_object_format?: string;
  // for columns
  columns?: string[];
  custom_attributes_search?: boolean;
  dashboard_elements_search?: boolean;
  // schema changes
  data_source?: string;
  // for tags
  data_type?: string;
  data_types?: string[];
  databases?: string[];
  datasources?: string[];
  day_of_week?: number;
  dbt_tags?: string[];
  distance?: string;
  // docs
  doc_type?: string;
  dsuser_created_by?: string[];
  dsusers?: string[];
  // cost analysis
  end_date?: string;
  explores?: string[];
  // extra filters for explores
  field_types?: string[];
  folders?: string[];
  groups?: string[];
  hour?: number;
  include?: string[];
  is_hidden?: boolean;
  is_join_key?: boolean;
  // tags
  kind?: string;
  message?: string;
  // dsusers filters
  mode_service_account?: boolean;
  models?: string[];
  new?: boolean;
  no_business_owner?: string;
  no_description?: string;
  no_downstream_lineage?: boolean;
  no_external_tags?: string;
  no_fuzzy?: boolean;
  no_popularity?: string;
  no_tags?: string;
  no_technical_owner?: string;
  no_upstream_lineage?: boolean;
  object_type?: string[];
  order?: string;
  owners?: string[];
  page?: number;
  page_size?: number;
  periscope_service_account?: boolean;
  popularity?: string[];
  popularityRawValues?: string[];
  // extra filters for lookmlviews
  projects?: string[];
  q?: string;
  query?: string;
  // extra filters for Tableau Fields
  role_types?: string[];
  // for tables
  row_count?: number;
  schema?: string[];
  // search filters
  search?: string;
  search_all?: string;
  // extra filters for dashboards
  search_bifolder?: string;
  search_business_owner?: string;
  // for comments
  search_comment?: string;
  search_commented_by?: string;
  // pii
  search_created_by?: string;
  search_data_source?: string;
  search_database?: string;
  search_description?: string;
  search_dsuser_created_by?: string;
  // users tables
  search_email?: string;
  search_group_label?: string;
  search_item_count?: string;
  search_label?: string;
  search_model?: string;
  search_name?: string;
  search_project_name?: string;
  // admin recent queries
  search_query?: string;
  search_raw_sql?: string;
  search_role?: string;
  search_schema?: string;
  search_source_description?: string;
  search_source_name?: string;
  // extra filters for mode queries / lookml views
  search_sql?: string;
  search_table?: string;
  search_table_name?: string;
  search_tags?: string;
  search_target?: string;
  search_team?: string;
  search_technical_owner?: string;
  search_type?: string;
  search_user?: string;
  search_view?: string;
  search_warehouse?: string;
  sigma_service_account?: boolean;
  sortColumn?: string;
  sortDirection?: SortDirection;
  start_date?: string;
  status_tags?: string[];
  tables?: string[];
  tags_search?: boolean;
  target?: string;
  target_full_name?: string;
  target_models?: string;
  team?: string;
  teams?: string[];
  // for tags to be filtered by status or category
  type?: string;
  // extra filters for tableau views
  types?: string[];
  user?: string;
  users?: string[];
  views?: string[];
  was_last_run_not_successful?: boolean;
}
/* eslint-enable typescript-sort-keys/interface */

/**
 * Created object for request to backend
 *
 * @param filter the current state of the filter.
 * Type FilterInterface
 * @returns object based on the set filters
 */
export const setParams = (filter: FilterOptions) => {
  // When search filters change, we need reload_params
  return {
    ...{ page: filter.page ?? 1 },
    ...{ search: filter.search },
    ...{ search_schema: filter.search_schema },
    ...{ search_name: filter.search_name },
    ...{ search_role: filter.search_role },
    ...{ search_email: filter.search_email },
    ...{ search_description: filter.search_description },
    ...{ search_business_owner: filter.search_business_owner },
    ...{ search_technical_owner: filter.search_technical_owner },
    ...{ search_tag_name: filter.search_tags },
    ...{ search_type: filter.search_type },
    ...{ search_created_by: filter.search_created_by },
    ...{ search_item_count: filter.search_item_count },
    ...{ search_all: filter.search_all },
    ...{ search_dsuser_created_by: filter.search_dsuser_created_by },
    ...{ search_raw_sql: filter.search_raw_sql },
    ...{ search_warehouse: filter.search_warehouse },
    ...{ search_group_label: filter.search_group_label },
    ...{ search_model: filter.search_model },
    ...{ search_view: filter.search_view },
    ...{ search_label: filter.search_label },
    ...{ searh_project_name: filter.search_project_name },
    ...{ search_bifolder: filter.search_bifolder },
    ...{ search_query: filter.search_query },
    ...{ search_user: filter.search_user },
    ...{ search_team: filter.search_team },
    ...{ search_table: filter.search_table },
    ...{ search_database: filter.search_database },
    ...{ search_sql: filter.search_sql },
    ...{ distance: filter.distance },
    ...{ search_target: filter.search_target },
    ...{ search_commented_by: filter.search_commented_by },
    ...{ search_comment: filter.search_comment },
    ...{ start_date: filter.start_date },
    ...{ end_date: filter.end_date },
    ...{ no_external_tags: filter.no_external_tags },
    ...{ popularity: filter.popularity && `${filter.popularity.join(',')}` },
    ...{ no_popularity: filter.no_popularity },
    ...{ no_technical_owner: filter.no_technical_owner },
    ...{ no_business_owner: filter.no_business_owner },
    ...{ status_tags: filter.status_tags && `${filter.status_tags.join(',')}` },
    ...{ category_tags: filter.category_tags && `${filter.category_tags.join(',')}` },
    ...{ dbt_tags: filter.dbt_tags && `${filter.dbt_tags.join(',')}` },
    ...{ owners: filter.owners && `${filter.owners.join(',')}` },
    ...{ no_tags: filter.no_tags },
    ...{ no_downstream_lineage: filter.no_downstream_lineage },
    ...{ no_upstream_lineage: filter.no_upstream_lineage },
    ...{ no_description: filter.no_description },
    ...{ teams: filter.teams && `${filter.teams.join(',')}` },
    ...{ users: filter.users && `${filter.users.join(',')}` },
    ...{ dsusers: filter.dsusers && `${filter.dsusers.join(',')}` },
    ...{ databases: filter.databases && `${filter.databases.join(',')}` },
    ...{ folders: filter.folders && `${filter.folders.join(',')}` },
    ...{ models: filter.models && `${filter.models.join(',')}` },
    ...{ groups: filter.groups && `${filter.groups.join(',')}` },
    ...{ page_size: filter.page_size && filter.page_size },
    ...{ query: filter.query },
    ...{ schemas: filter.schema && `${filter.schema.join(',')}` },
    ...{ tables: filter.tables && `${filter.tables.join(',')}` },
    ...{ columns: filter.columns && `${filter.columns.join(',')}` },
    ...{ datasources: filter.datasources && `${filter.datasources.join(',')}` },
    ...{ data_type: filter.data_type },
    ...{ types: filter.type },
    ...{ type: filter.type },
    ...{ user: filter.user },
    ...{ team: filter.team },
    ...{ target: filter.target },
    ...{ target_full_name: filter.target_full_name },
    ...{ order: filter.order },
    ...{ new: filter.new || undefined },
    ...{ bifolders: filter.bifolders && `${filter.bifolders.join(',')}` },
    ...{ explores: filter.explores && `${filter.explores.join(',')}` },
    ...{ views: filter.views && `${filter.views.join(',')}` },
    ...{ dsuser_created_by: filter.dsuser_created_by && `${filter.dsuser_created_by.join(',')}` },
    ...{ was_last_run_not_successful: filter.was_last_run_not_successful },
    ...{ is_join_key: filter.is_join_key },
    ...{ data_types: filter.data_types && `${filter.data_types.join(',')}` },
    ...{ types: filter.types && `${filter.types.join(',')}` },
    ...{ field_types: filter.field_types && `${filter.field_types.join(',')}` },
    ...{ role_types: filter.role_types && `${filter.role_types.join(',')}` },
    ...{ is_hidden: filter.is_hidden },
    ...{ q: filter.q },
    ...{ dashboard_elements_search: filter.dashboard_elements_search },
    ...{ no_fuzzy: filter.no_fuzzy },
    ...{ include: filter.include && `${filter.include.join(',')}` },
    ...{ tags_search: filter.tags_search },
    ...{ custom_attributes_search: filter.custom_attributes_search },
    ...{ mode_service_account: filter.mode_service_account },
    ...{ periscope_service_account: filter.periscope_service_account },
    ...{ sigma_service_account: filter.sigma_service_account },
    ...{ object_type: filter.object_type && `${filter.object_type.join(',')}` },
    ...{ active: filter.active },
    ...{ kind: filter.kind },
    ...{ doc_type: filter.doc_type },
    ...{ projects: filter.projects && `${filter.projects.join(',')}` },
    ...{ cloud_object_format: filter.cloud_object_format },
    ...{ target_models: filter.target_models },
    ...{ search_source_description: filter.search_source_description },
    ...{ search_source_name: filter.search_source_name },
    data_source: filter.data_source,
    day_of_week: filter.day_of_week,
    hour: filter.hour,
    message: filter.message,
    search_data_source: filter.search_data_source,
    search_table_name: filter.search_table_name,
  };
};

export interface FilterServiceInterface {
  changePage: (page: number) => void;
  filter: FilterOptions;
  globalSearch: (filterState: FilterValue) => void;
  initialTableSortState: Array<{ desc: boolean; id: string }>;
  search: (filterState: FilterValue) => void;
  sort: (sortState: SortingRule<Partial<any>>[]) => void;
  toggleHidden: () => void;
  toggleJoinKey: () => void;
  toggleLoadingIssues: () => void;
  toggleNoDownstreamLineage: () => void;
  toggleNoUpstreamLineage: () => void;
  updateBIFolders: (guid: string) => void;
  updateCategoryTags: (guid: string) => void;
  updateColumnDataType: (guid: string) => void;
  updateCreatedBy: (guid: string) => void;
  updateDataSources: (guid: string) => void;
  updateDataType: (value: string) => void;
  updateDbtTags: (guid: string) => void;
  updateDescriptions: () => void;
  updateFormatType: (type: string) => void;
  updateMetadataType: (value: MetadataObjectType) => void;
  updateNew: () => void;
  updateNoFuzzy: (no_fuzzy: boolean) => void;
  updateNoFuzzyAndInclude: (no_fuzzy: boolean, include?: string[]) => void;
  updateNoPopularity: () => void;
  updateNoTags: () => void;
  updateOnwers: (guid: string) => void;
  updatePage: (page: number) => void;
  updatePopularity: (value: string) => void;
  updateSchema: (guid: string) => void;
  updateSearchFilters: (key: string) => void;
  updateStatusTags: (guid: string) => void;
  updateTable: (guid: string) => void;
  updateTypes: (guid: string) => void;
}

/**
 * Hook to update the filter state. On update of this state the setTableFilter or setTagFilter
 * is called
 *
 * @param filterDefaults an initial filter object. No required
 *
 * @returns `{filter,
 *            updatePopularity,
 *            updateCategoryTags,
 *            updateStatusTags,
 *            updateDataSources,
 *            updateDataType,
 *            updateColumnDataType,
 *            updateDescriptions,
 *            updateNoTags,
 *            updateOnwers}`
 *  All update functions take in a guid
 *  and as a result updated the filter state
 */

export function useUpdateFilters(
  filterDefaults: FilterOptions,
  searchConfig: { [key: string]: keyof FilterOptions },
  sortConfig: { [key: string]: string },
  defaultSorting: string = '-popularity',
): FilterServiceInterface {
  const setMetadataType = useSetRecoilState(metadataType);
  const [filter, setFilter] = useState<FilterOptions>(filterDefaults || {});

  /**
   * Helper function to calculate upper
   * value for popularity range
   */
  const upper = (value: number) => {
    if (value === 0.5) value = 0;
    return Math.floor(((value + 1) / 6) * 100);
  };
  /**
   * Helper function to calculate lower
   * value for popularity range
   */
  const lower = (value: number) => {
    if (value === 0.5) value = 0;
    return Math.floor((value / 6) * 100);
  };

  /**
   * Helper function to calculate new state given a value and the
   * previous state.
   */
  const getNewState = (value: string, state?: string[]) => {
    if (state) {
      if (state.includes(value)) {
        return state.filter((id) => id !== value);
      }

      return [...state, value];
    }

    return [value];
  };

  const updatePopularity = (value: string) => {
    let popularityFilter = filter.popularity;
    let { popularityRawValues } = filter;

    const popularityRange = `${lower(Number(value))}-${upper(Number(value))}`;
    if (popularityFilter) {
      if (popularityFilter.includes(popularityRange)) {
        popularityFilter = popularityFilter.filter((val) => val !== popularityRange);
      } else {
        popularityFilter = [...popularityFilter, popularityRange];
      }
    } else {
      popularityFilter = [popularityRange];
    }

    if (popularityRawValues) {
      if (popularityRawValues.includes(value)) {
        popularityRawValues = popularityRawValues.filter((val) => val !== value);
      } else {
        popularityRawValues = [...popularityRawValues, value];
      }
    } else {
      popularityRawValues = [value];
    }

    setFilter({
      ...filter,
      no_popularity: undefined,
      page: 1,
      popularity: popularityFilter,
      popularityRawValues,
    });
  };

  const updateDataSources = (guid: string) => {
    const state = filter.datasources;
    const newState = getNewState(guid, state);

    setFilter({
      ...filter,
      datasources: newState,
      page: 1,
    });
  };

  const updateColumnDataType = (guid: string) => {
    const state = filter.data_types;
    const newState = getNewState(guid, state);
    setFilter({
      ...filter,
      data_types: newState,
      page: 1,
    });
  };

  const updateTypes = (id: string) => {
    const state = filter.types;
    const newState = getNewState(id, state);
    setFilter({
      ...filter,
      page: 1,
      types: newState,
    });
  };

  const updateDescriptions = () => {
    filter.no_description === undefined
      ? setFilter({
          ...filter,
          no_description: '',
          page: 1,
        })
      : setFilter({
          ...filter,
          no_description: undefined,
          page: 1,
        });
  };

  const updateOnwers = (guid: string) => {
    if (guid === 'no_business_owner') {
      filter.no_business_owner === undefined
        ? setFilter({
            ...filter,
            no_business_owner: '',
            page: 1,
          })
        : setFilter({
            ...filter,
            no_business_owner: undefined,
            page: 1,
          });
    } else if (guid === 'no_technical_owner') {
      filter.no_technical_owner === undefined
        ? setFilter({
            ...filter,
            no_technical_owner: '',
            page: 1,
          })
        : setFilter({
            ...filter,
            no_technical_owner: undefined,
            page: 1,
          });
    } else {
      const state = filter.teams;
      const newState = getNewState(guid, state);
      setFilter({
        ...filter,
        page: 1,
        teams: newState,
      });
    }
  };

  const updateNoTags = () => {
    filter.no_tags === undefined
      ? setFilter({
          ...filter,
          no_tags: '',
          page: 1,
        })
      : setFilter({
          ...filter,
          no_tags: undefined,
          page: 1,
        });
  };

  const toggleNoDownstreamLineage = () => {
    setFilter({
      ...filter,
      no_downstream_lineage: !filter.no_downstream_lineage,
      page: 1,
    });
  };

  const toggleNoUpstreamLineage = () => {
    setFilter({
      ...filter,
      no_upstream_lineage: !filter.no_upstream_lineage,
      page: 1,
    });
  };

  const updateCategoryTags = (guid: string) => {
    const state = filter.category_tags;
    const newState = getNewState(guid, state);
    setFilter({
      ...filter,
      category_tags: newState,
      page: 1,
    });
  };

  const updateStatusTags = (guid: string) => {
    const state = filter.status_tags;
    const newState = getNewState(guid, state);
    setFilter({
      ...filter,
      page: 1,
      status_tags: newState,
    });
  };

  const updateDbtTags = (guid: string) => {
    const state = filter.dbt_tags;
    const newState = getNewState(guid, state);
    setFilter({
      ...filter,
      dbt_tags: newState,
      page: 1,
    });
  };

  const updateDataType = (value: string) => {
    /*
     * For types the operation is replaced. There can only
     * be one type at a time
     */
    setFilter({
      ...filter,
      data_type: value,
      page: 1,
    });
  };

  const updateSearchFilters = (key: string) => {
    const state = filter.include;
    let newState = getNewState(key, state);

    // If the state of users facet is updated we must also update ds-users as they live under the same facet label.
    if (key === 'users') {
      newState = getNewState('ds-users', newState);
    }
    setFilter({
      ...filter,
      include: newState.length === 0 ? undefined : newState,
      page: 1,
    });
  };

  const updateNew = () => {
    filter.new
      ? setFilter({
          ...filter,
          new: undefined,
          page: 1,
        })
      : setFilter({
          ...filter,
          new: true,
          page: 1,
        });
  };

  const updateNoFuzzyAndInclude = (no_fuzzy: boolean, include?: string[]) => {
    setFilter({
      ...filter,
      include,
      no_fuzzy,
    });
  };

  const updateNoFuzzy = (no_fuzzy: boolean) => {
    setFilter({ ...filter, no_fuzzy });
  };

  const updateNoPopularity = () => {
    filter.no_popularity === undefined
      ? setFilter({
          ...filter,
          no_popularity: '',
          page: 1,
          popularity: undefined,
        })
      : setFilter({
          ...filter,
          no_popularity: undefined,
          page: 1,
          popularity: undefined,
        });
  };

  const updatePage = (page: number) => {
    setFilter({ ...filter, page });
  };

  const updateMetadataType = (value: MetadataObjectType) => {
    /*
     * Update metadat type does not actually update the filter.
     * It updates the recoil value which causes a rerender. This is
     * currently only being used for tables and columns but can be
     * extended.
     * The function is kept here because the component does not need
     * to know any detail on how we update information.
     */
    setMetadataType(value);
  };

  const updateCreatedBy = (guid: string) => {
    const state = filter.dsuser_created_by;
    const newState = getNewState(guid, state);
    setFilter({
      ...filter,
      dsuser_created_by: newState,
      page: 1,
    });
  };

  const updateBIFolders = (guid: string) => {
    const state = filter.bifolders;
    const newState = getNewState(guid, state);

    setFilter({
      ...filter,
      bifolders: newState,
      page: 1,
    });
  };

  const updateSchema = (guid: string) => {
    const state = filter.schema;
    const newState = getNewState(guid, state);
    setFilter({
      ...filter,
      page: 1,
      schema: newState,
    });
  };

  const updateTable = (guid: string) => {
    const state = filter.tables;
    const newState = getNewState(guid, state);
    setFilter({
      ...filter,
      page: 1,
      tables: newState,
    });
  };

  const updateFormatType = (value: string) => {
    setFilter({
      ...filter,
      cloud_object_format: value,
      page: 1,
    });
  };

  const toggleLoadingIssues = () => {
    filter.was_last_run_not_successful === undefined
      ? setFilter({
          ...filter,
          page: 1,
          was_last_run_not_successful: true,
        })
      : setFilter({
          ...filter,
          page: 1,
          was_last_run_not_successful: undefined,
        });
  };

  const toggleJoinKey = () => {
    filter.is_join_key === undefined
      ? setFilter({
          ...filter,
          is_join_key: true,
          page: 1,
        })
      : setFilter({
          ...filter,
          is_join_key: undefined,
          page: 1,
        });
  };

  const toggleHidden = () => {
    setFilter({
      ...filter,
      is_hidden: !filter.is_hidden,
      page: 1,
    });
  };

  const search = React.useCallback(
    (filterState: FilterValue[]) => {
      const newFilterState = Object.fromEntries(
        Object.entries(searchConfig).map(([columnId, columnParam]) => {
          const newValue: { id: string; value: string } = filterState.find(
            ({ id }) => id === columnId,
          );
          return [columnParam, newValue?.value];
        }),
      );

      setFilter((prev) => ({
        ...prev,
        ...newFilterState,
        page: 1,
      }));
    },
    [filter, searchConfig],
  );

  const globalSearch = React.useCallback(
    (filterValue: FilterValue) => {
      let newFilterState = { ...filter };
      if (!filterValue) {
        newFilterState = { ...newFilterState, search_all: undefined };
      } else {
        newFilterState = { ...newFilterState, search_all: filterValue };
      }

      setFilter((prev) => ({
        ...prev,
        ...newFilterState,
        page: 1,
      }));
    },
    [filter, searchConfig],
  );

  const sort = React.useCallback(
    (sortState: SortingRule<Partial<any>>[]) => {
      let column: string | undefined;
      let direction: SortDirection;
      let order: string | undefined;
      const currentSort = sortState.length > 0 ? sortState[0] : undefined;
      if (currentSort) {
        column = sortConfig[currentSort.id];
        direction = currentSort.desc ? 'descending' : 'ascending';
        order = `${direction === 'descending' ? '-' : ''}${column}`;
      } else {
        order = defaultSorting;
        column = defaultSorting.startsWith('-') ? defaultSorting.slice(1) : defaultSorting;
        direction = defaultSorting.startsWith('-') ? 'descending' : 'ascending';
      }

      setFilter((prev) => ({
        ...prev,
        order,
        sortColumn: column,
        sortDirection: direction,
      }));
    },
    [sortConfig, defaultSorting],
  );

  const changePage = React.useCallback(
    (pageIndex: number) => setFilter((prev) => ({ ...prev, page: pageIndex })),
    [],
  );

  const initialTableSortState: Array<{ desc: boolean; id: string }> = useMemo(() => {
    let param = filter.order ?? '';
    if (!param) return [];
    const desc = param.startsWith('-');
    if (desc) param = param.slice(1);
    return [{ desc, id: Object.keys(sortConfig).find((key) => sortConfig[key] === param) ?? '' }];
  }, [filter.order, sortConfig]);

  return {
    changePage,
    filter,
    globalSearch,
    initialTableSortState,
    search,
    sort,
    toggleHidden,
    toggleJoinKey,
    toggleLoadingIssues,
    toggleNoDownstreamLineage,
    toggleNoUpstreamLineage,
    updateBIFolders,
    updateCategoryTags,
    updateColumnDataType,
    updateCreatedBy,
    updateDataSources,
    updateDataType,
    updateDbtTags,
    updateDescriptions,
    updateFormatType,
    updateMetadataType,
    updateNew,
    updateNoFuzzy,
    updateNoFuzzyAndInclude,
    updateNoPopularity,
    updateNoTags,
    updateOnwers,
    updatePage,
    updatePopularity,
    updateSchema,
    updateSearchFilters,
    updateStatusTags,
    updateTable,
    updateTypes,
  };
}
