import styled from '@emotion/styled';
import type { ElementType } from 'react';
import type { LinkProps } from 'react-router-dom';

import type { StyledBoxProps } from '@components/Box';
import Box from '@components/Box';
import Highlighter from '@components/Highlighter';

export interface StyledBreadcrumbProps extends StyledBoxProps {
  as?: ElementType;
  className?: string;
  clickable?: boolean;
  to?: LinkProps['to'];
}

export const StyledBreadcrumb = styled(Box)<StyledBreadcrumbProps>`
  color: ${({ color, theme }) => color || theme.colors.gray[500]};
  word-break: break-word;

  &:hover {
    color: ${({ color, theme }) => color || theme.colors.gray[500]};
    text-decoration: ${({ clickable }) => (clickable ? 'underline' : 'none')};
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  }
`;

StyledBreadcrumb.defaultProps = {
  fontFamily: 'primary',
  fontSize: 'default',
  fontWeight: 'bold',
  noDefault: true,
};

interface StyledBreadcrumbSeparatorProps {
  breadcrumbSeparator: string;
}

interface StyledBreadcrumbItemProps extends StyledBoxProps {
  as?: ElementType;
  clickable?: boolean;
  to?: LinkProps['to'];
}

export const StyledBreadcrumbItemText = styled(Highlighter)``;

export const StyledBreadcrumbItem = styled(Box)<StyledBreadcrumbItemProps>`
  color: inherit;
  cursor: ${({ clickable }) => (clickable ? 'cursor' : 'inherit')};
  display: inline-grid;
  grid-auto-flow: column;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: inherit;

    ${StyledBreadcrumbItemText} {
      text-decoration: ${({ clickable }) => (clickable ? 'underline' : 'none')};
    }
  }
`;

export const StyledBreadcrumbSeparator = styled.span<StyledBreadcrumbSeparatorProps>`
  width: ${({ theme }) => theme.space(2)};
  color: ${({ theme }) => theme.colors.gray[500]};
  text-align: center;
`;
