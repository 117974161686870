import styled from '@emotion/styled';

export const StyledFormSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 0.5rem;
`;

export const StyledMetricForm = styled.div`
  .ui.form {
    display: flex;
    flex-flow: column nowrap;
    min-height: 30rem;
    max-height: 85vh;
    gap: ${({ theme }) => theme.space(1)};

    ${({ theme }) => theme.media.down('md')`
      min-height: auto;
  `}

    .fields {
      width: 100%;
    }
    .field {
      width: 100%;
    }
  }

  .dropdown,
  .type,
  .owner {
    display: flex;
    flex-direction: row;
    margin: 7px 0 2px 0;
  }

  .type > label,
  .dropdown > label,
  .owner > label {
    display: flex;
    font-size: 0.873rem;
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
    flex-basis: 25%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .dropdown {
    .field {
      display: flex;
      flex-grow: 1;
    }
  }

  .owner {
    .field {
      display: flex;
      flex-grow: 1;
    }
  }

  .ui.form .field .ui.input input,
  .ui.search.dropdown > .text {
    font-size: 0.875rem;
    width: 100%;
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  }

  .editDimensionsResults {
    height: 30rem;
  }
`;
