import React, { useMemo, useState } from 'react';
import type { Cell } from 'react-table';

import { useFetchCostAnalysisTables } from '@api/costAnalysis';
import type { TableModel } from '@api/tables/TableModel';
import NotApplicable from '@components/NotApplicable';
import EditableTaggedItemCell from '@components/Table/Cells/EditableTaggedItemCell';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { FilterOptions, setParams, useUpdateFilters } from '@utils/filters';

const SORT_CONFIG: { [key: string]: string } = {
  bytes: 'bytes',
  name: 'name',
  popularity: 'popularity',
  totalRun: 'total_run',
};

const SEARCH_CONFIG: { [key: string]: keyof FilterOptions } = {
  name: 'search',
};

const REQUEST_CONFIG: FilterOptions = {
  order: `-${SORT_CONFIG.bytes}`,
  page: 1,
  page_size: 20,
};

const INITIAL_TABLE_SORT = [{ desc: true, id: 'bytes' }];

const HIDDEN_COLUMNS = ['tags'];

interface TableByQueryCountTableProps {
  range: [string, string];
}

const TableByQueryCountTable: React.FC<TableByQueryCountTableProps> = ({ range }) => {
  const [showFilter, setShowFilter] = useState(false);
  const { changePage, filter, search, sort } = useUpdateFilters(
    REQUEST_CONFIG,
    SEARCH_CONFIG,
    SORT_CONFIG,
    REQUEST_CONFIG.order,
  );

  const { data, isLoading } = useFetchCostAnalysisTables({
    params: { ...setParams(filter), end_date: range[1], start_date: range[0] },
  });

  const columns: ColumnConfig<TableModel>[] = useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: ({ column, row: { original }, state }: Cell<TableModel>) => (
          <LinkedCell
            breadcrumbFixedUrl={original.routePath}
            column={column}
            item={original}
            noLink
            showBreadcrumbs
            showDataSourceIcon
            showIcon
            state={state}
          />
        ),
        Header: 'Table Name',
        accessor: (d) => d?.name,
        disableHiding: true,
        id: 'name',
        width: '130%',
      },
      {
        Cell: ({ row: { original } }: Cell<TableModel>) =>
          original.formattedBytes ?? <NotApplicable />,
        Header: 'Table Size',
        accessor: 'formattedBytes',
        disableFilters: true,
        id: 'bytes',
        width: '8rem',
      },
      {
        Header: 'Query Count',
        accessor: (d) => d.formattedTotalRun,
        disableFilters: true,
        id: 'totalRun',
        width: '8rem',
      },
      {
        Cell: (props: Cell<TableModel>) => {
          const { row } = props;
          const table = row.original;
          return <EditableTaggedItemCell {...props} isDataSourceEditable={false} obj={table} />;
        },
        Header: 'Tags',
        accessor: (d) => d.taggedItems,
        disableFilters: true,
        disableSortBy: true,
        id: 'tags',
        width: '130%',
      },
      {
        Cell: ({ row: { original } }: Cell<TableModel>) => (
          <PopularityCell popularity={original.popularity} />
        ),
        Header: PopularityCellHeader,
        accessor: (d) => d.popularity,
        disableFilters: true,
        disableResizing: true,
        id: 'popularity',
        sortDescFirst: true,
        width: 120,
      },
    ],
    [],
  );

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        columns={columns}
        compact
        data={data?.results ?? []}
        disableRowSelect
        initialState={{
          hiddenColumns: HIDDEN_COLUMNS,
          sortBy: INITIAL_TABLE_SORT,
        }}
        loading={isLoading}
        manualFilters
        manualPagination
        manualSortBy
        setFilters={search}
        setSortBy={sort}
        showFilter={showFilter}
        sortable
        toggleFilter={() => {
          setShowFilter((prev) => !prev);
        }}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default TableByQueryCountTable;
