import type { StyledBoxProps } from '@components/Box/Box.styles';

export interface TreeColumnsConfigProps extends StyledBoxProps {}

export type TreeColumnsConfigResult = [
  TreeColumnsConfigProps | null,
  TreeColumnsConfigProps | null,
  TreeColumnsConfigProps | null,
  TreeColumnsConfigProps | null,
];

interface GetWidthsConfigArgs {
  showDescriptions?: boolean;
  showUsage?: boolean;
}

const shareStyles = {
  compDisplay: 'flex',
  alignItems: 'center',
};

const nameColumnStyles = {
  flexGrow: 1,
  minWidth: '40%',
};

const descriptionColumnStyles = {
  compWidth: '30%',
  minWidth: '30%',
  maxWidth: '30%',
};

/**
 * null means that column should be rendered.
 */
const getTreeColumnsConfig = ({
  showDescriptions,
  showUsage,
}: GetWidthsConfigArgs): TreeColumnsConfigResult => {
  if (showDescriptions && !showUsage) {
    return [
      { ...shareStyles, ...nameColumnStyles },
      { ...shareStyles, ...descriptionColumnStyles },
      null,
      { ...shareStyles, minWidth: '100px' },
    ];
  }

  if (showUsage && !showDescriptions) {
    return [
      { ...shareStyles, ...nameColumnStyles },
      null,
      { ...shareStyles, minWidth: '100px' },
      { ...shareStyles, minWidth: '100px' },
    ];
  }

  if (showUsage && showDescriptions) {
    return [
      { ...shareStyles, ...nameColumnStyles },
      { ...shareStyles, ...descriptionColumnStyles },
      { ...shareStyles, minWidth: '100px' },
      { ...shareStyles, minWidth: '100px' },
    ];
  }

  return [
    { ...shareStyles, ...nameColumnStyles },
    null,
    null,
    { ...shareStyles, minWidth: '100px' },
  ];
};

export default getTreeColumnsConfig;
