import React, { useEffect, useState } from 'react';

import { ColumnModel } from '@api/columns/ColumnModel';
import { useFetchJoins } from '@api/joins';
import { SearchModel } from '@api/search/SearchModel';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import QuickSearchInput from '@components/QuickSearch/QuickSearchInput';
import stripSpaces from '@utils/stripSpaces';

import SelectedSearchItemPill from './SelectedSearchItemPill';

interface ColumnSelectProps {
  initialDimensionObjects?: ColumnModel[];
  parentGuid?: string;
  setSelectedDimensions: React.Dispatch<React.SetStateAction<string[]>>;
}

const QUERY = stripSpaces(`{
  conditions {
    right_column {
      guid,
      name,
      search_name,
      field_type,
      data_type,
      external_type,
      is_join_key,
      data_types
    }
  }
}`);

const ColumnSelect: React.FC<ColumnSelectProps> = ({
  initialDimensionObjects,
  parentGuid,
  setSelectedDimensions,
}) => {
  const [selectedColumns, setSelectedColumns] = useState<(SearchModel | ColumnModel)[]>(
    initialDimensionObjects ? [...initialDimensionObjects] : [],
  );

  const { isLoading } = useFetchJoins({
    enabled: !initialDimensionObjects,
    onSuccess: (data) => {
      const columns: ColumnModel[] = [];
      data.results.forEach((join) =>
        join.conditions.forEach((condition) =>
          condition?.rightColumn ? columns.push(condition?.rightColumn) : null,
        ),
      );

      if (columns.length > 0) {
        setSelectedColumns((prev) => [...prev, ...columns]);
      }
    },
    params: {
      order: '-popularity',
      query: QUERY,
      source_tables: parentGuid,
    },
  });

  const onResultSelect = (result: SearchModel) => {
    setSelectedColumns([result, ...selectedColumns]);
  };

  const onRemoveItem = (id?: string) => {
    setSelectedColumns(
      selectedColumns.filter((column) => {
        const guid = 'id' in column ? column.id : column.guid;
        return guid !== id;
      }),
    );
  };

  const columnGuids = React.useMemo(() => {
    return selectedColumns.map((column) => {
      return 'id' in column ? column.id : column.guid;
    });
  }, [selectedColumns]);

  // Filter already selected results before displaying to the user.
  const filterResults = (data: SearchModel[]) => {
    return data.filter((item) => !columnGuids.includes(item.id));
  };

  useEffect(() => {
    setSelectedDimensions(columnGuids);
  }, [columnGuids, setSelectedDimensions]);

  if (isLoading) return <CircularLoader centered compSize={5} cover />;

  return (
    <>
      <QuickSearchInput
        filters={[
          {
            filters: [{ field: 'parent_guid', values: [parentGuid!] }],
            indexes: ['columns'],
          },
        ]}
        onBeforeResult={filterResults}
        onResultSelect={onResultSelect}
      />
      <Box compHeight="16rem" compWidth="100%" mt={1} noDefault overflow="auto">
        {selectedColumns.map((column) => {
          return (
            <div key={column.guid} style={{ marginBottom: '0.5rem', width: '100%' }}>
              <SelectedSearchItemPill onRemove={onRemoveItem} selectedItem={column} />
            </div>
          );
        })}
      </Box>
    </>
  );
};

export default ColumnSelect;
