import styled from '@emotion/styled';

interface QuickSearchInputStyleProps {
  showFacets?: boolean;
  showTabs?: boolean;
}

const QuickSearchInputStyle = styled.div<QuickSearchInputStyleProps>`
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: flex-start;

  .ui.search {
    width: 100%;
  }

  .ui.search .prompt {
    border-radius: 0.28571429rem;
  }

  .ui.icon {
    width: 100%;
    position: relative;
    z-index: ${({ theme }) => theme.zIndex.floatingElement};
  }
  .ui.icon.input > input {
    font-size: 0.875rem;
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    width: 100%;
    color: ${({ theme }) => theme.colors.v1.gray[700]};

    &::placeholder {
      font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
      color: ${({ theme }) => theme.colors.v1.gray[300]};
    }
  }

  .with-results .ui.icon.input > input {
    border-radius: 6px 6px 0 0;
  }

  .ui.icon .search.icon {
    display: none;
  }

  .search-clear {
    font-size: 1.5625rem;
    fill: grey;
    position: absolute;
    right: 0.9375rem;
    top: 12px;
    cursor: pointer;
    z-index: ${({ theme }) => theme.zIndex.floatingElement};
  }

  .ui.search > .results {
    position: absolute;
    background: #fff;
    width: 100%;
    margin-top: ${({ showFacets, showTabs }) => {
      if (showFacets) return '97px';
      if (showTabs) return '37px';
      return '0px';
    }};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    border-radius: 0 0 5px 5px;
    border: 0;
    z-index: ${({ theme }) => theme.zIndex.floatingElement};
    max-height: 16rem;
    overflow-y: auto;
    letter-spacing: 0.3px;

    .message {
      padding: 0;
      .header,
      .description {
        padding: 1.25rem 1.5rem;
        border-bottom: 1px solid #e2e9f0;
      }
      .header {
        font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
        font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
        padding-left: 2rem;
      }
      .description {
        font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
      }
    }
  }

  .topsearch .search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: ${({ theme }) => theme.zIndex.floatingElement};
  }
`;

export default QuickSearchInputStyle;
