import { Expose, Transform } from 'class-transformer';
import moment from 'moment';

import { LookerExploreData, LookerExploreModel } from './LookerExploreModel';

export interface ExploreUserQuerySummaryData extends LookerExploreData {
  count: number;
  lastQueried: moment.Moment;
}

export class ExploreUserQuerySummaryModel extends LookerExploreModel {
  @Expose({ name: 'last_queried' })
  @Transform((value) => moment(value))
  lastQueried?: moment.Moment;

  count!: number;
}
