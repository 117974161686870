import React, { useEffect, useRef, useState } from 'react';
import { Divider, DropdownProps } from 'semantic-ui-react';

import invalidateCache from '@api/invalidateCache';
import { usePatchMetric } from '@api/metrics';
import { QueryModel } from '@api/queries/QueryModel';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import CodeEditor from '@components/CodeEditor';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import Checkbox from '@components/UI/Form/Checkbox';
import InputLabel from '@components/UI/Form/InputLabel';
import { ModalHeader } from '@components/UI/Modal';
import useWindowSize from '@hooks/useWindowSize';
import { QueryLogRecordModel } from '@models/QueryLogRecordModel';
import sqlFormat from '@utils/sqlFormat';
import useLocalStorage from '@utils/useLocalStorage';

import ModalWithSidebar, { ModalContainer, ModalSidebar } from '../ModalWithSidebar';

import { LastQuerySection, QuerySection, TablesQueriedSection } from './components';
import { StyledDropdown, StyledQueryModalButtonsContainer } from './QueryModal.styles';

export interface QueryModalProps {
  codeString?: string;
  dataSourceType?: string;
  editable?: boolean;
  hideSidebar?: boolean;
  metricGuid?: string;
  onClose?: () => void;
  query?: QueryModel;
  queryLogRecords?: QueryLogRecordModel[];
  reloadData?: () => void;
  title?: string;
}

const QueryModal: React.FC<QueryModalProps> = ({
  codeString = '',
  dataSourceType,
  editable,
  hideSidebar = false,
  metricGuid,
  onClose,
  query,
  queryLogRecords = [],
  reloadData,
  title,
}) => {
  const { isDownMd } = useWindowSize();
  const [selectedQueryRecord, setSelectedQueryRecord] = useState<QueryLogRecordModel | undefined>(
    queryLogRecords[0],
  );
  const [code, setCode] = useState(selectedQueryRecord?.query.rawSql ?? codeString);
  const lastSavedQuery = useRef(codeString);
  const [isEditing, setEditing] = useState(false);
  const [isFormatterEnabled, setStorageFormatterEnabled] = useLocalStorage(
    'sqlFormatterEnabled',
    true,
  );
  const showEditButton = editable && !isEditing;
  const showCopyButton = !isEditing && code !== '';
  const isFormattingAllowed = isFormatterEnabled && !isEditing;
  const finalCodeString = isFormattingAllowed ? sqlFormat(code, dataSourceType) : code;
  const {
    data: updateData,
    isError,
    mutate: updateMetric,
  } = usePatchMetric(metricGuid ?? '', {
    onSuccess: () => {
      invalidateCache((keys) => [keys.metrics.metric(metricGuid ?? '')]);
    },
  });
  const selectedQuery = selectedQueryRecord?.query ?? query;

  useEffect(() => {
    if (isError) {
      renderErrorToast('There was an error saving the query.');
    }

    if (updateData) {
      renderInfoToast('Query saved.');
      lastSavedQuery.current = updateData.sql ?? '';
    }
  }, [isError, updateData]);

  const closeModal = () => {
    /**
     * If we saved a new query we should reload the source data so next time the
     * modal is opened query is up-to-date.
     */

    if (codeString !== lastSavedQuery.current) {
      reloadData?.();
    }

    onClose?.();
  };

  const handleCancel = () => {
    setEditing(false);
    setCode(lastSavedQuery.current);
  };

  const handleSave = () => {
    updateMetric({ sql: code });
    setEditing(false);
  };

  const getModalSize = () => {
    if (isDownMd) {
      return { height: '95%', width: '95%' };
    }
    return { height: '80%', width: '75%' };
  };

  const handleQueryRecordChange = (
    _event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps,
  ) => {
    const queryRecord = queryLogRecords.find((item) => item.guid === data.value);
    setSelectedQueryRecord(queryRecord);
    setCode(queryRecord?.query?.rawSql ?? '');
  };

  const dropdownItems = queryLogRecords.map((item) => ({
    key: item.guid,
    text: item?.executedOn?.format('MMM DD, YYYY hh:mm:ss'),
    value: item.guid,
  }));

  const buttons = (
    <StyledQueryModalButtonsContainer>
      {isEditing && (
        <>
          <Button onClick={handleSave}>Save</Button>
          <Button onClick={handleCancel} variant="outlined">
            Cancel
          </Button>
        </>
      )}
      {showEditButton && (
        <Button onClick={() => setEditing(true)} variant="outlined">
          Edit
        </Button>
      )}
      {dropdownItems.length > 1 && (
        <StyledDropdown
          onChange={handleQueryRecordChange}
          options={dropdownItems}
          selection
          selectOnBlur={false}
          value={selectedQueryRecord?.guid ?? dropdownItems?.[0].value}
        />
      )}
    </StyledQueryModalButtonsContainer>
  );

  return (
    <ModalWithSidebar
      compHeight={getModalSize().height}
      compWidth={getModalSize().width}
      onClose={closeModal}
      size="unset"
    >
      <ModalContainer>
        <ModalHeader onClose={hideSidebar ? closeModal : undefined} title={title ?? 'SQL Query'}>
          {buttons}
        </ModalHeader>
        <Box compHeight="100px" flexGrow={1} noDefault px={3} py={2}>
          <CodeEditor
            disabled={!isEditing}
            onChange={setCode}
            placeholderText="Enter query..."
            showCopyButton={showCopyButton && Boolean(finalCodeString)}
            value={finalCodeString}
          />
        </Box>
        <Box alignItems="flex-end" compDisplay="flex" justifyContent="flex-end" mt="auto" p={3}>
          <InputLabel>
            <Checkbox
              checked={isFormattingAllowed}
              disabled={isEditing}
              onClick={() => setStorageFormatterEnabled((prev) => !prev)}
            />
            Enable formatting
          </InputLabel>
        </Box>
      </ModalContainer>
      {!hideSidebar && (
        <ModalSidebar data-testid="query-modal-sidebar" isRight>
          <ModalHeader mb={2} onClose={closeModal} />
          {selectedQuery && <TablesQueriedSection query={selectedQuery} />}
          {(selectedQueryRecord ?? selectedQuery) && <Divider />}
          {selectedQueryRecord && selectedQueryRecord.duration && selectedQueryRecord.dsuser ? (
            <QuerySection queryLogRecord={selectedQueryRecord} />
          ) : (
            <LastQuerySection guid={selectedQuery?.guid} />
          )}
        </ModalSidebar>
      )}
    </ModalWithSidebar>
  );
};

export default QueryModal;
