import React, { useCallback, useState } from 'react';
import { Cell } from 'react-table';

import { DocumentModel } from '@api/documents/DocumentModel';
import { MetricModel } from '@api/metrics/MetricModel';
import DateTime from '@components/DateTime';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import EditableTaggedItemCell from '@components/Table/Cells/EditableTaggedItemCell';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import RepresentedAsCell from '@components/Table/Cells/RepresentedAsCell';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import UserCell from '@components/Table/Cells/UserCell';
import Table from '@components/Table/Table';
import type { TableProps } from '@components/Table/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { Filter } from '@utils';

const initialSortState = [
  {
    desc: false,
    id: 'name',
  },
];

export interface DocumentsTableProps
  extends Pick<TableProps, 'setSelectedRowIds' | 'selectedRowIds' | 'disableRowSelect'> {
  data?: (DocumentModel | MetricModel)[];
  filterService: Filter.FilterServiceInterface;
  isDataSourceEditable: boolean;
  itemCount?: number;
  totalPages?: number;
  variant?: 'metric' | 'document';
}

const DocumentsTable: React.FC<DocumentsTableProps> = ({
  data,
  disableRowSelect,
  filterService,
  isDataSourceEditable,
  itemCount = 0,
  selectedRowIds,
  setSelectedRowIds,
  totalPages,
  variant,
}) => {
  const [isShowFilter, setShowFilter] = useState(false);
  const toggleFilter = React.useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);
  const { changePage, filter, search, sort } = filterService;

  const columns = React.useMemo(() => {
    const cols: ColumnConfig<DocumentModel>[] = [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: (props: Cell<DocumentModel>) => {
          const { row } = props;
          const item = row.original;
          return (
            <LinkedCell {...props} item={item} itemName={item.name} showIcon showNameTooltip />
          );
        },
        Header: `Name (${itemCount})`,
        accessor: (d) => d.name,
        disableHiding: true,
        id: 'name',
        width: '110%',
      },
      {
        Cell: (props: Cell<DocumentModel>) => {
          const {
            row: { original },
          } = props;
          return (
            <DescriptionCell
              {...original}
              isDataSourceEditable={false}
              showDescriptionSelector={false}
            />
          );
        },
        Header: 'Description',
        accessor: 'description',
        id: 'description',
        width: '140%',
      },
      {
        Cell: (props: Cell<DocumentModel>) => {
          const { row } = props;
          const doc = row.original;

          return (
            <EditableTaggedItemCell
              {...props}
              isDataSourceEditable={isDataSourceEditable}
              obj={doc}
            />
          );
        },
        Header: 'Tags',
        accessor: (d) => d.taggedItems,
        disableSortBy: true,
        id: 'tags',
        width: '110%',
      },
      ...(variant === 'metric'
        ? [
            {
              Cell: (props: Cell<DocumentModel>) => {
                const {
                  row: { original },
                } = props;
                return (
                  <RepresentedAsCell
                    guid={original.guid}
                    representedAs={original?.representedAs}
                    richtextRepresentedAs={original?.richtextRepresentedAs}
                  />
                );
              },
              Header: 'Represented As',
              accessor: (d: Partial<DocumentModel>) => d.richtextRepresentedAs,
              disableSortBy: true,
              id: 'represented_as',
              width: '110%',
            },
          ]
        : []),
      {
        Cell: (props: Cell<DocumentModel>) => {
          const { row } = props;
          return <UserCell {...props} user={row.original.businessOwner?.obj} />;
        },
        Header: 'Business Owner',
        accessor: (d) => d.businessOwner?.obj?.fullName,
        id: 'businessOwner',
        width: '115%',
      },
      {
        Cell: (props: Cell<DocumentModel>) => {
          const { row } = props;
          return <UserCell {...props} user={row.original.technicalOwner?.obj} />;
        },
        Header: 'Technical Owner',
        accessor: (d) => d.technicalOwner?.obj?.fullName,
        id: 'technicalOwner',
        width: '115%',
      },
      {
        Cell: (props: Cell<DocumentModel>) => {
          const { row } = props;
          const doc = row.original;
          if ('createdOn' in doc) {
            return <DateTime datetime={doc.createdOn} />;
          }
          return null;
        },
        Header: 'Created At',
        accessor: (d) => ('createdOn' in d ? d.createdOn : undefined),
        disableFilters: true,
        id: 'createdAt',
        sortDescFirst: true,
        width: '105%',
      },
      {
        Cell: (props: Cell<DocumentModel>) => {
          const { row } = props;
          return <DateTime datetime={row.original.updatedOn} />;
        },
        Header: 'Last Updated',
        accessor: (d) => d?.updatedOn,
        disableFilters: true,
        id: 'updatedAt',
        sortDescFirst: true,
        width: '105%',
      },
    ];

    return cols;
  }, [isDataSourceEditable, itemCount, variant]);

  const getRowId = useCallback((row: Partial<DocumentModel>) => row.guid!, []);

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        compact
        data={data || []}
        disableRowSelect={disableRowSelect}
        getRowId={getRowId}
        initialState={{
          hiddenColumns: ['businessOwner', 'technicalOwner', 'createdAt', 'updatedAt'],
          pageIndex: filter.page ? filter.page - 1 : 0,
          selectedRowIds,
          sortBy: initialSortState,
        }}
        loading={data === undefined}
        manualFilters
        manualPagination
        manualSortBy
        setFilters={search}
        setSelectedRowIds={setSelectedRowIds}
        setSortBy={sort}
        showFilter={isShowFilter}
        sortable
        stickyHeader
        toggleFilter={toggleFilter}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default React.memo<DocumentsTableProps>(DocumentsTable);
