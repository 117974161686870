import React, { memo } from 'react';

import Box from '@components/Box';
import useExpandExploreNode from '@components/Explore.v1/useExpandExploreNode';
import Icon from '@components/UI/Icon';
import flags from '@features';
import DataTypesModel from '@models/DataTypesModel';

import NodeHandles from '../../EdgeHandle/NodeHandles';
import LineageTooltip from '../../LineageTooltip';
import { getColorsBasedOnDataTypes } from '../../Nodes/Nodes.utils';

import {
  IntegrationType,
  StyledIntegrationNode,
  StyledIntegrationNodeCaret,
  StyledIntegrationNodeCaretContainer,
  StyledIntegrationNodeCount,
  StyledIntegrationNodeTitle,
} from './IntegrationNode.styles';

interface IntegrationExploreNode {
  contentGap: number;
  dataTypes?: DataTypesModel;
  isOpen: boolean;
  isUnfocused?: boolean;
  name: string;
  nodeKey?: string;
  onExpandNode?: () => void;
  style?: React.CSSProperties;
  tablesCount: number;
  type?: IntegrationType;
}

const IntegrationNode: React.FC<IntegrationExploreNode> = ({
  children,
  contentGap,
  dataTypes,
  isOpen,
  isUnfocused,
  name,
  nodeKey,
  onExpandNode,
  style,
  tablesCount,
  type = 'database',
}) => {
  const colors = getColorsBasedOnDataTypes(dataTypes as DataTypesModel);
  const { updateNodesOnExpand } = useExpandExploreNode();

  const handleNodeClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onExpandNode?.();
    if (nodeKey) {
      updateNodesOnExpand({ nodeKey, shouldEnableFocusedLineage: false });
    }
  };

  const assetIconName = type === 'database' ? 'database' : dataTypes?.icons.dataType!;

  return (
    <StyledIntegrationNode
      backgroundColor={colors.secondary}
      borderColor={`${colors.primary}30`}
      isOpen={isOpen}
      isUnfocused={isUnfocused}
      onClick={handleNodeClick}
      style={style}
    >
      <NodeHandles height="database" />
      <Box alignItems="center" compDisplay="flex" compWidth="100%" gap={0.5}>
        <Box alignItems="center" compDisplay="flex" compWidth="100%" gap={0.5} overflow="hidden">
          <Icon name={dataTypes?.icons?.dataSource!} size="16px" />
          <Icon color={colors.primary} name={assetIconName} size="16px" />
          <LineageTooltip content={name} offset={[8, 15]} placement="bottom-start">
            <StyledIntegrationNodeTitle>
              {flags.new_lineage_debug_mode ? nodeKey : name}
            </StyledIntegrationNodeTitle>
          </LineageTooltip>
          <StyledIntegrationNodeCount>{tablesCount.toLocaleString()}</StyledIntegrationNodeCount>
        </Box>
        <StyledIntegrationNodeCaretContainer>
          <StyledIntegrationNodeCaret
            color={colors.primary}
            isOpen={isOpen}
            name="down"
            role="button"
            size="18px"
          />
        </StyledIntegrationNodeCaretContainer>
      </Box>
      {isOpen && (
        <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={contentGap} mt={0.5}>
          {children}
        </Box>
      )}
    </StyledIntegrationNode>
  );
};

const MemoizedIntegrationNode = memo<typeof IntegrationNode>(IntegrationNode);

export default MemoizedIntegrationNode;
