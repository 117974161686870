import React, { useMemo, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Cell } from 'react-table';

import { ReportQueryModel } from '@api/queries/ReportQueryModel';
import DateTime from '@components/DateTime';
import Highlighter from '@components/Highlighter';
import Link from '@components/Link/Link.styles';
import QueryModalWithTargetBox from '@components/Modal/QueryModal/QueryModalWithTargetBox';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { CellWithButtons } from '@components/Table/TableStyled/TableStyled';
import useCustomAttributesColumns from '@hooks/useCustomAttributesColumns';
import BIColumnPageRouted from '@pages/BIColumnPageRouted';
import type { FilterOptions, FilterServiceInterface } from '@utils/filters';
import { getPopularityNormalized } from '@utils/popularity';

import EditableTaggedItemCell from '../Cells/EditableTaggedItemCell';
import DeletedLabel from '../DeletedLabel';

const allColumns = [
  'search',
  'name',
  'description',
  'rawSql',
  'popularity',
  'pageName',
  'tags',
  'updatedAt',
] as const;
type ColumnKey = (typeof allColumns)[number];

export const BI_CHARTS_TABLE_SEARCH_CONFIG: PartialRecord<ColumnKey, keyof FilterOptions> = {
  description: 'search_description',
  name: 'search_name',
  rawSql: 'search_sql',
};

export const BI_CHARTS_TABLE_SORT_CONFIG: PartialRecord<ColumnKey, string> = {
  description: 'description',
  name: 'name',
  pageName: 'page_name',
  popularity: 'popularity',
  rawSql: 'sql',
  updatedAt: 'query_updated_at',
};

export interface BIChartsTableProps {
  customAttributesAssetType: string;
  customColumProps?: PartialRecord<ColumnKey, { Header?: string }>;
  data?: ReportQueryModel[];
  filterService: FilterServiceInterface;
  isDataSourceEditable: boolean;
  isLoading?: boolean;
  itemCount?: number;
  stickyHeader?: boolean;
  visibleColumns?: Array<ColumnKey | 'customAttribute'>;
}

const BIChartsTable: React.FC<BIChartsTableProps> = ({
  customAttributesAssetType,
  customColumProps,
  data,
  filterService,
  isDataSourceEditable,
  isLoading,
  itemCount = 0,
  stickyHeader,
  visibleColumns = ['search', 'name', 'customAttribute', 'tags'],
}) => {
  const { path } = useRouteMatch();
  const [isShowFilter, setShowFilter] = useState(false);

  const { initialTableSortState, search, sort } = filterService;

  const { customAttributesColumns } = useCustomAttributesColumns({
    assetType: customAttributesAssetType,
    isDataSourceEditable,
    tableData: data ?? [],
  });

  const columns: ColumnConfig<ReportQueryModel>[] = useMemo(() => {
    const all: Record<ColumnKey, ColumnConfig<ReportQueryModel>> = {
      description: {
        Cell: ({ column, row: { original }, state }: Cell<ReportQueryModel>) => {
          return (
            <DescriptionCell
              column={column}
              state={state}
              {...original}
              dataSourceType={original.dataTypes?.dataSourceType}
              isDataSourceEditable={isDataSourceEditable}
            />
          );
        },
        Header: 'Description',
        accessor: 'description',
        id: 'description',
        width: '124.5%',
      },
      name: {
        Cell: (props: Cell<ReportQueryModel>) => {
          const { row } = props;
          const report = row.original;

          return (
            <CellWithButtons
              alignItems="center"
              compDisplay="flex"
              data-testid={`name-cell-${report.guid}`}
              gap={0.5}
            >
              <LinkedCell {...props} customUrl={report.routePath} item={report} showIcon />
              {report.deactivationScheduledOn && <DeletedLabel />}
            </CellWithButtons>
          );
        },
        Header: `${customColumProps?.name?.Header ?? 'Query'} (${itemCount})`,
        accessor: 'name',
        disableHiding: true,
        id: 'name',
        width: '130%',
      },
      pageName: {
        Cell: ({ column, row: { original } }: Cell<ReportQueryModel>) => (
          <Highlighter
            autoEscape
            searchWords={[column?.filterValue]}
            textToHighlight={original.pageName!}
          >
            {original.pageName}
          </Highlighter>
        ),
        Header: customColumProps?.pageName?.Header ?? 'Tab',
        accessor: 'pageName',
        disableFilters: true,
        id: 'pageName',
        width: '130%',
      },
      popularity: {
        Cell: (props: Cell<ReportQueryModel>) => {
          const { row } = props;
          const report = row.original;
          return <PopularityCell {...props} isReportQuery popularity={report.popularity} />;
        },
        Header: PopularityCellHeader,
        accessor: (d) => getPopularityNormalized(d?.popularity?.popularity),
        disableFilters: true,
        disableResizing: true,
        id: 'popularity',
        width: 120,
      },
      rawSql: {
        Cell: (props: Cell<ReportQueryModel>) => {
          const { column, row } = props;
          const report = row.original;

          return (
            <QueryModalWithTargetBox
              codeString={report?.rawSql}
              dataSourceType={report.dataSourceType}
            >
              <Link as="span" color="inherit" fontSize="inherit" role="button" underlineOnHover>
                <Highlighter
                  autoEscape
                  searchWords={[column?.filterValue]}
                  textToHighlight={report.rawSql ?? ''}
                >
                  {report.rawSql}
                </Highlighter>
              </Link>
            </QueryModalWithTargetBox>
          );
        },
        Header: 'SQL',
        accessor: 'rawSql',
        id: 'rawSql',
        width: '150%',
      },
      search: {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      tags: {
        Cell: (props: Cell<ReportQueryModel>) => {
          const { row } = props;
          const report = row.original;

          return (
            <EditableTaggedItemCell
              {...props}
              isDataSourceEditable={isDataSourceEditable}
              obj={report}
            />
          );
        },
        Header: 'Tags',
        accessor: (d) => {
          return d.taggedItems ? `${d.taggedItems.map((item) => item.tag.name).join(',')}` : null;
        },
        disableFilters: true,
        disableSortBy: true,
        id: 'tags',
        width: '120%',
      },
      updatedAt: {
        Cell: (props: Cell<ReportQueryModel>) => {
          const query = props.row.original;
          const lastEdit = query?.updatedAt?.isValid() ? query.updatedAt : query?.createdAt;
          return <DateTime datetime={lastEdit} />;
        },
        Header: 'Last Modified',
        accessor: (d) => (d?.updatedAt?.isValid() ? d.updatedAt?.toDate() : d?.createdAt?.toDate()),
        disableFilters: true,
        id: 'updatedAt',
      },
    };

    let columnArray: ColumnConfig<ReportQueryModel>[] = [];

    visibleColumns.forEach((col) => {
      if (col === 'customAttribute') {
        columnArray = columnArray.concat(customAttributesColumns);
      } else {
        columnArray.push(all[col as ColumnKey]);
      }
    });

    return columnArray;
  }, [customColumProps, itemCount, visibleColumns, isDataSourceEditable, customAttributesColumns]);

  const initialState = {
    sortBy: initialTableSortState,
  };

  return (
    <>
      <TableStyled>
        <Table
          basic="very"
          className="table-full"
          columns={columns}
          compact
          data={data || []}
          disablePagination
          disableRowSelect
          initialState={initialState}
          loading={isLoading}
          manualFilters
          manualSortBy
          setFilters={search}
          setSortBy={sort}
          showFilter={isShowFilter}
          sortable
          stickyHeader={stickyHeader}
          toggleFilter={() => setShowFilter((prev) => !prev)}
          unstackable
        />
      </TableStyled>
      <Switch>
        <Route path={`${path}/:columnGuid`}>
          <BIColumnPageRouted isDataSourceEditable={isDataSourceEditable} />
        </Route>
      </Switch>
    </>
  );
};

export default BIChartsTable;
