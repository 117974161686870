import React from 'react';

import DateRangePicker from '@components/DateRangePicker';
import { useDateRangePickerContext } from '@components/DateRangePicker/DateRangePicker.context';
import Select from '@components/UI/Select.v1/Select';

import { StyledCostAnalysisSharedFilter } from './CostAnalysisSharedFilter.styles';
import useWarehouseSelect from './useWarehouseSelect';

const CostAnalysisSharedFilter: React.FC = () => {
  const { dateRange, maxRangeDays, presetDateOptions, setDateRange } = useDateRangePickerContext();
  const { isLoading, selectedItems, warehouseFilterPlaceholder, warehouseOptions } =
    useWarehouseSelect();

  return (
    <StyledCostAnalysisSharedFilter fluid>
      <Select
        clearInputOnSelect={false}
        isLoading={isLoading}
        isMulti
        maxOptionsVisible={10}
        multiOptionsType="string"
        options={warehouseOptions}
        placeholder={warehouseFilterPlaceholder}
        value={selectedItems}
      />
      <DateRangePicker
        initialRange={dateRange}
        maxRangeDays={maxRangeDays}
        onChange={setDateRange}
        presetDateOptions={presetDateOptions}
      />
    </StyledCostAnalysisSharedFilter>
  );
};

export default CostAnalysisSharedFilter;
