import { Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

import type { CustomElement } from '../RichTextEditor.types';

const insertMention = (editor: ReactEditor & HistoryEditor, mention: any) => {
  const mentionElement: CustomElement = {
    children: [{ text: '' }],
    mention: { guid: mention.guid, name: mention.name },
    type: 'mention',
  };
  Transforms.insertNodes(editor, [mentionElement, { text: '' }]);
  Transforms.move(editor);
  setTimeout(() => ReactEditor.focus(editor), 10);
};

export default insertMention;
