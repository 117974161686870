import styled from '@emotion/styled';
import { Handle } from 'reactflow';

import { SIZES } from '@components/Explore.v1/components/Nodes/config';
import px from '@styles/mixins/px';

export interface StyledEdgeHandleProps {
  height: keyof typeof SIZES.height;
}

const StyledEdgeHandle = styled(Handle)<StyledEdgeHandleProps>`
  visibility: hidden;
  ${({ position }) => `${position}: 0;`};
  top: ${({ height }) => px(SIZES.height[height] / 2)};
`;

export default StyledEdgeHandle;
