import React, { ReactElement, useEffect, useState } from 'react';
import { LOADING_TEXT } from '@constants';
import { Search } from 'semantic-ui-react';
import { useDebounce, useDebouncedCallback } from 'use-debounce';

import { usePostQuickSearch } from '@api/search';
import type { SearchModel } from '@api/search/SearchModel';
import type { QuickSearchIndexFilter, SearchResult as SearchResultType } from '@api/search/types';
import type { DatasourceTabsV1Props } from '@components/SearchBar/DatasourceTabs/DatasourceTabsV1';
import DatasourceTabsV1 from '@components/SearchBar/DatasourceTabs/DatasourceTabsV1';
import SearchResult from '@components/SearchBar/SearchResult/SearchResult';
import { SearchResultContainer } from '@components/SearchBar/SearchResult/SearchResult.styles';
import Icon from '@components/UI/Icon';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import useClickOutside from '@hooks/useClickOutside';

import { DatasourceTabV1 } from '../SearchBar/DatasourceTabs';

import QuickSearchStyle from './QuickSearchStyle';

let searchText = '';

interface QuickSearchInputProps {
  filters?: QuickSearchIndexFilter[];
  noExternalTags?: boolean;
  onBeforeResult?: (
    items: SearchModel[],
    searchText: string,
    activeTab: DatasourceTabV1,
  ) => SearchModel[];
  onResultSelect?: (item: SearchModel) => void;
  onSearchChange?: (value: string) => void;
  placeholder?: string;
  tabs?: DatasourceTabsV1Props['tabs'];
}

const QuickSearchInput: React.FC<QuickSearchInputProps> = ({
  filters,
  noExternalTags = false,
  onBeforeResult,
  onResultSelect,
  onSearchChange,
  placeholder,
  tabs,
}) => {
  const [text, setText] = useState<string>('');
  const [isChangingText, setIsChangingText] = useState(false);
  const [textDebounced] = useDebounce(text, 500);
  const [activeTab, setActiveTab] = useState<DatasourceTabV1 | undefined>(tabs?.[0]);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<SearchResultType<SearchModel>>();
  const [showFacets, setShowFacets] = useState(false);
  const segment = useSegmentContext();
  const [facets, setFacets] = useState<SearchResultType<SearchModel>['facets']>();
  const [filter, setFilter] = useState(filters ?? tabs?.[0].filters);

  const clickOutsideRef = useClickOutside<HTMLDivElement>({
    onClick: () => {
      setIsShow(false);
      setShowFacets(false);
    },
  });

  const { isLoading: isLoadingResults, mutate: search } = usePostQuickSearch({
    onSuccess: (result) => {
      setIsChangingText(false);
      if (!facets) {
        setFacets(result.facets);
      }

      if (onBeforeResult)
        setSearchResult({
          ...result,
          data: onBeforeResult(result.data, text, activeTab as DatasourceTabV1),
        });
      else setSearchResult(result);
    },
  });

  const debouncedSearch = useDebouncedCallback((term: string) => {
    search({
      all_buckets_search: true,
      index_filters: filter,
      no_external_tags: noExternalTags,
      query: term,
    });
  }, 500);

  const resetSearch = () => {
    setIsChangingText(false);
    setText('');
    setIsShow(false);
    setShowFacets(false);
    setFacets(undefined);
    setActiveTab(tabs?.[0]);
    setFilter(filters ?? tabs?.[0].filters);
    setSearchResult(undefined);
    searchText = '';
  };

  const onMouseDown = () => {
    setIsShow(true);
  };

  const trackSelectedValue = (result: SearchModel) => {
    const { guid, indexPosition } = result;

    if (indexPosition !== undefined) {
      segment?.track(SegmentTrackEventName.QuickSearchResultClicked, {
        guid,
        rank: indexPosition,
        tab: activeTab?.name,
      });
    }
  };

  const handleResultSelect = (_e: any, { result }: { result: SearchModel }) => {
    trackSelectedValue(result);

    setText(result.title || 'unknown');
    setIsShow(false);
    resetSearch();
    if (onResultSelect) {
      onResultSelect(result);
    }
  };

  const handleSearchChange = (_e: any, { value }: any) => {
    setText(value);
    setIsShow(true);

    if (value.length < 1) {
      resetSearch();
    }

    searchText = value;

    if (onSearchChange) {
      onSearchChange(value);
    }
  };

  const removeValue = () => {
    resetSearch();
  };

  const onHide = () => {
    setIsShow(false);
  };

  useEffect(() => {
    setIsChangingText(true);
    setActiveTab(tabs?.[0]);
    setFilter(filters ?? tabs?.[0].filters);
    setFacets(undefined);
    setSearchResult(undefined);
    debouncedSearch(textDebounced);
  }, [textDebounced]);

  const show = isShow && Boolean(text);

  const filterTabs = show && tabs && (
    <DatasourceTabsV1
      data={searchResult}
      facets={facets}
      onFilterChange={(f, tab) => {
        setFilter(f);
        debouncedSearch(text);
        setActiveTab(tab);
      }}
      onSubtabVisibilityChange={setShowFacets}
      tabs={tabs}
    />
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const ResultRenderer: any = (searchItem: SearchModel): ReactElement<any> => (
    <SearchResultContainer id={searchItem.id}>
      <SearchResult
        clickableBreadcrumbs={false}
        isQuickSearch
        searchItem={searchItem}
        searchText={searchText}
      />
    </SearchResultContainer>
  );

  const isLoading = isLoadingResults || isChangingText;

  return (
    <QuickSearchStyle
      ref={clickOutsideRef}
      className="topsearch"
      showFacets={showFacets}
      showTabs={Boolean(tabs)}
    >
      <Search
        className={show ? 'with-results' : undefined}
        loading={isLoading}
        noResultsMessage={isLoading ? LOADING_TEXT : 'No results found.'}
        onMouseDown={onMouseDown}
        onResultSelect={handleResultSelect}
        onSearchChange={handleSearchChange}
        open={show}
        placeholder={placeholder ?? 'Search...'}
        resultRenderer={ResultRenderer}
        results={searchResult?.data}
        value={text}
      />
      {show && <Icon className="search-clear" name="close" onClick={removeValue} size="14px" />}
      {filterTabs}
      {show && (
        <div
          aria-label="Save"
          className="search-overlay"
          onClick={onHide}
          onKeyDown={onHide}
          role="button"
          tabIndex={-1}
        />
      )}
    </QuickSearchStyle>
  );
};

export default QuickSearchInput;
