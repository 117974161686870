import React from 'react';

import type { DbtProjectModelV1 } from '@api/dbt/DbtProjectModel.v1';
import { DbtTypes } from '@api/dbt/types';
import Alert from '@components/Alert';
import Box from '@components/Box';
import type { DataSourceFormProps } from '@components/DataSourceSetup/components/DataSourceAddStep/forms/types';
import {
  StyledDataSourceSetupContent,
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import { DropzoneFormElement } from '@components/Dropzone';
import Form from '@components/Form';
import type { UseFormReturnProps } from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import Select from '@components/UI/Select.v1/Select';
import type { Option } from '@components/UI/Select.v1/types';
import { DataSourceModel } from '@models/DataSourceModel';
import { WarehouseOptions } from '@models/DataSourceOptions';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import {
  DataSourceOption,
  DEFAULT_URL,
  FieldKey,
  getDatasourceOptions,
  TYPE_OPTIONS,
} from './utils';

export interface FormValues {
  [FieldKey.cloudBaseUrl]?: string;
  [FieldKey.cloudJobId]?: string;
  [FieldKey.cloudToken]?: string;
  [FieldKey.coreCatalogJson]?: JSON;
  [FieldKey.coreManifestJson]?: JSON;
  [FieldKey.coreRunResultsJson]?: JSON;
  [FieldKey.dbtType]: NonNullable<DbtProjectModelV1['dbtType']>;
  [FieldKey.dialect]?: string;
  [FieldKey.name]?: string;
  [FieldKey.targetDataSource]?: DataSourceModel;
}

export interface CreateDbtProjectFormProps
  extends Pick<
    UseFormReturnProps<FormValues>,
    'values' | 'setValues' | 'handleChange' | 'handleSubmit'
  > {
  children?: React.ReactNode;
  customFieldProps?: PartialRecord<keyof typeof FieldKey, { disabled?: boolean; label?: string }>;
  error?: EnhancedErrorResult | null;
  isLoading?: boolean;
  renderBefore?: DataSourceFormProps['renderBefore'];
  showEditMsg?: boolean;
  userDWHOptions?: DataSourceOption[];
}

const CreateDbtProjectForm: React.FC<CreateDbtProjectFormProps> = ({
  children,
  customFieldProps,
  error,
  handleChange,
  handleSubmit,
  isLoading,
  renderBefore,
  setValues,
  showEditMsg,
  userDWHOptions,
  values,
}) => {
  const targetDataSourceSelected = values.targetDataSource
    ? getDatasourceOptions({
        [values.targetDataSource.guid]: values.targetDataSource,
      })
    : [];

  const dialectSelected = WarehouseOptions.filter((opt) => opt.value === values.dialect);
  const dbtTypeSelected = TYPE_OPTIONS.filter((opt) => opt.value === values.dbtType);

  return (
    <StyledDataSourceSetupContent borderRadius="inherit">
      <Form isLoading={isLoading} onSubmit={handleSubmit}>
        <StyledFormHorizontalLabelGrid>
          {Boolean(showEditMsg) && (
            <Box gridColumn="1/3">
              <Alert type="info">
                Since you are configuring an existing data source, you can adjust the ingestion
                settings without needing to put in dbt Cloud credentials or dbt Core files again.
              </Alert>
            </Box>
          )}
          {renderBefore?.({ error, loading: isLoading })}
          <StyledLabel>
            {customFieldProps?.name?.label ?? 'Display Name'}
            <Input
              disabled={customFieldProps?.name?.disabled}
              error={error?.data?.name}
              helperText={error?.data?.name}
              maxLength={50}
              name="name"
              onChange={handleChange}
              placeholder="dbt"
              type="text"
              value={values.name}
            />
          </StyledLabel>
          {values.targetDataSource ? (
            <StyledLabel>
              Connected Warehouse
              <Select
                error={error?.data?.target_data_source}
                isDisabled={customFieldProps?.targetDataSource?.disabled}
                isDropdown
                onChange={(newValue) => {
                  const [option] = newValue as unknown as Option<DataSourceOption['value']>[];
                  setValues((prev) => ({
                    ...prev,
                    dialect: option?.value.type,
                    targetDataSource: option?.value,
                  }));
                }}
                options={userDWHOptions as any}
                placeholder={userDWHOptions?.[0]?.text}
                value={targetDataSourceSelected as any}
              />
            </StyledLabel>
          ) : (
            <StyledLabel>
              Dialect
              <Select
                aria-label="dialect"
                error={error?.data?.dialect}
                isDisabled={customFieldProps?.dialect?.disabled}
                isDropdown
                onChange={(newValue) => {
                  const [option] = newValue as unknown as Option<string>[];
                  setValues((prev) => ({ ...prev, dialect: option.value }));
                }}
                options={WarehouseOptions}
                placeholder={WarehouseOptions[0].text}
                value={dialectSelected}
              />
            </StyledLabel>
          )}
          <StyledLabel>
            dbt Type
            <Select
              aria-label="dbt type"
              error={error?.data?.dbt_type}
              isDisabled={customFieldProps?.dbtType?.disabled}
              isDropdown
              onChange={(newValue) => {
                const [option] = newValue as unknown as Option<DbtTypes>[];
                setValues((prev) => ({ ...prev, dbtType: option.value }));
              }}
              options={TYPE_OPTIONS}
              value={dbtTypeSelected}
            />
          </StyledLabel>
          {values.dbtType === DbtTypes.core && (
            <>
              <StyledLabel as="div">
                manifest.json
                <DropzoneFormElement<JSON>
                  disabled={customFieldProps?.coreManifestJson?.disabled}
                  format="json"
                  isComplete={Boolean(values.coreManifestJson)}
                  onFileAccept={(coreManifestJson) => {
                    setValues((prev) => ({ ...prev, coreManifestJson }));
                  }}
                  text="Drag & Drop your manifest.json here"
                />
              </StyledLabel>
              <StyledLabel as="div">
                catalog.json
                <DropzoneFormElement<JSON>
                  disabled={customFieldProps?.coreCatalogJson?.disabled}
                  format="json"
                  isComplete={Boolean(values.coreCatalogJson)}
                  onFileAccept={(coreCatalogJson) => {
                    setValues((prev) => ({ ...prev, coreCatalogJson }));
                  }}
                  text="Drag & Drop your catalog.json here"
                />
              </StyledLabel>
              <StyledLabel as="div">
                run_results.json
                <DropzoneFormElement<JSON>
                  disabled={customFieldProps?.coreRunResultsJson?.disabled}
                  format="json"
                  isComplete={Boolean(values.coreRunResultsJson)}
                  onFileAccept={(coreRunResultsJson) => {
                    setValues((prev) => ({ ...prev, coreRunResultsJson }));
                  }}
                  text="Drag & Drop your run_results.json here"
                />
              </StyledLabel>
            </>
          )}
          {values.dbtType === DbtTypes.cloud && (
            <>
              <StyledLabel>
                API Key
                <Input
                  disabled={customFieldProps?.cloudToken?.disabled}
                  error={error?.data?.cloud_token}
                  helperText={error?.data?.cloud_token}
                  name="cloudToken"
                  onChange={handleChange}
                  placeholder="API Key"
                  type="password"
                  value={values.cloudToken}
                />
              </StyledLabel>
              <StyledLabel>
                dbt Job Id
                <Input
                  disabled={customFieldProps?.cloudJobId?.disabled}
                  error={error?.data?.cloud_job_id}
                  helperText={error?.data?.cloud_job_id}
                  name="cloudJobId"
                  onChange={handleChange}
                  placeholder="12345"
                  value={values.cloudJobId}
                />
              </StyledLabel>
              <StyledLabel>
                Access URL
                <Input
                  disabled={customFieldProps?.cloudBaseUrl?.disabled}
                  error={error?.data?.cloud_base_url}
                  helperText={error?.data?.cloud_base_url}
                  name="cloudBaseUrl"
                  onChange={handleChange}
                  placeholder={DEFAULT_URL}
                  value={values.cloudBaseUrl}
                />
              </StyledLabel>
            </>
          )}
          <Box compDisplay="flex" flexDirection="column" gap={0.5} gridColumn="1/3">
            {error?.data.core_catalog_json && (
              <Alert title="catalog.json" type="error">
                {error?.data.core_catalog_json}
              </Alert>
            )}
            {error?.data.core_manifest_json && (
              <Alert title="manifest.json" type="error">
                {error?.data.core_manifest_json}
              </Alert>
            )}
            {error?.data.core_run_results_json && (
              <Alert title="run_results.json" type="error">
                {error?.data.core_run_results_json}
              </Alert>
            )}
          </Box>
        </StyledFormHorizontalLabelGrid>
        {children}
      </Form>
    </StyledDataSourceSetupContent>
  );
};

export default React.memo(CreateDbtProjectForm);
