import { Type } from 'class-transformer';

import { ColumnData, ColumnModel } from '@api/columns/ColumnModel';
import {
  LookerExploreFieldData,
  LookerExploreFieldModel,
} from '@api/explores/LookerExploreFieldModel';
import { TableauFieldData, TableauFieldModel } from '@api/tableau/TableauFieldModel';
import { Reference, ReferenceData } from '@models/Reference';

export type DimensionItemDataType = LookerExploreFieldData | TableauFieldData | ColumnData;

export type DimensionItemType = LookerExploreFieldModel | TableauFieldModel | ColumnModel;

export interface DimensionItemData {
  item: ReferenceData<DimensionItemDataType>;
}

export class DimensionItemModel {
  static objectType: string = 'dimensionitem';

  objectType: string = DimensionItemModel.objectType;

  static typeDisplay: string = 'Dimension Item';

  typeDisplay: string = DimensionItemModel.typeDisplay;

  @Type(() => Reference)
  field!: Reference<DimensionItemType>;
}
