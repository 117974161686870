import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { BIFolderData, BIFolderModel } from '@api/bifolder/BIFolderModel';
import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { DashboardTypes } from '@api/dashboards/DashboardTypes';
import { QueryErrorData, QueryErrorModel } from '@api/dashboards/QueryErrorModel';
import type { DescriptionSource } from '@api/description/description.types';
import { DSUserData, DsUserModel } from '@api/dsusers/DsUserModel';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { ObjectType } from '@api/types';
import { MetadataObjectType } from '@atoms';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import type { DataSourceTypesType } from '@models/DataSourceCredentials';
import DataTypesModel from '@models/DataTypesModel';
import { MetadataModel } from '@models/MetadataModel';
import { PopularityData, PopularityModel } from '@models/PopularityModel';
import { Reference } from '@models/Reference';
import RelatedObjectsCountsModel from '@models/RelatedObjectsCountsModel';
import formatNumber from '@utils/formatNumber';
import getParentName from '@utils/getParentName/getParentName';
import { dateTimeFormat } from '@utils/moment';
import { urlFor } from '@utils/routing';

const lastRunDataUnavailableDataTypes = [
  DashboardTypes.metabase_dashboard,
  DashboardTypes.workbook,
];

export interface DashboardData {
  bifolder: BIFolderData;
  chartCount: number;
  createdAt: moment.Moment;
  dataSourceGuid: string;
  dataType: string;
  dataTypes: DataTypesModel;
  description?: string;
  dsuserCreatedBy?: DSUserData;
  externalUrl?: string;
  fullPath?: string;
  guid: string;
  isHidden?: boolean;
  lastQueryError?: QueryErrorData;
  lastRunAt: moment.Moment;
  lastSuccessfulRun: moment.Moment;
  name: string;
  popularity?: PopularityData;
  queryCount: number;
  richtextDescription?: string;
  runsCount: number;
  topDSUser?: DSUserData;
  updatedAt: moment.Moment;
  viewCount: number;
  wasLastRunSuccessful?: boolean | null;
}

export class DashboardModel {
  static objectType: ObjectType = 'dashboard';

  objectType: ObjectType = DashboardModel.objectType;

  objectTypeV1: MetadataObjectType = 'dashboards';

  static typeDisplay: string = 'Dashboard';

  typeDisplay: string = DashboardModel.typeDisplay;

  guid!: string;

  name!: string;

  description?: string;

  // TODO: Remove transform https://app.shortcut.com/select-star/story/55620
  @Expose({ name: 'richtext_description' })
  @Transform((richtextDescription) =>
    isEmptyRichText(richtextDescription) ? '' : richtextDescription,
  )
  richtextDescription?: string;

  @Expose({ name: 'suggested_description' })
  suggestedDescription?: undefined;

  @Expose({ name: 'suggested_description_source' })
  suggestedDescriptionSource?: string;

  @Expose({ name: 'suggested_description_source_object' })
  @Type(() => Reference)
  suggestedDescriptionSourceObject?: Reference<MetadataModel>;

  size?: number;

  @Expose({ name: 'raw_sql' })
  rawSql?: string;

  @Expose({ name: 'data_type' })
  dataType!: string;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel;

  @Expose({ name: 'full_path' })
  fullPath?: string;

  @Expose({ name: 'search_name' })
  fullName?: string;

  @Type(() => BIFolderModel)
  bifolder!: BIFolderModel;

  @Expose({ name: 'dashboard_created_at' })
  @Transform((value) => moment(value))
  createdAt!: moment.Moment;

  @Expose({ name: 'dashboard_updated_at' })
  @Transform((value) => moment(value))
  updatedAt!: moment.Moment;

  @Expose({ name: 'last_run_at' })
  @Transform((value) => (value ? moment(value) : undefined))
  rawLastRunAt!: moment.Moment;

  @Expose({ name: 'last_viewed_at' })
  @Transform((value) => (value ? moment(value) : undefined))
  lastViewedAt!: moment.Moment;

  @Expose({ name: 'last_successful_run' })
  @Transform((value) => moment(value))
  lastSuccessfulRun!: moment.Moment;

  @Expose({ name: 'was_last_run_successful' })
  wasLastRunSuccessful?: boolean | null;

  @Expose({ name: 'query_count' })
  queryCount!: number;

  @Expose({ name: 'chart_count' })
  chartCount!: number;

  @Expose({ name: 'runs_count' })
  runsCount!: number;

  @Expose({ name: 'view_count' })
  viewCount!: number;

  @Expose({ name: 'dsuser_created_by' })
  @Type(() => DsUserModel)
  dsuserCreatedBy?: DsUserModel;

  @Expose({ name: 'tagged_items' })
  @Type(() => TaggedItemModel)
  taggedItems?: TaggedItemModel[];

  technicalOwner = undefined;

  businessOwner = undefined;

  @Expose({ name: 'top_dsuser' })
  @Type(() => DsUserModel)
  topDSUser?: DsUserModel;

  @Type(() => PopularityModel)
  popularity?: PopularityModel;

  @Expose({ name: 'last_query_error' })
  @Type(() => QueryErrorModel)
  lastQueryError?: QueryErrorModel;

  @Expose({ name: 'is_hidden' })
  isHidden?: boolean;

  @Expose({ name: 'downstream_objects_counts' })
  @Type(() => RelatedObjectsCountsModel)
  downstreamObjectsCounts?: RelatedObjectsCountsModel;

  @Expose({ name: 'upstream_objects_counts' })
  @Type(() => RelatedObjectsCountsModel)
  upstreamObjectsCounts?: RelatedObjectsCountsModel;

  @Expose({ name: 'source_tables_count' })
  sourceTablesCount?: number;

  @Expose({ name: 'data_source_type' })
  dataSourceType?: DataSourceTypesType;

  @Expose({ name: 'total_credits_used' })
  @Transform((value) => (value ? +value.toFixed(2) : 0))
  totalCreditsUsed?: number;

  get formattedTotalCreditsUsed() {
    return formatNumber(this.totalCreditsUsed);
  }

  @Expose({ name: 'total_run' })
  totalRun?: number;

  get formattedTotalRun() {
    return formatNumber(this.totalRun);
  }

  @Expose({ name: 'average_credits_used' })
  averageCreditsUsed?: number;

  get formattedAverageCreditsUsed() {
    return formatNumber(this.averageCreditsUsed);
  }

  @Expose({ name: 'last_executed_on' })
  @Transform((value) => value && moment(value))
  lastExecutedOn?: moment.Moment;

  get dataSourceGuid() {
    return this.bifolder?.dataSource?.guid;
  }

  /**
   * @experimental
   * @see TableModel for more info.
   */

  @Expose({ name: 'url' })
  externalUrl?: string;

  get supTitle() {
    return getParentName(this?.name || '', this?.fullPath) || this?.bifolder?.name;
  }

  get title() {
    return this.name;
  }

  get created() {
    return this.createdAt;
  }

  get lastUpdated() {
    return this.updatedAt;
  }

  get lastRun() {
    if (lastRunDataUnavailableDataTypes.includes(this.dataType as DashboardTypes)) {
      return undefined;
    }

    return this.lastRunAt;
  }

  get owner() {
    return this.dsuserCreatedBy;
  }

  get loadingStatus(): { status: 'success' | 'warning' | 'unknown'; text: string } | undefined {
    /**
     * @todo Architecture. Discuss with why do we apply that exception.
     */
    if (
      this.dataType !== DashboardTypes.workbook &&
      this.dataType !== DashboardTypes.periscope_dashboard &&
      this.dataType !== DashboardTypes.sigma_dashboard &&
      this.dataType !== DashboardTypes.power_bi_report &&
      this.dataType !== DashboardTypes.power_bi_dashboard &&
      this.dataType !== DashboardTypes.metabase_dashboard &&
      this.dataType !== DashboardTypes.data_studio_dashboard
    ) {
      if (this?.wasLastRunSuccessful) {
        return {
          status: 'success',
          text: this?.lastSuccessfulRun?.isValid?.()
            ? `Last run successful at ${this?.lastSuccessfulRun?.format(dateTimeFormat()) || ''}`
            : 'Last run successful',
        };
      }

      if (this?.wasLastRunSuccessful === null) {
        return {
          status: 'unknown',
          text: 'Last run unknown',
        };
      }

      if (this?.lastQueryError) {
        const queryErrorTime = this?.lastQueryError.eventOn.format(dateTimeFormat());
        const errorMessage = `: ${this?.lastQueryError.message}` || '';

        return {
          status: 'warning',
          text: `Last run error at ${queryErrorTime}${errorMessage}`,
        };
      }

      return {
        status: 'warning',
        text: 'Last run not successful',
      };
    }

    return undefined;
  }

  /** Used for owners edit modal. */
  itemsType = 'dashboards' as const;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }

  bytes = undefined;

  rowCount = undefined;

  lastRefreshed = undefined;

  materialization = undefined;

  showLinksTo = undefined;

  linksToObjects = undefined;

  showLinkedFrom = false;

  linkedFromObjects = undefined;

  showMentionedBy = true;

  showTableLastQuery = undefined;

  connections = undefined;

  color = undefined;

  /**
   * @todo Architecture.
   * Object might have the FE page link and the best place to define it is a model.
   */
  get routePath() {
    return urlFor({
      guid: this.guid,
      objectType: this.objectType,
    });
  }

  showEditTags = true;

  showCustomAttributes = true;

  cloudObject = undefined;

  showDescriptionSelector = true;

  @Expose({ name: 'description_source' })
  descriptionSource?: DescriptionSource;

  @Expose({ name: 'ai_description' })
  aiDescription?: string;

  @Expose({ name: 'ingested_description' })
  ingestedDescription?: string;

  @Expose({ name: 'user_description' })
  userDescription?: string;

  type = undefined;

  get lastRunAt() {
    if (this.rawLastRunAt) return this.rawLastRunAt; // others;
    if (this.lastViewedAt) return this.lastViewedAt; // tableau;
    return undefined;
  }

  get formattedDsuserCreatedBy() {
    return this.dsuserCreatedBy?.user?.fullName ? this.dsuserCreatedBy.user : this.dsuserCreatedBy;
  }
}
