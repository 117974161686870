import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { usePostOnboardingDataSourcesSelected } from '@api/onboarding';
import Box from '@components/Box';
import { DataSourceSetupBox, DataSourceSetupContent } from '@components/DataSourceSetup';
import Decorative from '@components/Decorative';
import DataSourceSelection from '@components/Forms/DataSourceSelection';
import { NullableDSType } from '@components/Forms/DataSourceSelection/DataSourceSelection';
import OrganizationOnboarding from '@components/Forms/OrganizationOnboarding';
import InformationScreen from '@components/InformationScreen';
import OnboardingFinished from '@components/Modal/OnboardingFinished';
import OnboardingSteps from '@components/OnboardingSteps';
import { step } from '@components/OnboardingSteps/OnboardingSteps';
import { useUserContext } from '@context/User';
import { ValidDSType } from '@models/DataSourceCredentials';
import MetadataDecorator from '@utils/MetadataDecorator';

const OnboardingPage: React.FC = () => {
  const { isOnboarding, isOnboardingComplete } = useUserContext();
  const [currentStep, setCurrentStep] = useState<step | undefined>(() => {
    if (isOnboarding) {
      return 'accountSetup';
    }

    if (!isOnboardingComplete) {
      return 'addDS';
    }

    return undefined;
  });

  const [selectedDataSources, setSelectedDataSources] = useState<ValidDSType[] | undefined>();
  const [currentDataSourceType, setCurrentDataSourceType] = useState<ValidDSType | undefined>();
  const [showCalendly, setShowCalendly] = useState<boolean>(false);

  const { mutate: postDataSourcesSelected } = usePostOnboardingDataSourcesSelected({});

  if (!isOnboarding && isOnboardingComplete) {
    return <Redirect to="/" />;
  }

  const onOrganizationCreated = () => {
    setCurrentStep('addDS');
  };

  const handleDataSourceSelection = (state: NullableDSType[]) => {
    const validState = state.filter((type) => type !== 'NA' && type !== 'BINA') as ValidDSType[];
    setSelectedDataSources(validState);
    setCurrentStep('importData');
    if (validState.length === 0) {
      setShowCalendly(true);
    } else {
      postDataSourcesSelected({ data_source_types: validState });
    }
  };

  const onDataSourceAdded = () => {
    if (currentDataSourceType && selectedDataSources) {
      const idx = selectedDataSources.findIndex((ds) => ds === currentDataSourceType);
      if (idx === -1 || idx + 1 >= selectedDataSources.length) {
        setCurrentDataSourceType(undefined);
        setCurrentStep('finish');
      } else {
        setCurrentDataSourceType(selectedDataSources[idx + 1]);
      }
    }
  };

  const goBackToServiceAccount = () => {
    setCurrentStep('addDS');
    setShowCalendly(false);
  };

  const handleInformationScreenChange = () => {
    if (selectedDataSources && selectedDataSources?.length > 0) {
      setCurrentDataSourceType(selectedDataSources[0]);
    }
    setShowCalendly(false);
  };

  return (
    <>
      <MetadataDecorator title="Onboarding" />
      <Decorative p={4} showFullCircle showSimpleBackground={currentStep === 'finish'}>
        <img alt="logo" height="auto" src="/icons/logo-ss-horizontal-white.svg" width="240px" />
        <OnboardingSteps currentStep={currentStep} />
        <Box m="auto" pt={3}>
          {currentStep === 'accountSetup' && (
            <OrganizationOnboarding onSuccess={onOrganizationCreated} />
          )}
          {currentStep === 'addDS' && <DataSourceSelection onSuccess={handleDataSourceSelection} />}
          {currentStep === 'importData' && !currentDataSourceType && (
            <InformationScreen
              goBack={goBackToServiceAccount}
              onChange={handleInformationScreenChange}
              showCalendly={showCalendly}
            />
          )}
          {currentStep === 'importData' && currentDataSourceType && (
            <DataSourceSetupBox>
              <DataSourceSetupContent
                key={currentDataSourceType}
                isOnboarding
                onSuccess={onDataSourceAdded}
                type={currentDataSourceType}
              />
            </DataSourceSetupBox>
          )}
          {currentStep === 'finish' && <OnboardingFinished />}
        </Box>
      </Decorative>
    </>
  );
};

export default OnboardingPage;
