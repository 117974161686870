import styled from '@emotion/styled';

import Box from '@components/Box';
import { CellWithButtons } from '@components/Table/TableStyled/TableStyled';

interface StyledColumnNameTableCellProps {
  withInlineButton?: boolean;
}

export const StyledColumnNameTableCell = styled(CellWithButtons)<StyledColumnNameTableCellProps>`
  display: flex;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.space(0.5, 0)};
  gap: ${({ theme, withInlineButton }) =>
    withInlineButton ? theme.space(0.25) : theme.space(0.25, 0.5)};
`;

export const StyledColumnNameTableCellSection = styled(Box)`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.media.down('lg')`
    min-width: 100%;
    flex-shrink: 0;
  `}
`;
