import React from 'react';

import {
  invitationsCacheKeys,
  useFetchInvitations,
  usePostResendInvitation,
} from '@api/invitations';
import { OrganizationInvitationModel } from '@api/invitations/OrganizationInvitationModel';
import { useFetchOrganizationUsers } from '@api/organizationUser';
import { useFetchTeams } from '@api/teams';
import { useFetchUsersCsv } from '@api/users';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import ExportToCSVButton from '@components/ExportToCSVButton';
import { GridContainer } from '@components/Grid';
import InviteUserModal from '@components/Modal/InviteUserModal';
import AdminInvitationsTable from '@components/Table/AdminInvitationsTable';
import AdminUsersTable from '@components/Table/AdminUsersTable';
import TitleHeader from '@components/Title/TitleHeader';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import TitleView from '@components/UI/TitleView';
import { useModal } from '@context/Modal';
import fetchClient from '@lib/fetchClient';
import { Filter } from '@utils';
import downloadCSV from '@utils/downloadCSV';
import MetadataDecorator from '@utils/MetadataDecorator';

import AddButton from '../AddButton';

const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  email: 'search_email',
  name: 'search_name',
  role: 'search_role',
};

const sortConfig: { [key: string]: string } = {
  email: 'email',
  lastActivity: 'last_activity',
  name: 'name',
  role: 'role',
  sso: 'sso_used_on',
  team: 'team',
};

export const requestConfig: Filter.FilterOptions = {
  order: '-last_activity',
  page: 1,
  page_size: 25,
  sortColumn: 'last_activity',
  sortDirection: 'descending',
};

const AdminUsersPage: React.FC = () => {
  const FilterService = Filter.useUpdateFilters(
    requestConfig,
    searchConfig,
    sortConfig,
    '-last_activity',
  );
  const { filter } = FilterService;
  const { data: organizationUsers, isLoading } = useFetchOrganizationUsers({
    keepPreviousData: true,
    params: Filter.setParams(filter),
  });
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();
  const { data: organizationTeams } = useFetchTeams();

  const { data: invitations, refetch: refetchInvitations } = useFetchInvitations();

  const { mutate: resendInvite } = usePostResendInvitation(undefined, {
    onError: () => {
      renderErrorToast('Sorry, something went wrong. Please try again');
    },
    onSuccess: () => {
      renderInfoToast('Invitation Sent');
      refetchInvitations();
    },
  });

  const { isLoading: csvIsLoading, refetch: fetchExportUsersToCSV } = useFetchUsersCsv({
    enabled: false,
    onError: (error) => {
      const errorMsg = error?.data?.[0] || 'Sorry, something went wrong';
      renderErrorToast(errorMsg);
    },
    onSuccess: (csvData: string) => {
      downloadCSV(csvData, 'users.csv');
    },
    refetchOnWindowFocus: false,
  });

  const handleExportToCSVClick = () => {
    fetchExportUsersToCSV();
  };

  if (!organizationUsers || !organizationTeams || !invitations) {
    return <CircularLoader cover />;
  }
  const itemCount = organizationUsers?.count;
  const totalPages =
    organizationUsers && filter.page_size
      ? Math.ceil(organizationUsers.count / filter.page_size)
      : 1;

  return (
    <>
      <MetadataDecorator title="Users Admin" />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <Box maxWidth="100%" mb={8} px={1.5} py={0}>
          <TitleHeader>
            <span className="title big">Users</span>
          </TitleHeader>
        </Box>
        <Box maxWidth="100%" mb={8} px={1.5} py={0}>
          <Box compDisplay="flex" justifyContent="space-between">
            <TitleView
              action={<AddButton onClick={() => openModal(MODAL_IDS.inviteUserAdminUsersPage)} />}
              mb={2.5}
              pt={1}
              title="Users"
            />
            {!isLoading && (
              <ExportToCSVButton isLoading={csvIsLoading} onClick={handleExportToCSVClick} />
            )}
          </Box>
          <AdminUsersTable
            filterService={FilterService}
            itemCount={itemCount}
            teams={organizationTeams ? organizationTeams.results : []}
            totalPages={totalPages}
            users={organizationUsers?.results}
          />
        </Box>
        <Box maxWidth="100%" mb={8} px={1.5} py={0}>
          <TitleView mb={2.5} pt={1} title="Invitations" />
          <AdminInvitationsTable
            invitations={invitations ? invitations.results : []}
            onInvitationRemoved={() => {
              fetchClient.invalidateQueries(invitationsCacheKeys.invitations);
            }}
            onInvitationResendClick={(invitation: OrganizationInvitationModel) => {
              resendInvite({ httpClientUrl: `invitations/${invitation.guid}/resend/` });
            }}
          />
        </Box>
        {checkModalOpened(MODAL_IDS.inviteUserAdminUsersPage) && (
          <InviteUserModal
            onClose={() => {
              fetchClient.invalidateQueries(invitationsCacheKeys.invitations);
              closeModal(MODAL_IDS.inviteUserAdminUsersPage);
            }}
            teams={organizationTeams ? organizationTeams.results : []}
          />
        )}
      </GridContainer>
    </>
  );
};

export default AdminUsersPage;
