import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

import Text from '@components/Text';

export const TableItemLinkStyled = styled(RouterLink)<{ isHiddenItem?: boolean }>`
  text-decoration: none;
  transition: none;
  color: inherit;
  display: flex;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: ${({ isHiddenItem, theme }) => (isHiddenItem ? theme.colors.gray.calm : 'inherit')};
  }
`;

export const LinkStyled = styled.a`
  text-decoration: none;
  transition: none;
  color: inherit;
  display: flex;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

export const StyledText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;

  &.always-bold {
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  }

  :hover:not(.no-bold) {
    font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  }

  :hover .link.no-bold {
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
    text-decoration: underline;
  }

  :hover {
    &.no-link {
      font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
      text-decoration: none;
      cursor: default;
    }
  }
`;
