import React from 'react';

import { StatusType } from '@api/types';
import { tagColorMap } from '@components/AppTag/helpers';
import Icon from '@components/UI/Icon';
import formatNumber from '@utils/formatNumber';

import { StyledFailed } from './StatusBadge.styles';

export type StatusCountsType = Record<StatusType, number> | null;

interface StatusBadgeProps {
  counts?: StatusCountsType;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ counts }) => {
  if (!counts) {
    return null;
  }

  if (counts.error === 0 && counts.warning === 0 && counts.success === 0) {
    return null;
  }

  if (counts?.error === 0 && counts.warning === 0) {
    return <Icon color={tagColorMap.endorsed} name="endorsed" size="20px" />;
  }

  return (
    <StyledFailed data-testid="status-badge">
      {formatNumber((counts?.error ?? 0) + (counts.warning ?? 0))}
    </StyledFailed>
  );
};

export default StatusBadge;
