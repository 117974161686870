import React, { useCallback, useEffect, useRef } from 'react';

import Portal from '@components/Portal';

import type { StyledModalCardProps, StyledModalOverlayProps } from './Modal.styles';
import { StyledModal, StyledModalCard, StyledModalOverlay } from './Modal.styles';

export interface ModalProps extends StyledModalCardProps {
  closeOnDimmerClick?: boolean;
  closeOnEscape?: boolean;
  id?: string;
  onClose?: (e?: any, isUnmount?: boolean) => void;
  open?: boolean;
  renderContent?: (value: { modalHandleClose: (_?: any, isUnmount?: boolean) => void }) => void;
  transparentOverlay?: StyledModalOverlayProps['transparent'];
  useContext?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  children,
  closeOnDimmerClick = true,
  closeOnEscape = true,
  onClose,
  open,
  renderContent,
  transparentOverlay,
  useContext,
  ...other
}) => {
  const onCloseRef = useRef(onClose);
  const isClosingRef = useRef(false);

  useEffect(() => {
    if (!useContext) {
      if (open) {
        document.body.classList.add('ReactModal__Body--open');
      } else {
        document.body.classList.remove('ReactModal__Body--open');
      }
    }

    return () => {
      if (!useContext) {
        document.body.classList.remove('ReactModal__Body--open');
      }
    };
  }, [open, useContext]);

  const handleClose = useCallback((_?: any, isUnmount?: boolean) => {
    // avoid calling onClose twice manually + unmount
    if (isClosingRef.current === true || !onCloseRef.current) return;
    isClosingRef.current = true;
    onCloseRef.current?.(null, isUnmount);
  }, []);

  // create dynamic ref
  useEffect(() => {
    onCloseRef.current = onClose;
  }, [onClose]);

  // use function.ref to call unmount only once instead of on prop re-render
  useEffect(() => {
    return () => {
      if (useContext) handleClose(null, true);
    };
  }, [handleClose, useContext]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && closeOnEscape) handleClose();
    };

    document.addEventListener('keydown', handleEscKey, false);

    return () => {
      document.removeEventListener('keydown', handleEscKey, false);
    };
  }, [closeOnEscape, handleClose]);

  const component = !open ? null : (
    <StyledModal>
      <StyledModalOverlay
        onClick={() => {
          if (closeOnDimmerClick && onClose) handleClose();
        }}
        role="none"
        transparent={transparentOverlay}
      />
      <StyledModalCard borderRadius="lg" {...other}>
        {renderContent?.({ modalHandleClose: handleClose })}
        {children}
      </StyledModalCard>
    </StyledModal>
  );

  return <Portal>{component}</Portal>;
};

export default Modal;
