import React from 'react';

import { DatabaseModel } from '@api/databases/DatabaseModel';
import invalidateCache from '@api/invalidateCache';
import { usePatchMetadataById } from '@api/metadata';
import { SchemaModel } from '@api/schema/SchemaModel';
import { useFetchTableExportData, useFetchTables } from '@api/tables';
import { TableModel } from '@api/tables/TableModel';
import Box from '@components/Box';
import Breadcrumbs from '@components/Breadcrumbs';
import BulkButtons from '@components/BulkButtons/BulkButtons';
import CircularLoader from '@components/CircularLoader';
import CopyFullPathButton from '@components/CopyFullPathButton';
import FavoriteButton from '@components/FavoriteButton';
import OpenInDjango from '@components/OpenInDjango';
import OwnerSelect from '@components/OwnerSelect';
import type { OwnerSelectItem } from '@components/OwnerSelect/types';
import Popularity from '@components/Popularity';
import RichTextDescriptionEditor from '@components/RichTextDescriptionEditor';
import TaggedItems from '@components/TaggedItems';
import Text from '@components/Text';
import { renderInfoToast } from '@components/Toast';
import Tooltip from '@components/Tooltip';
import HR from '@components/UI/HR';
import Icon from '@components/UI/Icon';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import { useUserContext } from '@context/User';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import theme from '@styles/theme';
import { Filter } from '@utils';
import downloadCSV from '@utils/downloadCSV';
import MetadataDecorator from '@utils/MetadataDecorator';

import TableFilters from './Filters';
import ItemsCount from './ItemsCount';
import { StyledGridContainer, StyledPageHeader, StyledTablesPage } from './TablesPage.styles';
import TablesTable from './TablesTable';
import { TablePageProps } from './types';

const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  businessOwner: 'search_business_owner',
  description: 'search_description',
  name: 'search_name',
  schema: 'search_schema',
  tags: 'search_tags',
  technicalOwner: 'search_technical_owner',
};

const sortConfig: { [key: string]: string } = {
  businessOwner: 'business_owner',
  description: 'description',
  downstreamDashboardsCount: 'downstream_dashboards',
  downstreamObjectsCounts: 'downstream_objects_total',
  name: 'name',
  popularity: 'popularity',
  rowCount: 'row_count',
  schema: 'schema',
  tableSize: 'bytes',
  technicalOwner: 'technical_owner',
  upstreamObjectsCounts: 'upstream_objects_total',
};

const invalidateTableCache = () => {
  invalidateCache((keys) => [keys.schemas.all, keys.databases.all, keys.tables.all]);
};

const TablesPage: React.FC<TablePageProps> = ({
  breadcrumbs,
  businessOwner,
  customColumnProps,
  data,
  dataSourceData,
  dataSourceType,
  defaultConfig,
  description,
  fullName,
  guid,
  hiddenColumns,
  icon,
  objectType,
  popularity,
  reloadData,
  showFilters,
  showMainContentOnly,
  showObjectBreadcrumbs,
  supIcon,
  supTitle,
  taggedItems,
  technicalOwner,
  title,
  tooltipText,
}) => {
  const { dataSources } = useUserContext();
  const {
    isEditable: isObjectEditable,
    isPbac,
    permissions,
  } = useObjectPermissionsContext({ id: guid });
  const { reset, selected, tagsCounts, toggleAll, toggleItem } = useBulkEditSelected<TableModel>();
  const { selected: currentObjectSelected, tagsCounts: currentObjectTagsCount } =
    useBulkEditSelected<SchemaModel | DatabaseModel>({
      defaultSelected: data ? [data] : undefined,
    });

  const FilterService = Filter.useUpdateFilters(defaultConfig, searchConfig, sortConfig);
  const { filter } = FilterService;
  const selectedEditableItems = selected.items.filter((item) =>
    isPbac ? permissions[item.guid]?.isEditable : true,
  );

  const { data: tablesData, isLoading: isTablesLoading } = useFetchTables({
    params: {
      ...Filter.setParams(filter),
      force_showing_suggested_description: true,
    },
  });

  const { isSuccess: isMetadataPatchSuccess, mutate: patchMetadata } = usePatchMetadataById(guid, {
    onSuccess: () => {
      renderInfoToast(`${title} description has been updated.`);
      invalidateCache((keys) => [
        keys.schemas.schema(guid),
        keys.databases.database(guid),
        keys.folders.folder(guid),
      ]);
    },
  });

  const { refetch: exportData } = useFetchTableExportData({
    enabled: false,
    onSuccess: (d) => {
      downloadCSV(d, `${title}_tables.csv`);
    },
    params: Filter.setParams({ ...filter, tables: selected.keys }),
  });

  const tables = tablesData ? tablesData?.results : undefined;
  const dsGuid = tables?.[0]?.database.dataSource?.guid ?? '';
  const isDataSourceEditable = Boolean(dataSources?.[dsGuid]?.settings?.isEditable);
  const isEditable = isDataSourceEditable && isObjectEditable;
  const tablesCount = tablesData?.count;
  const schemasCount = tablesData?.schema_count;
  const modelCount = tablesData?.model_count;
  const showModelsCount = dataSources && dataSources[dsGuid]?.type === 'dbt';
  const showOwners = dataSourceType !== 'dbt';

  const mainContent = (
    <>
      <BulkButtons
        canEditOwner
        canEditTags
        canExportCSV
        canRemove={!filter.is_hidden}
        canShowERD
        canUnHide={filter.is_hidden}
        isDataSourceEditable={isDataSourceEditable}
        onEdit={() => {
          invalidateTableCache();
          reset();
        }}
        onExportCSVClick={() => exportData()}
        selectedEditableItems={selectedEditableItems}
        selectedItems={selected.items}
        showText
        tagsCounts={tagsCounts}
      />
      <Box mb={0.5} mt={1.5}>
        <TablesTable
          customColumnProps={customColumnProps as any}
          data={tables}
          dataSourceType={dataSourceType}
          filterService={FilterService}
          hiddenColumns={hiddenColumns as any}
          isDataSourceEditable={isDataSourceEditable}
          isLoading={isTablesLoading}
          itemCount={tablesCount}
          pageObjectType={objectType}
          selectedRowIds={selected.ids}
          showBreadcrumbs={showObjectBreadcrumbs}
          toggleAll={(checked) => {
            toggleAll(tablesData?.results!, checked);
          }}
          toggleItem={toggleItem}
        />
      </Box>
    </>
  );

  if (!showMainContentOnly && (!title || !guid)) {
    return <CircularLoader centered cover />;
  }

  if (showMainContentOnly) {
    return mainContent;
  }

  return (
    <Box compDisplay="flex">
      <MetadataDecorator title={title} />
      <StyledGridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <StyledTablesPage>
          <StyledPageHeader
            icon={
              icon && (
                <Tooltip content={tooltipText}>
                  <Icon alignSelf="center" color={theme.colors.gray[600]} name={icon} size="30px" />
                </Tooltip>
              )
            }
            supIcon={supIcon && <Icon ml={0.5} name={supIcon} size="16px" />}
            supTitle={breadcrumbs ? <Breadcrumbs items={breadcrumbs} /> : supTitle}
            title={title}
            titleSuffix={
              <ItemsCount
                modelCount={showModelsCount ? modelCount : undefined}
                schemasCount={schemasCount}
                tablesCount={tablesCount}
              />
            }
            titleToolBox={
              <>
                {popularity && (
                  <Box compDisplay="flex">
                    <Popularity
                      priority={popularity?.formattedPopularity}
                      text={`Popularity score: ${popularity?.popularity}`}
                    />
                  </Box>
                )}
                {fullName && <CopyFullPathButton text={fullName} />}
                {guid && title && <FavoriteButton objGuid={guid} objName={title} />}
                {taggedItems && (
                  <TaggedItems
                    isEditable={isEditable}
                    objects={currentObjectSelected.items}
                    taggedItemsCounts={currentObjectTagsCount}
                  />
                )}
                <OpenInDjango guid={guid} size="14px" />
              </>
            }
          />
          {showOwners && (
            <Box
              alignItems="center"
              compDisplay="flex"
              data-testid="owners-container"
              mb={1.25}
              px={1}
            >
              <Box alignItems="center" compDisplay="flex" mr={3}>
                <Text
                  as="span"
                  color="gray.500"
                  fontSize={theme.typography.fontSizes.body2}
                  mr={1}
                  whiteSpace="nowrap"
                >
                  Business Owner
                </Text>
                <OwnerSelect
                  hasEditPermissions={Boolean(isEditable)}
                  items={[dataSourceData as OwnerSelectItem]}
                  itemsType={dataSourceData?.dataTypes?.dataType}
                  owner={businessOwner?.obj}
                  ownerUpdateType="business"
                  reloadData={reloadData}
                />
              </Box>
              <Box alignItems="center" compDisplay="flex">
                <Text
                  as="span"
                  color="gray.500"
                  fontSize={theme.typography.fontSizes.body2}
                  mr={1}
                  whiteSpace="nowrap"
                >
                  Technical Owner
                </Text>
                <OwnerSelect
                  hasEditPermissions={Boolean(isEditable)}
                  items={[dataSourceData as OwnerSelectItem]}
                  itemsType={dataSourceData?.dataTypes?.dataType}
                  owner={technicalOwner?.obj}
                  ownerUpdateType="technical"
                  reloadData={reloadData}
                />
              </Box>
            </Box>
          )}
          <Box compDisplay="flex" mb={2} px={1}>
            <RichTextDescriptionEditor
              descriptions={{ description }}
              editIconVariant="always"
              fontSize={theme.typography.fontSizes.body2}
              guid={guid}
              isEditable={isEditable}
              isSuccess={isMetadataPatchSuccess}
              maxLength={250}
              onDescriptionSave={(richtextDesc, plainTextDesc, descSource) => {
                patchMetadata({
                  description: plainTextDesc,
                  description_source: descSource,
                  richtext_description: richtextDesc,
                });
              }}
              placeholder="Description"
            />
          </Box>
          <HR />
          {mainContent}
        </StyledTablesPage>
      </StyledGridContainer>
      {showFilters && (
        <React.Suspense fallback={<CircularLoader borderWidth={1} centered compSize={2} />}>
          <TableFilters dataSourceType={dataSourceType} filterService={FilterService} />
        </React.Suspense>
      )}
    </Box>
  );
};

export default React.memo(TablesPage);
