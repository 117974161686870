import React, { useMemo } from 'react';

import Tooltip from '@components/Tooltip';
import RelatedObjectsCountsModel from '@models/RelatedObjectsCountsModel';
import type { DataTypeLabelKeys } from '@utils/dataTypeLabels';
import dataTypeLabels from '@utils/dataTypeLabels';

interface RelatedObjectsCountProps {
  counts?: RelatedObjectsCountsModel;
}

const getCountsByLabel = (counts?: RelatedObjectsCountProps['counts']): [string, number][] => {
  const countsByLabel: [string, number][] = [];

  if (counts) {
    Object.entries(counts).forEach(([key, value]) => {
      const show = value > 0 && !['total', 'sourceTable'].includes(key);
      const label = dataTypeLabels[key as DataTypeLabelKeys] || key;
      if (show) countsByLabel.push([label, value]);
    });
  }
  return countsByLabel.sort(([, a], [, b]) => b - a);
};

const RelatedObjectsCountCell: React.FC<RelatedObjectsCountProps> = ({ counts }) => {
  const total = counts?.total;
  const countsByLabel = useMemo(() => getCountsByLabel(counts), [counts]);

  if (!total) return <span>{total}</span>;

  const text = (
    <>
      {countsByLabel.map(([label, count]) => (
        <div key={label}>
          {label}: {count}
        </div>
      ))}
    </>
  );

  return (
    <Tooltip content={text} forceDefaultBackground>
      <span>{total}</span>
    </Tooltip>
  );
};

export default React.memo(RelatedObjectsCountCell);
