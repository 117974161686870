"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransformMetadata = void 0;
class TransformMetadata {
    constructor(target, propertyName, transformFn, options) {
        this.target = target;
        this.propertyName = propertyName;
        this.transformFn = transformFn;
        this.options = options;
    }
}
exports.TransformMetadata = TransformMetadata;
