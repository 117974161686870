import React from 'react';

import { DataSourcesMutatePayload } from '@api/dataSources';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import useDataSourceMutation from '../useDataSourceMutation';

import { DataSourceFormProps } from './types';

const LookerForm: React.FC<DataSourceFormProps> = ({
  children,
  dataSource,
  dataType,
  name = '',
  onSuccess,
  renderBefore,
}) => {
  const segment = useSegmentContext();
  const { error, isLoading, mutate } = useDataSourceMutation({ dataSource, onSuccess });

  const { handleChange, handleSubmit, values } = useForm({
    initialValues: {
      baseUrl: '',
      clientId: '',
      clientSecret: '',
      name: dataSource?.name || name,
    },
    onSubmit: (val) => {
      const payload = {} as DataSourcesMutatePayload;
      if (dataSource) {
        payload.guid = dataSource.guid;
        payload.type = dataSource.type;
      } else {
        payload.type = dataType;
      }
      payload.name = val.name;
      payload.credentials = {
        base_url: val.baseUrl,
        client_id: val.clientId,
        client_secret: val.clientSecret,
      };
      mutate(payload);

      segment?.track(SegmentTrackEventName.CreateServiceAccountConnectButtonClicked, { dataType });
    },
  });

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        {renderBefore?.({ error, loading: isLoading })}
        <StyledLabel>
          Display Name
          <Input
            error={error?.data?.name}
            helperText={error?.data?.name}
            maxLength={50}
            name="name"
            onChange={handleChange}
            placeholder="Looker"
            type="text"
            value={values.name}
          />
        </StyledLabel>
        <StyledLabel>
          API Client ID
          <Input
            error={error?.data?.username}
            helperText={error?.data?.username}
            name="clientId"
            onChange={handleChange}
            placeholder="API Client ID"
            type="text"
            value={values.clientId}
          />
        </StyledLabel>
        <StyledLabel>
          API Client Secret
          <Input
            error={error?.data?.password}
            helperText={error?.data?.password}
            name="clientSecret"
            onChange={handleChange}
            placeholder="API Client Secret"
            type="password"
            value={values.clientSecret}
          />
        </StyledLabel>
        <StyledLabel>
          Host URL
          <Input
            error={error?.data?.base_url}
            helperText={error?.data?.base_url}
            name="baseUrl"
            onChange={handleChange}
            placeholder="https://instance.cloud.looker.com"
            type="text"
            value={values.baseUrl}
          />
        </StyledLabel>
      </StyledFormHorizontalLabelGrid>
      {children?.({ error, loading: isLoading })}
    </Form>
  );
};

export default LookerForm;
