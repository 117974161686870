import React from 'react';

import { CommentModel } from '@api/comments/CommentModel';
import Box from '@components/Box';
import OwnerItem from '@components/Owners/OwnerItem';
import TagTable from '@components/TagTable';
import theme from '@styles/theme';

interface MessageInfoProps {
  comment: CommentModel;
  isOwner?: boolean;
}

const MessageInfo = React.memo<MessageInfoProps>(function MessageInfo({ comment, isOwner }) {
  const { commentedBy: author } = comment;
  const postedTime = comment.commentedOn?.fromNow();

  return (
    <Box
      alignItems="center"
      compDisplay="inline-flex"
      gap={2}
      justifyContent="flex-start"
      minHeight="1.75rem"
    >
      <OwnerItem bold contentAlignment="center" height="40px" owner={author} showAvatar showTags />
      {isOwner && <TagTable>Owner</TagTable>}
      <Box as="span" color={theme.colors.v1.gray[500]} fontSize={theme.typography.fontSizes.sm}>
        {postedTime}
      </Box>
    </Box>
  );
});

export default MessageInfo;
