import React from 'react';

import { StyledBoxProps } from '@components/Box';
import Icon, { IconProps } from '@components/UI/Icon';
import theme from '@styles/theme';

import type { StyledButtonGroupButtonProps } from './ButtonGroup.styles';
import { StyledButtonGroupBox, StyledButtonGroupButton } from './ButtonGroup.styles';

export type OptionType = {
  icon?: IconProps['name'];
  id: string;
  label?: string;
  onClick?: (option: OptionType) => void;
  title?: string;
};

interface ButtonGroupProps
  extends Pick<StyledBoxProps, 'compWidth'>,
    Pick<StyledButtonGroupButtonProps, 'compSize' | 'noBackGround'> {
  active?: string;
  options: OptionType[];
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  active,
  compSize,
  compWidth,
  noBackGround,
  options,
}) => {
  return (
    <StyledButtonGroupBox compDisplay="flex" compWidth={compWidth}>
      {options.map((option) => (
        <StyledButtonGroupButton
          key={option.id}
          active={active === option.id}
          compSize={compSize}
          noBackGround={noBackGround}
          onClick={() => option?.onClick?.(option)}
          title={option.title}
          type="button"
        >
          {option.icon && <Icon color={theme.colors.v1.gray[700]} name={option.icon} size="20px" />}
          {option.label}
        </StyledButtonGroupButton>
      ))}
    </StyledButtonGroupBox>
  );
};

export default ButtonGroup;
