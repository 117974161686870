import React from 'react';

import { useFetchPiiRuleExport, useFetchPiiRuleMatching, useFetchPiiRuleSync } from '@api/piiRules';
import type { PiiRuleModel } from '@api/piiRules/PiiRuleModel';
import Box from '@components/Box';
import Breadcrumbs from '@components/Breadcrumbs';
import Button from '@components/Button/Button';
import CircularLoader from '@components/CircularLoader';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';
import theme from '@styles/theme';
import downloadCSV from '@utils/downloadCSV';
import stripSpaces from '@utils/stripSpaces';

import { StyledPiiMatchedModalItem } from './PiiMatchedModal.styles';

interface PiiMatchedModalProps {
  datetime?: moment.Moment | null;
  onClose: () => void;
  pii?: Pick<PiiRuleModel, 'guid' | 'name'>;
}

const query = stripSpaces(`{
  item{
    object_type,
    obj{
      guid,
      name,
      breadcrumbs,
      data_types,
      parent_guid
    }
  }
}`);

const PiiMatchedModal: React.FC<PiiMatchedModalProps> = ({ datetime, onClose, pii }) => {
  const syncQuery = useFetchPiiRuleSync();
  const isSyncing = syncQuery.data?.status === 'in_progress' || syncQuery.isLoading;

  const { data, isLoading } = useFetchPiiRuleMatching(pii?.guid!, {
    enabled: Boolean(pii?.guid) && !isSyncing,
    params: { query },
  });

  const exportQuery = useFetchPiiRuleExport(pii?.guid!, {
    enabled: false,
    onSuccess: (d: string) => {
      downloadCSV(
        d,
        `${pii?.name} Matched Data List_${datetime?.format('YYYY-MM-DD HH-mm-ss')}.csv`,
      );
    },
  });

  const isEmpty = !data || data.results.length === 0;

  return (
    <Modal onClose={onClose} open size="tiny" useContext>
      <ModalHeader
        onClose={onClose}
        subtitle="The 20 most recent entries of data matched"
        title={`Matched Data for ${pii?.name}`}
      />
      <ModalContent>
        {isLoading ? (
          <CircularLoader cover />
        ) : (
          data?.results.map((taggedItem) => {
            const item = taggedItem.item?.obj as any;

            return (
              <StyledPiiMatchedModalItem key={item?.guid}>
                <Tooltip content={item?.dataTypes?.tooltips.dataSource}>
                  <Icon name={item?.dataTypes?.icons.dataSource!} size="16px" />
                </Tooltip>
                <Tooltip content={item?.dataTypes?.tooltips.dataType}>
                  <Icon name={item?.dataTypes?.icons.dataType!} size="16px" />
                </Tooltip>
                {item?.breadcrumbLabelList ? (
                  <Breadcrumbs
                    clickable
                    color="inherit"
                    fontSize={theme.typography.fontSizes.body1}
                    fontWeight={theme.typography.fontWeights.regular}
                    items={item?.breadcrumbLabelList}
                    showTrailingSlash={false}
                  />
                ) : (
                  item?.name
                )}
              </StyledPiiMatchedModalItem>
            );
          })
        )}
        {!isLoading && isEmpty && (
          <Box fontSize={theme.typography.fontSizes.body1} py={1}>
            {isSyncing && 'Sync in progress...'}
            {!isSyncing && 'No Data'}
          </Box>
        )}
      </ModalContent>
      <ModalFooter>
        <Button
          disabled={exportQuery.isLoading || isEmpty || isSyncing}
          onClick={() => exportQuery.refetch()}
          startIcon={
            exportQuery.isLoading ? (
              <CircularLoader borderWidth={2} compSize={2} />
            ) : (
              <Icon name="export" size="16px" />
            )
          }
          variant="outlined"
        >
          Download All Matched Data
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PiiMatchedModal;
