import { fetchColumnSelect, fetchColumnsSelect } from '@api/columns/columns';

export const fetchColumnsMock = fetchColumnsSelect({
  count: 2,
  model_count: 1,
  next: null,
  previous: null,
  results: [
    {
      ai_description: 'Unique identifier of the migration.',
      breadcrumbs: [
        {
          guid: 'br_aF2o28bYhUWMUcfsiHmrLE',
          ordinal: 0,
          target_data_source_guid: 'ds_hwRe6u6w99Hu4oRc885seK',
          target_data_source_type: 'postgres',
          target_data_type: 'database',
          target_guid: 'db_njinRVkZb4Shk7DqzNFoyP',
          target_name: 'metadata',
          target_object_type: 'database',
        },
        {
          guid: 'br_HtV8tNdp8pkrKpvED3wjLn',
          ordinal: 1,
          target_data_source_guid: 'ds_hwRe6u6w99Hu4oRc885seK',
          target_data_source_type: 'postgres',
          target_data_type: 'schema',
          target_guid: 'cl_ad6nXNYsxfWF9Gb76hG9bo',
          target_name: 'public',
          target_object_type: 'schema',
        },
        {
          guid: 'br_GLTPtWFa55NsvWXuMhL7dW',
          ordinal: 2,
          target_data_source_guid: 'ds_hwRe6u6w99Hu4oRc885seK',
          target_data_source_type: 'postgres',
          target_data_type: 'table',
          target_guid: 'ta_JA6LJuTAnC873kWF8JALdQ',
          target_name: 'django_migrations',
          target_object_type: 'table',
        },
      ],
      data_type: 'number',
      data_types: {
        data_source_type: 'postgres',
        data_type: 'number',
        object_type: 'column',
      },
      deactivation_scheduled_on: null,
      description: 'Unique identifier of the migration.',
      description_source: 'ai',
      external_type: 'int4',
      foreign_keys: [],
      guid: 'co_ii4Vfwk6ZeGC3JPM7V8s2Y',
      ingested_description: '',
      is_foreign_key: false,
      is_join_key: false,
      is_mention: false,
      is_metric: false,
      is_primary_key: true,
      name: 'id',
      popularity: null,
      richtext_description:
        '[{"type":"paragraph","children":[{"text":"Unique identifier of the migration."}]}]',
      sample_join_query: null,
      search_name: 'metadata.public.django_migrations.id',
      suggested_description: null,
      suggested_description_source: 'unknown',
      suggested_description_source_object: null,
      table: {
        guid: 'ta_JA6LJuTAnC873kWF8JALdQ',
        name: 'django_migrations',
      },
      tagged_items: [
        {
          kind: 'suggestion',
          suggested_source_kind: 'pii_from_name',
          suggestion_source: null,
          suggestion_source_object: {
            obj: null,
            object_type: 'piirule',
          },
          tag: {
            breadcrumbs: [],
            color: '#f38816',
            guid: 'tg_58DHZq47yRU6ToLxrQAZ4F',
            icon: 'pii',
            links_to: null,
            name: 'PII',
            type: 'status',
            visible: true,
          },
        },
      ],
      user_description:
        '[{"type":"paragraph","children":[{"text":"Unique identifier of the migration.1"}]}]',
    },
    {
      ai_description: 'Name of the application associated with the migration.',
      breadcrumbs: [
        {
          guid: 'br_XLH9C328oevWaKpp2YEE3B',
          ordinal: 0,
          target_data_source_guid: 'ds_hwRe6u6w99Hu4oRc885seK',
          target_data_source_type: 'postgres',
          target_data_type: 'database',
          target_guid: 'db_njinRVkZb4Shk7DqzNFoyP',
          target_name: 'metadata',
          target_object_type: 'database',
        },
        {
          guid: 'br_fKfvJgVBCEpTJy4CMbhVqb',
          ordinal: 1,
          target_data_source_guid: 'ds_hwRe6u6w99Hu4oRc885seK',
          target_data_source_type: 'postgres',
          target_data_type: 'schema',
          target_guid: 'cl_ad6nXNYsxfWF9Gb76hG9bo',
          target_name: 'public',
          target_object_type: 'schema',
        },
        {
          guid: 'br_LCufY8Xuzh4DmGu2DYT4Tq',
          ordinal: 2,
          target_data_source_guid: 'ds_hwRe6u6w99Hu4oRc885seK',
          target_data_source_type: 'postgres',
          target_data_type: 'table',
          target_guid: 'ta_JA6LJuTAnC873kWF8JALdQ',
          target_name: 'django_migrations',
          target_object_type: 'table',
        },
      ],
      data_type: 'string',
      data_types: {
        data_source_type: 'postgres',
        data_type: 'string',
        object_type: 'column',
      },
      deactivation_scheduled_on: null,
      description: 'Name of the application associated with the migration.',
      description_source: 'ai',
      external_type: 'varchar',
      foreign_keys: [],
      guid: 'co_VYBXmtKwS55s8RvixMxWVi',
      ingested_description: '',
      is_foreign_key: false,
      is_join_key: false,
      is_mention: false,
      is_metric: false,
      is_primary_key: false,
      name: 'app',
      popularity: null,
      richtext_description: '',
      sample_join_query: null,
      search_name: 'metadata.public.django_migrations.app',
      suggested_description: null,
      suggested_description_source: 'unknown',
      suggested_description_source_object: null,
      table: {
        guid: 'ta_JA6LJuTAnC873kWF8JALdQ',
        name: 'django_migrations',
      },
      tagged_items: [
        {
          kind: 'suggestion',
          suggested_source_kind: 'pii_from_name',
          suggestion_source: null,
          suggestion_source_object: {
            obj: null,
            object_type: 'piirule',
          },
          tag: {
            breadcrumbs: [],
            color: '#f38816',
            guid: 'tg_58DHZq47yRU6ToLxrQAZ4F',
            icon: 'pii',
            links_to: null,
            name: 'PII',
            type: 'status',
            visible: true,
          },
        },
      ],
      user_description: null,
    },
  ],
  schema_count: 1,
  table_count: 1,
});

export const fetchColumnMock = fetchColumnSelect({
  guid: 'co_Hx2wUMncJNbBAv2rrzPs9E',
  table: {
    guid: 'ta_JTnprm3kK2gmWy84HepQ9h',
    name: 'ORDERS',
    database: {
      guid: 'db_TSiF4zUe6gm7J2rTAEPYTQ',
      data_source: {
        guid: 'ds_gsKLQ5NrrRYgLht9KD6soq',
        type: 'snowflake',
      },
    },
  },
  search_name: 'OLIST.DATASETS.ORDERS.ORDER_ID',
  name: 'ORDER_ID',
  is_primary_key: true,
  is_foreign_key: false,
  is_metric: true,
  is_mention: true,
  is_join_key: true,
  is_unique: null,
  is_nullable: null,
  external_type: 'TEXT',
  data_type: 'string',
  data_types: {
    object_type: 'column',
    data_source_type: 'snowflake',
    data_type: 'string',
  },
  breadcrumbs: [
    {
      guid: 'br_YqEpCBKAjaV6mT37qvx75Y',
      target_guid: 'db_TSiF4zUe6gm7J2rTAEPYTQ',
      target_name: 'OLIST',
      target_object_type: 'database',
      target_data_type: 'database',
      target_data_source_guid: 'ds_gsKLQ5NrrRYgLht9KD6soq',
      target_data_source_type: 'snowflake',
      ordinal: 0,
    },
    {
      guid: 'br_AzL9YQBYM4twTWcqnMjFFp',
      target_guid: 'cl_dVyXhPvLeEkzuyowANTq8h',
      target_name: 'DATASETS',
      target_object_type: 'schema',
      target_data_type: 'schema',
      target_data_source_guid: 'ds_gsKLQ5NrrRYgLht9KD6soq',
      target_data_source_type: 'snowflake',
      ordinal: 1,
    },
    {
      guid: 'br_5UnKENiJiwLTguyMRuskX6',
      target_guid: 'ta_JTnprm3kK2gmWy84HepQ9h',
      target_name: 'ORDERS',
      target_object_type: 'table',
      target_data_type: 'table',
      target_data_source_guid: 'ds_gsKLQ5NrrRYgLht9KD6soq',
      target_data_source_type: 'snowflake',
      ordinal: 2,
    },
  ],
  description: '',
  popularity: {
    timeframe: 90,
    user_count: 11,
    service_query_count: 214,
    popularity: 88,
    query_count: 20,
  },
  tagged_items: [
    {
      guid: 'ti_AXFRZL9y3NwfYVEZkqDkjH',
      tag: {
        guid: 'tg_BBvnT97BRry5gzRa3vKdKM',
        type: 'status',
        name: 'Core',
        icon: 'endorsed',
        color: '#549f93',
        visible: true,
        links_to: null,
        breadcrumbs: [],
      },
      kind: 'user-defined',
      suggested_source_kind: 'unknown',
      suggestion_source: null,
      suggestion_source_object: null,
    },
    {
      guid: 'ti_8MBRDvS4rHYQa4W9suwTNA',
      tag: {
        guid: 'tg_TFDdK3WcKu7GEWS9GmoJ7g',
        type: 'status',
        name: 'new tag',
        icon: 'endorsed',
        color: '#549f93',
        visible: true,
        links_to: null,
        breadcrumbs: [],
      },
      kind: 'user-defined',
      suggested_source_kind: 'unknown',
      suggestion_source: null,
      suggestion_source_object: null,
    },
    {
      guid: 'ti_TGTBbYa6NKXjRLTeMiyTXn',
      tag: {
        guid: 'tg_VrXRkbPLKE3rxrKmcp9Ld8',
        type: 'status',
        name: 'Protected',
        icon: 'pii',
        color: '#f38816',
        visible: true,
        links_to: null,
        breadcrumbs: [
          {
            guid: 'br_jrFWrchaJuYrq8oVhpJqMk',
            target_guid: 'tg_co8FbEWvTqzwvfujuok3gA',
            target_name: 'Working Gold',
            target_object_type: 'tag',
            target_data_type: 'tag',
            target_data_source_guid: null,
            target_data_source_type: null,
            ordinal: 0,
          },
        ],
      },
      kind: 'suggestion',
      suggested_source_kind: 'downstream_lineage',
      suggestion_source: {
        item: {
          object_type: 'lookmlfield',
          obj: {
            object_type: 'lookmlfield',
            guid: 'lf_JAhtuxNjdmZHxmfX3hcqLm',
            name: 'Unique Order ID',
            breadcrumbs: [
              {
                guid: 'br_LqJ6BRHwPbHTrdvW6bLa74',
                target_guid: 'lp_4gaoNqRcKDpTFnriYXmDNL',
                target_name: 'olist_ecommerce',
                target_object_type: 'lookmlproject',
                target_data_type: 'lookmlproject',
                target_data_source_guid: 'ds_RueDSe9gSahU3ed93skbJw',
                target_data_source_type: 'looker',
                ordinal: 0,
              },
              {
                guid: 'br_NdPSfzeC8RpmozXiVixP5d',
                target_guid: 'lv_77PUe2JXSLrXotKRGcbS3X',
                target_name: 'min_orders',
                target_object_type: 'lookmlview',
                target_data_type: 'lookmlview',
                target_data_source_guid: 'ds_RueDSe9gSahU3ed93skbJw',
                target_data_source_type: 'looker',
                ordinal: 1,
              },
            ],
          },
        },
      },
      suggestion_source_object: {
        object_type: 'taggeditems',
        obj: {
          item: {
            object_type: 'lookmlfield',
            obj: {
              object_type: 'lookmlfield',
              guid: 'lf_JAhtuxNjdmZHxmfX3hcqLm',
              name: 'Unique Order ID',
              breadcrumbs: [
                {
                  guid: 'br_LqJ6BRHwPbHTrdvW6bLa74',
                  target_guid: 'lp_4gaoNqRcKDpTFnriYXmDNL',
                  target_name: 'olist_ecommerce',
                  target_object_type: 'lookmlproject',
                  target_data_type: 'lookmlproject',
                  target_data_source_guid: 'ds_RueDSe9gSahU3ed93skbJw',
                  target_data_source_type: 'looker',
                  ordinal: 0,
                },
                {
                  guid: 'br_NdPSfzeC8RpmozXiVixP5d',
                  target_guid: 'lv_77PUe2JXSLrXotKRGcbS3X',
                  target_name: 'min_orders',
                  target_object_type: 'lookmlview',
                  target_data_type: 'lookmlview',
                  target_data_source_guid: 'ds_RueDSe9gSahU3ed93skbJw',
                  target_data_source_type: 'looker',
                  ordinal: 1,
                },
              ],
            },
          },
          guid: 'ti_g5tYDumwWP5UKhecvq3SKn',
          tag: {
            guid: 'tg_VrXRkbPLKE3rxrKmcp9Ld8',
            type: 'status',
            name: 'Protected',
            icon: 'pii',
            color: '#f38816',
            visible: true,
            links_to: null,
            breadcrumbs: [
              {
                guid: 'br_jrFWrchaJuYrq8oVhpJqMk',
                target_guid: 'tg_co8FbEWvTqzwvfujuok3gA',
                target_name: 'Working Gold',
                target_object_type: 'tag',
                target_data_type: 'tag',
                target_data_source_guid: null,
                target_data_source_type: null,
                ordinal: 0,
              },
            ],
          },
          kind: 'suggestion',
          suggested_source_kind: 'downstream_lineage',
        },
      },
    },
    {
      guid: 'ti_c7ALn3dQe5exwWfdz2Y4w6',
      tag: {
        guid: 'tg_HNCU2UmnQPFA9uQJ5Mor8Q',
        type: 'status',
        name: 'status test',
        icon: 'endorsed',
        color: '#549f93',
        visible: true,
        links_to: null,
        breadcrumbs: [],
      },
      kind: 'suggestion',
      suggested_source_kind: 'downstream_lineage',
      suggestion_source: {
        item: {
          object_type: 'lookmlfield',
          obj: {
            object_type: 'lookmlfield',
            guid: 'lf_JAhtuxNjdmZHxmfX3hcqLm',
            name: 'Unique Order ID',
            breadcrumbs: [
              {
                guid: 'br_LqJ6BRHwPbHTrdvW6bLa74',
                target_guid: 'lp_4gaoNqRcKDpTFnriYXmDNL',
                target_name: 'olist_ecommerce',
                target_object_type: 'lookmlproject',
                target_data_type: 'lookmlproject',
                target_data_source_guid: 'ds_RueDSe9gSahU3ed93skbJw',
                target_data_source_type: 'looker',
                ordinal: 0,
              },
              {
                guid: 'br_NdPSfzeC8RpmozXiVixP5d',
                target_guid: 'lv_77PUe2JXSLrXotKRGcbS3X',
                target_name: 'min_orders',
                target_object_type: 'lookmlview',
                target_data_type: 'lookmlview',
                target_data_source_guid: 'ds_RueDSe9gSahU3ed93skbJw',
                target_data_source_type: 'looker',
                ordinal: 1,
              },
            ],
          },
        },
      },
      suggestion_source_object: {
        object_type: 'taggeditems',
        obj: {
          item: {
            object_type: 'lookmlfield',
            obj: {
              object_type: 'lookmlfield',
              guid: 'lf_JAhtuxNjdmZHxmfX3hcqLm',
              name: 'Unique Order ID',
              breadcrumbs: [
                {
                  guid: 'br_LqJ6BRHwPbHTrdvW6bLa74',
                  target_guid: 'lp_4gaoNqRcKDpTFnriYXmDNL',
                  target_name: 'olist_ecommerce',
                  target_object_type: 'lookmlproject',
                  target_data_type: 'lookmlproject',
                  target_data_source_guid: 'ds_RueDSe9gSahU3ed93skbJw',
                  target_data_source_type: 'looker',
                  ordinal: 0,
                },
                {
                  guid: 'br_NdPSfzeC8RpmozXiVixP5d',
                  target_guid: 'lv_77PUe2JXSLrXotKRGcbS3X',
                  target_name: 'min_orders',
                  target_object_type: 'lookmlview',
                  target_data_type: 'lookmlview',
                  target_data_source_guid: 'ds_RueDSe9gSahU3ed93skbJw',
                  target_data_source_type: 'looker',
                  ordinal: 1,
                },
              ],
            },
          },
          guid: 'ti_XxANGq2zcdduYaiJQ5i4Zd',
          tag: {
            guid: 'tg_HNCU2UmnQPFA9uQJ5Mor8Q',
            type: 'status',
            name: 'status test',
            icon: 'endorsed',
            color: '#549f93',
            visible: true,
            links_to: null,
            breadcrumbs: [],
          },
          kind: 'suggestion',
          suggested_source_kind: 'downstream_lineage',
        },
      },
    },
    {
      guid: 'ti_PcLz7bfYbdzQc8jr9PZS2U',
      tag: {
        guid: 'tg_2tfxSYzRW53BJtvKKXYWpJ',
        type: 'category',
        name: 'Finance',
        icon: 'tag',
        color: '#386b63',
        visible: true,
        links_to: null,
        breadcrumbs: [],
      },
      kind: 'suggestion',
      suggested_source_kind: 'upstream_lineage',
      suggestion_source: null,
      suggestion_source_object: {
        object_type: 'taggeditems',
        obj: {
          item: {
            object_type: 'column',
            obj: {
              object_type: 'column',
              guid: 'co_9NwwHJf2MH9Eb6JZrmKEbz',
              name: 'ORDER_ID',
              breadcrumbs: [
                {
                  guid: 'br_kr5oDmPKojscTAN7oBcLXd',
                  target_guid: 'db_TSiF4zUe6gm7J2rTAEPYTQ',
                  target_name: 'OLIST',
                  target_object_type: 'database',
                  target_data_type: 'database',
                  target_data_source_guid: 'ds_gsKLQ5NrrRYgLht9KD6soq',
                  target_data_source_type: 'snowflake',
                  ordinal: 0,
                },
                {
                  guid: 'br_XBsXYyzJiPY3ZjEK8sC9sw',
                  target_guid: 'cl_dVyXhPvLeEkzuyowANTq8h',
                  target_name: 'DATASETS',
                  target_object_type: 'schema',
                  target_data_type: 'schema',
                  target_data_source_guid: 'ds_gsKLQ5NrrRYgLht9KD6soq',
                  target_data_source_type: 'snowflake',
                  ordinal: 1,
                },
                {
                  guid: 'br_kEyGhC2VThh8ErCNxVTokf',
                  target_guid: 'ta_ZeQDMFCVYy6MNNriCusQoT',
                  target_name: 'ORDER_TRACKING',
                  target_object_type: 'table',
                  target_data_type: 'table',
                  target_data_source_guid: 'ds_gsKLQ5NrrRYgLht9KD6soq',
                  target_data_source_type: 'snowflake',
                  ordinal: 2,
                },
              ],
            },
          },
          guid: 'ti_PmmT9p7GqPj3BsTYVwKdUd',
          tag: {
            guid: 'tg_2tfxSYzRW53BJtvKKXYWpJ',
            type: 'category',
            name: 'Finance',
            icon: 'tag',
            color: '#386b63',
            visible: true,
            links_to: null,
            breadcrumbs: [],
          },
          kind: 'user-defined',
          suggested_source_kind: 'unknown',
        },
      },
    },
    {
      guid: 'ti_6SKNHwABEcuBhgfij5NAz8',
      tag: {
        guid: 'tg_KFw5nDGAUbxLNN2L88AkDj',
        type: 'category',
        name: 'Orders',
        icon: 'tag',
        color: '#33b1ff',
        visible: true,
        links_to: null,
        breadcrumbs: [],
      },
      kind: 'suggestion',
      suggested_source_kind: 'upstream_lineage',
      suggestion_source: null,
      suggestion_source_object: {
        object_type: 'taggeditems',
        obj: {
          item: {
            object_type: 'lookmlfield',
            obj: {
              object_type: 'lookmlfield',
              guid: 'lf_YHiGgwXjUDMokLiBjB3AVr',
              name: 'Order Count',
              breadcrumbs: [
                {
                  guid: 'br_75ftbbSh8PPh5WTsHkYj3t',
                  target_guid: 'lp_4gaoNqRcKDpTFnriYXmDNL',
                  target_name: 'olist_ecommerce',
                  target_object_type: 'lookmlproject',
                  target_data_type: 'lookmlproject',
                  target_data_source_guid: 'ds_RueDSe9gSahU3ed93skbJw',
                  target_data_source_type: 'looker',
                  ordinal: 0,
                },
                {
                  guid: 'br_cGZB44mEGuLKnSN856WpBc',
                  target_guid: 'lv_8ZBAjja7R9Tkrr85uZrVcM',
                  target_name: 'order_facts',
                  target_object_type: 'lookmlview',
                  target_data_type: 'lookmlview',
                  target_data_source_guid: 'ds_RueDSe9gSahU3ed93skbJw',
                  target_data_source_type: 'looker',
                  ordinal: 1,
                },
              ],
            },
          },
          guid: 'ti_Kyruj9BZWAKPJnt3kckUii',
          tag: {
            guid: 'tg_KFw5nDGAUbxLNN2L88AkDj',
            type: 'category',
            name: 'Orders',
            icon: 'tag',
            color: '#33b1ff',
            visible: true,
            links_to: null,
            breadcrumbs: [],
          },
          kind: 'user-defined',
          suggested_source_kind: 'unknown',
        },
      },
    },
  ],
  foreign_keys: [],
  description_source: null,
  richtext_description: '',
  user_description:
    '[{"type":"paragraph","children":[{"text":"Lorem ipsum dolor sit amet "},{"children":[{"text":""}],"mention":{"guid":"ta_DfqsL9jcm9ssxp6c8Z6M32","name":"PAYMENT_METHOD"},"type":"mention"},{"text":" testing description Pablo"}]}]',
  suggested_description: 'Order Status',
  ingested_description: '',
  ai_description: 'Unique identifier for each order.',
  downstream_objects_counts: {
    chart: 222,
    cloudobject: 0,
    column: 125,
    dashboard: 0,
    dashboardelement: 49,
    datastudiochart: 0,
    datastudiopage: 0,
    explore: 0,
    explorefield: 52,
    lookmlfield: 18,
    lookmlview: 0,
    metabasecolumn: 726,
    metabasetable: 0,
    page: 0,
    powerbicolumn: 81,
    powerbitable: 0,
    reportquery: 144,
    table: 0,
    tableaucolumn: 53,
    tableaudatasource: 0,
    tableaufield: 85,
    tableautable: 0,
    tableauview: 29,
    thoughtspotcolumn: 5,
    thoughtspottable: 0,
    source_table: 0,
    total: 1367,
  },
  upstream_objects_counts: {
    chart: 0,
    cloudobject: 0,
    column: 20,
    dashboard: 0,
    dashboardelement: 0,
    datastudiochart: 0,
    datastudiopage: 0,
    explore: 0,
    explorefield: 0,
    lookmlfield: 0,
    lookmlview: 0,
    metabasecolumn: 0,
    metabasetable: 0,
    page: 0,
    powerbicolumn: 0,
    powerbitable: 0,
    reportquery: 0,
    table: 0,
    tableaucolumn: 0,
    tableaudatasource: 0,
    tableaufield: 0,
    tableautable: 0,
    tableauview: 0,
    thoughtspotcolumn: 0,
    thoughtspottable: 0,
    source_table: 0,
    total: 20,
  },
  suggested_description_source: 'downstream_lineage',
  suggested_description_source_object: {
    object_type: 'column',
    obj: {
      object_type: 'column',
      guid: 'co_Xyg8isWvY2ZFkHikFhQWrc',
      name: 'ORDER_ID',
      breadcrumbs: [
        {
          guid: 'br_MxWYdyvWqy9cJPPpMjVPG4',
          target_guid: 'db_TSiF4zUe6gm7J2rTAEPYTQ',
          target_name: 'OLIST',
          target_object_type: 'database',
          target_data_type: 'database',
          target_data_source_guid: 'ds_gsKLQ5NrrRYgLht9KD6soq',
          target_data_source_type: 'snowflake',
          ordinal: 0,
        },
        {
          guid: 'br_C57BvTw6m3KJvAcLC7GP6U',
          target_guid: 'cl_dVyXhPvLeEkzuyowANTq8h',
          target_name: 'DATASETS',
          target_object_type: 'schema',
          target_data_type: 'schema',
          target_data_source_guid: 'ds_gsKLQ5NrrRYgLht9KD6soq',
          target_data_source_type: 'snowflake',
          ordinal: 1,
        },
        {
          guid: 'br_nYnuQAGyPKMzMd784mYLE6',
          target_guid: 'ta_Rq5Eni6XV8uSP5kscNEEVg',
          target_name: 'TEST10',
          target_object_type: 'table',
          target_data_type: 'table',
          target_data_source_guid: 'ds_gsKLQ5NrrRYgLht9KD6soq',
          target_data_source_type: 'snowflake',
          ordinal: 2,
        },
      ],
    },
  },
});
