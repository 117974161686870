import type { DataTypesConfig } from '../dataTypes/types';

export const NO_LINK = '#';

export const URL_CONFIG: DataTypesConfig<string> = {
  bigquery: {
    column: {
      default: '/tables/{parentGuid}/columns/{guid}',
    },
    database: {
      default: '/databases/{guid}',
    },
    datasource: {
      default: '/admin/bigquery/{guid}',
    },
    default: NO_LINK,
    dsuser: {
      default: '/dsusers/{guid}',
    },
    schema: {
      default: '/schemas/{guid}',
    },
    table: {
      default: '/tables/{guid}',
    },
  },
  data_studio: {
    bifolder: {
      default: '/data-studio/{guid}/folder',
    },
    dashboard: {
      data_studio_dashboard: '/dashboards/{guid}',
      default: NO_LINK,
    },
    datasource: {
      default: '/admin/data_studio/{guid}',
    },
    default: NO_LINK,
    dsuser: {
      default: '/dsusers/{guid}',
    },
    reportquery: {
      default: NO_LINK,
      page: '/dashboards/{parentGuid}/pages/{guid}',
    },
  },
  databricks: {
    bifolder: {
      default: '/notebooks/fl/{guid}',
    },
    column: {
      default: '/tables/{parentGuid}/columns/{guid}',
    },
    database: {
      default: '/databases/{guid}',
    },
    databricks_notebooks_container: {
      default: '/notebooks/ds/{parentGuid}',
    },
    datasource: {
      default: '/admin/databricks/{guid}',
    },
    default: NO_LINK,
    dsuser: {
      default: '/dsusers/{guid}',
    },
    notebook: {
      default: '/notebooks/{guid}',
    },
    schema: {
      default: '/schemas/{guid}',
    },
    table: {
      default: '/tables/{guid}',
    },
  },
  dbt: {
    column: {
      default: '/tables/{parentGuid}/columns/{guid}',
    },
    datasource: {
      default: '/admin/dbt/{guid}',
    },
    dbt_test_container: {
      default: '/dbt-tests/{parentGuid}/{guid}/tests',
    },
    dbttest: {
      default: '/dbt-tests/{parentGuid}/tests/{guid}',
    },
    default: NO_LINK,
    dsuser: {
      default: '/dsusers/{guid}',
    },
    folder: {
      default: '/projects/{guid}',
    },
    table: {
      default: '/tables/{guid}',
    },
  },
  document: {
    default: '/docs/{guid}',
  },
  glue: {
    column: {
      default: '/tables/{parentGuid}/columns/{guid}',
    },
    database: {
      default: '/databases/{guid}',
    },
    datasource: {
      default: '/admin/glue/{guid}',
    },
    default: NO_LINK,
    dsuser: {
      default: '/dsusers/{guid}',
    },
    schema: {
      default: '/schemas/{guid}',
    },
    table: {
      default: '/tables/{guid}',
    },
  },
  looker: {
    bifolder: {
      default: '/folders/{guid}',
      group: '/groups/{guid}',
    },
    dashboard: {
      default: '/dashboards/{guid}',
    },
    dashboardelement: {
      default: '/dashboards/{parentGuid}/tiles/{guid}',
    },
    datasource: {
      default: '/admin/looker/{guid}',
    },
    default: NO_LINK,
    dsuser: {
      default: '/dsusers/{guid}',
    },
    explore: {
      default: '/explores/{guid}',
    },
    explorefield: {
      default: '/explores/{parentGuid}/fields/{guid}',
    },
    folders_container: {
      default: '/looker/{guid}/folders_container/dashboards',
    },
    lookmlfield: {
      default: '/views/{parentGuid}/fields/{guid}',
    },
    lookmlproject: {
      default: '/lookmlprojects/{guid}',
    },
    lookmlview: {
      default: '/views/{guid}',
    },
    models_container: {
      default: '/looker/{guid}/models_container/explores',
    },
    views_container: {
      default: '/looker/{guid}/views_container/views',
    },
  },
  metabase: {
    bifolder: {
      collection: '/metabase/{guid}/folder',
      default: NO_LINK,
    },
    dashboard: {
      default: NO_LINK,
      metabase_dashboard: '/dashboards/{guid}',
    },
    datasource: {
      default: '/admin/metabase/{guid}',
    },
    default: NO_LINK,
    dsuser: {
      default: '/dsusers/{guid}',
    },
    metabase_folders_container: {
      default: '/metabase/{guid}/metabase_folders_container/datasource',
    },
    reportquery: {
      card: '/dashboards/{parentGuid}/cards/{guid}',
      default: NO_LINK,
    },
  },
  metric: {
    default: '/metrics/{guid}',
  },
  mode: {
    bifolder: {
      default: NO_LINK,
      space: '/spaces/{guid}',
    },
    collections_container: {
      default: '/mode/{guid}/collections_container/spaces',
    },
    dashboard: {
      default: NO_LINK,
      report: '/dashboards/{guid}/queries',
    },
    datasource: {
      default: '/admin/mode/{guid}',
    },
    default: NO_LINK,
    dsuser: {
      default: '/dsusers/{guid}',
    },
    reportquery: {
      default: NO_LINK,
      query: '/dashboards/{parentGuid}/queries/{guid}',
    },
  },
  mssql: {
    column: {
      default: '/tables/{parentGuid}/columns/{guid}',
    },
    database: {
      default: '/databases/{guid}',
    },
    datasource: {
      default: '/admin/mssql/{guid}',
    },
    default: NO_LINK,
    dsuser: {
      default: '/dsusers/{guid}',
    },
    schema: {
      default: '/schemas/{guid}',
    },
    table: {
      default: '/tables/{guid}',
    },
  },
  mysql: {
    column: {
      default: '/tables/{parentGuid}/columns/{guid}',
    },
    database: {
      default: '/databases/{guid}',
    },
    datasource: {
      default: '/admin/mysql/{guid}',
    },
    default: NO_LINK,
    dsuser: {
      default: '/dsusers/{guid}',
    },
    schema: {
      default: '/schemas/{guid}',
    },
    table: {
      default: '/tables/{guid}',
    },
  },
  oracle: {
    column: {
      default: '/tables/{parentGuid}/columns/{guid}',
    },
    database: {
      default: '/databases/{guid}',
    },
    datasource: {
      default: '/admin/oracle/{guid}',
    },
    default: NO_LINK,
    dsuser: {
      default: '/dsusers/{guid}',
    },
    schema: {
      default: '/schemas/{guid}',
    },
    table: {
      default: '/tables/{guid}',
    },
  },
  postgres: {
    column: {
      default: '/tables/{parentGuid}/columns/{guid}',
    },
    database: {
      default: '/databases/{guid}',
    },
    datasource: {
      default: '/admin/postgres/{guid}',
    },
    default: NO_LINK,
    dsuser: {
      default: '/dsusers/{guid}',
    },
    schema: {
      default: '/schemas/{guid}',
    },
    table: {
      default: '/tables/{guid}',
    },
  },
  power_bi: {
    bifolder: {
      default: '/power-bi/{guid}/folder',
    },
    dashboard: {
      default: '/dashboards/{guid}',
    },
    datasource: {
      default: '/admin/power-bi/{guid}',
    },
    default: NO_LINK,
    dsuser: {
      default: '/dsusers/{guid}',
    },
    power_bi_folders_container: {
      default: '/power-bi/{parentGuid}/power_bi_folders_container/datasource',
    },
    powerbicolumn: {
      default: '/power-bi-tables/{parentGuid}/fields/{guid}',
    },
    powerbidataset: {
      default: '/power-bi-tables/{guid}',
    },
    reportquery: {
      default: NO_LINK,
      page: '/dashboards/{parentGuid}/pages/{guid}',
      tile: '/dashboards/{parentGuid}/tiles/{guid}',
    },
  },
  quicksight: {
    bicolumn: {
      default: '/datasets/{parentGuid}/fields/{guid}',
    },
    bidashboardelement: {
      default: '/dashboards/{parentGuid}/visuals/{guid}',
    },
    bidataset: {
      default: '/datasets/{guid}',
    },
    bifolder: {
      default: '/quicksight/folder/{guid}',
    },
    dashboard: {
      default: '/dashboards/{guid}',
    },
    datasource: {
      default: '/admin/quicksight/{guid}',
    },
    default: NO_LINK,
    quicksight_folders_container: {
      default: '/quicksight/{parentGuid}/folder',
    },
  },
  redshift: {
    column: {
      default: '/tables/{parentGuid}/columns/{guid}',
    },
    database: {
      default: '/databases/{guid}',
    },
    datasource: {
      default: '/admin/redshift/{guid}',
    },
    default: NO_LINK,
    dsuser: {
      default: '/dsusers/{guid}',
    },
    schema: {
      default: '/schemas/{guid}',
    },
    table: {
      default: '/tables/{guid}',
    },
  },
  salesforce: {
    column: {
      default: '/tables/{parentGuid}/columns/{guid}',
    },
    database: {
      default: '/databases/{guid}',
    },
    datasource: {
      default: '/admin/mssql/{guid}',
    },
    default: NO_LINK,
    dsuser: {
      default: '/dsusers/{guid}',
    },
    schema: {
      default: '/schemas/{guid}',
    },
    table: {
      default: '/tables/{guid}',
    },
  },
  sigma: {
    bifolder: {
      default: '/sigma/{guid}/folder',
    },
    dashboard: {
      default: NO_LINK,
      sigma_dashboard: '/dashboards/{guid}',
    },
    datasource: {
      default: '/admin/sigma/{guid}',
    },
    default: NO_LINK,
    dsuser: {
      default: '/dsusers/{guid}',
    },
    reportquery: {
      default: NO_LINK,
      element: '/dashboards/{parentGuid}/elements/{guid}',
    },
  },
  snowflake: {
    column: {
      default: '/tables/{parentGuid}/columns/{guid}',
    },
    database: {
      default: '/databases/{guid}',
    },
    datasource: {
      default: '/admin/snowflake/{guid}',
    },
    default: NO_LINK,
    dsuser: {
      default: '/dsusers/{guid}',
    },
    schema: {
      default: '/schemas/{guid}',
    },
    table: {
      default: '/tables/{guid}',
    },
  },
  tableau: {
    bifolder: {
      default: NO_LINK,
      project: '/tableau/folder_container/{guid}',
    },
    dashboard: {
      default: '/dashboards/{guid}',
    },
    datasource: {
      default: '/admin/tableau/{guid}',
    },
    default: NO_LINK,
    dsuser: {
      default: '/dsusers/{guid}',
    },
    projects_container: {
      default: '/tableau/{parentGuid}/projects_container/projects',
    },
    tableaudatasource: {
      default: '/tableaudatasources/{guid}',
    },
  },
  tag: {
    default: '/tags/{guid}',
  },
  team: {
    default: '/teams/{guid}',
  },
  term: {
    default: '/terms/{guid}',
  },
  thoughtspot: {
    bifolder: {
      answer: '/thoughtspot/folder_container/{guid}?{dataTypesParams}',
      default: NO_LINK,
      liveboard: '/thoughtspot/folder_container/{guid}?{dataTypesParams}',
      one_to_one_logical: '/bi-tables/folder_container/{guid}?{dataTypesParams}',
      sql_view: '/bi-tables/folder_container/{guid}?{dataTypesParams}',
      worksheet: '/bi-tables/folder_container/{guid}?{dataTypesParams}',
    },
    dashboard: {
      default: '/dashboards/{guid}',
    },
    datasource: {
      default: '/admin/thoughtspot/{guid}',
    },
    default: NO_LINK,
    dsuser: {
      default: '/dsusers/{guid}',
    },
    reportquery: {
      column: '/dashboards/{parentGuid}/fields/{guid}',
      default: NO_LINK,
      visual: '/dashboards/{parentGuid}/charts/{guid}',
    },
    thoughtspot_folders_container: {
      default: '/thoughtspot/{parentGuid}/folder_container?{dataTypesParams}',
    },
    thoughtspotcolumn: {
      default: '/bi-table/{parentGuid}/fields/{guid}',
    },
    thoughtspottable: {
      default: '/bi-table/{guid}',
    },
    ts_data_container: { default: '/bi-tables/{parentGuid}/folder_container?{dataTypesParams}' },
  },
  user: {
    default: '/profiles/{guid}',
  },
};
