import React from 'react';

import { useFetchDsUserMostQueriedTables } from '@api/dsusers';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import { useFetchTables } from '@api/tables';
import Box from '@components/Box';
import { GridContainer } from '@components/Grid';
import { Profile } from '@components/Profile';
import RecentQueriesTab from '@components/TabContent/RecentQueriesTab';
import { OwnsTable, TablesQueryTable } from '@components/Table';
import TabsRouter from '@components/TabsRouter';
import { tableOwnsQuery } from '@models/Owners';
import MetadataDecorator from '@utils/MetadataDecorator';
import stripSpaces from '@utils/stripSpaces';

import DSUserPageStyled from './DSUserPageStyled';

const query = stripSpaces(`{
  guid,
  external_id,
  executed_on,
  duration,
  query {
    name,
    guid,
    raw_sql,
    database,
    last_executed_on,
    created_by {
      guid,
      first_name,
      last_name,
      avatar
    },
    dsuser_created_by {
      guid,
      name,
      display_name
    }
  },
  dsuser {
    guid,
    name,
    display_name,
    data_types,
    user {
      guid,
      first_name,
      last_name,
      avatar
    },
    data_source {
      guid,
      name,
      type
    }
  }
}`);

const popularTablesConfig = {
  params: {
    page_size: 20,
  },
};

interface DSUserPageProps {
  dsuser: DsUserModel;
  guid: string;
}

const DSUserPage: React.FC<DSUserPageProps> = ({ dsuser, guid }) => {
  const { data: popularTables } = useFetchDsUserMostQueriedTables(guid, {
    ...popularTablesConfig,
  });

  const { data: ownedTables } = useFetchTables({
    params: {
      dsuser_created_by: guid,
      order: '-popularity',
      page_size: 20,
      query: tableOwnsQuery,
    },
  });

  const tabObjects = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <TablesQueryTable data={popularTables?.results} />,
      default: true,
      label: 'Most Queried',
      route: '/most-queried',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <RecentQueriesTab
          hiddenColumns={['user']}
          requestConfig={{
            dsusers: [guid],
            order: '-executed_on',
            page_size: 20,
            query,
          }}
          stickyHeader
        />
      ),
      label: 'Recent Queries',
      route: '/recent-queries',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <OwnsTable data={ownedTables?.results} stickyHeader />,
      label: 'Owns',
      route: '/owns',
    },
  ];

  return (
    <>
      <MetadataDecorator title={dsuser?.fullName} />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <DSUserPageStyled>
          {dsuser && <Profile connectedAccounts={[dsuser]} item={dsuser} />}
          <Box mt={6}>
            <TabsRouter config={tabObjects} sticky />
          </Box>
        </DSUserPageStyled>
      </GridContainer>
    </>
  );
};

export default DSUserPage;
