import React from 'react';

import Box from '@components/Box';
import Text from '@components/Text';
import { TreeKey } from '@components/Tree/Tree.v2/types';
import HR from '@components/UI/HR';
import Option from '@components/UI/Select.v1/Option';
import type { Option as OptionType } from '@components/UI/Select.v1/types';
import { GetItemPropsParams } from '@components/UI/Select.v1/useSelect';
import theme from '@styles/theme';

import { StyledTreeOptionsItem, StyledTreeV2 } from './TreeOptions.styles';

interface TreeOptionsProps {
  expandedKeys?: TreeKey[];
  filteredItems: OptionType[];
  flatOptions: OptionType[];
  getItemProps: (params: GetItemPropsParams) => Record<string, any>;
  selectedItems: OptionType[];
  setSelectedItems?: (items: OptionType[]) => void;
  updateFlatOptions: (expandedOption: OptionType, isExpanded: boolean) => void;
}

const TreeOptions = ({
  expandedKeys = [],
  filteredItems,
  flatOptions,
  getItemProps,
  selectedItems,
  setSelectedItems,
  updateFlatOptions,
}: TreeOptionsProps) => {
  return (
    <StyledTreeV2
      allowCarrot={({ level, original: item }) => item?.children?.length > 0 && level > 0}
      allowDataLoad={({ original: item }) => Boolean(item?.children?.length > 0)}
      checkboxProps={({ original }) => ({
        checked: selectedItems.some((selected) => selected.value === original.value),
        indeterminate: selectedItems.find((item) => item.value === original.value)
          ?.checkboxIndeterminate,
        variantColor: selectedItems.find((item) => item.value === original.value)?.checkboxColor,
      })}
      disableHover={({ level }) => level === 0}
      expandedKeys={[...filteredItems.map((item) => item.value.toString()), ...expandedKeys]}
      expandOnNodeClick={false}
      getKey={(item) => item.value}
      getNodeExtraProps={({ level, original: item }) => {
        const { original } = item;

        const itemIndex = flatOptions.findIndex((treeNode) => treeNode.value === original.guid);

        if (itemIndex === -1) {
          return {};
        }

        const itemProps = getItemProps({
          disabled: level === 0,
          index: itemIndex,
          item: original,
        });

        return { ...itemProps };
      }}
      highlightedKeys={[]}
      hoverBackgroundColor={theme.colors.v1.gray[100]}
      hoverFontWeight="normal"
      hoverLetterSpacing="normal"
      offset={(level) => (level === 0 ? 0 : level - 1)}
      onCarrotClick={(node, isExpanded) => {
        updateFlatOptions(node.original, isExpanded);
      }}
      onNodeClick={(node) => {
        if (node.level > 0) {
          const isSelected = selectedItems.some(
            (selected) => selected.value === node.original.value,
          );
          if (isSelected) {
            setSelectedItems?.(
              selectedItems.filter((i) => {
                return i.value !== node.original.value;
              }),
            );
          } else {
            setSelectedItems?.([...selectedItems, node.original]);
          }
        }
      }}
      renderLabel={({ key, level, original: item }) => {
        const { original, ...rest } = item;

        if (level === 0) {
          return (
            <Box compWidth="100%">
              {filteredItems[0].value !== key && <HR mb={1} mt={0} />}
              <Box alignItems="center" compDisplay="flex" compHeight="32px">
                <Text
                  color="gray.500"
                  fontSize={theme.typography.fontSizes.sm}
                  fontWeight="bold"
                  lineHeight={theme.typography.lineHeights.md}
                  textTransform="uppercase"
                >
                  {item.text}
                </Text>
              </Box>
            </Box>
          );
        }
        return (
          <StyledTreeOptionsItem>
            <Option {...rest} />
          </StyledTreeOptionsItem>
        );
      }}
      showCheckbox={({ level }) => level > 0}
      treeData={filteredItems}
    />
  );
};

export default TreeOptions;
