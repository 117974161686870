import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { TableModel } from '@api/tables/TableModel';
import Box from '@components/Box';
import ErdExplore from '@components/ErdExplore';
import { ErdExploreProps } from '@components/ErdExplore/ErdExplore';
import { TabContentProps } from '@components/Tabs/types';
import type { TabRoutesConfig } from '@components/TabsRouter';
import TabsRouter from '@components/TabsRouter';
import Icon from '@components/UI/Icon';
import { TableViewTabProps } from '@pages/TabbedTableViewPage/TableViewTab';
import { FilterOptions } from '@utils/filters';

import PopularJoins from '../PopularJoins';
import PopularQueries from '../PopularQueriesTab/PopularQueriesTab';
import RecentQueriesTab from '../RecentQueriesTab';

export interface QueriesAndJoinsProps extends TabContentProps {
  basic?: boolean;
  dataSourceGuid?: string;
  itemMetadata?: TableModel;
  requestConfig: FilterOptions;
}

export interface QueriesJoinsTabProps extends TableViewTabProps, TabContentProps {
  dataSourceGuid?: string;
  tableGuid?: string;
}

const QueriesJoinsTab: React.FC<QueriesJoinsTabProps> = (props) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { guid, itemMetadata, stickyHeader, tableGuid } = props;
  const isColumn = Boolean(tableGuid);
  const queryConfig: FilterOptions = {
    columns: isColumn ? [guid] : undefined,
    tables: isColumn ? undefined : [guid],
  };

  const tabComponentProps: QueriesAndJoinsProps & ErdExploreProps = {
    columnsIds: [],
    getStackStartingTableId: () => guid,
    itemMetadata,
    onModalClose: () => history.push(url.replace('/queries/erd', '/queries')),
    requestConfig: queryConfig,
    tablesIds: [guid],
  };

  const tabsRouterConfig: TabRoutesConfig[] = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <PopularQueries {...tabComponentProps} isColumn={isColumn} />,
      default: true,
      label: 'Popular Queries',
      route: '/popular',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <PopularJoins {...tabComponentProps} isColumn={isColumn} />,
      label: 'Popular Joins',
      route: '/popular-joins',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <RecentQueriesTab {...tabComponentProps} />,
      label: 'Recent Queries',
      route: '/recent',
    },
    ...(guid.includes('ta_')
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => <ErdExplore {...tabComponentProps} />,
            label: (
              <Box alignItems="center" compDisplay="flex" gap={0.5}>
                ERD <Icon color="currentColor" name="open-new-tab" size="14px" />
              </Box>
            ),
            openInNewTab: Boolean(process.env.REACT_APP_CHROME_EXTENSION_BUILD),
            route: '/erd',
            variant: 'filled' as const,
          },
        ]
      : []),
  ];

  return <TabsRouter config={tabsRouterConfig} sticky={stickyHeader} subTabs />;
};

export default QueriesJoinsTab;
