import { useFetchTableEvents } from '@api/tableEvents';
import stripSpaces from '@utils/stripSpaces';

const QUERY = stripSpaces(`{
  event_on,
  query_record {
    guid,
    query {
      guid,
      raw_sql
    },
    executed_on,
    duration,
    external_id,
    rows_produced,
    dsuser {
      guid,
      name,
      display_name,
      data_source,
      is_active,
      user {
        guid,
        first_name,
        last_name,
        avatar,
        is_active
      }
    }
  }
}`);

interface UseFetchTableSqlParams {
  enabled?: boolean;
  guid: string;
}

const useFetchTableSql = ({ enabled, guid }: UseFetchTableSqlParams) => {
  const { data, isError, isLoading } = useFetchTableEvents({
    enabled,
    params: {
      order: '-event_on',
      page: 1,
      page_size: 10,
      query: QUERY,
      tables: guid,
    },
  });

  const queryLogRecords = data?.results.map((item) => item.queryRecord);

  return { data: queryLogRecords, isError, isLoading };
};

export default useFetchTableSql;
