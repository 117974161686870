import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Box from '@components/Box';
import type { StyledBoxProps } from '@components/Box/Box.styles';

import type { VariantColor, VariantSize, VariantState } from './Input.v1.styles.variants';
import { colorVariants, sizeVariants } from './Input.v1.styles.variants';

export interface StyledInputProps extends StyledBoxProps {
  compSize: VariantSize;
  hideFocusState: Boolean;
  state: VariantState;
  variantColor: VariantColor;
}

export const StyledInput = styled(Box)<StyledInputProps>`
  line-height: normal;
  display: flex;
  align-items: center;
  border: 1px solid;

  ${({ compSize = 'default' }) => sizeVariants[compSize]};

  ${({ hideFocusState, state = 'default', variantColor = 'white' }) => {
    const mappedVariant = colorVariants[variantColor];
    return css`
      ${mappedVariant[state]}
      &:focus, &:focus-within {
        ${state !== 'disabled' && !hideFocusState && mappedVariant.focused};
      }
    `;
  }};
`;

StyledInput.defaultProps = {
  borderRadius: 'default',
  borderWidth: '1px',
  color: 'gray.700',
  compWidth: '100%',
  fontFamily: 'primary',
  fontSize: 'body2',
  fontWeight: 'regular',
  gap: 1,
  noDefault: true,
  px: 1.25,
};

export const StyledInputField = styled.input`
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  background: none;
  border: none;
  color: inherit;
  display: block;
  font-size: inherit;
  min-height: 100%;
  letter-spacing: inherit;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  width: 100%;

  &::placeholder {
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    color: ${({ theme }) => theme.colors.v1.gray[300]};
  }
`;

interface HelperTextProps {
  state?: VariantState;
}

export const HelperText = styled(Box)<HelperTextProps>`
  color: ${({ state, theme }) =>
    state === 'error' ? theme.colors.error : theme.colors.v1.gray[400]};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  pointer-events: none;
  width: 100%;
`;

HelperText.defaultProps = {
  as: 'p',
  mt: 0.5,
  noDefault: true,
};
