import React from 'react';
import { ErrorBoundary } from '@sentry/react';
import { Redirect } from 'react-router-dom';

import client from '@api';
import CircularLoader from '@components/CircularLoader';
import UnexpectedError from '@components/Error/UnexpectedError';
import { useUserContext } from '@context/User';
import { isMasked } from '@pages/MaskPage/MaskPage.utils';

interface Props {
  adminExceptForPbacRequired?: boolean;
  adminRequired?: boolean;
  dataManagerRequired?: boolean;
  maskRequired?: boolean;
  staffRequired?: boolean;
  superuserRequired?: boolean;
}

const UserPermissions: React.FC<Props> = ({
  adminExceptForPbacRequired,
  adminRequired,
  children,
  dataManagerRequired,
  maskRequired,
  staffRequired,
  superuserRequired,
}) => {
  const { hasEditPermissions, isOrgAdmin, loading, organization } = useUserContext();
  const useRbac = organization?.settings?.useRbac;

  if (!useRbac && adminExceptForPbacRequired && !isOrgAdmin) {
    return <Redirect to="/" />;
  }
  if (adminRequired && !isOrgAdmin) {
    return <Redirect to="/" />;
  }
  if (dataManagerRequired && !hasEditPermissions) {
    return <Redirect to="/" />;
  }
  if (staffRequired && !client.isStaff()) {
    return <Redirect to="/" />;
  }
  if (superuserRequired && !client.isSuperUser()) {
    return <Redirect to="/" />;
  }
  if (maskRequired && !isMasked()) {
    return <Redirect to="/logout" />;
  }

  if (loading) {
    return <CircularLoader centered cover />;
  }

  return (
    <ErrorBoundary fallback={UnexpectedError}>
      <React.Suspense fallback={<CircularLoader cover />}>{children}</React.Suspense>
    </ErrorBoundary>
  );
};

export default UserPermissions;
