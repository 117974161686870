import React, { useMemo } from 'react';
import { Cell } from 'react-table';

import { SearchModel } from '@api/search/SearchModel';
import ExcerptText from '@components/ExcerptText';
import Highlighter from '@components/Highlighter';
import useSearchHighlightedWords from '@components/SearchBar/useSearchHighlightedWords';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import SearchResultCell from '@components/Table/Cells/SearchResultCell';
import TaggedItemCell from '@components/Table/Cells/TaggedItemCell';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { Filter } from '@utils';

interface FullSearchResultTableProps {
  data?: SearchModel[];
  filterService: Filter.FilterServiceInterface;
  query?: string;
  searchSuggestions?: string[];
  totalPages: number;
}

const initialSortState = [
  {
    desc: false,
    id: '_score',
  },
];

const FullSearchResultTable = React.memo<FullSearchResultTableProps>(
  ({ data, filterService, query, searchSuggestions = [], totalPages }) => {
    const { changePage, filter, sort } = filterService;

    const highlightedWords = useSearchHighlightedWords(query ?? '', searchSuggestions);

    const columns: ColumnConfig<SearchModel>[] = useMemo(
      () => [
        {
          Cell: (props: Cell<SearchModel>) => {
            const { row } = props;
            const item = row.original;
            return (
              <SearchResultCell
                {...props}
                item={item}
                searchText={query}
                searchWords={highlightedWords}
              />
            );
          },
          Header: 'Name',
          accessor: (d) => d.searchName,
          disableHiding: true,
          id: 'name',
          width: '130%',
        },
        {
          Cell: (props: Cell<SearchModel>) => {
            const { row } = props;
            const item = row.original;

            // Don't show comment descriptions because they aren't really descriptions.
            if (item.objectType === 'comment') return null;

            return (
              <ExcerptText
                isSuggestion={!!item.suggestedDescription}
                searchTerm={query}
                value={item.suggestedDescription || item.description}
              >
                {({ value }) => (
                  <Highlighter autoEscape searchWords={highlightedWords} textToHighlight={value} />
                )}
              </ExcerptText>
            );
          },
          Header: 'Description',
          accessor: 'description',
          disableFilters: true,
          id: 'description',
          width: '150%',
        },
        {
          Cell: (props: Cell<SearchModel>) => {
            const { row } = props;
            const item = row.original;

            if (item.customAttributeValues === undefined) return null;
            const customAttributeString = item.customAttributeValues
              .map((cav) => `${cav.name}: ${cav.value}`)
              .join(' | ');

            return (
              <ExcerptText searchTerm={query} value={customAttributeString}>
                {({ value }) => (
                  <Highlighter autoEscape searchWords={highlightedWords} textToHighlight={value} />
                )}
              </ExcerptText>
            );
          },
          Header: 'Custom Attributes',
          accessor: 'customAttributeValues',
          disableFilters: true,
          disableSortBy: true,
          id: 'customAttributeValues',
          width: '120%',
        },
        {
          Cell: TaggedItemCell,
          Header: 'Tags',
          accessor: (d) => d.taggedItems,
          disableFilters: true,
          disableSortBy: true,
          id: 'tags',
          width: '120%',
        },
        {
          Cell: (props: Cell<SearchModel>) => {
            const { row } = props;
            const item = row.original;
            return (
              <PopularityCell
                {...props}
                dataTypes={item.dataTypes}
                rawPopularity={item.popularity?.popularity}
              />
            );
          },
          Header: PopularityCellHeader,
          accessor: (d) => d.popularity,
          disableFilters: true,
          disableResizing: true,
          id: 'popularity',
          sortDescFirst: true,
          width: 120,
        },
      ],
      [query, highlightedWords],
    );

    return (
      <TableStyled>
        <Table
          basic="very"
          changePage={changePage}
          className="table-full"
          columns={columns}
          compact
          data={data || []}
          disableFilters
          disableRowSelect
          initialState={{
            hiddenColumns: ['tags', 'customAttributeValues'],
            pageIndex: filter.page ? filter.page - 1 : 0,
            sortBy: initialSortState,
          }}
          loading={data === undefined}
          manualPagination
          manualSortBy
          selectable
          setSortBy={sort}
          sortable
          totalPages={totalPages}
          unstackable
        />
      </TableStyled>
    );
  },
);

export default FullSearchResultTable;
