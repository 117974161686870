import React from 'react';

import { usePatchDataSource, usePostDataSource } from '@api/dataSources';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import { DataSourceFormProps } from './types';

const ThoughtSpotForm: React.FC<DataSourceFormProps> = ({
  children,
  dataSource,
  onSuccess,
  renderBefore,
}) => {
  const segment = useSegmentContext();
  const isPatch = Boolean(dataSource);
  const postQuery = usePostDataSource({ onSuccess });
  const patchQuery = usePatchDataSource(dataSource?.guid!, { onSuccess });
  const { error, isLoading, mutate } = isPatch ? patchQuery : postQuery;

  const { handleChange, handleSubmit, values } = useForm({
    initialValues: {
      baseUrl: (dataSource?.credentials as any)?.base_url ?? '',
      name: dataSource?.name ?? '',
      secretKey: '',
      username: (dataSource?.credentials as any)?.username ?? '',
    },
    onSubmit: ({ baseUrl, name, secretKey, username }) => {
      mutate({
        credentials: {
          base_url: baseUrl,
          secret_key: secretKey,
          username,
        },
        name,
        type: 'thoughtspot',
      });
      segment?.track(SegmentTrackEventName.CreateServiceAccountConnectButtonClicked, {
        dataType: 'thoughtspot',
      });
    },
  });

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        {renderBefore?.({ error, loading: isLoading })}
        <StyledLabel>
          Display Name
          <Input
            aria-label="name"
            error={error?.data?.name}
            helperText={error?.data?.name}
            maxLength={50}
            name="name"
            onChange={handleChange}
            placeholder="ThoughtSpot"
            type="text"
            value={values.name}
          />
        </StyledLabel>
        <StyledLabel>
          Username
          <Input
            aria-label="username"
            error={error?.data?.username}
            helperText={error?.data?.username}
            name="username"
            onChange={handleChange}
            placeholder="Username"
            value={values.username}
          />
        </StyledLabel>
        <StyledLabel>
          Secret Key
          <Input
            aria-label="secretKey"
            error={error?.data?.secret_key}
            helperText={error?.data?.secret_key}
            name="secretKey"
            onChange={handleChange}
            placeholder="Secret Key"
            type="password"
            value={values.secretKey}
          />
        </StyledLabel>
        <StyledLabel>
          Base URL
          <Input
            aria-label="baseUrl"
            error={error?.data?.base_url}
            helperText={error?.data?.base_url}
            name="baseUrl"
            onChange={handleChange}
            placeholder="https://my2.thoughtspot.cloud"
            value={values.baseUrl}
          />
        </StyledLabel>
      </StyledFormHorizontalLabelGrid>
      {children?.({ error, loading: isLoading })}
    </Form>
  );
};

export default ThoughtSpotForm;
