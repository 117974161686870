import { UsageTypeType } from '@api/lineage/types';
import { LineageInteractions } from '@components/Explore.v1/Explore.types';
import parseLineageNodes from '@components/Explore.v1/useCreateNodesEdges/algorithm/parseLineageNodes';
import createStacks from '@components/Explore.v1/useCreateNodesEdges/utils/createStacks';
import { useExplore } from '@components/Explore.v1/useExplore';
import useGetConfigQueryParams from '@components/Explore.v1/useGetConfigQueryParams';

import { filterInputNodesByUsageType } from './useRemoveUsageType.utils';

const useRemoveUsageType = () => {
  const {
    initialPosition,
    inputNodesById,
    isColumnLevelLineage,
    previousTableLineageState,
    setBiggestConflictEndPerStack,
    setEdgesById,
    setInputNodesById,
    setIsColumnLevelLineage,
    setNextUserInteraction,
    setNodesById,
    setNodesByStack,
    setStacksData,
  } = useExplore();
  const { enableHorizontalGroups, shouldHideFilterLineage } = useGetConfigQueryParams();

  const removeUsageType = ({
    columnKey,
    tableGuid,
    usageTypes,
  }: {
    columnKey?: string;
    tableGuid: string;
    usageTypes: UsageTypeType[];
  }) => {
    const inputNodesToFilter = isColumnLevelLineage
      ? previousTableLineageState.inputNodesById
      : inputNodesById;

    const filteredInputNodesById = filterInputNodesByUsageType({
      inputNodesById: inputNodesToFilter,
      validUsageTypes: usageTypes,
    });

    const stacksData = createStacks({
      inputNodesById: filteredInputNodesById,
      options: {
        enableColumnEdges: false,
        enableHorizontalGroups,
        enableTableEdges: true,
        openAll: false,
      },
      startingTableId: tableGuid,
    });

    const { edgesById, nodesById, stackGroups } = stacksData;

    const result = parseLineageNodes({
      edgesById,
      initialPosition,
      isColumnLevelLineage,
      nodesById,
      shouldHideFilterLineage,
      stackGroups,
    });

    setNodesById(result.nodesById);
    setEdgesById(result.edgesById);
    setBiggestConflictEndPerStack(result.biggestConflictEndPerStack);
    setNodesByStack(result.nodesByStack);
    setStacksData(stacksData, { fitView: !columnKey });
    setInputNodesById(filteredInputNodesById);
    setIsColumnLevelLineage(false);

    if (columnKey) {
      setNextUserInteraction({
        payload: {
          nodeKey: columnKey,
        },
        type: LineageInteractions.ToggleColumnLevelLineage,
      });
    }
  };

  return {
    removeUsageType,
  };
};

export default useRemoveUsageType;
