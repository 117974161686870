import React, { useMemo, useState } from 'react';
import type { DataSource } from '@configs/dataSources/types';
import iconsConfigMap from '@configs/icons/config';

import Alert from '@components/Alert';
import Button from '@components/Button/Button';
import AddDSHeader from '@components/InformationScreen/AddDSHeader';
import Text from '@components/Text';
import Card from '@components/UI/Card';
import Icon from '@components/UI/Icon';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { useUserContext } from '@context/User';
import {
  isBIType,
  isTransformerType,
  isWarehouseType,
  ValidDSType,
} from '@models/DataSourceCredentials';
import {
  BITypeOptions,
  DataSourceOptionType,
  TransformerOptions,
  WarehouseOptions,
} from '@models/DataSourceOptions';

import {
  StyledDataSourceSelectionList,
  StyledDataSourceSelectionOption,
} from './DataSourceSelection.styles';

interface DataSourceSelectionOptionProps {
  isSelected: boolean;
  onClick: () => void;
  option?: DataSourceOptionType<DataSource>;
}

const DataSourceSelectionOption: React.FC<DataSourceSelectionOptionProps> = ({
  isSelected,
  onClick,
  option,
}) => (
  <StyledDataSourceSelectionOption isSelected={isSelected} onClick={onClick}>
    <Icon name={iconsConfigMap[option?.value!]?.default!} size="48px" />
    {option?.text}
  </StyledDataSourceSelectionOption>
);

export type NullableDSType = ValidDSType | 'NA' | 'BINA';

const toggleDSType = (current: NullableDSType) => (prev?: NullableDSType[]) => {
  if (!prev) {
    return [current];
  }
  if (prev.includes(current)) {
    return prev.filter((ds) => ds !== current);
  }
  return [...prev, current];
};

export const DWH_SELECTION_HEADER = '1. Which data warehouse / source do you use?';
export const BI_SELECTION_HEADER = '2. Which data visualization / BI tool do you use?';
export const NO_ACCESS_WARNING =
  "You do not have access to add data sources. Please contact your organization's admin.";

interface DataSourceSelectionProps {
  onSuccess: (state: NullableDSType[]) => void;
}

const DataSourceSelection: React.FC<DataSourceSelectionProps> = ({ onSuccess }) => {
  const [dsType, setDsType] = useState<NullableDSType[]>();
  const segment = useSegmentContext();
  const { isOrgAdmin, organization, settings } = useUserContext();
  const isNextButtonDisabled = useMemo(() => dsType, [dsType]);

  const handleOnSubmit = () => {
    const dsValues = dsType
      ? dsType
          .filter((ds) => ds !== 'NA' && ds !== 'BINA')
          .sort((a, b) => {
            if (isWarehouseType(a) && isBIType(b)) {
              return -1;
            }
            if (isWarehouseType(a) && isTransformerType(b)) {
              return -1;
            }
            if (isBIType(a) && isWarehouseType(b)) {
              return 1;
            }
            if (isBIType(a) && isTransformerType(b)) {
              return 1;
            }
            if (isTransformerType(a) && isWarehouseType(b)) {
              return 1;
            }
            if (isTransformerType(a) && isBIType(b)) {
              return -1;
            }
            return 0;
          })
      : [];
    segment?.track(SegmentTrackEventName.DataSourceSelectionNextButtonClicked, {
      orgName: organization?.fullName,
      types: dsValues,
    });
    onSuccess(dsValues);
  };

  const filteredWarehouseOptions = [...WarehouseOptions, ...TransformerOptions].filter((option) => {
    if (!settings?.useMssql && option.value === 'mssql') return false;
    if (!settings?.useOracle && option.value === 'oracle') return false;
    if (!settings?.useMysql && option.value === 'mysql') return false;
    if (!settings?.useSalesforce && option.value === 'salesforce') return false;
    if (option.value === 'monte_carlo') return false;
    return true;
  });

  const dataSourcesTemplate = (
    <>
      <Text fontSize="16px" fontWeight="medium" p={0.5} pb={0.5} pl={0}>
        {DWH_SELECTION_HEADER}
      </Text>
      <StyledDataSourceSelectionList>
        {filteredWarehouseOptions.map((option) => (
          <DataSourceSelectionOption
            key={option.value}
            isSelected={!!dsType?.includes(option.value)}
            onClick={() => setDsType(toggleDSType(option.value))}
            option={option}
          />
        ))}
        <StyledDataSourceSelectionOption
          isSelected={!!dsType?.includes('NA')}
          onClick={() => setDsType(toggleDSType('NA'))}
        >
          Other
        </StyledDataSourceSelectionOption>
      </StyledDataSourceSelectionList>
      <Text fontSize="16px" fontWeight="medium" mt={3} p={0.5} pb={1} pl={0}>
        {BI_SELECTION_HEADER}
      </Text>
      <StyledDataSourceSelectionList>
        {BITypeOptions.map((option) => (
          <DataSourceSelectionOption
            key={option.value}
            isSelected={!!dsType?.includes(option.value)}
            onClick={() => setDsType(toggleDSType(option.value))}
            option={option}
          />
        ))}
        <StyledDataSourceSelectionOption
          isSelected={!!dsType?.includes('BINA')}
          onClick={() => setDsType(toggleDSType('BINA'))}
        >
          Other
        </StyledDataSourceSelectionOption>
      </StyledDataSourceSelectionList>
      <Button
        compDisplay="flex"
        compWidth="100px"
        disabled={!isNextButtonDisabled}
        ml="auto"
        mt={3}
        onClick={handleOnSubmit}
      >
        Next
      </Button>
    </>
  );

  return (
    <Card m="auto" maxWidth="820px" p={5} position="relative">
      <AddDSHeader fontSize="1.5rem" fontWeight="medium" m={0} mb={2.4} pt={0.9} />
      {isOrgAdmin ? dataSourcesTemplate : <Alert type="warning">{NO_ACCESS_WARNING}</Alert>}
    </Card>
  );
};

export default DataSourceSelection;
