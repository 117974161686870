import styled from '@emotion/styled';
import * as CSS from 'csstype';
import { Input as SemanticInput, InputProps as SemanticInputProps } from 'semantic-ui-react';

interface StyledInputProps {
  compHeight?: CSS.Property.Height;
  compWidth?: CSS.Property.Width;
  flex?: boolean;
  flexGrow?: string;
}

const Input = styled(SemanticInput)<SemanticInputProps & StyledInputProps>`
  display: flex;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary} !important;
  flex-grow: ${({ flexGrow = '0' }) => flexGrow};

  &.ui.input {
    ${({ theme }) => theme.media.down('xs')`
      width: auto;
  `}
    ${({ compWidth = '100%', theme }) => theme.media.up('xs')`
      width: ${compWidth};
  `}
    height: ${({ compHeight = 'initial' }) => compHeight};
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary} !important;
    font-size: 0.875rem;

    > input {
      font-family: ${({ theme }) => theme.typography.fontFamilies.primary} !important;
      flex-grow: ${({ flexGrow = '1' }) => flexGrow};
    }

    &.error {
      > input {
        background-color: transparent;
        border-color: #d12771;
        color: #2c2e36;
        box-shadow: none;

        &::placeholder {
          color: #2c2e36;
        }
      }
    }
  }

  &.ui[class*='right labeled'].input > input + .label {
    border-left: 0 solid;
  }

  &.ui.label > a {
    opacity: 1 !important;
  }
`;

export default Input;
