import React from 'react';
import { Redirect } from 'react-router-dom';

import { useUserContext } from '@context/User';

const DefaultProfilePage: React.FC = () => {
  const { user } = useUserContext();
  return <>{user && <Redirect to={`/profiles/${user.guid}`} />}</>;
};

export default DefaultProfilePage;
