"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TypeMetadata = void 0;
class TypeMetadata {
    constructor(target, propertyName, reflectedType, typeFunction, options) {
        this.target = target;
        this.propertyName = propertyName;
        this.reflectedType = reflectedType;
        this.typeFunction = typeFunction;
        this.options = options;
    }
}
exports.TypeMetadata = TypeMetadata;
