import React, { useState } from 'react';
import { LOADING_TEXT } from '@constants';

import { useFetchQueries } from '@api/queries';
import QueryModal from '@components/Modal/QueryModal';
import Icon from '@components/UI/Icon';
import { QueryLogRecordModel } from '@models/QueryLogRecordModel';
import stripSpaces from '@utils/stripSpaces';

const query = stripSpaces(`{
  guid,
  name,
  database{
    data_source{
      type
    }
  },
  raw_sql,
  last_executed_on,
  created_by{
    guid,
    first_name,
    last_name,
    avatar
  },
  dsuser_created_by{
    name,
    display_name
  },
  main_user,
  main_dsuser,
  popularity
}`);

interface ErdColumnJoinQueryModalProps {
  dataSourceType?: string;
  sourceGuid?: string;
  targetGuid?: string;
}

const ErdColumnJoinQueryModal: React.FC<ErdColumnJoinQueryModalProps> = ({
  dataSourceType,
  sourceGuid = '',
  targetGuid = '',
}) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  const { data, isError, isLoading } = useFetchQueries({
    enabled: Boolean(sourceGuid) && Boolean(targetGuid) && isShow,
    params: {
      join_columns: [sourceGuid, targetGuid].join(','),
      order: 'created_on',
      page_size: 5,
      query,
    },
  });

  if (isError) {
    return null;
  }

  const sql = data?.results?.[0]?.rawSql;

  return (
    <>
      <button className="iconGroup" onClick={() => setIsShow(true)} type="button">
        <Icon name="code-block" size="16px" />
      </button>
      {isShow && (
        <QueryModal
          key={sql}
          codeString={isLoading ? LOADING_TEXT : sql}
          dataSourceType={dataSourceType}
          onClose={() => {
            setIsShow(false);
          }}
          query={data?.results?.[0]}
          queryLogRecords={data?.results?.map(
            (q): QueryLogRecordModel => ({
              executedOn: q.lastExecutedOn as any,
              formattedCreditsUsed: '',
              guid: q.guid,
              objectType: 'querylogrecord',
              query: q,
            }),
          )}
        />
      )}
    </>
  );
};

export default React.memo(ErdColumnJoinQueryModal);
