import styled from '@emotion/styled';
import type { LinkProps } from 'react-router-dom';

export const ItemStyled = styled.span<{ to?: LinkProps['to'] }>`
  color: unset;
  &:hover {
    color: unset;
  }
  display: inline-flex;
  &.block {
    display: flex;
  }
`;

export const ItemLinkText = styled.span`
  vertical-align: middle;
  word-break: break-all;
  overflow-wrap: anywhere;
`;
