import isUrl from 'is-url';
import { Editor, Node, Path, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { CustomElement } from '../RichTextEditor.types';

export const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'svg', 'png'];
export const MAX_IMAGE_SIZE = 2097152;
export const MAX_IMAGE_SIZE_AS_MB_STRING = `${Math.floor(MAX_IMAGE_SIZE / 1024 / 1024)}MB`;

export const insertImage = (editor: Editor, url: string) => {
  const text = { text: '' };
  const getImage = (appendParagraph = true) => {
    const image: CustomElement[] = [{ type: 'image', url, children: [text] }];
    if (appendParagraph) {
      image.push({ type: 'paragraph', children: [text] });
    }
    return image;
  };

  const { selection } = editor;

  ReactEditor.focus(editor);

  if (selection) {
    const [parentNode, parentPath] = Editor.parent(editor, selection?.focus?.path || []);
    if (editor.isVoid(parentNode as CustomElement)) {
      // Insert the new image node after the void node or a node with content
      Transforms.insertNodes(editor, getImage(), {
        at: Path.next(parentPath),
        select: true,
      });
    } else if (Node.string(parentNode).length && Range.isCollapsed(selection)) {
      // If current node has content, split it and insert the image in the middle
      Transforms.splitNodes(editor);
      Transforms.insertNodes(editor, getImage(false), {
        select: true,
      });
    } else {
      // If the node is empty, replace it instead
      Transforms.removeNodes(editor, { at: parentPath });
      Transforms.insertNodes(editor, getImage(), { at: parentPath, select: true });
    }
  } else {
    Transforms.insertNodes(editor, getImage(), { select: true });
  }
};

export const isImageUrl = (url: string) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split('.').pop();
  return ext ? IMAGE_EXTENSIONS.includes(ext) : false;
};

export const parseFilesToImages = (
  files: FileList,
  onFileSizeExceeded?: () => void,
  onInsertImage?: (url: string) => void,
) => {
  Array.from(files).forEach((file) => {
    if (file.size > MAX_IMAGE_SIZE) {
      onFileSizeExceeded?.();
      return;
    }
    const reader = new FileReader();
    const [mime] = file.type.split('/');

    if (mime === 'image') {
      reader.addEventListener('load', () => {
        const url = reader.result;
        if (url && typeof url === 'string') {
          onInsertImage?.(url);
        }
      });
      reader.readAsDataURL(file);
    }
  });
};
