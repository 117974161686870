import { useExplore } from '@components/Explore.v1/useExplore';

import { DEFAULT_GENERIC_OPERATION_LOADING_DELAY } from '../Explore.constants';

const useShowLoading = () => {
  const { setLoadingState } = useExplore();

  const setLoading = (enabled: boolean) => {
    setLoadingState({
      delay: DEFAULT_GENERIC_OPERATION_LOADING_DELAY,
      enabled,
      type: 'generic-operation',
    });
  };

  const withLoading = (fn: Function) => {
    return async (...args: any[]) => {
      setLoading(true);
      setTimeout(() => {
        fn(...args);
        setLoading(false);
      }, 0);
    };
  };

  return { withLoading };
};

export default useShowLoading;
