import React from 'react';
import { DataTypesConfig } from '@configs/dataTypes/types';
import noop from 'lodash/noop';

import type { TableModel } from '@api/tables/TableModel';
import Box from '@components/Box';
import DateTime from '@components/DateTime';
import LinksTo from '@components/SidebarMetadataObjectDetails/LinksTo';
import LoadingStatus from '@components/SidebarMetadataObjectDetails/LoadingStatus';
import { POPULARITY_DESCRIPTION } from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';
import titlelize from '@utils/titlelize';

import OwnerSelect from '../OwnerSelect';

import { StyledDetailsListItemValue } from './DetailsSection.styles';
import { ConfigObject, ConfigType } from './DetailsSection.types';
import DbtLinksTo from './fields/DbtLinksTo';
import OwnerItem from './fields/OwnerItem';
import PopularityItem from './fields/PopularityItem';
import TableSizeItem from './fields/TableSizeItem';

export const disabledDetailsV1ObjectTypes = ['documents', 'metrics', 'tags', 'terms'];

const ALL_FIELD_KEYS = [
  'businessOwner',
  'connection',
  'connectionMethod',
  'created',
  'createdBy',
  'dbtLinksTo',
  'updatedAt',
  'lastRefreshed',
  'lastRun',
  'linksTo',
  'loadingStatus',
  'location',
  'materialization',
  'popularity',
  'rowCount',
  'sourceFileType',
  'tableSize',
  'technicalOwner',
  'totalObjects',
  'type',
  'views',
  'language',
] as const;

type FieldKey = (typeof ALL_FIELD_KEYS)[number];

const FIELDS: Record<FieldKey, ConfigObject> = {
  businessOwner: {
    hidden: (item) => item.businessOwner === undefined,
    label: 'Business Owner',
    render: (item, options) => (
      <OwnerSelect
        hasEditPermissions={options.hasEditPermissions}
        items={[item]}
        itemsType={item.itemsType ?? 'tables'}
        owner={item.businessOwner?.obj}
        ownerUpdateType="business"
        reloadData={options.reloadData}
      />
    ),
  },
  connection: {
    hidden: (item) => !item.connections,
    label: 'Connection',
    render: (item) => (
      <Box gap={0.25}>
        {item.connections?.map((connection) => (
          <Box key={connection.name} alignItems="center" as="span" compDisplay="flex" gap={0.5}>
            {connection?.icon && <Icon name={connection?.icon} />}
            {connection?.name}
          </Box>
        ))}
      </Box>
    ),
  },
  connectionMethod: {
    hidden: (item) => 'formattedConnectionMethod' in item && !item?.formattedConnectionMethod,
    label: 'Type',
    render: (item) => (
      <StyledDetailsListItemValue>
        {'formattedConnectionMethod' in item && item.formattedConnectionMethod}
      </StyledDetailsListItemValue>
    ),
  },
  created: {
    hidden: (item) => !item.created && !item.createdAt,
    label: 'Created',
    render: (item) => <DateTime datetime={item.created ?? item.createdAt} />,
  },
  createdBy: {
    hidden: (item) => !item.owner && !(item as TableModel).dsuserCreatedBy,
    label: 'Created by',
    render: (item) => (
      <OwnerItem
        hasEditPermissions={false}
        onEdit={noop}
        owner={item.owner ?? (item as TableModel).dsuserCreatedBy}
      />
    ),
  },
  dbtLinksTo: {
    hidden: (item) => !item.guid,
    render: (item) => <DbtLinksTo guid={item.guid} />,
  },
  language: {
    hidden: (item) => !item.language,
    label: 'Language',
    render: (item) => item.language,
  },
  lastRefreshed: {
    hidden: (item) => !item.lastRefreshed,
    label: 'Last Refreshed',
    render: (item) => <DateTime datetime={item.lastRefreshed} />,
  },
  lastRun: {
    hidden: (item) => !item.lastRun,
    label: 'Last Run',
    render: (item) => <DateTime datetime={item.lastRun} />,
  },
  linksTo: {
    hidden: (item) => !item.guid || !item.showLinksTo,
    label: 'Links to',
    render: (item) => <LinksTo guid={item.guid} linksToObjects={item.linksToObjects} />,
  },
  loadingStatus: {
    hidden: (item) => !item.loadingStatus,
    label: 'Loading Status',
    render: (item) => (
      <LoadingStatus status={item.loadingStatus?.status} text={item.loadingStatus?.text} />
    ),
  },
  location: {
    hidden: (item) => !item?.cloudObject?.location,
    label: 'Location',
    render: (item) => (
      <StyledDetailsListItemValue>{item?.cloudObject?.location}</StyledDetailsListItemValue>
    ),
  },
  materialization: {
    hidden: (item) => !item.materialization,
    label: 'Materialization',
    render: (item) => item.materialization,
  },
  popularity: {
    hidden: () => false,
    info: (
      <Tooltip content={POPULARITY_DESCRIPTION}>
        <Icon color={theme.colors.gray[500]} name="info-outline" size="16px" />
      </Tooltip>
    ),
    label: 'Popularity',
    render: (item) => <PopularityItem dataType={item.dataType} popularity={item.popularity} />,
  },
  rowCount: {
    hidden: (item) => !item.rowCount,
    label: 'Row Count',
    render: (item) => <TableSizeItem rowCount={item?.rowCount} />,
  },
  sourceFileType: {
    hidden: (item) => !item?.cloudObject?.format,
    label: 'Source File Type',
    render: (item) => (
      <StyledDetailsListItemValue>
        {item?.cloudObject?.format.toUpperCase()}
      </StyledDetailsListItemValue>
    ),
  },
  tableSize: {
    hidden: (item) => !item.rowCount && !item.bytes,
    label: 'Table Size',
    render: (item) => <TableSizeItem bytes={item?.bytes} rowCount={item?.rowCount} />,
  },
  technicalOwner: {
    hidden: (item) => item.technicalOwner === undefined,
    label: 'Technical Owner',
    render: (item, options) => (
      <OwnerSelect
        hasEditPermissions={options.hasEditPermissions}
        items={[item]}
        itemsType={item.itemsType ?? 'tables'}
        owner={item.technicalOwner?.obj}
        ownerUpdateType="technical"
        reloadData={options.reloadData}
      />
    ),
  },
  totalObjects: {
    hidden: (item) => !item?.cloudObject?.objectsCount,
    label: 'Total Objects',
    render: (item) => (
      <StyledDetailsListItemValue>{item?.cloudObject?.objectsCount}</StyledDetailsListItemValue>
    ),
  },
  type: {
    hidden: (item) => !item?.dataTypes,
    label: 'Type',
    render: (item) => (
      <StyledDetailsListItemValue>
        {titlelize(item?.dataTypes?.dataType)}
      </StyledDetailsListItemValue>
    ),
  },
  updatedAt: {
    hidden: (item) => !item.updatedAt,
    label: 'Last Modified',
    render: (item) => <DateTime datetime={item.updatedAt} />,
  },
  views: {
    hidden: (item: any) => item?.viewCount,
    label: 'Views',
    render: (item: any) => (
      <StyledDetailsListItemValue>{item?.viewCount}</StyledDetailsListItemValue>
    ),
  },
};

const DASHBOARD_FIELDS = [
  FIELDS.createdBy,
  FIELDS.popularity,
  FIELDS.loadingStatus,
  FIELDS.created,
  FIELDS.lastRun,
  FIELDS.updatedAt,
];

const WAREHOUSE_TABLE_FIELDS = [
  FIELDS.createdBy,
  FIELDS.businessOwner,
  FIELDS.technicalOwner,
  FIELDS.materialization,
  FIELDS.popularity,
  FIELDS.tableSize,
  FIELDS.created,
  FIELDS.lastRefreshed,
  FIELDS.totalObjects,
  FIELDS.dbtLinksTo,
  FIELDS.sourceFileType,
  FIELDS.location,
];

const DATASET_FIELDS = [FIELDS.createdBy, FIELDS.popularity, FIELDS.created, FIELDS.updatedAt];

const LOADING_STATUS_LABEL = 'Loading Status';

const DETAIL_SECTION_FIELD_CONFIG_BY_OBJECT: ConfigType = {
  dashboard: DASHBOARD_FIELDS,
  default: [],
  explore: [FIELDS.businessOwner, FIELDS.technicalOwner, FIELDS.popularity],
  lookmlview: [FIELDS.businessOwner, FIELDS.technicalOwner, FIELDS.popularity, FIELDS.linksTo],
  table: WAREHOUSE_TABLE_FIELDS,
};

export const DETAIL_SECTION_FIELD_CONFIG: DataTypesConfig<Array<ConfigObject>> = {
  databricks: {
    default: [],
    notebook: { default: [FIELDS.language, FIELDS.created, FIELDS.updatedAt, FIELDS.lastRun] },
    table: { default: WAREHOUSE_TABLE_FIELDS },
  },
  dbt: {
    default: [],
    table: {
      default: [
        FIELDS.createdBy,
        FIELDS.businessOwner,
        FIELDS.technicalOwner,
        FIELDS.materialization,
        FIELDS.popularity,
        FIELDS.tableSize,
        FIELDS.created,
        FIELDS.lastRefreshed,
        FIELDS.totalObjects,
        FIELDS.linksTo,
        FIELDS.sourceFileType,
        FIELDS.location,
      ],
    },
  },
  power_bi: {
    dashboard: { default: DASHBOARD_FIELDS },
    default: [],
    powerbidataset: { default: DATASET_FIELDS },
  },
  quicksight: {
    bidataset: { default: DATASET_FIELDS },
    dashboard: {
      default: DASHBOARD_FIELDS.filter((item) => item.label !== LOADING_STATUS_LABEL),
    },
    default: [],
  },
  tableau: {
    dashboard: {
      default: [
        FIELDS.createdBy,
        FIELDS.popularity,
        FIELDS.loadingStatus,
        FIELDS.created,
        FIELDS.lastRun,
        FIELDS.updatedAt,
      ],
    },
    default: [],
    tableaudatasource: {
      default: [FIELDS.connectionMethod, FIELDS.connection, FIELDS.popularity, FIELDS.updatedAt],
    },
  },
  thoughtspot: {
    dashboard: {
      default: [FIELDS.createdBy, FIELDS.created, FIELDS.updatedAt, FIELDS.views, FIELDS.type],
    },
    default: [],
    thoughtspottable: {
      default: [FIELDS.createdBy, FIELDS.created, FIELDS.updatedAt, FIELDS.type],
    },
  },
};

export default DETAIL_SECTION_FIELD_CONFIG_BY_OBJECT;
