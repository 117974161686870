import { isWarehouseType } from '@configs/dataSources/getType';

import {
  CustomAttributeDataSourceType,
  CustomAttributeDataType,
  CustomAttributeObjectType,
} from '@api/customAttributes/CustomAttributeModel';

export interface CustomAttributesAssetConfig {
  dataType?: CustomAttributeDataType;
  label: string;
  objectType: CustomAttributeObjectType;
}

export const CUSTOM_ATTRIBUTES_DWH_ASSETS: CustomAttributesAssetConfig[] = [
  { label: 'Table', objectType: 'table' },
  { label: 'Column', objectType: 'column' },
];

const customAttributesAssetsConfig: PartialRecord<
  CustomAttributeDataSourceType,
  CustomAttributesAssetConfig[]
> = {
  data_studio: [
    { dataType: 'page', label: 'Page', objectType: 'reportquery' },
    { label: 'Report', objectType: 'dashboard' },
  ],
  databricks: [
    { label: 'Table', objectType: 'table' },
    { label: 'Column', objectType: 'column' },
    { label: 'Notebook', objectType: 'notebook' },
  ],
  dbt: CUSTOM_ATTRIBUTES_DWH_ASSETS,
  looker: [
    { label: 'Explore', objectType: 'explore' },
    { label: 'View', objectType: 'lookmlview' },
    { label: 'Explore Field', objectType: 'explorefield' },
    { label: 'View Field', objectType: 'lookmlfield' },
    { label: 'Dashboard', objectType: 'dashboard' },
    { label: 'Tile', objectType: 'dashboardelement' },
  ],
  metabase: [
    { label: 'Dashboard', objectType: 'dashboard' },
    { dataType: 'card', label: 'Card', objectType: 'reportquery' },
  ],
  mode: [
    { label: 'Dashboard', objectType: 'dashboard' },
    { label: 'Query', objectType: 'dashboardelement' },
  ],
  periscope: [
    { label: 'Charts', objectType: 'dashboard' },
    { label: 'Element', objectType: 'dashboardelement' },
  ],
  power_bi: [
    { dataType: 'dashboard', label: 'Dashboard', objectType: 'dashboard' },
    { dataType: 'tile', label: 'Tile', objectType: 'reportquery' },
    { dataType: 'report', label: 'Report', objectType: 'dashboard' },
    { dataType: 'page', label: 'Page', objectType: 'reportquery' },
  ],
  quicksight: [
    { dataType: 'dashboard', label: 'Analysis', objectType: 'dashboard' },
    { dataType: 'report', label: 'Dashboard', objectType: 'dashboard' },
    { label: 'Dataset', objectType: 'bidataset' },
  ],
  sigma: [
    { label: 'Workbook', objectType: 'dashboard' },
    { dataType: 'element', label: 'Element', objectType: 'reportquery' },
  ],
  tableau: [
    { label: 'Data Source', objectType: 'tableaudatasource' },
    { label: 'View', objectType: 'tableauview' },
    { label: 'Field', objectType: 'tableaufield' },
    { label: 'Workbook', objectType: 'dashboard' },
  ],
  thoughtspot: [
    {
      dataType: 'report',
      label: 'Answer',
      objectType: 'dashboard',
    },
    {
      dataType: 'dashboard',
      label: 'Liveboard',
      objectType: 'dashboard',
    },
    {
      dataType: 'worksheet',
      label: 'Worksheet',
      objectType: 'thoughtspottable',
    },
    {
      dataType: 'one_to_one_logical',
      label: 'Table',
      objectType: 'thoughtspottable',
    },
    {
      dataType: 'sql_view',
      label: 'View',
      objectType: 'thoughtspottable',
    },
  ],
};

export const getCustomAttributesAssetsConfig = (dataSource: CustomAttributeDataSourceType) => {
  if (dataSource in customAttributesAssetsConfig) return customAttributesAssetsConfig[dataSource];
  if (isWarehouseType(dataSource)) return CUSTOM_ATTRIBUTES_DWH_ASSETS;
  return undefined;
};

export default customAttributesAssetsConfig;
