import React from 'react';
import { Position } from 'reactflow';

import { getColorsBasedOnDataTypes } from '@components/Explore.v1/components/Nodes/Nodes.utils';
import { ExploreNode } from '@components/Explore.v1/Explore.types';
import useShowMoreNodes from '@components/Explore.v1/useShowMoreNodes';

import EdgeHandle from '../../EdgeHandle';

import { StyledShowMoreNode, StyledShowMoreNodeCount } from './ShowMoreNode.styles';

const ShowMoreNode: React.FC<ExploreNode> = ({ data }) => {
  const { dataTypes, hiddenChildrenCount = 0, isUnfocused, key, stackedAt } = data;
  const colors = getColorsBasedOnDataTypes(dataTypes);
  const { updateNodesOnShowMoreTables } = useShowMoreNodes();

  const handleShowMoreButtonClick = () => {
    if (key) {
      updateNodesOnShowMoreTables(key, Number(stackedAt));
    }
  };

  return (
    <StyledShowMoreNode
      borderColor={colors.primary}
      color={colors.primary}
      isUnfocused={isUnfocused}
      onClick={handleShowMoreButtonClick}
    >
      <EdgeHandle height="showMoreButton" id="left" position={Position.Left} type="source" />
      <EdgeHandle height="showMoreButton" id="left" position={Position.Left} type="target" />
      Show more
      <StyledShowMoreNodeCount>{hiddenChildrenCount?.toLocaleString()}</StyledShowMoreNodeCount>
      <EdgeHandle height="showMoreButton" id="right" position={Position.Right} type="source" />
      <EdgeHandle height="showMoreButton" id="right" position={Position.Right} type="target" />
    </StyledShowMoreNode>
  );
};

export default ShowMoreNode;
