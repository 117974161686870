const formatNumber = (
  value?: number | string,
  options: { maximumFractionDigits?: number; notation?: 'compact' } = { maximumFractionDigits: 2 },
  locale?: string,
) => {
  const v = Number(value);
  if (Number.isNaN(v)) {
    return '0';
  }

  if (!locale) {
    return v.toLocaleString(undefined, options);
  }

  return Intl.NumberFormat(locale, options).format(v);
};

export default formatNumber;
