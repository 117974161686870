import React from 'react';
import type { MetadataObjectType } from '@atoms';
import moment from 'moment';

import type { LookerExploreModel } from '@api/explores/LookerExploreModel';
import type CloudObjectModel from '@api/tables/CloudObjectModel';
import Box from '@components/Box';
import DateTime from '@components/DateTime';
import { disabledDetailsV1ObjectTypes } from '@components/DetailsSection/config';
import { Column } from '@components/Grid';
import MentionedBy from '@components/MentionedBy';
import type { MentionedByProps } from '@components/MentionedBy/MentionedBy';
import type {
  ObjWithOwners,
  UsePatchUpdateOwnersProps,
} from '@components/Modal/BulkEditOwnersModal/BulkEditOwnersModal.types';
import type { OwnerType } from '@components/Owners/OwnerItem';
import OwnerSelect from '@components/OwnerSelect';
import PageHistory from '@components/PageHistory';
import Popularity, { NO_POPULARITY_OBJECTS } from '@components/Popularity';
import LinkedFrom from '@components/SidebarMetadataObjectDetails/LinkedFrom';
import Text from '@components/Text';
import type { IconProps } from '@components/UI/Icon';
import Icon from '@components/UI/Icon';
import type DataTypesModel from '@models/DataTypesModel';
import type { PopularityModel } from '@models/PopularityModel';
import theme from '@styles/theme';
import formatByte from '@utils/formatByte';
import wrapString from '@utils/wrapString';

import LinksTo from './LinksTo';
import type { LoadingStatusProps } from './LoadingStatus';
import LoadingStatus from './LoadingStatus';
import { StyledSidebarMetadataObjectDetails } from './SidebarMetadataObjectDetails.styles';
import SidebarMetadataObjectDetailsItem from './SidebarMetadataObjectDetailsItem';

interface SidebarMetadataObjectDetailsData {
  cloudObject?: CloudObjectModel;
  dataTypes?: DataTypesModel;
  linksToObjects?: LookerExploreModel[];
  objectTypeV1?: MetadataObjectType;
  popularity?: PopularityModel;
}

export interface SidebarMetadataObjectDetailsProps {
  businessOwner?: OwnerType | null;
  bytes?: number;
  connections?: { icon?: IconProps['name']; name: string }[];
  created?: moment.Moment;
  data?: SidebarMetadataObjectDetailsData;
  editOwnersItems?: ObjWithOwners[];
  guid: string;
  isEditable: boolean;
  isLoading?: boolean;
  itemsType?: UsePatchUpdateOwnersProps['itemsType'];
  lastRefreshed?: moment.Moment | null;
  lastRun?: moment.Moment | null;
  lastUpdated?: moment.Moment | null;
  loadingStatus?: LoadingStatusProps;
  materialization?: string;
  mentionedByProps?: MentionedByProps;
  objectType?: string;
  onEdit?: () => void;
  owner?: OwnerType;
  popularity?: PopularityModel;
  popularityDescription?: string;
  reloadData?: () => void;
  rowCount?: number;
  showLinkedFrom?: boolean;
  showLinksTo?: boolean;
  showMentionedBy?: boolean;
  technicalOwner?: OwnerType | null;
}

const SidebarMetadataObjectDetails: React.FC<SidebarMetadataObjectDetailsProps> = ({
  businessOwner,
  bytes,
  children,
  connections,
  created,
  data,
  editOwnersItems,
  guid,
  isEditable,
  itemsType = 'tables',
  lastRefreshed,
  lastRun,
  lastUpdated,
  loadingStatus,
  materialization,
  mentionedByProps = {},
  objectType,
  owner,
  popularity,
  popularityDescription,
  reloadData = () => setTimeout(() => document.location.reload(), 500),
  rowCount,
  showLinkedFrom,
  showLinksTo,
  showMentionedBy,
  technicalOwner,
}) => {
  const detailsHeading = (
    <Text
      as="h3"
      color="gray.700"
      fontSize={theme.typography.h4.fontSize}
      fontWeight="medium"
      mb={0}
    >
      Details
    </Text>
  );

  const itemsMap = [
    {
      children: (
        <OwnerSelect
          hasEditPermissions={Boolean(isEditable && editOwnersItems && itemsType === 'tags')}
          items={[data] as ObjWithOwners[]}
          itemsType={itemsType}
          owner={owner}
          ownerUpdateType="tag"
          reloadData={reloadData}
          showNoOwnerOption={false}
        />
      ),
      hide: data?.objectTypeV1 !== 'tags',
      label: 'Owned by',
    },
    {
      children: (
        <OwnerSelect
          hasEditPermissions={Boolean(isEditable && editOwnersItems)}
          items={editOwnersItems as ObjWithOwners[]}
          itemsType={itemsType}
          owner={businessOwner}
          ownerUpdateType="business"
          reloadData={reloadData}
        />
      ),
      hide: businessOwner === undefined,
      label: 'Business Owner',
    },
    {
      children: (
        <OwnerSelect
          hasEditPermissions={Boolean(isEditable && editOwnersItems)}
          items={editOwnersItems as ObjWithOwners[]}
          itemsType={itemsType}
          owner={technicalOwner}
          ownerUpdateType="technical"
          reloadData={reloadData}
        />
      ),
      hide: technicalOwner === undefined,
      label: 'Technical Owner',
    },
    {
      children: (
        <Box vSpacing={0.25}>
          {connections?.map((connection) => (
            <Box key={connection.name} alignItems="center" as="span" compDisplay="flex" spacing={1}>
              {connection?.icon && <Icon mr={0.5} name={connection?.icon} />}
              {connection?.name}
            </Box>
          ))}
        </Box>
      ),
      hide: !connections,
      label: 'Connections',
    },
    {
      children: (
        <Popularity
          priority={popularity?.formattedPopularity}
          text={popularityDescription}
          topUsersLink
        />
      ),
      hide: NO_POPULARITY_OBJECTS.includes(data?.dataTypes?.objectType!),
      label: 'Popularity',
    },
    {
      children: <LoadingStatus status={loadingStatus?.status} text={loadingStatus?.text} />,
      hide: loadingStatus === undefined,
      label: 'Loading Status',
    },
    {
      children: materialization,
      hide: !materialization!,
      label: 'Materialization',
    },
    {
      children: <DateTime datetime={created} />,
      hide: !created,
      label: 'Created',
    },
    {
      children: <DateTime datetime={lastUpdated} />,
      hide: !lastUpdated,
      label: 'Last Updated',
    },
    {
      children: <DateTime datetime={lastRefreshed} />,
      hide: !lastRefreshed,
      label: 'Last Refreshed',
    },
    {
      children: <DateTime datetime={lastRun} />,
      hide: !lastRun,
      label: 'Last Run',
    },
    {
      children: <>{wrapString(rowCount?.toLocaleString(), '', ` rows | ${formatByte(bytes)}`)}</>,
      hide: !rowCount && !bytes,
      label: 'Table Size',
    },
    {
      children: (
        <Text color="gray.900" fontSize={theme.typography.fontSizes.body2}>
          {data?.cloudObject?.objectsCount}
        </Text>
      ),
      hide: !data?.cloudObject?.objectsCount,
      label: 'Total Objects',
    },
    {
      children: <LinksTo guid={guid} linksToObjects={data?.linksToObjects} />,
      hide: !guid || !showLinksTo,
      label: 'Links to',
    },
    {
      children: <LinkedFrom guid={guid} />,
      hide: !guid || !showLinkedFrom,
      label: 'Linked from',
    },
    {
      children: (
        <Text color="gray.900" fontSize={theme.typography.fontSizes.body2}>
          {data?.cloudObject?.format.toUpperCase()}
        </Text>
      ),
      hide: !data?.cloudObject?.format,
      label: 'Source File Type',
    },
    {
      children: (
        <Text color="gray.900" fontSize={theme.typography.fontSizes.body2}>
          {data?.cloudObject?.location}
        </Text>
      ),
      hide: !data?.cloudObject?.location,
      label: 'Location',
    },
  ];

  return (
    <StyledSidebarMetadataObjectDetails alignItems="flex-start" flexWrap="wrap" space={1}>
      {disabledDetailsV1ObjectTypes.includes(data?.objectTypeV1 ?? itemsType) && (
        <Column xs={12}>
          <Box compDisplay="flex" flexDirection="column" gap={1}>
            {detailsHeading}
            {itemsMap.map((item) => {
              return item.hide ? null : (
                <SidebarMetadataObjectDetailsItem key={item.label} label={item.label}>
                  {item.children}
                </SidebarMetadataObjectDetailsItem>
              );
            })}
            {children}
          </Box>
        </Column>
      )}
      {guid && showMentionedBy && (
        <Column xs={12}>
          <MentionedBy targetId={guid} {...mentionedByProps} />
        </Column>
      )}
      {guid && (
        <Column xs={12}>
          <PageHistory id={guid} objectType={objectType} />
        </Column>
      )}
    </StyledSidebarMetadataObjectDetails>
  );
};

export default SidebarMetadataObjectDetails;
