import { Expose, Type } from 'class-transformer';

import type { NodeExtraMeta } from '@components/ExploreTree/types';
import type { DataSourceTypesType } from '@models/DataSourceCredentials';
import DataTypesModel from '@models/DataTypesModel';
import { PopularityModel } from '@models/PopularityModel';
import { makeUrl, urlFor } from '@utils/routing';

import LineageEdge from './LineageEdge';

class ColumnLineageModel {
  key!: string;

  guid!: string;

  name!: string;

  description?: string;

  @Expose({ name: 'table_guid' })
  tableGuid!: string;

  rank?: number;

  ordinal?: number;

  @Expose({ name: 'full_name' })
  fullName?: string;

  @Expose({ name: 'is_hidden' })
  isHidden!: boolean;

  @Expose({ name: 'object_type' })
  objectType!: string;

  @Expose({ name: 'data_type' })
  dataType!: string;

  @Expose({ name: 'source_table_guids' })
  sourceTableGuids!: string[];

  @Expose({ name: 'data_source_type' })
  dataSourceType!: DataSourceTypesType;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel;

  @Type(() => PopularityModel)
  popularity?: PopularityModel;

  @Expose({ name: 'tag_guids' })
  tagGuids?: string[];

  @Expose({ name: 'is_primary_key' })
  isPrimaryKey?: boolean;

  @Expose({ name: 'is_foreign_key' })
  isForeignKey?: boolean;

  @Expose({ name: 'is_nested' })
  isNested!: boolean;

  @Expose({ name: 'source_edges' })
  sourceEdges!: Record<string, LineageEdge>;

  @Expose({ name: 'target_edges' })
  targetEdges!: Record<string, LineageEdge>;

  /**
   * The dbt/warehouse columns associated with this warehouse/dbt column respectively
   * If this column is in a DBT Data Source, then this is a list of associated warehouse column
   * If this column is not in a DBT Data Source, then this is a list of associated dbt column
   *
   * Usually is undefined or has 1 elements, never has 0 elements
   */
  @Expose({ name: 'linked_objs' })
  linkedObjs?: string[];

  /**
   * @todo Architecture.
   * Object might have the FE page link and the best place to define it is a model.
   */
  get routePath() {
    /**
     * Sigma and Mode has the same objectType which cause troubles
     * with defining a proper router for that bi column object page.
     */
    if (this.dataSourceType && this.dataSourceType === 'sigma' && this.dataType === 'reportquery') {
      return makeUrl('reportquerysigma', this.guid, this.tableGuid);
    }

    return urlFor({ guid: this.guid, objectType: this.objectType, parentGuid: this.tableGuid });
  }

  runtimeMeta: NodeExtraMeta = {};
}

export default ColumnLineageModel;
