import { createContext, useContext } from 'react';

import type { Option } from '@components/UI/Select.v1/types';
import { HierarchyData } from '@models/HierarchyModel';

export interface TagsSelectOptions {
  category?: Option;
  status?: Option;
}

export interface TagsHierarchyContextProps {
  tagsHierarchy?: HierarchyData[];
  tagsHierarchyIsLoading?: boolean;
  tagsSelectOptions?: TagsSelectOptions;
}

export const TagsHierarchyContext = createContext<TagsHierarchyContextProps>(undefined as never);

export const useTagsHierarchy = () => {
  const tagsHierarchyContext = useContext(TagsHierarchyContext);

  if (tagsHierarchyContext === undefined) {
    throw new Error('useTagsHierarchy must be used within <TagsHierarchyProvider>.');
  }

  return tagsHierarchyContext;
};

export default useTagsHierarchy;
