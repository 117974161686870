import React from 'react';

import { useFetchSSOSlackToken } from '@api/sso';
import TextScreen from '@components/TextScreen';
import TwoColumnDecorativeComponent from '@components/TwoColumnDecorativeComponent';
import TwoColumnLayout from '@components/TwoColumnLayout';
import MetadataDecorator from '@utils/MetadataDecorator';

function buildRedirectURL(redirectURL: string, ssoToken: string) {
  if (redirectURL.indexOf('?') === -1) {
    return `${redirectURL}?ssoToken=${ssoToken}`;
  }
  return `${redirectURL}&ssoToken=${ssoToken}`;
}

function openUrl(url: string) {
  window.location.href = url;
}

const SlackAuthorizePage: React.FC = () => {
  const { error } = useFetchSSOSlackToken({
    onSuccess: (d) => {
      if (d?.token) {
        openUrl(buildRedirectURL(d.redirectUrl, d.token));
      }
    },
    retry: false,
  });

  let leftComponent = (
    <TextScreen text="Redirecting to the slack app..." title="Select Star Slack App" />
  );

  if (error) {
    leftComponent = (
      <TextScreen
        buttonText="Open Slack App"
        onClick={() => openUrl(window.env?.SLACK_REDIRECT_URL)}
        text="Couldn't redirect you to slack app automatically."
        title="Redirect Error"
      />
    );
  }

  return (
    <>
      <MetadataDecorator title="Slack Authorize" />
      <TwoColumnLayout left={leftComponent} right={<TwoColumnDecorativeComponent />} />
    </>
  );
};

export default SlackAuthorizePage;
