import React from 'react';

import CircularLoader from '@components/CircularLoader';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

import { colors, StyledButton } from './ExpandCollapseButton.styles';

export type ExpandAllState = 'collapsed' | 'expanded';
interface ExpandCollapseButtonProps {
  direction: 'right' | 'left';
  isLoading?: boolean;
  onClick?: () => void;
  state?: ExpandAllState;
}

const ExpandCollapseButton: React.FC<ExpandCollapseButtonProps> = ({
  direction,
  isLoading,
  onClick,
  state = 'collapsed',
}) => {
  const isUpstreamDirection = direction === 'left';
  const isExpanded = state === 'expanded';

  return (
    <StyledButton
      backgroundColor={colors[state].default.background}
      borderColor={colors[state].default.border}
      borderRadius="10px"
      color={colors[state].default.color}
      compSize="xxs"
      onClick={() => {
        if (!isLoading) {
          onClick?.();
        }
      }}
      spacing={0.5}
      startIcon={
        isLoading ? (
          <CircularLoader
            bgColor={theme.colors.v1.primary[100]}
            borderWidth={1}
            color={theme.colors.v1.primary[500]}
            compSize={1.5}
          />
        ) : (
          <Icon
            color={colors[state].default.icon}
            name={isExpanded ? 'chevron-up-double' : 'chevron-down-double'}
            size="16px"
          />
        )
      }
      state={state}
      variant="outlined"
    >
      {isExpanded ? 'Close' : 'Open'} all {isUpstreamDirection ? 'upstream' : 'downstream'}
    </StyledButton>
  );
};

export default ExpandCollapseButton;
