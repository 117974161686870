import { useCallback, useState } from 'react';

export interface Step<T> {
  hidden?: boolean;
  key: T;
  name: string;
  title?: string;
}
export interface UseStepProps {
  startIndex?: number;
  steps: Step<string>[];
}

const useStep = ({ startIndex = 0, steps }: UseStepProps) => {
  const [step, setStep] = useState(steps[startIndex]);

  const nextStep = useCallback(() => {
    const idx = steps.indexOf(step);
    if (idx + 1 < steps.length) {
      setStep(steps[idx + 1]);
    }
  }, [step, steps]);

  const prevStep = useCallback(() => {
    const idx = steps.indexOf(step);
    if (idx - 1 >= 0) {
      setStep(steps[idx - 1]);
    }
  }, [step, steps]);

  return { currStep: step, nextStep, prevStep };
};

export default useStep;
