import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { DashboardData, DashboardModel } from '@api/dashboards/DashboardModel';
import type { DescriptionSource } from '@api/description/description.types';
import { DSUserData } from '@api/dsusers/DsUserModel';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import isEmptyRichText from '@components/RichTextEditor/helpers/isEmptyRichText';
import type { DataSourceTypesType } from '@models/DataSourceCredentials';
import DataTypesModel from '@models/DataTypesModel';
import { PopularityData, PopularityModel } from '@models/PopularityModel';
import { urlFor } from '@utils/routing';

export interface ReportQueryData {
  dashboard: DashboardData;
  dsuserCreatedBy: DSUserData;
  externalId: string;
  guid: string;
  name?: string;
  popularity?: PopularityData;
  queryCreatedAt?: moment.Moment;
  queryUpdatedAt?: moment.Moment;
  rawSql?: string;
}

export class ReportQueryModel {
  static objectType: 'reportquery' = 'reportquery';

  @Expose({ name: 'object_type' })
  objectType: 'reportquery' = ReportQueryModel.objectType;

  static typeDisplay: string = 'ReportQuery';

  typeDisplay: string = ReportQueryModel.typeDisplay;

  guid!: string;

  name!: string;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  @Expose({ name: 'data_type' })
  dataType?: string;

  @Expose({ name: 'external_id' })
  externalId!: string;

  @Type(() => DashboardModel)
  dashboard?: DashboardModel;

  @Type(() => PopularityModel)
  popularity?: PopularityModel;

  @Expose({ name: 'raw_sql' })
  rawSql?: string;

  @Expose({ name: 'ai_description' })
  aiDescription?: string;

  description?: string;

  @Expose({ name: 'description_source' })
  descriptionSource?: DescriptionSource;

  @Expose({ name: 'ingested_description' })
  ingestedDescription?: string;

  @Expose({ name: 'richtext_description' })
  @Transform((richtextDescription) =>
    isEmptyRichText(richtextDescription) ? '' : richtextDescription,
  )
  richtextDescription?: string;

  @Expose({ name: 'user_description' })
  userDescription?: string;

  showDescriptionSelector = true;

  @Expose({ name: 'query_created_at' })
  @Transform((value) => moment(value))
  createdAt!: moment.Moment;

  @Expose({ name: 'query_updated_at' })
  @Transform((value) => moment(value))
  updatedAt!: moment.Moment;

  @Expose({ name: 'external_type' })
  externalType!: string;

  @Expose({ name: 'tagged_items' })
  @Type(() => TaggedItemModel)
  taggedItems?: TaggedItemModel[];

  @Expose({ name: 'search_name' })
  fullName?: string;

  @Expose({ name: 'page_name' })
  pageName?: string;

  @Expose({ name: 'data_source_type' })
  dataSourceType?: DataSourceTypesType;

  @Expose({ name: 'parent_guid' })
  @Transform((value, obj) => (obj.dashboard ? obj.dashboard?.guid : value))
  parentGuid?: string;

  /**
   * @todo Architecture.
   * Object might have the FE page link and the best place to define it is a model.
   */
  get routePath() {
    return urlFor({
      /**
       * We do that exception because most of the report queries objectType is reportQuery,
       * which doesn't allow us to select a proper route. Using merged objectType and dataType
       * for dataType we can more precisely define what route needs to be used.
       * The second line (objectType) is used as fallback.
       */
      dataType: this.objectType + this.dataType,
      dataTypes: this.dataTypes,
      guid: this.guid,
      objectType: this.objectType,
      parentGuid: this.parentGuid,
    });
  }

  @Expose({ name: 'deactivation_scheduled_on' })
  @Transform((value) => (value ? moment(value) : value))
  deactivationScheduledOn?: moment.Moment;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }
}
