/* eslint-disable sort-keys-fix/sort-keys-fix */
import { plainToClass } from 'class-transformer';

import DataTypesModel from '@models/DataTypesModel';

import type { PageViewProps } from '../constants';

export const DATA_TYPE_OPTION_CONFIG = {
  content: {
    key: '',
    text: 'Content',
    value: 'content',
  },
  analysis: {
    key: 'report',
    text: 'Analyses',
    value: 'analysis',
  },
  dashboard: {
    key: 'dashboard',
    text: 'Dashboards',
    value: 'dashboard',
  },
  bidataset: {
    key: '',
    text: 'Datasets',
    value: 'bidataset',
  },
} as const;

export type DataTypeOptionConfig = Mutable<typeof DATA_TYPE_OPTION_CONFIG>;
export type DataTypeOption = DataTypeOptionConfig[keyof DataTypeOptionConfig];
export type DataTypeOptions = DataTypeOption[];

export const DATA_TYPE_OPTIONS = Object.values(DATA_TYPE_OPTION_CONFIG);

export interface QuicksightPageViewProps extends PageViewProps<DataTypeOption> {}

export const CONTAINER_CONFIG = {
  dataTypes: plainToClass(DataTypesModel, {
    data_source_type: 'quicksight',
    object_type: 'quicksight_folders_container',
  }),
  title: 'Folders',
};
