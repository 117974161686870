import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BaseRange, Descendant, Editor, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { useDebounce, useDebouncedCallback } from 'use-debounce';

import { defaultMentionFilters } from '@api/search/indexes';
import { SearchModel } from '@api/search/SearchModel';
import type { QuickSearchIndexFilter, SearchResult } from '@api/search/types';
import type { MentionType } from '@components/Mention/Mention.types';
import { DatasourceTabV1, tabConfigDefault } from '@components/SearchBar/DatasourceTabs/config';
import type { SearchFiltersType } from '@components/SearchBar/SearchTypes';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import useWindowSize from '@hooks/useWindowSize';

import { getCurrentWord } from '../helpers/getCurrentWord';

import insertMention from './insertMention';
import MentionSuggestionsPopup from './MentionSuggestionsPopup';

const useMentions = ({
  defaultFilter = defaultMentionFilters,
  editor,
  onBeforeResults,
  onSearch,
  searchResults,
  searchTabs = tabConfigDefault,
}: {
  defaultFilter?: QuickSearchIndexFilter[] | Record<string, boolean>;
  editor: Editor;
  onBeforeResults?: (results: MentionType[]) => MentionType[];
  onSearch: (searchTerm: string, filters?: QuickSearchIndexFilter[]) => void;
  searchResults?: SearchResult<SearchModel>;
  searchTabs?: DatasourceTabV1[];
}) => {
  const { windowHeight, windowWidth } = useWindowSize();
  const [target, setTarget] = useState<BaseRange | undefined>();
  const [index, setIndex] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [textDebounced] = useDebounce(searchText, 500);
  const [suggestionResults, setSuggestionResults] = useState<SearchResult<SearchModel>>();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [position, setPosition] = useState<{
    left: string;
    top: string;
  } | null>(null);
  const segment = useSegmentContext();
  const [facets, setFacets] = useState<SearchResult<SearchModel>['facets']>();
  const shouldUpdateFacets = useRef(true);

  const debouncedSearch = useDebouncedCallback(
    (term: string, filters?: SearchFiltersType | DatasourceTabV1['filters']) => {
      onSearch(term, (filters ?? defaultFilter) as QuickSearchIndexFilter[]);
    },
    500,
  );

  useEffect(() => {
    shouldUpdateFacets.current = true;
    if (textDebounced) {
      debouncedSearch(textDebounced);
    }
  }, [textDebounced, debouncedSearch]);

  useEffect(() => {
    if (searchResults) {
      if (shouldUpdateFacets.current && searchResults.facets) {
        setFacets(searchResults.facets);
      }
      if (onBeforeResults) {
        const resultsData = [...searchResults.data] as MentionType[];
        onBeforeResults(resultsData);
        const result = { ...searchResults, data: resultsData } as SearchResult<SearchModel>;
        setSuggestionResults(result);
      } else {
        setSuggestionResults(searchResults);
      }
    }
  }, [searchResults, onBeforeResults, facets]);

  useEffect(() => {
    if (target) {
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();

      const top = `${rect.top}px`;
      const left = `${rect.left}px`;
      setPosition({ left, top });
    }
  }, [suggestionResults?.data.length, editor, target, windowHeight, windowWidth]);

  const handleSelectMention = (suggestionIndex: number) => {
    if (target && suggestionResults && suggestionResults.data.length > 0) {
      ReactEditor.focus(editor);
      Transforms.select(editor, target);

      const { guid, indexPosition, name } = suggestionResults.data[suggestionIndex] ?? {};
      insertMention(editor, {
        guid,
        name,
      });

      segment?.track(SegmentTrackEventName.QuickSearchResultClicked, {
        guid,
        rank: indexPosition,
      });

      setTarget(undefined);
      shouldUpdateFacets.current = true;
    }
  };

  const onKeyDownWithMentions = useCallback(
    (event) => {
      if (target && suggestionResults && suggestionResults.data.length > 0) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const prevIndex = index >= suggestionResults.data.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          case 'ArrowUp':
            event.preventDefault();
            const nextIndex = index <= 0 ? suggestionResults.data.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          case 'Enter':
            event.preventDefault();
            event.stopPropagation();
            handleSelectMention(index);
            break;
          case 'Escape':
            event.preventDefault();
            setTarget(undefined);
            break;
          default:
            break;
        }
      }
      return event;
    },
    [index, onSearch, suggestionResults, target, handleSelectMention],
  );

  const onChangeWithMentions = (
    editorValue: Descendant[],
    onChangeHandler: (state: Descendant[]) => void,
  ) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const beforeRange = getCurrentWord(editor, selection, {
        directions: 'left',
        include: true,
        terminator: ['@', '\n'],
      });
      const beforeText = beforeRange && Editor.string(editor, beforeRange);
      const beforeMatch = beforeText && beforeText.match(/^@/);

      if (beforeMatch) {
        setTarget(beforeRange);
        setSearchText(beforeText.slice(1));
        setIndex(0);
        return;
      }
    }

    setTarget(undefined);
    onChangeHandler(editorValue);
  };

  const handleClickOutside = () => {
    setTarget(undefined);
    shouldUpdateFacets.current = true;
    setSearchText('');
    setSuggestionResults(undefined);
  };

  const handleFilterChange = (newFilter: SearchFiltersType | DatasourceTabV1['filters']) => {
    shouldUpdateFacets.current = false;
    debouncedSearch(textDebounced, newFilter);
  };

  useEffect(() => {
    if (target && position && suggestionResults?.data.length !== 0) {
      setShowSuggestions(true);
    } else if (showSuggestions) {
      setShowSuggestions(false);
    }
  }, [target, position, suggestionResults?.data.length, setShowSuggestions, showSuggestions]);

  const mentionSuggestionElement = showSuggestions ? (
    <MentionSuggestionsPopup
      index={index}
      isOpen={showSuggestions}
      mentionSearchFacets={facets}
      mentionSearchResult={searchText.length > 1 ? suggestionResults : undefined}
      onClickOutside={handleClickOutside}
      onMentionSelect={handleSelectMention}
      position={position}
      searchTabs={searchTabs}
      searchValue={searchText}
      setFilters={handleFilterChange}
    />
  ) : null;

  return {
    mentionSuggestionElement,
    onChangeWithMentions,
    onKeyDownWithMentions,
  };
};

export default useMentions;
