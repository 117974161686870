import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Cell } from 'react-table';

import { DashboardElementModel } from '@api/dashboards/DashboardElementModel';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import EditableTaggedItemCell from '@components/Table/Cells/EditableTaggedItemCell';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { useUserContext } from '@context/User';
import useCustomAttributesColumns from '@hooks/useCustomAttributesColumns';
import BIColumnPage from '@pages/BIColumnPage';

import TileNameTableCell from './TileNameTableCell';

interface TileTableProps {
  data?: DashboardElementModel[];
  isDataSourceEditable: boolean;
  isLoading?: boolean;
  itemCount?: number;
}

const TileTable: React.FC<TileTableProps> = ({
  data,
  isDataSourceEditable,
  isLoading,
  itemCount = 0,
}) => {
  const history = useHistory();
  const { organization } = useUserContext();
  const { guid, itemId } = useParams<{ guid: string; itemId: string }>();
  const [isShowFilter, setShowFilter] = useState(false);
  const [currTile, setCurrTile] = useState<string | undefined>(itemId);
  const customAttributesAssetType = 'looker,dashboardelement,';

  const { customAttributesColumns } = useCustomAttributesColumns({
    assetType: customAttributesAssetType,
    isDataSourceEditable,
    tableData: data ?? [],
  });

  const columns: ColumnConfig<DashboardElementModel>[] = useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: (props: Cell<DashboardElementModel>) => {
          const { row } = props;
          const tile = row.original;
          return (
            <TileNameTableCell
              {...props}
              openTilePage={() => {
                history.push(`tiles/${tile.guid}`);
                setCurrTile(tile.guid);
              }}
              tile={tile}
              tooltipText={tile.name}
            />
          );
        },
        Header: `Tile (${itemCount})`,
        accessor: (d) => d.name,
        disableHiding: true,
        id: 'name',
        width: '140%',
      },
      {
        Cell: (props: Cell<DashboardElementModel>) => {
          const { row } = props;
          const element = row.original;
          return (
            <DescriptionCell
              {...props}
              aiDescription={element.aiDescription}
              dataSourceType={element.dataTypes?.dataSourceType}
              description={element.description}
              descriptionSource={element.descriptionSource}
              guid={element.guid}
              ingestedDescription={element.ingestedDescription}
              isDataSourceEditable={isDataSourceEditable}
              name={element.name}
              parentGuid={guid}
              richtextDescription={element.richtextDescription}
              truncateDisabled={!organization?.settings?.useShowMoreButton}
              userDescription={element.userDescription}
            />
          );
        },
        Header: 'Description',
        accessor: 'description',
        id: 'description',
        width: '160%',
      },
      ...customAttributesColumns,
      {
        Cell: (props: Cell<DashboardElementModel>) => {
          const { row } = props;
          const item = row.original;
          return (
            <EditableTaggedItemCell
              {...props}
              isDataSourceEditable={isDataSourceEditable}
              obj={item}
            />
          );
        },
        Header: 'Tags',
        accessor: (d) => d.taggedItems,
        disableSortBy: true,
        id: 'tags',
        width: '120%',
      },
    ],
    [itemCount, customAttributesColumns],
  );

  const getRowId = React.useCallback((row: Partial<DashboardElementModel>) => row.guid!, []);

  return (
    <>
      <TableStyled>
        <Table
          basic="very"
          className="table-full"
          columns={columns}
          compact
          data={data || []}
          disablePagination
          disableRowSelect
          getRowId={getRowId}
          initialState={{
            sortBy: [
              {
                desc: true,
                id: 'popularity',
              },
            ],
          }}
          loading={isLoading}
          selectable
          showFilter={isShowFilter}
          sortable
          stickyHeader
          toggleFilter={() => setShowFilter((prev) => !prev)}
          unstackable
        />
      </TableStyled>
      {currTile && (
        <BIColumnPage
          guid={currTile}
          isDataSourceEditable={isDataSourceEditable}
          onClose={() => {
            history.push(`/dashboards/${guid}/tiles`);
            setCurrTile(undefined);
          }}
        />
      )}
    </>
  );
};

export default TileTable;
