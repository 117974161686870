import { plainToClass } from 'class-transformer';

import type { IconProps } from '@components/UI/Icon';
import DataTypesModel from '@models/DataTypesModel';
import theme from '@styles/theme';

export interface DeletedData {
  description: string;
  fullName: string;
  icon: string;
  iconName: string;
  name: string;
}

export class DeletedModel {
  objectType?: string;

  static deletedName: string = 'Deleted Item';

  name: string = DeletedModel.deletedName;

  description: string = 'This item has been deleted';

  get fullName() {
    const nameMap: Record<string, string> = {
      team: 'Deleted Team',
      user: 'Deleted User',
    };
    return nameMap[this.objectType as string] ?? DeletedModel.deletedName;
  }

  isDeleted = true;

  dataTypes?: DataTypesModel = plainToClass(DataTypesModel, {
    data_source_type: 'app',
    object_type: 'deleted',
  });

  get mappedAvatar() {
    const avatarNameMap: Record<string, IconProps['name']> = {
      default: 'deactivated-avatar',
      team: 'deleted-team',
      user: 'deactivated-avatar',
    };

    const avatarSizeMap: Record<string, string> = {
      default: '100%',
      team: '75%',
      user: '100%',
    };

    return {
      icon: {
        color: theme.colors.v1.gray[500],
        name: avatarNameMap[this.objectType as string] ?? avatarNameMap.default,
        size: avatarSizeMap[this.objectType as string] ?? avatarSizeMap.default,
      } as const,
      isDeleted: true,
    };
  }
}
