import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as CSS from 'csstype';
import type { ElementType } from 'react';
import type { LinkProps } from 'react-router-dom';

import getColor, { GetColorProps } from '@styles/mixins/getColor';
import makeSpace, { MakeSpaceProps } from '@styles/mixins/makeSpace';
import type { Theme } from '@styles/theme';
import type { Radius } from '@styles/theme/radius';
import { Shadow } from '@styles/theme/shadow';
import type { FontFamily, FontSize, FontWeight } from '@styles/theme/typography';

export interface StyledBoxProps extends MakeSpaceProps {
  alignItems?: CSS.Property.AlignItems;
  alignSelf?: CSS.Property.AlignSelf;
  as?: ElementType;
  backgroundColor?: GetColorProps;
  border?: CSS.Property.Border;
  borderBottom?: CSS.Property.BorderBottom;
  borderColor?: GetColorProps;
  borderRadius?: keyof Radius | CSS.Property.BorderRadius;
  borderStyle?: CSS.Property.BorderStyle;
  borderTop?: CSS.Property.BorderTop;
  borderWidth?: CSS.Property.BorderWidth;
  bottom?: CSS.Property.Bottom;
  boxShadow?: keyof Shadow | CSS.Property.BoxShadow;
  color?: GetColorProps;
  columnGap?: number;
  compDisplay?: CSS.Property.Display;
  compHeight?: CSS.Property.Height;
  compWidth?: CSS.Property.Width;
  cursor?: CSS.Property.Cursor;
  fill?: GetColorProps;
  flex?: CSS.Property.Flex;
  flexBasis?: CSS.Property.FlexBasis;
  flexDirection?: CSS.Property.FlexDirection;
  flexGrow?: CSS.Property.FlexGrow;
  flexShrink?: CSS.Property.FlexShrink;
  flexWrap?: CSS.Property.FlexWrap;
  fontFamily?: FontFamily;
  fontSize?: FontSize | CSS.Property.FontSize;
  fontStyle?: CSS.Property.FontStyle;
  fontWeight?: FontWeight | CSS.Property.FontWeight;
  /**
   * Allows to define spacing between children if such elements wrap to the next line.
   * Should be used together with display=flex.
   * Don't use gap and spacing/vSpacing together! It might cause some issue.
   */
  gap?: number;
  gridAutoRows?: CSS.Property.GridAutoRows;
  gridColumn?: CSS.Property.GridColumn;
  gridColumnStart?: CSS.Property.GridColumnStart;
  gridTemplateColumns?: CSS.Property.GridTemplateColumns;
  gridTemplateRows?: CSS.Property.GridTemplateRows;
  justifyContent?: CSS.Property.JustifyContent;
  left?: CSS.Property.Left;
  lineHeight?: CSS.Property.LineHeight;
  maxHeight?: CSS.Property.MaxHeight;
  maxWidth?: CSS.Property.MaxWidth;
  minHeight?: CSS.Property.MinHeight;
  minWidth?: CSS.Property.MinWidth;
  noDefault?: boolean;
  opacity?: CSS.Property.Opacity;
  outline?: CSS.Property.Outline;
  overflow?: CSS.Property.Overflow;
  overflowWrap?: CSS.Property.OverflowWrap;
  overflowX?: CSS.Property.OverflowX;
  overflowY?: CSS.Property.OverflowY;
  overscrollBehavior?: CSS.Property.OverscrollBehavior;
  position?: CSS.Property.Position;
  right?: CSS.Property.Right;
  rowGap?: number;
  textAlign?: CSS.Property.TextAlign;
  textDecoration?: CSS.Property.TextDecoration;
  textOverflow?: CSS.Property.TextOverflow;
  to?: LinkProps['to'];
  top?: CSS.Property.Top;
  transform?: CSS.Property.Transform;
  userSelect?: CSS.Property.UserSelect;
  verticalAlign?: CSS.Property.VerticalAlign;
  whiteSpace?: CSS.Property.WhiteSpace;
  wordBreak?: CSS.Property.WordBreak;
  zIndex?: CSS.Property.ZIndex;

  /**
   * @deprecated Allows to define the horizontal spacing between children elements.
   */
  // eslint-disable-next-line typescript-sort-keys/interface
  spacing?: number;
  /**
   * @deprecated Allows to define the vertical spacing between children elements.
   */
  // eslint-disable-next-line typescript-sort-keys/interface
  vSpacing?: number;
}

/** @deprecated */
const getSpacing = ({
  spacing,
  theme,
  vSpacing,
}: {
  spacing?: number;
  theme: Theme;
  vSpacing?: number;
}) => {
  if (!spacing && !vSpacing) {
    return undefined;
  }

  return {
    '> :not(style) + :not(style)': {
      margin: theme.space(vSpacing ?? 0, 0, 0, spacing ?? 0),
    },
  };
};

/**
 * @todo Architecture.
 * Remove default values from box so we don't need to be overwriting them.
 */

export const BoxStyles = (props: StyledBoxProps & { theme: Theme }) => css`
  align-items: ${props.alignItems};
  align-self: ${props.alignSelf};
  background-color: ${getColor(props.backgroundColor)};
  color: ${getColor(props.color)};
  border: ${props.border};
  border-bottom: ${props.borderBottom};
  border-top: ${props.borderTop};
  border-radius: ${props.theme.radius[props.borderRadius as keyof Radius] ?? props.borderRadius};
  border-width: ${props.borderWidth};
  border-style: ${props.borderStyle};
  border-color: ${getColor(props.borderColor)};
  bottom: ${props.bottom};
  box-shadow: ${props.theme.shadow[props.boxShadow as keyof Shadow] ?? props.boxShadow};
  cursor: ${props.cursor};
  fill: ${getColor(props.fill)};
  display: ${props.compDisplay};
  flex: ${props.flex};
  flex-direction: ${props.flexDirection};
  flex-grow: ${props.flexGrow};
  flex-shrink: ${props.flexShrink};
  flex-wrap: ${props.flexWrap};
  font-style: ${props.fontStyle};
  font-size: ${props.theme.typography.fontSizes[props.fontSize as FontSize] ?? props.fontSize};
  font-weight: ${props.theme.typography.fontWeights[props.fontWeight as FontWeight] ??
  props.fontWeight};
  font-family: ${props.theme.typography.fontFamilies[props.fontFamily as FontFamily] ??
  props.fontFamily};
  gap: ${props.gap ? props.theme.space(props.gap) : undefined};
  column-gap: ${props.columnGap ? props.theme.space(props.columnGap) : undefined};
  row-gap: ${props.rowGap ? props.theme.space(props.rowGap) : undefined};
  grid-auto-rows: ${props.gridAutoRows};
  grid-column: ${props.gridColumn};
  grid-template-columns: ${props.gridTemplateColumns};
  grid-template-rows: ${props.gridTemplateRows};
  grid-column-start: ${props.gridColumnStart};
  height: ${props.compHeight};
  justify-content: ${props.justifyContent};
  left: ${props.left};
  line-height: ${props.lineHeight};
  max-height: ${props.maxHeight};
  max-width: ${props.maxWidth};
  min-height: ${props.minHeight};
  min-width: ${props.minWidth};
  opacity: ${props.opacity};
  outline: ${props.outline};
  overflow: ${props.overflow};
  overflow-x: ${props.overflowX};
  overflow-y: ${props.overflowY};
  overflow-wrap: ${props.overflowWrap};
  overscroll-behavior: ${props.overscrollBehavior};
  position: ${props.position};
  right: ${props.right};
  text-align: ${props.textAlign};
  transform: ${props.transform};
  text-decoration: ${props.textDecoration};
  text-overflow: ${props.textOverflow};
  top: ${props.top};
  user-select: ${props.userSelect};
  vertical-align: ${props.verticalAlign};
  white-space: ${props.whiteSpace};
  width: ${props.compWidth};
  word-break: ${props.wordBreak};
  z-index: ${props.zIndex};
  ${getSpacing(props)};
  ${makeSpace(props.theme, props)};
`;
const StyledBox = styled.div<StyledBoxProps>(({ compWidth, maxWidth, noDefault, ...other }) =>
  BoxStyles({
    compWidth: compWidth === undefined && !noDefault ? 'auto' : compWidth,
    maxWidth: maxWidth === undefined && !noDefault ? '100%' : maxWidth,
    ...other,
  }),
);

export default StyledBox;
