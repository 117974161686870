import React from 'react';

import { GridContainer } from '@components/Grid';
import TabsRouter from '@components/TabsRouter';
import Icon from '@components/UI/Icon';
import TitleView from '@components/UI/TitleView';
import { useUserContext } from '@context/User';
import MetadataDecorator from '@utils/MetadataDecorator';

import AnalyticsActivities from './tabs/AnalyticsActivities/AnalyticsActivities';
import AnalyticsActivityLogTab from './tabs/AnalyticsActivityLogTab/AnalyticsActivityLogTab';
import AnalyticsSchemaChangesTab from './tabs/AnalyticsSchemaChangesTab/AnalyticsSchemaChangesTab';
import AnalyticsUsers from './tabs/AnalyticsUsers/AnalyticsUsers';

const AdminAnalyticsPage: React.FC = () => {
  const { organization } = useUserContext();
  const { useSchemaChangeNotification } = organization?.settings ?? {};

  const tabObjects = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <AnalyticsUsers />,
      contentOffset: 1.6,
      default: true,
      label: 'Users',
      route: '/users',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <AnalyticsActivities />,
      contentOffset: 1.6,
      label: 'Activities',
      route: '/activities',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <AnalyticsActivityLogTab />,
      contentOffset: 1,
      label: 'Activity Log',
      route: '/activity-log',
    },
    ...(useSchemaChangeNotification
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => <AnalyticsSchemaChangesTab />,
            contentOffset: 1,
            label: 'Schema Changes',
            route: '/schema-changes',
          },
        ]
      : []),
  ];

  return (
    <>
      <MetadataDecorator title="Analytics" />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <TitleView
          as="h1"
          compSize="lg"
          icon={<Icon color="currentColor" mr={0.5} name="analytics" size="1.1em" />}
          mb={4}
          title="Analytics"
        />
        <TabsRouter config={tabObjects} />
      </GridContainer>
    </>
  );
};

export default AdminAnalyticsPage;
