import { useMemo } from 'react';
import { plainToClass } from 'class-transformer';
import { useLocation } from 'react-router-dom';

import DataTypesModel from '@models/DataTypesModel';

/*
 * Hook to get dataTypes from the search params if available
 */

const useSearchParamsDataType = () => {
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const searchParamsDataTypes = useMemo(() => {
    const dataSourceType = searchParams.get('dataSourceType');
    const objectType = searchParams.get('objectType');
    const dataType = searchParams.get('dataType');

    if (!dataSourceType || !objectType) {
      return undefined;
    }
    return plainToClass(DataTypesModel, {
      data_source_type: dataSourceType,
      data_type: dataType,
      object_type: objectType,
    });
  }, [searchParams]);

  return searchParamsDataTypes;
};

export default useSearchParamsDataType;
