import React, { useEffect, useMemo } from 'react';
import { LOADING_TEXT } from '@constants';
import { useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import invalidateCache from '@api/invalidateCache';
import { useFetchSlackConnectedChannels } from '@api/slack';
import { useFetchTeam, usePatchTeam } from '@api/teams';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import NotFoundError from '@components/Error/NotFoundError';
import useForm from '@components/Form/useForm';
import { GridContainer } from '@components/Grid';
import Input from '@components/Input/Input.v1';
import TitleHeader from '@components/Title/TitleHeader';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import InputLabel from '@components/UI/Form/InputLabel';
import Select from '@components/UI/Select.v1/Select';
import { SelectValue } from '@components/UI/Select.v1/types';
import AppSettingsInput from '@pages/UserSettingsPage/AppSettingsInput';
import SettingsSection from '@pages/UserSettingsPage/SettingsSection';
import SettingsSubSection from '@pages/UserSettingsPage/SettingsSubSection';
import UserSettingsInput from '@pages/UserSettingsPage/UserSettingsInput';
import rootTheme from '@styles/theme';
import MetadataDecorator from '@utils/MetadataDecorator';

export const SAVED_MESSAGE = 'Changes saved.';
export const SAVED_ERROR_MESSAGE = "Couldn't save settings.";
const SECTION_GRID_TEMPLATE = `${rootTheme.space(10)} max-content`;
const UPDATE_DEBOUNCE_TIME = 1000;

interface FormValues {
  emailsForNotifications: SelectValue;
  enableNotificationsForMetadataChanges: boolean;
  enableNotificationsForSchemaChanges: boolean;
  name: string;
  slackChannelsForNotifications: string[];
}

const TeamSettingsPage: React.FC = () => {
  const { guid } = useParams<{ guid: string }>();

  const {
    data: teamData,
    error,
    isLoading,
  } = useFetchTeam(guid, {
    enabled: Boolean(guid),
  });
  const { data: connectedChannelsData, isLoading: isLoadingConnectedChannels } =
    useFetchSlackConnectedChannels();

  const { error: updateError, mutate } = usePatchTeam(guid, {
    onError: () => {
      renderErrorToast(SAVED_ERROR_MESSAGE);
    },
    onSuccess: () => {
      renderInfoToast(SAVED_MESSAGE);
      invalidateCache((keys) => [keys.teams.all]);
    },
  });

  const { handleChange, setValues, values } = useForm<FormValues>({
    initialValues: {
      emailsForNotifications: undefined,
      enableNotificationsForMetadataChanges: false,
      enableNotificationsForSchemaChanges: false,
      name: teamData?.name ?? '',
      slackChannelsForNotifications: [],
    },
  });

  useEffect(() => {
    if (teamData?.settings) {
      const { emailsForNotifications, ...rest } = teamData.settings;
      const emailOptions = emailsForNotifications?.map((email: string) => ({
        text: email,
        value: email,
      }));
      setValues((oldValues) => ({
        ...oldValues,
        emailsForNotifications: emailOptions,
        ...rest,
        name: teamData.name,
      }));
    }
  }, [setValues, teamData]);

  const updateTeamName = useDebouncedCallback((name) => {
    mutate({ name });
  }, UPDATE_DEBOUNCE_TIME);

  const updateSettings = useDebouncedCallback(() => {
    const emailValues = values.emailsForNotifications?.map((option) => String(option.value));

    mutate({
      settings: {
        emails_for_notifications: emailValues,
        enable_notifications_for_metadata_changes: values.enableNotificationsForMetadataChanges,
        enable_notifications_for_schema_changes: values.enableNotificationsForSchemaChanges,
        slack_channels_for_notifications: values.slackChannelsForNotifications,
      },
    });
  }, UPDATE_DEBOUNCE_TIME);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);

    if (values.name.trim() !== e.target.value?.trim()) {
      updateTeamName(e.target.value);
    }
  };

  const handleSettingChange = (
    name: keyof typeof values,
    value: string | boolean | string[] | SelectValue,
  ) => {
    setValues((current) => ({ ...current, [name]: value }));
    updateSettings();
  };

  const emailErrorMessage = useMemo(() => {
    const errorJson = updateError?.data?.settings?.emails_for_notifications ?? {};

    const keys = Object.keys(errorJson);
    const message = errorJson?.[keys?.[0]]?.[0];

    return {
      at: keys.map((key) => Number(key)),
      message,
    };
  }, [updateError]);

  const emailsForNotificationsWithErrorValues = useMemo(
    () =>
      values.emailsForNotifications?.map((email, index) => {
        if (emailErrorMessage.at?.includes(index)) {
          return {
            ...email,
            hasError: true,
          };
        }

        return email;
      }),
    [emailErrorMessage, values.emailsForNotifications],
  );

  const connectedChannelsOptions = connectedChannelsData?.results?.map((channel) => {
    return {
      selected: values.slackChannelsForNotifications?.includes(channel.channel_id),
      text: `# ${channel.name}`,
      value: channel.channel_id,
    };
  });

  if (!guid || error) {
    return <NotFoundError />;
  }

  if (isLoading) {
    return <CircularLoader centered cover />;
  }

  return (
    <>
      <MetadataDecorator title="Team Settings" />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <Box alignItems="start" position="relative">
          <Box mb={5}>
            <TitleHeader>
              <span className="title big">Team Settings</span>
            </TitleHeader>
          </Box>
          <Box compDisplay="flex" flex={1} flexDirection="column" gap={5.25}>
            <SettingsSection title="Team profile">
              <InputLabel
                alignItems="baseline"
                color="gray.700"
                compDisplay="grid"
                fontWeight="medium"
                gridTemplateColumns={SECTION_GRID_TEMPLATE}
              >
                Name
                <Input
                  disabled={isLoading}
                  error={updateError?.data?.name}
                  helperText={updateError?.data?.name}
                  name="name"
                  onChange={handleNameChange}
                  placeholder={isLoading ? LOADING_TEXT : 'Name'}
                  type="text"
                  value={values.name ?? teamData?.name}
                />
              </InputLabel>
            </SettingsSection>
            <SettingsSection
              subtitle="Teams are notified of metadata and schema changes on objects they own or if they are mentioned in a comment. Notifications will be sent to all team members directly unless an email or Slack channel is configured."
              title="Notification settings"
            >
              <SettingsSubSection compDisplay="flex" flexDirection="column" gap={1}>
                <AppSettingsInput title="Email" titleAlignment="center">
                  <Select
                    error={Boolean(updateError)}
                    helperText={updateError ? emailErrorMessage.message : undefined}
                    hideCaret
                    inputId="emails-select"
                    isCreatable
                    isLoading={isLoading}
                    isMulti
                    isOpen={false}
                    onChange={(options) => {
                      handleSettingChange('emailsForNotifications', options);
                    }}
                    onNewOption={(newOption) => {
                      const newOptions = [...(values.emailsForNotifications ?? []), newOption];
                      handleSettingChange('emailsForNotifications', newOptions);
                    }}
                    placeholder="Enter recipients"
                    showSelectAllButton={false}
                    value={emailsForNotificationsWithErrorValues}
                  />
                </AppSettingsInput>
                <AppSettingsInput title="Slack channels" titleAlignment="center">
                  <Select
                    inputId="slack-channels-select"
                    isLoading={isLoading || isLoadingConnectedChannels}
                    isMulti
                    multiOptionsType="tiles"
                    onChange={(options) => {
                      const newOptions = options?.map((option) => option.value) as string[];

                      handleSettingChange('slackChannelsForNotifications', newOptions);
                    }}
                    options={connectedChannelsOptions}
                    placeholder="Choose channels"
                    showSelectAllButton={false}
                    value={connectedChannelsOptions?.filter((option) => option.selected)}
                  />
                </AppSettingsInput>
              </SettingsSubSection>
              <SettingsSubSection>
                <AppSettingsInput title="Receive notifications for">
                  <UserSettingsInput
                    checked={values.enableNotificationsForSchemaChanges}
                    onChange={(newValue: boolean) => {
                      handleSettingChange('enableNotificationsForSchemaChanges', newValue);
                    }}
                    title="Schema changes"
                  />
                </AppSettingsInput>
                <AppSettingsInput>
                  <UserSettingsInput
                    checked={values.enableNotificationsForMetadataChanges}
                    onChange={(newValue: boolean) => {
                      handleSettingChange('enableNotificationsForMetadataChanges', newValue);
                    }}
                    subtitle="Tags, owners, descriptions"
                    title="Metadata changes"
                  />
                </AppSettingsInput>
              </SettingsSubSection>
            </SettingsSection>
          </Box>
        </Box>
      </GridContainer>
    </>
  );
};

export default TeamSettingsPage;
