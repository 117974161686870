import { useReactFlow } from 'reactflow';

import { ExploreNode } from '@components/Explore.v1/Explore.types';
import { useExplore } from '@components/Explore.v1/useExplore';

const calculateNodeCenter = (node: ExploreNode) => {
  const { positionAbsolute, style } = node;

  const x = positionAbsolute!.x + Number(style!.width) / 2;
  const y = positionAbsolute!.y + Number(style!.height) / 2;
  return { x, y };
};

const useLineageInteractions = () => {
  const { setCenteredTableKey } = useExplore();
  const { fitView, getNode, getZoom, setCenter } = useReactFlow();

  const fitLineageView = (duration = 1500) => {
    const currentZoomLevel = getZoom();
    setTimeout(() => {
      fitView({
        duration,
        maxZoom: currentZoomLevel,
        minZoom: currentZoomLevel,
      });
    });
  };

  const focusOnNode = (nodeKey: string) => {
    const clickedNode = getNode(nodeKey);

    if (clickedNode) {
      const { x, y } = calculateNodeCenter(clickedNode);
      setCenter(x, y, { duration: 1500, zoom: 1 });
      setCenteredTableKey(nodeKey);
    }
  };

  return {
    fitLineageView,
    focusOnNode,
  };
};

export default useLineageInteractions;
