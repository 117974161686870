import React from 'react';
import copy from 'copy-to-clipboard';

import IconButton from '@components/IconButton';
import { renderInfoToast } from '@components/Toast';
import Icon from '@components/UI/Icon';
import { useMetadataObjectContext } from '@context/MetadataObject';
import { isBIType } from '@models/DataSourceCredentials';
import theme from '@styles/theme';

interface CopyFullPathButtonProps {
  className?: string;
  iconSize?: string;
  text: string;
}

export const onCopyClick = (textToCopy: string) => {
  copy(textToCopy);
  renderInfoToast('Full name copied to clipboard');
};

const CopyFullPathButton: React.FC<CopyFullPathButtonProps> = ({
  className,
  iconSize = '16px',
  text,
}) => {
  const { dataSourceType } = useMetadataObjectContext();
  const textToCopy =
    dataSourceType && isBIType(dataSourceType) ? text.split('.').join(' / ') : text;

  return (
    <IconButton
      className={className}
      onClick={(e) => {
        /**
         * stop propagation is important here,
         * otherwise, this would open the
         * column page.
         */
        e.stopPropagation();
        onCopyClick(textToCopy);
      }}
      role="button"
    >
      <Icon color={theme.colors.v1.gray[400]} name="copy" size={iconSize} />
    </IconButton>
  );
};

export default CopyFullPathButton;
