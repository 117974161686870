import React from 'react';

import ConnectionAlertModel from '@api/connectionAlerts/ConnectionAlertModel';
import Modal, { ModalContent, ModalHeader } from '@components/UI/Modal';
import { DataSourceModel } from '@models/DataSourceModel';
import theme from '@styles/theme';

import AlertDescription from './AlertDescription';

export interface DataSourceConnectionStatusModalProps {
  connectionAlerts: ConnectionAlertModel[];
  dataSource: DataSourceModel;
  onClose?: () => void;
}

const DataSourceConnectionStatusModal = ({
  connectionAlerts,
  dataSource,
  onClose,
}: DataSourceConnectionStatusModalProps) => {
  return (
    <Modal onClose={onClose} open size="medium" useContext>
      <ModalHeader onClose={onClose} title={`Connection Status ${dataSource.name}`} />
      <ModalContent
        compDisplay="flex"
        flexDirection="column"
        gap={2.5}
        maxHeight={theme.space(50)}
        minHeight="unset"
        py={2.5}
      >
        {connectionAlerts.map((alert) => (
          <AlertDescription key={alert.guid} alert={alert} />
        ))}
      </ModalContent>
    </Modal>
  );
};
export default DataSourceConnectionStatusModal;
