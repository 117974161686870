import React from 'react';

import { useExplore } from '@components/Explore.v1/useExplore';
import Icon from '@components/UI/Icon';
import { StyledModalHeaderCloseButton } from '@components/UI/Modal/Modal.styles';
import flags from '@features';
import theme from '@styles/theme';

import CollapseAllNodesButton from '../CollapseAllNodesButton';
import UndoRedoControls from '../UndoRedoControls';
import UsageFieldFilter from '../UsageFieldFilter';

import { StyledControlBar } from './ControlBar.styles';

interface ControlBarProps {
  onClose: () => void;
}

const ControlBar = ({ onClose }: ControlBarProps) => {
  const { selectedUsageTypesState, setSelectedUsageTypesState } = useExplore();
  return (
    <StyledControlBar className="lineage-control-bar">
      <UndoRedoControls />
      {flags.lineage_collapse_all_nodes_button && <CollapseAllNodesButton />}
      <UsageFieldFilter
        selectedUsageTypes={selectedUsageTypesState.usageTypes}
        setSelectedUsageTypesState={setSelectedUsageTypesState}
      />
      <StyledModalHeaderCloseButton aria-label="close" onClick={onClose} onKeyPress={onClose}>
        <Icon color="gray.500" name="close" size={theme.space(1.5)} />
      </StyledModalHeaderCloseButton>
    </StyledControlBar>
  );
};

export default ControlBar;
