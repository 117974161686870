import { ExploreNode, ExploreNodeData } from '@components/Explore.v1/Explore.types';
import { EdgesById, NodesById } from '@components/Explore.v1/useCreateNodesEdges/algorithm/types';

import { createRedirectedEdgesToShowMore } from './createRedirectedEdges';

interface CreateShowMoreArgs
  extends Required<Pick<ExploreNode, 'position' | 'parent'>>,
    Pick<ExploreNodeData, 'hiddenChildrenCount' | 'hiddenChildren'> {
  data: ExploreNodeData;
  edgesById: EdgesById;
  id?: string;
  nodesById: NodesById;
  stackedAt?: number;
  width: number;
}

export const createShowMoreId = (id: string) => `${id}-showMore`;

const createShowMore = ({
  data,
  edgesById,
  hiddenChildren = new Set([]),
  hiddenChildrenCount,
  id,
  nodesById,
  parent,
  position,
  stackedAt,
  width,
}: CreateShowMoreArgs) => {
  const { edges, nodeId } = createRedirectedEdgesToShowMore({
    edgesById,
    inputNodeId: id ?? parent,
    nodesById,
    targetOrSourceNodeKeys: hiddenChildren,
  });

  const node = {
    data: {
      ...data,
      hiddenChildrenCount,
      key: parent,
      nodeEdgesById: edges,
      stackedAt: stackedAt ?? data.stackedAt,
    },
    id: nodeId,
    key: nodeId,
    parentId: parent,
    parentNode: parent,
    position,
    style: {
      width,
    },
    type: 'showMore',
  };

  return {
    edges: { ...edgesById, ...edges },
    node,
  };
};

export default createShowMore;
