import { UserModel } from '@api/user/UserModel';
import { MetadataModel } from '@models/MetadataModel';

export const suggestedDescriptionSourceObjectMock = {
  objectType: 'column',
  typeDisplay: 'Column',
  guid: 'co_X59iusjQ63ySZ2kkNLbhaU',
  name: 'REVIEW_COMMENT_MESSAGE',
  breadcrumbs: [
    {
      guid: 'br_VBMYXtWq86FLHSrqF2kcvH',
      ordinal: 0,
      targetDataType: 'database',
      targetGuid: 'db_oDvRw8HPxpsWSAN75CdL5E',
      targetName: 'olist',
      targetObjectType: 'database',
      targetDataSourceType: 'snowflake',
      targetDataSourceGuid: 'ds_SHxQcuS6JBKSRQd8y9SPrv',
    },
    {
      guid: 'br_7yHqpoba8WjoN2LwayEfrN',
      ordinal: 1,
      targetDataType: 'schema',
      targetGuid: 'cl_33KExqs84ZDN2c2VqvhXLv',
      targetName: 'datasets',
      targetObjectType: 'schema',
      targetDataSourceType: 'snowflake',
      targetDataSourceGuid: 'ds_SHxQcuS6JBKSRQd8y9SPrv',
    },
    {
      guid: 'br_h4nMpW2YpgYryvnGWPXS8Y',
      ordinal: 2,
      targetDataType: 'table',
      targetGuid: 'ta_NBTzdPHGFimWoD2Nb5GLJx',
      targetName: 'order_reviews',
      targetObjectType: 'table',
      targetDataSourceType: 'snowflake',
      targetDataSourceGuid: 'ds_SHxQcuS6JBKSRQd8y9SPrv',
    },
  ],
  breadcrumbList: [
    {
      guid: 'br_YPLuHxj94xG3Gh3kjrpTVF',
      label: 'OLIST',
      url: '/databases/db_TSiF4zUe6gm7J2rTAEPYTQ',
    },
    {
      guid: 'br_6mVfiPuhcXTxnzbLCVtYkj',
      label: 'DATASETS',
      url: '/schemas/cl_dVyXhPvLeEkzuyowANTq8h',
    },
    {
      guid: 'br_TuHpCyzzVpqP9UxzN9LwSf',
      label: 'ORDER_REVIEWS',
      url: '/tables/ta_WJefWaVBz9rh2XVwVXrqX5',
    },
  ],
  object_type: 'column',
  dataSourceType: 'snowflake',
} as unknown as MetadataModel;

export const activityMock = {
  objectType: 'activity',
  guid: 'ac_BnAgUgeLE8qYE94GBSFxHn',
  activityType: 'update',
  user: {
    objectType: 'user',
    typeDisplay: 'User',
    guid: 'us_VQBz2J98h6AoRduveikmAN',
    firstName: 'Chloe',
    lastName: 'Kim',
    email: 'Chloe@getselectstar.com',
    avatar: undefined,
    team: {
      objectType: 'team',
      typeDisplay: 'Team',
      guid: 'te_CnsrQpgGAyQy3Y8ZGb9fMZ',
      name: 'Design',
      color: '#CE8EED',
      description: '',
    },
    mappedAvatar: {
      guid: 'us_VQBz2J98h6AoRduveikmAN',
      isActive: true,
      isDeleted: false,
      name: 'Chloe Kim',
      src: undefined,
    },
  } as UserModel,
  target: {
    objectType: 'column',
    obj: {
      objectType: 'column',
      typeDisplay: 'Column',
      guid: 'co_Hx2wUMncJNbBAv2rrzPs9E',
      name: 'ORDER_ID',
      description: 'Order Status teste',
      dataTypes: {
        dataSourceType: 'snowflake',
        dataType: 'string',
        objectType: 'column',
      },
      richtextDescription: '[{"type":"paragraph","children":[{"text":"Order Status teste"}]}]',
      suggestedDescription: null,
      dataType: 'string',
      deactivationScheduledOn: null,
      dataSourceType: 'snowflake',
      rawParentGuid: 'ta_JTnprm3kK2gmWy84HepQ9h',
      object_type: 'column',
      raw_popularity: null,
    },
  },
  cachedTargetName: 'ORDER_ID',
  comment: null,
  performedOn: '2023-06-07T17:45:38.398Z',
};
