import { Expose, Type } from 'class-transformer';

import { DataSourceModel } from '@models/DataSourceModel';
import formatNumber from '@utils/formatNumber';

export interface WarehouseModelResponse {
  data_source: any;
  name: 'string';
  size: 'string';
  total_credits_used: number;
  total_run: number;
}

class WarehouseModel {
  static objectType: 'warehouse' = 'warehouse';

  guid!: string;

  @Expose({ name: 'average_credits_used' })
  averageCreditsUsed?: number = 0;

  get formattedAverageCreditsUsed() {
    return formatNumber(this.averageCreditsUsed);
  }

  @Expose({ name: 'data_source' })
  @Type(() => DataSourceModel)
  dataSource!: DataSourceModel;

  name!: string;

  size!: string;

  objectType = WarehouseModel.objectType;

  @Expose({ name: 'total_credits_used' })
  totalCreditsUsed?: number = 0;

  get formattedTotalCreditsUsed() {
    return formatNumber(this.totalCreditsUsed);
  }

  @Expose({ name: 'total_run' })
  totalRun: number = 0;

  get formattedTotalRun() {
    return formatNumber(this.totalRun);
  }
}

export default WarehouseModel;
