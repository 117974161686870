import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useFetchDatabases } from '@api/databases';
import { DatabaseModel } from '@api/databases/DatabaseModel';
import Box from '@components/Box';
import NextStepButton from '@components/DataSourceSetup/components/Buttons/NextStepButton';
import { BoxWrapper } from '@components/DataSourceSetup/components/DataSourceAddStep';
import Icon from '@components/UI/Icon';
import { ModalFooter } from '@components/UI/Modal';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackPageName } from '@context/Segment/Segment.types';
import { isTransformerType, isWarehouseType } from '@models/DataSourceCredentials';
import { DataSourceModel } from '@models/DataSourceModel';
import { urlFor } from '@utils/routing';

import CompleteInfoText from './CompleteInfoText';
import DatabaseListStyled from './DatabaseListStyled';

interface Props {
  dataSource: DataSourceModel;
  onSuccess: () => void;
}

const SetupCompleteStep: React.FC<Props> = ({ dataSource, onSuccess }) => {
  const segment = useSegmentContext();
  const history = useHistory();

  const { data } = useFetchDatabases({
    params: {
      active: true,
      datasources: dataSource.guid,
    },
  });
  const databases: DatabaseModel[] | undefined = data?.results;

  useEffect(() => {
    segment?.page(SegmentTrackPageName.ImportDataCompleted);
  }, []);

  const dbUrl = (currDb: DatabaseModel) => {
    if (!databases) {
      return '';
    }
    const filtered = databases.filter((db) => db.name === currDb.name);
    if (filtered.length === 0) {
      return '';
    }
    return urlFor(filtered[0]) || '';
  };

  const handleClick = (db: DatabaseModel) => {
    const url = dbUrl(db);
    if (url) {
      history.push(url);
    }
  };

  return (
    <>
      <BoxWrapper>
        {(isWarehouseType(dataSource.type) || isTransformerType(dataSource.type)) && (
          <>
            <CompleteInfoText>
              Visit your Database pages to assign ownership to Table pages.
            </CompleteInfoText>
            <DatabaseListStyled>
              {databases?.map((db) => (
                <Box
                  key={db.name}
                  as="li"
                  onClick={() => handleClick(db)}
                  onKeyPress={() => handleClick(db)}
                  overflow="auto"
                  role="button"
                  tabIndex={0}
                >
                  <Icon compDisplay="inline-block" mr={0.5} name="database" size="16px" />
                  {db.name}
                </Box>
              ))}
            </DatabaseListStyled>
          </>
        )}
        {dataSource.type === 'mode' && (
          <CompleteInfoText>
            Visit your report pages to check out the data ingested.
          </CompleteInfoText>
        )}
        {dataSource.type === 'sigma' && (
          <CompleteInfoText>
            Visit your workbook pages to check out the data ingested.
          </CompleteInfoText>
        )}
        {dataSource.type === 'power_bi' && (
          <CompleteInfoText>Visit your workspaces to check out the data ingested.</CompleteInfoText>
        )}
        {dataSource.type === 'periscope' && (
          <CompleteInfoText>Visit your tag pages to check out the data ingested.</CompleteInfoText>
        )}
        {dataSource.type === 'looker' && (
          <CompleteInfoText>
            Visit your explore pages to check out the data ingested.
          </CompleteInfoText>
        )}
        {dataSource.type === 'tableau' && (
          <CompleteInfoText>
            Visit your Tableau pages to check out the data ingested.
          </CompleteInfoText>
        )}
      </BoxWrapper>
      <ModalFooter>
        <NextStepButton onClick={onSuccess} text="Done" />
      </ModalFooter>
    </>
  );
};

export default SetupCompleteStep;
