import styled from '@emotion/styled';
import { Dropdown } from 'semantic-ui-react';

export const StyledQueryModalButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  margin: -5px 0px;
`;

export const StyledQueryModalFooterSeparator = styled.div`
  flex-grow: 1;
`;

export const StyledQueryModalFooterPanel = styled.div`
  text-align: right;
`;

export const StyledQueryModalCodeEditorWrapper = styled.div`
  position: relative;
`;

export const StyledDropdown = styled(Dropdown)`
  &.ui.selection.dropdown {
    border: none;
    background-color: ${({ theme }) => theme.colors.gray[100]};
    line-height: ${({ theme }) => theme.typography.lineHeights.md};
    font-size: ${({ theme }) => theme.typography.fontSizes.body2};
    padding: ${({ theme }) => theme.colors.gray[100]};
  }

  .item {
    background-color: ${({ theme }) => theme.colors.gray[100]};
  }

  &.ui.dropdown .menu > .item:hover {
    background-color: #375fa0;

    .text {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  .text {
    font-size: ${({ theme }) => theme.typography.fontSizes.body2};
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
    color: ${({ theme }) => theme.colors.primary};
  }

  &.ui.selection.visible.dropdown > .text:not(.default) {
    color: ${({ theme }) => theme.colors.primary};
  }

  &.ui.selection.active.dropdown .menu {
    border: none;
  }
`;
