import { Expose, Type } from 'class-transformer';

import type { TablePayload } from '@api/tables/TableModel';
import { TableModel } from '@api/tables/TableModel';
import type { DataTypesPayload } from '@models/DataTypesModel';
import DataTypesModel from '@models/DataTypesModel';

import type { ConditionPayload } from './ConditionModel';
import ConditionModel from './ConditionModel';

export interface IndexPayload {
  conditions?: Array<ConditionPayload>;
  data_types?: DataTypesPayload;
  definition?: string;
  guid?: string;
  name?: string;
  table?: Pick<TablePayload, 'guid' | 'data_types'>;
}

const objectType = 'index' as const;

class IndexModel {
  static objectType = objectType;

  objectType = objectType;

  @Type(() => ConditionModel)
  conditions?: Array<ConditionModel>;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel;

  definition?: string;

  guid!: string;

  name?: string;

  @Type(() => TableModel)
  table?: TableModel;
}

export default IndexModel;
