import React from 'react';

import { usePostIncompleteDataSource } from '@api/dataSources';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import { DataSourceFormProps } from './types';

const FORM_CONFIG = [
  { key: 'name', maxLength: 50, text: 'Display Name', type: 'text' },
  { key: 'host', text: 'Hostname', type: 'text' },
  { key: 'port', text: 'Port', type: 'text' },
  { key: 'user', text: 'Username', type: 'text' },
  { key: 'password', text: 'Password', type: 'password' },
  { key: 'database', text: 'DB Name', type: 'text' },
] as { key: string; maxLength?: number; text: string; type: string }[];

type FormConfig = typeof FORM_CONFIG;
type Field = FormConfig[number];
type Payload = Record<Field['key'], string>;

const PostgresForm: React.FC<DataSourceFormProps> = ({
  children,
  dataSource,
  dataType,
  name: defaultName = '',
  onSuccess,
  renderBefore,
}) => {
  const segment = useSegmentContext();
  const { error, isLoading, mutate } = usePostIncompleteDataSource({
    onSuccess,
  });

  const { handleChange, handleSubmit, values } = useForm<Payload>({
    initialValues: {
      database: '',
      host: '',
      name: dataSource?.name || defaultName,
      password: '',
      port: '5432',
      user: '',
    },
    onSubmit: ({ database, host, name, password, port, user }) => {
      mutate({
        credentials: { database, host, password, port, user },
        name,
        type: dataType,
      });
      segment?.track(SegmentTrackEventName.CreateServiceAccountConnectButtonClicked, { dataType });
    },
  });

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        {renderBefore?.({ error, loading: isLoading })}
        {FORM_CONFIG.map(({ key, maxLength, text, type }) => {
          const ApiError = error?.data[key];
          return (
            <StyledLabel key={key}>
              {text}
              <Input
                error={ApiError}
                helperText={ApiError}
                maxLength={maxLength}
                name={key}
                onChange={handleChange}
                placeholder={text}
                type={type}
                value={values?.[key]}
              />
            </StyledLabel>
          );
        })}
      </StyledFormHorizontalLabelGrid>
      {children?.({ error, loading: isLoading })}
    </Form>
  );
};

export default PostgresForm;
