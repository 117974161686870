import { dataSources } from '@configs/dataSources/config';
import type { DataTypes } from '@configs/dataTypes/types';

import theme from '@styles/theme';

export const colors = {
  columnArrowCircleFill: '#ffffff',

  columnArrowCircleStroke: '#cfd9e7',

  headingFill: '#f2f5f9',

  headingFillHover: '#d5ddeb',

  headingIconBGCloseFillHover: '#2c2e36',

  headingIconBGFillHover: '#b5c3dc',

  // arrows from column to column
  hoveredColumnArrowColor: '#e3649b',

  hoveredColumnFill: '#eedbe3',

  manualLineageColor: '#9269FF',

  pinnedColumnArrowColor: '#4c288f',

  pinnedColumnFill: '#d5cfe1',

  removeArrowColor: '#cc5f36',

  tableFill: '#ffffff',

  // arrows from table to table
  tableLinkColor: '#7490be',

  tableLinkHoverColor: '#F38817',

  tableStroke: '#cfd9e7',

  tableStrokeHover: '#375fa0',

  userPinnedColumnFill: '#C1C4DA',
};

export const colorsByType = {
  data_studio: {
    default: '#E5F2FF',
    hover: '#B4D6FC',
    iconHover: '#9dbde1',
    tableStroke: colors.tableStroke,
    tableStrokeHover: colors.tableStrokeHover,
  },
  default: {
    default: colors.headingFill,
    hover: colors.headingFillHover,
    iconHover: colors.headingIconBGFillHover,
    tableStroke: colors.tableStroke,
    tableStrokeHover: colors.tableStrokeHover,
  },
  erd: {
    default: '#F3F2FF',
    hover: '#e8e7f8',
    iconHover: colors.headingIconBGFillHover,
    tableStroke: '#A896C9',
    tableStrokeHover: '#4C288F',
  },
  looker: {
    default: '#e9e4fa',
    hover: '#d4cee5',
    iconHover: '#beb5c9',
    tableStroke: colors.tableStroke,
    tableStrokeHover: colors.tableStrokeHover,
  },
  metabase: {
    default: '#E5F2FF',
    hover: '#B4D6FC',
    iconHover: '#9dbde1',
    tableStroke: colors.tableStroke,
    tableStrokeHover: colors.tableStrokeHover,
  },
  mode: {
    default: '#d2e7db',
    hover: '#bfd2c7',
    iconHover: '#a7b6ad',
    tableStroke: colors.tableStroke,
    tableStrokeHover: colors.tableStrokeHover,
  },
  periscope: {
    default: '#fffae7',
    hover: '#FFE995',
    iconHover: '#FFE995',
    tableStroke: colors.tableStroke,
    tableStrokeHover: colors.tableStrokeHover,
  },
  power_bi: {
    default: '#F6E9C5',
    hover: '#F9D988',
    iconHover: '#F9D988',
    tableStroke: colors.tableStroke,
    tableStrokeHover: colors.tableStrokeHover,
  },
  [dataSources.quicksight.value]: {
    default: '#EEF6FC',
    hover: colors.headingFillHover,
    iconHover: colors.headingIconBGFillHover,
    tableStroke: colors.tableStroke,
    tableStrokeHover: colors.tableStrokeHover,
  },
  [dataSources.mssql.value]: {
    default: '#EEF6FC',
    hover: colors.headingFillHover,
    iconHover: colors.headingIconBGFillHover,
    tableStroke: colors.tableStroke,
    tableStrokeHover: colors.tableStrokeHover,
  },
  [dataSources.mysql.value]: {
    default: '#EEF6FC',
    hover: colors.headingFillHover,
    iconHover: colors.headingIconBGFillHover,
    tableStroke: colors.tableStroke,
    tableStrokeHover: colors.tableStrokeHover,
  },
  [dataSources.oracle.value]: {
    default: '#EEF6FC',
    hover: colors.headingFillHover,
    iconHover: colors.headingIconBGFillHover,
    tableStroke: colors.tableStroke,
    tableStrokeHover: colors.tableStrokeHover,
  },
  sigma: {
    default: '#E6F6F3',
    hover: '#ADDCD4',
    iconHover: '#ADDCD4',
    tableStroke: colors.tableStroke,
    tableStrokeHover: colors.tableStrokeHover,
  },
  tableau: {
    default: '#ebebeb',
    hover: '#dcdcdc',
    iconHover: '#c0c0c0',
    tableStroke: colors.tableStroke,
    tableStrokeHover: colors.tableStrokeHover,
  },
  thoughtspot: {
    default: 'white',
    hover: theme.colors.gray[100],
    iconHover: '#c0c0c0',
    tableStroke: colors.tableStroke,
    tableStrokeHover: colors.tableStrokeHover,
  },
} as const;

export type GetColorByTypeKey = DataTypes['dataSourceType'] | 'erd' | 'default';

export const getColorByType = (type?: GetColorByTypeKey) => {
  return colorsByType?.[type as keyof typeof colorsByType] ?? colorsByType.default;
};
