import React from 'react';

import IconButton from '@components/IconButton';

interface ClearSelectButtonProps {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}
const ClearSelectButton = ({ onClick }: ClearSelectButtonProps) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(e);
  };

  return (
    <IconButton
      aria-label="clear-select-button"
      compHeight="16px"
      compWidth="16px"
      iconColor="gray.500"
      iconName="close"
      iconSize="12px"
      onClick={handleClick}
      type="button"
      variant="clear"
    />
  );
};

export default ClearSelectButton;
