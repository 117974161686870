import React from 'react';

import Button from '@components/Button/Button';
import { useModal } from '@context/Modal';
import theme from '@styles/theme';

const CreateTagButton: React.FC = () => {
  const { MODAL_IDS, openModal } = useModal();

  const handleModalOpen = () => openModal(MODAL_IDS.createTag, undefined);

  return (
    <Button
      compHeight={theme.space(4.5)}
      compSize="lg"
      compWidth="100%"
      justifyContent="flex-start"
      onClick={handleModalOpen}
      startIconName="plus"
      startIconSize="20px"
      textAlign="left"
      variant="outlined"
    >
      Create a New Tag
    </Button>
  );
};

export default CreateTagButton;
