import React from 'react';
import getContainerPagesMapping from '@configs/getContainerPagesMapping';
import { useLocation, useParams } from 'react-router-dom';

import { useFetchFolder } from '@api/bifolder';
import NotFoundError from '@components/Error/NotFoundError';
import DashboardsPage from '@pages/DashboardsPage/DashboardsPage.v1';

const DashboardBIToolPageRouted: React.FC = () => {
  const { dsGuid, guid } = useParams<{ dsGuid: string; guid: string }>();
  const location = useLocation();
  const params = getContainerPagesMapping(location);

  const { data, error } = useFetchFolder(guid, { enabled: Boolean(guid) });

  if ((!dsGuid && !guid) || error) {
    return <NotFoundError />;
  }

  return (
    <DashboardsPage
      breadcrumbList={data?.breadcrumbList}
      countItemsConfig={params?.countItemsConfig}
      dataTypeOptions={params?.dataTypeOptions}
      dataTypes={guid ? data?.dataTypes : params?.dataTypes}
      dsGuid={dsGuid}
      filterProps={params?.filterProps}
      guid={guid}
      title={guid ? data?.name : params?.title}
    />
  );
};

export default DashboardBIToolPageRouted;
