import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useDeleteChatBotConversationById } from '@api/chatbot';
import invalidateCache from '@api/invalidateCache';
import Box from '@components/Box';
import DeleteConfirmationModal from '@components/Modal/DeleteConfirmationModal';

export interface DeleteChatBotHierarchyItemModalProps {
  guid: string;
  name: string;
  onClose?: () => void;
}

const DeleteChatBotHierarchyItemModal: React.FC<DeleteChatBotHierarchyItemModalProps> = ({
  guid,
  name,
  onClose,
}) => {
  const { guid: currentGuid } = useParams<{ guid: string }>();
  const history = useHistory();
  const { isLoading, mutate: deleteChat } = useDeleteChatBotConversationById(guid, {
    onSuccess: () => {
      invalidateCache((keys) => [keys.chatbot.all]);

      if (guid === currentGuid) {
        history.push('/chatbot');
      }
      onClose?.();
    },
  });

  return (
    <DeleteConfirmationModal
      loading={isLoading}
      onClose={() => onClose?.()}
      onRemove={() => deleteChat(null)}
      open
      title="Delete chat?"
      useContext
    >
      <Box as="span">
        This will delete{' '}
        <Box as="span" fontWeight="bold">
          {name}
        </Box>
      </Box>
    </DeleteConfirmationModal>
  );
};

export default React.memo(DeleteChatBotHierarchyItemModal);
