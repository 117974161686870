import React from 'react';

import Box from '@components/Box';
import { useExplore } from '@components/Explore.v1/useExplore';
import Skeleton from '@components/Skeleton';
import TaggedItems from '@components/TaggedItems';
import Tooltip from '@components/Tooltip.v1';
import Icon from '@components/UI/Icon';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import { MetadataData } from '@models/MetadataModel';
import theme from '@styles/theme';

import { getObjectRouteUrl } from '../Tabs/OverviewTab/OverviewTab.utils';

import {
  StyledBreadcrumbs,
  StyledTabHeader,
  StyledTabHeaderRowContainer,
  StyledTabHeaderTitle,
} from './TabsHeader.styles';

const TabHeader = () => {
  const { isLoadingSelectedNode, selectedNode } = useExplore();

  const { breadcrumbList, dataTypes, name } = selectedNode?.metadata ?? {};

  const { isEditable: isObjectEditable } = useObjectPermissionsContext({ id: selectedNode?.guid });

  const { selected, tagsCounts } = useBulkEditSelected<MetadataData>({
    defaultSelected: selectedNode?.metadata ? [selectedNode?.metadata] : undefined,
  });

  const isImplicit = dataTypes?.dataType === 'implicit';

  return (
    <StyledTabHeader>
      {isLoadingSelectedNode ? (
        <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={0.5}>
          <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={0.5}>
            <Skeleton
              compHeight={theme.space(2.5)}
              compWidth="50%"
              grayShade={200}
              variant="text"
            />
            <Skeleton
              compHeight={theme.space(3.5)}
              compWidth="40%"
              grayShade={200}
              variant="text"
            />
          </Box>
          <Skeleton compHeight={theme.space(3)} compWidth="60%" grayShade={200} variant="text" />
        </Box>
      ) : (
        <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={0.5}>
          <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={0.5}>
            <StyledTabHeaderRowContainer gap={1} pl={0.25}>
              <Icon color="gray.400" name={dataTypes?.icons.dataSource!} size={theme.space(2)} />
              <StyledBreadcrumbs items={breadcrumbList} showTrailingSlash={false} />
            </StyledTabHeaderRowContainer>
            <StyledTabHeaderRowContainer gap={0.75}>
              <Tooltip content={isImplicit ? 'Temporary table (inferred)' : ''} offset={[8, 15]}>
                <Icon color="gray.800" name={dataTypes?.icons.dataType!} size={theme.space(2.5)} />
              </Tooltip>
              <StyledTabHeaderTitle target="_blank" to={getObjectRouteUrl(selectedNode?.metadata)}>
                {name}
              </StyledTabHeaderTitle>
            </StyledTabHeaderRowContainer>
          </Box>
          {!isImplicit && !['columngroup', 'tablegroup'].includes(dataTypes?.objectType!) && (
            <TaggedItems
              iconButtonProps={{ size: 'sm' }}
              isEditable={isObjectEditable}
              objects={selected.items}
              showEllipsis
              taggedItemsCounts={tagsCounts}
              tagTextColor="gray.800"
            />
          )}
        </Box>
      )}
    </StyledTabHeader>
  );
};

export default TabHeader;
