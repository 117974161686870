import React, { memo, useEffect, useRef, useState } from 'react';

import { UsageTypeType } from '@api/lineage/types';
import truncateNameBreadcrumbs from '@api/lineage/utils/truncateNameBreadcrumbs';
import Box from '@components/Box';
import { ExploreNode } from '@components/Explore.v1/Explore.types';
import useColumnLevelLineage from '@components/Explore.v1/useColumnLevelLineage';
import { useExplore } from '@components/Explore.v1/useExplore';
import { SidebarOpeningOrigin } from '@components/Explore.v1/useExplore/Explore.context.types';
import useUserTracking from '@components/Explore.v1/useUserTracking';
import Highlighter from '@components/Highlighter';
import Icon from '@components/UI/Icon';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import flags from '@features';
import useComponentHover from '@utils/useComponentHover';

import UsageTypeBadge from '../../Common/UsageTypeBadge';
import NodeHandles from '../../EdgeHandle/NodeHandles';
import LineageTooltip from '../../LineageTooltip';
import { getColorsBasedOnDataTypes } from '../Nodes.utils';
import UpstreamDownstreamButton from '../UpstreamDownstreamButton';

import { StyledColumnNode, StyledColumnNodeTitle } from './ColumnNode.styles';
import { getDebugSimplifiedKeyLabel } from './ColumnNode.utils';
import ColumnNodeOptions from './ColumnNodeOptions';

const USAGE_SORT_ORDER = { aggregated: 2, asis: 1, filter: 4, none: 5, transformed: 3 };

const sortUsageLabels = (a: UsageTypeType, b: UsageTypeType) => {
  if (USAGE_SORT_ORDER[a] < USAGE_SORT_ORDER[b]) {
    return -1;
  }
  if (USAGE_SORT_ORDER[a] > USAGE_SORT_ORDER[b]) {
    return 1;
  }
  return 0;
};

const ColumnNode: React.FC<ExploreNode> = ({ data }) => {
  const {
    dataTypes,
    downstreamObjectsCount,
    edgesLoaded,
    guid = '',
    hideDownstreamButton,
    hideUpstreamButton,
    highlightedText,
    isBITableColumn,
    isNested,
    isSelected,
    isUnfocused,
    key,
    name,
    parent,
    upstreamObjectsCount,
    usage,
  } = data;

  const trackingData = {
    dataTypes: {
      dataSourceType: dataTypes?.dataSourceType,
      dataType: dataTypes?.dataType,
      objectType: dataTypes?.objectType,
    },
    downstreamObjectsCount,
    guid,
    isBITableColumn,
    key,
    name,
    parent,
    upstreamObjectsCount,
  };

  const { track } = useUserTracking();
  const [waitingOnClickAction, setWaitingOnClickAction] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { isHovered } = useComponentHover(containerRef);

  const {
    initialSelectedNodeGuid,
    initialSelectedNodeParentGuid,
    inputNodesById,
    isColumnLevelLineage,
    isColumnLevelLoading,
    openOverviewTab,
    selectedEdge,
    selectedNode,
    setLoadingState,
    setSelectedNode,
  } = useExplore();

  const { toggleColumnLevelLineage } = useColumnLevelLineage();

  const colors = getColorsBasedOnDataTypes(dataTypes);

  const showOpenDownstreamLevelButton = () => {
    if (hideDownstreamButton === undefined && initialSelectedNodeParentGuid === parent) {
      return false;
    }

    if (initialSelectedNodeGuid === parent) {
      if (hideDownstreamButton === undefined) {
        return false;
      }
      if (hideDownstreamButton === false) {
        return true;
      }
    }
    return !hideDownstreamButton && (downstreamObjectsCount ?? 0) > 0;
  };

  const showOpenUpstreamLevelButton = () => {
    if (hideUpstreamButton === undefined && initialSelectedNodeParentGuid === parent) {
      return false;
    }

    if (initialSelectedNodeGuid === parent) {
      if (hideUpstreamButton === undefined) {
        return false;
      }
      if (hideUpstreamButton === false) {
        return true;
      }
    }
    return !hideUpstreamButton && (upstreamObjectsCount ?? 0) > 0;
  };

  const shouldShowOpenLevelButton = {
    downstream: showOpenDownstreamLevelButton(),
    upstream: showOpenUpstreamLevelButton(),
  };

  const nodeClickAction = () => {
    track(SegmentTrackEventName.LineageColumnNodeClicked, trackingData);
    if (key) {
      setSelectedNode({
        guid,
        key,
        metadata: selectedNode?.guid === guid ? selectedNode?.metadata : undefined,
        type: 'column',
      });
      toggleColumnLevelLineage(key);
      openOverviewTab(SidebarOpeningOrigin.Column);
    }
  };

  const handleColumnClick = () => {
    if (!isColumnLevelLoading) {
      nodeClickAction();
    } else {
      setWaitingOnClickAction(true);
      setLoadingState({ enabled: true, type: 'column-level' });
    }
  };

  useEffect(() => {
    if (waitingOnClickAction && !isColumnLevelLoading) {
      nodeClickAction();
      setWaitingOnClickAction(false);
      setLoadingState({ enabled: false, type: 'column-level' });
    }
  }, [waitingOnClickAction, setLoadingState, isColumnLevelLoading]);

  const isParentImplicit = parent
    ? inputNodesById?.[parent]?.metadata?.dataTypes?.dataType === 'implicit'
    : false;

  const splitName = truncateNameBreadcrumbs({ enabled: isNested, str: name });
  const searchWords = highlightedText?.split(/[.| ]+/) ?? [];

  const columnInputData = inputNodesById?.[key ?? ''];
  const isDownstreamLeaf =
    edgesLoaded &&
    Object.keys(columnInputData?.target_edges ?? {}).length === 0 &&
    !shouldShowOpenLevelButton.downstream;
  const isUpstreamLeaf =
    edgesLoaded &&
    Object.keys(columnInputData?.source_edges ?? {}).length === 0 &&
    !shouldShowOpenLevelButton.upstream;
  const isConnectedToSelectedEdge = selectedEdge?.target === key || selectedEdge?.source === key;

  return (
    <StyledColumnNode
      ref={containerRef}
      borderColor={selectedNode?.key === key ? colors.primary : undefined}
      data-testid={`column-node-${key}`}
      isBITableColumn={isBITableColumn}
      isColumnLevelLineage={isColumnLevelLineage}
      isConnectedToSelectedEdge={isConnectedToSelectedEdge}
      isDownstreamLeaf={isDownstreamLeaf}
      isHighlighted={selectedNode?.key === key}
      isParentImplicit={isParentImplicit}
      isSelected={isSelected}
      isUnfocused={isUnfocused}
      isUpstreamLeaf={isUpstreamLeaf}
      nodeKey={key}
      onClick={handleColumnClick}
      showUpstreamDownstreamButtons={selectedNode?.key === key}
    >
      <NodeHandles height="column" />
      {shouldShowOpenLevelButton.upstream && (
        <UpstreamDownstreamButton
          direction="upstream"
          guid={guid}
          left="6px"
          nodeKey={key ?? ''}
          nodeType="column"
        />
      )}
      <Box alignItems="center" compDisplay="flex" gap={1.25} overflow="hidden">
        <Box alignItems="center" compDisplay="flex" gap={0.5} overflow="hidden">
          <Icon color="gray.400" name={dataTypes?.icons.dataType!} size="16px" />
          <LineageTooltip content={name} offset={[8, 15]} placement="bottom-start">
            <StyledColumnNodeTitle>
              {splitName.breadcrumbs && (
                <Box as="span" color="gray.500" noDefault>
                  <Highlighter
                    autoEscape
                    searchWords={searchWords}
                    textToHighlight={splitName.breadcrumbs}
                  />
                </Box>
              )}
              <Highlighter
                autoEscape
                searchWords={searchWords}
                textToHighlight={
                  (flags.new_lineage_debug_mode
                    ? getDebugSimplifiedKeyLabel(key)
                    : splitName.name) ?? ''
                }
              />
            </StyledColumnNodeTitle>
          </LineageTooltip>
        </Box>
        {isSelected &&
          usage
            ?.sort((a, b) => sortUsageLabels(a, b))
            ?.map((usageItem) => <UsageTypeBadge key={usageItem} type={usageItem} />)}
      </Box>
      <Box mr={isHovered && shouldShowOpenLevelButton.downstream ? 2.5 : 0}>
        <ColumnNodeOptions
          downstreamObjectsCount={downstreamObjectsCount}
          guid={guid}
          isColumnSelected={Boolean(isSelected)}
          nodeKey={key ?? ''}
          show={isHovered}
          trackingData={trackingData}
          upstreamObjectsCount={upstreamObjectsCount}
        />
      </Box>
      {shouldShowOpenLevelButton.downstream && (
        <UpstreamDownstreamButton
          direction="downstream"
          guid={guid}
          nodeKey={key ?? ''}
          nodeType="column"
          right="6px"
        />
      )}
    </StyledColumnNode>
  );
};

const MemoizedColumnNode = memo<typeof ColumnNode>(ColumnNode);

export default MemoizedColumnNode;
