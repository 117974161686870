import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import Explore from '@components/Explore';
import { TabContentProps } from '@components/Tabs/types';
import { DataSourceTypesType } from '@models/DataSourceCredentials';

import ExploreV1 from '../Explore.v1/Explore.v1';

export interface ExploreTabProps extends TabContentProps {
  dataSourceType?: DataSourceTypesType;
  downstreamCount?: number;
  guid: string;
  showModeLineage: boolean;
  upstreamCount?: number;
}

const ExploreTab: React.FC<ExploreTabProps> = ({
  dataSourceType,
  downstreamCount,
  guid,
  showModeLineage,
  upstreamCount,
}) => {
  const { url } = useRouteMatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  if (searchParams.get('version') === 'v0') {
    return <ExploreV1 prevRoute={url.replace('/lineage/explore', '/lineage')} />;
  }

  return (
    <Explore
      dataSourceType={dataSourceType}
      downstreamCount={downstreamCount}
      prevRoute={url.replace('/lineage/explore', '/lineage')}
      renderContext="dbSource"
      showModeLineage={showModeLineage}
      startingTableId={guid}
      upstreamCount={upstreamCount}
    />
  );
};

export default ExploreTab;
