import { DataTypesConfig } from '@configs/dataTypes/types';

import type { ColumnTableProps } from './ColumnsTable';

export interface ColumnsTableConfigItem
  extends Pick<ColumnTableProps, 'visibleColumns' | 'customColumnProps' | 'hiddenColumns'> {}

const COLUMNS_TABLE_CONFIG: DataTypesConfig<ColumnsTableConfigItem> = {
  dbt: {
    default: {
      customColumnProps: { popularity: { dropdownCheckboxLabel: 'Popularity' } },
      hiddenColumns: ['popularity'],
    },
  },
  postgres: {
    default: {
      visibleColumns: [
        'search',
        'name',
        'constraints',
        'indexes',
        'dataType',
        'description',
        'customAttribute',
        'tags',
        'popularity',
      ],
    },
  },
};

export default COLUMNS_TABLE_CONFIG;
