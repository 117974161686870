import React from 'react';

import Text from '@components/Text';
import theme from '@styles/theme';

interface Props {
  className?: string;
  columnsCount?: number;
  modelCount?: number;
  schemasCount?: number;
  tablesCount?: number;
}

const ItemsCount: React.FC<Props> = ({
  className,
  columnsCount,
  modelCount,
  schemasCount,
  tablesCount,
}) => {
  const modelText = modelCount === 1 ? '1 model' : `${modelCount} models`;
  const schemasText = schemasCount === 1 ? '1 schema' : `${schemasCount} schemas`;
  const tablesText = tablesCount === 1 ? '1 table' : `${tablesCount} tables`;
  const columnsText = columnsCount === 1 ? ', 1 column' : `, ${columnsCount} columns`;
  const text = modelCount
    ? modelText
    : `${schemasText}, ${tablesText}${columnsCount ? columnsText : ''}`;

  if (!schemasCount && !tablesCount && !columnsCount && !modelCount) {
    return null;
  }

  return (
    <Text
      as="span"
      className={className}
      color={theme.colors.gray[600]}
      display="inline"
      fontSize="16px"
      verticalAlign="baseline"
    >
      {text}
    </Text>
  );
};

export default React.memo(ItemsCount);
