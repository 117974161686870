import styled from '@emotion/styled';
import * as CSS from 'csstype';

import { SIZES } from '@components/Explore.v1/components/Nodes/config';

export interface StyledUpstreamDownstreamButtonProps {
  left?: CSS.Property.Left;
  nodeType: keyof typeof SIZES.height;
  right?: CSS.Property.Right;
}

const stylesByNodeType: Record<string, any> = {
  column: {
    height: '20px',
    top: '2px',
    width: '20px',
  },
  table: {
    height: '22px',
    top: '9px',
    width: '22px',
  },
};

export const StyledUpstreamDownstreamButtonContainer = styled.div<StyledUpstreamDownstreamButtonProps>`
  position: absolute;
  height: ${({ nodeType }) =>
    `${nodeType === 'table' ? SIZES.height.table : SIZES.height.column}px`};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  top: 0px;
  display: flex;
  align-items: center;
`;

export const StyledUpstreamDownstreamButton = styled.div<StyledUpstreamDownstreamButtonProps>`
  border-radius: 100%;
  height: ${({ nodeType }) => stylesByNodeType[nodeType].height};
  width: ${({ nodeType }) => stylesByNodeType[nodeType].width};
  background-color: ${({ theme }) => theme.colors.v1.primary[200]};
  cursor: pointer;
  visibility: hidden;

  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.v1.primary[600]};
    }
  }
`;
