import React from 'react';

import Button from '@components/Button/Button';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';
import { useModal } from '@context/Modal';

interface UploadTooLargeModalProps {
  maxFileSize?: string;
  onClose?: () => void;
  onSelectNewFile: () => void;
}

const UploadTooLargeModal: React.FC<UploadTooLargeModalProps> = ({
  maxFileSize = '2MB',
  onClose,
  onSelectNewFile,
}) => {
  const { MODAL_IDS, checkModalOpened, closeModal } = useModal();

  const handleClose = () => {
    closeModal(MODAL_IDS.uploadTooLarge);
    onClose?.();
  };

  const handleSelect = () => {
    closeModal(MODAL_IDS.uploadTooLarge);
    onSelectNewFile?.();
  };

  if (!checkModalOpened(MODAL_IDS.uploadTooLarge)) return null;

  return (
    <Modal onClose={handleClose} open size="tiny" useContext>
      <ModalHeader onClose={handleClose} title="Image size too large" />
      <ModalContent minHeight="unset" py={2}>
        File size cannot exceed {maxFileSize}. Please select another file.
      </ModalContent>
      <ModalFooter>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSelect}>Choose another file</Button>
      </ModalFooter>
    </Modal>
  );
};

export default UploadTooLargeModal;
