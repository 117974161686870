import React from 'react';
import { useHistory } from 'react-router-dom';

import { usePostPasswordReset } from '@api/password';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import CenterAlignedBox from '@components/CenterAlignedBox';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import Text, { defaultParagraphStyles } from '@components/Text';
import Title from '@components/Title/Title';

interface PasswordResetComponentProps {
  onSuccess: () => void;
}

const ResetPassword: React.FC<PasswordResetComponentProps> = ({ onSuccess }) => {
  const history = useHistory();
  const { error, isError, isLoading, mutate } = usePostPasswordReset({ onSuccess });
  const { handleChange, handleSubmit, values } = useForm({
    initialValues: {
      email: '',
    },
    onSubmit: (v) => {
      mutate(v);
    },
  });

  return (
    <CenterAlignedBox maxWidth="460px" minWidth="360px">
      <Form onSubmit={handleSubmit}>
        <Box alignItems="flex-start" compWidth="100%" mb={2}>
          <Title showLogo>Reset your password</Title>
        </Box>
        <Text as="p" pt={1.5} {...defaultParagraphStyles} lineHeight={1.5}>
          Enter your user account&apos;s email address and we will send you a password reset link.
        </Text>
        <Box compDisplay="block" compWidth="100%">
          <Input
            error={isError}
            helperText={
              isError && (error?.data?.email?.[0] || 'Unexpected Error. Please try again.')
            }
            name="email"
            onChange={handleChange}
            placeholder="Email"
            type="text"
            value={values.email}
          />
        </Box>
        <Button disabled={isLoading || !values.email} fluid mt={3} type="submit">
          Send reset email
        </Button>
      </Form>
      <Button fluid mt={3} onClick={() => history.push('/login')} type="button" variant="outlined">
        Sign in
      </Button>
    </CenterAlignedBox>
  );
};

export default ResetPassword;
