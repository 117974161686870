import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import cancelQueries from '@api/cancelQueries';
import {
  useFetchChatBotConversationById,
  usePostChatBotConversation,
  usePostChatBotConversationByIdMessagesStream,
} from '@api/chatbot';
import ChatBotConversationModel, {
  ChatBotConversationData,
} from '@api/chatbot/ChatBotConversationModel';
import getCacheData from '@api/getCacheData';
import invalidateCache from '@api/invalidateCache';
import setCacheData from '@api/setCacheData';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import CircularLoader from '@components/CircularLoader';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import { GridContainer } from '@components/Grid';
import IconButton from '@components/IconButton';
import Text from '@components/Text';
import { renderInfoToast } from '@components/Toast';
import Avatar from '@components/UI/Avatar/Avatar';
import Textarea from '@components/UI/Form/Textarea';
import HR from '@components/UI/HR';
import Icon from '@components/UI/Icon';
import { useUserContext } from '@context/User';
import px from '@styles/mixins/px';
import theme from '@styles/theme';
import formatNumber from '@utils/formatNumber';
import MetadataDecorator from '@utils/MetadataDecorator';

import ChatBotInitialPrompts from './ChatBotInitialPrompts';
import ChatBotMessage from './ChatBotMessage';
import ChatBotMessageToolbar from './ChatBotMessageToolbar';
import { StyledChatBotPageFormWrapper } from './ChatbotPage.styles';
import ChatBotPageTitle from './ChatBotPageTitle';

export enum CustomMsgType {
  intro = 'intro',
  optimistic = 'optimistic',
  streaming = 'streaming',
}

const SCROLL_BOTTOM_DEBOUNCE_TIME = 100;
export const STREAMING_ERROR_MESSAGE = 'An error occurred while generating a response.';
export const CREATE_NEW_CHAT_ERROR_MESSAGE = 'Failed to create conversation.';
export const FETCH_CONVERSATION_ERROR_MESSAGE = 'Error retrieving chat conversation.';
export const PROCESSING_REQUEST_INFO = 'We are processing your previous request. Please wait.';
export const MAX_CHARS_ALLOWED = 2000;

export const getInitialMessage = (name?: string) =>
  `Hey ${name}, welcome to Select Star AI chatbot! \n With me, you can improve your data literacy over the platform and get the information quickly.`;

const ChatBotPage: React.FC = () => {
  const { guid } = useParams<{ guid: string }>();
  const isChatExist = Boolean(guid);
  const [errors, setErrors] = useState<{
    [guid: string]: { conversation?: boolean; newChat?: boolean; stream?: boolean };
  }>({});
  const [newlyCreatedChatGuid, setNewlyCreatedChatGuid] = useState('');
  const contentContainerRef = useRef<HTMLDivElement | null>(null);
  const pendingPrompt = useRef('');
  const history = useHistory();
  const { user } = useUserContext();
  const { handleChange, handleSubmit, setValues, values } = useForm({
    initialValues: {
      prompt: '',
    },
    onSubmit: (val) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      sendMessage(val.prompt);
    },
  });

  const setErrorByGuid = (
    key: string,
    type: 'conversation' | 'newChat' | 'stream',
    error: boolean,
  ) => {
    setErrors((prev) => ({ ...prev, [key]: { [type]: error } }));
  };

  const initialMessage: ChatBotConversationModel = useMemo(
    () => ({
      messages: [
        {
          content: getInitialMessage(user?.firstName),
          createdOn: moment(),
          guid: CustomMsgType.intro,
          role: 'assistant',
        },
      ],
    }),
    [user?.firstName],
  );

  const {
    data,
    isLoading: isConversationLoading,
    refetch,
  } = useFetchChatBotConversationById(guid, {
    enabled: isChatExist,
    onError: () => {
      setErrorByGuid(guid, 'conversation', true);
    },
    onSuccess: () => {
      setErrorByGuid(guid, 'conversation', false);
    },
    placeholderData: isChatExist ? undefined : initialMessage,
  });

  const { isLoading: isMsgStreamLoading, mutate: streamMessageMutation } =
    usePostChatBotConversationByIdMessagesStream(guid || newlyCreatedChatGuid, {
      onDownloadProgress: (e) => {
        const prevData = getCacheData<ChatBotConversationData>((keys) =>
          keys.chatbot.conversation(guid),
        );

        if (e?.event?.target?.response) {
          const messageIndex = prevData?.messages?.findIndex(
            (m) => m.guid === CustomMsgType.streaming,
          );

          if (messageIndex && messageIndex !== -1) {
            const newData = {
              ...prevData,
              messages: prevData?.messages?.map((message) => {
                if (message.guid === CustomMsgType.streaming) {
                  return {
                    ...message,
                    content: e.event.target.response,
                  };
                }

                return message;
              }),
            };

            setCacheData((keys) => [[keys.chatbot.conversation(guid), newData]]);
          }
        }
      },
      onError: () => {
        setErrorByGuid(guid, 'stream', true);
      },
      onMutate: (val) => {
        cancelQueries((keys) => [keys.chatbot.conversation(guid)]);

        const prevData = getCacheData<ChatBotConversationData>((keys) =>
          keys.chatbot.conversation(guid || ''),
        );

        const newMessages = [...(prevData?.messages ?? [])];
        const isOptimisticMessage = newMessages.find((m) => m.guid === CustomMsgType.optimistic);
        const isStreamingMessage = newMessages.find((m) => m.guid === CustomMsgType.streaming);

        if (!isOptimisticMessage) {
          newMessages.push({
            content: val.user_input,
            guid: CustomMsgType.optimistic,
            role: 'user',
          });
        }

        if (!isStreamingMessage) {
          newMessages.push({
            content: '',
            guid: CustomMsgType.streaming,
            role: 'assistant',
          });
        }

        const newData = {
          ...prevData,
          guid,
          messages: newMessages,
        };

        setValues({ prompt: '' });
        setCacheData((keys) => [[keys.chatbot.conversation(guid), newData]]);
      },
      onSuccess: () => {
        if (newlyCreatedChatGuid) {
          history.replace(`/chatbot/${newlyCreatedChatGuid}`);
          setCacheData((keys) => [[keys.chatbot.conversation(undefined), undefined]]);
          invalidateCache((keys) => [keys.chatbot.all]);
        }
        setErrorByGuid(guid, 'stream', false);
        pendingPrompt.current = '';
        setNewlyCreatedChatGuid('');
        invalidateCache((keys) => [keys.chatbot.conversation(guid)]);
      },
    });

  const { isLoading: isCreateNewChatLoading, mutate: createNewChatMutation } =
    usePostChatBotConversation({
      onError: () => {
        setErrorByGuid(guid, 'newChat', true);
      },
      onSuccess: (d) => {
        if (d?.guid) {
          setErrorByGuid(guid, 'newChat', false);
          setNewlyCreatedChatGuid(d.guid);
          streamMessageMutation({ user_input: pendingPrompt.current });
        }
      },
    });

  const isLoading = isCreateNewChatLoading || isMsgStreamLoading || isConversationLoading;
  const isMsgStreamingError = errors[guid]?.stream;
  const isCreateNewChatError = errors[guid]?.newChat;
  const isConversationFetchingError = errors[guid]?.conversation;
  const isError = isMsgStreamingError || isCreateNewChatError || isConversationFetchingError;
  const promptTrimmed = values.prompt.trim();
  const isCharsLengthExceeded = promptTrimmed.length > MAX_CHARS_ALLOWED;

  const sendMessage = (val?: string) => {
    if (val?.trim() && !isCharsLengthExceeded) {
      setValues({ prompt: '' });
      pendingPrompt.current = val;

      if (isChatExist) {
        streamMessageMutation({ user_input: val });
      } else {
        createNewChatMutation({});
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey && !isLoading) {
      e.preventDefault();
      sendMessage(values.prompt);
    }
  };

  const handlePromptSelect = (val: string) => {
    if (!isMsgStreamLoading) {
      sendMessage(val);
    } else {
      renderInfoToast(PROCESSING_REQUEST_INFO);
    }
  };

  const scrollToBottom = useDebouncedCallback(
    () => {
      window?.scrollTo({ top: contentContainerRef.current?.scrollHeight });
    },
    SCROLL_BOTTOM_DEBOUNCE_TIME,
    { leading: true },
  );

  useLayoutEffect(() => {
    scrollToBottom();
  }, [data, scrollToBottom]);

  const avatars = useMemo(() => {
    return {
      assistant: (
        <Avatar
          backgroundColor={theme.colors.v1.primary['900']}
          icon={{ color: theme.colors.white, name: 'select-star', size: '18px' }}
          p={0.5}
          size={theme.space(4)}
        />
      ),
      user: <Avatar {...(user?.mappedAvatar ?? {})} size="32px" />,
    };
  }, [user?.mappedAvatar]);

  return (
    <>
      <MetadataDecorator title="Ask Select Star AI" />
      <GridContainer
        ref={contentContainerRef}
        compDisplay="flex"
        compHeight="100%"
        flexDirection="column"
        fluid
        hPaddingSpace={0}
        vPaddingSpace={0}
      >
        {isChatExist ? (
          <>
            <Box pt={5} px={5}>
              <ChatBotPageTitle />
            </Box>
            <HR mb={3} mt={2} />
          </>
        ) : (
          <Box alignItems="center" compDisplay="flex" flexGrow={1} justifyContent="center" p={5}>
            <ChatBotInitialPrompts onSelect={handlePromptSelect} />
          </Box>
        )}
        <Box
          compWidth="100%"
          flexGrow={isChatExist ? 1 : 0}
          m="auto"
          maxWidth={px(theme.breakpoints.lg)}
          pb={3}
          pr={5}
          px={5}
        >
          {data?.messages?.map((message) => {
            const loading = message.guid === CustomMsgType.streaming && !message.content;
            const isStreamingError =
              message.guid === CustomMsgType.streaming && isMsgStreamingError;
            const isOptimisticMsg = message.guid === CustomMsgType.optimistic;
            const isOptimisticMsgError = isOptimisticMsg && isMsgStreamingError;

            return (
              <ChatBotMessage
                key={message?.guid}
                avatar={avatars[message.role]}
                content={isStreamingError ? STREAMING_ERROR_MESSAGE : message?.content}
                error={isStreamingError || isOptimisticMsgError}
                loading={loading}
                messageRole={message?.role}
                title={message.role === 'assistant' ? 'Select Star' : 'You'}
              >
                <ChatBotMessageToolbar
                  content={message?.content}
                  conversationId={guid}
                  id={message?.guid}
                  messageRole={message?.role}
                  rating={message?.rating}
                />
              </ChatBotMessage>
            );
          })}
        </Box>
        <Box
          backgroundColor="white"
          bottom={0}
          compDisplay="flex"
          compWidth="100%"
          flexWrap="wrap"
          gap={1}
          m="auto"
          maxWidth={px(theme.breakpoints.lg)}
          pb={5}
          position="sticky"
          px={5}
        >
          {isError ? (
            <Box
              compDisplay="flex"
              compWidth="100%"
              flexWrap="wrap"
              gap={1}
              justifyContent="center"
            >
              {isCreateNewChatError && (
                <Box compWidth="100%" role="alert" textAlign="center">
                  <Text color="error.500" fontSize="body2" mb={0}>
                    {CREATE_NEW_CHAT_ERROR_MESSAGE}
                  </Text>
                </Box>
              )}
              {isConversationFetchingError && (
                <Box compWidth="100%" role="alert" textAlign="center">
                  <Text color="error.500" fontSize="body2" mb={0}>
                    {FETCH_CONVERSATION_ERROR_MESSAGE}
                  </Text>
                </Box>
              )}
              <Button
                onClick={() => {
                  if (isConversationFetchingError) {
                    refetch();
                    setErrorByGuid(guid, 'conversation', false);
                  } else {
                    const optimisticMsg = data?.messages?.find(
                      (m) => m.guid === CustomMsgType.optimistic,
                    )?.content;

                    sendMessage(optimisticMsg || pendingPrompt.current);
                    setErrorByGuid(guid, 'stream', false);
                  }
                }}
                startIcon={<Icon name="refresh" />}
              >
                Retry
              </Button>
            </Box>
          ) : (
            <StyledChatBotPageFormWrapper
              alignItems="center"
              borderRadius="xl"
              boxShadow="lg"
              compDisplay="flex"
              compWidth="inherit"
              gap={1}
              isError={isCharsLengthExceeded}
              position="relative"
              px={0.5}
              py={0.25}
            >
              <Form onSubmit={handleSubmit} pr={3}>
                <Textarea
                  autoHeight
                  borderWidth={0}
                  maxHeight="200px"
                  name="prompt"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Ask me something..."
                  placeholderFontWeight="medium"
                  resize="none"
                  value={values.prompt}
                />
                <Box
                  alignItems="center"
                  bottom={0}
                  compDisplay="flex"
                  position="absolute"
                  right={0}
                  top={0}
                >
                  {isCreateNewChatLoading || isConversationLoading ? (
                    <CircularLoader borderWidth={2} color={theme.colors.gray[500]} compSize={3} />
                  ) : (
                    <IconButton
                      disabled={isLoading}
                      iconColor={theme.colors.v1.gray[500]}
                      iconName="send-outlined"
                      iconSize="24px"
                      size="lg"
                      type="submit"
                    />
                  )}
                </Box>
              </Form>
            </StyledChatBotPageFormWrapper>
          )}
          <Text
            color={isCharsLengthExceeded ? 'error.500' : 'primary.500'}
            fontSize="sm"
            pl={1.25}
            role={isCharsLengthExceeded ? 'alert' : undefined}
          >
            {formatNumber(promptTrimmed.length)} / {formatNumber(MAX_CHARS_ALLOWED)} characters
            {isCharsLengthExceeded &&
              `. Please enter less than ${formatNumber(MAX_CHARS_ALLOWED)} characters.`}
          </Text>
        </Box>
      </GridContainer>
    </>
  );
};

export default ChatBotPage;
