import React, { forwardRef, InputHTMLAttributes, useMemo } from 'react';
import uniqueId from 'lodash/uniqueId';

import Box from '@components/Box';

import type { StyledInputProps } from './Input.v1.styles';
import { HelperText, StyledInput, StyledInputField } from './Input.v1.styles';

export interface InputProps
  extends Partial<StyledInputProps>,
    Pick<
      InputHTMLAttributes<HTMLInputElement>,
      | 'autoFocus'
      | 'defaultValue'
      | 'disabled'
      | 'max'
      | 'min'
      | 'name'
      | 'onChange'
      | 'onKeyDown'
      | 'placeholder'
      | 'required'
      | 'role'
      | 'type'
      | 'value'
      | 'maxLength'
      | 'aria-label'
      | 'onBlur'
      | 'id'
    > {
  endIcon?: React.ReactNode;
  error?: boolean;
  helperText?: React.ReactNode;
  readOnly?: boolean;
  startIcon?: React.ReactNode;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      'aria-label': ariaLabel,
      autoFocus,
      compSize = 'default',
      defaultValue,
      disabled,
      endIcon = null,
      error,
      helperText,
      hideFocusState = false,
      max,
      maxLength,
      min,
      name,
      onBlur,
      onChange,
      onKeyDown,
      placeholder,
      required,
      role = 'textbox',
      startIcon = null,
      type = 'text',
      value,
      variantColor = 'white',
      ...other
    },
    ref,
  ) => {
    const helperTextId = helperText ? uniqueId(`helper-text-`) : '';
    const state = useMemo(() => {
      if (error) return 'error';
      if (disabled) return 'disabled';
      return 'default';
    }, [error, disabled]);

    return (
      <Box compDisplay="flex" flexDirection="column" {...other}>
        <StyledInput
          compSize={compSize}
          hideFocusState={hideFocusState}
          state={state}
          variantColor={variantColor}
        >
          {startIcon}
          <StyledInputField
            ref={ref}
            aria-describedby={helperTextId}
            aria-label={ariaLabel}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocus}
            defaultValue={defaultValue}
            disabled={disabled}
            max={max}
            maxLength={maxLength}
            min={min}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            required={required}
            role={role}
            type={type}
            value={value}
          />
          {endIcon}
        </StyledInput>
        {helperText && (
          <HelperText id={helperTextId} state={state}>
            {helperText}
          </HelperText>
        )}
      </Box>
    );
  },
);

export default Input;
