import React from 'react';

import { RelatedDashboardModel } from '@api/dashboards/RelatedDashboardModel';
import { useFetchExploreRelatedDashboards } from '@api/explores';
import { useFetchLookMLViewRelatedDashboards } from '@api/lookML';
import { useFetchTableauDataSourceRelatedDashboards } from '@api/tableau';
import { useFetchTableRelatedDashboards } from '@api/tables';
import Box from '@components/Box';
import { RelatedDashboardsList } from '@components/RelatedTableList';
import TabError from '@components/TabContent/TabError';
import RelatedDashboardsTable, {
  RELATED_DASHBOARDS_TABLE_SEARCH_CONFIG,
  RELATED_DASHBOARDS_TABLE_SORT_CONFIG,
} from '@components/Table/RelatedDashboardsTable';
import { ColumnKey } from '@components/Table/RelatedDashboardsTable/RelatedDashboardsTable';
import { TabContentProps } from '@components/Tabs/types';
import flags from '@features';
import type DataTypesModel from '@models/DataTypesModel';
import { Filter } from '@utils';
import stripSpaces from '@utils/stripSpaces';

import type { RelatedDashboardsConfigItem } from './config';
import RELATED_DASHBOARDS_CONFIG from './config';
import ExportToCsv from './ExportToCsv';

const relatedDashboardConfig: Filter.FilterOptions = {
  page: 1,
  page_size: 100,
  query: stripSpaces(
    `{
      distance,
      dashboard{
        is_hidden,
        guid,
        name,
        data_type,
        last_viewed_at,
        dsuser_created_by{
          guid,
          name,
          display_name,
          email,
          user{
            -is_invitation_pending,
            -settings,
            -team
          },
          data_source
        },
        top_dsuser{
          guid,
          name,
          display_name,
          email,
          user{
            -is_invitation_pending,
            -settings,
            -team
          },
          data_source
        },
        dashboard_updated_at,
        last_run_at,
        data_types,
        popularity{
          popularity,
          user_count,
          view_count,
          timeframe,
          service_query_count
        }
      }
    }`,
  ),
};

export interface RelatedDashboardsProps extends TabContentProps {
  assetName: string;
  dataTypes?: DataTypesModel;
  guid: string;
  objectType?: 'explore' | 'table' | 'column' | 'dashboard' | 'lookmlview' | 'tableaudatasource';
  stickyHeader?: boolean;
}

const RelatedDashboards: React.FC<RelatedDashboardsProps> = ({
  assetName,
  dataTypes,
  guid,
  objectType,
  stickyHeader,
}) => {
  const showDistance = objectType === 'table';
  const objectTypeTableOrder = showDistance
    ? RELATED_DASHBOARDS_TABLE_SORT_CONFIG.distance
    : '-popularity';

  const FilterService = Filter.useUpdateFilters(
    { ...relatedDashboardConfig, order: objectTypeTableOrder },
    RELATED_DASHBOARDS_TABLE_SEARCH_CONFIG,
    {
      ...RELATED_DASHBOARDS_TABLE_SORT_CONFIG,
      popularity: showDistance ? 'popularity,distance' : 'popularity',
    },
    objectTypeTableOrder,
  );
  const { filter } = FilterService;

  const shouldIncludeHidden = true;
  const filterParams = Filter.setParams(filter);
  const orderParam = filterParams.order;

  const {
    data: tableData,
    isError: isTableError,
    isLoading: isTableLoading,
    refetch,
  } = useFetchTableRelatedDashboards(guid, {
    enabled: objectType === 'table',
    params: {
      ...filterParams,
      include_hidden: shouldIncludeHidden,
    },
  });

  const {
    data: exploreData,
    isError: isExploreError,
    isLoading: isExploreLoading,
  } = useFetchExploreRelatedDashboards(guid, {
    enabled: objectType === 'explore',
    params: {
      ...filterParams,
      include_hidden: shouldIncludeHidden,
    },
  });

  const {
    data: lookmlViewData,
    isError: isLookMLViewError,
    isLoading: isLookMLViewLoading,
  } = useFetchLookMLViewRelatedDashboards(guid, {
    enabled: objectType === 'lookmlview',
    params: {
      ...filterParams,
      include_hidden: shouldIncludeHidden,
    },
  });

  const {
    data: tableauDSData,
    isError: isTableauDSError,
    isLoading: isTableauDSLoading,
  } = useFetchTableauDataSourceRelatedDashboards(guid, {
    enabled: objectType === 'tableaudatasource',
    params: {
      ...filterParams,
      include_hidden: shouldIncludeHidden,
    },
  });

  const isLoading = isTableLoading || isExploreLoading || isLookMLViewLoading || isTableauDSLoading;

  const isError = isTableError || isExploreError || isLookMLViewError || isTableauDSError;

  if (isError) return <TabError />;

  const relatedDashboards: RelatedDashboardModel[] | undefined = !isLoading
    ? tableData?.results ??
      exploreData?.results ??
      lookmlViewData?.results ??
      tableauDSData?.results
    : undefined;

  const itemCount: number = !isLoading
    ? tableData?.count ?? exploreData?.count ?? lookmlViewData?.count ?? tableauDSData?.count ?? 0
    : 0;

  const config = dataTypes?.findConfig<RelatedDashboardsConfigItem>(RELATED_DASHBOARDS_CONFIG);
  const component =
    objectType === 'column' ? (
      <RelatedDashboardsList items={relatedDashboards} />
    ) : (
      <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={2}>
        {flags.lineage_list_export_csv && objectType !== 'dashboard' && (
          <Box compDisplay="flex" justifyContent="flex-end" mt={1}>
            <ExportToCsv
              assetName={assetName}
              guid={guid}
              includeHidden={shouldIncludeHidden}
              objectType={objectType}
              order={orderParam}
            />
          </Box>
        )}
        <RelatedDashboardsTable
          customColumProps={config?.customColumProps}
          data={relatedDashboards}
          filterService={FilterService}
          itemCount={itemCount}
          loading={isLoading}
          reloadData={refetch}
          stickyHeader={stickyHeader}
          visibleColumns={[
            'search',
            'name',
            'createdBy',
            'topUser',
            ...((showDistance ? ['distance'] : []) as ColumnKey[]),
            'updatedAt',
            'lastRun',
            'popularity',
          ]}
        />
      </Box>
    );

  return component;
};

export default React.memo(RelatedDashboards);
