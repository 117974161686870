import stripSpaces from '@utils/stripSpaces';

export default stripSpaces(`{
  bifolder,
  chart_count,
  dashboard_created_at,
  dashboard_updated_at,
  data_type,
  data_types,
  description,
  downstream_objects_counts,
  richtext_description,
  dsuser_created_by {
    data_types,
    data_source,
    display_name
  },
  full_path,
  guid,
  last_query_error {
    message,
    event_on
  },
  last_run_at,
  last_successful_run,
  name,
  raw_sql,
  search_name,
  popularity,
  query_count,
  runs_count,
  tagged_items,
  upstream_objects_counts,
  url,
  view_count,
  was_last_run_successful,
  breadcrumbs,
  description_source,
  ingested_description,
  ai_description,
  user_description
}`);
