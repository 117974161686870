import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

export const StyledSearchResultCell = styled(RouterLink)`
  padding: 0.5rem 0;
  display: flex;
  flex-flow: column nowrap;
  text-decoration: none;
  transition: none;
  color: inherit;
  font-size: 0.9rem;
  line-height: 1rem;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;
