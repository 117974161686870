import React from 'react';

import { useDeleteDataSource } from '@api/dataSources/dataSources';
import invalidateCache from '@api/invalidateCache';
import Box from '@components/Box';
import { renderInfoToast } from '@components/Toast';
import { DataSourceModel } from '@models/DataSourceModel';

import DeleteConfirmationModal from './DeleteConfirmationModal';

export interface DeleteDataSourceConfirmationModalProps {
  item: DataSourceModel;
  onClose?: () => void;
  onRemove?: (dataSource: DataSourceModel) => void;
}

const DeleteDataSourceConfirmationModal: React.FC<DeleteDataSourceConfirmationModalProps> = (
  props,
) => {
  const { item, onClose, onRemove } = props;

  const { error, isLoading, mutate } = useDeleteDataSource(item.guid, {
    onSuccess: () => {
      invalidateCache((keys) => [keys.dataSources.all]);
      renderInfoToast(
        'Data source deletion has started. It will be full deleted in the next 24 hours.',
      );
      onRemove?.(item);
      onClose?.();
    },
  });

  return (
    <DeleteConfirmationModal
      error={error?.data?.detail || error?.data?.non_field_errors || error?.message}
      loading={isLoading}
      onClose={onClose}
      onRemove={() => mutate(null)}
      open
      title="Remove Data Source"
      useContext
    >
      <Box as="span">
        Are you sure you want to remove{' '}
        <Box as="span" fontWeight="bold">
          {item.name}
        </Box>
        ? All your data catalog information related to this data source will also be deleted.
      </Box>
    </DeleteConfirmationModal>
  );
};

export default DeleteDataSourceConfirmationModal;
