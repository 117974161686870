import React from 'react';
import styled from '@emotion/styled';
import { Modal, ModalHeaderProps } from 'semantic-ui-react';

import Box from '@components/Box';
import IconButton from '@components/IconButton';

interface BaseModalHeaderProps {
  onClose?: () => void;
  title?: string | React.ReactNode;
}

const StyledHeader = styled(Modal.Header)`
  color: #2c2e36;
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  border-bottom: 0;
  padding: ${({ theme }) => theme.space(1, 0)};
  margin-bottom: ${({ theme }) => theme.space(1.5)};
`;

const BaseModalHeader: React.FC<BaseModalHeaderProps & ModalHeaderProps> = ({ onClose, title }) => {
  return (
    <StyledHeader id="dialog-header">
      <Box compDisplay="flex" compWidth="100%">
        {title && (
          <Box compDisplay="flex" flexGrow={1} id="dialog-header" justifyContent="flex-start">
            {title}
          </Box>
        )}
        <Box compDisplay="flex" justifyContent="flex-end">
          {onClose && (
            <IconButton
              iconName="close"
              iconSize="16px"
              onClick={onClose}
              onKeyPress={onClose}
              tabIndex={-1}
              variant="clear"
            />
          )}
        </Box>
      </Box>
    </StyledHeader>
  );
};

export default BaseModalHeader;
