import { Expose, Type } from 'class-transformer';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import DataTypesModel from '@models/DataTypesModel';
import { urlFor } from '@utils/routing';

import { TableauTableModel } from './TableauTableModel';

export interface TableauColumnData {
  description?: string;
  guid: string;
  name: string;
}

export class TableauColumnModel {
  static objectType: string = 'tableaucolumn';

  objectType: string = TableauColumnModel.objectType;

  static typeDisplay: string = 'Tableau Column';

  typeDisplay: string = TableauColumnModel.typeDisplay;

  guid!: string;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  name!: string;

  description?: string;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }

  @Type(() => TableauTableModel)
  table?: TableauTableModel;
}
