import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Input, Table } from 'semantic-ui-react';
import { Dictionary } from 'ts-essentials';

import { useFetchDataSourceBIFoldersHierarchy } from '@api/dataSources';
import NextStepButton from '@components/DataSourceSetup/components/Buttons/NextStepButton';
import PreviousStepButton from '@components/DataSourceSetup/components/Buttons/PreviousStepButton';
import { BoxWrapper } from '@components/DataSourceSetup/components/DataSourceAddStep';
import BaseTableStyled from '@components/Table/TableStyled/BaseTableStyled';
import Checkbox from '@components/UI/Form/Checkbox';
import Icon from '@components/UI/Icon';
import { ModalFooter } from '@components/UI/Modal';
import { DataSourceModel } from '@models/DataSourceModel';
import { HierarchyModel } from '@models/HierarchyModel';
import useMultiselect from '@utils/multiselect';

import SchemaHeader from '../SchemaHeader';

interface BIFolderEditConfigTableProps {
  dataSource: DataSourceModel;
  onSave: (folders: HierarchyModel[]) => void;
  prevStep: () => void;
}

const BIFolderEditConfigTable: React.FC<BIFolderEditConfigTableProps> = ({
  dataSource,
  onSave,
  prevStep,
}) => {
  const { guid, name } = dataSource;

  const { data: biFolders, isLoading: foldersLoading } = useFetchDataSourceBIFoldersHierarchy(
    guid,
    { cacheTime: 0 },
  );

  const [filter, setFilter] = useState('');
  const [foldersList, setFoldersList] = useState<Record<string, HierarchyModel>>({});
  const filteredFolders: Record<string, HierarchyModel> = useMemo(() => {
    if (filter) {
      const filterLowercase = filter.trim().toLowerCase();
      return Object.fromEntries(
        Object.entries(foldersList).filter(([hierarchicalFolderName]) =>
          hierarchicalFolderName.toLowerCase().includes(filterLowercase),
        ),
      );
    }
    return foldersList;
  }, [foldersList, filter]);
  const [childrenNodeDict, setChildrenNodeDict] = useState<Dictionary<string[]>>({});

  const { isSelected, resetSelection, selectedItems, toggleSelect, toggleSelectAll } =
    useMultiselect<string>(
      Object.keys(filteredFolders),
      Object.entries(foldersList)
        .filter(([, folder]) => folder.shouldIngest!)
        .map(([hierarchicalFolderName]) => hierarchicalFolderName),
    );

  const flattenTree = (treeNode: HierarchyModel, parent?: HierarchyModel, parentName?: string) => {
    const arr: [string, HierarchyModel][] = [];
    let hierarchicalNodeName = treeNode.name!;
    if (parent && parentName) {
      if (parent.objectType !== 'datasource') {
        hierarchicalNodeName = `${parentName} / ${treeNode?.name}`;
      }
      arr.push([hierarchicalNodeName, treeNode]);
    }
    if (treeNode.children) {
      const children: [string, HierarchyModel][] = [];
      for (let i = 0; i < treeNode.children.length; i += 1) {
        children.push(...flattenTree(treeNode.children[i], treeNode, hierarchicalNodeName));
      }
      setChildrenNodeDict((prevChildrenNodeDict) => ({
        ...prevChildrenNodeDict,
        [hierarchicalNodeName]: arr.map(([childName]) => childName),
      }));
      arr.push(...children);
    }
    return arr;
  };

  useEffect(() => {
    resetSelection(
      Object.entries(foldersList)
        .filter(([, folder]) => folder.shouldIngest)
        .map(([hierarchicalFolderName]) => hierarchicalFolderName),
    );
  }, [biFolders, foldersList]);

  useEffect(() => {
    if (!foldersLoading && biFolders) {
      setFoldersList(Object.fromEntries(flattenTree(biFolders.data!)));
    }
  }, [foldersLoading]);

  const handleRowClick = (itemGuid: string, clickedIndex: number, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const isShift = e.shiftKey;
    toggleSelect(itemGuid, clickedIndex, isShift);
    const children: string[] = childrenNodeDict[itemGuid] || [];
    if (children.length > 0) {
      toggleSelectAll(children);
    }
  };

  const handleSave = () => {
    Object.entries(foldersList).forEach(([hierarchicalFolderName, folder]) => {
      // eslint-disable-next-line no-param-reassign
      folder.shouldIngest = isSelected(hierarchicalFolderName);
    });
    onSave(Object.values(foldersList));
  };

  const allChecked =
    !!filteredFolders.length &&
    Object.entries(filteredFolders).every(([hierarchicalFolderName]) =>
      isSelected(hierarchicalFolderName),
    );

  return (
    <>
      <SchemaHeader name={name} selectedCount={selectedItems.size} />
      <BoxWrapper pt={0}>
        <BaseTableStyled>
          <Table basic="very" compact selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing>
                  <Checkbox
                    checked={allChecked}
                    indeterminate={!allChecked && selectedItems.size > 0}
                    onClick={() => toggleSelectAll(Object.keys(filteredFolders))}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell />
                <Table.HeaderCell>
                  <Input
                    className="filter-input"
                    fluid
                    onChange={(_, { value }) => setFilter(value)}
                    placeholder="Filter"
                    size="small"
                    value={filter}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.entries(filteredFolders).map(([hierarchicalFolderName, folder], index) => (
                <Table.Row
                  key={hierarchicalFolderName}
                  className={clsx(isSelected(hierarchicalFolderName) && 'selected-row')}
                  onClick={(e: React.MouseEvent) =>
                    handleRowClick(hierarchicalFolderName, index, e)
                  }
                >
                  <Table.Cell collapsing>
                    <Checkbox checked={isSelected(hierarchicalFolderName)} />
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name={folder.dataTypes?.icons.dataType!} />
                  </Table.Cell>
                  <Table.Cell>{hierarchicalFolderName}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </BaseTableStyled>
      </BoxWrapper>
      <ModalFooter>
        <PreviousStepButton disabled={foldersLoading} onClick={prevStep} />
        <NextStepButton disabled={foldersLoading} onClick={handleSave} />
      </ModalFooter>
    </>
  );
};

export default BIFolderEditConfigTable;
