import type { GetDbtProjectListQueryParams } from '@api/openapi.generated';
import paginatedTransform from '@api/paginatedTransform';
import { paginatedSelect, rawSelect } from '@api/utils';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import { PaginatedResponse } from '@models/PaginatedResponse';

import rawTransform from '../rawTransform';

import * as cacheKeys from './cacheKeys';
import DbtProjectModel from './DbtProjectModel';
import type { DbtProjectModelV1 } from './DbtProjectModel.v1';
import mapDbtProjectModel from './DbtProjectModel.v1';
import DbtRunModel from './DbtRunModel';
import DbtTestModel from './DbtTestModel';
import DbtWarehouseLinkModel from './DbtWarehouseLinkModel';

export const fetchDbtProjectsSelect = paginatedSelect(mapDbtProjectModel);
export const useFetchDbtProjects = (
  options?: UseFetchOptions<
    PaginatedResponse<DbtProjectModelV1>,
    GetDbtProjectListQueryParams & { query?: string }
  >,
) => {
  return useFetch({
    ...options,
    queryKey: [...cacheKeys.projects, options?.params],
    select: fetchDbtProjectsSelect,
    url: `/dbt/project/`,
  });
};

const fetchDbtProjectV1Select = rawSelect(mapDbtProjectModel);
export const useFetchDbtProjectV1 = (
  guid: string,
  options?: UseFetchOptions<DbtProjectModelV1>,
) => {
  return useFetch({
    ...options,
    queryKey: [...cacheKeys.project(guid), options?.params],
    select: fetchDbtProjectV1Select,
    url: `/dbt/project/${guid}/`,
  });
};

export const usePatchDbtProject = (id: string, options?: UseMutationOptions) => {
  return useMutation<DbtTestModel>({
    ...options,
    method: 'PATCH',
    url: `/dbt/project/${id}/`,
  });
};

export const usePostDbtProject = (options?: UseMutationOptions) => {
  return useMutation<DbtTestModel>({
    ...options,
    method: 'POST',
    url: `/dbt/project/`,
  });
};

export const useDeleteDbtProject = (id: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    url: `/dbt/project/${id}/`,
  });
};

const fetchDbtProjectSelect = rawTransform(DbtProjectModel);
export const useFetchDbtProject = (
  dataSourceGuid: string,
  options?: UseFetchOptions<DbtProjectModel>,
) => {
  return useFetch<DbtProjectModel>({
    ...options,
    queryKey: cacheKeys.project(dataSourceGuid),
    select: fetchDbtProjectSelect,
    url: `/dbt/project-deprecated/${dataSourceGuid}/`,
  });
};

const fetchDbtWarehouseLinkSelect = paginatedTransform(DbtWarehouseLinkModel);
export const useFetchDbtWarehouseLink = (
  guid?: string,
  options?: UseFetchOptions<PaginatedResponse<DbtWarehouseLinkModel>>,
) => {
  return useFetch<PaginatedResponse<DbtWarehouseLinkModel>>({
    ...options,
    queryKey: [...cacheKeys.warehouseLink(guid!), options?.params],
    select: fetchDbtWarehouseLinkSelect,
    url: `/dbt/warehouse-link/${guid}/`,
  });
};

const fetchDbtTestSelect = rawTransform(DbtTestModel);
export const useFetchDbtTest = (id: string, options?: UseFetchOptions<DbtTestModel>) => {
  return useFetch<DbtTestModel>({
    ...options,
    queryKey: cacheKeys.test(id),
    select: fetchDbtTestSelect,
    url: `/dbt/test/${id}/`,
  });
};

export const usePatchDbtTest = (id: string, options: UseMutationOptions) => {
  return useMutation<DbtTestModel>({
    ...options,
    method: 'PATCH',
    url: `/dbt/test/${id}/`,
  });
};

const fetchDbtRunsSelect = paginatedTransform(DbtRunModel);
export const useFetchDbtRuns = (options?: UseFetchOptions<PaginatedResponse<DbtRunModel>>) => {
  return useFetch<PaginatedResponse<DbtRunModel>>({
    ...options,
    queryKey: [...cacheKeys.runs, options?.params],
    select: fetchDbtRunsSelect,
    url: `/dbt/test/runs/`,
  });
};

export const fetchDbtTestsSelect = paginatedTransform(DbtTestModel);
export const useFetchDbtTests = (options?: UseFetchOptions<PaginatedResponse<DbtTestModel>>) => {
  return useFetch<PaginatedResponse<DbtTestModel>>({
    ...options,
    queryKey: [...cacheKeys.tests, options?.params],
    select: fetchDbtTestsSelect,
    url: `/dbt/tests/`,
  });
};
