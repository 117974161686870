import React, { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useHistory } from 'react-router-dom';

import Icon from '@components/UI/Icon';
import { HierarchyData } from '@models/HierarchyModel';
import { HierarchyState } from '@models/HierarchyState';

import NodeIcon from './NodeIcon';
import NodeLink from './NodeLink';
import { StyledTreeNode } from './TreeNodeStyle';

/* A list of node guids that will not display carets Eg. All Metrics */
const noCaretList: string[] = ['all-tags', 'all-metrics'];
/* A list of node guids with no icons. Eg category tag containers */
const noIconList: string[] = ['category_tag_container', 'status_tag_container', 'apps-menu-item'];

interface Props {
  filter?: string;
  level: number;
  node: HierarchyData;
  path: string[];
  state: HierarchyState;
}

const TreeNode: React.FC<Props> = (props) => {
  const { filter = '', level, node, path, state } = props;
  const history = useHistory();
  const [, refresh] = useState();

  useEffect(() => {
    // @ts-expect-error
    state.subscribe(path, () => refresh({}));
    return () => {
      state.unsubscribe(path);
    };
  });

  useEffect(() => {
    /*
     * refresh component when state prop changes
     * this is a temporary fix to avoid race conditions
     * where state updates after the component tree
     */
    // @ts-expect-error
    refresh({});
  }, [state]);

  const handleExpand = React.useCallback(() => {
    if (node.children && node.children.length) {
      state.toggleOpen(path);
    }
  }, [node.children, path, state]);

  const handleOpen = React.useCallback(
    (url?: string) => {
      if (url) {
        history.push(url);
        return;
      }

      handleExpand();
    },
    [handleExpand, history],
  );

  const onExpand = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();
      handleExpand();
    },
    [handleExpand],
  );

  const onOpen = React.useCallback(
    (e: React.MouseEvent, url?: string) => {
      e.preventDefault();
      e.stopPropagation();
      if (!(e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        handleOpen(url);
      }
    },
    [handleOpen],
  );
  return (
    <>
      <StyledTreeNode level={level} title={node.name} type={node.objectType}>
        <NodeLink
          className={state && state.isActive(path) ? 'treenode-wrapper active' : 'treenode-wrapper'}
          handleOpen={handleOpen}
          node={node}
          onOpen={onOpen}
        >
          {/* Caret */}
          {!noCaretList.includes(node.guid) && (
            <NodeIcon
              onClick={onExpand}
              onKeyDown={(e) => e.key === 'Enter' && onExpand(e)}
              role="button"
              tabIndex={-1}
            >
              {!!node.children && Boolean(node.children.length) && (
                <Icon name={state.isOpen(path) ? 'caret-down' : 'caret-right'} size="14px" />
              )}
            </NodeIcon>
          )}
          {/* Icon */}
          {!noIconList.includes(node.objectType) && (
            <NodeIcon>
              {node.iconEl}
              {!node.iconEl && node?.dataTypes && (
                <Icon name={node.dataTypes.icons.dataSource} size="16px" />
              )}
            </NodeIcon>
          )}
          <span className="treenode-label">
            <Highlighter
              autoEscape
              highlightClassName="highlight"
              searchWords={filter ? filter.split(' ') : []}
              textToHighlight={node.name}
            />
          </span>
        </NodeLink>
      </StyledTreeNode>
      {state.isOpen(path) &&
        node.children.map((childNode) => (
          <TreeNode
            key={childNode.guid}
            filter={filter}
            level={level + 1}
            node={childNode}
            path={path.concat([childNode.guid])}
            state={state}
          />
        ))}
    </>
  );
};

export default React.memo(TreeNode);
