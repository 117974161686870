import React from 'react';

import { TagModel } from '@api/tags/TagModel';
import Box from '@components/Box';
import DocsButton from '@components/Button/BIButtons/DocsButton';
import Modal, { ModalHeader } from '@components/UI/Modal';
import { useModal } from '@context/Modal';

import CreateTagContent from './CreateTagContent';
import type { CreateTagContentProps } from './CreateTagContent/CreateTagContent';

export interface CreateTagModalProps extends CreateTagContentProps {
  onAddTag?: () => void;
  onClose?: () => void;
}

const CreateTagModal: React.FC<CreateTagModalProps> = ({
  initialState,
  isEdit,
  onAddTag,
  onClose,
}) => {
  const { MODAL_IDS, openModal } = useModal();

  const handleAddTag = (newTag: TagModel) => {
    onAddTag?.();
    if (!isEdit) {
      openModal(MODAL_IDS.createAnotherTag, newTag);
    }
    onClose?.();
  };

  return (
    <Modal id="create-tag-modal" onClose={onClose} open useContext>
      <ModalHeader
        onClose={onClose}
        title={
          <Box alignItems="center" compDisplay="flex">
            <Box mr={1}>{isEdit ? 'Edit Tag' : 'Create New Tag'}</Box>
            <DocsButton
              href="https://docs.selectstar.com/data-management/tag-management"
              rel="noopener noreferrer"
              target="_blank"
            />
          </Box>
        }
      />
      <CreateTagContent
        initialState={initialState}
        isEdit={isEdit}
        onAddTag={handleAddTag}
        onClose={onClose}
      />
    </Modal>
  );
};

export default CreateTagModal;
