import React, { useState } from 'react';
import { Cell } from 'react-table';

import { LookerExploreModel } from '@api/explores/LookerExploreModel';
import { MetricModel } from '@api/metrics/MetricModel';
import { TableModel } from '@api/tables/TableModel';
import DateTime from '@components/DateTime';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import type { ColumnConfig } from '@components/Table/Table/types';
import { Filter } from '@utils';

import LinkedCell from '../Cells/LinkedCell';
import UserCell from '../Cells/UserCell';
import Table from '../Table';
import { sortDates } from '../Table/sortDates';
import TableStyled from '../TableStyled';

interface Props {
  data?: any[];
  filterService?: Filter.FilterServiceInterface;
  itemCount?: number;
  loading?: boolean;
  stickyHeader?: boolean;
  totalPages?: number;
}

export type OwnedItemsType = TableModel | LookerExploreModel | MetricModel;

const OwnsTable: React.FC<Props> = ({
  data,
  filterService,
  itemCount = 0,
  loading,
  stickyHeader,
  totalPages = 0,
}) => {
  const [isShowFilter, setShowFilter] = useState(false);
  const toggleFilter = React.useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);
  const { search } = filterService || {};
  const columns: ColumnConfig<OwnedItemsType>[] = React.useMemo(
    () => [
      ...(filterService
        ? [
            {
              Header: SearchHeader,
              disableFilters: true,
              disableResizing: true,
              disableSortBy: true,
              id: 'search',
              width: 32,
            },
          ]
        : []),
      {
        Cell: (props: Cell<OwnedItemsType>) => {
          const { row } = props;
          const item = row.original;
          return <LinkedCell item={item} showDataSourceIcon showIcon showNameTooltip />;
        },
        Header: `Name (${itemCount})`,
        accessor: (d) => d?.name,
        disableFilters: !filterService,
        id: 'dataObject',
        width: '170%',
      },
      {
        Cell: (props: Cell<OwnedItemsType>) => {
          const { row } = props;
          const item = row.original;
          return <UserCell {...props} user={item.businessOwner?.obj} />;
        },
        Header: () => 'Business Owner',
        accessor: (d) => d?.businessOwner?.obj?.fullName,
        disableFilters: true,

        id: 'businessOwner',
        width: '110%',
      },
      {
        Cell: (props: Cell<OwnedItemsType>) => {
          const { row } = props;
          const item = row.original;
          return <UserCell {...props} user={item.technicalOwner?.obj} />;
        },
        Header: () => 'Technical Owner',
        accessor: (d) => d?.technicalOwner?.obj?.fullName,
        disableFilters: true,
        id: 'technicalOwner',
        width: '110%',
      },
      {
        Cell: ({ row: { original } }: Cell<OwnedItemsType>) => {
          if (original.objectType === 'explore') return null;
          const item = original as any;
          const date = item?.lastUpdatedOn ?? item?.updatedOn;
          return <DateTime datetime={date} />;
        },
        Header: 'Last Updated',
        accessor: (d: any) => {
          if (d.objectType === 'explore') return null;

          const date = d?.lastUpdatedOn ?? d?.updatedOn;
          return date?.toDate();
        },
        disableFilters: true,
        id: 'lastUpdatedOn',
        sortDescFirst: true,
        sortType: sortDates,
        width: '110%',
      },
    ],
    [filterService, itemCount],
  );

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={filterService?.changePage}
        className="table-full"
        columns={columns}
        data={data ?? []}
        disableColumnFiltering
        disableRowSelect
        loading={loading}
        manualFilters
        manualPagination
        setFilters={search}
        showFilter={isShowFilter}
        singleLine
        sortable
        stickyHeader={stickyHeader}
        toggleFilter={toggleFilter}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default OwnsTable;
