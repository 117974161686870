import React from 'react';
import * as CSS from 'csstype';
import type { UseComboboxGetInputPropsOptions } from 'downshift';

import Box from '@components/Box';
import { HelperText, type StyledInputProps } from '@components/Input/Input.v1.styles';
import { UsePopperProps } from '@components/Popper/usePopper';
import { TreeKey } from '@components/Tree/Tree.v2/types';
import { IconProps } from '@components/UI/Icon';
import useClickOutside from '@hooks/useClickOutside';

import CaretButton from '../CaretButton';
import ClearSelectButton from '../ClearSelectButton';
import MainInput from '../MainInput/MainInput';
import OptionsList from '../OptionsList';
import type {
  MultiOptionTypes,
  Option as OptionType,
  RenderCustomAnchorArgs,
  SelectValue,
} from '../types';
import type { UseSelectProps } from '../useSelect';
import useSelect from '../useSelect';

import { StyledSelect } from './Select.styles';

export interface SelectProps
  extends Partial<Omit<StyledInputProps, 'status' | 'hideFocusState' | 'state' | 'zIndex'>>,
    Pick<UseSelectProps, 'containerId'> {
  clearInputOnSelect?: boolean;
  error?: boolean;
  expandedKeys?: TreeKey[];
  getClickOutsideExcludedTargets?: () => NodeListOf<Element> | HTMLElement[] | null | undefined;
  helperText?: string;
  hideCaret?: boolean;
  initialIsOpen?: boolean;
  inputId?: string;
  inputProps?: UseComboboxGetInputPropsOptions;
  isCreatable?: boolean;
  isDisabled?: boolean;
  isDropdown?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  isOpen?: boolean;
  isTree?: boolean;
  label?: string;
  leftIcon?: IconProps['name'];
  loadingPosition?: 'input' | 'options-list';
  maxOptionsVisible?: number;
  multiOptionsType?: MultiOptionTypes;
  name?: string;
  newOptionValidator?: (newOption: OptionType) => boolean;
  onChange?: (newValue: SelectValue) => void;
  onClose?: () => void;
  onInputBlur?: () => void;
  onNewOption?: (newOption: OptionType) => void;
  onSearchValueChange?: (text?: string) => void;
  optionListMaxHeight?: CSS.Property.MaxHeight;
  options?: OptionType[];
  optionsFitAnchorWidth?: boolean;
  optionsListFooterElement?: React.ReactNode;
  placeholder?: string;
  popperConfigProps?: UsePopperProps;
  renderCustomAnchor?: (args: RenderCustomAnchorArgs) => React.ReactNode;
  renderEmptyMessage?: (searchInputValue?: string) => React.ReactNode;
  searchPlaceholder?: string;
  searchValue?: string;
  setIsOpen?: (isOpen?: boolean) => void;
  shouldBlockOnLoading?: boolean;
  showClearButton?: boolean;
  showClearSelection?: boolean;
  showSearchOnOptions?: boolean;
  showSelectAllButton?: boolean;
  showSelectAllCount?: boolean;
  value?: SelectValue;
  wrapOptionText?: OptionType['wrapOptionText'];
}

const Select: React.FC<SelectProps> = ({
  clearInputOnSelect = true,
  containerId,
  error,
  expandedKeys,
  getClickOutsideExcludedTargets,
  helperText,
  hideCaret = false,
  initialIsOpen,
  inputId,
  inputProps: customInputProps,
  isCreatable = false,
  isDisabled = false,
  isDropdown = false,
  isLoading = false,
  isMulti = false,
  isOpen: propIsOpen,
  isTree = false,
  label,
  leftIcon,
  loadingPosition = 'input',
  maxOptionsVisible,
  multiOptionsType,
  newOptionValidator,
  onChange,
  onClose,
  onInputBlur,
  onNewOption,
  onSearchValueChange,
  optionListMaxHeight,
  options = [],
  optionsFitAnchorWidth,
  optionsListFooterElement,
  placeholder = 'Select',
  popperConfigProps,
  renderCustomAnchor,
  renderEmptyMessage,
  searchPlaceholder = 'Search',
  searchValue,
  setIsOpen,
  shouldBlockOnLoading = true,
  showClearButton = false,
  showClearSelection = false,
  showSearchOnOptions = false,
  showSelectAllButton = true,
  showSelectAllCount = true,
  value,
  wrapOptionText = true,
  ...other
}) => {
  const isMultiOptions = isDropdown ? false : isMulti;
  const {
    addSelectedItem,
    closeMenu,
    dropdownProps,
    fieldState,
    filteredItems,
    flatOptions,
    getInputProps,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    inputValue,
    isAllSelected,
    isOpen,
    popper: { anchorProps, popperProps },
    removeSelectedItem,
    resetSelect,
    selectedItems,
    setInputValue,
    setSelectedItems,
    updateFlatOptions,
  } = useSelect({
    clearInputOnSelect,
    closeOnBlur: !getClickOutsideExcludedTargets,
    containerId,
    error,
    expandedKeys,
    initialIsOpen,
    isDisabled,
    isDropdown,
    isLoading,
    isMulti: isMultiOptions,
    isOpen: propIsOpen,
    isTree,
    onChange,
    onClose,
    onInputBlur,
    onSearchValueChange,
    options,
    optionsFitAnchorWidth,
    popperConfigProps,
    searchValue,
    setIsOpen,
    shouldBlockOnLoading,
    value,
  });

  const selectAnchorProps = getToggleButtonProps({
    ...dropdownProps,
    ...anchorProps,
  });

  const toggleButtonProps = getToggleButtonProps({
    ...dropdownProps,
  });

  const menuProps = getMenuProps(popperProps);

  const inputProps = getInputProps({
    ...dropdownProps,
    autoFocus: isOpen,
    ...customInputProps,
  });

  const useClickOutsideRef = useClickOutside<HTMLDivElement>({
    disabled: !getClickOutsideExcludedTargets && !isOpen,
    excludedTargets: getClickOutsideExcludedTargets,
    ignoreElements: ['select'],
    onClick: () => {
      closeMenu?.();
    },
  });

  const isMultiString = isMulti && multiOptionsType === 'string';

  return (
    <>
      {renderCustomAnchor?.({
        anchorProps: selectAnchorProps,
        anchorRef: useClickOutsideRef,
        isOpen,
        selectedItem: selectedItems,
      }) ?? (
        <Box compDisplay="flex" compWidth="100%" flexDirection="column" noDefault>
          <StyledSelect
            aria-disabled={isDisabled}
            aria-label={label}
            cursor={isDropdown ? 'pointer' : undefined}
            hideFocusState={fieldState !== 'error'}
            isMultiString={isMultiString}
            role="button"
            state={fieldState}
            {...selectAnchorProps}
            {...other}
          >
            <MainInput
              addSelectedItem={addSelectedItem}
              fieldState={fieldState}
              id={inputId}
              inputProps={inputProps}
              isCreatable={isCreatable}
              isDropdown={isDropdown}
              isLoading={isLoading && loadingPosition === 'input' && shouldBlockOnLoading}
              isMulti={isMultiOptions}
              leftIcon={leftIcon}
              multiOptionsType={multiOptionsType}
              newOptionValidator={newOptionValidator}
              onNewOption={onNewOption}
              options={options}
              placeholder={placeholder}
              removeSelectedItem={removeSelectedItem}
              selectedItems={selectedItems}
              setInputValue={setInputValue}
              showSearchOnOptions={showSearchOnOptions || isMultiString}
              value={inputValue}
            />
            {showClearButton && selectedItems.length > 0 && !isLoading && (
              <ClearSelectButton onClick={resetSelect} />
            )}
            {!hideCaret && (
              <CaretButton
                isLoading={isLoading && loadingPosition === 'input'}
                isOpen={isOpen}
                {...toggleButtonProps}
              />
            )}
          </StyledSelect>
          {helperText && <HelperText state={fieldState}>{helperText}</HelperText>}
        </Box>
      )}
      <OptionsList
        expandedKeys={expandedKeys}
        filteredItems={filteredItems}
        flatOptions={flatOptions}
        getItemProps={({ index, item }) =>
          getItemProps({
            index,
            item,
          })
        }
        highlightedIndex={highlightedIndex}
        inputProps={inputProps}
        inputValue={inputValue}
        isAllSelected={isAllSelected}
        isDropdown={isDropdown}
        isLoading={isLoading && loadingPosition === 'options-list'}
        isMulti={isMultiOptions}
        isOpen={isOpen}
        isTree={isTree}
        maxHeight={optionListMaxHeight}
        maxOptionsVisible={maxOptionsVisible}
        menuProps={menuProps}
        options={options}
        optionsListFooterElement={optionsListFooterElement}
        renderEmptyMessage={renderEmptyMessage?.(inputValue)}
        resetSelect={resetSelect}
        searchPlaceholder={searchPlaceholder}
        selectedItems={selectedItems}
        setInputValue={setInputValue}
        setSelectedItems={setSelectedItems}
        showClearSelection={showClearSelection}
        showSearch={showSearchOnOptions || isMultiString}
        showSelectAllButton={showSelectAllButton}
        showSelectAllCount={showSelectAllCount}
        updateFlatOptions={updateFlatOptions}
        wrapOptionText={wrapOptionText}
      />
    </>
  );
};

export default Select;
