import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import * as CSS from 'csstype';

import rootTheme from '@styles/theme';

export type CheckboxState = 'checked' | 'default' | 'error';
type CheckboxStatus = 'default' | 'disabled' | 'hover';

export type VariantColor = 'purple' | 'white' | 'blue' | 'pale-purple';
type VariantColorStyle = Record<CheckboxState, Record<CheckboxStatus, SerializedStyles>>;
export type VariantRadius = 'squared' | 'rounded';

const sharedVariantColorStyle = {
  default: {
    default: css`
      background-color: ${rootTheme.colors.white};
      border-color: ${rootTheme.colors.v1.gray[300]};
    `,
    disabled: css`
      background-color: ${rootTheme.colors.v1.gray[100]};
      border-color: ${rootTheme.colors.v1.gray[200]};
      cursor: auto;
    `,
    hover: css`
      border-color: ${rootTheme.colors.v1.gray[400]};
    `,
  },
  error: {
    default: css`
      background-color: ${rootTheme.colors.white};
      color: ${rootTheme.colors.error};
      border-color: ${rootTheme.colors.error};
    `,
    disabled: css`
      background-color: ${rootTheme.colors.white};
      color: ${rootTheme.colors.error};
      border-color: ${rootTheme.colors.error};
      cursor: auto;
    `,
    hover: css`
      background-color: ${rootTheme.colors.white};
      color: ${rootTheme.colors.error};
      border-color: ${rootTheme.colors.error};
    `,
  },
} as const;

const purple: VariantColorStyle = {
  checked: {
    default: css`
      background-color: ${rootTheme.colors.v1.primary[600]};
      border-color: ${rootTheme.colors.v1.primary[500]};
    `,
    disabled: sharedVariantColorStyle.default.disabled,
    hover: css`
      background-color: ${rootTheme.colors.v1.primary[700]};
      border-color: ${rootTheme.colors.v1.primary[600]};
    `,
  },
  default: sharedVariantColorStyle.default,
  error: sharedVariantColorStyle.error,
};

const palePurple: VariantColorStyle = {
  checked: {
    default: css`
      background-color: ${rootTheme.colors.v1.primary[50]};
      color: ${rootTheme.colors.v1.primary[500]};
      border-color: ${rootTheme.colors.v1.primary[500]};
    `,
    disabled: sharedVariantColorStyle.default.disabled,
    hover: css`
      background-color: ${rootTheme.colors.v1.primary[100]};
      border-color: ${rootTheme.colors.v1.primary[600]};
    `,
  },
  default: sharedVariantColorStyle.default,
  error: sharedVariantColorStyle.error,
};

const white: VariantColorStyle = {
  checked: {
    default: css`
      background-color: ${rootTheme.colors.white};
      color: ${rootTheme.colors.v1.gray[600]};
      border-color: ${rootTheme.colors.gray[200]};
    `,
    disabled: sharedVariantColorStyle.default.disabled,
    hover: css`
      color: ${rootTheme.colors.v1.gray[800]};
      background-color: ${rootTheme.colors.gray[100]};
      border-color: ${rootTheme.colors.gray[300]};
    `,
  },
  default: sharedVariantColorStyle.default,
  error: sharedVariantColorStyle.error,
};

const blue: VariantColorStyle = {
  checked: {
    default: css`
      background-color: ${rootTheme.colors.lightblue};
      color: ${rootTheme.colors.white};
      border-color: ${rootTheme.colors.lightblue};
    `,
    disabled: sharedVariantColorStyle.default.disabled,
    hover: css`
      background-color: ${rootTheme.colors.lightblue};
      color: ${rootTheme.colors.white};
      border-color: ${rootTheme.colors.lightblue};
    `,
  },
  default: sharedVariantColorStyle.default,
  error: sharedVariantColorStyle.error,
};

export const colorVariants: Record<VariantColor, VariantColorStyle> = {
  blue,
  'pale-purple': palePurple,
  purple,
  white,
};

export const radiusVariants: Record<VariantRadius, CSS.Property.BorderRadius> = {
  rounded: '50px',
  squared: '3px',
};
