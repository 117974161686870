import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import Box from '@components/Box';
import wrapString from '@utils/wrapString';

import { TabItem, Tabs, TabsContainer } from './ExploreSidebar.styles';
import SidebarInfo from './SidebarInfo';
import sidebarInit from './sidebarInit';
import SidebarTree from './SidebarTree';
import type { NodeSource, SidebarProps } from './types';

export const getDefaultDir = (traversalProps: any, allNodes: NodeSource[], nodeKey: string) => {
  const obj = allNodes.find((_) => _.key === nodeKey) || ({} as NodeSource);
  const l = traversalProps[0] as keyof NodeSource;
  const r = traversalProps[1] as keyof NodeSource;
  const leftCount: number = (obj[l] as string[]).length || 0;
  const rightCount: number = (obj[r] as string[]).length || 0;
  return leftCount >= rightCount ? 'left' : 'right';
};

const ExploreSidebar: React.FC<SidebarProps> = (props) => {
  const [direction, setDirection] = useState<'right' | 'left'>('left');
  const {
    columns,
    downstreamCount,
    nodeKey,
    showInfo = true,
    showLineageTree = true,
    type,
    upstreamCount,
    zoomOnItemClick,
  } = props;
  const { allNodes, currentNode, traversalProps } = sidebarInit(props);
  const [counts, setCounts] = useState({
    downstream: downstreamCount,
    upstream: upstreamCount,
  });

  useEffect(() => {
    if (currentNode && nodeKey) {
      setDirection(getDefaultDir(traversalProps, allNodes, nodeKey));
    }

    /**
     * This useEffect changes the current tab to be the one with more lineage.
     * If we follow ESLint recommendations, it will cause the tab to change
     * when data is loaded by other user actions (i.e. when clicking ▶).
     * Hence the ESLint exception, so it runs only when `guid` changes
     */
  }, [currentNode, nodeKey]);

  return (
    <Box compDisplay="flex" compHeight="100%" flexDirection="column">
      {showInfo && (
        <SidebarInfo
          columns={columns}
          nodeKey={nodeKey}
          onDataLoad={(data) => {
            setCounts({
              downstream: data?.downstreamObjectsCounts?.total,
              upstream: data?.upstreamObjectsCounts?.total,
            });
          }}
          type={type}
        />
      )}
      {showLineageTree && (
        <>
          <TabsContainer>
            <Tabs>
              <TabItem
                className={clsx({ active: direction === 'left' })}
                onClick={() => setDirection('left')}
              >
                Upstream {wrapString(showInfo ? counts.upstream : upstreamCount)}
              </TabItem>
              <TabItem
                className={clsx({ active: direction === 'right' })}
                onClick={() => setDirection('right')}
              >
                Downstream {wrapString(showInfo ? counts.downstream : downstreamCount)}
              </TabItem>
            </Tabs>
          </TabsContainer>
          <Box compHeight="100%" overflowY="auto">
            <SidebarTree {...props} direction={direction} zoomOnItemClick={zoomOnItemClick} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default React.memo(ExploreSidebar);
