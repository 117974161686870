import { AVAILABLE_USAGE_TYPES } from '@api/lineage/types';
import { InputNodesById } from '@components/Explore.v1/Explore.types';
import createStacks from '@components/Explore.v1/useCreateNodesEdges/utils/createStacks';

import { NodesById } from './useCreateNodesEdges/algorithm/types';
import { filterInputNodesByUsageType } from './useRemoveUsageType/useRemoveUsageType.utils';

interface MergeTableAndColumnLineageArgs {
  columnInputNodesById: InputNodesById;
  enableHorizontalGroups?: boolean;
  removeEdgesWithNoUsages?: boolean;
  startingTableId: string;
  tableNodesById?: NodesById;
}

const getFilteredColumnInputNodesById = (
  inputNodesById: InputNodesById,
  removeEdgesWithNoUsages: boolean,
) => {
  if (!removeEdgesWithNoUsages) {
    return inputNodesById;
  }

  const notEmptyUsages = AVAILABLE_USAGE_TYPES.filter((usageType) => usageType !== 'none');

  return filterInputNodesByUsageType({ inputNodesById, validUsageTypes: notEmptyUsages });
};

export const mergeTableAndColumnLineage = ({
  columnInputNodesById,
  enableHorizontalGroups,
  removeEdgesWithNoUsages = false,
  startingTableId,
  tableNodesById,
}: MergeTableAndColumnLineageArgs) => {
  const mergedNodesById: NodesById = {};

  const { nodesById: columnNodesById } = createStacks({
    inputNodesById: getFilteredColumnInputNodesById(columnInputNodesById, removeEdgesWithNoUsages),
    options: {
      enableColumnEdges: false,
      enableHorizontalGroups,
      enableTableEdges: true,
      openAll: false,
    },
    startingTableId,
  });

  Object.keys(columnNodesById).forEach((inputNodeKey) => {
    const tableInputNode = tableNodesById?.[inputNodeKey];
    const columnInputNode = columnNodesById[inputNodeKey];

    if (tableInputNode?.type === 'table') {
      mergedNodesById[inputNodeKey] = {
        ...tableInputNode,
        data: {
          ...tableInputNode.data,
          children: columnInputNode.data.children,
        },
      };
    } else if (columnInputNode?.type === 'column') {
      mergedNodesById[inputNodeKey] = columnInputNode;
    } else if (tableNodesById?.[inputNodeKey]) {
      mergedNodesById[inputNodeKey] = tableNodesById?.[inputNodeKey];
    }
  });

  return mergedNodesById;
};
