import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { ColumnData, ColumnModel } from '@api/columns/ColumnModel';

interface TableSnapshotRow extends Record<string, string | number | boolean | undefined | null> {}

export interface TableSnapshotData {
  columns: ColumnData[];
  rows: TableSnapshotRow[];
  snapshotOn: moment.Moment;
}

export class TableSnapshotModel {
  @Type(() => ColumnModel)
  columns!: ColumnModel[];

  rows!: TableSnapshotRow[];

  @Expose({ name: 'snapshot_on' })
  @Transform((value) => moment(value))
  snapshotOn!: moment.Moment;
}
