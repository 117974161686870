import React from 'react';

import Box from '@components/Box';
import { GridContainer } from '@components/Grid';
import TitleHeader from '@components/Title/TitleHeader';
import MetadataDecorator from '@utils/MetadataDecorator';

import AdminDataSources from './AdminDataSources';

const AdminDataPage: React.FC = () => {
  return (
    <>
      <MetadataDecorator title="Data Admin" />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <Box mb={5}>
          <TitleHeader>
            <span className="title big">Data</span>
          </TitleHeader>
        </Box>
        <AdminDataSources />
      </GridContainer>
    </>
  );
};

export default AdminDataPage;
