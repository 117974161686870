import paginatedTransform from '@api/paginatedTransform';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import { PaginatedResponse } from '@models/PaginatedResponse';
import { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import rawTransform from '../rawTransform';

import { AccountBindingModel } from './AccountBindingModel';
import * as cacheKeys from './cacheKeys';
import { SlackConnectedChannelsModel } from './SlackConnectedChannelsModel';
import { SlackSettingsModel } from './SlackSettingsModel';
import { SlackTeamModel } from './SlackTeamModel';

export const fetchAccountBindingSelect = rawTransform(AccountBindingModel);

export const useFetchSlackUser = (id: string, options?: UseFetchOptions<AccountBindingModel>) => {
  return useFetch<AccountBindingModel>({
    ...options,
    queryKey: [...cacheKeys.accountBinding(id), options?.params],
    select: fetchAccountBindingSelect,
    url: `/slack/account-binding/${id}/`,
  });
};

export const fetchSlackTeamSelect = rawTransform(SlackTeamModel);
export const useFetchSlackTeam = (options?: UseFetchOptions<SlackTeamModel>) => {
  return useFetch<SlackTeamModel>({
    ...options,
    queryKey: [...cacheKeys.slackTeam, options?.params],
    select: fetchSlackTeamSelect,
    url: '/slack/team/',
  });
};

export interface UsePatchSlackSettingsOptions
  extends UseMutationOptions<
    never,
    EnhancedErrorResult,
    {
      allow_link_unfurling?: boolean;
      receive_notification_type?: string;
      slack_channels_for_notifications?: string[];
    }
  > {}

export const usePatchSlackSettings = (options?: UsePatchSlackSettingsOptions) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: `/slack/settings/`,
  });
};

export const fetchSlackSettingsSelect = rawTransform(SlackSettingsModel);
export const useFetchSlackSettings = (options?: UseFetchOptions<SlackSettingsModel>) => {
  return useFetch<SlackSettingsModel>({
    ...options,
    queryKey: cacheKeys.slackSettings,
    select: fetchSlackSettingsSelect,
    url: `/slack/settings/`,
  });
};

export const fetchSlackConnectedChannelsSelect = paginatedTransform(SlackConnectedChannelsModel);
export const useFetchSlackConnectedChannels = (
  options?: UseFetchOptions<PaginatedResponse<SlackConnectedChannelsModel>>,
) => {
  return useFetch<PaginatedResponse<SlackConnectedChannelsModel>>({
    ...options,
    queryKey: cacheKeys.connectedChannels,
    select: fetchSlackConnectedChannelsSelect,
    url: `/slack/connected_channels/`,
  });
};
