import React from 'react';

import { usePostIncompleteDataSource } from '@api/dataSources';
import invalidateCache from '@api/invalidateCache';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import { DataSourceFormProps } from './types';

const QuickSightForm: React.FC<DataSourceFormProps> = ({
  children,
  dataSource,
  dataType,
  name = '',
  onSuccess,
  renderBefore,
}) => {
  const segment = useSegmentContext();
  const { error, isLoading, mutate } = usePostIncompleteDataSource({
    onSuccess: (dataSourceModel) => {
      invalidateCache((keys) => [keys.dataSources.all]);
      onSuccess?.(dataSourceModel);
    },
  });
  const { handleChange, handleSubmit, values } = useForm({
    initialValues: {
      clusterIdentifier: '',
      database: '',
      name: dataSource?.name || name,
      region: '',
    },
    onSubmit: (val) => {
      mutate({
        credentials: {
          region: val.region,
        },
        name: val.name,
        type: dataType,
      });
      segment?.track(SegmentTrackEventName.CreateServiceAccountConnectButtonClicked, {
        dataType,
      });
    },
  });

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        {renderBefore?.({ error, loading: isLoading })}
        <StyledLabel>
          Display Name
          <Input
            error={error?.data?.name}
            helperText={error?.data?.name}
            maxLength={50}
            name="name"
            onChange={handleChange}
            placeholder={name}
            type="text"
            value={values.name}
          />
        </StyledLabel>
        <StyledLabel>
          Region
          <Input
            error={error?.data?.region}
            helperText={error?.data?.region}
            name="region"
            onChange={handleChange}
            placeholder="us-east-2"
            type="text"
            value={values.region}
          />
        </StyledLabel>
      </StyledFormHorizontalLabelGrid>
      {children?.({ error, loading: isLoading })}
    </Form>
  );
};

export default QuickSightForm;
