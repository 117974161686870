import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

import invalidateCache from '@api/invalidateCache';
import { usePatchUserSettings } from '@api/settings';
import Box from '@components/Box';
import useForm from '@components/Form/useForm';
import { GridContainer } from '@components/Grid';
import TitleHeader from '@components/Title/TitleHeader';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import { useUserContext } from '@context/User';
import MetadataDecorator from '@utils/MetadataDecorator';

import AppSettingsInput from './AppSettingsInput';
import SettingsSection from './SettingsSection';
import SettingsSubSection from './SettingsSubSection';
import SlackInput from './SlackInput';
import UserSettingsInput from './UserSettingsInput';

const DATABASE_TABLE_COLUMN_ORDER_OPTIONS = [
  {
    subtitle: 'Columns are ordered by their popularity score',
    title: 'Popularity',
    value: 'popularity',
  },
  {
    subtitle: 'Columns are shown in the same order as in the connected data source',
    title: 'Column Ordinal Position',
    value: 'column_ordinal_position',
  },
  {
    subtitle: 'Columns are ordered alphabetically by name',
    title: 'Alphabetical',
    value: 'alphabetical',
  },
];

const DEFAULT_TABLE_DASHBOARD_TAB_OPTIONS = [
  {
    title: 'Overview',
    value: 'overview',
  },
  {
    title: 'Column / Charts',
    value: 'columns_charts',
  },
];

const UPDATE_DEBOUNCE_TIME = 500;

interface UserSettingsForm {
  defaultDatabaseTableColumnOrder: string;
  defaultTableDashboardTab: string;
  enableEmailNotifications: boolean;
  enableNewLineage: boolean;
  enableSlackNotifications: boolean;
  enableSlackNotificationsForMetadataChanges: boolean;
  enableSlackNotificationsForSchemaChanges: boolean;
}

const UserSettingsPage: React.FC = () => {
  const { organization, user } = useUserContext();

  const {
    error,
    isLoading,
    mutate: updateUserSettings,
  } = usePatchUserSettings({
    onError: () => {
      renderErrorToast("Couldn't save settings.");
    },
    onSuccess: () => {
      renderInfoToast('Changes saved.');
      invalidateCache((keys) => [
        keys.organizationUser.all,
        keys.organizationUser.organizationUser('me'),
        keys.user.me,
      ]);
    },
  });

  const { handleChange, values } = useForm<UserSettingsForm>({
    initialValues: {
      defaultDatabaseTableColumnOrder: user?.settings?.defaultDatabaseTableColumnOrder ?? '',
      defaultTableDashboardTab: user?.settings?.defaultTableDashboardTab ?? '',
      enableEmailNotifications: Boolean(user?.settings?.enableEmailNotifications),
      enableNewLineage: Boolean(user?.settings?.enableNewLineage),
      enableSlackNotifications: Boolean(user?.settings?.enableSlackNotifications),
      enableSlackNotificationsForMetadataChanges: Boolean(
        user?.settings?.enableSlackNotificationsForMetadataChanges,
      ),
      enableSlackNotificationsForSchemaChanges: Boolean(
        user?.settings?.enableSlackNotificationsForSchemaChanges,
      ),
    },
  });

  const update = useDebouncedCallback(() => {
    if (!isLoading && !error) {
      updateUserSettings({
        default_database_table_column_order: values.defaultDatabaseTableColumnOrder,
        default_table_dashboard_tab: values.defaultTableDashboardTab,
        enable_email_notifications: values.enableEmailNotifications,
        enable_new_lineage: values.enableNewLineage,
        enable_slack_notifications: values.enableSlackNotifications,
        enable_slack_notifications_for_metadata_changes:
          values.enableSlackNotificationsForMetadataChanges,
        enable_slack_notifications_for_schema_changes:
          values.enableSlackNotificationsForSchemaChanges,
      });
    }
  }, UPDATE_DEBOUNCE_TIME);

  const handleSettingChange = (name: string, value: string | boolean) => {
    handleChange({ target: { name, value } });
    update();
  };

  return (
    <>
      <MetadataDecorator title="User Settings" />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <Box alignItems="start" position="relative">
          <Box mb={5}>
            <TitleHeader>
              <span className="title big">User Settings</span>
            </TitleHeader>
          </Box>
          <Box compDisplay="flex" flex={1} flexDirection="column" gap={5.25}>
            <SettingsSection
              subtitle="Users are notified for changes on objects they own, or if they are mentioned in a comment.
          Notifications are always shown in-app."
              title="Notification settings"
            >
              <SettingsSubSection>
                <AppSettingsInput title="Notification type">
                  <UserSettingsInput
                    checked={values.enableEmailNotifications}
                    onChange={(newValue: boolean) =>
                      handleSettingChange('enableEmailNotifications', newValue)
                    }
                    title="Email"
                  />
                </AppSettingsInput>
                <AppSettingsInput>
                  <SlackInput
                    checked={values.enableSlackNotifications}
                    onChange={(newValue: boolean) =>
                      handleSettingChange('enableSlackNotifications', newValue)
                    }
                  />
                </AppSettingsInput>
              </SettingsSubSection>
              <SettingsSubSection>
                <AppSettingsInput title="Receive notifications for">
                  <UserSettingsInput
                    checked={values.enableSlackNotificationsForSchemaChanges}
                    onChange={(newValue: boolean) =>
                      handleSettingChange('enableSlackNotificationsForSchemaChanges', newValue)
                    }
                    title="Schema changes"
                  />
                </AppSettingsInput>
                <AppSettingsInput>
                  <UserSettingsInput
                    checked={values.enableSlackNotificationsForMetadataChanges}
                    onChange={(newValue: boolean) =>
                      handleSettingChange('enableSlackNotificationsForMetadataChanges', newValue)
                    }
                    subtitle="Tags, owners, descriptions"
                    title="Metadata changes"
                  />
                </AppSettingsInput>
              </SettingsSubSection>
            </SettingsSection>
            <SettingsSection
              subtitle="Customize your experience in Select Star "
              title="App settings"
            >
              <SettingsSubSection>
                <AppSettingsInput
                  onChange={(newValue: string | boolean) =>
                    handleSettingChange('defaultDatabaseTableColumnOrder', newValue)
                  }
                  options={DATABASE_TABLE_COLUMN_ORDER_OPTIONS}
                  subtitle="There are several different ways to order columns"
                  title="Database table column order"
                  value={values.defaultDatabaseTableColumnOrder}
                />
              </SettingsSubSection>
              <SettingsSubSection>
                <AppSettingsInput
                  onChange={(newValue: string | boolean) =>
                    handleSettingChange('defaultTableDashboardTab', newValue)
                  }
                  options={DEFAULT_TABLE_DASHBOARD_TAB_OPTIONS}
                  title="Default tab for table / dashboard page"
                  value={values.defaultTableDashboardTab}
                />
              </SettingsSubSection>
              {organization?.settings?.useNewLineage && (
                <SettingsSubSection>
                  <AppSettingsInput
                    onChange={(newValue: string | boolean) =>
                      handleSettingChange('enableNewLineage', newValue)
                    }
                    showBetaBadge
                    subtitle="Select Star's new lineage user experience, currently in beta"
                    title="New lineage UI"
                    type="toggle"
                    value={values.enableNewLineage}
                  />
                </SettingsSubSection>
              )}
            </SettingsSection>
          </Box>
        </Box>
      </GridContainer>
    </>
  );
};

export default UserSettingsPage;
