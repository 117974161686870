import React, { memo, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { commentCacheKeys, usePatchComment, usePostComments } from '@api/comments';
import { CommentModel } from '@api/comments/CommentModel';
import invalidateCache from '@api/invalidateCache';
import { useFetchMetadataComments } from '@api/metadata';
import { TeamModel } from '@api/teams/TeamModel';
import { UserModel } from '@api/user/UserModel';
import Discussion from '@components/Discussion';
import { CommentPayload } from '@components/Discussion/CommentTextBox/CommentTextBox.types';
import NotifyUsersModal from '@components/Modal/NotifyUsersModal';
import { useNotificationContext } from '@components/Modal/NotifyUsersModal/Notification.context';
import { TabContentProps } from '@components/Tabs/types';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import fetchClient from '@lib/fetchClient';

import TabError from './TabError';

interface DiscussionTabProps extends TabContentProps {
  businessOwner?: UserModel | TeamModel;
  enableNotifications?: boolean;
  guid: string;
  technicalOwner?: UserModel | TeamModel;
}

export interface CommentModalState {
  comment?: CommentPayload;
  state: 'off' | 'notify';
}

type MessageType = 'comment' | 'notification';

const POST_MESSAGE_INFO: Record<MessageType, string> = {
  comment: '',
  notification: 'Message is sent',
};

const DiscussionTab = ({
  businessOwner,
  enableNotifications,
  guid,
  technicalOwner,
}: DiscussionTabProps) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const [editingNotificationGuid, setEditingNotificationGuid] = useState<string | undefined>();
  const { clearCurrentComment, currentComment, setCurrentComment } = useNotificationContext();
  const [shouldWaitCommentList, setShouldWaitCommentList] = useState(false);
  const { isEditable: isParentEditable } = useObjectPermissionsContext({ id: guid });

  const [dataType] = url.split(guid);
  const baseUrl = `${dataType}${guid}`;

  const handleCloseNotificationModal = () => {
    if (currentComment) {
      clearCurrentComment();
      setEditingNotificationGuid(undefined);
    }

    if (pathname.includes('notify')) {
      history.push(`${baseUrl}/discussion/${history.location.search}`);
    }
  };

  const {
    data,
    isError,
    isLoading: isLoadingComments,
    refetch,
  } = useFetchMetadataComments(guid, {
    keepPreviousData: true,
    onSettled: () => {
      if (shouldWaitCommentList) {
        handleCloseNotificationModal();
        setShouldWaitCommentList(false);
      }
    },
  });

  const { isLoading: isSavingComment, mutate: postComment } = usePostComments({
    onError: () => {
      renderErrorToast('There was an error saving your comment.');
    },
    onSettled: () => {
      handleCloseNotificationModal();
    },
    onSuccess: () => {
      fetchClient.invalidateQueries(commentCacheKeys.comments);
      invalidateCache((keys) => [keys.metadata.metadataComments]);
    },
  });

  const { isLoading: isUpdatingComment, mutate: updateComment } = usePatchComment(
    currentComment?.guid ?? '',
    {
      onError: () => {
        handleCloseNotificationModal();
        renderErrorToast('There was an error saving your comment.');
      },
      onSuccess: () => {
        fetchClient.invalidateQueries(commentCacheKeys.comments);
        invalidateCache((keys) => [keys.metadata.metadataComments]);

        setShouldWaitCommentList(true);
      },
    },
  );

  const onSave = (payload: CommentPayload, type: MessageType) => {
    postComment(payload, {
      onSuccess: () => {
        const message = POST_MESSAGE_INFO[type];
        if (message) {
          renderInfoToast(message);
        }
      },
    });
  };

  const onUpdateNotification = (payload: CommentPayload) => {
    updateComment(payload, {
      onSuccess: () => {
        renderInfoToast('Notification updated');
      },
    });
  };

  const handleNotificationUpdate = (payload: CommentModel) => {
    setCurrentComment(payload);
    setEditingNotificationGuid(payload.guid);
  };

  if (isError) return <TabError />;

  return (
    <>
      <Discussion
        businessOwner={businessOwner}
        data={data}
        editingNotificationGuid={shouldWaitCommentList ? editingNotificationGuid : undefined}
        enableNotifications={enableNotifications}
        isParentEditable={isParentEditable}
        loading={isLoadingComments}
        onNotificationUpdate={handleNotificationUpdate}
        onSave={(payload) => onSave(payload, 'comment')}
        parentGuid={guid}
        reloadComments={refetch}
        shouldBlockNavigation={!currentComment}
        technicalOwner={technicalOwner}
      />
      {enableNotifications && (
        <Switch>
          <Route key={`${url}/notify`} path={`${path}/notify`}>
            <NotifyUsersModal
              comment={currentComment}
              isSaving={
                isSavingComment || isUpdatingComment || isLoadingComments || shouldWaitCommentList
              }
              onClose={handleCloseNotificationModal}
              onSaveComment={(payload) => onSave(payload, 'notification')}
              onUpdateComment={onUpdateNotification}
              parentGuid={guid}
            />
          </Route>
        </Switch>
      )}
    </>
  );
};

export default memo(DiscussionTab);
