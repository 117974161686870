import { Expose, Type } from 'class-transformer';
import groupBy from 'lodash/groupBy';

import type { DataType, ObjectType } from '@api/types';
import { UserData, UserModel } from '@api/user/UserModel';
import { BIType, TransformerToolType, WarehouseType } from '@models/DataSourceCredentials';

import { sortDataSource } from './CustomAttributes.utils';

export type CustomAttributeType = 'text';

export type CustomAttributeObjectType = Extract<
  ObjectType,
  | 'table'
  | 'column'
  | 'dashboard'
  | 'dashboardelement'
  | 'explore'
  | 'lookmlmodel'
  | 'lookmlview'
  | 'lookmlfield'
  | 'explorefield'
  | 'tableaudatasource'
  | 'tableauview'
  | 'tableaufield'
  | 'reportquery'
  | 'bidataset'
  | 'thoughtspottable'
  | 'notebook'
>;

export type CustomAttributeDataType = Extract<
  DataType,
  | 'tile'
  | 'dashboard'
  | 'report'
  | 'card'
  | 'page'
  | 'element'
  | 'sql_view'
  | 'worksheet'
  | 'one_to_one_logical'
>;

export type CustomAttributeDataSourceType = WarehouseType | BIType | TransformerToolType;

export interface CustomAttribute {
  createdBy: UserData;
  dataSourceTypes: WarehouseType[] | BIType[];
  dataTypes: CustomAttributeDataType[];
  guid: string;
  name: string;
  objectTypes: CustomAttributeObjectType[];
  type: CustomAttributeType;
}

export interface AppliesTo {
  dataSourceType: CustomAttributeDataSourceType;
  dataTypes: CustomAttributeDataType[];
  objectTypes: CustomAttributeObjectType[];
}

export class CustomAttributeModel {
  guid!: string;

  type!: CustomAttributeType;

  name!: string;

  @Expose({ name: 'data_source_types' })
  dataSourceTypes: CustomAttributeDataSourceType[] = [];

  @Expose({ name: 'object_types' })
  objectTypes: CustomAttributeObjectType[] = [];

  @Expose({ name: 'data_types' })
  dataTypes: CustomAttributeDataType[] = [];

  @Expose({ name: 'created_by' })
  @Type(() => UserModel)
  createdBy!: UserModel;

  get appliesTo() {
    const mapAppliesTo = this.dataSourceTypes.map((dataSource, index) => {
      return {
        dataSourceType: dataSource,
        dataType: this.dataTypes[index],
        objectType: this.objectTypes[index],
      };
    });

    const groupedByDataSource = groupBy(mapAppliesTo, 'dataSourceType');

    return Object.entries(groupedByDataSource)
      .map<AppliesTo>(([key, values]) => ({
        dataSourceType: key as CustomAttributeDataSourceType,
        dataTypes: values.map<CustomAttributeDataType>((item) => item.dataType),
        objectTypes: values.map<CustomAttributeObjectType>((item) => item.objectType),
      }))
      .sort((a, b) => sortDataSource(a.dataSourceType, b.dataSourceType));
  }
}
