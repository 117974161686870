import React from 'react';

import { useFetchDbtWarehouseLink } from '@api/dbt';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import Mention from '@components/Mention';
import Text from '@components/Text';
import theme from '@styles/theme';

interface TableLinksToProps {
  guid: string;
}

const TableLinksTo: React.FC<TableLinksToProps> = ({ guid }) => {
  const { data, isLoading } = useFetchDbtWarehouseLink(guid, {
    enabled: Boolean(guid),
  });

  if (isLoading) {
    return <CircularLoader borderWidth={1} compSize={1.5} />;
  }

  if (!data || data.results.length === 0) {
    return (
      <Text color="gray.400" fontSize={theme.typography.fontSizes.body2}>
        Link not found
      </Text>
    );
  }

  return (
    <Box compDisplay="flex" flexWrap="wrap" gap={0.25}>
      {data.results.map(({ warehouseTable }) => (
        <Mention
          key={warehouseTable.guid}
          dataTypes={warehouseTable?.dataTypes}
          guid={warehouseTable?.guid}
          link={warehouseTable?.routePath}
          name={warehouseTable?.name}
        />
      ))}
    </Box>
  );
};

export default TableLinksTo;
