import React from 'react';
import { DEFAULT_DOCS_URL } from '@constants';

import { usePatchIncompleteDataSource } from '@api/dataSources';
import Alert from '@components/Alert';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import NextStepButton from '@components/DataSourceSetup/components/Buttons/NextStepButton';
import ErrorCredentialAlert from '@components/DataSourceSetup/components/ErrorCredentialAlert/ErrorCredentialAlert';
import { StyledText } from '@components/DataSourceSetup/components/LookMLProjectSetup/LookMLProjectSetup';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import Link from '@components/Link';
import { ModalFooter } from '@components/UI/Modal';
import { DataSourceModel } from '@models/DataSourceModel';

import ErrorDescriptionMarkdown from '../ErrorDescriptionMarkdown';

interface DataSourceAddActivityDataStepProps {
  dataSource: DataSourceModel;
  onDataSourceAdded: (dataSource: DataSourceModel) => void;
}

const AuthorizeAWS: React.FC<DataSourceAddActivityDataStepProps> = ({
  dataSource,
  onDataSourceAdded,
}) => {
  const onResponse = (ds: DataSourceModel) => {
    onDataSourceAdded(ds);
  };

  const { error, isLoading, mutate } = usePatchIncompleteDataSource(dataSource.guid, {
    onSuccess: onResponse,
  });

  const { handleChange, handleSubmit, values } = useForm({
    initialValues: ['redshift', 'glue'].includes(dataSource.type)
      ? {
          roleArn: '',
        }
      : {
          roleArn: '',
          secretArn: '',
        },
    onSubmit: (val) => {
      mutate({
        credentials: {
          role_arn: val.roleArn.trim(),
          ...(dataSource.type === 'postgres' && { secret_arn: val.secretArn }),
        },
        guid: dataSource.guid,
        type: dataSource.type,
      });
    },
  });

  const redirectToAWS = () => {
    window.open(dataSource.cloudFormationUrl?.[dataSource.type], '_blank');
  };
  const integrationPrettyNameMap: Record<string, string> = {
    glue: 'AWS Glue Data Catalog',
    postgres: 'AWS RDS for PostgreSQL instance',
    redshift: 'Redshift cluster',
  };
  const documentationUrlMap: Record<string, string> = {
    glue: 'aws-glue',
    postgres: 'postgres',
    quicksight: 'quicksight',
    redshift: 'redshift',
  };
  const integrationPrettyName: string = integrationPrettyNameMap[dataSource.type];

  const documentationUrl = `${DEFAULT_DOCS_URL}/integrations/${
    documentationUrlMap[dataSource.type]
  }`;
  const outputValues =
    dataSource.type === 'postgres' ? 'AWS IAM role ARN and secret ARN' : 'AWS IAM role ARN';

  const helpText = (
    <StyledText display="inline">
      To authorize our access to {integrationPrettyName} please deploy CloudFormation stack in the
      AWS account where the {integrationPrettyName} exists. The output of the stack indicates the{' '}
      {outputValues} for Select Star access. See the instruction in the{' '}
      <Link href={documentationUrl} rel="noopener noreferrer" target="_blank" underline>
        documentation
      </Link>
    </StyledText>
  );

  const redshiftHelpText = (
    <StyledText display="inline">
      When setting up {integrationPrettyName} please be aware that if you are setting up S3 activity
      logging for the first time, you will miss popularity and lineage features in the first days of
      using SelectStar. See more information{' '}
      <Link href={documentationUrl} rel="noopener noreferrer" target="_blank" underline>
        documentation
      </Link>
    </StyledText>
  );

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        <Box compDisplay="flex" flexDirection="column" gap={1} gridColumn="1/3">
          <Alert type="info">{helpText}</Alert>
          {dataSource.type === 'redshift' && <Alert type="info">{redshiftHelpText}</Alert>}
          <Button disabled={isLoading} fluid onClick={redirectToAWS} type="button">
            Open CloudFormation
          </Button>
        </Box>
        <StyledLabel>
          Role ARN
          <Input
            error={error?.data?.role_arn}
            helperText={error?.data?.role_arn}
            name="roleArn"
            onChange={handleChange}
            placeholder="arn:aws:iam:..."
            type="text"
            value={values.roleArn}
          />
        </StyledLabel>
        {dataSource && dataSource.type === 'postgres' && (
          <StyledLabel>
            Secret ARN
            <Input
              error={error?.data?.secret_arn}
              helperText={error?.data?.secret_arn}
              name="secretArn"
              onChange={handleChange}
              placeholder="arn:..."
              type="text"
              value={values.secretArn}
            />
          </StyledLabel>
        )}
        <Box gridColumn="1/3">
          {error && error.status === 500 && (
            <Alert title="Couldn't store credentials." type="error">
              Please try again later.
            </Alert>
          )}
          <ErrorCredentialAlert error={error?.data.credentials} />
          <ErrorDescriptionMarkdown error={error} />
        </Box>
      </StyledFormHorizontalLabelGrid>
      <ModalFooter>
        <NextStepButton disabled={isLoading} text="Save" />
      </ModalFooter>
    </Form>
  );
};

export default AuthorizeAWS;
