import parseLineageNodes from '@components/Explore.v1/useCreateNodesEdges/algorithm/parseLineageNodes';
import useExpandExploreNode from '@components/Explore.v1/useExpandExploreNode';
import { useExplore } from '@components/Explore.v1/useExplore';
import useUserTracking from '@components/Explore.v1/useUserTracking';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import { LineageSearchToggleState } from '../useExplore/Explore.context.types';
import useGetConfigQueryParams from '../useGetConfigQueryParams';

const useSearchChildren = (nodeKey?: string) => {
  const {
    biggestConflictEndPerStack,
    initialPosition,
    isColumnLevelLineage,
    nodesById,
    setNodesById,
    stackData,
  } = useExplore();
  const { track } = useUserTracking();
  const { updateNodesOnExpand } = useExpandExploreNode();
  const { shouldHideFilterLineage } = useGetConfigQueryParams();

  const updateNodesOnSearch = (query: string) => {
    if (nodeKey) {
      if (stackData?.nodesById[nodeKey]) {
        stackData.nodesById[nodeKey].data.childrenSearchText = query;
      }

      const result = parseLineageNodes({
        edgesById: stackData?.edgesById ?? {},
        initialPosition,
        isColumnLevelLineage,
        nodesById: stackData?.nodesById ?? {},
        operationPivotNodeKey: nodeKey,
        preCalculatedConflictEndPerStack: biggestConflictEndPerStack,
        preCalculatedNodesById: nodesById,
        shouldHideFilterLineage,
        stackGroups: stackData?.stackGroups,
      });

      setNodesById(result.nodesById);
    }
  };

  const triggerColumnSearch = () => {
    if (nodeKey) {
      const node = stackData?.nodesById[nodeKey];
      if (node) {
        if (!node.data.isOpen) {
          updateNodesOnExpand({
            nodeKey,
            shouldEnableFocusedLineage: !isColumnLevelLineage,
            shouldEnableSearch: true,
          });
          track(SegmentTrackEventName.LineageSearchToggleClicked, {
            key: nodeKey,
            state: LineageSearchToggleState.On,
          });
        } else {
          const prevIsSearchEnabled = node.data.isSearchEnabled;
          const hasSearchText = prevIsSearchEnabled && Boolean(node.data.childrenSearchText);

          if (!hasSearchText) {
            const newSearchIsEnabled = !prevIsSearchEnabled;
            stackData.nodesById[nodeKey].data.isSearchEnabled = newSearchIsEnabled;
            track(SegmentTrackEventName.LineageSearchToggleClicked, {
              key: nodeKey,
              state: newSearchIsEnabled
                ? LineageSearchToggleState.On
                : LineageSearchToggleState.Off,
            });

            const result = parseLineageNodes({
              edgesById: stackData?.edgesById ?? {},
              initialPosition,
              isColumnLevelLineage,
              nodesById: stackData?.nodesById ?? {},
              operationPivotNodeKey: nodeKey,
              preCalculatedConflictEndPerStack: biggestConflictEndPerStack,
              preCalculatedNodesById: nodesById,
              shouldHideFilterLineage,
              stackGroups: stackData?.stackGroups,
            });

            setNodesById(result.nodesById);
          }
        }
      }
    }
  };

  return { triggerColumnSearch, updateNodesOnSearch };
};

export default useSearchChildren;
