import React, { useCallback, useEffect, useMemo } from 'react';

import { useFetchSyncBackAvailableDataSources } from '@api/syncBack';
import { RepositoryModel } from '@api/syncBack/RepositoryModel';
import Alert from '@components/Alert';
import Box from '@components/Box';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import InputLabel from '@components/UI/Form/InputLabel/InputLabel';
import Select from '@components/UI/Select.v1/Select';
import { Option, SelectValue } from '@components/UI/Select.v1/types';
import { useWizard } from '@components/Wizard/Wizard/useWizard';
import theme from '@styles/theme';

import GithubDocumentationAlert from './GithubDocumentationAlert';
import useMutateSyncBackRepository from './useMutateSyncBackRepository';

const INPUT_GRID_TEMPLATE = `${theme.space(21.75)} 1fr`;

export interface GithubFormStepProps {
  repository?: RepositoryModel;
}

interface GithubFormValues {
  dataSource: SelectValue;
  dbtProjectFilepath: string;
  githubUrl: string;
  pathPrefix: string;
  projectName: string;
}

const GithubFormStep = ({ repository }: GithubFormStepProps) => {
  const isEditing = Boolean(repository);

  const defaultDataSourceValue = useMemo(() => {
    if (repository) {
      const { dataSource } = repository;
      return {
        icon: dataSource.dataTypes?.icons.dataSource,
        text: dataSource.name,
        value: dataSource.guid,
      };
    }
    return undefined;
  }, [repository]);

  const {
    error,
    isLoading: mutatingRepository,
    mutate,
  } = useMutateSyncBackRepository({ repository });

  const {
    data: dataSources = [],
    error: dataSourcesError,
    isLoading: loadingDataSources,
  } = useFetchSyncBackAvailableDataSources({ enabled: !isEditing });

  const { handleChange, setValues, values } = useForm<GithubFormValues>({
    initialValues: {
      dataSource: defaultDataSourceValue ? [defaultDataSourceValue] : undefined,
      dbtProjectFilepath: '',
      githubUrl: '',
      pathPrefix: '',
      projectName: '',
    },
  });

  const wizardNextButtonAction = useCallback(
    async (next: () => void) => {
      try {
        const { dataSource, dbtProjectFilepath, githubUrl, pathPrefix, projectName } = values;

        const [selectedDataSource] = dataSource as Option[];

        const payload = {
          data_source: {
            guid: selectedDataSource.value,
          },
          extra_conf: {
            dbt_project_filepath: dbtProjectFilepath,
            package_name: projectName,
          },
          files_path_prefix: pathPrefix,
          scm_provider: 'github',
          url: githubUrl,
        };

        await mutate(payload);

        next();
      } catch {
        // errors handled by the form
      }
    },
    [mutate, values],
  );

  useWizard({
    loading: mutatingRepository,
    nextButtonAction: wizardNextButtonAction,
  });

  const dsOptions = useMemo(() => {
    const options = dataSources.map((ds) => ({
      icon: ds.dataTypes?.icons.dataSource,
      text: ds.name,
      value: ds.guid,
    }));

    if (isEditing && defaultDataSourceValue) {
      options.push(defaultDataSourceValue);
    }

    return options;
  }, [dataSources, isEditing, defaultDataSourceValue]);

  useEffect(() => {
    setValues({
      dataSource: defaultDataSourceValue ? [defaultDataSourceValue] : undefined,
      dbtProjectFilepath: repository?.extraConfig?.dbtProjectFilepath ?? '',
      githubUrl: repository?.url ?? '',
      pathPrefix: repository?.filePathPrefix ?? '',
      projectName: repository?.extraConfig?.packageName ?? '',
    });
  }, [repository, setValues, defaultDataSourceValue]);

  const dataSourceErrorText =
    dataSourcesError?.message || (error?.data && error.data?.data_source?.guid);

  return (
    <Box compDisplay="flex" flexDirection="column">
      <GithubDocumentationAlert />
      {error?.data && error.data?.non_field_errors?.length > 0 && (
        <Alert mt={2} title="Invalid Credential" type="error">
          Please ensure the GitHub App is installed on the repo provided here.
        </Alert>
      )}
      <Box mt={1.75}>
        <Form>
          <Box
            compDisplay="grid"
            flexDirection="column"
            gap={1.5}
            gridTemplateRows="repeat(4, min-content)"
          >
            <InputLabel
              as="div"
              compDisplay="grid"
              fontWeight="semibold"
              gridTemplateColumns={INPUT_GRID_TEMPLATE}
            >
              Select Star Datasource
              <Select
                error={Boolean(dataSourceErrorText)}
                helperText={dataSourceErrorText}
                isDisabled={isEditing || loadingDataSources}
                isLoading={loadingDataSources}
                onChange={(ds) => {
                  setValues((current) => ({ ...current, dataSource: ds }));
                }}
                options={dsOptions}
                placeholder="Please select a data source"
                value={values.dataSource}
              />
            </InputLabel>
            <InputLabel
              compDisplay="grid"
              fontWeight="semibold"
              gridTemplateColumns={INPUT_GRID_TEMPLATE}
            >
              GitHub URL
              <Box>
                <Input
                  error={error?.data && error.data?.url}
                  helperText={error?.data && error.data?.url}
                  name="githubUrl"
                  onChange={handleChange}
                  placeholder="https://github.com/cx_org/dbt_repo"
                  type="text"
                  value={values.githubUrl}
                />
              </Box>
            </InputLabel>
            <InputLabel
              compDisplay="grid"
              fontWeight="semibold"
              gridTemplateColumns={INPUT_GRID_TEMPLATE}
            >
              Project Name
              <Box>
                <Input
                  error={error?.data && error.data?.package_name}
                  helperText={error?.data && error.data?.package_name}
                  name="projectName"
                  onChange={handleChange}
                  placeholder="dbt project name"
                  type="text"
                  value={values.projectName}
                />
              </Box>
            </InputLabel>
            <InputLabel
              compDisplay="grid"
              fontWeight="semibold"
              gridTemplateColumns={INPUT_GRID_TEMPLATE}
            >
              Path Prefix (optional)
              <Input
                name="pathPrefix"
                onChange={handleChange}
                type="text"
                value={values.pathPrefix}
              />
            </InputLabel>
            <InputLabel
              compDisplay="grid"
              fontWeight="semibold"
              gridTemplateColumns={INPUT_GRID_TEMPLATE}
            >
              dbt Project File Path (optional)
              <Input
                error={error?.data && error.data?.dbt_project_filepath}
                helperText={error?.data && error.data?.dbt_project_filepath}
                name="dbtProjectFilepath"
                onChange={handleChange}
                placeholder="dbt_repo/dbt_project.yml"
                type="text"
                value={values.dbtProjectFilepath}
              />
            </InputLabel>
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

export default GithubFormStep;
