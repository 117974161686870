import styled from '@emotion/styled';

import Breadcrumbs from '@components/Breadcrumbs';

interface StyledMentionProps {
  color?: string;
}

export const StyledMention = styled.span<StyledMentionProps>`
  align-items: center;
  background-color: #fff;
  border-radius: ${({ theme }) => theme.radius.default};
  border: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
  color: ${({ color }) => color};
  cursor: default;
  display: inline-flex;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-size: 0.9rem;
  gap: ${({ theme }) => theme.space(0.5)};
  height: 22px;
  margin-top: ${({ theme }) => theme.space(0.25)};
  max-width: 100%;
  overflow: hidden;
  padding: ${({ theme }) => theme.space(0.5)};
  vertical-align: bottom;
  width: fit-content;
  line-height: normal;

  a {
    background: #fff !important;
    padding: 0;
    border-radius: 0;
    display: inline-flex;
    align-items: center;
    max-height: 1.4rem;
    text-decoration-line: none !important;
    color: ${({ theme }) => theme.colors.text};

    max-width: 100%;

    span {
      color: ${({ theme }) => theme.colors.text};
      font-size: ${({ theme }) => theme.typography.fontSizes.body2};
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .icon {
    width: 1rem;
    flex-shrink: 0;
  }
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  display: flex;
  align-items: center;
`;
