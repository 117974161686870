import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as d3 from 'd3';
import getDebug from 'debug';
import compact from 'lodash/compact';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import TableLineageModel from '@api/lineage/TableLineageModel';
import { useUserContext } from '@context/User';
import { isBIType } from '@models/DataSourceCredentials';

import { desiredZoomTable, exploreOptions, pinnedTable, searchOptions } from './atoms';
import { GRAPH_LINKS_CONTAINER_ID, GRAPH_ROOT_CONTAINER_ID } from './consts';
import ExploreTreeContext, { defaultYSpacing } from './context';
import ExploreColumns from './ExploreColumns';
import ExploreHeading from './ExploreHeading';
import ExploreTableFrame from './ExploreTableFrame';
import StyledExploreTreeSvg from './ExploreTree.styles';
import ExploreTreeDefs from './ExploreTreeDefs';
import {
  extractColumnsIdsFromTables,
  getCoordinatesOnly,
  getPinnableColumnsById,
  getPinnedColumnRoot,
  tableGraphState,
} from './helpers';
import createStack from './helpers/createStack/createStack';
import drawColumnLinks from './helpers/drawColumnLinks';
import drawColumnsToTableLinks from './helpers/drawColumnsToTableLinks';
import drawTableLinks from './helpers/drawTableLinks';
import findColumnRelatedTables from './helpers/findColumnRelatedTables';
import { findAllRelatedColumns } from './helpers/findRelatedColumns';
import findRelatedTables from './helpers/findRelatedTables';
import generateStackCoordinates from './helpers/generateStackCoordinates';
import generateTableRelationsPaths from './helpers/generateTableRelationsPaths';
import getAllSortedColumns from './helpers/getAllSortedColumns';
import getTableIds from './helpers/getTableIds';
import transformArrayToObject from './helpers/transformArrayToObject';
import useInitZoom from './helpers/useInitZoom';
import zoomToCoordinates from './helpers/zoomToCoordinates';
import {
  Coordinates,
  Direction,
  ExploreData,
  ExploreType,
  OpenLineageCallback,
  TableLink,
  TreeContext,
} from './types';

const debug = getDebug('selectstar:explore');
const debugRender = debug.extend('render');
const debugArrows = debug.extend('arrows');

interface ExploreTreeProps extends ExploreData {
  colorType?: 'erd';
  exploreType?: ExploreType;
  getStackStartingTableId?: (
    tablesGroup: TableLineageModel[],
    defaultStartingTableId: string,
  ) => string;
  loadLineage: OpenLineageCallback;
  makeStartingTablesActive?: boolean;
  openAllTables?: boolean;
  openSidebar: (type: 'table' | 'column', key: string) => void;
  renderContext: string;
  startingColumnId?: string | null;
  startingTablesIds?: string[];
}

const ExploreTree: React.FC<ExploreTreeProps> = ({
  colorType,
  columns: columnsRaw = [],
  exploreType = 'lineage',
  getStackStartingTableId,
  loadLineage,
  makeStartingTablesActive,
  openAllTables = false,
  openSidebar,
  renderContext,
  startingColumnId = null,
  startingTablesIds = [],
  tables: tablesRaw = [],
}) => {
  const svgRootRef = useRef<SVGSVGElement>(null);
  const { settings } = useUserContext();
  const zoomRef = useInitZoom({ id: GRAPH_ROOT_CONTAINER_ID, svgRootRef });
  const startingTableId = startingTablesIds?.[0];
  const lastPinnedTableId = useRef<string | null>(null);
  const [userPinnedTableId, setUserPinnedTableId] = useRecoilState<string | null>(pinnedTable);
  const [zoomToTableId, setZoomToTableId] = useRecoilState<string | null>(desiredZoomTable);
  const [userPinnedColumns, setPinnedColumns] = useState<string[]>(() => {
    if (startingColumnId && startingTableId) {
      setUserPinnedTableId(startingTableId);
      lastPinnedTableId.current = startingTableId;
      return [`${startingTableId}/${startingColumnId}`];
    }

    return [];
  });
  const [suppressedTableIds, setSuppressedTableIds] = useState<string[]>([]);
  const options = useRecoilValue(exploreOptions);
  const search = useRecoilValue(searchOptions);
  const resetSearch = useResetRecoilState(searchOptions);
  const [hoveredColumn, setHoveredColumn] = useState<string | null>(null);
  const [hoveredTableHeader, setHoveringTableHeader] = useState<string | null>(null);
  const [hoveredTable, setHoveringTable] = useState<string | null>(null);
  const [tableIdsShowAllColumns, setTableIdsShowAllColumns] = useState<string[]>([]);
  const [activeSearchTables, setActiveSearchTables] = useState<string[]>([]);
  const [ySpacing, setYSpacing] = useState(defaultYSpacing);
  const [searchColumnNamePerTable, setSearchColumnNamePerTable] = useState<{
    [key: string]: string;
  }>({});
  const [hoveredLineageToClose, setHoveringCloseLineage] = useState<[string, Direction] | null>(
    null,
  );
  const coordinatesCache = useRef<{
    [key: number]: { lastCoords: Coordinates[]; lastFlow: Coordinates };
  }>({});

  const filteredData = useMemo(() => {
    let columns = columnsRaw.filter((column) => !suppressedTableIds.includes(column.tableGuid));

    let tables = tablesRaw
      .filter((table) => !suppressedTableIds.includes(table.key))
      .filter((table) => {
        if (search.keyword && !startingTablesIds?.includes(table?.guid)) {
          const match = table?.name
            ?.toLocaleLowerCase()
            ?.includes(search.keyword?.toLocaleLowerCase());

          if (search.exclude) {
            return !match;
          }

          return match;
        }

        return true;
      });

    if (!options.showTables.value) {
      // Hide all Tables
      tables = tables?.filter((t) => !(t.guid.startsWith('ta_') && t.dataSourceType !== 'dbt'));
    }

    if (!options.showModels?.value) {
      // Hide all dbt Models
      tables = tables?.filter((t) => !(t.guid.startsWith('ta_') && t.dataSourceType === 'dbt'));
    }

    if (options.showModels?.value && options.showTables?.value) {
      /**
       * Hide all dbt Models that have an associated warehouse table
       * this way we don't duplicate the visible warehouse tables
       *
       */
      const existingTableGuids = new Set(tables.map((t) => t.guid));
      tables = tables?.filter(
        (t) =>
          !(
            t?.guid?.startsWith('ta_') &&
            t.dataSourceType === 'dbt' &&
            /**
             * if a dbt table is linked to another table that is visible, then hide the dbt table
             *  NOTE: we can assume that dbt will only be linked to not-dbt data sources because
             * backend gurantees it. Which means we won't be hiding all references to a linked table.
             */
            t.linkedObjs?.some((linkedTable) => existingTableGuids.has(linkedTable))
          ),
      );
    }

    if (!options.showDashboards.value) {
      // Hide all Dashboards
      tables = tables.filter((t) => !isBIType(t?.dataSourceType));
    }

    if (options.autoClose.value) {
      // Hide unrelated assets that are not upstream/downstream of userPinnedTableId.
      tables = findRelatedTables(userPinnedTableId, tables);

      /**
       * Show related and parent tables when autoClose checkbox enabled.
       * Other tables have to be hidden.
       */
      if (userPinnedColumns.length > 0) {
        const userPinnedTable = find(tables, ['key', userPinnedTableId]);
        const relatedColumns = findAllRelatedColumns({
          columnIds: userPinnedColumns,
          columns: columnsRaw,
        });
        const columnRelatedTables = findColumnRelatedTables({
          columns: relatedColumns,
          tables,
        });
        const columnRelatedParentTables = uniqBy(
          compact(relatedColumns?.map((column) => find(tables, { key: column.tableGuid }))),
          'key',
        );

        tables = [...columnRelatedTables, ...columnRelatedParentTables];
        columns = relatedColumns;

        if (userPinnedTable) {
          tables.push(userPinnedTable);
        }
      }
    }

    /** Filter out columns that don't match user search. */
    columns = columns.filter((column) => {
      const tableSearchValue = searchColumnNamePerTable[column.tableGuid];

      if (tableSearchValue) {
        return column.name.toLowerCase().includes(tableSearchValue.toLowerCase());
      }

      return true;
    });

    /** We should always show user pinned table or the initial one. */
    const initialTableIncluded = tables?.some(
      (t) => t?.guid === userPinnedTableId || startingTablesIds?.includes(t?.guid),
    );

    if (!initialTableIncluded) {
      const initialTable = tablesRaw?.find((t) => startingTablesIds?.includes(t?.guid));

      if (initialTable) {
        tables = [...tables, initialTable];
      }
    }

    return { columns, tables };
  }, [
    startingTablesIds,
    tablesRaw,
    suppressedTableIds,
    userPinnedTableId,
    options.showTables.value,
    options.showModels?.value,
    options.showDashboards.value,
    options.autoClose.value,
    userPinnedColumns,
    columnsRaw,
    search.keyword,
    search.exclude,
    searchColumnNamePerTable,
  ]);

  const tablesById = useMemo(
    () => transformArrayToObject(filteredData.tables),
    [filteredData.tables],
  );

  const columnsById = useMemo(
    () => transformArrayToObject(filteredData.columns),
    [filteredData.columns],
  );

  const columnExists = useMemo(
    () => extractColumnsIdsFromTables(filteredData.tables),
    [filteredData.tables],
  );

  const pinnableColumnsById = useMemo(() => getPinnableColumnsById(columnsById), [columnsById]);

  const sortedColumnIdsByTableId = useMemo(() => {
    return getAllSortedColumns(
      filteredData.tables,
      columnsById,
      pinnableColumnsById,
      search.sortBy,
      search.orderBy,
    );
  }, [filteredData.tables, columnsById, pinnableColumnsById, search.sortBy, search.orderBy]);

  const stacks = useMemo(() => {
    const graphByBelongsTo = groupBy(filteredData.tables, 'componentIdentifier');

    return Object.values(graphByBelongsTo)?.map((tablesGroup) => {
      return createStack({
        startingTableId: getStackStartingTableId?.(tablesGroup, startingTableId) ?? startingTableId,
        suppressedTableIds,
        tables: tablesGroup,
        tablesById: transformArrayToObject(tablesGroup),
      });
    }, []);
  }, [filteredData.tables, getStackStartingTableId, startingTableId, suppressedTableIds]);

  // Graph and Math calculation caches, also only change on data load
  const existingContext = useContext<TreeContext>(ExploreTreeContext);
  const { viewHeight, viewMinX, viewMinY, viewWidth, xScale, yScale } = existingContext;

  // Expose all top level caches, that only change when data load
  const context = useMemo(
    (): TreeContext => ({
      ...existingContext,
      activeSearchTables,
      columnExists,
      columnsById,
      pinnableColumnsById,
      renderContext,
      sortedColumnIdsByTableId,
      tablesById,
      visibleColumns: filteredData.columns,
      visibleTables: filteredData.tables,
      ySpacing,
    }),
    [
      ySpacing,
      tablesById,
      columnsById,
      columnExists,
      renderContext,
      existingContext,
      pinnableColumnsById,
      sortedColumnIdsByTableId,
      activeSearchTables,
      filteredData.tables,
      filteredData.columns,
    ],
  );

  const relatedColumnsOnHover = useMemo(() => {
    const target = findAllRelatedColumns({
      columnIds: hoveredColumn ? [hoveredColumn] : [],
      columns: filteredData.columns,
    })?.map((column) => column.key);

    return {
      all: hoveredColumn ? [...target, hoveredColumn] : [],
      target,
    };
  }, [hoveredColumn, filteredData.columns]);

  const relatedColumnsOnPin = useMemo(() => {
    const target = findAllRelatedColumns({
      allowExtraMetaUpdate: true,
      columnIds: userPinnedColumns,
      columns: filteredData.columns,
    })?.map((column) => column.key);

    return {
      all: [...target, ...userPinnedColumns],
      target,
    };
  }, [userPinnedColumns, filteredData.columns]);

  const tableIdsOfHoverTargetedPinnedColumns = useMemo(() => {
    const tablesIds = getTableIds(
      [...relatedColumnsOnHover.target, ...relatedColumnsOnPin.all],
      columnsById,
    );

    if (userPinnedTableId) {
      tablesIds.push(userPinnedTableId);
    }

    return tablesIds;
  }, [relatedColumnsOnPin.all, columnsById, relatedColumnsOnHover.target, userPinnedTableId]);

  const openedTables = useMemo(() => {
    const tablesIds = [...tableIdsOfHoverTargetedPinnedColumns];

    if (openAllTables) {
      tablesIds.push(...filteredData.tables.map((t) => t.guid));
    }

    debugArrows('openedTables', tablesIds);

    return tablesIds;
  }, [tableIdsOfHoverTargetedPinnedColumns, openAllTables, filteredData.tables]);

  const activeTables = useMemo(() => {
    const tablesIds = [...tableIdsOfHoverTargetedPinnedColumns];

    if (makeStartingTablesActive) {
      return [...tablesIds, ...startingTablesIds];
    }

    return tablesIds;
  }, [tableIdsOfHoverTargetedPinnedColumns, makeStartingTablesActive, startingTablesIds]);

  const currentCoordinates = useMemo(() => {
    let prevGraphHeight = 0;

    return stacks
      ?.map((stack, index) => {
        const SPACE_BETWEEN_GRAPHS = index > 0 ? 10 : 0;
        const offsetY = SPACE_BETWEEN_GRAPHS + prevGraphHeight;
        const { lastCoords, lastFlow } = coordinatesCache.current[index] ?? {
          lastCoords: [],
          lastFlow: {
            key: startingTableId,
            stackPos: 0,
            x: 0,
            y: offsetY,
          },
        };
        const newFlow = lastCoords.find((coord) => coord.key === hoveredTable) || lastFlow;
        const coords = generateStackCoordinates({
          activeSearchTables,
          columnsById,
          flow: newFlow,
          hoverTargetedColumns: [...relatedColumnsOnHover.target, ...relatedColumnsOnPin.target],
          offsetY,
          openedTables,
          pinnableColumnsById: context.pinnableColumnsById,
          rectHeight: context.rectHeight,
          rectWidth: context.rectWidth,
          sortedColumnIdsByTableId,
          stack: stack.stack,
          stackedAt: stack.stackedAt,
          tableIdsShowAllColumns,
          tablesById,
          xSpacing: context.xSpacing,
          ySpacing: context.ySpacing,
        });

        prevGraphHeight = coords.yMax;
        coordinatesCache.current[index] = {
          lastCoords: coords.coords,
          lastFlow: newFlow,
        };

        return coords;
      })
      .reduce((acc, item) => [...acc, ...item.coords], [] as Coordinates[]);
  }, [
    stacks,
    startingTableId,
    activeSearchTables,
    columnsById,
    relatedColumnsOnHover.target,
    relatedColumnsOnPin.target,
    openedTables,
    context.pinnableColumnsById,
    context.rectHeight,
    context.rectWidth,
    context.xSpacing,
    context.ySpacing,
    sortedColumnIdsByTableId,
    tableIdsShowAllColumns,
    tablesById,
    hoveredTable,
  ]);

  const graph = useMemo(
    () => tableGraphState(currentCoordinates, context),
    [currentCoordinates, context],
  );

  const hoverTableLinksCoordinates: TableLink[] = useMemo(() => {
    return hoveredTableHeader
      ? generateTableRelationsPaths({ activeTableId: hoveredTableHeader, context, graph })
      : [];
  }, [context, graph, hoveredTableHeader]);

  const redTableLinksCoordinates: TableLink[] = useMemo(() => {
    if (!hoveredLineageToClose) {
      return [];
    }

    const [tableId, direction] = hoveredLineageToClose;

    return generateTableRelationsPaths({ activeTableId: tableId, context, direction, graph });
  }, [context, graph, hoveredLineageToClose]);

  const pinnedTableLinksCoordinates = useMemo(() => {
    if (!userPinnedTableId) {
      return [];
    }

    return generateTableRelationsPaths({
      activeTableId: userPinnedTableId,
      context,
      graph,
    });
  }, [userPinnedTableId, graph, context]);

  const hoveredTablesTargets = useMemo(() => {
    return hoverTableLinksCoordinates.reduce((unique, t) => {
      [t.tableId, t.targetTableId].forEach((id) => {
        if (!unique.includes(id)) unique.push(id);
      });

      return unique;
    }, [] as string[]);
  }, [hoverTableLinksCoordinates]);

  const toBeRemovedTableTargets = useMemo(() => {
    return redTableLinksCoordinates.reduce((unique, t) => {
      [t.tableId, t.targetTableId].forEach((id) => {
        if (!unique.includes(id) && hoveredTableHeader !== id) unique.push(id);
      });

      return unique;
    }, [] as string[]);
  }, [redTableLinksCoordinates, hoveredTableHeader]);

  /**
   * Turn back the lineage to the default state on modal close.
   */
  useEffect(() => {
    resetSearch();

    return () => {
      lastPinnedTableId.current = null;
      setUserPinnedTableId(null);
      setZoomToTableId(null);
      resetSearch();
    };
  }, [setUserPinnedTableId, setZoomToTableId, resetSearch]);

  /**
   * This effect should trigger when user clicks "Open" from the sidebar
   *
   * Turns out, we need the `graph` to be updated when zooming, which added
   * to dependency array causes the effect to refresh every time anything
   * changes (like hovers, clicks, etc.) causing glitches and snapping back.
   *
   * Therefore, we need to keep lastZoom manually, which semantically would
   * be equivalent of useEffect(fn, [zoomToTableId]); // but fn has access to updated graph
   */
  useLayoutEffect(
    function reactToProgrammaticZoom() {
      // First disclose table if it were suppressed
      if (suppressedTableIds.includes(zoomToTableId || '')) {
        return setSuppressedTableIds((prev) => prev.filter((_) => _ !== zoomToTableId));
        // returning here causes the effect to run again in next frame and resolve correctly
      }

      if (!zoomToTableId || zoomRef.current.lastZoom === zoomToTableId) {
        return undefined;
      }

      zoomRef.current.lastZoom = zoomToTableId;

      const table = graph[zoomToTableId];

      if (!table) return undefined;
      /*
       * `startingTablesIds` is at `zoomIdentity`, and it is positioned correctly
       * by `svg.viewBox` prop down in the JSX bellow. All table coordinates are
       * "startingTablesIds [+x, +y]", so we only need to translate[-x, -y] to
       * have the it centered in the same way :wink:
       */
      zoomToCoordinates({
        svgRootRef,
        transform: zoomRef.current.zoom.transform,
        x: xScale(-table.x),
        y: yScale(-table.y),
      });

      return undefined;
    },

    [suppressedTableIds, zoomToTableId, graph, xScale, yScale, zoomRef],
  );

  useLayoutEffect(() => {
    /** Clear all stack containers before drawing to eliminate any previous relationships.  */
    d3.select(svgRootRef.current).select(`#${GRAPH_LINKS_CONTAINER_ID}`).selectAll('*').remove();

    const generatedStacksTableLinksConfigsDerived = stacks?.map((stack, index) => {
      return {
        containerId: `starting-table-links-layer-stack-${index}`,
        coordinates: generateTableRelationsPaths({
          activeTableId: null,
          context,
          filterByEdgeType: 'derived',
          graph,
        }),
        customClass: 'startingTable',
      };
    });

    const generatedStacksTableLinksConfigsManual = stacks?.map((stack, index) => {
      return {
        containerId: `starting-table-links-layer-stack-${index + 1}`,
        coordinates: generateTableRelationsPaths({
          activeTableId: null,
          context,
          filterByEdgeType: 'manual',
          graph,
        }),
        customClass: 'manualLineageTable',
      };
    });

    const generatedStacksTableLinksConfigs = [
      ...generatedStacksTableLinksConfigsDerived,
      ...generatedStacksTableLinksConfigsManual,
    ];

    const stacksTableLinksConfigs =
      generatedStacksTableLinksConfigs?.length > 0
        ? generatedStacksTableLinksConfigs
        : [
            {
              containerId: `starting-table-links-layer-stack-0`,
              coordinates: [],
              customClass: 'startingTable',
            },
          ];

    drawTableLinks(
      d3.select(svgRootRef.current).select(`#${GRAPH_LINKS_CONTAINER_ID}`),
      context.lineGenerator,
    )([
      ...stacksTableLinksConfigs,
      {
        containerId: 'toBeRemovedTableLinkLayer',
        coordinates: redTableLinksCoordinates,
        customClass: 'toBeRemovedTable',
      },
      {
        containerId: 'pinnedTableLinkLayer',
        coordinates: pinnedTableLinksCoordinates,
        customClass: 'pinnedTable',
      },
      {
        containerId: 'hoveredTableLinkLayer',
        coordinates: hoverTableLinksCoordinates,
        customClass: 'hoveredTable',
      },
    ]);
  }, [
    context,
    graph,
    hoverTableLinksCoordinates,
    pinnedTableLinksCoordinates,
    redTableLinksCoordinates,
    stacks,
    userPinnedTableId,
  ]);

  useLayoutEffect(
    function drawingColumnLinks() {
      const root = d3.select(svgRootRef.current).select(`#${GRAPH_ROOT_CONTAINER_ID}`);
      const tableCoordinatesMap = getCoordinatesOnly(currentCoordinates);
      const hovered = hoveredColumn ? [hoveredColumn] : [];
      drawColumnLinks({
        context,
        displayColLinks: hovered,
        linkClass: 'hoveredColumnLink',
        root,
        tableCoordinatesMap,
      });
      drawColumnLinks({
        context,
        displayColLinks: userPinnedColumns,
        linkClass: 'pinnedColumnLink',
        root,
        tableCoordinatesMap,
      });
      drawColumnsToTableLinks({
        context,
        displayColLinks: hovered,
        linkClassType: 'hovered',
        root,
        tableCoordinatesMap,
      });
      drawColumnsToTableLinks({
        context,
        displayColLinks: userPinnedColumns,
        linkClassType: 'pinned',
        root,
        tableCoordinatesMap,
      });
    },
    [context, currentCoordinates, userPinnedColumns, hoveredColumn, activeSearchTables, ySpacing],
  );

  const toggleShowAll = useCallback(
    (id: string) => {
      return setTableIdsShowAllColumns((prev) =>
        prev.includes(id) ? prev.filter((_) => _ !== id) : [id, ...prev],
      );
    },
    [setTableIdsShowAllColumns],
  );

  const handleExploreHeadingClick = useCallback(
    (id: string) => {
      setSearchColumnNamePerTable({});
      setActiveSearchTables([]);
      setPinnedColumns([]);
      setUserPinnedTableId((prev) => {
        if (prev === id) return null;
        requestAnimationFrame(() => openSidebar('table', id));
        lastPinnedTableId.current = id;
        return id;
      });
    },
    [setUserPinnedTableId, openSidebar],
  );

  const handleColumnClick = useCallback(
    (id: string) => {
      setYSpacing(defaultYSpacing);
      setActiveSearchTables([]);
      setPinnedColumns((prev) => {
        const rootColumn = getPinnedColumnRoot(id, prev, columnsById);

        if (prev.includes(rootColumn)) {
          openSidebar('table', userPinnedTableId ?? startingTableId);

          setZoomToTableId(null);
          return [];
        }

        /** Resets same table zoom cache. */
        zoomRef.current.lastZoom = '';
        setZoomToTableId(userPinnedTableId);

        openSidebar('column', id);

        return [id];
      });
    },
    [columnsById, zoomRef, setZoomToTableId, userPinnedTableId, openSidebar, startingTableId],
  );

  const suppressTableIds = useCallback(
    (tableIds) => {
      /**
       * That's the best place to clean up all the states that might exist
       * for this table. Allowing a state to leak, will cause exceptions or we will
       * end up writing defensive code where is not needed.
       */
      const prevOrNull = (prev: string | null) => (!tableIds.includes(prev) ? null : prev);
      setUserPinnedTableId(prevOrNull);
      setHoveredColumn(prevOrNull);
      setHoveringTableHeader(prevOrNull);
      setHoveringCloseLineage((state) => {
        if (state === null) return state;
        return !tableIds.includes(state[0]) ? null : state;
      });
      setSuppressedTableIds((prev) => [...tableIds, ...prev]);
    },
    [
      setSuppressedTableIds,
      setUserPinnedTableId,
      setHoveredColumn,
      setHoveringTableHeader,
      setHoveringCloseLineage,
    ],
  );

  const handleSearchIconClick = useCallback(
    (key: string) => {
      setActiveSearchTables((prev) => {
        let nextActiveSearchTables;

        if (prev.includes(key)) {
          nextActiveSearchTables = prev.filter((tId) => tId !== key);
        } else {
          nextActiveSearchTables = [...prev, key];
        }

        return nextActiveSearchTables;
      });
    },
    [setActiveSearchTables],
  );

  const openLineage = useCallback<OpenLineageCallback>(
    (args) => {
      debugArrows('openLineage');
      const { direction, guid } = args;
      const targetIds: string[] = [];

      if (direction !== 'right' && guid) {
        targetIds.push(...(tablesById[guid]?.sourceTableGuids || []));
      }

      if (direction !== 'left' && guid) {
        targetIds.push(...(tablesById[guid]?.targetTableGuids || []));
      }

      loadLineage(args);

      setSuppressedTableIds((prev) => prev.filter((_) => !targetIds.includes(_)));
    },
    [loadLineage, tablesById],
  );

  const handleOnColumnMouseLeave = useCallback(() => {
    setHoveredColumn(null);
  }, []);

  const handleOnColumnMouseEnter = useCallback((id: string) => {
    setHoveredColumn(id);
  }, []);

  const handleSearchExploreHeadingChange = useCallback((value: string, key: string) => {
    setSearchColumnNamePerTable((prev) => ({ ...prev, [key]: value }));
  }, []);

  debugRender('render');
  debug('suppressedTableIds', suppressedTableIds);

  return (
    <ExploreTreeContext.Provider value={context}>
      <StyledExploreTreeSvg
        ref={svgRootRef}
        className="select-none"
        height="100%"
        preserveAspectRatio="xMinYMin meet"
        viewBox={`${viewMinX} ${viewMinY} ${viewWidth} ${viewHeight}`}
        width="100%"
      >
        <g id={GRAPH_ROOT_CONTAINER_ID}>
          <g id={GRAPH_LINKS_CONTAINER_ID} />
          {currentCoordinates.map(({ key, x, y }: Coordinates) => {
            const table = tablesById[key];

            if (table === undefined) return null;

            const {
              componentIdentifier,
              dataSourceType,
              dataTypes,
              fullName,
              isHidden,
              linkedObjs,
              name,
              sourceTableGuids: rawSourceTableGuids = [],
              targetTableGuids: rawTargetTableGuids = [],
            } = table;

            /**
             * if the table has any linked objects that are dbt models, we should also show the dbt icon
             * NOTE: Curently we assume that linkedObjs only contain information about dbt <-> DWH connections
             * NOTE: this is related to SidebarTree
             *
             */
            const hasDbtLinkedObjs = dataSourceType === 'dbt' ? false : !!linkedObjs;

            // linked objects are hidden, so don't show them in upstream/downstream
            const removeLinkedObjs = (id: string) => !linkedObjs?.some((guid) => guid === id);
            const sourceTableGuids = rawSourceTableGuids.filter(removeLinkedObjs);
            const targetTableGuids = rawTargetTableGuids.filter(removeLinkedObjs);

            // find all "missing" tables (tables that don't show up in Explore right now)
            const filterMissingTables = (id: string) => !tablesById[id];
            const lineageLeft = sourceTableGuids.length > 0;
            const lineageRight = targetTableGuids.length > 0;
            const notLoadedLeft = sourceTableGuids.filter(filterMissingTables);
            const notLoadedRight = targetTableGuids.filter(filterMissingTables);

            // which chevron to use? depends on which missing tables are active
            const lineageRightChevron = lineageRight ? 'close' : 'none';
            const lineageLeftChevron = lineageLeft ? 'close' : 'none';
            const chevronRight = notLoadedRight.length > 0 ? 'open' : lineageRightChevron;
            const chevronLeft = notLoadedLeft.length > 0 ? 'open' : lineageLeftChevron;

            const hasDbtModel = hasDbtLinkedObjs && options.showModels?.value;
            const isTablePinned = userPinnedTableId === key;
            const isTableOpen = openedTables.includes(key);
            const isTableActive = activeTables.includes(key);
            const isSearchActivated = activeSearchTables.includes(key);
            const isTableTargeted = [hoveredTableHeader, ...hoveredTablesTargets].includes(key);
            const isTableToBeRemoved = toBeRemovedTableTargets.includes(key);
            const showAllColumns = tableIdsShowAllColumns.includes(key);
            const columnsIds = sortedColumnIdsByTableId[key];
            const searchColumnName = searchColumnNamePerTable[key] ?? '';
            const tableName = name || key;
            const finalColorType = colorType === 'erd' ? 'erd' : dataSourceType;
            const isTableHasPinnedColumn = relatedColumnsOnPin.all.some((columnId) =>
              columnId?.includes(key),
            );

            const showSearchIcon =
              ((isTableHasPinnedColumn || isTablePinned) && columnsIds?.length > 1) ||
              Boolean(searchColumnNamePerTable[key]);
            const columnPositionOffset =
              (isTablePinned && isSearchActivated) || (isTableHasPinnedColumn && isSearchActivated)
                ? 1
                : 0;

            return (
              <ExploreTableFrame
                key={key}
                colorType={finalColorType}
                columnsIds={columnsIds}
                guid={key}
                isColumnPinned={Boolean(userPinnedColumns[0])}
                isTableActive={isTableActive || isSearchActivated}
                isTableGroup={
                  dataTypes?.objectType === 'tablegroup' && !settings?.useSimilarTablesV2
                }
                isTableImplicit={dataTypes?.dataType === 'implicit'}
                isTableOpen={isTableOpen || isSearchActivated}
                isTablePinned={isTablePinned}
                isTableTargeted={isTableTargeted}
                isTableToBeRemoved={isTableToBeRemoved}
                offset={isSearchActivated ? 1 : 0}
                setHoveringTable={setHoveringTable}
                showAllColumns={showAllColumns}
                toggleShowAll={toggleShowAll}
                x={xScale(x)}
                y={yScale(y)}
              >
                <ExploreHeading
                  chevronLeft={chevronLeft}
                  chevronRight={chevronRight}
                  componentIdentifier={componentIdentifier}
                  dataTypes={dataTypes}
                  exploreType={exploreType}
                  fullTableName={fullName}
                  guid={key}
                  hasDbtModel={hasDbtModel}
                  hideCloseButton={startingTablesIds.includes(key)}
                  isSearchActivated={isSearchActivated}
                  isTableHidden={isHidden}
                  isTablePinned={isTablePinned}
                  onChange={handleSearchExploreHeadingChange}
                  onClick={handleExploreHeadingClick}
                  onSearchIconClick={handleSearchIconClick}
                  openLineage={openLineage}
                  searchColumnName={searchColumnName}
                  setHoveringCloseLineage={setHoveringCloseLineage}
                  setHoveringTableHeader={setHoveringTableHeader}
                  showInputSearch={isSearchActivated}
                  showSearchIcon={showSearchIcon}
                  suppressTableIds={suppressTableIds}
                  tableKey={key}
                  tableName={tableName}
                />
                {isTableOpen && (
                  <ExploreColumns
                    allHoveredColumns={relatedColumnsOnHover.all}
                    allPinnedColumns={relatedColumnsOnPin.all}
                    columnPositionOffset={columnPositionOffset}
                    columnsIds={columnsIds}
                    componentIdentifier={componentIdentifier}
                    exploreType={exploreType}
                    isTableHidden={isHidden}
                    onClick={handleColumnClick}
                    onMouseEnter={handleOnColumnMouseEnter}
                    onMouseLeave={handleOnColumnMouseLeave}
                    openLineage={openLineage}
                    searchColumnName={searchColumnName}
                    showAllColumns={showAllColumns}
                    tableKey={key}
                    userPinnedColumn={userPinnedColumns[0]}
                  />
                )}
              </ExploreTableFrame>
            );
          })}
        </g>
        <ExploreTreeDefs />
      </StyledExploreTreeSvg>
    </ExploreTreeContext.Provider>
  );
};

export default React.memo<ExploreTreeProps>(ExploreTree);
