import React from 'react';

import { ColumnModel } from '@api/columns/ColumnModel';
import Button from '@components/Button/Button';
import MentionedByModalWithAnchor from '@components/MentionedBy/MentionedByModalWithAnchor';
import DeletedLabel from '@components/Table/DeletedLabel';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

import type { StyledColumnContraintProps } from './ColumnConstraint.styles';
import {
  StyledColumnConstraint,
  StyledColumnConstraintIconButton,
} from './ColumnConstraint.styles';

interface ColumnConstraintProps extends StyledColumnContraintProps {
  columnItem?: ColumnModel;
  editPk?: (value: ColumnModel) => void;
  hideConstraints?: boolean;
  isEditable?: boolean;
  showUsageBadges?: boolean;
}

const ColumnConstraint: React.FC<ColumnConstraintProps> = ({
  columnItem,
  editPk,
  gap = 0.5,
  hideConstraints,
  isEditable,
  showUsageBadges,
  ...other
}) => {
  const isClickable = isEditable && !!editPk;
  return (
    <StyledColumnConstraint gap={gap} {...other}>
      {!hideConstraints && (
        <>
          {columnItem && isClickable && (
            <StyledColumnConstraintIconButton
              onClick={() => {
                editPk?.(columnItem);
              }}
            >
              <Icon color="gray.400" name="plus" size="16px" />
            </StyledColumnConstraintIconButton>
          )}
          {columnItem?.isPrimaryKey && (
            <Button as="span" noHover variant="constraint">
              <Icon name="key" size="12px" />
              PK
            </Button>
          )}
          {columnItem?.isForeignKey && (
            <Button
              as={isClickable ? 'button' : 'span'}
              noHover={!isClickable}
              onClick={() => isClickable && editPk(columnItem)}
              variant="constraint"
            >
              <Icon name="key-gray" size="12px" />
              FK
            </Button>
          )}
          {columnItem?.isNullable === false && (
            <Button as="span" noHover variant="constraint">
              NOT NULL
            </Button>
          )}
          {columnItem?.isUnique && (
            <Button as="span" noHover variant="constraint">
              UNIQUE
            </Button>
          )}
        </>
      )}
      {showUsageBadges && (
        <>
          {columnItem?.isMention && <MentionedByModalWithAnchor guid={columnItem?.guid} />}
          {columnItem?.isMetric && (
            <MentionedByModalWithAnchor
              filterByMetrics
              guid={columnItem?.guid}
              iconName="metric"
              label="Metric"
              textColor={theme.colors.hyperlink}
            />
          )}
          {columnItem?.deactivationScheduledOn && <DeletedLabel />}
        </>
      )}
    </StyledColumnConstraint>
  );
};

export default ColumnConstraint;
