import parseLineageNodes from '@components/Explore.v1/useCreateNodesEdges/algorithm/parseLineageNodes';

import { NodesById } from '../useCreateNodesEdges/algorithm/types';
import { useExplore } from '../useExplore';
import useGetConfigQueryParams from '../useGetConfigQueryParams';

import { EXPLORE_NODE_PAGE_SIZE } from './useShowMore.constants';

const useShowMoreNodes = () => {
  const {
    biggestConflictEndPerStack,
    edgesById,
    initialPosition,
    isColumnLevelLineage,
    nodesById,
    previousTableLineageState,
    setEdgesById,
    setNodesById,
    stackData,
  } = useExplore();
  const { shouldHideFilterLineage } = useGetConfigQueryParams();

  const updateNodesOnShowMoreTables = (clickedNodeKey: string, stackedAt: number) => {
    const updateNodesByIdChildrenCounts = (nodesByIdObject: NodesById, nodeKey: string) => {
      if (nodesByIdObject[nodeKey]) {
        nodesByIdObject[nodeKey].data.shownChildrenCount =
          (nodesByIdObject[nodeKey].data.shownChildrenCount ?? EXPLORE_NODE_PAGE_SIZE) +
          EXPLORE_NODE_PAGE_SIZE;

        if (nodesByIdObject[nodeKey].nodeStacksConfig) {
          const currentStackShownChildrenCount = Number(
            nodesByIdObject[nodeKey].nodeStacksConfig![stackedAt].shownChildrenCount ??
              EXPLORE_NODE_PAGE_SIZE,
          );

          nodesByIdObject[nodeKey].nodeStacksConfig![stackedAt].shownChildrenCount =
            currentStackShownChildrenCount + EXPLORE_NODE_PAGE_SIZE;
        }
      }
    };

    updateNodesByIdChildrenCounts(stackData?.nodesById ?? {}, clickedNodeKey);

    if (isColumnLevelLineage) {
      updateNodesByIdChildrenCounts(
        previousTableLineageState.stackData?.nodesById ?? {},
        clickedNodeKey,
      );
    }

    const result = parseLineageNodes({
      edgesById: stackData?.edgesById ?? {},
      initialPosition,
      isColumnLevelLineage,
      nodesById: stackData?.nodesById ?? {},
      operationPivotNodeKey: clickedNodeKey,
      preCalculatedConflictEndPerStack: biggestConflictEndPerStack,
      preCalculatedNodesById: nodesById,
      shouldHideFilterLineage,
      stackGroups: stackData?.stackGroups,
    });

    setNodesById(result.nodesById);
    setEdgesById({ ...edgesById, ...result.edgesById });
  };

  return { updateNodesOnShowMoreTables };
};

export default useShowMoreNodes;
