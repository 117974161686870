import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

export const MentionedByModalLink = styled(RouterLink)`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.colors.hierarchyHoverBackground};
  padding: ${({ theme }) => theme.space(1.5, 0)};
  position: relative;
  transition: background-color 0.2s linear;

  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colors.hierarchyHoverBackground};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.v1.gray[50]};
  }
`;
