import React from 'react';

import { DashboardModel } from '@api/dashboards/DashboardModel';
import { DocumentModel } from '@api/documents/DocumentModel';
import { TagModel } from '@api/tags/TagModel';
import { TabContentProps } from '@components/Tabs/types';
import type { TabRoutesConfig } from '@components/TabsRouter';
import TabsRouter from '@components/TabsRouter';
import { MetadataData } from '@models/MetadataModel';
import { Filter } from '@utils';

import { ProfileType } from '../ProfilePage.types';

import OwnedDashboardsSubTab from './OwnedSubTabs/OwnedDashboardsSubTab';
import OwnedDocumentsSubTab from './OwnedSubTabs/OwnedDocumentsSubTab';
import OwnedMetricsSubTab from './OwnedSubTabs/OwnedMetricsSubTab';
import OwnedTablesSubTab from './OwnedSubTabs/OwnedTablesSubTab';
import OwnedTagsSubTab from './OwnedSubTabs/OwnedTagsSubTab';
import OwnedTermsSubTab from './OwnedSubTabs/OwnedTermsSubTab';

export interface ProfileOwnsTabProps extends TabContentProps {
  guid: string;
  profileType: ProfileType;
}
export interface ProfileOwnsSubtabProps extends TabContentProps {
  dashboards?: DashboardModel[];
  documents?: DocumentModel[];
  error?: boolean;
  filterService?: Filter.FilterServiceInterface;
  items?: MetadataData[];
  loading?: boolean;
  tags?: TagModel[];
}

const ProfileOwnsTab: React.FC<ProfileOwnsTabProps> = ({ guid, profileType, stickyHeader }) => {
  const tabsRouterConfig: TabRoutesConfig[] = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <OwnedTablesSubTab guid={guid} profileType={profileType} />,
      default: true,
      label: 'Tables',
      route: '/tables',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <OwnedDashboardsSubTab guid={guid} profileType={profileType} />,
      label: 'Dashboards',
      route: '/dashboards',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <OwnedDocumentsSubTab guid={guid} profileType={profileType} />,
      label: 'Pages',
      route: '/documents',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <OwnedMetricsSubTab guid={guid} profileType={profileType} />,
      label: 'Metrics',
      route: '/metrics',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <OwnedTermsSubTab guid={guid} profileType={profileType} />,
      label: 'Glossary',
      route: '/terms',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <OwnedTagsSubTab guid={guid} />,
      label: 'Tags',
      route: '/tags',
    },
  ];

  return <TabsRouter config={tabsRouterConfig} sticky={stickyHeader} subTabs />;
};

export default React.memo<ProfileOwnsTabProps>(ProfileOwnsTab);
