import { Expose, Type } from 'class-transformer';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { BiConnectionData, BiConnectionModel } from '@api/views/BiConnectionModel';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import DataTypesModel from '@models/DataTypesModel';
import { urlFor } from '@utils/routing';

export interface TableauTableData {
  database: BiConnectionData;
  description?: string;
  externalId: string;
  guid: string;
  isCertified: boolean;
  isEmbedded: boolean;
  name: string;
}

export class TableauTableModel {
  static objectType: string = 'tableautable';

  objectType: string = TableauTableModel.objectType;

  static typeDisplay: string = 'Tableau Table';

  typeDisplay: string = TableauTableModel.typeDisplay;

  guid!: string;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  @Expose({ name: 'external_id' })
  externalId!: string;

  name!: string;

  description?: string;

  @Expose({ name: 'is_embedded' })
  isEmbedded!: boolean;

  @Expose({ name: 'is_certified' })
  isCertified!: boolean;

  @Type(() => BiConnectionModel)
  database?: BiConnectionModel;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }
}
