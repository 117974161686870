import styled from '@emotion/styled';
import { Dropdown } from 'semantic-ui-react';

export const StyledDropdown = styled(Dropdown)`
  &.ui.dropdown {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 100%;
    height: 2.5rem;
  }

  &.ui.dropdown .menu > .item {
    padding: 0.5em 0.5rem !important;
    z-index: 12;
  }
`;
