import React, { useState } from 'react';

import { UserModel } from '@api/user/UserModel';
import Hidden from '@components/Hidden';

import BackdropStaticStyled from './BackdropStaticStyled';
import Notification from './Notification';
import ProfileAvatar from './ProfileAvatar';
import { ProfileMenuWrapper } from './ProfileMenu.styles';
import ProfileMenuItem, { ProfileMenuItemsProps } from './ProfileMenuItem';

interface ProfileMenuProps {
  hideNotification?: boolean;
  items: ProfileMenuItemsProps[];
  user: UserModel;
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({ hideNotification, items, user }) => {
  const [isBackdropActive, setBackdropActive] = useState(false);
  const [isActiveMenuProfile, setActiveMenuProfile] = useState(false);

  const handleMenuClose = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setActiveMenuProfile(false);
    setBackdropActive(false);
  };

  const handleMenuOpen = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setActiveMenuProfile(true);
    setBackdropActive(true);
  };

  return (
    <ProfileMenuWrapper>
      <Hidden alignItems="center" compDisplay="flex" spacing={1.5}>
        {!hideNotification && <Notification />}
        <ProfileAvatar onClick={handleMenuOpen} user={user} />
      </Hidden>
      {isActiveMenuProfile && (
        <ul
          className="profile-menu-list"
          data-testid="menu-list"
          onClick={handleMenuClose}
          onKeyDown={handleMenuClose}
        >
          {items.map(({ externalLink, icon, link, name, newTab }) => {
            return (
              <li key={link}>
                <ProfileMenuItem
                  externalLink={externalLink}
                  icon={icon}
                  link={link}
                  name={name}
                  newTab={newTab}
                />
              </li>
            );
          })}
        </ul>
      )}
      <BackdropStaticStyled
        data-testid="backdrop"
        isActive={isBackdropActive}
        onClick={handleMenuClose}
      />
    </ProfileMenuWrapper>
  );
};

export default ProfileMenu;
