import { DashboardUserQuerySummaryModel } from '@api/dashboards/DashboardUserQuerySummaryModel';
import { ExploreUserQuerySummaryModel } from '@api/explores/ExploreUserQuerySummaryModel';
import paginatedTransform from '@api/paginatedTransform';
import rawTransform from '@api/rawTransform';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import { PaginatedResponse } from '@models/PaginatedResponse';
import { TableQueryModel } from '@models/TableQueryModel';

import { DsUserModel } from './DsUserModel';

export const dsUsersCacheKeys = {
  dsUser: (id: string) => ['dsUsers', id],
  dsUserMostQueriedDashboards: (id: string) => ['dsUsers', 'userMostQueriedDashboards', id],
  dsUserMostQueriedExplores: (id: string) => ['dsUsers', 'userMostQueriedExplores', id],
  dsUsers: ['dsUsers'],
};

const fetchDsUserSelect = rawTransform(DsUserModel);
export const useFetchDsUser = (guid: string, options?: UseFetchOptions<DsUserModel>) => {
  return useFetch<DsUserModel>({
    ...options,
    queryKey: [...dsUsersCacheKeys.dsUser(guid), options?.params],
    select: fetchDsUserSelect,
    url: `/dsusers/${guid}/`,
  });
};

const fetchDsUsersSelect = paginatedTransform(DsUserModel);
export const useFetchDsUsers = (options: UseFetchOptions<PaginatedResponse<DsUserModel>>) => {
  return useFetch<PaginatedResponse<DsUserModel>>({
    ...options,
    queryKey: [...dsUsersCacheKeys.dsUsers, options?.params],
    select: fetchDsUsersSelect,
    url: `/dsusers/`,
  });
};

const fetchDsUserMostQueriedDashboardsSelect = paginatedTransform(DashboardUserQuerySummaryModel);
export const useFetchDsUserMostQueriedDashboards = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<DashboardUserQuerySummaryModel>>,
) => {
  return useFetch<PaginatedResponse<DashboardUserQuerySummaryModel>>({
    ...options,
    queryKey: [...dsUsersCacheKeys.dsUserMostQueriedDashboards(id), options?.params],
    select: fetchDsUserMostQueriedDashboardsSelect,
    url: `/dsusers/${id}/most-queried-dashboards/`,
  });
};

const fetchDsUserMostQueriedExploresSelect = paginatedTransform(ExploreUserQuerySummaryModel);
export const useFetchDsUserMostQueriedExplores = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<ExploreUserQuerySummaryModel>>,
) => {
  return useFetch<PaginatedResponse<ExploreUserQuerySummaryModel>>({
    ...options,
    queryKey: [...dsUsersCacheKeys.dsUserMostQueriedExplores(id), options?.params],
    select: fetchDsUserMostQueriedExploresSelect,
    url: `/dsusers/${id}/most-queried-explores/`,
  });
};

const fetchDsUserMostQueriedTablesSelect = paginatedTransform(TableQueryModel);
export const useFetchDsUserMostQueriedTables = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<TableQueryModel>>,
) => {
  return useFetch<PaginatedResponse<TableQueryModel>>({
    ...options,
    queryKey: [...dsUsersCacheKeys.dsUserMostQueriedExplores(id), options?.params],
    select: fetchDsUserMostQueriedTablesSelect,
    url: `/dsusers/${id}/most-queried-tables/`,
  });
};

export const usePatchDsUser = (guid: string, options?: UseMutationOptions<DsUserModel>) => {
  return useMutation<DsUserModel>({
    ...options,
    method: 'PATCH',
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(fetchDsUserSelect(data), variables, context);
    },
    url: `/dsusers/${guid}/`,
  });
};

export const usePatchDsUsersModeServiceAccount = (options?: UseMutationOptions<DsUserModel>) => {
  return useMutation<DsUserModel>({
    ...options,
    method: 'PATCH',
    url: `/dsusers/mode-service-account/`,
  });
};
