import React, { useMemo, useState } from 'react';
import {
  Link as RouterLink,
  RouteComponentProps,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { historyCacheKeys } from '@api/hierarchy';
import Box from '@components/Box';
import { useStickyProvider } from '@components/Sticky/Sticky';
import Icon from '@components/UI/Icon';
import { ReactComponent as AiIcon } from '@components/UI/Icon/sprites/individual-icons/ai.svg';
import { useUserContext } from '@context/User';
import { UserContextProps } from '@context/User/User';
import useClickOutside from '@hooks/useClickOutside';
import fetchClient from '@lib/fetchClient';
import HierarchyModelV1 from '@models/HierarchyModelV1';
import { PaginatedResponse } from '@models/PaginatedResponse';
import useLocalStorage from '@utils/useLocalStorage';

import {
  AppMainSidebarInner,
  AppMainSidebarMenuItem,
  AppMainSidebarPinSecondLevelNavButton,
  AppMainSidebarSecondLevelResizableNav,
  StyledAppMainSidebar,
} from './AppMainSidebar.styles';
import ChatBotHierarchy from './Hierarchy/ChatbotHierarchy';
import DataSourceHierarchy from './Hierarchy/DataSourceHierarchy';
import type { DataSourceHierarchyProps } from './Hierarchy/DataSourceHierarchy/DataSourceHierarchy';
import {
  getDefaultDataSourceGuid,
  getFirstDataSourceObjectUrl,
} from './Hierarchy/DataSourceHierarchy/DataSourceHierarchy';
import DocumentsHierarchy from './Hierarchy/DocumentsHierarchy';
import FavoritesHierarchy from './Hierarchy/FavoritesHierarchy';
import TagsHierarchy from './Hierarchy/TagsHierarchy';
import TeamsMenu from './TeamsMenu';

const HOVER_ITEM_DEBOUNCE_TIME = 350;
const RESIZE_SECOND_LEVEL_MENU_DEBOUNCE_TIME = 300;

export interface AppMainSidebarItem {
  children: React.ReactElement;
  getTo?: ({ currentPath }: { currentPath: string }) => string | undefined;
  icon: React.ReactNode;
  id: string;
  label: string;
  onClick?: ({
    dataSources,
  }: {
    dataSources: UserContextProps['dataSources'];
    push: RouteComponentProps['history']['push'];
  }) => void;
}

interface RedirectToFirstDataSourceLinkArgs {
  dataSources: UserContextProps['dataSources'];
  push: RouteComponentProps['history']['push'];
  type: DataSourceHierarchyProps['type'];
}

const redirectToFirstDataSourceLink = ({
  dataSources,
  push,
  type,
}: RedirectToFirstDataSourceLinkArgs) => {
  const { guid } = getDefaultDataSourceGuid(dataSources, type);

  const hierarchy = fetchClient.getQueryCache().find([...historyCacheKeys.hierarchy, { guid }])
    ?.state?.data as PaginatedResponse<HierarchyModelV1>;

  const url = getFirstDataSourceObjectUrl(hierarchy?.results?.[0]);

  if (url) {
    push(url);
  }
};

const defaultItems: AppMainSidebarItem[] = [
  {
    children: <ChatBotHierarchy />,
    getTo: ({ currentPath }) => {
      return currentPath.startsWith('/chatbot') ? undefined : '/chatbot';
    },
    // Refactor in sc-73998
    icon: <AiIcon height="24px" width="24px" />,
    id: 'chatbot',
    label: 'Ask AI',
  },
  {
    children: <DataSourceHierarchy type="dwh" />,
    icon: <Icon name="database" size="21px" />,
    id: 'data',
    label: 'Data',
    onClick: ({ dataSources, push }) => {
      redirectToFirstDataSourceLink({ dataSources, push, type: 'dwh' });
    },
  },
  {
    children: <DataSourceHierarchy type="bi" />,
    icon: <Icon name="dashboards-outlined" size="24px" />,
    id: 'dashboards',
    label: 'Dashboards',
    onClick: ({ dataSources, push }) => {
      redirectToFirstDataSourceLink({ dataSources, push, type: 'bi' });
    },
  },
  {
    children: <DocumentsHierarchy />,
    getTo: () => '/docs',
    icon: <Icon name="all-docs" size="24px" />,
    id: 'docs',
    label: 'Docs',
  },
  {
    children: <TagsHierarchy />,
    getTo: () => '/tags',
    icon: <Icon name="tag-outline" size="24px" />,
    id: 'tags',
    label: 'Tags',
  },
  {
    children: <TeamsMenu />,
    getTo: () => '/teams',
    icon: <Icon name="team" size="24px" />,
    id: 'teams',
    label: 'Teams',
  },
  {
    children: <FavoritesHierarchy />,
    icon: <Icon name="favorite-outlined" size="24px" />,
    id: 'favorites',
    label: 'Favorites',
  },
];

interface AppMainSidebarProps {
  items?: AppMainSidebarItem[];
}

const AppMainSidebar: React.FC<AppMainSidebarProps> = ({ items = defaultItems }) => {
  const history = useHistory();
  const { dataSources, user } = useUserContext();
  const { organization } = useUserContext();
  const { useLlmChatBot } = organization?.settings ?? {};
  const stickyProvider = useStickyProvider();
  const { path: currentPath } = useRouteMatch();

  const { hasBiDataSources, hasDwhDataSources } = useMemo(() => {
    const { dataSourcesList: biDataSourcesList } = getDefaultDataSourceGuid(dataSources, 'bi');
    const { dataSourcesList: dwhDataSourceList } = getDefaultDataSourceGuid(dataSources, 'dwh');

    return {
      hasBiDataSources: Boolean(biDataSourcesList?.length),
      hasDwhDataSources: Boolean(dwhDataSourceList?.length),
    };
  }, [dataSources]);

  const finalItems = useMemo(() => {
    const checkedItems = items.filter((i) => {
      if (i.id === 'chatbot') {
        return useLlmChatBot;
      }

      if (i.id === 'data') {
        return hasDwhDataSources;
      }

      if (i.id === 'dashboards') {
        return hasBiDataSources;
      }

      return true;
    });

    return checkedItems;
  }, [hasBiDataSources, hasDwhDataSources, items, useLlmChatBot]);

  const [secondLevelSidebarWidth, setSecondLevelSidebarWidth] = useLocalStorage(
    'secondLevelSidebarWidth',
    RESIZE_SECOND_LEVEL_MENU_DEBOUNCE_TIME,
  );
  const [activeMenuItemId, setActiveMenuItemId] = useLocalStorage(
    'activeMenuItemId',
    finalItems[0].label,
  );
  const [isSecondLevelMenuPinned, setSecondLevelMenuPinned] = useLocalStorage(
    'secondLevelMenuPinned',
    Boolean(user?.lastActivity),
  );
  const [hoveredMenuItemId, setHoveredMenuItemId] = useState(() => {
    return isSecondLevelMenuPinned ? activeMenuItemId : '';
  });
  const useClickOutsideRef = useClickOutside({
    onClick: () => {
      if (!isSecondLevelMenuPinned) {
        setHoveredMenuItemId('');
      }
    },
  });

  const setHoveredMenuItemIdDebounce = useDebouncedCallback(
    setHoveredMenuItemId,
    HOVER_ITEM_DEBOUNCE_TIME,
  );

  const headerHeight = stickyProvider?.elementsRef.current?.AppHeader?.current?.clientHeight;

  return (
    <StyledAppMainSidebar
      ref={useClickOutsideRef}
      onMouseEnter={() => {
        setHoveredMenuItemIdDebounce?.cancel?.();
      }}
      onMouseLeave={() => {
        setHoveredMenuItemIdDebounce(isSecondLevelMenuPinned ? activeMenuItemId : '');
      }}
      top={headerHeight}
    >
      <AppMainSidebarInner>
        {finalItems?.map(({ getTo, icon, label, onClick }) => {
          const to = getTo?.({ currentPath });

          return (
            <AppMainSidebarMenuItem
              key={label}
              as={to ? RouterLink : 'button'}
              isActive={label === activeMenuItemId}
              isHovered={label === hoveredMenuItemId}
              onClick={() => {
                setActiveMenuItemId(label);
                onClick?.({ dataSources, push: history.push });
              }}
              onMouseEnter={() => {
                if (!isSecondLevelMenuPinned) {
                  setHoveredMenuItemIdDebounce(label);
                }
              }}
              to={to}
            >
              {icon}
              {label}
            </AppMainSidebarMenuItem>
          );
        })}
      </AppMainSidebarInner>
      <AppMainSidebarSecondLevelResizableNav
        defaultSize={{
          height: '100%',
          width: secondLevelSidebarWidth,
        }}
        enable={{ right: true }}
        id="AppMainSidebarSecondLevelResizableNav"
        isPinned={isSecondLevelMenuPinned}
        isVisible={hoveredMenuItemId !== '' || isSecondLevelMenuPinned}
        maxWidth={500}
        minWidth={200}
        onResize={() => {
          setHoveredMenuItemIdDebounce(hoveredMenuItemId);
        }}
        onResizeStop={(_e, _direction, ref) => {
          setSecondLevelSidebarWidth(ref.offsetWidth);
        }}
      >
        <AppMainSidebarPinSecondLevelNavButton
          isActive={isSecondLevelMenuPinned}
          onClick={() => {
            setSecondLevelMenuPinned((prev) => !prev);
          }}
          title={isSecondLevelMenuPinned ? 'Unpin' : 'Pin'}
          variant="outlined"
        >
          <Icon
            color="currentColor"
            name={isSecondLevelMenuPinned ? 'pin-selected' : 'pin-unselected'}
            size="15px"
          />
        </AppMainSidebarPinSecondLevelNavButton>
        {finalItems?.map((item) => {
          const isVisible =
            (isSecondLevelMenuPinned ? activeMenuItemId : hoveredMenuItemId) === item?.label;

          return (
            <Box
              key={item.label}
              compDisplay={isVisible ? 'flex' : 'none'}
              compHeight="inherit"
              flexDirection="column"
              onMouseEnter={() => {
                setHoveredMenuItemIdDebounce(item.label);
              }}
            >
              {item?.children}
            </Box>
          );
        })}
      </AppMainSidebarSecondLevelResizableNav>
    </StyledAppMainSidebar>
  );
};

export default AppMainSidebar;
