export enum DashboardTypes {
  // Used for Looker
  answer = 'answer',
  dashboard = 'dashboard',
  data_studio_dashboard = 'data_studio_dashboard',
  liveboard = 'liveboard',
  // Used for Looker
  looker_look = 'look',
  metabase_dashboard = 'metabase_dashboard',
  periscope_dashboard = 'periscope_dashboard',
  power_bi_dashboard = 'power_bi_dashboard',
  power_bi_report = 'power_bi_report',
  report = 'report',
  sigma_dashboard = 'sigma_dashboard',
  workbook = 'workbook',
}
