import styled from '@emotion/styled';

import type { TypographyElement } from '@styles/theme/typography';

export const StyledPageHeaderBarWrapper = styled.div`
  container-name: page-header-bar;
  container-type: inline-size;
  flex-grow: 1;
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space(1)};
  flex-wrap: nowrap;

  @container page-header-bar (max-width: 600px) {
    flex-wrap: wrap;
  }
`;

export interface StyledPageHeaderBarTitleProps {
  titleFontSize?: TypographyElement;
}

export const StyledPageHeaderBarTitle = styled.h1<StyledPageHeaderBarTitleProps>`
  display: inline;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-size: ${({ theme, titleFontSize = 'h1' }) => theme.typography[titleFontSize].fontSize};
  font-weight: ${({ theme }) => theme.typography.h1.fontWeight};
  margin: ${({ theme }) => theme.space(0, 1, 0, 0)};
  position: relative;
  word-break: break-word;
  vertical-align: sub;
  height: 100%;
`;

export const StyledPageHeaderBarSupTitle = styled.div`
  color: ${({ theme }) => theme.colors.gray[500]};
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-size: ${({ theme }) => theme.typography.fontSizes.default};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  margin: 0;
  word-break: break-word;
`;

export interface StyledPageHeaderBarIconWrapperProps {
  height?: number;
  overwriteIconsSizes?: boolean;
  width?: number;
}

export const StyledPageHeaderBarIconWrapper = styled.div<StyledPageHeaderBarIconWrapperProps>`
  width: ${({ overwriteIconsSizes = true, theme }) =>
    overwriteIconsSizes ? theme.space(4) : undefined};
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    display: block;
    width: ${({ overwriteIconsSizes = true, width }) =>
      width && overwriteIconsSizes !== false ? `${width}px` : undefined};
    height: ${({ height, overwriteIconsSizes = true }) =>
      height && overwriteIconsSizes !== false ? `${height}px` : undefined};
  }
`;

export const StyledRightEdgeElementWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  width: auto;

  @container page-header-bar (max-width: 600px) {
    margin-top: ${({ theme }) => theme.space(1)};
    width: 100%;
  }
`;
