import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import Explore from '@components/Explore';
import ExploreV1 from '@components/Explore.v1/Explore.v1';
import { TabContentProps } from '@components/Tabs/types';
import { useUserContext } from '@context/User';
import { DataSourceTypesType } from '@models/DataSourceCredentials';

export interface ExploreTabProps extends TabContentProps {
  dataSourceType?: DataSourceTypesType;
  downstreamCount?: number;
  startingColumnId: string;
  startingTableId: string;
  upstreamCount?: number;
}

const ColumnExploreTab: React.FC<ExploreTabProps> = ({
  dataSourceType,
  downstreamCount,
  startingColumnId,
  startingTableId,
  upstreamCount,
}) => {
  const { url } = useRouteMatch();
  const location = useLocation();
  const { organization, user } = useUserContext();
  const searchParams = new URLSearchParams(location.search);
  const useNewLineage = organization?.settings?.useNewLineage && user?.settings?.enableNewLineage;

  if (useNewLineage || searchParams.get('version') === 'v0') {
    return <ExploreV1 prevRoute={url.replace('/lineage-explore', '')} />;
  }

  return (
    <Explore
      dataSourceType={dataSourceType}
      downstreamCount={downstreamCount}
      mode="all"
      prevRoute={url.replace('/lineage-explore', '')}
      renderContext="dbSource"
      startingColumnId={startingColumnId}
      startingTableId={startingTableId}
      upstreamCount={upstreamCount}
    />
  );
};

export default ColumnExploreTab;
