import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { usePatchUserVerification } from '@api/user';
import CircularLoader from '@components/CircularLoader';
import Link from '@components/Link';
import Text, { defaultParagraphStyles } from '@components/Text';
import TextScreen from '@components/TextScreen';
import TwoColumnDecorativeComponent from '@components/TwoColumnDecorativeComponent';
import TwoColumnLayout from '@components/TwoColumnLayout';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName, SegmentTrackPageName } from '@context/Segment/Segment.types';
import MetadataDecorator from '@utils/MetadataDecorator';

const EmailVerificationPage: React.FC = () => {
  const { code } = useParams<{ code: string }>();
  const history = useHistory();
  const segment = useSegmentContext();
  const { data, error, mutate } = usePatchUserVerification(code, {
    onError: (err) => {
      if (err && !err?.data?.is_verified) {
        segment?.page(SegmentTrackPageName.VerificationFailed, { code });
      }
    },
    onSuccess: () => {
      segment?.page(SegmentTrackPageName.VerificationSuccess, { code });
    },
    retry: false,
  });

  useEffect(() => {
    if (code) {
      mutate(null);
    }
  }, [mutate, code]);

  let leftComponent = <CircularLoader centered />;

  if (data) {
    leftComponent = (
      <TextScreen
        onClick={() => {
          segment?.track(SegmentTrackEventName.EmailVerificationGetStartedButtonClicked, { code });
          window.location.href = '/login';
        }}
        text="Congratulations! You have successfully verified your email address."
        title="Email verification succeeded"
      />
    );
  }

  if (error) {
    leftComponent = (
      <TextScreen
        text={
          <>
            <Text {...defaultParagraphStyles} mb={0.5}>
              There was an error in verifying your email address.
            </Text>
            <Link href="mailto:hello@selectstar.com" underline>
              Please contact us
            </Link>
          </>
        }
        title="Email verification failed"
      />
    );

    // email already verified
    if (error?.data?.is_verified) {
      leftComponent = (
        <TextScreen
          buttonText="Sign in"
          onClick={() => {
            history.push('/login');
          }}
          text={<Text {...defaultParagraphStyles}>This email has already been verified.</Text>}
          title="Email has already been verified"
        />
      );
    }
  }

  return (
    <>
      <MetadataDecorator title="Verify Email" />
      <TwoColumnLayout left={leftComponent} right={<TwoColumnDecorativeComponent />} />
    </>
  );
};

export default EmailVerificationPage;
