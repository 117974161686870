import DataStudioAddActivityDataStepForm from './DataStudioAddActivityDataStepForm';
import MetabaseAddActivityDataStepForm from './MetabaseAddActivityDataStepForm';
import TableauAddActivityDataStepForm from './TableauAddActivityDataStepForm';
import { DataSourceAddActivityDataStepProps } from './types';

const forms: { [key: string]: React.FC<DataSourceAddActivityDataStepProps> } = {
  metabase: MetabaseAddActivityDataStepForm,
  tableau: TableauAddActivityDataStepForm,
  data_studio: DataStudioAddActivityDataStepForm,
};

export default forms;
