import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { unmask } from './UnmaskPage.utils';

const UnmaskPage: React.FC = () => {
  useEffect(() => {
    unmask();
    // This forces page reload (rerender user specific stuff)
    requestAnimationFrame(() => document.location.reload());
  }, []);

  return <Redirect to="/" />;
};

export default UnmaskPage;
