import React from 'react';
import type { ElementType, ReactNode } from 'react';

import type { StyledBoxProps } from '@components/Box';
import Box from '@components/Box';
import Text from '@components/Text';
import theme from '@styles/theme';

type TitleViewCompSize = 'default' | 'lg' | 'sm';

export interface TitleViewProps extends Omit<StyledBoxProps, 'fontSize'> {
  action?: ReactNode;
  as?: ElementType;
  compSize?: TitleViewCompSize;
  icon?: ReactNode;
  subtitle?: React.ReactNode;
  title?: string | ReactNode;
}

const TitleView: React.FC<TitleViewProps> = ({
  action,
  as,
  compSize = 'default',
  icon,
  subtitle = '',
  title = '',
  ...rest
}) => {
  const fontSizeMap = {
    default: 'h2',
    lg: 'h1',
    sm: 'h3',
  } as const;

  const subtitleFontSizeMap = {
    default: theme.typography.fontSizes.body2,
    lg: '0.49em',
    sm: theme.typography.fontSizes.sm,
  } as const;

  const fontSize = theme.typography.fontSizes[fontSizeMap[compSize]];
  const subtitleFontSize = subtitleFontSizeMap[compSize];

  return (
    <Box fontSize={fontSize} {...rest}>
      <Box alignItems="center" compDisplay="flex">
        {title && (
          <Box
            alignItems="center"
            as={as}
            compDisplay="inline-flex"
            fontFamily="primary"
            fontSize="1em"
            fontWeight="bold"
            m={0}
            mr={1}
          >
            {icon}
            {title}
          </Box>
        )}
        {action}
      </Box>
      {subtitle && (
        <Text color={theme.colors.gray[600]} fontSize={subtitleFontSize} fontWeight="regular" m={0}>
          {subtitle}
        </Text>
      )}
    </Box>
  );
};

export default TitleView;
