import { removeDuplicatedOptions } from '@components/UI/Select.v1/Select.utils';
import { Option } from '@components/UI/Select.v1/types';
import { useUserContext } from '@context/User';

import { CreateTagModalPayload } from './types';

const useDefaultOwnerOptions = (initialState?: CreateTagModalPayload) => {
  const { user } = useUserContext();

  const currentOwnerOption = initialState?.owner
    ? {
        avatar: initialState.owner?.mappedAvatar,
        text: initialState.owner?.fullName ?? '',
        value: initialState.owner?.guid ?? '',
      }
    : undefined;

  const loggedUserOption = {
    avatar: user?.mappedAvatar,
    text: user?.fullName ?? '',
    value: user?.guid ?? '',
  };

  const validOptions = [currentOwnerOption, loggedUserOption].filter((opt) => Boolean(opt));

  const options = removeDuplicatedOptions(validOptions as Option[]);

  return { options, selectedOption: [currentOwnerOption ?? loggedUserOption] };
};

export default useDefaultOwnerOptions;
