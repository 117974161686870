import React, { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Box from '@components/Box';

import type { StyledBreadcrumbProps } from './Breadcrumbs.styles';
import {
  StyledBreadcrumb,
  StyledBreadcrumbItem,
  StyledBreadcrumbItemText,
  StyledBreadcrumbSeparator,
} from './Breadcrumbs.styles';
import { BreadcrumbItem } from './Breadcrumbs.types';

interface BreadcrumbsProps extends StyledBreadcrumbProps {
  clickable?: boolean;
  filterValue?: string;
  fixedUrl?: string;
  globalFilter?: string;
  items?: BreadcrumbItem[];
  showTrailingSlash?: boolean;
}

const BREADCRUMBS_SEPARATOR = ' / ';

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  clickable = true,
  filterValue,
  fixedUrl,
  globalFilter,
  items,
  showTrailingSlash = true,
  ...other
}) => {
  if (!items) {
    return null;
  }

  const searchWords = globalFilter?.split(/[.| ]+/) || filterValue?.split(/[.| ]+/) || [];
  const hasFixedUrl = Boolean(fixedUrl);

  const handleStopPropagation = (e: React.MouseEvent) => {
    if (clickable) {
      e.stopPropagation();
    }
  };

  return (
    <Box as="span" compDisplay="flex" noDefault onClick={handleStopPropagation}>
      <StyledBreadcrumb
        as={hasFixedUrl ? RouterLink : 'div'}
        clickable={hasFixedUrl && clickable}
        data-testid={clickable ? 'breadcrumbs' : 'not-clickable-breadcrumbs'}
        to={hasFixedUrl ? fixedUrl : undefined}
        {...other}
      >
        {items.map((item, index) => {
          const hasLink = Boolean(clickable && item?.url && !hasFixedUrl);
          const breadcrumbSeparator =
            index !== items.length - 1 || showTrailingSlash ? BREADCRUMBS_SEPARATOR : '';

          return (
            <Fragment key={item.guid}>
              <StyledBreadcrumbItem
                as={hasLink ? RouterLink : 'span'}
                clickable={hasLink}
                to={hasLink ? item.url : undefined}
              >
                <StyledBreadcrumbItemText searchWords={searchWords} textToHighlight={item.label} />
                {breadcrumbSeparator && (
                  <StyledBreadcrumbSeparator breadcrumbSeparator={breadcrumbSeparator}>
                    {BREADCRUMBS_SEPARATOR}
                  </StyledBreadcrumbSeparator>
                )}
              </StyledBreadcrumbItem>
            </Fragment>
          );
        })}
      </StyledBreadcrumb>
    </Box>
  );
};

export default Breadcrumbs;
