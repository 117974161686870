import React, { forwardRef } from 'react';

import CircularLoader from '@components/CircularLoader';
import Tooltip from '@components/Tooltip.v1';
import Icon, { IconProps } from '@components/UI/Icon';
import flags from '@features';

import { REACT_FLOW_CONTAINER_SELECTOR } from '../../Nodes/config';

import {
  StyledOptionsMenu,
  StyledOptionsMenuOption,
  StyledOptionsMenuOptionCount,
  StyledOptionsMenuSection,
} from './OptionsMenu.styles';

export interface OptionsMenuProps {
  onOptionClick?: () => void;
  sections: Array<{
    id: string;
    options: Array<{
      count?: number;
      icon: IconProps['name'];
      id: string;
      isDisabled?: boolean;
      isLoading?: boolean;
      label: string;
      onClick: (id: string) => void;
      tooltipMessage?: string;
    }>;
  }>;
}

const OptionsMenu = forwardRef<HTMLDivElement, OptionsMenuProps>(
  ({ onOptionClick, sections }, ref) => {
    const reactFlowContainer = document.querySelector(REACT_FLOW_CONTAINER_SELECTOR) as HTMLElement;

    return (
      <StyledOptionsMenu ref={ref} role="listbox">
        {sections.map(({ id, options }) => (
          <StyledOptionsMenuSection key={id}>
            {options.map(
              ({
                count,
                icon,
                id: optionId,
                isDisabled,
                isLoading,
                label,
                onClick,
                tooltipMessage,
              }) => (
                <Tooltip
                  key={optionId}
                  appendTo={reactFlowContainer}
                  content={tooltipMessage}
                  offset={[8, 15]}
                  placement="bottom-start"
                >
                  <StyledOptionsMenuOption
                    aria-disabled={isDisabled}
                    disabled={isDisabled}
                    isDisabled={isDisabled}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!isDisabled && !isLoading) {
                        onClick(optionId);
                        onOptionClick?.();
                      }
                    }}
                  >
                    {isLoading ? (
                      <CircularLoader borderWidth={1.75} compSize={2} />
                    ) : (
                      <Icon color="gray.400" name={icon} size="16px" />
                    )}
                    {label}
                    {flags.new_lineage_counts && count !== undefined && (
                      <StyledOptionsMenuOptionCount data-testid="option-count">
                        {count?.toLocaleString()}
                      </StyledOptionsMenuOptionCount>
                    )}
                  </StyledOptionsMenuOption>
                </Tooltip>
              ),
            )}
          </StyledOptionsMenuSection>
        ))}
      </StyledOptionsMenu>
    );
  },
);

export default OptionsMenu;
