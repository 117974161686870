import React from 'react';

import Box, { StyledBoxProps } from '@components/Box';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

import {
  StyledModalHeader,
  StyledModalHeaderCloseButton,
  StyledModalSubTitle,
  StyledModalTitle,
} from './Modal.styles';

export interface ModalHeaderProps extends StyledBoxProps {
  onClose?: () => void;
  showCloseButtonHover?: boolean;
  subtitle?: string;
  title?: React.ReactNode;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({
  children,
  onClose,
  showCloseButtonHover,
  subtitle,
  title,
  ...others
}) => {
  return (
    <StyledModalHeader {...others}>
      <Box compDisplay="flex" flexDirection="column" flexGrow={1}>
        <Box alignItems="center" compDisplay="flex" flexGrow={1}>
          {title && <StyledModalTitle>{title}</StyledModalTitle>}
        </Box>
        {subtitle && <StyledModalSubTitle>{subtitle}</StyledModalSubTitle>}
      </Box>
      {onClose && (
        <StyledModalHeaderCloseButton
          aria-label="close"
          my={-2}
          onClick={onClose}
          onKeyPress={onClose}
        >
          <Icon color="gray.500" name="close" size={theme.space(1.5)} />
        </StyledModalHeaderCloseButton>
      )}
      {children}
    </StyledModalHeader>
  );
};

export default ModalHeader;
