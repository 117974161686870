import { Editor, Element, Point, Range, Transforms } from 'slate';

import { toggleBlock } from '../helpers/blockHelpers';
import calculateCharsSelection from '../helpers/calculateCharsSelection';
import { getCurrentWord } from '../helpers/getCurrentWord';
import getMDInlineShortcut from '../helpers/getMDInlineShortcut';
import { BLOCK_SHORTCUTS } from '../helpers/shortcuts';

/**
 * Plugin that adds markdown shortcuts to the richtext editor
 */
const withMDShortcuts = (editor: Editor) => {
  const { deleteBackward, insertText } = editor;

  editor.insertText = (text) => {
    const { selection } = editor;

    /**
     * If the last inserted text was a space, we search for markdown shortcuts
     * in the previous text.
     */
    if (text.endsWith(' ') && selection && Range.isCollapsed(selection)) {
      const { anchor, focus } = selection;
      const block = Editor.above(editor, {
        match: (n) => Element.isElement(n) && Editor.isBlock(editor, n) && n.type === 'paragraph',
      });

      /**
       * Logic for handling block level MD shortcuts.
       */
      if (block) {
        const parent = block ? Editor.parent(editor, block[1]) : null;

        /**
         * If the block has a parent and that parent is any type other than paragraph
         * we don't want to apply the md shortcut.
         */
        if (parent && Element.isElement(parent[0]) && parent[0].type !== 'paragraph') {
          insertText(text);
          return;
        }

        const path = block ? block[1] : [];
        const start = Editor.start(editor, path);
        const range = { anchor, focus: start };
        const beforeText = Editor.string(editor, range) + text.slice(0, -1);

        /**
         * For ordered lists we must match any number followed by a '.'
         * and convert it to the list.
         */
        const type = beforeText.match(/^[0-9]*\.$/) ? 'ol' : BLOCK_SHORTCUTS[beforeText];

        if (type) {
          Transforms.select(editor, range);

          if (!Range.isCollapsed(range)) {
            Transforms.delete(editor);
          }

          toggleBlock(editor, type);
          return;
        }
      }

      // Logic for handling inline style MD shortcuts
      if (selection && Range.isCollapsed(selection)) {
        const shortcut = getMDInlineShortcut(editor, { anchor, focus });

        if (shortcut) {
          /**
           * Check if the previous character matches an inline shortcut and try to find
           * if previous word terminates with that same char.
           */
          const previousCharsSelection = calculateCharsSelection(
            {
              distanceFromAnchor: shortcut.key.length,
              distanceFromFocus: 0,
            },
            { anchor, focus },
            { direction: 'left' },
          );
          const matchRange = getCurrentWord(editor, previousCharsSelection, {
            directions: 'left',
            include: true,
            terminator: [shortcut.key],
          });
          const matchText = matchRange && Editor.string(editor, matchRange);

          /**
           * If we found a match, replace the text in that range with the markdown syntax
           * stripped and styling applied.
           */
          if (
            matchRange &&
            matchText &&
            matchText.length > 2 &&
            matchText.startsWith(shortcut.key)
          ) {
            Transforms.delete(editor, { at: matchRange });
            Transforms.insertNodes(editor, [
              {
                text: matchText.slice(shortcut.key.length, -shortcut.key.length),
                [shortcut.type]: true,
              },
              { text: ' ' },
            ]);
            return;
          }
        }
      }
    }

    insertText(text);
  };

  /**
   *  When deleting backwards at the start of a block, convert block to a paragraph
   *  if styling was previously applied.
   */
  editor.deleteBackward = (...args) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: (n) => Element.isElement(n) && Editor.isBlock(editor, n),
      });

      if (match) {
        const [block, path] = match;
        const start = Editor.start(editor, path);

        if (
          !Editor.isEditor(block) &&
          Element.isElement(block) &&
          block.type !== 'paragraph' &&
          Point.equals(selection.anchor, start)
        ) {
          toggleBlock(editor, 'paragraph');
          return;
        }
      }

      deleteBackward(...args);
    }
  };

  return editor;
};

export default withMDShortcuts;
