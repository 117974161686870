import { Type } from 'class-transformer';

import type { ColumnPayload } from '@api/columns/ColumnModel';
import { ColumnModel } from '@api/columns/ColumnModel';

export interface ConditionPayload {
  column: Pick<
    ColumnPayload,
    | 'breadcrumbs'
    | 'data_types'
    | 'external_type'
    | 'guid'
    | 'is_foreign_key'
    | 'is_nullable'
    | 'is_primary_key'
    | 'is_unique'
    | 'name'
    | 'search_name'
  >;
  ordinal: number;
}

class ConditionModel {
  ordinal!: number;

  @Type(() => ColumnModel)
  column!: ColumnModel;
}

export default ConditionModel;
