import React from 'react';

import { useDeleteInvitation } from '@api/invitations';
import { OrganizationInvitationModel } from '@api/invitations/OrganizationInvitationModel';
import Box from '@components/Box';
import { renderInfoToast } from '@components/Toast';

import DeleteConfirmationModal from './DeleteConfirmationModal';

export interface RevokeInvitationModalProps {
  invitation: OrganizationInvitationModel;
  onClose?: () => void;
  onRemoved?: (user: OrganizationInvitationModel) => void;
}

const RevokeInvitationModal: React.FC<RevokeInvitationModalProps> = ({
  invitation,
  onClose,
  onRemoved,
}) => {
  const { error, isLoading, mutate } = useDeleteInvitation(invitation.guid, {
    onSuccess: () => {
      renderInfoToast('Invitation revoked.');
      onRemoved?.(invitation);
      onClose?.();
    },
  });

  return (
    <DeleteConfirmationModal
      error={error?.data?.detail || error?.data?.non_field_errors || error?.message}
      loading={isLoading}
      onClose={onClose}
      onRemove={() => mutate(null)}
      open
      title="Revoke Invitation"
      useContext
    >
      <Box as="span">
        Are you sure you want to revoke invitation for{' '}
        <Box as="span" fontWeight="bold">
          {invitation.email}
        </Box>
        ?
      </Box>
    </DeleteConfirmationModal>
  );
};

export default RevokeInvitationModal;
