import { Edge as ReactFlowEdge, Node as ReactFlowNode } from 'reactflow';

import { NewLineageItemModel } from '@api/lineage/mapNewLineage';
import { UsageTypeType } from '@api/lineage/types';
import DataTypesModel from '@models/DataTypesModel';

export type NodeStacksConfig = Record<
  number,
  {
    hiddenChildren: Set<string>;
    hiddenChildrenCount: number;
    shownChildrenCount: number;
  }
>;

export interface ExploreNode extends ReactFlowNode<ExploreNodeData> {
  allChildrenKeys?: string[];
  endStackId?: number;
  hasCollapsedEdges?: boolean;
  key?: string;
  nodeStacksConfig?: NodeStacksConfig;
  parent?: string;
  stackedAt?: number;
  startStackId?: number;
}

export type EdgeType = 'column' | 'manual' | 'table';

export interface ExploreEdgeData {
  collapsedEdges?: ExploreEdge[];
  isUnfocused?: boolean;
  side?: 'left' | 'right';
  sourceTargetSameStack?: boolean;
  type?: EdgeType;
}

export type ExploreEdge = ReactFlowEdge<ExploreEdgeData>;

export interface ExploreNodeData {
  children?: Set<string>;
  childrenCount?: number;
  childrenSearchText?: string;
  columnsCount?: number;
  dataTypes?: DataTypesModel;
  databaseGuid?: string;
  downstreamObjectsCount?: number;
  edgesLoaded?: boolean;
  guid?: string;
  hasRelevantColumnLineage?: boolean;
  hiddenChildren?: Set<string>;
  hiddenChildrenCount?: number;
  hideColumns?: boolean;
  hideDownstreamButton?: boolean;
  hideUpstreamButton?: boolean;
  highlightedText?: string;
  id?: string;
  isBITable?: boolean;
  isBITableColumn?: boolean;
  isNested?: boolean;
  isOpen?: boolean;
  isSearchEnabled?: boolean;
  isSelected?: boolean;
  isUnfocused?: boolean;
  key?: string;
  label?: string;
  linkedObjs?: string[];
  name?: string;
  noMatchedChildren?: boolean;
  onClick?: () => void;
  onExpandNode?: () => void;
  parent?: string;
  parents?: string[];
  popularity?: number;
  schemaGuid?: string;
  shownChildrenCount?: number;
  stackedAt?: number;
  style?: React.CSSProperties;
  tablesCount?: number;
  upstreamObjectsCount?: number;
  usage?: UsageTypeType[];
}

export interface InputNode extends NewLineageItemModel {
  hideDownstreamButton?: boolean;
  hideUpstreamButton?: boolean;
  shouldSkipEdgesCalculation?: boolean;
  usage?: UsageTypeType[];
}

export type InputNodesById = Record<string, InputNode>;

export enum LineageInteractions {
  FitLineageView = 'FitLineageView',
  FocusOnNode = 'FocusOnNode',
  ToggleColumnLevelLineage = 'ToggleColumnLevelLineage',
}

export enum LineageSidebarTabs {
  LineageTree = 'LineageTree',
  Overview = 'Overview',
}

export interface UserInteraction {
  payload?: Record<string, any>;
  type: LineageInteractions;
}

export interface LineageLoadingState {
  delay?: number;
  enabled: boolean;
  type: 'initial' | 'expansion' | 'column-level' | 'generic-operation';
}
