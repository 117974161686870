import styled from '@emotion/styled';
import * as CSS from 'csstype';

import type { StyledBoxProps } from '@components/Box';

export interface StyledLinkProps extends StyledBoxProps {
  compDisplay?: CSS.Property.Display;
  compWidth?: string;
  disabled?: boolean;
  fontSize?: CSS.Property.FontSize;
  href?: string;
  pointerEvents?: CSS.Property.PointerEvents;
  to?: string;
  underline?: boolean;
  underlineOnHover?: boolean;
}

const StyledLink = styled.a<StyledLinkProps>`
  color: ${({ color, theme }) => color ?? theme.colors.hyperlink};
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-size: ${({ fontSize, theme }) => fontSize || theme.typography.fontSizes.default};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  width: ${({ compWidth }) => compWidth};
  pointer-events: ${({ disabled, pointerEvents }) => (disabled ? 'none' : pointerEvents)};

  &:hover,
  &:active {
    text-decoration: ${({ underlineOnHover }) => (underlineOnHover ? 'underline' : 'none')};
    color: ${({ color, theme }) => color ?? theme.colors.hyperlink};
  }

  &:focus {
    outline-width: thin;
    outline-style: auto;
    color: ${({ color = '#4c288f' }) => color};
  }
`;

export default StyledLink;
