import React from 'react';

import type { CheckboxProps } from '@components/UI/Form/Checkbox';

import { StyledTree } from './Tree.v2.styles';
import type { TreeNodeProps } from './TreeNode.v2';
import TreeNode from './TreeNode.v2';
import type { GetKeyType, TreeNodeType } from './types';
import { TreeKey } from './types';
import useHighlighted from './useHighlighted';
import useLoadData from './useLoadData';

interface TreeProps<T>
  extends Pick<
    TreeNodeProps,
    'expandOnNodeClick' | 'hoverBackgroundColor' | 'hoverFontWeight' | 'hoverLetterSpacing'
  > {
  allowCarrot?: (node: TreeNodeType<T>) => boolean;
  allowDataLoad?: (node: TreeNodeType<T>) => boolean;
  checkboxProps?: (node: TreeNodeType<T>) => CheckboxProps;
  className?: string;
  disableHover?: (node: TreeNodeType<T>) => boolean;
  expandedKeys?: TreeKey[];
  getKey: GetKeyType<T>;
  /*
   *  getNodeExtraProps must return props that will passed to the node element.
   *  You can use it to add new dynamic props to the node, like props related to keyboard navigation.
   */
  getNodeExtraProps?: (node: TreeNodeType<T>) => any;
  highlightedKeys?: TreeKey[];
  offset?: (level: number, node: TreeNodeType<T>) => number;
  onCarrotClick?: (node: TreeNodeType<T>, isExpanded: boolean) => void;
  onDataLoad?: (node: TreeNodeType<T>) => Promise<any>;
  onNodeClick?: (node: TreeNodeType<T>) => void;
  onNodeMount?: (node: TreeNodeType<T>) => void;
  renderLabel: (node: TreeNodeType<T>, isCarrotVisible?: boolean) => React.ReactNode;
  showCheckbox?: (node: TreeNodeType<T>) => boolean;
  treeData?: T[];
}

const Tree = <T,>({
  allowCarrot,
  allowDataLoad = () => true,
  checkboxProps,
  className,
  disableHover,
  expandOnNodeClick,
  expandedKeys = [],
  getKey,
  getNodeExtraProps,
  highlightedKeys = [],
  hoverBackgroundColor,
  hoverFontWeight,
  hoverLetterSpacing,
  offset,
  onCarrotClick,
  onDataLoad,
  onNodeClick,
  onNodeMount,
  renderLabel,
  showCheckbox,
  treeData = [],
}: TreeProps<T>) => {
  const [highlighted, setHighlighted] = useHighlighted(highlightedKeys);
  const { handleDataLoad, loadedTreeData, loadingNodes } = useLoadData<T>({
    getKey,
    onDataLoad,
  });

  const handleOnExpand = (node: TreeNodeType<T>, expanded: boolean) => {
    if (allowDataLoad?.(node) && expanded) {
      handleDataLoad?.(node);
    }
  };

  const handleNodeClick = (node: TreeNodeType<T>) => {
    if (!showCheckbox) {
      setHighlighted((prev) => [...prev, node.key]);
    }
    onNodeClick?.(node);
  };

  const parseNodes = (nodes: T[], { level = 0 } = {}) => {
    if (!nodes) {
      return null;
    }

    return nodes?.map?.((item, index) => {
      const key = getKey(item);
      const node: TreeNodeType<T> = {
        index,
        key,
        level,
        original: item,
      };
      const hasChildren = node.original.children && node.original.children.length > 0;
      const children = hasChildren ? node.original.children : loadedTreeData[key];
      const isCarrotVisible = allowCarrot?.(node) ?? hasChildren;
      const sharedProps: TreeNodeProps = {
        checkboxProps: checkboxProps?.(node),
        disableHover: disableHover?.(node),
        expandOnNodeClick,
        hoverBackgroundColor,
        hoverFontWeight,
        hoverLetterSpacing,
        isCarrotVisible,
        isDefaultExpanded: expandedKeys.includes(key),
        isHighlighted: !showCheckbox?.(node) ? highlighted.includes(key) : false,
        isLoading: loadingNodes[key],
        label: renderLabel(node, isCarrotVisible),
        level,
        nodeExtraProps: getNodeExtraProps?.(node),
        offset: () => offset?.(level, node),
        onCarrotClick: (isExpanded) => onCarrotClick?.(node, isExpanded),
        onExpand: (expanded) => handleOnExpand(node, expanded),
        onMount: () => onNodeMount?.(node),
        onNodeClick: () => handleNodeClick(node),
        showCheckbox: showCheckbox?.(node),
      };

      if (children) {
        return (
          <TreeNode {...sharedProps} key={key}>
            {parseNodes(children, { level: level + 1 })}
          </TreeNode>
        );
      }

      return <TreeNode {...sharedProps} key={key} />;
    });
  };

  return (
    <StyledTree className={className} role="tree">
      {parseNodes(treeData)}
    </StyledTree>
  );
};

export default React.memo<TreeProps<any>>(Tree);
