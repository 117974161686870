import React from 'react';

import Box from '@components/Box';
import Button from '@components/Button/Button';
import Text, { defaultParagraphStyles } from '@components/Text';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

import { StyledList } from '../../AdminBillingPage.styles';
import { Subscription } from '../../AdminBillingPage.types';

interface SubscriptionCardProps {
  activePriceId?: string;
  onButtonClick: (id?: string, subscriptionName?: string, isChangePlanButton?: boolean) => void;
  subscription: Subscription;
  yearlyPrices?: boolean;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  activePriceId,
  onButtonClick,
  subscription,
  yearlyPrices,
}) => {
  const {
    buttonText = 'Subscribe',
    dataSources,
    features,
    featuresTagline,
    name,
    price,
    priceId,
    tables,
    users,
    yearlyPrice,
    yearlyPriceId,
  } = subscription;

  const getActive = () => {
    if (!activePriceId) return false;
    if (yearlyPrices) return activePriceId === yearlyPriceId;
    return activePriceId === priceId;
  };

  const active = getActive();
  const isEnterpriseButton = name === 'Enterprise';
  const isChangePlanButton = !!activePriceId && !isEnterpriseButton;

  return (
    <Box
      alignItems="center"
      backgroundColor="white"
      border={active ? '2px solid' : '1px solid'}
      borderColor={active ? 'primary.500' : 'gray.200'}
      borderRadius="0.4rem"
      compDisplay="flex"
      compWidth="100%"
      flexDirection="column"
      p={2}
    >
      <Box
        alignItems="start"
        borderBottom="1px solid"
        borderColor="#d8dbe0"
        compDisplay="flex"
        compWidth="100%"
        flexDirection="column"
        mb={2}
        pb={3}
        position="relative"
      >
        {active && (
          <Box position="absolute" right={0} top={0}>
            <Text as="div" color="primary.900" fontSize="15px" fontWeight="bold" m={0} mb={1}>
              Your Current Plan
            </Text>
          </Box>
        )}
        <Text as="h2" fontSize="24px" fontWeight="bold" m={0} mb={1}>
          {name}
        </Text>
        <Text as="h2" fontSize="24px" fontWeight="bold" mb={1} mt={0}>
          {yearlyPrices ? yearlyPrice : price}
        </Text>
        <Text as="div" {...defaultParagraphStyles} fontSize="15px" lineHeight={2}>
          <StyledList>
            <li>
              <b>{`${dataSources[0]} `}</b>
              {dataSources[1]}
            </li>
            <li>
              <b>{`${users} `}</b>
              Users
            </li>
            <li>
              <b>{`${tables} `}</b>
              Tables
            </li>
          </StyledList>
        </Text>
      </Box>
      <Box compDisplay="flex" compHeight="100%" compWidth="100%" flexDirection="column" gap={2}>
        <Text {...defaultParagraphStyles} color={theme.colors.gray[700]} fontSize="14px" mb={0.5}>
          {featuresTagline}
        </Text>
        <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={0.5}>
          {features &&
            features.map((feature) => (
              <Box key={feature} alignItems="center" compDisplay="flex" gap={1}>
                <Icon color="#409C6C" name="check" size="16px" />
                {feature}
              </Box>
            ))}
        </Box>
        {!active && (
          <Box compHeight="2rem" mt="auto">
            <Button
              backgroundColor={isEnterpriseButton ? theme.colors.v1.primary[900] : undefined}
              fluid
              onClick={() =>
                onButtonClick(
                  yearlyPrices ? yearlyPriceId : priceId,
                  subscription.name,
                  isChangePlanButton,
                )
              }
              variant={isChangePlanButton ? 'outlined' : 'default'}
            >
              <Text as="span" color="inherit" fontSize="13px" fontWeight="medium">
                {isChangePlanButton ? 'Change Plan' : buttonText}
              </Text>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SubscriptionCard;
