import React from 'react';

import { TagModel } from '@api/tags/TagModel';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import Text from '@components/Text';
import HR from '@components/UI/HR';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import { useUserContext } from '@context/User';

interface EmptyMessageProps {
  hideCategoryTags?: boolean;
  onCreateNewTagModalClose?: (newTagId?: string) => void;
  searchInputValue?: string;
}
const EmptyMessage: React.FC<EmptyMessageProps> = ({
  hideCategoryTags,
  onCreateNewTagModalClose,
  searchInputValue,
}) => {
  const { MODAL_IDS, openModal } = useModal();

  const { hasEditPermissions } = useUserContext();

  return (
    <Box>
      <HR mb={0} mt={0} />
      <Text color="gray.600" fontSize="sm" mb={0} px={1.75} py={1.75}>
        No Result Found.
      </Text>
      {hasEditPermissions && (
        <>
          <HR mb={0} mt={0} />
          <Button
            color="primary.600"
            compSize="xs"
            fontSize="body2"
            mx={0.75}
            my={0.75}
            onClick={() =>
              openModal(MODAL_IDS.createTag, {
                initialState: {
                  name: searchInputValue,
                  type: hideCategoryTags ? 'status' : 'category',
                } as TagModel,
                onClose: onCreateNewTagModalClose,
              })
            }
            startIcon={<Icon color="currentColor" name="plus" size="16px" />}
            variant="text"
          >
            Create a New Tag
          </Button>
        </>
      )}
    </Box>
  );
};

export default EmptyMessage;
