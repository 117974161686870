import React from 'react';
import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

import { CommentModel } from '@api/comments/CommentModel';
import Icon from '@components/UI/Icon';
import { urlFor } from '@utils/routing';

const StyledLink = styled(RouterLink)`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.5rem 0;
  color: ${({ theme }) => theme.colors.text};

  &:hover {
    color: ${({ theme }) => theme.colors.text};
  }
`;

const InReplyToComment = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.gray[600]};
  line-height: 1rem;
  padding: 0 0 0.5rem;
`;

const MainComment = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  line-height: 1rem;
`;

interface CommentCellProps {
  comment: CommentModel;
}

const CommentCell = React.memo((props: CommentCellProps) => {
  const { inReplyTo, message, target } = props.comment;

  const inReplyToText = inReplyTo?.message ? `"${inReplyTo?.message}"` : '';
  const mainCommentText = message ? `"${message}"` : '';
  const url = urlFor(target.obj, true);
  const link = target ? `${url}/discussion` : '#';

  return (
    <StyledLink to={link}>
      {inReplyTo && <InReplyToComment>{inReplyToText}</InReplyToComment>}
      <MainComment>
        {inReplyTo && mainCommentText && (
          <Icon mb={0.1} mr={0.4} name="reply-arrow" size="0.7rem" />
        )}
        {mainCommentText}
      </MainComment>
    </StyledLink>
  );
});

export default CommentCell;
