import { Option } from '@components/UI/Select.v1/types';

export type OrganizationUserRole = 'admin' | 'user' | 'data_manager';

export const OrganizationUserRoleMap: Record<string, string> = {
  admin: 'Admin',
  data_manager: 'Data Manager',
  user: 'Viewer',
};

export const ROLE_OPTIONS: Option[] = [
  { text: 'Viewer', value: 'user' },
  { text: 'Data Manager', value: 'data_manager' },
  { text: 'Admin', value: 'admin' },
];
