import React from 'react';

import { folderCacheKeys } from '@api/bifolder';
import { exploresCacheKeys } from '@api/explores';
import invalidateCache from '@api/invalidateCache';
import { tableauCacheKeys } from '@api/tableau';
import { useDeleteTag } from '@api/tags';
import { TagModel } from '@api/tags/TagModel';
import { viewsCacheKeys } from '@api/views';
import AppTag from '@components/AppTag';
import Box from '@components/Box';
import { renderInfoToast } from '@components/Toast';
import fetchClient from '@lib/fetchClient';

import DeleteConfirmationModal from './DeleteConfirmationModal';

export interface DeleteTagModalProps {
  item: TagModel;
  onClose?: () => void;
  onDeleteTag?: (tagGuid: string) => void;
}

const DeleteTagModal: React.FC<DeleteTagModalProps> = ({ item, onClose, onDeleteTag }) => {
  const { error, isLoading, mutate } = useDeleteTag(item.guid, {
    onSuccess: () => {
      invalidateCache((keys) => [
        keys.columns.all,
        keys.dashboards.all,
        keys.tableau.all,
        keys.documents.all,
        keys.tables.all,
        keys.tags.all,
        keys.tags.hierarchy,
        keys.queries.all,
        keys.lookML.views,
      ]);
      fetchClient.invalidateQueries(folderCacheKeys.folders);
      fetchClient.invalidateQueries(exploresCacheKeys.explores);
      fetchClient.invalidateQueries(viewsCacheKeys.views);
      fetchClient.invalidateQueries(tableauCacheKeys.datasources);
      onDeleteTag?.(item.guid);
      renderInfoToast('Tag Deleted');
      onClose?.();
    },
  });

  return (
    <DeleteConfirmationModal
      error={error?.data?.detail || error?.data?.non_field_errors || error?.message}
      loading={isLoading}
      onClose={onClose}
      onRemove={() => mutate(null)}
      open
      title="Delete Tag"
      useContext
    >
      <Box alignItems="center" compDisplay="flex" gap={0.5}>
        Are you sure you want to delete <AppTag noLink tag={item} variant="outlined" /> ?
      </Box>
    </DeleteConfirmationModal>
  );
};

export default DeleteTagModal;
