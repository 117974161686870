import React from 'react';

import Box, { StyledBoxProps } from '@components/Box';
import Text from '@components/Text';
import Icon, { IconProps } from '@components/UI/Icon';
import theme from '@styles/theme';

type StatusType = 'error' | 'warning' | 'success' | 'paused';

interface StatusConfig {
  backgroundColor: StyledBoxProps['color'];
  color: StyledBoxProps['color'];
  icon: IconProps['name'];
  text: string;
}

export const statusMap: Record<StatusType, StatusConfig> = {
  error: {
    backgroundColor: '#FFE9E9',
    color: '#CA3544',
    icon: 'error',
    text: 'Failed',
  },
  paused: {
    backgroundColor: '#EAECF0',
    color: '#1D2939',
    icon: 'pause-square',
    text: 'Paused',
  },
  success: {
    backgroundColor: '#D5F4EF',
    color: '#0E6964',
    icon: 'success',
    text: 'Passed',
  },
  warning: {
    backgroundColor: '#FFF9DF',
    color: '#44464A',
    icon: 'warning',
    text: 'Warning',
  },
};

interface StatusProps {
  icon?: IconProps['name'];
  noIcon?: boolean;
  status?: StatusType;
}

const Status: React.FC<StatusProps> = ({ children, icon, noIcon, status }) => {
  if (!status) {
    return null;
  }

  const props = statusMap?.[status];

  if (!props) {
    return null;
  }

  const startIcon = () => {
    if (!noIcon && (icon || props.icon)) {
      return <Icon color="currentColor" name={icon ?? props.icon} size="16px" />;
    }

    return null;
  };

  return (
    <Box
      alignItems="center"
      backgroundColor={props.backgroundColor}
      borderRadius="default"
      compDisplay="flex"
      compHeight={theme.space(2.5)}
      gap={0.25}
      maxWidth="max-content"
      pl={0.5}
      pr={0.625}
      role="status"
    >
      {startIcon()}
      <Text color={props.color} fontSize="sm" fontWeight="medium" lineHeight="16px">
        {children ?? props.text}
      </Text>
    </Box>
  );
};

export default Status;
