import React, { useEffect, useState } from 'react';
import { BaseEditor, Descendant } from 'slate';
import { HistoryEditor } from 'slate-history';
import { Editable, ReactEditor, Slate } from 'slate-react';

import { RenderElementMap } from '@components/RichTextEditor/RenderElements/RenderElement';

import useEditorConfig from './Hooks/useEditorConfig';
import MiniToolbar from './MiniToolbar/MiniToolbar';
import { StyledRichTextEditor } from './RichTextEditor.styles';
import { RichTextEditorElement } from './RichTextEditor.types';
import RichTextEditorControls from './RichTextEditorControls';

export interface RichTextEditorProps {
  allowedElements?: RichTextEditorElement[];
  className?: string;
  controlsRightElement?: React.ReactNode;
  editable?: boolean;
  editor: BaseEditor & ReactEditor & HistoryEditor;
  elementMap?: RenderElementMap;
  fluid?: boolean;
  initialState: Descendant[];
  isSuggested?: boolean;
  mentionSuggestionElement?: React.ReactElement | null;
  mentionTriggerHandler?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  onBlur?: () => void;
  onCancel?: () => void;
  onChange: (state: Descendant[]) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  onFocus?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  placeholder?: string;
  scrollable?: boolean;
  shouldFocusOnEdit?: boolean;
  showToolbar?: boolean;
  textToHighlight?: string;
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  allowedElements,
  className,
  controlsRightElement,
  editable,
  editor,
  elementMap,
  fluid = true,
  initialState,
  isSuggested,
  mentionSuggestionElement,
  mentionTriggerHandler,
  onBlur,
  onCancel,
  onChange,
  onClick,
  onFocus,
  onKeyDown,
  placeholder,
  scrollable,
  shouldFocusOnEdit = true,
  showToolbar = true,
  textToHighlight,
}) => {
  const [blurEditorAllowed, setBlurEditorAllowed] = useState(true);

  const {
    decorate,
    onKeyDown: baseKeyDown,
    renderElement,
    renderLeaf,
  } = useEditorConfig(editor, textToHighlight, onCancel);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const newState = mentionTriggerHandler?.(e);
    baseKeyDown(newState ?? e);
    onKeyDown?.(e);
  };

  const handleChange = (value: Descendant[]) => {
    onChange(value);
  };

  useEffect(() => {
    if (editable && shouldFocusOnEdit) {
      // Without timeout here editor gains focus but immediately loses it
      setTimeout(() => ReactEditor.focus(editor), 10);
    }
  }, [editable, shouldFocusOnEdit]);

  return (
    <StyledRichTextEditor
      className={className}
      editable={editable}
      fluid={fluid}
      isSuggested={isSuggested}
      onClick={onClick}
      showToolbar={showToolbar}
    >
      <Slate editor={editor} initialValue={initialState} onChange={handleChange}>
        {showToolbar && editable && (
          <RichTextEditorControls
            allowedElements={allowedElements}
            rightElement={controlsRightElement}
            scrollable={scrollable}
          />
        )}
        {editable && (
          <MiniToolbar
            allowedElements={allowedElements}
            onLinkModalClose={() => setBlurEditorAllowed(true)}
            onLinkModalOpen={() => setBlurEditorAllowed(false)}
          />
        )}
        <Editable
          className="slate-editable"
          data-testid="slate-editable"
          decorate={decorate}
          onBlur={() => {
            if (blurEditorAllowed) {
              onBlur?.();
            }
          }}
          onFocus={onFocus}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          readOnly={!editable}
          renderElement={renderElement(elementMap, allowedElements)}
          renderLeaf={renderLeaf}
        />
        {editable && mentionSuggestionElement}
      </Slate>
    </StyledRichTextEditor>
  );
};

export default RichTextEditor;
