import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

import { usePostMask } from '@api/mask';
import Alert from '@components/Alert';
import Button from '@components/Button/Button';
import { GridContainer } from '@components/Grid';
import { renderInfoToast } from '@components/Toast';
import MetadataDecorator from '@utils/MetadataDecorator';

import { StyledFormWrapper } from './MaskPage.styles';
import { mask } from './MaskPage.utils';

const MaskPage: React.FC = () => {
  const history = useHistory();
  const [email, setEmail] = React.useState('');
  const { search: searchLocation } = useLocation();
  const params = new URLSearchParams(searchLocation);
  const userEmailLocation = params.get('user') || undefined;
  const redirectUrl = params.get('redirectUrl') || undefined;

  const { error, isLoading, mutate } = usePostMask({
    onSuccess: (data) => {
      mask(data);
      renderInfoToast('Masked successfully.');
      // This forces page reload (rerender user/org specific stuff)
      requestAnimationFrame(() => document.location.reload());
    },
  });

  const onClick = () => {
    if (!isLoading) {
      mutate({ email });
      if (redirectUrl) {
        history.push(redirectUrl);
      }
    }
  };

  useEffect(() => {
    if (userEmailLocation) {
      setEmail(userEmailLocation);
    }
  }, [userEmailLocation]);

  return (
    <>
      <MetadataDecorator title="Mask" />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <StyledFormWrapper>
          <Form error={!!error} loading={isLoading}>
            <Form.Group>
              <Form.Input
                error={error?.data && error.data?.email}
                label="Email"
                onChange={(_, d) => setEmail(d.value as string)}
                placeholder="john@yourdomain.com"
                type="email"
                value={email}
              />
            </Form.Group>
            {error && error.status === 500 && (
              <Alert title="Could not Mask" type="error">
                Internal Server Error
              </Alert>
            )}
            <Button disabled={isLoading} onClick={onClick}>
              Mask
            </Button>
          </Form>
        </StyledFormWrapper>
      </GridContainer>
    </>
  );
};

export default MaskPage;
