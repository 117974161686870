import { Expose, Type } from 'class-transformer';

import { DashboardData, DashboardModel } from '@api/dashboards/DashboardModel';

export interface RelatedDashboardData {
  dashboard: DashboardData;
  distance: number;
  fieldCount: number;
  rank: number;
  viewCount: number;
}

export class RelatedDashboardModel {
  @Type(() => DashboardModel)
  dashboard!: DashboardModel;

  distance!: number;

  @Expose({ name: 'view_count' })
  viewCount!: number;

  @Expose({ name: 'field_count' })
  fieldCount!: number;

  rank!: number;
}
