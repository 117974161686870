import styled from '@emotion/styled';

export interface StyledPopularityProps {
  compWidth?: string;
}

export const StyledPopularity = styled.span<StyledPopularityProps>`
  display: inline-flex;
  align-items: center;
`;

export interface StyledPopularityItemProps {
  color?: string;
}

export const StyledPopularityItem = styled.span<StyledPopularityItemProps>`
  width: 0.75rem;
  height: 0.9375rem;
  /* we are not fighting for precedence here, the gap is this component
     UI requirement, but generic wrappers often need generic overrites to deal
     with other components also used. So I guess we can say !important is like
     a cancer here, it is a sibling infection of sorts */
  margin-right: 1px !important;
  display: inline-block;
  background: ${(props) => props.color};
`;
