import React, { useState } from 'react';

import { ERD_EXPLORE_ID } from '@components/ErdExplore/consts';
import OptionsButton from '@components/Explore.v1/components/Common/OptionsButton';
import type { OptionsButtonProps } from '@components/Explore.v1/components/Common/OptionsButton/OptionsButton';

interface ErdOptionsDropdownProps {
  children: OptionsButtonProps['children'];
  guid: string;
  optionsSelections: OptionsButtonProps['optionsSections'];
}

const ErdOptionsDropdown: React.FC<ErdOptionsDropdownProps> = ({
  children,
  guid,
  optionsSelections,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <OptionsButton
      containerSelector={`#${ERD_EXPLORE_ID}`}
      isAnchorVisible
      isOpen={isOpen}
      optionsSections={optionsSelections}
      setIsOpen={setIsOpen}
      triggerId={guid}
    >
      {children}
    </OptionsButton>
  );
};

export default React.memo(ErdOptionsDropdown);
