import React from 'react';

import Box from '@components/Box';
import useExpandLineage from '@components/Explore.v1/useExpandLineage';
import { useExplore } from '@components/Explore.v1/useExplore';
import { LineageModeChangeTrigger } from '@components/Explore.v1/useExplore/Explore.context.types';
import { colors } from '@components/ExploreTree/colors';
import Icon from '@components/UI/Icon';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import {
  StyledUpstreamDownstreamButton,
  StyledUpstreamDownstreamButtonContainer,
  StyledUpstreamDownstreamButtonProps,
} from './UpstreamDownstreamButton.styles';

interface UpstreamDownstreamButtonProps extends StyledUpstreamDownstreamButtonProps {
  direction: 'downstream' | 'upstream';
  guid: string;
  nodeKey: string;
}
const UpstreamDownstreamButton: React.FC<UpstreamDownstreamButtonProps> = ({
  direction,
  guid,
  left,
  nodeKey,
  nodeType,
  right,
}) => {
  const type = nodeType === 'table' ? 'table' : 'column';

  const isDownstream = direction === 'downstream';
  const segment = useSegmentContext();
  const { selectedNode, setSelectedEdge, setSelectedNode } = useExplore();
  const { openDownstreamLineage, openUpstreamLineage } = useExpandLineage({
    guid,
    nodeKey,
    origin: type,
  });

  const openLineage = (maxDepth: number, eventTrigger: LineageModeChangeTrigger) => {
    const eventName =
      nodeType === 'table'
        ? SegmentTrackEventName.LineageTableLevelLoaded
        : SegmentTrackEventName.LineageColumnLevelLoaded;

    if (isDownstream) {
      openDownstreamLineage({
        eventTrigger,
        maxDepth,
      });
    } else {
      openUpstreamLineage({
        eventTrigger,
        maxDepth,
      });
    }

    segment?.track(eventName, {
      trigger: eventTrigger,
    });
  };

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setSelectedEdge(undefined);
    const eventTrigger = isDownstream
      ? LineageModeChangeTrigger.OpenOneLevelDownstreamLineage
      : LineageModeChangeTrigger.OpenOneLevelUpstreamLineage;
    setSelectedNode({
      guid,
      key: nodeKey,
      metadata: selectedNode?.guid === guid ? selectedNode?.metadata : undefined,
      type,
    });
    openLineage(1, eventTrigger);
  };

  return (
    <StyledUpstreamDownstreamButtonContainer left={left} nodeType={nodeType} right={right}>
      <StyledUpstreamDownstreamButton
        data-testid="upstream-downstream-button-wrapper"
        nodeType={nodeType}
      >
        <Box
          alignItems="center"
          as="button"
          compDisplay="flex"
          compHeight="100%"
          compWidth="100%"
          data-testid={`${direction}-button`}
          justifyContent="center"
          onClick={handleOnClick}
        >
          <Icon
            color={colors.manualLineageColor}
            name={isDownstream ? 'arrow-right' : 'arrow-left'}
            size={nodeType === 'table' ? '18px' : '16px'}
          />
        </Box>
      </StyledUpstreamDownstreamButton>
    </StyledUpstreamDownstreamButtonContainer>
  );
};

export default UpstreamDownstreamButton;
