import { Expose, Type } from 'class-transformer';

import type { ValidDSType } from '@models/DataSourceCredentials';
import DataTypesModel from '@models/DataTypesModel';
import { PopularityModel } from '@models/PopularityModel';

import LineageEdge from './LineageEdge';

class TableLineageModel {
  key!: string;

  guid!: string;

  name!: string;

  description?: string;

  rank!: string;

  columns!: string[];

  @Expose({ name: 'full_name' })
  fullName?: string;

  @Expose({ name: 'object_type' })
  objectType!: string;

  @Expose({ name: 'data_type' })
  dataType!: string;

  @Expose({ name: 'is_hidden' })
  isHidden!: boolean;

  @Expose({ name: 'data_source_type' })
  dataSourceType!: ValidDSType;

  /**
   * will be deprecated in the future
   * use source_edges
   */
  @Expose({ name: 'source_table_guids' })
  sourceTableGuids!: string[];

  /**
   * will be deprecated in the future
   * use target_edges
   */
  @Expose({ name: 'target_table_guids' })
  targetTableGuids!: string[];

  @Expose({ name: 'tag_guids' })
  tagGuids?: string[];

  @Type(() => PopularityModel)
  popularity?: PopularityModel;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel;

  /**
   * The dbt models/warehouse tables associated with this warehouse table/dbt model respecively
   * If this table is in a DBT Data Source, then this is a list of associated warehouse tables
   * If this table is not in a DBT Data Source, then this is a list of associated dbt models
   *
   * Usually is undefined or has 1 elements, never has 0 elements
   */
  @Expose({ name: 'linked_objs' })
  linkedObjs?: string[];

  @Expose({ name: 'component_identifier' })
  componentIdentifier?: string;

  @Expose({ name: 'source_edges' })
  sourceEdges!: Record<string, LineageEdge>;

  @Expose({ name: 'target_edges' })
  targetEdges!: Record<string, LineageEdge>;

  query!: string;
}

export default TableLineageModel;
