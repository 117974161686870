import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import TextScreen from '@components/TextScreen';
import TwoColumnDecorativeComponent from '@components/TwoColumnDecorativeComponent';
import TwoColumnLayout from '@components/TwoColumnLayout';
import MetadataDecorator from '@utils/MetadataDecorator';

import VerificationScreen from './VerificationScreen';

interface StateType {
  email?: string;
  redirect?: string;
}

const SignUpSuccessPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation<StateType>();

  if (location?.state?.redirect) {
    history.push(location?.state?.redirect || '/');
  }

  if (location?.state?.email) {
    return (
      <>
        <MetadataDecorator title="Email Verification" />
        <TwoColumnLayout
          left={<VerificationScreen email={location?.state?.email} />}
          right={<TwoColumnDecorativeComponent />}
        />
      </>
    );
  }

  return (
    <>
      <MetadataDecorator title="Sign up success" />
      <TwoColumnLayout
        left={<TextScreen title="Sign Up Successful" />}
        right={<TwoColumnDecorativeComponent />}
      />
    </>
  );
};

export default SignUpSuccessPage;
