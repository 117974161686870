import styled from '@emotion/styled';

import type { StyledBoxProps } from '@components/Box';
import Box from '@components/Box';

import type { VariantColor } from './InputLabel.variant';
import { colorVariants } from './InputLabel.variant';

export interface InputLabelProps extends StyledBoxProps {
  htmlFor?: string;
  variantColor?: VariantColor;
}

const InputLabel = styled(Box)<InputLabelProps>`
  ${({ variantColor = 'default' }) => colorVariants[variantColor]};
`;

InputLabel.defaultProps = {
  alignItems: 'center',
  as: 'label',
  className: 'InputLabel',
  compDisplay: 'inline-flex',
  cursor: 'pointer',
  fontFamily: 'primary',
  fontSize: 'body2',
  gap: 1,
  lineHeight: 'normal',
  noDefault: true,
  userSelect: 'none',
};

export default InputLabel;
