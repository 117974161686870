import React from 'react';
import { NO_LINK } from '@configs/urls/config';

import Box from '@components/Box';
import { useExplore } from '@components/Explore.v1/useExplore';
import Text from '@components/Text';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import metadataConfig from '@pages/MetadataObjectPage/config';

import MetadataOverview from './MetadataOverview';
import OverviewSkeleton from './OverviewSkeleton';
import { getObjectRouteUrl } from './OverviewTab.utils';
import TableOverview from './TableOverview';

const getAssetConfigPath = (guid: string, routerUrl?: string) => {
  const noRouteAvailable = routerUrl === NO_LINK || !routerUrl;
  if (noRouteAvailable) {
    return undefined;
  }

  const mainPath = (routerUrl ?? '').split(guid)[0];
  return `${mainPath}:guid`;
};

const OverviewTab = () => {
  const { isLoadingSelectedNode, selectedNode } = useExplore();
  const guid = selectedNode?.guid ?? '';
  const { routerUrl } = selectedNode?.metadata ?? {};
  const objectRouteUrl = getObjectRouteUrl(selectedNode?.metadata);
  const noRouteAvailable = !objectRouteUrl;
  const configPath = getAssetConfigPath(guid, routerUrl);
  const hasMetadataConfig = metadataConfig[configPath as keyof typeof metadataConfig];
  const { isEditable } = useObjectPermissionsContext({ id: guid });

  let content = null;

  if (isLoadingSelectedNode) {
    content = <OverviewSkeleton />;
  }

  if (noRouteAvailable ?? !selectedNode?.metadata) {
    content = (
      <Text color="gray.500" fontSize="sm" fontWeight="medium" px={1.5} py={0.5}>
        No details available
      </Text>
    );
  } else if (selectedNode?.type === 'table' && configPath && hasMetadataConfig) {
    content = (
      <TableOverview
        configPath={configPath}
        guid={guid}
        isEditable={isEditable}
        selectedNodeMetadata={selectedNode?.metadata}
      />
    );
  } else {
    content = (
      <MetadataOverview
        guid={guid}
        isEditable={isEditable}
        selectedNodeMetadata={selectedNode?.metadata}
      />
    );
  }

  return (
    <Box compDisplay="flex" flexDirection="column" flexGrow={1} gap={0.5} overflow="auto" p={1}>
      {content}
    </Box>
  );
};

export default OverviewTab;
