import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as CSS from 'csstype';

import Box from '@components/Box';
import rootTheme from '@styles/theme';

export interface StyledTreeNodeProps {
  disableHover?: boolean;
  hoverBackgroundColor?: CSS.Property.BackgroundColor;
  hoverFontWeight?: CSS.Property.FontWeight;
  hoverLetterSpacing?: CSS.Property.LetterSpacing;
  isHighlighted: boolean;
  offset: number;
}

const DEFAULT_NODE_PADDING = 1;

const makeActiveColor = (customHover: {
  backgroundColor?: CSS.Property.Color;
  fontWeight?: CSS.Property.FontWeight;
  letterSpacing?: CSS.Property.LetterSpacing;
}) => css`
  font-weight: ${customHover.fontWeight || rootTheme.typography.fontWeights.bold};
  background-color: ${customHover.backgroundColor || rootTheme.colors.hierarchyHoverBackground};
  color: ${rootTheme.colors.primary};
  letter-spacing: ${customHover.letterSpacing || '-0.0075em'};
`;

export const StyledTreeNode = styled(Box)<StyledTreeNodeProps>`
  color: ${({ theme }) => theme.colors.text};
  cursor: ${({ disableHover = false }) => (disableHover ? 'default' : 'pointer')};
  font-size: ${({ theme }) => theme.typography.fontSizes.body2};
  padding-left: ${({ offset, theme }) => theme.space(DEFAULT_NODE_PADDING + offset * 2.5)};
  padding-right: ${({ theme }) => theme.space(DEFAULT_NODE_PADDING)};
  transition: background-color 0.1s ease, color 0.1s ease;
  user-select: none;

  ${({ isHighlighted }) => (isHighlighted ? makeActiveColor : undefined)}

  &:hover {
    ${({ disableHover = false, hoverBackgroundColor, hoverFontWeight, hoverLetterSpacing }) =>
      disableHover
        ? ''
        : makeActiveColor({
            backgroundColor: hoverBackgroundColor,
            fontWeight: hoverFontWeight,
            letterSpacing: hoverLetterSpacing,
          })}
  }
`;

export const StyledTreeNodeItemWrapper = styled(Box)``;

export const StyledTreeNodeLabelWrapper = styled.span`
  display: flex;
  width: 100%;
`;

interface StyledTreeNodeCarrotProps {
  isExpanded: boolean;
}

export const StyledTreeNodeCarrot = styled.button<StyledTreeNodeCarrotProps>`
  transform: ${({ isExpanded }) => `rotate(${isExpanded ? '90deg' : '0'})`};
  background: transparent;
  outline: none;
`;
