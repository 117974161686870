import {
  ALLOWED_COLLAPSED_EDGES_NODE_TYPES,
  COLUMN_LEVEL_ALLOWED_COLLAPSED_EDGES_NODE_TYPES,
} from '@components/Explore.v1/Explore.constants';
import { ExploreEdge, ExploreNode, InputNode } from '@components/Explore.v1/Explore.types';
import parseLineageNodes from '@components/Explore.v1/useCreateNodesEdges/algorithm/parseLineageNodes';
import {
  DataNodeTypes,
  EdgesById,
  NodesById,
} from '@components/Explore.v1/useCreateNodesEdges/algorithm/types';
import { createRedirectedEdgesToCollapsedNode } from '@components/Explore.v1/useCreateNodesEdges/utils/createRedirectedEdges';
import { useExplore } from '@components/Explore.v1/useExplore';
import useFocusLineage from '@components/Explore.v1/useFocusLineage';
import useGetConfigQueryParams from '@components/Explore.v1/useGetConfigQueryParams';
import { EXPLORE_NODE_PAGE_SIZE } from '@components/Explore.v1/useShowMoreNodes/useShowMore.constants';

import { SIZES } from '../components/Nodes/config';

const findTopCollapsedParentKey = (
  nodeKey: string,
  nodesById: NodesById,
  collapsedParentKey?: string,
): string | undefined => {
  const parentNodeKey = nodesById[nodeKey]?.data?.parent;
  if (!parentNodeKey) {
    return collapsedParentKey;
  }

  let newClosedParentKey = collapsedParentKey;
  if (!nodesById[parentNodeKey].data.isOpen) {
    newClosedParentKey = parentNodeKey;
  }

  return findTopCollapsedParentKey(parentNodeKey, nodesById, newClosedParentKey);
};

interface ParseColumnNodeParams {
  isBITableColumn: boolean;
  parentId: string;
  parents: string[];
  rawNode: InputNode;
  stackId: number;
}

// TODO: remove this once we merge https://github.com/selectstar/frontend/pull/5106 in favor of parseColumn
const parseColumnNode = ({
  isBITableColumn,
  parentId,
  parents,
  rawNode,
  stackId,
}: ParseColumnNodeParams) => {
  const { hideDownstreamButton, hideUpstreamButton, key, metadata, usage } = rawNode;
  const {
    dataTypes,
    downstream_objects_count: downstreamObjectsCount,
    guid,
    is_nested: isNested,
    name,
    popularity,
    upstream_objects_count: upstreamObjectsCount,
  } = metadata;

  const parsedNode: ExploreNode = {
    data: {
      dataTypes,
      downstreamObjectsCount: downstreamObjectsCount ?? 0,
      edgesLoaded: false,
      guid,
      hiddenChildrenCount: 0,
      hideDownstreamButton,
      hideUpstreamButton,
      isBITableColumn,
      isNested,
      isOpen: false,
      isSelected: false,
      key,
      label: name,
      name,
      noMatchedChildren: false,
      parent: parentId,
      parents,
      popularity: popularity ?? 0,
      shownChildrenCount: EXPLORE_NODE_PAGE_SIZE,
      stackedAt: stackId,
      upstreamObjectsCount: upstreamObjectsCount ?? 0,
      usage,
    },
    height: SIZES.height.column,
    id: key,
    key,
    parent: parentId,
    parentNode: parentId,
    position: { x: 0, y: 0 },
    stackedAt: stackId,
    type: DataNodeTypes.column,
    width: isBITableColumn ? SIZES.width.biColumn : SIZES.width.column,
  };

  return parsedNode;
};

const useExpandExploreNode = () => {
  const {
    biggestConflictEndPerStack,
    edgesById,
    focusedNodeKey,
    initialPosition,
    inputNodesById,
    isColumnLevelLineage,
    nodesById,
    setEdgesById,
    setIsCollapseAllButtonEnabled,
    setNodesById,
    setSelectedEdge,
    setStacksData,
    stackData,
  } = useExplore();
  const { disableFocusedLineage, enableFocusLineage } = useFocusLineage();
  const { shouldHideFilterLineage } = useGetConfigQueryParams();

  const updateNodesOnExpand = ({
    nodeKey,
    shouldEnableFocusedLineage = true,
    shouldEnableSearch = false,
  }: {
    nodeKey: string;
    shouldEnableFocusedLineage?: boolean;
    shouldEnableSearch?: boolean;
  }) => {
    setSelectedEdge(undefined);
    if (stackData?.nodesById[nodeKey]) {
      const isFocused = focusedNodeKey === nodeKey;
      const node = stackData.nodesById[nodeKey];
      const prevIsOpen = node.data.isOpen;

      const allowedCollapsedEdgesNodeTypes = isColumnLevelLineage
        ? COLUMN_LEVEL_ALLOWED_COLLAPSED_EDGES_NODE_TYPES
        : ALLOWED_COLLAPSED_EDGES_NODE_TYPES;

      const isCollapsedEdgesAllowed = allowedCollapsedEdgesNodeTypes.includes(node.type ?? '');

      const removeCollapsedEdges = () => {
        if (isCollapsedEdgesAllowed) {
          const newEdges: EdgesById = {};
          Object.values(edgesById).forEach((edge) => {
            if (edge?.target === nodeKey || edge?.source === nodeKey) {
              const isOpeningTarget = edge.target.includes(nodeKey);

              const sourceOrTarget = isOpeningTarget ? edge.source : edge.target;

              const groupNodeIsCollapsed =
                !stackData.nodesById[sourceOrTarget]?.data?.isOpen &&
                allowedCollapsedEdgesNodeTypes.includes(
                  stackData.nodesById[sourceOrTarget]?.type ?? '',
                );

              /*
               * If the node in the other side of the edge (target/source) is inside
               * a collapsed node, we need to redirect the edge to the collapsed node
               */
              if (groupNodeIsCollapsed) {
                (edge.data?.collapsedEdges ?? [])
                  .filter((collapsedEdge: ExploreEdge) => !collapsedEdge.id.includes(nodeKey))
                  .forEach((collapsedEdge: ExploreEdge) => {
                    const { source, target, ...rest } = collapsedEdge;

                    let newTarget = isOpeningTarget ? target : sourceOrTarget;
                    let newSource = isOpeningTarget ? sourceOrTarget : source;

                    const sourceParentKey = findTopCollapsedParentKey(
                      newSource,
                      stackData.nodesById,
                    );
                    if (sourceParentKey) {
                      const sourceParentIsOpen = stackData.nodesById[sourceParentKey]?.data?.isOpen;
                      if (!sourceParentIsOpen) {
                        newSource = sourceParentKey;
                      }
                    }

                    const targetParentKey = findTopCollapsedParentKey(
                      newTarget,
                      stackData.nodesById,
                    );
                    if (targetParentKey) {
                      const targetParentIsOpen = stackData.nodesById[targetParentKey]?.data?.isOpen;
                      if (!targetParentIsOpen) {
                        newTarget = targetParentKey;
                      }
                    }
                    newEdges[`${newSource}-${newTarget}`] = {
                      ...rest,
                      data: {
                        ...rest.data,
                        collapsedEdges: edge.data?.collapsedEdges,
                      },
                      id: `${newSource}-${newTarget}`,
                      source: newSource,
                      target: newTarget,
                    };
                  });
              }
            } else {
              newEdges[edge.id] = {
                ...edge,
              };
            }
          });

          return newEdges;
        }

        return edgesById;
      };

      const createCollapsedEdges = () => {
        if (isCollapsedEdgesAllowed) {
          const { edges: collapsedNodeEdges } = createRedirectedEdgesToCollapsedNode({
            edgesById,
            inputNodeId: nodeKey,
            nodesById: stackData.nodesById,
          });

          return { ...edgesById, ...collapsedNodeEdges };
        }

        return edgesById;
      };

      const createCollapsedEdgesForChildrenNodes = (parsedNodes: NodesById) => {
        let newEdgesById: EdgesById = {};
        if (!prevIsOpen) {
          (node.data.children ?? []).forEach((childKey) => {
            const currentNode = parsedNodes[childKey];
            if (!currentNode) return;
            const nodeHasCollapsedEdges = stackData.nodesById[childKey].hasCollapsedEdges;
            const isChildGroupNode = allowedCollapsedEdgesNodeTypes.includes(
              currentNode.type ?? '',
            );

            if (isChildGroupNode && !nodeHasCollapsedEdges && !currentNode.data.isOpen) {
              const { edges: collapsedNodeEdges } = createRedirectedEdgesToCollapsedNode({
                edgesById,
                inputNodeId: childKey,
                nodesById: stackData.nodesById,
              });

              stackData.nodesById[nodeKey].hasCollapsedEdges = true;
              newEdgesById = { ...newEdgesById, ...collapsedNodeEdges };
            } else if (currentNode.type === DataNodeTypes.column && isColumnLevelLineage) {
              const collapsedNodes = Object.values(parsedNodes).filter(
                (parsedNode) => parsedNode.data.isOpen === false,
              );

              collapsedNodes.forEach((collapsedNode) => {
                const { edges: collapsedNodeEdges } = createRedirectedEdgesToCollapsedNode({
                  edgesById,
                  inputNodeId: collapsedNode.key ?? '',
                  nodesById: stackData.nodesById,
                });

                newEdgesById = { ...newEdgesById, ...collapsedNodeEdges };
              });
            }
          });
        }

        return newEdgesById;
      };

      stackData.nodesById[nodeKey].data.isOpen = !prevIsOpen;
      stackData.nodesById[nodeKey].hasCollapsedEdges = isCollapsedEdgesAllowed && !prevIsOpen;
      stackData.nodesById[nodeKey].data.shownChildrenCount =
        stackData.nodesById[nodeKey].data.shownChildrenCount ?? EXPLORE_NODE_PAGE_SIZE;
      if (shouldEnableSearch) {
        stackData.nodesById[nodeKey].data.isSearchEnabled = true;
      }
      const parsingResult = parseLineageNodes({
        edgesById: stackData?.edgesById ?? {},
        initialPosition,
        isColumnLevelLineage,
        nodesById: stackData?.nodesById ?? {},
        operationPivotNodeKey: nodeKey,
        preCalculatedConflictEndPerStack: biggestConflictEndPerStack,
        preCalculatedNodesById: nodesById,
        shouldHideFilterLineage,
        stackGroups: stackData?.stackGroups,
      });

      if (shouldEnableFocusedLineage) {
        if (!isFocused) {
          enableFocusLineage({
            edgesById: removeCollapsedEdges(),
            inputNodesById,
            nodeKey,
            nodesById: parsingResult.nodesById,
            stacksData: stackData,
          });
        } else {
          disableFocusedLineage({
            edgesById: createCollapsedEdges(),
            nodesById: parsingResult.nodesById,
            stacksData: stackData,
          });
        }
      } else {
        let newEdgesById = prevIsOpen ? createCollapsedEdges() : removeCollapsedEdges();
        const collapsedEdgesForChildren = createCollapsedEdgesForChildrenNodes(
          parsingResult.nodesById,
        );
        newEdgesById = { ...newEdgesById, ...collapsedEdgesForChildren };

        setNodesById(parsingResult.nodesById);
        setEdgesById(newEdgesById);
        setStacksData({ ...stackData, edgesById: newEdgesById });
      }

      if (!prevIsOpen) {
        setIsCollapseAllButtonEnabled(true);
      }
    }
  };

  const mergeTableNodeColumns = ({
    newColumns,
    nodeKey,
    shouldExpandTableNode = true,
  }: {
    newColumns: Array<InputNode>;
    nodeKey: string;
    shouldExpandTableNode?: boolean;
  }) => {
    const parentNode = stackData?.nodesById[nodeKey];

    if (parentNode && newColumns.length > 0) {
      const parsedColumns = newColumns.map((rawColumn) =>
        parseColumnNode({
          isBITableColumn: Boolean(parentNode.data.isBITable),
          parentId: nodeKey,
          parents: [...(parentNode.data.parents ?? []), nodeKey],
          rawNode: rawColumn,
          stackId: parentNode.data.stackedAt!,
        }),
      );

      const parsedColumnsIds: Array<string> = [];

      parsedColumns.forEach((column) => {
        parsedColumnsIds.push(column.id);
        stackData.nodesById[column.id] = column;
      });
      parentNode.data.children = new Set(parsedColumnsIds);
      parentNode.data.childrenCount = parsedColumnsIds.length;
      parentNode.data.noMatchedChildren = false;
      if (shouldExpandTableNode) {
        updateNodesOnExpand({ nodeKey, shouldEnableFocusedLineage: !isColumnLevelLineage });
      }
    }
  };

  return { mergeTableNodeColumns, updateNodesOnExpand };
};

export default useExpandExploreNode;
