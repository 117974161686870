import { dataSources } from '@configs/dataSources/config';
import type { DataSource } from '@configs/dataSources/types';

interface LinksProps {
  label: string;
}

const biLinksPropsMap: PartialRecord<DataSource, LinksProps> = {
  bigquery: {
    label: `Open in ${dataSources.bigquery.text}`,
  },
  data_studio: {
    label: `Open in ${dataSources.data_studio.text}`,
  },
  databricks: {
    label: `Open in ${dataSources.databricks.text}`,
  },
  dbt: {
    label: `To ${dataSources.dbt.text} Model`,
  },
  glue: {
    label: `Open in ${dataSources.glue.text}`,
  },
  looker: {
    label: `Open in ${dataSources.looker.text}`,
  },
  metabase: {
    label: `Open in ${dataSources.metabase.text}`,
  },
  mode: {
    label: `Open in ${dataSources.mode.text}`,
  },
  mssql: {
    label: `Open in ${dataSources.mssql.text}`,
  },
  mysql: {
    label: `Open in ${dataSources.mysql.text}`,
  },
  oracle: {
    label: `Open in ${dataSources.oracle.text}`,
  },
  periscope: {
    label: `Open in ${dataSources.periscope.text}`,
  },
  postgres: {
    label: `Open in ${dataSources.postgres.text}`,
  },
  power_bi: {
    label: `Open in ${dataSources.power_bi.text}`,
  },
  quicksight: {
    label: `Open in ${dataSources.quicksight.text}`,
  },
  redshift: {
    label: `Open in ${dataSources.redshift.text}`,
  },
  sigma: {
    label: `Open in ${dataSources.sigma.text}`,
  },
  snowflake: {
    label: `Open in ${dataSources.snowflake.text}`,
  },
  tableau: {
    label: `Open in ${dataSources.tableau.text}`,
  },
  thoughtspot: {
    label: `Open in ${dataSources.thoughtspot.text}`,
  },
};

export default biLinksPropsMap;
