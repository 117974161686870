import { RelatedDashboardModel } from '@api/dashboards/RelatedDashboardModel';
import paginatedTransform from '@api/paginatedTransform';
import rawTransform from '@api/rawTransform';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import { FrequentDSUserModel } from '@models/FrequentDSUserModel';
import { LookMLProjectModel } from '@models/LookMLProjectModel';
import type { OwnersUpdateData } from '@models/Owners';
import { PaginatedResponse } from '@models/PaginatedResponse';
import type { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

import { GetBiLookmlViewsRelatedDashboardsCsvRetrievePathParams } from '../openapi.generated';

import * as cacheKeys from './cacheKeys';
import { LookMLViewModel } from './LookMLViewModel';

const fetchLookMLViewsSelect = paginatedTransform(LookMLViewModel);
export const useFetchLookMLViews = (
  options?: UseFetchOptions<PaginatedResponse<LookMLViewModel>>,
) => {
  return useFetch<PaginatedResponse<LookMLViewModel>>({
    ...options,
    queryKey: [...cacheKeys.views, options?.params],
    select: fetchLookMLViewsSelect,
    url: '/bi/lookml/views/',
  });
};

const fetchLookMLViewSelect = rawTransform(LookMLViewModel);
export const useFetchLookMLView = (id: string, options: UseFetchOptions<LookMLViewModel>) => {
  return useFetch<LookMLViewModel>({
    ...options,
    queryKey: cacheKeys.view(id),
    select: fetchLookMLViewSelect,
    url: `/bi/lookml/views/${id}/`,
  });
};

const fetchLookMLViewRelatedDashboardsSelect = paginatedTransform(RelatedDashboardModel);
export const useFetchLookMLViewRelatedDashboards = (
  id: string,
  options: UseFetchOptions<PaginatedResponse<RelatedDashboardModel>>,
) => {
  return useFetch<PaginatedResponse<RelatedDashboardModel>>({
    ...options,
    queryKey: [...cacheKeys.viewRelatedDashboards(id), options?.params],
    select: fetchLookMLViewRelatedDashboardsSelect,
    url: `/bi/lookml/views/${id}/related-dashboards/`,
  });
};

const fetchLookMLViewFrequentDSUsersSelect = paginatedTransform(FrequentDSUserModel);
export const useFetchLookmlViewFrequentDSUsers = (id: string, options?: UseFetchOptions) => {
  return useFetch<PaginatedResponse<FrequentDSUserModel>>({
    ...options,
    queryKey: [...cacheKeys.viewFrequentDsUsers(id), options?.params],
    select: fetchLookMLViewFrequentDSUsersSelect,
    url: `bi/lookml/views/${id}/frequent-dsusers/`,
  });
};

const fetchLookMLProjectsSelect = paginatedTransform(LookMLProjectModel);
export const useFetchLookMLProjects = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<LookMLProjectModel>>,
) => {
  return useFetch<PaginatedResponse<LookMLProjectModel>>({
    ...options,
    queryKey: [cacheKeys.projects(id), options?.params],
    select: fetchLookMLProjectsSelect,
    url: `/bi/lookml/${id}/project/`,
  });
};

const fetchLookMLProjectSelect = rawTransform(LookMLProjectModel);
export const useFetchLookMLProject = (
  guid: string,
  options?: UseFetchOptions<LookMLProjectModel>,
) => {
  return useFetch<LookMLProjectModel>({
    ...options,
    queryKey: [cacheKeys.project(guid), options?.params],
    select: fetchLookMLProjectSelect,
    url: `/bi/lookml/project/${guid}/`,
  });
};

export const usePatchLookMLProjects = (id: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: `/bi/lookml/${id}/project/`,
  });
};

export const usePatchLookMLViewsOwners = (
  options?: UseMutationOptions<never, EnhancedErrorResult, OwnersUpdateData>,
) => {
  return useMutation({
    ...options,
    method: 'PATCH',
    url: `/bi/lookml/views/owners/`,
  });
};

export const useFetchLookMLViewRelatedDashboardsCsv = (
  id: string,
  options?: UseFetchOptions<any, GetBiLookmlViewsRelatedDashboardsCsvRetrievePathParams>,
) => {
  return useFetch({
    ...options,
    queryKey: [...cacheKeys.viewRelatedDashboardsCsv(id), options?.params],
    url: `/bi/lookml/views/${id}/related-dashboards/csv/`,
  });
};
