import React from 'react';
import { DEFAULT_DOCS_URL } from '@constants';

import Icon from '@components/UI/Icon';
import { useUserContext } from '@context/User';
import { isMasked } from '@pages/MaskPage/MaskPage.utils';

import ProfileMenu from '../ProfileMenu';
import { ProfileMenuItemsProps } from '../ProfileMenu/ProfileMenuItem';

interface ProfileMenuListProps {
  hideNotification?: boolean;
  menuList?: ProfileMenuItemsProps[];
}

const ProfileMenuList: React.FC<ProfileMenuListProps> = ({ hideNotification, menuList }) => {
  const { user } = useUserContext();
  const defaultProfileMenu: ProfileMenuItemsProps[] = [
    {
      icon: <Icon color="currentColor" name="user-filled" size="14px" />,
      link: '/profile',
      name: 'My profile',
    },
    {
      icon: <Icon color="currentColor" name="setting" size="14px" />,
      link: '/settings',
      name: 'Settings',
    },
    {
      externalLink: true,
      icon: <Icon color="currentColor" name="help" size="14px" />,
      link: DEFAULT_DOCS_URL,
      name: 'Docs',
    },
    {
      icon: <Icon color="currentColor" name="exit-to-app" size="14px" />,
      link: '/logout',
      name: 'Logout',
    },
  ];

  if (isMasked()) {
    defaultProfileMenu.push({
      icon: <Icon color="currentColor" name="lock-filled" size="14px" />,
      link: '/unmask',
      name: 'Unmask',
    });
  }

  if (!user) {
    return null;
  }

  return (
    <ProfileMenu
      hideNotification={hideNotification}
      items={menuList || defaultProfileMenu}
      user={user}
    />
  );
};

export default ProfileMenuList;
