import React, { useEffect } from 'react';
import type { ModalId } from '@constants';

import Button from '@components/Button/Button';
import Form from '@components/Form';
import { FormHorizontalLabelGrid } from '@components/Form/Form.styles';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import InputLabel from '@components/UI/Form/InputLabel';
import Modal, { ModalFooter, ModalHeader } from '@components/UI/Modal';
import { useModal } from '@context/Modal';

interface AddLinkModalProps {
  modalId: ModalId;
  onClose: () => void;
  onSave: (text: string, url: string) => void;
  selectedLink?: string;
  selectedText?: string;
}

const AddLinkModal: React.FC<AddLinkModalProps> = ({
  modalId,
  onClose,
  onSave,
  selectedLink = '',
  selectedText = '',
}) => {
  const { checkModalOpened, closeModal } = useModal();
  const { handleChange, handleSubmit, setValues, values } = useForm({
    initialValues: {
      link: selectedLink,
      text: selectedText,
    },
    onSubmit: (val) => {
      onSave(val.text, val.link);
      closeModal(modalId);
      setValues({ link: '', text: '' });
    },
  });

  const handleClose = () => {
    closeModal(modalId);
    onClose();
  };

  useEffect(() => {
    setValues((prev) => ({ ...prev, text: selectedText }));
  }, [selectedText, setValues]);

  useEffect(() => {
    setValues((prev) => ({ ...prev, link: selectedLink }));
  }, [selectedLink, setValues]);

  if (!checkModalOpened(modalId)) return null;

  return (
    <Modal onClose={handleClose} open size="tiny" useContext>
      <ModalHeader onClose={handleClose} title={selectedLink ? 'Edit link' : 'Add link'} />
      <Form onSubmit={handleSubmit}>
        <FormHorizontalLabelGrid gridTemplateColumns="auto 1fr">
          <InputLabel compDisplay="contents" variantColor="modalForm">
            Text
            <Input autoFocus name="text" onChange={handleChange} value={values.text} />
          </InputLabel>
          <InputLabel compDisplay="contents" variantColor="modalForm">
            Link
            <Input name="link" onChange={handleChange} value={values.link} />
          </InputLabel>
        </FormHorizontalLabelGrid>
        <ModalFooter>
          <Button onClick={handleClose} type="button" variant="outlined">
            Cancel
          </Button>
          <Button disabled={Boolean(values.link) && !values.text} variant="default">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddLinkModal;
