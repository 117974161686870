import React from 'react';
import getDebug from 'debug';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react';

import invalidateCache from '@api/invalidateCache';
import { usePatchTag, usePostTag } from '@api/tags';
import { TagModel } from '@api/tags/TagModel';
import Alert from '@components/Alert';
import { TERM_DESCRIPTION_ELEMENTS } from '@components/AppMainSidebar/Hierarchy/DocumentsHierarchy/CreateDocMenu/CreateTermModal/CreateTermModal';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { ErrorMessage } from '@components/Message';
import BaseOwnerSelect from '@components/OwnerSelect/BaseOwnerSelect';
import RichTextDescriptionEditor from '@components/RichTextDescriptionEditor';
import { renderInfoToast } from '@components/Toast';
import InputLabel from '@components/UI/Form/InputLabel';
import { ModalFooter } from '@components/UI/Modal';
import Select from '@components/UI/Select.v1/Select';
import { Option } from '@components/UI/Select.v1/types';
import theme from '@styles/theme';

import {
  categoryTagOptions,
  defaultIconForType,
  statusTagOptions,
  TAG_TYPE_OPTIONS,
} from './constants';
import {
  StyleCreateTagContentTagDropdown,
  StyledCreateTagContentFieldLabel,
  StyledCreateTagContentNameColorFieldError,
  StyledCreateTagContentNameColorFieldLabel,
} from './CreateTagContent.styles';
import { getInitialState } from './CreateTagContent.utils';
import { CreateTagForm, CreateTagModalPayload } from './types';
import useDefaultOwnerOptions from './useDefaultOwnerOptions';

const debug = getDebug('selectstar:tags:create');

export interface CreateTagContentProps {
  initialState?: CreateTagModalPayload;
  isEdit?: boolean;
  onAddTag?: (tag: TagModel) => void;
  onClose?: (guid?: string, type?: TagModel['type']) => void;
}

const CreateTagContent: React.FC<CreateTagContentProps> = ({
  initialState,
  isEdit = false,
  onAddTag,
  onClose,
}) => {
  const { options: defaultOwnerOptions, selectedOption: currentOwner } =
    useDefaultOwnerOptions(initialState);

  const onSuccess = (data: TagModel) => {
    renderInfoToast(isEdit ? 'Tag Updated' : 'New Tag Created');
    invalidateCache((keys) => [keys.tags.all]);
    onAddTag?.(data);
  };

  const postQuery = usePostTag({
    onSuccess,
  });

  const patchQuery = usePatchTag(initialState?.guid, {
    onSuccess,
  });

  const { error, isLoading, mutate } = isEdit ? patchQuery : postQuery;

  const { handleChange, handleSubmit, setValues, touched, values } = useForm<CreateTagForm>({
    initialValues: getInitialState(currentOwner, initialState),
    onSubmit: (payload) => {
      const [selectedOwner] = payload.owner ?? [];
      const [selectedType] = payload.type ?? [];

      const getFieldValue = (field: keyof CreateTagForm) => {
        return !isEdit || initialState?.[field] !== payload[field] ? payload[field] : undefined;
      };

      mutate({
        color: getFieldValue('color'),
        description: getFieldValue('description'),
        icon: getFieldValue('icon'),
        name: getFieldValue('name'),
        owner:
          !isEdit || selectedOwner?.value !== initialState?.owner?.guid
            ? selectedOwner?.value
            : undefined,
        richtext_description: getFieldValue('richtextDescription'),
        type:
          !isEdit || selectedType?.value !== initialState?.type ? selectedType?.value : undefined,
      });
    },
  });

  const handleDescriptionChange = (richTextDescription: string, description?: string) => {
    setValues((prevValues) => ({
      ...prevValues,
      description: description ?? '',
      richtextDescription: richTextDescription ?? '',
    }));
  };

  const iconOptions =
    values.type?.[0]?.value === 'category' ? categoryTagOptions : statusTagOptions;

  debug('state type/icon', values.type, values.icon);

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={2} px={3} py={2.5}>
        <StyledCreateTagContentFieldLabel>
          Tag Type
          <Select
            onChange={(newValue) => {
              const [selectedOption] = newValue as Option[];
              const def = defaultIconForType[selectedOption.value];

              setValues((prev) => ({
                ...prev,
                color: def.color,
                icon: def.icon,
                type: newValue as Option[],
              }));
            }}
            options={TAG_TYPE_OPTIONS}
            value={values.type}
          />
        </StyledCreateTagContentFieldLabel>
        {error?.data?.type && (
          <Box>
            <ErrorMessage message={error?.data.type[0]} />
          </Box>
        )}
        <StyledCreateTagContentNameColorFieldLabel>
          Icon/Name
          <Box alignItems="center" compDisplay="flex" gap={1} gridColumn="2 / 4">
            <StyleCreateTagContentTagDropdown>
              <SemanticDropdown
                key={values.type?.[0]?.value}
                item
                onChange={(__, d: any) => {
                  debug('dropdownvalue', d);

                  setValues((prev) => ({
                    ...prev,
                    color: d.value.color,
                    icon: d.value.icon,
                  }));
                }}
                options={iconOptions as any}
                simple
                value={{ color: values.color, icon: values.icon } as any}
              />
            </StyleCreateTagContentTagDropdown>
            <Input
              autoFocus
              error={!touched.name && error?.data?.name}
              flexGrow="1"
              max={30}
              min={1}
              name="name"
              onChange={handleChange}
              placeholder="Enter Tag Name"
              value={values.name}
            />
          </Box>
          {error?.data?.name && !touched.name && (
            <StyledCreateTagContentNameColorFieldError className="dropdown">
              <label htmlFor="icon" />
              <ErrorMessage message={error?.data?.name[0]} />
            </StyledCreateTagContentNameColorFieldError>
          )}
        </StyledCreateTagContentNameColorFieldLabel>
        <StyledCreateTagContentFieldLabel>
          Tag Owner
          <BaseOwnerSelect
            defaultOptions={defaultOwnerOptions}
            fitAnchorWidth
            label="newOwnerSelect"
            onChange={(newValue) => {
              setValues((prev) => ({
                ...prev,
                owner: newValue as Option[],
              }));
            }}
            placeholder="Search for teams or users"
            showClearSelection
            value={values.owner}
          />
        </StyledCreateTagContentFieldLabel>
        <Box compDisplay="flex" flexDirection="column">
          <InputLabel color="gray.700" cursor="default" fontWeight="medium" mb={1} mt={1.25}>
            Tag Description
          </InputLabel>
          <RichTextDescriptionEditor
            allowedElements={TERM_DESCRIPTION_ELEMENTS}
            descriptions={{
              description: values.description,
              richTextDescription: values.richtextDescription,
            }}
            disableSaveOnEnter
            editIconVariant="always"
            editMode
            fontSize="body2"
            isEditable
            maxHeight={theme.space(45)}
            onDescriptionChange={handleDescriptionChange}
            placeholder="Enter the description here"
            shouldFocusOnEdit={false}
            shouldStartFocused={false}
            title=""
            variant="inline"
          />
        </Box>
        {error?.data?.owner && (
          <Box>
            <ErrorMessage message={error?.data.owner[0]} />
          </Box>
        )}
        {error && error?.status === 500 && (
          <Alert title="Something went wrong." type="error">
            Server couldn&apos;t process the request.{' '}
            {error?.data?.detail || error?.data?.non_field_errors || ''}
          </Alert>
        )}
      </Box>
      <ModalFooter p={3}>
        <Button onClick={() => onClose?.()} type="button" variant="outlined">
          Cancel
        </Button>
        <Button disabled={isLoading || !values.name} type="submit">
          Save
        </Button>
      </ModalFooter>
    </Form>
  );
};

export default CreateTagContent;
