import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@components/Button/Button';
import IconButton from '@components/IconButton';
import Text from '@components/Text';
import Icon from '@components/UI/Icon';
import flags from '@features';
import theme from '@styles/theme';

import Banner from './TopBanner.styles';

interface ExpiredProps {
  daysLeft: number;
  isSubscriptionEndingBanner?: boolean;
}

const TopBanner: React.FC<ExpiredProps> = ({ daysLeft, isSubscriptionEndingBanner }) => {
  const history = useHistory();
  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  if (isVisible === false) return null;

  return (
    <Banner
      alignItems="center"
      compDisplay="flex"
      compHeight="40px"
      flexDirection="row"
      justifyContent="center"
      left={0}
      p={1}
      position="sticky"
      top={0}
    >
      <Text color={theme.colors.white} fontSize="16px" fontWeight="medium" m={0} pr={2}>
        In {daysLeft} day{daysLeft === 1 ? '' : 's'} your Select Star{' '}
        {isSubscriptionEndingBanner ? 'Subscription' : 'Free trial'} will expire.
      </Text>
      <Button
        backgroundColor="white"
        borderRadius="lg"
        color="#2C2E36"
        compSize="xxs"
        fontSize="13px"
        fontWeight="bold"
        onClick={() =>
          flags.billing
            ? history.push('/admin/billing/')
            : window.open('https://calendly.com/selectstar/next-steps', '_blank')
        }
        px={1.5}
        py={1.25}
      >
        Upgrade your plan&nbsp;🏆
      </Button>
      <IconButton ml={1} onClick={toggleVisibility} variant="clear">
        <Icon color="white" name="close" />
      </IconButton>
    </Banner>
  );
};

export default TopBanner;
