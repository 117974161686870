import 'reflect-metadata';

import { Expose, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import type { DescriptionSource } from '@api/description/description.types';
import { TaggedItemModel } from '@api/tags/TaggedItemModel';
import type { DataType, ObjectType } from '@api/types';
import { breadcrumbsToLabelList, breadcrumbsToList } from '@components/Breadcrumbs';
import getUrl from '@components/Tree/getUrl';
import DataTypesModel from '@models/DataTypesModel';
import RelatedObjectsCountsModel from '@models/RelatedObjectsCountsModel';
import { makeUrl, urlFor } from '@utils/routing';

import type { DataSourceTypesType } from './DataSourceCredentials';
import { PopularityModel } from './PopularityModel';
import { Reference } from './Reference';

/*
 * Metadatadata interface is not equal to the model.
 * This marks the change in code where interfaces do not need to be the
 * same as the model.
 * Metadatadata inteface is used to combine multiple models/interfaces.
 * For all other cases such as Newest/Most popular items, the Metadata model
 * is used
 */
export interface MetadataData {
  dataSourceType?: DataSourceTypesType;
  dataType?: string;
  dataTypeIcon?: string;
  dataTypes?: DataTypesModel;
  data_source_type?: string;
  description?: string;
  externalType?: string;
  fullName?: string;
  guid: string;
  icon?: string;
  iconName?: string;
  isHidden?: boolean;
  name?: string;
  objectType: ObjectType;
  parentGuid?: string;
  popularity?: PopularityModel;
  rawPopularity?: number;
  routePath?: string;
  routerUrl?: string;
  taggedItems?: TaggedItemModel[];
  url?: string;
}

export class MetadataModel {
  guid!: string;

  name!: string;

  url?: string;

  description?: string;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  @Expose({ name: 'richtext_description' })
  richtextDescription?: string;

  @Expose({ name: 'suggested_description' })
  suggestedDescription?: string;

  @Expose({ name: 'suggested_description_source' })
  suggestedDescriptionSource?: string;

  @Expose({ name: 'suggested_description_source_object' })
  @Type(() => Reference)
  suggestedDescriptionSourceObject?: Reference<MetadataModel>;

  @Expose({ name: 'short_name' })
  shortName?: string;

  @Expose({ name: 'search_name' })
  fullName?: string;

  @Expose({ name: 'is_hidden' })
  isHidden?: boolean;

  @Expose({ name: 'object_type' })
  objectType!: ObjectType;

  @Expose({ name: 'data_type' })
  dataType!: DataType;

  @Expose({ name: 'external_type' })
  externalType!: string;

  @Expose({ name: 'parent_guid' })
  parentGuid!: string;

  @Expose({ name: 'tagged_items' })
  @Type(() => TaggedItemModel)
  taggedItems?: TaggedItemModel[];

  @Expose({ name: 'data_source_type' })
  dataSourceType?: DataSourceTypesType;

  @Type(() => PopularityModel)
  popularity?: PopularityModel;

  @Expose({ name: 'raw_popularity' })
  rawPopularity?: number;

  @Expose({ name: 'last_updated_on' })
  @Transform((value) => (value ? moment(value) : value))
  lastUpdatedOn?: moment.Moment;

  @Expose({ name: 'downstream_objects_counts' })
  @Type(() => RelatedObjectsCountsModel)
  downstreamObjectsCounts?: RelatedObjectsCountsModel;

  @Expose({ name: 'upstream_objects_counts' })
  @Type(() => RelatedObjectsCountsModel)
  upstreamObjectsCounts?: RelatedObjectsCountsModel;

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  @Expose({ name: 'is_metric' })
  isMetric?: boolean;

  @Expose({ name: 'is_mention' })
  isMention?: boolean;

  get externalUrl() {
    if (['dashboard', 'dashboardelement'].includes(this.objectType)) {
      return this.url;
    }
    return undefined;
  }

  get breadcrumbList() {
    return breadcrumbsToList(this.breadcrumbs);
  }

  /**
   * @todo Architecture.
   * Object might have the FE page link and the best place to define it is a model.
   */
  get routePath() {
    /**
     * Sigma and Mode has the same objectType which cause troubles
     * with defining a proper router for that bi column object page.
     */
    if (this.dataSourceType && this.dataSourceType === 'sigma' && this.dataType === 'reportquery') {
      return makeUrl('reportquerysigma', this.guid, this.parentGuid);
    }

    return urlFor({ guid: this.guid, objectType: this.objectType, parentGuid: this.parentGuid });
  }

  get breadcrumbLabelList() {
    const url = urlFor(this, false, '');
    return breadcrumbsToLabelList(this.name, url, this.breadcrumbs);
  }

  @Expose({ name: 'ai_description' })
  aiDescription?: string;

  @Expose({ name: 'ingested_description' })
  ingestedDescription?: string;

  @Expose({ name: 'description_source' })
  descriptionSource?: DescriptionSource;

  @Expose({ name: 'user_description' })
  userDescription?: string;

  get routerUrl() {
    return getUrl({
      dataType: this.dataType,
      dataTypes: this.dataTypes,
      guid: this.guid,
      objectType: this.objectType,
      parentGuid: this.parentGuid,
      showSchemataPage: true,
      type: this.dataSourceType,
    });
  }
}
