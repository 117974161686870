import React from 'react';

import { DateRangePickerProvider } from '@components/DateRangePicker/DateRangePicker.context';
import { GridContainer } from '@components/Grid';
import type { TabRoutesConfig } from '@components/TabsRouter';
import TabsRouter from '@components/TabsRouter';
import Icon from '@components/UI/Icon';
import { MultiSelectProvider } from '@components/UI/Select.v1/MultiSelect.context';
import TitleView from '@components/UI/TitleView';
import MetadataDecorator from '@utils/MetadataDecorator';

import CostAnalysisDataTab from './tabs/CostAnalysisDataTab';
import CostAnalysisOverviewTab from './tabs/CostAnalysisOverviewTab';
import CostAnalysisUserTab from './tabs/CostAnalysisUserTab';

const title = 'Snowflake Cost Analysis';

const AdminCostAnalysisPage: React.FC = () => {
  const tabsRouterConfig: TabRoutesConfig[] = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <CostAnalysisOverviewTab />,
      default: true,
      label: 'Overview',
      route: '/overview',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <CostAnalysisDataTab />,
      label: 'Data',
      route: '/data',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <CostAnalysisUserTab />,
      label: 'User',
      route: '/user',
    },
  ];

  return (
    <>
      <MetadataDecorator title={title} />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <TitleView
          as="h1"
          compSize="lg"
          icon={<Icon color="currentColor" ml={-0.5} mr={0.5} name="dollar-circle" size="1.1em" />}
          mb={4}
          title={title}
        />
        <DateRangePickerProvider>
          <MultiSelectProvider>
            <TabsRouter config={tabsRouterConfig} />
          </MultiSelectProvider>
        </DateRangePickerProvider>
      </GridContainer>
    </>
  );
};

export default AdminCostAnalysisPage;
