import React from 'react';

import { ColumnModel } from '@api/columns/ColumnModel';
import { ObjectType } from '@api/types';
import { breadcrumbsToSimplifiedLabelList } from '@components/Breadcrumbs/Breadcrumbs.utils';
import CopyFullPathButton from '@components/CopyFullPathButton';
import IconButton from '@components/IconButton';
import ColumnConstraint from '@components/TabContent/ColumnsTab/ColumnTable/ColumnConstraint';
import type { LinkedCellProps } from '@components/Table/Cells/LinkedCell';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import { TypedCell } from '@components/Table/Cells/types';
import Icon from '@components/UI/Icon';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';

import {
  StyledColumnNameTableCell,
  StyledColumnNameTableCellSection,
} from './ColumnNameTableCell.styles';

export interface ColumnNameTableCellProps
  extends Pick<LinkedCellProps, 'breadcrumbFixedUrl' | 'customUrl' | 'showBreadcrumbs'> {
  columnItem: ColumnModel;
  editPk?: () => void;
  hideConstraints?: boolean;
  isDataSourceEditable: boolean;
  openColumnsPage?: () => void;
  pageObjectType?: ObjectType;
}

const ColumnNameTableCell: React.FC<ColumnNameTableCellProps & Partial<TypedCell>> = ({
  columnItem,
  editPk,
  hideConstraints,
  isDataSourceEditable,
  openColumnsPage,
  pageObjectType,
  showBreadcrumbs,
  ...other
}) => {
  const { breadcrumbs, fullName: columnFullName, guid, name, routePath } = columnItem;

  const { isEditable: isObjectEditable } = useObjectPermissionsContext({ id: guid });
  const isEditable = isDataSourceEditable && isObjectEditable;
  const showAddButton = isEditable && !hideConstraints && editPk;
  const simplifiedBreadcrumbs = breadcrumbsToSimplifiedLabelList({
    breadcrumbs,
    label: name,
    pageObjectType,
    url: routePath,
  });

  const item = {
    ...columnItem,
    breadcrumbLabelList: simplifiedBreadcrumbs,
  };

  return (
    <StyledColumnNameTableCell withInlineButton={Boolean(showAddButton ?? columnFullName)}>
      <StyledColumnNameTableCellSection>
        <LinkedCell
          {...other}
          item={item}
          onClick={openColumnsPage}
          showBreadcrumbs={showBreadcrumbs}
          showDataTypeTooltip
          showIcon
          showNameTooltip
        />
        {showAddButton && (
          <IconButton className="inline-button" onClick={editPk}>
            <Icon color="#0c3476" name="plus" size="14px" />
          </IconButton>
        )}
        {columnFullName && (
          <CopyFullPathButton className="inline-button" iconSize="14px" text={columnFullName} />
        )}
      </StyledColumnNameTableCellSection>
      <ColumnConstraint
        columnItem={columnItem}
        flexWrap="wrap"
        gap={0.25}
        hideConstraints={hideConstraints}
        showUsageBadges
      />
    </StyledColumnNameTableCell>
  );
};

export default React.memo(ColumnNameTableCell);
