import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import getDebug from 'debug';
import { useRouteMatch } from 'react-router-dom';
import {
  TableOptions,
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { useRecoilValue } from 'recoil';
import { Pagination, Table as SemanticTable } from 'semantic-ui-react';

import { metadataType } from '@atoms/MetadataType';
import Box from '@components/Box';
import useStickyContext from '@components/Sticky';
import { isModalOpened } from '@components/UI/Modal/Modal.utils';

import ColumnResizer from './ColumnResizer';
import DefaultColumnFilter from './DefaultFilter';
import FilterColumnButton from './FilterColumnButton';
import { StyledSemanticTableCell, StyledTablePagination } from './Table.styles';
import TableRowWithHighlight from './TableRowWithHighlight';
import TableTopBar from './TableTopBar';
import type { ColumnInstance } from './types';
import useHighlight from './useHighlight';
import useRangeSelect, { RangeSelectionColumnConfigArgs } from './useRangeSelection';

export const tableUserChosenFiltersStorageKey = 'tableUserChosenFilters';

const debug = getDebug('selectstar:table-component');

interface SearchHeaderCellProps {
  column: ColumnInstance;
  toggleFilter?: () => void;
}
const SearchHeaderCell: React.FC<SearchHeaderCellProps> = ({ column, toggleFilter }) => {
  return (
    <SemanticTable.HeaderCell
      {...column.getHeaderProps()}
      className="search"
      onClick={toggleFilter}
      role="button"
      style={{
        ...column.getHeaderProps().style,
        width: column?.width,
      }}
    >
      {column.render('Header')}
    </SemanticTable.HeaderCell>
  );
};
/*
 * This is the main Table implementation.
 * All other implementations should be on top of this implementation
 *
 * This implementation is based on SemanticUI + React Table
 * Documentation:
 * Semantic UI: https://react.semantic-ui.com/collections/table/
 * React-Table: https://react-table.tanstack.com/docs/overview
 */

export interface TableProps extends TableOptions<Partial<any>> {
  customTopBarContent?: React.ReactNode;
  'data-testid'?: string;
  disablePagination?: boolean;
  disableRowSelect?: boolean;
  emptyMessage?: React.ReactNode;
  manualRowSelect?: boolean;
  /** Allows to customize checkboxes column. */
  rangeSelectConfig?: RangeSelectionColumnConfigArgs;
  selectedRowIds?: { [key: string]: boolean };
  setSelectedRowIds?: (arg: { [key: string]: boolean }) => void;
  showGlobalFilterV1?: boolean;
  stickyHeader?: boolean;
  toggleFilter?: () => React.Dispatch<React.SetStateAction<boolean>> | void;
  totalPages?: number;
}

const defaultFn = () => {};

const Table: React.FC<TableProps> = ({
  changePage,
  columns,
  customTopBarContent,
  data,
  'data-testid': testId,
  disableColumnFiltering,
  disableFilters,
  disableHeaders,
  disablePagination,
  disableRowSelect,
  disableSortBy,
  emptyMessage = 'No Data',
  getRowId,
  initialState,
  loading,
  manualFilters,
  manualGlobalFilter,
  manualPagination,
  manualRowSelect,
  manualSortBy,
  rangeSelectConfig = {},
  setFilters,
  setGlobalFilter,
  setSelectedRowIds = defaultFn,
  setSortBy,
  showFilter,
  showGlobalFilterV1,
  showSelectionHeader,
  stickyHeader = false,
  toggleFilter,
  totalPages,
  ...rest
}) => {
  /**
   * Since there's so many variables, we need to do some validation of the props passed to avoid errors.
   */
  if ((!disableHeaders && showSelectionHeader) || (disableHeaders && !showSelectionHeader)) {
    throw new Error(
      'disableHeaders and showSelectionHeader are mutually exclusive. Either both can be true or both can be false. Check props',
    );
  }
  const { path } = useRouteMatch();
  const metadata = useRecoilValue(metadataType);
  const stickyProps = useStickyContext({
    enabled: stickyHeader,
  });
  const filtersCacheKey = metadata ? `${path}:${metadata}` : path;
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const { highlightedRowId, isHighlightedRowActive, setIsHighlightedRowActive } = useHighlight();
  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      width: 110,
    }),
    [],
  );
  const initialStateModified = useMemo(() => {
    const hiddenColumnsSet = new Set(initialState?.hiddenColumns ?? []);
    const tableUserChosenFilters =
      JSON.parse(localStorage?.getItem(tableUserChosenFiltersStorageKey) || '{}')?.[
        filtersCacheKey
      ] ?? {};

    Object.keys(tableUserChosenFilters)?.forEach((filterName) => {
      if (tableUserChosenFilters[filterName] === false) {
        hiddenColumnsSet.add(filterName);
      } else {
        hiddenColumnsSet.delete(filterName);
      }
    });

    const hiddenColumns = Array.from(hiddenColumnsSet);

    if (disableRowSelect) {
      if (initialState) {
        return {
          ...initialState,
          hiddenColumns: [...hiddenColumns, 'selection'],
          selectedRowIds: manualRowSelect ? initialState.selectedRowIds : {},
        };
      }
      return { hiddenColumns: [...hiddenColumns, 'selection'], selectedRowIds: {} };
    }
    return { ...initialState, hiddenColumns };
  }, [initialState, filtersCacheKey, disableRowSelect, manualRowSelect]);

  const {
    allColumns,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    pageCount,
    prepareRow,
    rows,
    setGlobalFilter: setGlobalFilterInternal,
    state: { filters, globalFilter, pageIndex, selectedRowIds, sortBy },
    visibleColumns,
  } = useTable(
    {
      autoResetFilters: !manualFilters,
      autoResetGlobalFilter: !manualGlobalFilter,
      autoResetPage: !manualPagination,
      autoResetSortBy: !manualSortBy,
      columns,
      data,
      defaultColumn,
      disableFilters,
      disableSortBy,
      getRowId,
      initialState: initialStateModified,
      manualFilters,
      manualGlobalFilter,
      manualPagination,
      manualSortBy,
      pageCount: totalPages,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useRangeSelect(rangeSelectConfig),
    useResizeColumns,
  );

  const totalColumns = useMemo(() => {
    let resizerColumnCount = 0;
    visibleColumns.forEach((col, idx, elements) => {
      /*
       * if column can be resized and is not hidden and
       * the next column is also visible
       */
      if (!col.disableResizing && elements[idx + 1] && elements[idx + 1]) resizerColumnCount += 1;
    });
    return visibleColumns.length + resizerColumnCount;
  }, [visibleColumns]);

  const shouldShowResizer = useCallback(
    (column: ColumnInstance, nextColumn: ColumnInstance | undefined) => {
      return column.canResize && column.isVisible && nextColumn;
    },
    [],
  );

  const shouldShowPagination = useMemo(
    () => !disablePagination && rows.length > 0 && pageCount > 1,
    [disablePagination, rows.length, pageCount],
  );

  const onFilterChange = useAsyncDebounce(setFilters, 300);
  const onGlobalFilterChange = useAsyncDebounce(setGlobalFilter, 300);
  const onSortingChange = useAsyncDebounce(setSortBy, 10);
  const onRowSelection = useAsyncDebounce(setSelectedRowIds, 10);
  const onPageChange = useAsyncDebounce(changePage, 10);

  const handleFilterColumnClick = (column: ColumnInstance) => {
    try {
      const tableUserChosenFilters = JSON.parse(
        localStorage?.getItem(tableUserChosenFiltersStorageKey) ?? '{}',
      );

      localStorage.setItem(
        tableUserChosenFiltersStorageKey,
        JSON.stringify({
          ...tableUserChosenFilters,
          [filtersCacheKey]: {
            ...tableUserChosenFilters[filtersCacheKey],
            [column.id]: !column.isVisible,
          },
        }),
      );
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  // Override default cmd + f and ctrl + f behavior for browsers and instead open table filters if they exist.
  useEffect(() => {
    const handleFindShortcut = (event: KeyboardEvent) => {
      if (
        (event.metaKey || event.ctrlKey) &&
        event.key === 'f' &&
        toggleFilter &&
        !isModalOpened()
      ) {
        event.preventDefault();
        toggleFilter();
      }
    };

    window.addEventListener('keydown', handleFindShortcut);
    return () => {
      window.removeEventListener('keydown', handleFindShortcut);
    };
  }, [toggleFilter]);

  useEffect(() => {
    debug('filters', filters);
    if (manualFilters) {
      onFilterChange(filters);
    }
  }, [manualFilters, filters, onFilterChange]);

  useEffect(() => {
    if (manualGlobalFilter) {
      onGlobalFilterChange(globalFilter);
    }
  }, [manualGlobalFilter, globalFilter, onGlobalFilterChange]);

  useEffect(() => {
    debug('sortBy', sortBy);
    if (manualSortBy) {
      onSortingChange(sortBy);
    }
  }, [manualSortBy, sortBy, onSortingChange]);

  /**
   * @todo Ask Sakina to explain this.
   */
  useEffect(() => {
    debug('selected rows', selectedRowIds);
    onRowSelection(selectedRowIds);
  }, [selectedRowIds, onRowSelection, setSelectedRowIds]);

  useEffect(() => {
    debug('pageIndex', pageIndex);
    if (manualPagination) {
      onPageChange(pageIndex + 1);
    }
  }, [manualPagination, onPageChange, pageIndex]);

  /** Pagination has always show a first page when user use sort by. */
  useEffect(() => {
    gotoPage(initialState?.pageIndex ?? 0);
  }, [initialState?.pageIndex, gotoPage]);

  useEffect(function disableShiftTextSelectionForTable() {
    const tableElement = document.querySelector('table');
    const disableShiftTextSelectionHandler = (e: MouseEvent) => {
      document.onselectstart = () => {
        return !e.shiftKey;
      };
    };
    tableElement?.addEventListener('mousedown', disableShiftTextSelectionHandler);

    return () => {
      tableElement?.removeEventListener('mousedown', disableShiftTextSelectionHandler);
    };
  }, []);

  return (
    <Box>
      {showGlobalFilterV1 && (
        <TableTopBar
          customContent={customTopBarContent}
          onSearchValueChange={(e) => {
            setGlobalFilterInternal(e.target.value);
          }}
          searchValue={globalFilter}
        />
      )}
      <SemanticTable data-testid={testId} {...rest} {...getTableProps()}>
        <Box as="thead" backgroundColor="white" {...stickyProps}>
          {headerGroups.map((headerGroup) => {
            const hgKey = headerGroup.getHeaderGroupProps().key;
            return (
              <Fragment key={`${hgKey}_fragment`}>
                {!disableHeaders && (
                  <SemanticTable.Row {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index, elements) =>
                      column.id === 'search' ? (
                        <SearchHeaderCell
                          key={`${hgKey}_fragment_column_${index}`}
                          column={column}
                          toggleFilter={toggleFilter}
                        />
                      ) : (
                        <Fragment key={`${hgKey}_fragment_column_${index}`}>
                          <SemanticTable.HeaderCell
                            data-testid={`header ${column.id}`}
                            {...column.getSortByToggleProps()}
                            className={
                              // eslint-disable-next-line no-nested-ternary
                              column.canSort
                                ? column.sortDescFirst
                                  ? `${column.id} sortable desc`
                                  : `${column.id} sortable`
                                : `${column.id}`
                            }
                            sorted={
                              // eslint-disable-next-line no-nested-ternary
                              column.isSorted
                                ? column.isSortedDesc
                                  ? 'descending'
                                  : 'ascending'
                                : undefined
                            }
                            {...column.getHeaderProps()}
                            style={{
                              ...column.getHeaderProps().style,
                              textAlign: (column as ColumnInstance)?.centerHeader
                                ? 'center'
                                : undefined,
                              width: column?.width,
                            }}
                          >
                            {column.render('Header')}
                          </SemanticTable.HeaderCell>
                          {shouldShowResizer(column, elements[index + 1]) && (
                            <ColumnResizer
                              className={hoverIndex === index ? 'hovered' : ''}
                              index={index}
                              minWidth={104}
                              updateIndex={setHoverIndex}
                            />
                          )}
                        </Fragment>
                      ),
                    )}
                    {!disableColumnFiltering && (
                      <SemanticTable.HeaderCell className="filter-columns-button">
                        <FilterColumnButton
                          columns={allColumns}
                          onClick={handleFilterColumnClick}
                        />
                      </SemanticTable.HeaderCell>
                    )}
                  </SemanticTable.Row>
                )}
                <SemanticTable.Row
                  key={`${hgKey}_filter`}
                  className="filter-row"
                  style={{ display: `${!showFilter ? 'none' : ''}` }}
                >
                  {headerGroup.headers.map((column, idx, elements) => (
                    <Fragment key={`filter_${column.id}`}>
                      <SemanticTable.HeaderCell
                        style={{
                          ...column.getHeaderProps().style,
                          width: column?.width,
                        }}
                      >
                        {column.id === 'selection' &&
                          showSelectionHeader &&
                          column.render('Header')}
                        {column.canFilter &&
                          column.render('Filter', { manualGlobalFilter, showFilter })}
                      </SemanticTable.HeaderCell>
                      {shouldShowResizer(column, elements[idx + 1]) && (
                        <SemanticTable.HeaderCell
                          className={hoverIndex === idx ? 'empty-cell hovered' : 'empty-cell'}
                        />
                      )}
                    </Fragment>
                  ))}
                </SemanticTable.Row>
              </Fragment>
            );
          })}
        </Box>
        <SemanticTable.Body
          {...getTableBodyProps()}
          onMouseEnter={() => {
            setIsHighlightedRowActive(false);
          }}
        >
          {!loading && rows.length > 0 ? (
            rows.map((row) => {
              prepareRow(row);
              const rowProps = row.getRowProps();
              return (
                <TableRowWithHighlight
                  key={rowProps.key}
                  className={rowProps.className}
                  highlight={isHighlightedRowActive && highlightedRowId === row.id}
                  role={rowProps.role}
                >
                  {row.cells.map((cell, idx) => {
                    return (
                      <Fragment key={cell.column.id}>
                        <SemanticTable.Cell
                          className={cell.column.id}
                          data-testid={`cell ${cell.column.id}`}
                          {...cell.getCellProps()}
                          style={{
                            ...cell.getCellProps().style,
                            textAlign: (cell?.column as ColumnInstance)?.center
                              ? 'center'
                              : undefined,
                            width: cell?.column?.width,
                          }}
                        >
                          {cell.render('Cell', { globalFilter })}
                        </SemanticTable.Cell>
                        {shouldShowResizer(cell.column, visibleColumns[idx + 1]) && (
                          <SemanticTable.Cell className={hoverIndex === idx ? 'hovered' : ''} />
                        )}
                      </Fragment>
                    );
                  })}
                </TableRowWithHighlight>
              );
            })
          ) : (
            <SemanticTable.Row>
              <StyledSemanticTableCell colSpan={totalColumns} noBorder>
                {loading ? 'Loading data...' : emptyMessage}
              </StyledSemanticTableCell>
            </SemanticTable.Row>
          )}
        </SemanticTable.Body>
      </SemanticTable>
      {shouldShowPagination && (
        <StyledTablePagination>
          <Pagination
            activePage={pageIndex + 1}
            onPageChange={(_, { activePage }) => {
              gotoPage(Number(activePage) - 1);
              window.scrollTo(0, 0);
            }}
            secondary
            size="mini"
            totalPages={pageCount}
          />
        </StyledTablePagination>
      )}
    </Box>
  );
};

export default Table;
