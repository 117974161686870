import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Box from '@components/Box';
import rootTheme from '@styles/theme';

type OverflowVariant = 'wrap' | 'scroll';
export interface OverflowVariantProps {
  overflowVariant?: OverflowVariant;
}

type Variants = 'default' | 'filled' | 'flat';

export interface VariantProps {
  variant?: Variants;
}

type TabsVariant = 'default';

export interface TabsVariantProps {
  variant?: TabsVariant;
}

const variants: Record<Variants, SerializedStyles> = {
  default: css`
    background-color: transparent;
    border-color: ${rootTheme.colors.v1.gray[200]};
  `,
  filled: css`
    background-color: ${rootTheme.colors.gray[100]};
    border-color: ${rootTheme.colors.v1.gray[200]};
  `,
  flat: css`
    background-color: transparent;
    border-color: ${rootTheme.colors.v1.gray[200]};
  `,
};

const variantsHover: Record<Variants, SerializedStyles> = {
  default: css`
    color: ${rootTheme.colors.primary};
    border-color: ${rootTheme.colors.primary};

    &::before {
      background: ${rootTheme.colors.primary};
    }
  `,
  filled: css`
    color: ${rootTheme.colors.primary};
    border-color: ${rootTheme.colors.primary};

    &::before {
      background: ${rootTheme.colors.primary};
    }
  `,
  flat: css``,
};

const variantsActive: Record<Variants, SerializedStyles> = {
  default: css`
    color: ${rootTheme.colors.primary};
    font-weight: ${rootTheme.typography.fontWeights.bold};
    border-color: ${rootTheme.colors.primary};

    &::before {
      background: ${rootTheme.colors.primary};
    }
  `,
  filled: css`
    color: ${rootTheme.colors.primary};
    font-weight: ${rootTheme.typography.fontWeights.bold};
    border-color: ${rootTheme.colors.primary};

    &::before {
      background: ${rootTheme.colors.primary};
    }
  `,
  flat: css`
    color: ${rootTheme.colors.primary};
    font-weight: ${rootTheme.typography.fontWeights.bold};
    border-color: ${rootTheme.colors.primary};

    &::before {
      background: ${rootTheme.colors.primary};
    }
  `,
};

export interface StyledTabProps extends VariantProps {
  subTab?: boolean;
}

export const StyledTab = styled.button<StyledTabProps>`
  ${({ variant = 'default' }) => variants[variant]};

  color: ${({ theme }) => theme.colors.v1.gray[500]};
  display: inline-flex;
  align-items: center;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  gap: ${({ theme }) => theme.space(0.5)};
  font-size: ${({ subTab, theme }) =>
    subTab ? theme.typography.fontSizes.body2 : theme.typography.fontSizes.default};
  font-family: ${({ theme }) => `${theme.typography.fontFamilies.primary}`};
  line-height: ${({ theme }) => `${theme.typography.lineHeights.default}`};
  outline: none;
  padding: ${({ theme }) => theme.space(1, 1.5)};
  position: relative;
  transition: color 0.1s;
  border-bottom: 1px ${({ theme }) => theme.colors.v1.gray[200]} solid;

  ${({ theme }) => theme.media.down('md')`
     padding: ${theme.space(1)}
  `}

  &::before {
    height: 1px;
    background: transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    z-index: 1;
  }

  &:hover {
    ${({ variant = 'default' }) => variantsHover[variant]};
  }
  &.active {
    ${({ variant = 'default' }) => variantsActive[variant]};
  }
  path {
    fill: currentColor;
  }
`;

export const StyledTabsOverflowGradient = styled.div`
  width: 100px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(to right, transparent, white);
  pointer-events: none;
  display: none;
`;

StyledTab.defaultProps = {
  role: 'tab',
  type: 'button',
};

const tabsVariants: Record<TabsVariant, SerializedStyles> = {
  default: css`
    background: #fff;

    &::before {
      height: 1px;
      background: ${rootTheme.colors.v1.gray[200]};
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
    }
  `,
};

export const StyledTabs = styled(Box)<TabsVariantProps>`
  background: #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  position: relative;

  ${StyledTabsOverflowGradient} {
    display: block;
  }

  ${({ variant = 'default' }) => tabsVariants[variant]};
`;

StyledTabs.defaultProps = {
  noDefault: true,
  role: 'tablist',
};

const overflowVariants: Record<OverflowVariant, SerializedStyles> = {
  scroll: css`
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    /* Firefox */
    scrollbar-width: none;
  `,
  wrap: css`
    flex-wrap: wrap;
  `,
};

export const StyledTabsLeft = styled.div<OverflowVariantProps>`
  display: flex;
  align-items: center;
  background: white;
  position: relative;

  &::before {
    height: 1px;
    background: ${({ theme }) => theme.colors.v1.gray[200]};
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
  }

  ${({ overflowVariant = 'wrap' }) => overflowVariants[overflowVariant]};
`;

export const StyledTabsRight = styled.div``;
