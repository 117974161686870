import styled from '@emotion/styled';

import TreeV2 from '@components/Tree/Tree.v2';

import { StyledOptionsListItem } from '../OptionsList.styles';

export const StyledTreeV2 = styled(TreeV2)`
  > li[aria-selected='true'] {
    background-color: ${({ theme }) => theme.colors.v1.gray[100]};
  }
`;

export const StyledTreeOptionsItem = styled(StyledOptionsListItem)`
  padding: ${({ theme }) => theme.space(1, 0.5, 1, 0.25)};
  background-color: transparent;
`;
