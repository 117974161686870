import styled from '@emotion/styled';

export const FilterSidebarStyle = styled.div`
  margin: 0 0.2rem 2rem 1.5rem;
  .accordion-sidebar-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    box-shadow: none !important;
    border: 0;
  }
  .filter-label-icon {
    display: inline;
    width: 0.95rem;
    height: 0.95rem;
    margin-right: 0.3rem;
    margin-top: -0.15rem;
  }
`;

export const StyledTitle = styled.h3`
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  text-align: left;
  color: #2c2e36;
  padding: 2rem 1.5rem 0;
`;

export const StyledLabel = styled.div`
  display: flex;
  font-size: 0.8125;
  span {
    margin-right: 0;
    align-items: normal;

    &.text-content {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
