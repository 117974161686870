import { useMemo } from 'react';

import { useFetchOrganizationUsers } from '@api/organizationUser';
import { TeamModel } from '@api/teams/TeamModel';
import { mapOwnerToOption } from '@components/OwnerSelect/utils';
import { Option } from '@components/UI/Select.v1/types';
import stripSpaces from '@utils/stripSpaces';

const TEAM_USERS_PARAMS = {
  is_active: true,
  order: 'role,name',
  page: 1,
  page_size: 6,
  query: stripSpaces(`{
    role,
    user{
      guid,
      avatar,
      first_name,
      last_name,
      is_active,
      team
    }
  }`),
  roles: 'admin,data_manager',
};

const useDefaultOptions = (deletedTeam: TeamModel) => {
  const { data: organizationUsers, isLoading } = useFetchOrganizationUsers({
    enabled: Boolean(deletedTeam.guid),
    params: {
      teams: deletedTeam.guid,
      ...TEAM_USERS_PARAMS,
    },
  });

  const options: Option[] = useMemo(() => {
    if (!organizationUsers) return [];

    if (organizationUsers?.results && organizationUsers?.results.length > 0) {
      const teamUsers = organizationUsers.results.map((orgUser) => orgUser.user);
      return teamUsers.map((owner) => mapOwnerToOption(owner));
    }

    return [];
  }, [organizationUsers]);
  return { isLoading, options };
};

export default useDefaultOptions;
