import React from 'react';

import {
  useDeleteClientApiTokenById,
  useFetchClientApiTokenById,
  usePutClientApiTokenById,
} from '@api/clientApiToken';
import invalidateCache from '@api/invalidateCache';
import setCacheData from '@api/setCacheData';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import CircularLoader from '@components/CircularLoader';
import Form from '@components/Form';
import FormStyled from '@components/FormStyled/FormStyled';
import Input from '@components/Input/Input.v1';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import InputLabel from '@components/UI/Form/InputLabel';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';
import { useUserContext } from '@context/User';
import { dateTimeFormat } from '@utils/moment';

interface ClientApiModalProps {
  onClose: () => void;
}

const ClientApiModal: React.FC<ClientApiModalProps> = ({ onClose }) => {
  const { user } = useUserContext();
  const userGuid = (user && user.guid) || '';
  const { data, isLoading: isDataLoading } = useFetchClientApiTokenById(userGuid);
  const { isLoading: isUpdateLoading, mutate: createToken } = usePutClientApiTokenById(userGuid, {
    onError: () => {
      renderErrorToast('Update failed');
    },
    onSuccess: () => {
      invalidateCache((keys) => [keys.clientApiToken.user(userGuid)]);
      renderInfoToast('Successfully added');
    },
  });
  const { isLoading: isDeleteLoading, mutate: deleteToken } = useDeleteClientApiTokenById(
    userGuid,
    {
      onError: () => {
        renderErrorToast('Deletion failed');
      },
      onSuccess: () => {
        setCacheData((keys) => [[keys.clientApiToken.user(userGuid), undefined]]);
        renderInfoToast('Successfully deleted');
      },
    },
  );

  return (
    <Modal onClose={onClose} open size="medium" useContext>
      <ModalHeader onClose={onClose} title="Client API Token" />
      <ModalContent minHeight="auto" py={1}>
        <FormStyled labelWidth={8}>
          <Form isLoading={isDataLoading}>
            <InputLabel
              alignItems="center"
              compWidth="100%"
              fontSize="body2"
              fontWeight="bold"
              my={1}
            >
              <Box compWidth="120px">API Token</Box>
              <Input
                compWidth="100%"
                placeholder="Click 'Create' to generate an API token"
                type="text"
                value={data?.key ?? ''}
              />
            </InputLabel>
            {data?.key && (
              <>
                <InputLabel
                  alignItems="center"
                  compWidth="100%"
                  fontSize="body2"
                  fontWeight="bold"
                  my={1}
                >
                  <Box compWidth="120px">Created</Box>
                  <Input
                    compWidth="100%"
                    readOnly
                    type="datetime"
                    value={data?.created?.format(dateTimeFormat())}
                  />
                </InputLabel>
                <InputLabel
                  alignItems="center"
                  compWidth="100%"
                  fontSize="body2"
                  fontWeight="bold"
                  my={1}
                >
                  <Box compWidth="120px">Last activity</Box>
                  <Input
                    compWidth="100%"
                    readOnly
                    type="datetime"
                    value={data?.lastActivity?.format(dateTimeFormat())}
                  />
                </InputLabel>
              </>
            )}
          </Form>
        </FormStyled>
      </ModalContent>
      <ModalFooter>
        {!isDataLoading && (
          <>
            {!data?.key && (
              <Button
                disabled={isDataLoading || isUpdateLoading}
                onClick={() => {
                  createToken({});
                }}
                startIcon={isUpdateLoading && <CircularLoader compSize={1} />}
              >
                Create
              </Button>
            )}
            {data?.key && (
              <Button
                disabled={isDataLoading || isDeleteLoading}
                onClick={() => {
                  deleteToken(null);
                }}
                startIcon={isDeleteLoading && <CircularLoader compSize={1} />}
                variant="outlined"
              >
                Delete
              </Button>
            )}
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ClientApiModal;
