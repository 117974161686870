import React, { useEffect, useState } from 'react';
import createDebug from 'debug';
import { Popup } from 'semantic-ui-react';

import {
  useFetchNotifications,
  usePatchAllNotificationsAsRead,
  usePatchNotificationAsRead,
} from '@api/notifications';
import { NotificationModel } from '@api/notifications/NotificationModel';
import { isIngestionComplete } from '@api/organizations/OrganizationModel';
import Box from '@components/Box';
import Icon from '@components/UI/Icon';
import { useUserContext } from '@context/User';
import { PaginatedResponse } from '@models/PaginatedResponse';
import zIndex from '@styles/theme/zIndex';
import useInterval from '@utils/useInterval';

import {
  StyledNotificationButton,
  StyledNotificationHeading,
  StyledNotificationHeadingContainer,
  StyledNotificationShowMoreContainer,
} from './Notification.styles';
import NotificationItem from './NotificationItem';

const debug = createDebug('selectstar:notification');

const config = {
  params: {
    page: 1,
    page_size: 7,
  },
};

const mergeNotificationList =
  (newData: NotificationModel[], page: number) =>
  (existingData: NotificationModel[] | undefined) => {
    if (!existingData) return newData;

    const newState: NotificationModel[] = [...existingData];
    newData.forEach((item) => {
      if (!existingData.find((_) => _.id === item.id)) {
        // figure our whether to place at the beginning or the end
        if (page === 1) newState.unshift(item);
        else newState.push(item);
      }
    });

    return newState;
  };

const Notification: React.FC = () => {
  const [isNotificationOpen, setNotificationOpen] = useState<boolean>(false);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
  const [notifications, mergeNotifications] = useState<NotificationModel[]>();
  const [notificationConfig, updateNotificationConfig] = useState(config);
  const [updatedDataResponse, setUpdatedDataResponse] = useState<
    PaginatedResponse<NotificationModel> | undefined
  >();
  const [totalLeft, setTotalLeft] = useState<number>();
  const { dataSources, organization } = useUserContext();

  const { data: notificationResponse, refetch: refetchNotifications } =
    useFetchNotifications(notificationConfig);

  const { mutate: markAllAsRead } = usePatchAllNotificationsAsRead({
    ...notificationConfig,
    onSuccess: (data) => setUpdatedDataResponse(data),
  });

  const markedAsRead = (data: NotificationModel) => {
    debug('mark single as read');
    mergeNotifications((prev) => {
      if (prev) {
        const newState = [...prev];
        const idx = newState.findIndex((item) => item.id === data.id);
        newState[idx] = data;
        return newState;
      }
      return prev;
    });
  };

  const { mutate: markAsRead } = usePatchNotificationAsRead('', {
    onSuccess: (data) => markedAsRead(data),
  });

  useEffect(() => {
    if (notificationResponse) {
      debug('Got notification response');
      mergeNotifications(
        mergeNotificationList(notificationResponse.results, notificationConfig.params.page),
      );
      setTotalLeft((prev) => {
        /*
         * if total left is 0 that means we've brought out the old notifications
         * we also have to check if page is 1 now which means we have new notification
         * but old notification count is still 0
         */
        if (prev === 0) return prev;
        // if results are less than page_size, we've exhausted all notifications
        if (notificationResponse.results.length < notificationConfig.params.page_size) return 0;
        /*
         * this is the normal case when we have results === page_size and more
         * notifications on the next page
         */
        return (
          notificationResponse.count -
          notificationResponse.results.length * notificationConfig.params.page
        );
      });
      if (isFirstTime) {
        const anyUnread = notificationResponse.results?.some(
          (item: NotificationModel) => item.unread,
        );
        if (anyUnread) setNotificationOpen(true);
        setIsFirstTime(false);
      }
    }
  }, [
    notificationResponse,
    mergeNotifications,
    isFirstTime,
    setNotificationOpen,
    setIsFirstTime,
    setTotalLeft,
    notificationConfig.params,
  ]);

  useEffect(() => {
    if (updatedDataResponse) {
      /*
       * instead of figuring out which notifications have returned
       * from the BE, just loop through the notifications we have
       * and mark them as read
       */
      mergeNotifications((prev) => {
        if (!prev) {
          return prev;
        }
        const newState = [...prev];
        newState.forEach((notification) => (notification.unread = false));
        return newState;
      });
    }
  }, [updatedDataResponse, mergeNotifications]);

  useInterval(() => {
    if (notificationConfig.params.page === 1) {
      refetchNotifications();
    } else {
      updateNotificationConfig((prev) => ({
        params: {
          ...prev.params,
          page: 1,
        },
      }));
    }
  }, 100000);

  const getNextPage = () => {
    updateNotificationConfig((prev) => ({
      params: {
        ...prev.params,
        page: prev.params.page + 1,
      },
    }));
  };

  const isAnyUnread = notifications?.some((item) => item.unread);

  return (
    <Popup
      as="div"
      flowing
      offset={[14, 0]}
      on="click"
      onClose={() => setNotificationOpen(false)}
      onOpen={() => setNotificationOpen(true)}
      open={isNotificationOpen && isIngestionComplete(organization, dataSources)}
      pinned
      popperModifiers={[
        {
          preventOverflow: {
            boundariesElement: 'offsetParent',
          },
        },
      ]}
      position="bottom right"
      positionFixed
      style={{
        padding: '0',
        zIndex: zIndex.floatingElement,
      }}
      trigger={
        <Icon
          color="white"
          compHeight="30px"
          compWidth="25px"
          data-testid="notification"
          name={isAnyUnread ? 'notification-bell' : 'bell'}
        />
      }
    >
      <Box
        compWidth="340px"
        maxHeight="640px"
        minHeight="100px"
        noDefault
        overflowX="hidden"
        overflowY="auto"
      >
        <StyledNotificationHeadingContainer>
          <StyledNotificationHeading>Notifications</StyledNotificationHeading>
          <StyledNotificationButton onClick={() => markAllAsRead({})} role="button">
            Mark all as read
          </StyledNotificationButton>
        </StyledNotificationHeadingContainer>
        {notifications?.map((item) => (
          <NotificationItem key={item.id} item={item} markAsRead={markAsRead} />
        ))}
        <StyledNotificationShowMoreContainer>
          {totalLeft && totalLeft > 0 ? (
            <StyledNotificationButton onClick={getNextPage} role="button">
              {`Show ${totalLeft} More`}
            </StyledNotificationButton>
          ) : (
            <StyledNotificationButton>No More</StyledNotificationButton>
          )}
        </StyledNotificationShowMoreContainer>
      </Box>
    </Popup>
  );
};

export default Notification;
