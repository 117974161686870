import React, { useEffect, useState } from 'react';

import { DashboardModel } from '@api/dashboards/DashboardModel';
import { LookerExploreModel } from '@api/explores/LookerExploreModel';
import invalidateCache from '@api/invalidateCache';
import { LookMLViewModel } from '@api/lookML/LookMLViewModel';
import { searchCacheKeys } from '@api/search';
import { TableauDataSourceModel } from '@api/tableau/TableauDataSourceModel';
import { TableModel } from '@api/tables/TableModel';
import { TagModel } from '@api/tags/TagModel';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import fetchClient from '@lib/fetchClient';
import EditableHeader from '@pages/DocumentsPage/EditableHeader/EditableHeader';
import { EnhancedErrorResult } from '@utils/createEnhancedError/createEnhancedError';

interface MetadataEditableNameProps {
  data?:
    | TableModel
    | DashboardModel
    | LookerExploreModel
    | TableauDataSourceModel
    | TagModel
    | LookMLViewModel;
  isEditable?: boolean;
  patchHook: Function;
}

const MetadataEditableName: React.FC<MetadataEditableNameProps> = ({
  data,
  isEditable,
  patchHook,
}) => {
  const [name, setName] = useState(data?.name || '');

  const shouldDisable = !isEditable || (data?.objectTypeV1 === 'tags' && data?.type === 'dbt');

  const { mutate, reload } = patchHook(data?.guid || '', {
    onError: (e: EnhancedErrorResult) => {
      renderErrorToast(e?.data?.name ?? e?.data?.detail ?? 'There was an error in update');
    },
    onSuccess: (d: { guid: string }) => {
      fetchClient.setQueryData(searchCacheKeys.searchItem(d.guid), d);
      invalidateCache((keys) => [keys.documents.hierarchy, keys.tags.hierarchy]);
      renderInfoToast('Edit saved.');
      reload?.();
    },
  });

  const handleNameSave = () => {
    if (data?.name !== name) {
      mutate({
        name,
      });
    }
  };

  useEffect(() => {
    setName(data?.name || '');
  }, [data?.name]);

  return (
    <EditableHeader
      disabled={shouldDisable}
      onChange={(value: string) => {
        setName(value);
      }}
      onSave={handleNameSave}
      value={name || ''}
    />
  );
};

export default MetadataEditableName;
