import styled from '@emotion/styled';

interface StyledExploreTreeLegendProps {
  isOpen: boolean;
}

export const StyledExploreTreeLegend = styled.div<StyledExploreTreeLegendProps>`
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radius.md};
  border: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
  bottom: ${({ theme }) => theme.space(4)};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  padding: ${({ theme }) => theme.space(2)};
  position: absolute;
  right: ${({ theme }) => theme.space(5)};
  transition: transform 0.15s ease;
  width: 320px;
`;
