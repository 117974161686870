import BiTables from './BiTables';
import dashboards from './dashboards';
import datasets from './datasets';
import datasetsV1 from './datasetsV1';
import explores from './explores';
import notebooks from './notebooks';
import tableaudatasources from './tableaudatasources';
import tables from './tables';
import tags from './tags';
import terms from './terms';
import { MetadataObjectConfig } from './types';
import views from './views';
import workbooks from './workbooks';

const config: { [path: string]: MetadataObjectConfig } = {
  '/bi-table/:guid': BiTables,
  '/dashboards/:guid': dashboards,
  '/datasets/:guid': datasetsV1,
  '/explores/:guid': explores,
  '/notebooks/:guid': notebooks,
  '/power-bi-tables/:guid': datasets,
  '/tableaudatasources/:guid': tableaudatasources,
  '/tables/:guid': tables,
  '/tags/:guid': tags,
  '/terms/:guid': terms,
  '/views/:guid': views,
  '/workbooks/:guid': workbooks,
};

export default config;
