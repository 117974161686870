interface DataTypeMapInterface {
  [key: string]: string;
}

interface TooltipMapInterface {
  [key: string]: DataTypeMapInterface;
}

const tooltipMap: TooltipMapInterface = {
  bifolder: {
    default: 'Folder',
  },
  collection: {
    default: 'Collection',
    mode: 'Mode Collection',
  },
  column: {
    default: 'Column',
  },
  dashboard: {
    answer: 'Answer',
    dashboard: 'Looker Dashboard',
    data_studio_dashboard: 'Looker Studio Dashboard',
    default: 'Looker Dashboard',
    liveboard: 'Liveboard',
    look: 'Looker Look',
    metabase_dashboard: 'Metabase Dashboard',
    periscope_dashboard: 'Sisense / Periscope Dashboard',
    power_bi_dashboard: 'Power BI Dashboard',
    power_bi_report: 'Power BI Report',
    report: 'Mode Report',
    sigma_dashboard: 'Sigma Workbook',
    workbook: 'Workbook',
  },
  database: {
    bigquery: 'BigQuery Project',
    default: 'Database',
    postgres: 'Postgres Database',
    snowflake: 'Snowflake Database',
  },
  document: {
    default: 'Document',
  },
  explore: {
    default: 'Looker Explore',
  },
  explorefield: {
    default: 'Looker Explore Field',
  },
  folder: {
    default: 'Project',
  },
  lookmlfield: {
    default: 'Looker View Field',
  },
  lookmlview: {
    default: 'Looker View',
  },
  metabasetable: {
    model: 'Metabase Model',
    question: 'Metabase Question',
    table: 'Metabase Table',
  },
  metric: {
    default: 'Metric',
  },
  reportquery: {
    column: 'Column',
    default: 'Report Query',
    visual: 'Chart',
  },
  schema: {
    bigquery: 'BigQuery Dataset',
    default: 'Schema',
    postgres: 'Postgres Schema',
    snowflake: 'Snowflake Schema',
  },
  table: {
    default: 'Table',
    ephemeral: 'Ephemeral Model',
    external_table: 'External Table',
    incremental: 'Incremental Model',
    seed: 'Seed Model',
    source: 'Source Table',
    table: 'Table',
    view: 'View',
  },
  tableaucolumn: {
    default: 'Tableau Column',
  },
  tableaudatasource: {
    default: 'Tableau Datasource',
    published: 'Tableau Datasource - Published',
  },
  tableaufield: {
    default: 'Tableau Field',
  },
  tableautable: {
    default: 'Tableau Table',
    'excel-direct': 'Excel direct',
  },
  tableauview: {
    dashboard: 'Tableau Dashboard',
    default: 'Tableau View',
    sheet: 'Tableau Sheet',
  },
  term: {
    default: 'Term',
  },
  thoughtspotcolumn: {
    default: 'column',
  },
  thoughtspottable: {
    default: 'Table',
    one_to_one_logical: 'One To One Logical',
    sql_view: 'View',
    worksheet: 'Worksheet',
  },
};

function getTooltip(objectType = '', dataType = ''): string {
  const lowerCaseObjType = objectType?.toLowerCase();
  const lowerCaseDataType = dataType?.toLowerCase();

  if (lowerCaseObjType in tooltipMap) {
    const itemMap = tooltipMap[lowerCaseObjType];

    if (lowerCaseDataType in itemMap) {
      return itemMap[lowerCaseDataType];
    }

    return itemMap.default;
  }

  return '';
}

export default getTooltip;
