import React, { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { NavLink, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import useStickyContext from '@components/Sticky';
import type { TabProps, TabsProps } from '@components/Tabs';
import { StyledTab, StyledTabs, StyledTabsLeft, StyledTabsRight } from '@components/Tabs';

export interface TabRoutesConfig extends Pick<TabProps, 'variant'> {
  component: () => React.ReactNode;
  contentOffset?: number;
  default?: boolean;
  label: string | JSX.Element;
  openInNewTab?: boolean;
  path?: string;
  route: string;
  search?: string;
  stickyHeader?: boolean;
}

export interface TabsRouterProps extends Pick<TabsProps, 'action' | 'subTabs' | 'variant' | 'mt'> {
  config: TabRoutesConfig[];
  isLoading?: boolean;
  sticky?: boolean;
}

const TabsRouter: React.FC<TabsRouterProps> = ({
  action,
  config,
  isLoading,
  sticky = false,
  subTabs = false,
  variant,
  ...other
}) => {
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const { zIndex } = useTheme();
  const stickyProps = useStickyContext({ enabled: sticky });

  const tabsData = useMemo(() => {
    const defaultTab = config.find((item) => item.default);
    const activeTab = config.find((tab) => `${location.pathname}/`.search(`${tab.route}/`) >= 0);
    const duplicatedRoutes = config?.map((c) => c.route)?.filter((v, i, a) => a.indexOf(v) !== i);

    if (duplicatedRoutes?.length > 0) {
      throw new Error(`Duplicated routes are not allowed: ${duplicatedRoutes?.join(', ')}`);
    }

    return { active: activeTab, default: defaultTab };
  }, [config, location.pathname]);

  if (tabsData.default?.route && !tabsData.active) {
    return (
      <Redirect
        to={{
          pathname: `${url}${tabsData.default?.route}`,
          search: location.search,
          state: location.state,
        }}
      />
    );
  }

  if (isLoading) return <CircularLoader compDisplay="block" mx="auto" my={1} />;

  return (
    <>
      <Box {...stickyProps} zIndex={zIndex.contentHeaders}>
        <StyledTabs variant={variant} {...other}>
          <StyledTabsLeft>
            {config.map((item) => {
              return (
                <StyledTab
                  key={item.route}
                  // eslint-disable-next-line react/no-unstable-nested-components
                  as={(props) => (
                    <NavLink
                      {...props}
                      activeClassName="active"
                      rel={item.openInNewTab ? 'noopener noreferrer' : undefined}
                      target={item.openInNewTab ? '_blank' : undefined}
                      to={{
                        pathname: `${
                          item.openInNewTab ? process.env.REACT_APP_SS_APP_URL : ''
                        }${url}${item?.route}`,
                        search: item.search ?? location.search,
                        state: location.state,
                      }}
                    />
                  )}
                  subTab={subTabs}
                  variant={item.variant || variant}
                >
                  {item.label}
                </StyledTab>
              );
            })}
          </StyledTabsLeft>
          {action && <StyledTabsRight>{action}</StyledTabsRight>}
        </StyledTabs>
      </Box>
      <Switch>
        {config.map((item) => (
          <Route key={`${url}${item.route}`} path={`${path}${item?.path ?? item?.route}`}>
            <Box mt={item.contentOffset ?? 0} />
            {item.component()}
          </Route>
        ))}
      </Switch>
    </>
  );
};

export default TabsRouter;
