import React from 'react';

import { useDeleteSlackSubscriber } from '@api/slack/subscribers';
import Box from '@components/Box';
import { renderInfoToast } from '@components/Toast';

import DeleteConfirmationModal from '../DeleteConfirmationModal';

interface Props {
  onClose: () => void;
  onSuccess?: () => void;
  reloadData?: () => void;
}

const DisconnectSlackModal = (props: Props) => {
  const { onClose, onSuccess, reloadData } = props;

  const {
    error,
    isLoading,
    mutate: deleteSlackSubscriber,
  } = useDeleteSlackSubscriber({
    onSuccess: () => {
      renderInfoToast('Slack Integration Disconnected');
      onSuccess?.();
      reloadData?.();
    },
  });

  const handleSubmit = () => {
    deleteSlackSubscriber(null);
  };

  return (
    <DeleteConfirmationModal
      deleteButtonText="Disconnect"
      error={error?.message}
      loading={isLoading}
      onClose={onClose}
      onRemove={handleSubmit}
      open
      title="Disconnect Select Star from Slack"
      useContext
    >
      <Box compDisplay="flex" compWidth="100%" flexDirection="column">
        Are you sure you want to disconnect Select Star from Slack?
      </Box>
    </DeleteConfirmationModal>
  );
};

export default DisconnectSlackModal;
