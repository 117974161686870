import React, { useEffect, useState } from 'react';

import { DataSourcesMutatePayload, useFetchDataSources } from '@api/dataSources';
import { useFetchDsUsers, usePatchDsUser } from '@api/dsusers';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Dropdown from '@components/Dropdown';
import { getDatasourceOptions, getDsusersOptions } from '@components/Dropdown/helpers';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { isWarehouseType } from '@models/DataSourceCredentials';
import { DataSourceModel } from '@models/DataSourceModel';

import useDataSourceMutation from '../useDataSourceMutation';

import { DataSourceFormProps } from './types';

const ModeForm: React.FC<DataSourceFormProps> = ({
  children,
  dataSource,
  dataType,
  name = '',
  onSuccess,
  renderBefore,
}) => {
  const [datasourceConnections, setDatasourceConnections] = useState<DataSourceModel[]>([]);
  const [selectedDSConnection, setSelectedDSConnection] = useState<string>();
  const [dsUsers, setDsUsers] = useState<DsUserModel[]>([]);
  const [selectedDsUser, setSelectedDsUser] = useState<string>();
  const segment = useSegmentContext();

  const { mutate: updateDsUser } = usePatchDsUser(selectedDsUser || '');

  const { error, isLoading, mutate } = useDataSourceMutation({
    dataSource,
    onSuccess: (createdDataSource: DataSourceModel) => {
      onSuccess(createdDataSource);

      if (selectedDsUser) {
        updateDsUser({ data: { is_mode_service_account: true } });
      }
    },
  });

  const { handleChange, handleSubmit, values } = useForm({
    initialValues: {
      name: dataSource?.name || name,
      organization: '',
      password: '',
      user: '',
    },
    onSubmit: (val) => {
      const payload = {} as DataSourcesMutatePayload;
      if (dataSource) {
        payload.guid = dataSource.guid;
        payload.type = dataSource.type;
      } else {
        payload.type = dataType;
      }
      payload.name = val.name;
      payload.credentials = {
        organization: val.organization,
        password: val.password,
        username: val.user,
      };
      mutate(payload);
      segment?.track(SegmentTrackEventName.CreateServiceAccountConnectButtonClicked, { dataType });
    },
  });

  const {
    data: dataSourcesResponse,
    error: dataSourcesError,
    isLoading: isLoadingDataSources,
  } = useFetchDataSources();

  const dsUserConfig = React.useMemo(
    () => ({
      params: {
        datasources: selectedDSConnection || '',
      },
    }),
    [selectedDSConnection],
  );

  const {
    data: dsUserResponse,
    error: dsUsersError,
    isLoading: loadingDsUsers,
  } = useFetchDsUsers({
    ...dsUserConfig,
  });

  useEffect(() => {
    if (dataSourcesResponse) {
      setDatasourceConnections(
        dataSourcesResponse.results.filter((ds: DataSourceModel) => isWarehouseType(ds.type)),
      );
    }
  }, [dataSourcesResponse]);

  useEffect(() => {
    if (selectedDSConnection && dsUserResponse) {
      setDsUsers(dsUserResponse.results);
    }
  }, [dsUserResponse]);

  const handleDatasourceSelection = (_e: React.SyntheticEvent, { value }: any) => {
    setSelectedDSConnection(value);
    if (!value) {
      setSelectedDsUser('');
    }
  };

  const handleDsUserSelection = (_e: React.SyntheticEvent, { value }: any) => {
    setSelectedDsUser(value);
  };

  const dataSourceOptions = getDatasourceOptions(datasourceConnections);
  const userOptions = getDsusersOptions(dsUsers);

  return (
    <Form isLoading={isLoading || isLoadingDataSources} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        {renderBefore?.({ error, loading: isLoading })}
        <StyledLabel>
          Display Name
          <Input
            error={error?.data?.name}
            helperText={error?.data?.name}
            maxLength={50}
            name="name"
            onChange={handleChange}
            placeholder="Mode"
            type="text"
            value={values.name}
          />
        </StyledLabel>
        <StyledLabel>
          Workspace API token
          <Input
            error={error?.data?.username}
            helperText={error?.data?.username}
            name="user"
            onChange={handleChange}
            placeholder="Workspace API token"
            type="text"
            value={values.user}
          />
        </StyledLabel>
        <StyledLabel>
          Token secret
          <Input
            error={error?.data?.organization}
            helperText={error?.data?.organization}
            name="password"
            onChange={handleChange}
            placeholder="Token secret"
            type="password"
            value={values.password}
          />
        </StyledLabel>
        <StyledLabel>
          Organization
          <Input
            error={error?.data?.organization}
            helperText={error?.data?.organization}
            name="organization"
            onChange={handleChange}
            placeholder="organization"
            type="text"
            value={values.organization}
          />
        </StyledLabel>
        {datasourceConnections.length > 0 && (
          <>
            <StyledLabel as="div">
              DB Connection
              <Dropdown
                clearable
                closeOnChange
                error={Boolean(dataSourcesError)}
                loading={isLoadingDataSources}
                onChange={handleDatasourceSelection}
                options={dataSourceOptions}
                search
                selection
                selectOnBlur={false}
                selectOnNavigation={false}
                upward
                value={selectedDSConnection}
              />
            </StyledLabel>
            <StyledLabel as="div">
              DB Username
              <Dropdown
                clearable
                disabled={!selectedDSConnection && !selectedDsUser}
                error={Boolean(dsUsersError)}
                loading={loadingDsUsers}
                onChange={handleDsUserSelection}
                options={userOptions}
                placeholder={!selectedDSConnection ? 'Please select a DB connection first.' : ''}
                search
                selection
                selectOnBlur={false}
                selectOnNavigation={false}
                upward
                value={selectedDsUser}
              />
            </StyledLabel>
          </>
        )}
      </StyledFormHorizontalLabelGrid>
      {children?.({ error, loading: isLoading })}
    </Form>
  );
};

export default ModeForm;
