import styled from '@emotion/styled';
import { Dropdown } from 'semantic-ui-react';

import Box from '@components/Box';

export const TreeContainer = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  min-height: 3em;
  padding: ${({ theme }) => theme.space(0, 0, 1, 0)};

  * {
    outline: none;
  }

  &.keyUser li.focused {
    outline: 1px dotted gray;
  }
`;

export const SortByPanel = styled(Box)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  border-bottom: 1px #d8dbe0 solid;
  margin: ${({ theme }) => theme.space(0, 0, 1, 0)};
  width: 100%;
`;

export const StyledDropdown = styled(Dropdown)`
  &.ui.dropdown .menu > .item {
    padding: 0 !important;
    label.InputLabel {
      padding: ${({ theme }) => `${theme.space(1)} !important`};
    }
  }
`;
