import React from 'react';
import { useRecoilValue } from 'recoil';

import { metadataType } from '@atoms';
import { FiltersSidebar } from '@components/FiltersSidebar';
import { DataSourceTypesType } from '@models/DataSourceCredentials';
import { Filter } from '@utils';

export interface TableFiltersProps {
  dataSourceType?: DataSourceTypesType | null;
  filterService: Filter.FilterServiceInterface;
}

const TableFilters: React.FC<TableFiltersProps> = ({ dataSourceType, filterService }) => {
  const metadata = useRecoilValue(metadataType);
  return (
    <FiltersSidebar
      dataSourceType={dataSourceType}
      filterService={filterService}
      isCategoryTags={metadata === 'tables'}
      isColumnDataType={metadata === 'columns'}
      isDataType
      isDbtTags
      isOwners={metadata === 'tables'}
      isPopularity
      isSchemaOrTable={metadata === 'columns'}
      isSelectStarDetected
      isStatusTags
      showHidden={metadata === 'tables'}
      showJoinKeyFilter={metadata === 'columns'}
    />
  );
};

export default React.memo(TableFilters);
