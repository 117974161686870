import { Filter } from '@utils';
import stripSpaces from '@utils/stripSpaces';

export const defaultFilterOptions: Filter.FilterOptions = {
  order: 'name',
  page_size: 100,
  query: stripSpaces(`{
    guid,
    name,
    description,
    richtext_description,
    tagged_items,
    data_type,
    data_types,
    technical_owner {
      object_type,
      obj
    },
    business_owner {
      object_type,
      obj
    },
    created_on,
    updated_on,
    breadcrumbs,
    represented_as,
    richtext_represented_as
  }`),
  sortColumn: 'name',
  sortDirection: 'ascending',
};

export const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  businessOwner: 'search_business_owner',
  description: 'search_description',
  name: 'search_name',
  tags: 'search_tags',
  technicalOwner: 'search_technical_owner',
};

export const sortConfig: { [key: string]: string } = {
  businessOwner: 'business_owner',
  createdAt: 'created_on',
  description: 'description',
  name: 'name',
  technicalOwner: 'technical_owner',
  updatedAt: 'updated_on',
};
