import React from 'react';

import { searchOptionsSortedByUsage } from '@components/ExploreTree/atoms';
import DownstreamChartsTab from '@components/TabContent/DownstreamChartsTab';
import UpstreamDownstreamTab from '@components/TabContent/UpstreamDownstreamTab';
import { TabContentProps } from '@components/Tabs/types';
import TabsRouter, { TabRoutesConfig } from '@components/TabsRouter';
import wrapString from '@utils/wrapString';

export interface ColumnLineageTabProps {
  assetName: string;
  downstreamChartsCount?: number;
  downstreamCount?: number;
  guid: string;
  nodeKey: string;
  upstreamCount?: number;
}

const ColumnLineageTab: React.FC<ColumnLineageTabProps & TabContentProps> = ({
  assetName,
  downstreamChartsCount,
  downstreamCount,
  guid,
  nodeKey,
  upstreamCount,
}) => {
  const tabsRouterConfig: TabRoutesConfig[] = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <UpstreamDownstreamTab
          assetName={assetName}
          customSearchOptions={searchOptionsSortedByUsage}
          direction="left"
          mode="column"
          nodeKey={nodeKey}
          showDescriptions={false}
          showUsage
          tableOrColumnId={guid}
        />
      ),
      default: true,
      label: `Upstream ${wrapString(upstreamCount)}`,
      route: '/upstream',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <UpstreamDownstreamTab
          assetName={assetName}
          customSearchOptions={searchOptionsSortedByUsage}
          direction="right"
          mode="column"
          nodeKey={nodeKey}
          showDescriptions={false}
          showUsage
          tableOrColumnId={guid}
        />
      ),
      label: `Downstream ${wrapString(downstreamCount)}`,
      route: '/downstream',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <DownstreamChartsTab guid={guid} />,
      contentOffset: 2,
      label: `Downstream Charts ${wrapString(downstreamChartsCount)}`,
      route: '/downstream-charts',
    },
  ];

  return <TabsRouter config={tabsRouterConfig} subTabs />;
};

export default ColumnLineageTab;
