import React from 'react';

import { useFetchActivity } from '@api/activity/activity';
import CircularLoader from '@components/CircularLoader';
import { ActivityItem } from '@components/RecentActivity/RecentActivity';
import Modal, { ModalContent, ModalHeader } from '@components/UI/Modal';
import theme from '@styles/theme';
import stripSpaces from '@utils/stripSpaces';

interface DocumentHistoryModalProps {
  id?: string;
  objectType?: string;
  onClose?: () => void;
  teams?: string;
}

const query = stripSpaces(`{
	guid,
  activity_type,
  generated_by,
  performed_on,
  message,
  user {
    guid,
    email,
    first_name,
    last_name,
    avatar,
    is_active,
    team {
      name
    }
  },
  target {
    object_type,
    obj {
      -breadcrumbs,
      -downstream_objects_counts,
      -popularity,
      -search_name,
      -tagged_items,
      -upstream_objects_counts
    }
  },
  cached_target_name,
  cached_target_data_type,
  comment,
  field,
  data
}`);

const activityParams = {
  generated_by: 'user',
  include_child_activities: false,
  order: '-performed_on',
  page_size: 100,
  query,
  types: 'create,update,add,delete,comment,tagged',
};

const PageHistoryModal: React.FC<DocumentHistoryModalProps> = ({
  id,
  objectType,
  onClose,
  teams,
}) => {
  const { data, isLoading } = useFetchActivity({
    enabled: Boolean(id || teams),
    params: {
      ...activityParams,
      generated_by: 'user,ingestion',
      include_child_activities: objectType === 'table',
      targets: id,
      teams,
    },
  });

  return (
    <Modal closeOnEscape onClose={onClose} open overflow="hidden" size="tiny" useContext>
      <ModalHeader onClose={onClose} title="History" />
      <ModalContent
        compDisplay="flex"
        compHeight={theme.space(44)}
        flexDirection="column"
        maxHeight="unset"
        py={2.5}
      >
        {isLoading && <CircularLoader cover />}
        {data?.results.map((activity) => (
          <ActivityItem
            key={activity.performedOn.toISOString()}
            activity={activity}
            timeFormat="datetime"
          />
        ))}
      </ModalContent>
    </Modal>
  );
};

export default PageHistoryModal;
