import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import { GridContainer } from '@components/Grid';
import TitleHeader from '@components/Title/TitleHeader';
import GithubConfigurationWizard from '@components/Wizard/GithubConfigurationWizard';
import MonteCarloConfigurationWizard from '@components/Wizard/MonteCarloConfigurationWizard';
import { useModal } from '@context/Modal';
import { useUserContext } from '@context/User';
import { ConnectableApps, useConnectableApps } from '@hooks/useConnectableApps';
import MetadataDecorator from '@utils/MetadataDecorator';

import { AdminAppCardProps } from './AdminAppCard';
import AdminAppsContainer from './AdminAppsContainer';

export const appsBaseConfiguration: Record<ConnectableApps, AdminAppCardProps> = {
  [ConnectableApps.slack]: {
    description: 'Send alerts and notification to Slack',
    icon: 'slack',
    name: 'Slack',
  },
  [ConnectableApps.github]: {
    description: 'Connect repo for metadata sync back',
    icon: 'github',
    name: 'GitHub dbt docs Sync',
  },
  [ConnectableApps.monteCarlo]: {
    description: 'Gain visibility into the quality of your data assets',
    icon: 'monte-carlo',
    name: 'Monte Carlo',
  },
};

const AdminAppsPage: React.FC = () => {
  const history = useHistory();

  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();

  const { organization } = useUserContext();

  const { connectedApps, isLoading, unconnectedApps } = useConnectableApps();

  const connectedAppsBaseConfiguration = useMemo(
    () => ({
      [ConnectableApps.slack]: {
        onClick: () => history.push('/admin/slack'),
        onConnect: () => history.push('/admin/slack'),
      },
      [ConnectableApps.github]: {
        onClick: () => history.push('/admin/github'),
        onConnect: () => history.push('/admin/github'),
      },
      [ConnectableApps.monteCarlo]: {
        onClick: () => history.push('/admin/monte-carlo'),
        onConnect: () => history.push('/admin/monte-carlo'),
      },
    }),
    [history],
  );

  const unconnectedAppsBaseConfiguration = useMemo(
    () => ({
      [ConnectableApps.slack]: {
        onConnect: () => history.push('/admin/slack'),
      },
      [ConnectableApps.github]: {
        onConnect: () => openModal(MODAL_IDS.connectGithub),
      },
      [ConnectableApps.monteCarlo]: {
        onConnect: () => openModal(MODAL_IDS.connectMonteCarlo),
      },
    }),
    [MODAL_IDS, history, openModal],
  );

  const connectedAppsConfiguration = useMemo(
    () =>
      connectedApps
        .map((app, id) => ({
          ...appsBaseConfiguration[app],
          ...connectedAppsBaseConfiguration[app],
          connected: true,
          id: `${id}`,
        }))
        .filter((app) =>
          organization?.settings?.useDataQualityTab ? true : app.name !== 'Monte Carlo',
        ),
    [connectedApps, connectedAppsBaseConfiguration, organization?.settings?.useDataQualityTab],
  );

  const unconnectedAppsConfiguration = useMemo(
    () =>
      unconnectedApps
        .map((app, id) => ({
          ...appsBaseConfiguration[app],
          ...unconnectedAppsBaseConfiguration[app],
          connected: false,
          id: `${id}`,
        }))
        .filter((app) =>
          organization?.settings?.useDataQualityTab ? true : app.name !== 'Monte Carlo',
        ),
    [organization?.settings?.useDataQualityTab, unconnectedApps, unconnectedAppsBaseConfiguration],
  );

  return (
    <>
      <MetadataDecorator title="Apps" />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <Box alignItems="start" position="relative">
          <Box mb={5}>
            <TitleHeader>
              <span className="title big">Apps</span>
            </TitleHeader>
          </Box>
          <Box compDisplay="flex" flex={1} flexDirection="column">
            {isLoading && <CircularLoader centered />}
            {!isLoading && connectedAppsConfiguration.length > 0 && (
              <AdminAppsContainer
                apps={connectedAppsConfiguration}
                data-testid="connected-tab"
                title="Connected"
              />
            )}
            {!isLoading && unconnectedAppsConfiguration.length > 0 && (
              <AdminAppsContainer
                apps={unconnectedAppsConfiguration}
                data-testid="not-connected-tab"
                title="Not Connected"
              />
            )}
          </Box>
        </Box>
      </GridContainer>
      {checkModalOpened(MODAL_IDS.connectGithub) && (
        <GithubConfigurationWizard onClose={() => closeModal(MODAL_IDS.connectGithub)} />
      )}
      {checkModalOpened(MODAL_IDS.connectMonteCarlo) && (
        <MonteCarloConfigurationWizard onClose={() => closeModal(MODAL_IDS.connectMonteCarlo)} />
      )}
    </>
  );
};

export default AdminAppsPage;
