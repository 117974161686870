import React from 'react';

import Box from '@components/Box';
import StatusBadge from '@components/StatusBadge';
import { StatusCountsType } from '@components/StatusBadge/StatusBadge';
import TabsRouter, { TabRoutesConfig } from '@components/TabsRouter';
import Icon from '@components/UI/Icon';

import MonitorsDbtTestsTable from './MonitorsDbtTestsTable';
import MonitorsTable from './MonitorsTable';

interface DataQualityTabContentProps {
  dbtModelGuid?: string;
  initialCounts: {
    dbt?: StatusCountsType;
    monteCarlo?: StatusCountsType;
  };
  tableGuid: string;
  tabs?: { dbt?: boolean; monteCarlo?: boolean };
}

const DataQualityTabContent: React.FC<DataQualityTabContentProps> = ({
  dbtModelGuid,
  initialCounts,
  tableGuid,
  tabs,
}) => {
  const tabsRouterConfig: TabRoutesConfig[] = [];

  if (tabs?.monteCarlo) {
    tabsRouterConfig.push({
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <MonitorsTable tableGuid={tableGuid} />,
      contentOffset: 2,
      default: true,
      label: (
        <Box alignItems="center" compDisplay="flex" gap={0.5}>
          <Icon name="monte-carlo" size="16px" />
          Monte Carlo
          <StatusBadge counts={initialCounts.monteCarlo} />
        </Box>
      ),
      path: '/monte-carlo/:itemId?',
      route: '/monte-carlo',
    });
  }

  if (tabs?.dbt) {
    tabsRouterConfig.push({
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <MonitorsDbtTestsTable tableGuid={dbtModelGuid ?? ''} />,
      contentOffset: 2,
      default: !tabs?.monteCarlo,
      label: (
        <Box alignItems="center" compDisplay="flex" gap={0.5}>
          <Icon name="dbt" size="16px" />
          dbt Test
          <StatusBadge counts={initialCounts.dbt} />
        </Box>
      ),
      route: '/dbt-test',
    });
  }

  return <TabsRouter config={tabsRouterConfig} sticky subTabs />;
};

export default DataQualityTabContent;
