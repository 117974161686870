export const all = ['tables'];

export const exportData = [...all, 'exportData'];

export const relatedTables = (id: string) => [...all, id, 'related'];

export const similarTables = (id: string) => [...all, id, 'similar'];

export const table = (id: string) => [...all, id];

export const tableFrequentBiDsUsers = (id: string) => [...all, id, 'frequentBiDsUsers'];

export const tableFrequentDsUsers = (id: string) => [...all, id, 'frequentDsUsers'];

export const tableRelatedDashboards = (id: string) => [...all, id, 'relatedDashboards'];

export const tableRelatedDashboardsCsv = (id: string) => [...all, id, 'relatedDashboards', 'csv'];

export const tableSnapshot = (id: string) => [...all, id, 'snapshot'];
