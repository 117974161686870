import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useFetchDocument, usePatchDocument, usePostDocument } from '@api/documents/documents';
import invalidateCache from '@api/invalidateCache';
import { useFetchMetadataComments } from '@api/metadata';
import { searchCacheKeys } from '@api/search';
import Breadcrumbs from '@components/Breadcrumbs';
import MetadataObjectTemplate from '@components/MetadataObjectTemplate';
import SidebarMetadataObjectDetails from '@components/SidebarMetadataObjectDetails';
import DiscussionTab from '@components/TabContent/DiscussionTab';
import OverviewTab from '@components/TabContent/OverviewTab';
import TabsRouter from '@components/TabsRouter';
import { renderInfoToast } from '@components/Toast';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import { useUserContext } from '@context/User';
import fetchClient from '@lib/fetchClient';
import { urlFor } from '@utils/routing';
import wrapString from '@utils/wrapString';

import { StyledEditableHeader } from './DocumentPage.styles';

export const UNTITLED_GUID = 'untitled';

const DocumentPage: React.FC = () => {
  const { guid } = useParams<{ guid: string }>();
  const isUntitledDoc = guid === UNTITLED_GUID;
  const history = useHistory();
  const { state } = useLocation() as unknown as { state: { newDoc: boolean } };
  const { hasEditPermissions, organization } = useUserContext();
  const { isEditable: isItemEditable } = useObjectPermissionsContext({
    id: isUntitledDoc ? undefined : guid,
  });
  const discussionsEnabled = organization?.settings?.useDiscussions;
  const [forceEditMode, setForceEditMode] = useState(false);
  const [blockNavigation, setBlockNavigation] = useState(true);
  const [documentName, setDocumentName] = useState('');

  const isEditable = isUntitledDoc ? Boolean(hasEditPermissions) : isItemEditable;

  const [documentDescription, setDocumentDescription] = useState<{
    plainText?: string;
    richText?: string;
  }>({
    plainText: '',
    richText: '',
  });
  const [untitledDocumentDescription, setUntitledDocumentDescription] = useState<{
    plainText?: string;
    richText?: string;
  }>({
    plainText: '',
    richText: '',
  });

  const { data, error, isError, isFetching, refetch } = useFetchDocument(guid, {
    enabled: Boolean(guid) && !isUntitledDoc,
    keepPreviousData: true,
    onSuccess: (d) => {
      setDocumentName(d.name);
      fetchClient.setQueryData(searchCacheKeys.searchItem(d.guid), d);
    },
  });

  const { data: commentsData } = useFetchMetadataComments(guid, { enabled: !isUntitledDoc });

  useEffect(() => {
    if (!isFetching) {
      if (isUntitledDoc) {
        setDocumentName('');
        setDocumentDescription({ plainText: '', richText: '' });
        setForceEditMode(true);
      } else {
        setDocumentName(data?.name || 'Untitled');
        setDocumentDescription({
          plainText: data?.description,
          richText: data?.richtextDescription,
        });
      }
    }
  }, [data?.description, data?.name, data?.richtextDescription, isFetching, isUntitledDoc]);

  useEffect(() => {
    if (!isFetching && !isUntitledDoc) {
      if (state?.newDoc && !data?.description) {
        setForceEditMode(true);
      } else {
        setForceEditMode(false);
      }
    }
  }, [data?.description, isFetching, isUntitledDoc, state?.newDoc]);

  const { mutate: createDocument } = usePostDocument({
    onSuccess: (createdDoc) => {
      history.push(urlFor(createdDoc), { newDoc: true });
      setBlockNavigation(true);
      setUntitledDocumentDescription({
        plainText: '',
        richText: '',
      });
    },
  });

  const {
    isError: isPatchMetadataError,
    isLoading: isPatchDocumentLoading,
    mutate: mutateDocument,
  } = usePatchDocument(guid, {
    onSuccess: () => {
      invalidateCache((keys) => [keys.metrics.all, keys.documents.all, keys.activity.all]);
      refetch();
      renderInfoToast('Edit saved.');
    },
  });

  const invalidateDocumentsQueries = () => {
    invalidateCache((keys) => [keys.documents.all]);
  };

  const handleNameSave = () => {
    if (documentName) {
      if (isUntitledDoc) {
        setBlockNavigation(false);
        setForceEditMode(!untitledDocumentDescription.plainText);
        createDocument({
          description: untitledDocumentDescription.plainText,
          name: documentName,
          richtext_description: untitledDocumentDescription.richText,
        });
      } else if (data?.name !== documentName) {
        mutateDocument({
          name: documentName ?? 'Untitled',
        });
      }
    }
  };

  const handleSaveDescription = (richtextDesc: string, plainTextDesc?: string) => {
    if (isUntitledDoc) {
      setForceEditMode(false);
      createDocument({
        description: plainTextDesc,
        name: 'Untitled',
        richtext_description: richtextDesc,
      });
    } else {
      mutateDocument({ description: plainTextDesc, richtext_description: richtextDesc });
    }
  };

  const handleDescriptionChange = (richtextDesc: string, plainTextDesc?: string) => {
    if (isUntitledDoc) {
      setUntitledDocumentDescription({
        plainText: plainTextDesc,
        richText: richtextDesc,
      });
    }
  };

  const tabsRouterConfig = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <OverviewTab
          blockNavigation={blockNavigation}
          canEditDescription={isEditable}
          description={documentDescription.plainText}
          descriptionHeading=""
          descriptionShouldStartFocused={!isUntitledDoc}
          descriptionVariantV1="block-simple"
          forceDescriptionEditModeOnStart={forceEditMode}
          guid={guid}
          isEditable={false}
          isError={isPatchMetadataError}
          isLoading={isFetching || isPatchDocumentLoading}
          onDescriptionChange={handleDescriptionChange}
          onDescriptionSave={handleSaveDescription}
          richtextDescription={documentDescription.richText}
          shouldFocusDescriptionOnEdit={!isUntitledDoc}
        >
          {!isUntitledDoc && (
            <SidebarMetadataObjectDetails
              businessOwner={data?.businessOwner === null ? null : data?.businessOwner?.obj}
              data={data}
              editOwnersItems={data ? [data] : undefined}
              guid={guid}
              isEditable={isEditable}
              itemsType="documents"
              lastUpdated={data?.updatedOn}
              onEdit={invalidateDocumentsQueries}
              reloadData={invalidateDocumentsQueries}
              showMentionedBy
              technicalOwner={data?.technicalOwner === null ? null : data?.technicalOwner?.obj}
            />
          )}
        </OverviewTab>
      ),
      default: true,
      label: 'Overview',
      path: '/overview',
      route: '/overview',
    },
    ...(discussionsEnabled && !isUntitledDoc
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => (
              <DiscussionTab
                businessOwner={data?.businessOwner?.obj}
                guid={guid}
                technicalOwner={data?.technicalOwner?.obj}
              />
            ),
            contentOffset: 2,
            label: `Discussion ${wrapString(commentsData?.length)}`,
            path: '/discussion/:itemId?',
            route: '/discussion',
          },
        ]
      : []),
  ];

  return (
    <MetadataObjectTemplate
      data={data}
      deleteAllowed={isEditable}
      guid={guid}
      headerProps={{
        icon: (
          <Tooltip content={data?.dataTypes?.tooltips.objectType}>
            <Icon name="document" size="30px" />
          </Tooltip>
        ),
        supIcon: <Icon color="#6f747c" name="all-docs" />,
        supTitle: <Breadcrumbs items={data?.breadcrumbList} />,
        title: (
          <StyledEditableHeader
            autoFocus={isUntitledDoc}
            disabled={!isEditable}
            onChange={setDocumentName}
            onSave={handleNameSave}
            placeholder="Untitled"
            value={documentName || ''}
          />
        ),
      }}
      hideTitleToolBox={isUntitledDoc}
      isAuthorized={!(error && error?.status === 403)}
      isError={isError || !guid}
      metaTitle={data?.name}
      objectName={data?.name || ''}
      objectType="documents"
      onDeleteSuccess={() => history.push('/docs/tabs')}
      onTagsEdit={() => refetch()}
      tagsEditAllowed={isEditable}
    >
      <TabsRouter config={tabsRouterConfig} sticky />
    </MetadataObjectTemplate>
  );
};

export default DocumentPage;
