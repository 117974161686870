import { SIZES } from '../../../components/Nodes/config';
import { ExploreNode } from '../../../Explore.types';
import { EXPLORE_NODE_PAGE_SIZE } from '../../../useShowMoreNodes/useShowMore.constants';
import createShowMore from '../../utils/createShowMore';
import {
  calcColumnInitialY,
  columnX,
  filterChildrenByQuery,
  showMoreTotalHeight,
  sortNodeKeysByPopularity,
  splitColumnsByUsage,
  tableX,
} from '../algorithm.utils';
import { EdgesById } from '../types';

import parseNode from './parseNode';
import { NodeParser } from './types';

const tableNodeParser: NodeParser = ({
  edgesById,
  initialPosition,
  isColumnLevelLineage,
  nodesById,
  rawNode,
  shouldHideFilterLineage,
}) => {
  const { isBITable } = rawNode.data ?? {};
  const style = {
    height: SIZES.height.table,
    width: isBITable ? SIZES.width.schema : SIZES.width.table,
  };

  const tableNode: ExploreNode = {
    ...rawNode,
    data: {
      ...rawNode?.data,
      hiddenChildren: new Set([]),
      hiddenChildrenCount: 0,
      noMatchedChildren: false,
      style,
    },
    position: {
      x: initialPosition?.x ?? tableX,
      y: initialPosition?.y as number,
    },
    style,
  };

  const tableChildrenNodes: ExploreNode[] = [];
  let extraEdgesById: EdgesById = {};

  const { children, isOpen, isSearchEnabled } = tableNode.data;

  if (isOpen) {
    if (isSearchEnabled) {
      tableNode.style!.height =
        Number(tableNode.style!.height) + SIZES.height.columnsSearchBar + SIZES.contentMargin.table;
    }
    const tableChildrenKeys = Array.from(children ?? []);
    const tableQuery = tableNode?.data?.childrenSearchText;
    let { shownChildrenCount } = tableNode.data;

    let tableChildren = [] as ExploreNode[];

    if (isColumnLevelLineage && shouldHideFilterLineage && !tableQuery) {
      const { hidden, shown } = splitColumnsByUsage({
        columnsKeys: Array.from(children ?? []),
        nodesById,
      });
      tableChildren = [...(shown as ExploreNode[]), ...(hidden as ExploreNode[])];
      shownChildrenCount = shownChildrenCount ?? Math.min(shown.length, EXPLORE_NODE_PAGE_SIZE);
    } else {
      shownChildrenCount = shownChildrenCount ?? EXPLORE_NODE_PAGE_SIZE;
      const filteredTableChildren = filterChildrenByQuery({
        childrenKeys: tableChildrenKeys,
        nodesById,
        query: tableQuery,
      });

      tableChildren = sortNodeKeysByPopularity(filteredTableChildren, nodesById).map(
        (childId) => nodesById[childId],
      );
    }

    if (tableChildren.length > 0) {
      tableNode.data.noMatchedChildren = false;
      const currentInitialPosition = {
        x: columnX,
        y: calcColumnInitialY(isSearchEnabled),
      };
      if (!isSearchEnabled) {
        tableNode.style!.height = Number(tableNode.style!.height) + SIZES.paddingBottom.table;
      }

      tableChildren.forEach((childNode, index) => {
        if (index < (shownChildrenCount ?? EXPLORE_NODE_PAGE_SIZE)) {
          const { node, nodeHeight } = parseNode({
            edgesById,
            initialPosition: currentInitialPosition,
            nodeIndex: index,
            nodesById,
            query: tableQuery,
            rawNode: childNode,
          });

          tableChildrenNodes.push(node);
          currentInitialPosition.y += nodeHeight + SIZES.rowGap.column;
          tableNode.style!.height = Number(tableNode.style!.height) + nodeHeight;
        } else {
          tableNode.data.hiddenChildrenCount = (tableNode.data.hiddenChildrenCount ?? 0) + 1;
          tableNode.data.hiddenChildren?.add(String(childNode.key));
        }
      });
    } else {
      tableNode.style!.height = Number(tableNode.style!.height) + SIZES.height.noMatchedDataBadge;
      tableNode.data.noMatchedChildren = true;
    }
  }

  if (tableNode.data.hiddenChildrenCount! >= 1) {
    const showMore = createShowMore({
      data: tableNode.data,
      edgesById,
      hiddenChildren: tableNode.data.hiddenChildren,
      hiddenChildrenCount: tableNode.data.hiddenChildrenCount,
      nodesById,
      parent: String(tableNode.key),
      position: {
        x: columnX,
        y: Number(tableNode.style!.height),
      },
      width: isBITable ? SIZES.width.biColumn : SIZES.width.column,
    });

    tableNode.style!.height = Number(tableNode.style!.height) + showMoreTotalHeight;
    tableChildrenNodes.push(showMore.node);
    extraEdgesById = { ...extraEdgesById, ...showMore.edges };
  }

  /*
   * We need to update the height and width of the node outside the style object
   * since react-flow uses these values to calculate the nodes overlaping and
   * remove the ones not visible on screen
   */
  tableNode.height = Number(tableNode.style?.height);
  tableNode.width = Number(tableNode.style?.width);

  return {
    children: tableChildrenNodes,
    extraEdgesById,
    node: tableNode,
    nodeHeight: Number(tableNode.style!.height),
  };
};

export default tableNodeParser;
