import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Box from '@components/Box';
import IconButton from '@components/IconButton';
import Skeleton from '@components/Skeleton';
import DropdownMenu from '@components/UI/DropdownMenu';
import type { IconProps } from '@components/UI/Icon';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';
import { FontSize } from '@styles/theme/typography';

import {
  StyledMetadataGrid,
  StyledMetadataGridCardDescription,
  StyledMetadataGridCardHeader,
  StyledMetadataGridCardIconWrapper,
  StyledMetadataGridCardName,
  StyledMetadataGridCardNameWrapper,
  StyledMetadataGridCardSecondaryIconWrapper,
} from './MetadataGrid.styles';

export interface MetadataGridCardProps {
  description?: string;
  dropdownMenuId?: string;
  footer?: React.ReactNode;
  icon?: IconProps['name'] | React.ReactNode;
  iconColor?: string;
  isEditable?: boolean;
  isLoading?: boolean;
  minHeight?: string;
  name?: React.ReactNode;
  nameFontSize?: FontSize;
  onDelete?: () => void;
  onEdit?: () => void;
  secondaryIcon?: IconProps['name'];
  secondaryName?: React.ReactNode;
  url?: string;
}

const MetadataGridCard: React.FC<MetadataGridCardProps> = ({
  description,
  dropdownMenuId = 'defaultId',
  footer,
  icon,
  iconColor,
  isEditable,
  isLoading,
  minHeight,
  name,
  nameFontSize,
  onDelete,
  onEdit,
  secondaryIcon,
  secondaryName,
  url = '',
}) => {
  if (isLoading) {
    return <Skeleton borderRadius="lg" compHeight="100px" gap={2} p={2} />;
  }

  const getIcon = () => {
    if (typeof icon === 'string') {
      return (
        <StyledMetadataGridCardIconWrapper>
          <Icon color={iconColor} name={icon as IconProps['name']} size="24px" />
          {secondaryIcon && (
            <StyledMetadataGridCardSecondaryIconWrapper>
              <Icon name={secondaryIcon} size="14px" />
            </StyledMetadataGridCardSecondaryIconWrapper>
          )}
        </StyledMetadataGridCardIconWrapper>
      );
    }

    return icon;
  };

  return (
    <StyledMetadataGrid as={RouterLink} boxShadow="sm" clickable minHeight={minHeight} to={url}>
      <Box>
        <StyledMetadataGridCardHeader>
          {getIcon()}
          <StyledMetadataGridCardNameWrapper>
            {secondaryName}
            {name && (
              <StyledMetadataGridCardName fontSize={nameFontSize}>
                {name}
              </StyledMetadataGridCardName>
            )}
          </StyledMetadataGridCardNameWrapper>
          {isEditable && (
            <DropdownMenu
              id={dropdownMenuId}
              options={[
                { label: 'Edit', onClick: onEdit },
                { label: 'Delete', onClick: onDelete },
              ]}
            >
              <IconButton
                iconColor={theme.colors.v1.gray[300]}
                iconName="settings"
                iconSize="18px"
                size="sm"
              />
            </DropdownMenu>
          )}
        </StyledMetadataGridCardHeader>
        {description && (
          <StyledMetadataGridCardDescription>{description}</StyledMetadataGridCardDescription>
        )}
      </Box>
      {footer}
    </StyledMetadataGrid>
  );
};

export default MetadataGridCard;
