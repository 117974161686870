import React from 'react';

import PageHeaderBar from '@components/PageHeaderBar';
import TabsRouter from '@components/TabsRouter';
import { Filter } from '@utils/index';
import MetadataDecorator from '@utils/MetadataDecorator';

import BaseDocsTab from './BaseDocsTab';
import { StyledGridContainer } from './DocumentsPage.styles';
import GlossaryTab from './GlossaryTab/GlossaryTab';

interface FullDocumentsPageProps {
  defaultConfig?: Filter.FilterOptions;
}

const DocumentsPage: React.FC<FullDocumentsPageProps> = () => {
  const tabsRouterConfig = () => {
    const tabs = [
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => <BaseDocsTab />,
        default: true,
        label: 'All',
        route: '/all',
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => <BaseDocsTab variant="document" />,
        label: 'Pages',
        route: '/documents',
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => <BaseDocsTab variant="metric" />,
        label: 'Metrics',
        route: '/metrics',
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => <GlossaryTab />,
        label: 'Glossary',
        route: '/glossary',
      },
    ];

    return tabs;
  };

  return (
    <>
      <MetadataDecorator title="All Docs" />
      <StyledGridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <PageHeaderBar title="All Docs" />
        <TabsRouter config={tabsRouterConfig()} sticky />
      </StyledGridContainer>
    </>
  );
};

export default DocumentsPage;
