import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { useFetchWorkspacesHierarchy } from '@api/workspaces';
import Box from '@components/Box';
import Breadcrumbs from '@components/Breadcrumbs';
import Link from '@components/Link/Link.styles';
import getUrl from '@components/Tree/getUrl';
import TreeV2 from '@components/Tree/Tree.v2';
import theme from '@styles/theme';

import HierarchyLoadingSkeleton from '../HierarchyLoadingSkeleton';

import FavoritesHierarchyItemIcons from './FavoritesHierarchyItemIcons';

const FavoritesHierarchy: React.FC = () => {
  const { data, isLoading } = useFetchWorkspacesHierarchy({
    params: {
      order: 'created_on',
    },
  });
  const { guid } = useParams<{ guid: string }>();

  if (isLoading) {
    return <HierarchyLoadingSkeleton />;
  }

  if (!data?.children || data?.children?.length === 0) {
    return <Box p={2}>No data.</Box>;
  }

  return (
    <Box
      compDisplay={isLoading ? 'none' : 'block'}
      compHeight="100%"
      overflowX="hidden"
      overflowY="auto"
    >
      <TreeV2
        allowCarrot={() => false}
        allowDataLoad={() => false}
        getKey={(item) => item.guid}
        highlightedKeys={[guid]}
        renderLabel={({ original }) => {
          const url =
            getUrl({
              dataType: original.dataType,
              dataTypes: original.dataTypes,
              dsGuid: original.dataSourceGuid,
              guid: original.guid,
              objectType: original.objectType,
              showSchemataPage: true,
              type: original.dataSourceType,
            }) ?? '#';

          return (
            <Link
              as={RouterLink}
              color="inherit"
              compDisplay="block"
              compWidth="100%"
              fontSize="inherit"
              to={url}
            >
              <Box alignItems="center" as="span" compDisplay="flex" gap={0.5} py={0.75}>
                <FavoritesHierarchyItemIcons
                  color={original.color}
                  dataSourceIcon={original.dataTypes?.icons.dataSource}
                  dataTypeIcon={original.dataTypes?.icons.dataType!}
                  guid={original.guid}
                  icon={original.icon}
                  name={original.name}
                  objectType={original.dataTypes?.objectType}
                />
                {original.breadcrumbLabelList ? (
                  <Breadcrumbs
                    clickable={false}
                    color="inherit"
                    fontSize={theme.typography.fontSizes.body1}
                    fontWeight="unset"
                    items={original.breadcrumbLabelList}
                    showTrailingSlash={false}
                  />
                ) : (
                  original.name
                )}
              </Box>
            </Link>
          );
        }}
        treeData={data?.children}
      />
    </Box>
  );
};

export default FavoritesHierarchy;
