import React from 'react';

import { usePostIncompleteDataSource } from '@api/dataSources';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import { DataSourceFormProps } from './types';

const RedshiftForm: React.FC<DataSourceFormProps> = ({
  children,
  dataSource,
  dataType,
  name = '',
  onSuccess,
  renderBefore,
}) => {
  const segment = useSegmentContext();
  const { error, isLoading, mutate } = usePostIncompleteDataSource({
    onSuccess,
  });
  const { handleChange, handleSubmit, values } = useForm({
    initialValues: {
      clusterIdentifier: '',
      database: '',
      name: dataSource?.name || name,
      region: '',
    },
    onSubmit: (val) => {
      mutate({
        credentials: {
          cluster_identifier: val.clusterIdentifier,
          database: val.database,
          region: val.region,
        },
        name: val.name,
        type: 'redshift',
      });
      segment?.track(SegmentTrackEventName.CreateServiceAccountConnectButtonClicked, { dataType });
    },
  });

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        {renderBefore?.({ error, loading: isLoading })}
        <StyledLabel>
          Display Name
          <Input
            error={error?.data?.name}
            helperText={error?.data?.name}
            maxLength={50}
            name="name"
            onChange={handleChange}
            placeholder="Redshift"
            type="text"
            value={values.name}
          />
        </StyledLabel>
        <StyledLabel>
          Cluster Name
          <Input
            error={error?.data?.cluster_identifier}
            helperText={error?.data?.cluster_identifier}
            name="clusterIdentifier"
            onChange={handleChange}
            placeholder="redshift-cluster-1"
            type="text"
            value={values.clusterIdentifier}
          />
        </StyledLabel>
        <StyledLabel>
          Database
          <Input
            error={error?.data?.database}
            helperText={error?.data?.database}
            name="database"
            onChange={handleChange}
            placeholder="Database"
            type="text"
            value={values.database}
          />
        </StyledLabel>
        <StyledLabel>
          Region
          <Input
            error={error?.data?.region}
            helperText={error?.data?.region}
            name="region"
            onChange={handleChange}
            placeholder="us-east-2"
            type="text"
            value={values.region}
          />
        </StyledLabel>
      </StyledFormHorizontalLabelGrid>
      {children?.({ error, loading: isLoading })}
    </Form>
  );
};

export default RedshiftForm;
