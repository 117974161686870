import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { DsUserModel } from '@api/dsusers/DsUserModel';
import Box from '@components/Box';
import OwnerItem from '@components/Owners/OwnerItem';
import Avatar from '@components/UI/Avatar';
import { urlFor } from '@utils/routing';

import ConnectedAccountsStyled from './ConnectedAccountsStyled';

export interface Props {
  dsUserPage?: boolean;
  users: DsUserModel[];
}

const ConnectedAccounts: React.FC<Props> = ({ dsUserPage = false, users }) => {
  if (!users.length) return null;

  if (dsUserPage) {
    const { user } = users[0];

    if (!user) return null;

    return (
      <ConnectedAccountsStyled>
        <span className="heading">Select Star Account</span>
        <span className="user-item">
          <OwnerItem avatarMargin={0.5} labelMargin={0.5} owner={user} showTags />
        </span>
      </ConnectedAccountsStyled>
    );
  }

  return (
    <ConnectedAccountsStyled>
      <span className="heading">Connected Accounts</span>
      {users.map((user) => {
        const url = urlFor(user, true);
        return (
          <RouterLink key={user.guid} className="user-item" to={url}>
            <Avatar {...user.mappedAvatar} mr={0.5} size="20px" />
            <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {user.name}
            </Box>
          </RouterLink>
        );
      })}
    </ConnectedAccountsStyled>
  );
};

export default ConnectedAccounts;
