import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';

/**
 * Todo: These should be renamed to slack/subscriber endpoint to follow conventions.
 */
export const useFetchSlackSubscribers = (options?: UseFetchOptions) => {
  return useFetch({
    ...options,
    url: `/subscribers/slack/`,
  });
};

export const useDeleteSlackSubscriber = (options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    url: `/subscribers/slack/`,
  });
};

export const usePostSlackSubscriber = (options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'POST',
    url: `/subscribers/slack/`,
  });
};
