import React from 'react';
import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { OrganizationData, OrganizationModel } from '@api/organizations/OrganizationModel';
import { TeamModel } from '@api/teams/TeamModel';
import { ObjectType } from '@api/types';
import { UserModel } from '@api/user/UserModel';
import { MetadataObjectType } from '@atoms';
import { breadcrumbsToList } from '@components/Breadcrumbs';
import getUrl from '@components/Tree/getUrl';
import Icon from '@components/UI/Icon';
import DataTypesModel from '@models/DataTypesModel';
import { MetadataData, MetadataModel } from '@models/MetadataModel';
import { PaginatedResponse } from '@models/PaginatedResponse';
import theme from '@styles/theme';

export interface TagData {
  color?: string;
  count?: number;
  description?: string;
  guid: string;
  icon: string;
  items?: MetadataData[];
  name: string;
  organization: OrganizationData;
  owner?: UserModel;
  richtextDescription?: string;
  tagColor: string;
  tagIcon?: string;
  type: string;
  updatedAt: moment.Moment;
}

export const allTagsBreadcrumb = {
  label: 'All Tags',
  url: '/tags',
};

export type TagType = 'status' | 'category' | 'dbt';

export class TagModel {
  static objectType: ObjectType = 'tag';

  objectType: ObjectType = TagModel.objectType;

  objectTypeV1: MetadataObjectType = 'tags';

  static typeDisplay: string = 'Tag';

  typeDisplay: string = TagModel.typeDisplay;

  guid!: string;

  name!: string;

  /*
   * fullName here is strictly for type compatibility.
   * Added first for PBAC Except For Tags section
   */
  get fullName() {
    return this.name;
  }

  description?: string;

  suggestedDescriptionSource?: undefined;

  suggestedDescriptionSourceObject?: undefined;

  itemsType = 'tags' as const;

  color?: string;

  type!: TagType;

  visible?: boolean = true;

  @Expose({ name: 'data_source_guid' })
  dataSourceGuid!: string;

  @Expose({ name: 'links_to' })
  linksTo?: string;

  @Expose({ name: 'linked_from' })
  linkedFrom?: string;

  @Expose({ name: 'data_types' })
  @Transform((value) => {
    if (!value) {
      return plainToClass(DataTypesModel, {
        data_source_type: 'tag',
        object_type: 'tag',
      });
    }
    return plainToClass(DataTypesModel, value);
  })
  dataTypes?: DataTypesModel;

  get tagColor() {
    /*
     * only need specific colour for category tags.
     * status tags will have a single colour
     */
    if (this.type === 'status' && this.color) {
      return this.color;
    }
    return '#2c2e36';
  }

  icon: 'tag' = 'tag';

  get lastUpdated() {
    return this.updatedAt;
  }

  @Expose({ name: 'richtext_description' })
  richtextDescription?: string;

  @Expose({ name: 'updated_on' })
  @Transform((value) => moment(value))
  updatedAt!: moment.Moment;

  @Type(() => OrganizationModel)
  organization?: OrganizationModel;

  @Type(() => UserModel)
  @Transform((value) => {
    if (!value) {
      return undefined;
    }
    if (value.object_type === 'team') {
      return plainToClass(TeamModel, value?.obj);
    }
    return plainToClass(UserModel, value?.obj);
  })
  owner?: UserModel;

  @Type(() => MetadataModel)
  items?: MetadataData[];

  count?: number;

  get supIcon() {
    return {
      description: '',
      icon: <Icon color={theme.colors.gray[500]} name="tag" />,
    };
  }

  @Type(() => BreadcrumbModel)
  breadcrumbs?: BreadcrumbModel[];

  get breadcrumbList() {
    const breadcrumbsList = breadcrumbsToList(this.breadcrumbs);
    breadcrumbsList?.unshift(allTagsBreadcrumb);
    return breadcrumbsList;
  }

  get breadcrumbLabelList() {
    const breadcrumbLabelList = breadcrumbsToList(this.breadcrumbs);
    breadcrumbLabelList?.push({
      label: this.name,
      url: getUrl({
        defaultUrl: '',
        guid: this.guid,
        objectType: this.objectType,
        showSchemataPage: true,
        type: this.dataSourceGuid,
      }),
    });
    return breadcrumbLabelList;
  }

  technicalOwner = undefined;

  businessOwner = undefined;

  externalUrl = undefined;

  dataSource = undefined;

  dataSourceType = undefined;

  dataType = undefined;

  showTableLastQuery = undefined;

  suggestedDescription = undefined;

  bytes = undefined;

  connections = undefined;

  created = undefined;

  lastRefreshed = undefined;

  lastRun = undefined;

  loadingStatus = undefined;

  materialization = undefined;

  popularity = undefined;

  rowCount = undefined;

  showLinksTo = true;

  linksToObjects = undefined;

  showLinkedFrom = true;

  linkedFromObjects = undefined;

  showMentionedBy = false;

  supTitle = undefined;

  showEditTags = false;

  showCustomAttributes = false;

  cloudObject = undefined;

  rawSql = undefined;

  showDescriptionSelector = false;

  descriptionSource = undefined;

  @Expose({ name: 'ai_description' })
  aiDescription?: string;

  @Expose({ name: 'ingested_description' })
  ingestedDescription?: string;

  @Expose({ name: 'user_description' })
  userDescription?: string;

  editNameAllowed = true;
}

export type TagCounts = { [index: string]: number };

export interface TaggedItemsUpdateData {
  items: string[];
  tags: string[];
}

/* @deprecated */
export interface TaggedItemsPaginatedResponse<T> extends PaginatedResponse<T> {
  object_type_counts: { [type: string]: number };
}
