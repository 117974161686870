import { Expose } from 'class-transformer';

export interface UserSettingsUpdateData {
  enableEmailNotifications?: boolean;
  firstName?: string;
  lastName?: string;
  newPassword?: string;
  newsletterSubscribed?: boolean;
  password?: string;
}

export interface UserSettingsData {
  enableEmailNotifications?: boolean;
  newsletterSubscribed?: boolean;
}

export type DefaultDatabaseTableColumnOrderOptions =
  | 'popularity'
  | 'column_ordinal_position'
  | 'alphabetical';
export type DefaultTableDashboardTabOptions = 'overview' | 'columns_charts';

export class UserSettingsModel {
  @Expose({ name: 'enable_email_notifications' })
  enableEmailNotifications?: boolean;

  @Expose({ name: 'enable_slack_notifications' })
  enableSlackNotifications?: boolean;

  @Expose({ name: 'enable_slack_notifications_for_schema_changes' })
  enableSlackNotificationsForSchemaChanges?: boolean;

  @Expose({ name: 'enable_slack_notifications_for_metadata_changes' })
  enableSlackNotificationsForMetadataChanges?: boolean;

  @Expose({ name: 'default_database_table_column_order' })
  defaultDatabaseTableColumnOrder?: DefaultDatabaseTableColumnOrderOptions;

  @Expose({ name: 'default_table_dashboard_tab' })
  defaultTableDashboardTab?: DefaultTableDashboardTabOptions;

  @Expose({ name: 'enable_new_lineage' })
  enableNewLineage?: boolean;
}
