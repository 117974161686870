import React from 'react';
import { HighlighterProps } from 'react-highlight-words';

import type { StyledHighlighterProps } from './Highlighter.styles';
import { StyledHighlighter } from './Highlighter.styles';

const Highlighter: React.FC<HighlighterProps & StyledHighlighterProps> = (props) => {
  return <StyledHighlighter autoEscape {...props} />;
};

export default Highlighter;
