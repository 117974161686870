import styled from '@emotion/styled';

const levelStyleMap: { [key: string]: string } = {
  0: 'decimal',
  1: 'lower-alpha',
  2: 'lower-roman',
  3: 'decimal',
  4: 'lower-alpha',
};

export const OLElement = styled.ol<{ level?: number }>`
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  list-style: ${({ level }) => (level ? levelStyleMap[level] : 'decimal')};

  li {
    margin-left: ${({ level, theme }) => theme.space(((level ?? 0) + 1) * 2.65)};
    padding-left: ${({ theme }) => theme.space(0.35)};
  }
`;
