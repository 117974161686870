import React, { useCallback, useState } from 'react';
import { TreeMenuItem } from 'react-simple-tree-menu';

import { FetchLineageParams, useFetchLineage } from '@api/lineage';
import Box from '@components/Box';
import { LineageInteractions } from '@components/Explore.v1/Explore.types';
import { useExplore } from '@components/Explore.v1/useExplore';
import useMergeLineageData from '@components/Explore/useMergeLineageData';
import ExploreSidebar from '@components/ExploreSidebar';
import Skeleton from '@components/Skeleton';
import flags from '@features';
import theme from '@styles/theme';

const LineageTreeTab = () => {
  const { selectedNode, setNextUserInteraction, stackData } = useExplore();
  const [currentNodeId, setCurrentNodeId] = useState(selectedNode?.guid);
  const type =
    selectedNode?.key && stackData?.nodesById?.[selectedNode?.key]?.type === 'column'
      ? 'column'
      : 'table';

  const [requestParams, setRequestParams] = useState<FetchLineageParams>({
    dbt_links: true,
    group_by_data_source: true,
    include_borderline_edges: true,
    looker_view_lineage: flags.looker_view_lineage,
    mode: type,
    mode_lineage: true,
  });

  const { data: selectedNodeData, isLoading: isSelectedNodeDataLoading } = useFetchLineage(
    selectedNode?.guid ?? '',
    {
      enabled: Boolean(selectedNode?.guid === currentNodeId),
      params: {
        ...requestParams,
      },
    },
  );

  const { data: expandedNodeData } = useFetchLineage(currentNodeId ?? '', {
    enabled: Boolean(currentNodeId),
    params: {
      ...requestParams,
      mode: 'all',
    },
  });

  const lineageDataMerged = useMergeLineageData({
    columns: [...(selectedNodeData?.columns ?? []), ...(expandedNodeData?.columns ?? [])],
    tables: [...(selectedNodeData?.tables ?? []), ...(expandedNodeData?.tables ?? [])],
  });

  const handleLineageRepositioning = (item: TreeMenuItem) => {
    setNextUserInteraction({
      payload: {
        nodeKey: item.id,
      },
      type: LineageInteractions.FocusOnNode,
    });
  };

  const loadLineage = useCallback(
    (id: string, direction?: FetchLineageParams['direction']) => {
      setCurrentNodeId(id);
      setRequestParams((prevReq) => {
        const newRequest = { ...prevReq };
        delete newRequest.direction;

        if (direction) {
          newRequest.direction = direction;
        }

        return newRequest;
      });
    },
    [setRequestParams, setCurrentNodeId],
  );

  const upstreamCount = selectedNode?.metadata?.upstreamObjectsCounts?.total;
  const downstreamCount = selectedNode?.metadata?.downstreamObjectsCounts?.total;

  if (isSelectedNodeDataLoading) {
    return (
      <Box compDisplay="flex" compWidth="100%" flexDirection="column" gap={1} p={1}>
        <Skeleton compHeight={theme.space(3)} compWidth="100%" grayShade={200} variant="text" />
        <Skeleton compHeight={theme.space(4)} compWidth="100%" grayShade={200} variant="text" />
        <Skeleton compHeight={theme.space(3)} compWidth="60%" grayShade={200} variant="text" />
      </Box>
    );
  }

  return (
    <ExploreSidebar
      columns={lineageDataMerged?.columns ?? []}
      downstreamCount={downstreamCount}
      loadLineage={loadLineage}
      nodeKey={selectedNode?.key}
      onItemClick={handleLineageRepositioning}
      showInfo={false}
      showLineageTree={lineageDataMerged.tables.length > 0}
      tables={lineageDataMerged?.tables ?? []}
      type={type}
      upstreamCount={upstreamCount}
      zoomOnItemClick={false} // this is used by the old lineage, we may remove it on the future
    />
  );
};

export default LineageTreeTab;
