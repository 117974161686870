import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';

import { useFetchSSOAuthURL } from '@api/sso';
import Box from '@components/Box';
import Button from '@components/Button/Button';
import { GridContainer } from '@components/Grid';
import ChangePasswordModal from '@components/Modal/ChangePasswordModal';
import ChangeProfilePictureModal from '@components/Modal/ChangeProfilePictureModal';
import ClientApiModal from '@components/Modal/ClientApiModal';
import TitleHeader from '@components/Title/TitleHeader';
import Avatar from '@components/UI/Avatar';
import { useModal } from '@context/Modal';
import { useUserContext } from '@context/User';
import { Section } from '@pages/AccountSettingsPage/AccountSettingsPage.styles';
import ConnectedDSUsers from '@pages/AccountSettingsPage/ConnectedDSUsers';
import Profile from '@pages/AccountSettingsPage/Profile';
import MetadataDecorator from '@utils/MetadataDecorator';

const AccountSettingsPage: React.FC = () => {
  const { user } = useUserContext();
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();

  const { data, isLoading } = useFetchSSOAuthURL({
    enabled: Boolean(user?.email),
    params: { email: user?.email },
    retry: false,
  });

  return (
    <>
      <MetadataDecorator title="Account Settings" />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <Box compDisplay="flex" flexDirection="column">
          <TitleHeader className="title big">
            <span className="title big">Account Settings</span>
          </TitleHeader>
          {user && (
            <>
              <Section>Profile Picture</Section>
              <Section>
                <Avatar
                  {...user.mappedAvatar}
                  as={RouterNavLink}
                  mr={2}
                  size="100px"
                  to={`/profiles/${user.guid}`}
                />
                <Box>
                  <Button
                    onClick={() => openModal(MODAL_IDS.changeProfilePicture)}
                    variant="outlined"
                  >
                    Change Profile Picture
                  </Button>
                </Box>
              </Section>
              <Profile
                openChangePasswordModal={() => openModal(MODAL_IDS.changePassword)}
                openClientApiModal={() => openModal(MODAL_IDS.clientApi)}
                ssoFound={!isLoading && Boolean(data)}
              />
              <ConnectedDSUsers guid={user.guid} />
            </>
          )}
          {checkModalOpened(MODAL_IDS.changeProfilePicture) && (
            <ChangeProfilePictureModal onClose={() => closeModal(MODAL_IDS.changeProfilePicture)} />
          )}
          {checkModalOpened(MODAL_IDS.changePassword) && (
            <ChangePasswordModal onClose={() => closeModal(MODAL_IDS.changePassword)} />
          )}
          {checkModalOpened(MODAL_IDS.clientApi) && (
            <ClientApiModal onClose={() => closeModal(MODAL_IDS.clientApi)} />
          )}
        </Box>
      </GridContainer>
    </>
  );
};

export default AccountSettingsPage;
