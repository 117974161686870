import styled from '@emotion/styled';

import { SearchResultContainer } from '@components/SearchBar/SearchResult/SearchResult.styles';

export const MentionSuggestion = styled(SearchResultContainer)<{ active?: boolean }>`
  border-bottom: 1px solid #e2e9f0;
  background-color: ${({ active }) => (active ? '#f9fafb' : 'unset')};
  padding: ${({ theme }) => theme.space(0.75, 1.5, 0.75)};
  max-height: 70px;
  display: flex;
  flex-direction: column;
  gap: 0.05rem;
  cursor: pointer;
`;

export const MentionSuggestions = styled.div`
  display: flex;
  flex-direction: column;
  z-index: ${({ theme }) => theme.zIndex.floatingElement};
  background: white;
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  border-radius: ${({ theme }) => theme.radius.default};
  box-shadow: 10px 10px 10px 3px rgba(111, 116, 124, 0.07);
  height: auto;
  max-width: 40rem;
  min-width: 40rem;
  transition: all 0.065s cubic-bezier(0.2, 0.75, 0.1, 0.5) 0s;
  position: relative;
  overflow: hidden;
`;

export const SearchTabsScrollContainer = styled.div`
  flex-shrink: 0;
  height: 36px;
  overflow-x: auto;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &::before {
    content: '';
    width: 100px;
    height: 35px;
    position: absolute;
    right: 0;
    top: 0;
    background: linear-gradient(to right, transparent, white);
    pointer-events: none;
  }
`;
