import { TeamModel } from '@api/teams/TeamModel';
import { UserModel } from '@api/user/UserModel';
import { UserListModel } from '@api/userLists/UserListModel';
import { IconProps } from '@components/UI/Icon';
import { Option } from '@components/UI/Select.v1/types';
import { Reference } from '@models/Reference';

const mapRecipientsToOptions = (
  recipients: Reference<UserModel | TeamModel | UserListModel>[],
): Option[] => {
  const recipientObjects = recipients.map((ref) => ref.obj) as Array<
    UserModel | TeamModel | UserListModel
  >;
  return recipientObjects.map((recipient) => {
    if (recipient instanceof UserListModel || recipient.objectType === 'userlist') {
      return {
        icon: 'team' as IconProps['name'],
        text: (recipient as UserListModel).name,
        value: recipient.guid,
      };
    }

    return {
      avatar: {
        guid: recipient.guid,
        isActive: (recipient as UserModel)?.isActive,
        isDeleted: false,
        name: recipient.fullName,
        src: recipient.avatar,
      },
      teamName: (recipient as UserModel)?.team?.name,
      text: recipient.fullName,
      value: recipient.guid,
    };
  });
};

export default mapRecipientsToOptions;
