import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const LegacyDocumentsPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  /*
   * We keep this legacy page because some users may have the /docs page saved,
   * and we don't want to show an error page in that case.
   */
  useEffect(() => {
    if (location.pathname === '/docs' || location.pathname === '/docs/') {
      history.push('/docs/tabs');
    }
  }, [history, location.pathname]);

  return null;
};

export default LegacyDocumentsPage;
