import { useCallback } from 'react';

import { InputNodesById } from '@components/Explore.v1/Explore.types';
import parseLineageNodes from '@components/Explore.v1/useCreateNodesEdges/algorithm/parseLineageNodes';
import createStacks from '@components/Explore.v1/useCreateNodesEdges/utils/createStacks';
import { useExplore } from '@components/Explore.v1/useExplore';
import useGetConfigQueryParams from '@components/Explore.v1/useGetConfigQueryParams';
import useUserTracking from '@components/Explore.v1/useUserTracking';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import { mergeTableAndColumnLineage } from '../Explore.constants.utils';
import { cloneLineageData } from '../Explore.v1.utils';
import { getTopLevelParentNode } from '../useCreateNodesEdges/algorithm/algorithm.utils';
import { CreateStacksResult } from '../useCreateNodesEdges/algorithm/types';
import { LineageModeChangeTrigger } from '../useExplore/Explore.context.types';
import useShowLoading from '../useShowLoading';
import { useUndoRedo } from '../useUndoRedo';

import {
  createColumnLevelInput,
  mergeRelevantColumnLineageState,
} from './useColumnLevelLineage.utils';

interface UseColumnLevelLineageParams {
  showLoading?: boolean;
}

const useColumnLevelLineage = (params?: UseColumnLevelLineageParams) => {
  const { showLoading = true } = params ?? {};
  const { track } = useUserTracking();
  const {
    biggestConflictEndPerStack,
    disableColumnLevelLineage,
    edgesById,
    enableColumnLevelLineage,
    initialPosition: currInitialPosition,
    inputNodesById,
    isCollapseAllButtonEnabled,
    isColumnLevelLineage,
    nodesById,
    nodesByStack,
    previousTableLineageState,
    setInputNodesById,
    setIsColumnLevelLoading,
    setLoadingState,
    setNodesById,
    setStacksData,
    stackData,
  } = useExplore();
  const { enableHorizontalGroups, shouldHideFilterLineage } = useGetConfigQueryParams();
  const { saveExploreState } = useUndoRedo();
  const { withLoading } = useShowLoading();

  const startColumnLevelLineage = ({
    nodeKey,
    selectedNodesById,
    trigger,
    updatePreviousLineageState,
  }: {
    nodeKey: string;
    selectedNodesById?: InputNodesById;
    trigger: LineageModeChangeTrigger;
    updatePreviousLineageState?: boolean;
  }) => {
    const parentKey = selectedNodesById?.[nodeKey]?.metadata?.parent_guid;
    if (parentKey && selectedNodesById) {
      const topLevelParentNode = getTopLevelParentNode(nodeKey, nodesById);
      const startTime = Date.now();

      const columnLevelInput = createColumnLevelInput({
        columnId: nodeKey,
        inputNodesById: cloneLineageData(selectedNodesById),
        parentKey,
      });

      const columnLevelStacks = createStacks({
        inputNodesById: columnLevelInput,
        options: {
          enableColumnEdges: true,
          enableHorizontalGroups,
          enableTableEdges: false,
          openAll: true,
          shouldHideFilterLineage,
        },
        startingColumnGuid: nodeKey,
        startingTableId: selectedNodesById[nodeKey]?.metadata?.parent_guid ?? '',
      });

      const initialPosition = {
        x: topLevelParentNode?.position.x ?? 0,
        y: topLevelParentNode?.position.y ?? 0,
      };

      columnLevelStacks.nodesById[parentKey].data.isOpen = true;
      columnLevelStacks.nodesById[parentKey].data.childrenSearchText =
        stackData!.nodesById[parentKey].data.childrenSearchText;
      columnLevelStacks.nodesById[parentKey].data.shownChildrenCount = Math.max(
        columnLevelStacks?.nodesById?.[parentKey]?.data?.shownChildrenCount ?? 0,
        stackData?.nodesById?.[parentKey]?.data?.shownChildrenCount ?? 0,
      );

      const mergedNodesById = mergeRelevantColumnLineageState({
        newNodes: columnLevelStacks.nodesById,
        previousNodes: stackData?.nodesById ?? {},
      });

      const result = parseLineageNodes({
        edgesById: columnLevelStacks?.edgesById ?? {},
        initialPosition,
        isColumnLevelLineage: true,
        nodesById: mergedNodesById,
        shouldHideFilterLineage,
        stackGroups: columnLevelStacks?.stackGroups,
      });

      enableColumnLevelLineage(
        {
          biggestConflictEndPerStack: result.biggestConflictEndPerStack,
          edgesById: result.edgesById,
          initialPosition,
          inputNodesById: columnLevelInput,
          nodesById: result.nodesById,
          nodesByStack: result.nodesByStack,
          rootColumnKey: nodeKey,
          stackData: columnLevelStacks,
        },
        updatePreviousLineageState,
      );

      saveExploreState({
        biggestConflictEndPerStack: result.biggestConflictEndPerStack,
        edgesById: result.edgesById,
        initialPosition,
        inputNodesById: columnLevelInput,
        isCollapseAllButtonEnabled,
        isColumnLevelLineage: true,
        nodesById: result.nodesById,
        nodesByStack: result.nodesByStack,
        stackData: columnLevelStacks,
      });

      const endTime = Date.now();
      track(SegmentTrackEventName.LineageColumnLevelLoaded, {
        duration: endTime - startTime,
        key: nodeKey,
        trigger,
      });
    }
  };

  const toggleColumnLevelLineage = (
    nodeKey: string,
    trigger: LineageModeChangeTrigger = LineageModeChangeTrigger.Click,
  ) => {
    if (!isColumnLevelLineage) {
      startColumnLevelLineage({
        nodeKey,
        selectedNodesById: inputNodesById,
        trigger,
      });
    } else {
      const clickedColumn = stackData?.nodesById[nodeKey];

      if (clickedColumn?.data?.isSelected) {
        disableColumnLevelLineage();
        track(SegmentTrackEventName.LineageTableLevelLoaded, {
          trigger,
        });

        saveExploreState({
          ...previousTableLineageState,
          inputNodesById: previousTableLineageState?.inputNodesById ?? {},
          isCollapseAllButtonEnabled,
          isColumnLevelLineage: false,
          stackData: previousTableLineageState?.stackData ?? {},
        });
      } else {
        startColumnLevelLineage({
          nodeKey,
          selectedNodesById: previousTableLineageState.inputNodesById,
          trigger,
          updatePreviousLineageState: false,
        });
      }
    }
  };

  const createColumnNodes = useCallback(
    ({
      columnInputNodesById,
      fitView = true,
      removeEdgesWithNoUsages,
      stacksData,
      startingTableId,
    }: {
      columnInputNodesById: InputNodesById;
      fitView?: boolean;
      removeEdgesWithNoUsages?: boolean;
      stacksData: CreateStacksResult;
      startingTableId: string;
    }) => {
      const mergedNodesById = mergeTableAndColumnLineage({
        columnInputNodesById,
        enableHorizontalGroups,
        removeEdgesWithNoUsages,
        startingTableId,
        tableNodesById: stacksData?.nodesById ?? {},
      });

      const newStacksData = {
        ...stacksData,
        edgesById: stacksData?.edgesById ?? {},
        nodesById: mergedNodesById,
        stackGroups: stacksData?.stackGroups ?? {},
        stackedAt: stacksData?.stackedAt ?? {},
        startingTableId: stacksData?.startingTableId ?? '',
      };

      setStacksData(newStacksData, { fitView });

      const newInputNodesById = {
        ...inputNodesById,
        ...columnInputNodesById,
      };

      setInputNodesById(newInputNodesById);

      const newNodesById = { ...nodesById };

      const inputColumnsKeys = Object.keys(columnInputNodesById);
      for (let i = 0; i < inputColumnsKeys.length; i += 1) {
        const columnKey = inputColumnsKeys[i];
        if (newNodesById[columnKey]) {
          newNodesById[columnKey].data.edgesLoaded = true;
        }
      }

      setNodesById(newNodesById);
      saveExploreState({
        biggestConflictEndPerStack,
        edgesById,
        initialPosition: currInitialPosition,
        inputNodesById: newInputNodesById ?? {},
        isCollapseAllButtonEnabled,
        isColumnLevelLineage: false,
        nodesById: newNodesById,
        nodesByStack,
        stackData: newStacksData,
      });
      setIsColumnLevelLoading(false);
      setLoadingState({ enabled: false, type: 'column-level' });
    },
    [
      enableHorizontalGroups,
      setNodesById,
      setStacksData,
      isCollapseAllButtonEnabled,
      inputNodesById,
      setInputNodesById,
      saveExploreState,
      biggestConflictEndPerStack,
      edgesById,
      currInitialPosition,
      nodesById,
      nodesByStack,
      setIsColumnLevelLoading,
      setLoadingState,
    ],
  );

  return {
    createColumnNodes,
    toggleColumnLevelLineage: showLoading
      ? withLoading(toggleColumnLevelLineage)
      : toggleColumnLevelLineage,
  };
};

export default useColumnLevelLineage;
