import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import { PaginatedResponse } from '@models/PaginatedResponse';

import paginatedTransform from '../paginatedTransform';

import { CustomAttributeModel } from './CustomAttributeModel';

export const customAttributesCacheKeys = {
  customAttribute: (id: string) => ['customAttributes', id],
  customAttributes: ['customAttributes'],
};

const selectCustomAttribute = paginatedTransform(CustomAttributeModel);
export const useFetchCustomAttribute = (
  id: string,
  options?: UseFetchOptions<CustomAttributeModel>,
) => {
  return useFetch<CustomAttributeModel>({
    ...options,
    queryKey: customAttributesCacheKeys.customAttribute(id),
    select: selectCustomAttribute,
    url: `/custom-attributes/`,
  });
};

const selectCustomAttributes = paginatedTransform(CustomAttributeModel);
export const useFetchCustomAttributes = (
  options?: UseFetchOptions<PaginatedResponse<CustomAttributeModel>>,
) => {
  return useFetch<PaginatedResponse<CustomAttributeModel>>({
    ...options,
    queryKey: [...customAttributesCacheKeys.customAttributes, options?.params],
    select: selectCustomAttributes,
    url: `/custom-attributes/`,
  });
};

export const usePostCustomAttribute = (options?: UseMutationOptions<CustomAttributeModel>) => {
  return useMutation<CustomAttributeModel>({
    ...options,
    method: 'POST',
    onSuccess: options?.onSuccess,
    url: '/custom-attributes/',
  });
};

export const usePatchCustomAttribute = (
  id: string,
  options?: UseMutationOptions<CustomAttributeModel>,
) => {
  return useMutation<CustomAttributeModel>({
    ...options,
    method: 'PATCH',
    url: `/custom-attributes/${id}/`,
  });
};

export const useDeleteCustomAttribute = (
  id: string,
  options?: UseMutationOptions<CustomAttributeModel>,
) => {
  return useMutation<CustomAttributeModel>({
    ...options,
    method: 'DELETE',
    url: `/custom-attributes/${id}/`,
  });
};
