import styled from '@emotion/styled';

import Button from '@components/Button/Button';
import theme from '@styles/theme';

export interface StyledButtonProps {
  state: 'collapsed' | 'expanded';
}

export const colors = {
  collapsed: {
    default: {
      background: 'white',
      border: theme.colors.v1.gray[300],
      color: theme.colors.v1.gray[500],
      icon: theme.colors.v1.gray[500],
    },
    hover: {
      background: '#F2F4F7',
      border: theme.colors.v1.gray[300],
      color: theme.colors.v1.gray[500],
      icon: theme.colors.v1.gray[500],
    },
  },
  expanded: {
    default: {
      background: theme.colors.v1.primary[50],
      border: theme.colors.v1.primary[300],
      color: theme.colors.v1.primary[500],
      icon: theme.colors.v1.primary[500],
    },
    hover: {
      background: theme.colors.v1.primary[100],
      border: theme.colors.v1.primary[300],
      color: theme.colors.v1.primary[500],
      icon: theme.colors.v1.primary[500],
    },
  },
};

export const StyledButton = styled(Button)<StyledButtonProps>`
  ${({ state }) =>
    `
    text-wrap: nowrap;

    &:hover {
    background-color: ${colors[state].hover.background};
    border-color: ${colors[state].hover.border};
    color: ${colors[state].hover.color};

    svg {
      color: ${colors[state].hover.icon};
    }
  }
  `}
`;
