import { Exclude, Expose, Type } from 'class-transformer';

import {
  OrganizationInvitationUser,
  OrganizationInvitationUserData,
} from './OrganizationInvitationUser';

export interface OrganizationInvitationVerifyData {
  email: string;
  organizationName: string;
  status: string;
  user?: OrganizationInvitationUserData;
}

export class OrganizationInvitationVerifyModel {
  @Exclude()
  static objectType: string = 'organizationinvitationverify';

  objectType: string = OrganizationInvitationVerifyModel.objectType;

  @Exclude()
  static typeDisplay: string = 'OrganizationInvitationVerify';

  typeDisplay: string = OrganizationInvitationVerifyModel.typeDisplay;

  email!: string;

  @Type(() => OrganizationInvitationUser)
  user?: OrganizationInvitationUser;

  @Expose({ name: 'organization_name' })
  organizationName!: string;

  status!: string;
}
