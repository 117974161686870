import React, { useEffect, useMemo, useState } from 'react';
import type { Cell } from 'react-table';

import { useFetchBiColumns } from '@api/biColumnsV1';
import type { ColumnModel } from '@api/columns/ColumnModel';
import Box from '@components/Box';
import {
  BI_COLUMNS_TABLE_SEARCH_CONFIG,
  BI_COLUMNS_TABLE_SORT_CONFIG,
} from '@components/TabContent/BiColumnsTab/BiColumnsTable';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { useUserContext } from '@context/User';
import type { FilterOptions } from '@utils/filters';
import { setParams, useUpdateFilters } from '@utils/filters';
import stripSpaces from '@utils/stripSpaces';

const getRowId = (row: Partial<{ guid: string }>) => row.guid!;

const REQUEST_CONFIG: FilterOptions = {
  order: 'name',
  query: stripSpaces(`{
    guid,
    data_types,
    description,
    parent_guid,
    name
  }`),
};

interface BiColumnSelectProps {
  initialDimensions?: string[];
  parentGuid?: string;
  setSelectedDimensions: (value: string[]) => void;
}

const BiColumnSelect: React.FC<BiColumnSelectProps> = ({
  initialDimensions,
  parentGuid,
  setSelectedDimensions,
}) => {
  const { organization } = useUserContext();
  const [isShowFilter, setShowFilter] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState(() => {
    const selectedMap: { [key: string]: boolean } = {};
    initialDimensions?.forEach((dimension) => {
      selectedMap[dimension] = true;
    });
    return selectedMap;
  });

  const { filter, initialTableSortState, search, sort } = useUpdateFilters(
    REQUEST_CONFIG,
    BI_COLUMNS_TABLE_SEARCH_CONFIG,
    BI_COLUMNS_TABLE_SORT_CONFIG,
    REQUEST_CONFIG.order,
  );

  const { data, isLoading } = useFetchBiColumns({
    enabled: Boolean(parentGuid),
    params: {
      ...setParams(filter),
      datasets: parentGuid!,
    },
  });
  const selectedRowKeys = useMemo(() => Object.keys(selectedRowIds), [selectedRowIds]);

  const columns: ColumnConfig<ColumnModel>[] = useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ column, row: { original }, state }: Cell<ColumnModel>) => (
          <LinkedCell
            column={column}
            customUrl={original.routePath}
            item={original}
            showIcon
            showNameTooltip
            state={state}
          />
        ),
        Header: `Fields (${data?.count ?? 0})`,
        accessor: (d) => d.name,
        disableHiding: true,
        id: 'name',
        width: '100%',
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original } }: Cell<ColumnModel>) => (
          <DescriptionCell
            {...original}
            dataSourceType={original.dataTypes?.dataSourceType}
            isDataSourceEditable={false}
            showDescriptionSelector={false}
            truncateDisabled={!organization?.settings?.useShowMoreButton}
          />
        ),
        Header: 'Description',
        accessor: (d) => d.description,
        disableFilters: true,
        id: 'description',
        width: '150%',
      },
    ],
    [data?.count, organization?.settings?.useShowMoreButton],
  );

  const toggleFilter = () => setShowFilter((prev) => !prev);

  const result = data?.results ?? [];

  useEffect(() => {
    setSelectedDimensions(selectedRowKeys);
  }, [selectedRowKeys, setSelectedDimensions]);

  return (
    <Box compHeight="16rem" compWidth="100%" noDefault overflow="auto">
      <TableStyled>
        <Table
          aria-label="Fields table"
          basic="very"
          columns={columns}
          compact
          data={result}
          disableColumnFiltering
          disablePagination
          getRowId={getRowId}
          initialState={{
            pageIndex: 0,
            selectedRowIds,
            sortBy: initialTableSortState,
          }}
          loading={isLoading}
          manualFilters
          manualSortBy
          selectable
          setFilters={search}
          setSelectedRowIds={setSelectedRowIds}
          setSortBy={sort}
          showFilter={isShowFilter}
          sortable
          toggleFilter={toggleFilter}
          unstackable
        />
      </TableStyled>
    </Box>
  );
};

export default BiColumnSelect;
