import React from 'react';

import { useFetchMetadataComments } from '@api/metadata';
import DiscussionTab from '@components/TabContent/DiscussionTab';
import ExploreFieldsTab from '@components/TabContent/ExploreFieldsTab';
import TabbedLineage from '@components/TabContent/TabbedLineage';
import TopUsersTab from '@components/TabContent/TopUsersTab';
import type { TabRoutesConfig } from '@components/TabsRouter';
import { useMetadataObjectContext } from '@context/MetadataObject';
import { useUserContext } from '@context/User';
import MetadataTabsRouter from '@pages/MetadataObjectPage/MetadataTabsRouter';
import wrapString from '@utils/wrapString';

import type { TabContentProps } from '../types';

interface ExploresTabContentProps extends TabContentProps {}

const ExploresTabContent: React.FC<ExploresTabContentProps> = ({
  data,
  guid,
  overviewTabConfig,
}) => {
  const { data: commentData } = useFetchMetadataComments(guid);
  const { dataSources, organization, user } = useUserContext();
  const { dataSourceGuid } = useMetadataObjectContext();
  const isDataSourceEditable = Boolean(dataSources?.[dataSourceGuid ?? '']?.settings?.isEditable);
  const userDefaultTab = user?.settings.defaultTableDashboardTab;

  const overviewTabDefaultConfig = { default: userDefaultTab === 'overview' };

  const tabsRouterConfig: TabRoutesConfig[] = [
    { ...overviewTabConfig, ...overviewTabDefaultConfig },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <ExploreFieldsTab guid={guid} isDataSourceEditable={isDataSourceEditable} stickyHeader />
      ),
      default: userDefaultTab === 'columns_charts',
      label: 'Fields',
      path: '/fields/:itemId?',
      route: '/fields',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <TabbedLineage guid={guid} itemMetadata={data} objectType="explore" />,
      contentOffset: 1.6,
      label: 'Lineage',
      route: '/lineage',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <TopUsersTab guid={guid} objectType="explore" />,
      label: 'Top Users',
      route: '/topusers',
    },
    ...(organization?.settings?.useDiscussions
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => <DiscussionTab guid={guid} />,
            contentOffset: 2,
            label: `Discussion ${wrapString(commentData?.length)}`,
            path: '/discussion/:itemId?',
            route: '/discussion',
          },
        ]
      : []),
  ];

  return <MetadataTabsRouter config={tabsRouterConfig} />;
};

export default ExploresTabContent;
