import React, { useEffect, useState } from 'react';
import getIcons from '@configs/icons/getIcons';
import { useTheme } from '@emotion/react';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import remove from 'lodash/remove';
import { CheckboxProps } from 'semantic-ui-react';

import { CustomAttributeDataSourceType } from '@api/customAttributes/CustomAttributeModel';
import { sortDataSource } from '@api/customAttributes/CustomAttributes.utils';
import Box from '@components/Box';
import Text from '@components/Text';
import Checkbox from '@components/UI/Form/Checkbox';
import InputLabel from '@components/UI/Form/InputLabel';
import Icon from '@components/UI/Icon';
import { useUserContext } from '@context/User';
import { DataSourceOptions } from '@models/DataSourceOptions';

import {
  CustomAttributesAssetConfig,
  getCustomAttributesAssetsConfig,
} from '../../customAttributesAssetsConfig';

export type AssetsMap = {
  [key in CustomAttributeDataSourceType]?: string[];
};

interface CustomAttributesAssetsProps {
  initialAssets?: AssetsMap;
  onAssetsChange: (assets: AssetsMap) => void;
}

export const getAssetValue = (asset: CustomAttributesAssetConfig) =>
  `${asset.objectType},${asset.dataType ?? ''}`;

const CustomAttributesAssets: React.FC<CustomAttributesAssetsProps> = ({
  initialAssets,
  onAssetsChange,
}) => {
  const { colors, typography } = useTheme();
  const { dataSources } = useUserContext();
  const [assetsMap, setAssetsMap] = useState<AssetsMap>({});
  const [customAttributesDataSources, setCustomAttributesDataSources] = useState<
    CustomAttributeDataSourceType[]
  >([]);

  const handleAssetsChange = (dataSource: CustomAttributeDataSourceType, data: CheckboxProps) => {
    setAssetsMap((prevAssetsMap) => {
      const newDataSourceAssets = [...(prevAssetsMap[dataSource] ?? [])];

      if (data.checked) {
        newDataSourceAssets.push(data.value as string);
      } else {
        remove(newDataSourceAssets, (asset) => asset === data.value);
      }

      onAssetsChange({ ...prevAssetsMap, [dataSource]: newDataSourceAssets });

      return { ...prevAssetsMap, [dataSource]: newDataSourceAssets };
    });
  };

  useEffect(() => {
    const dataSourcesGrouped = groupBy(dataSources, 'type');
    const userDataSources = Object.keys(dataSourcesGrouped) as CustomAttributeDataSourceType[];
    const initialAssetsState = mapValues<AssetsMap, string[]>(dataSourcesGrouped, () => []);

    Object.keys(initialAssetsState).forEach((dataSource) => {
      const typedDataSource = dataSource as CustomAttributeDataSourceType;
      if (initialAssets?.[typedDataSource]) {
        initialAssetsState[typedDataSource] = initialAssets?.[typedDataSource];
      }
    });
    setAssetsMap(initialAssetsState);
    setCustomAttributesDataSources([...userDataSources].sort(sortDataSource));
  }, [dataSources, initialAssets]);

  return (
    <Box>
      {customAttributesDataSources.map((dataSource) => {
        const config = getCustomAttributesAssetsConfig(dataSource);
        return (
          <Box key={dataSource} pb={2.5} pl={2}>
            <Box alignItems="center" compDisplay="flex">
              <Icon name={getIcons({ dataSourceType: dataSource }).dataSource} size="14px" />
              <Text
                color={colors.gray[700]}
                fontSize={typography.fontSizes.body1}
                fontWeight="bold"
                mb={0}
                pl={1}
                pr={0.5}
              >
                {DataSourceOptions.find((ds) => ds.value === dataSource)?.text}
              </Text>
            </Box>
            <Box compDisplay="flex" pl={2.75} pt={1}>
              {config?.map((asset) => {
                const assetValue = getAssetValue(asset);
                return (
                  <Box
                    key={`${dataSource}-${asset.objectType}-${asset.dataType}`}
                    compDisplay="flex"
                    pr={2}
                  >
                    <InputLabel alignItems="flex-start" compDisplay="flex" fontSize="body1">
                      <Checkbox
                        checked={assetsMap[dataSource]?.some((item) => item === assetValue)}
                        onChange={(e) =>
                          handleAssetsChange(dataSource, {
                            checked: e.target.checked,
                            value: assetValue,
                          })
                        }
                      />
                      {asset.label}
                    </InputLabel>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default CustomAttributesAssets;
