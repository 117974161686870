import React, { useEffect, useState } from 'react';

import { useFetchDataSourceTagSync, usePatchDataSourceTagSync } from '@api/dataSources';
import invalidateCache from '@api/invalidateCache';
import Alert from '@components/Alert';
import Box from '@components/Box';
import DocsButton from '@components/Button/BIButtons/DocsButton';
import Button from '@components/Button/Button';
import CircularLoader from '@components/CircularLoader';
import Emoji from '@components/Emoji';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import Link from '@components/Link';
import type { TabContentProps } from '@components/Tabs/types';
import { renderInfoToast } from '@components/Toast';
import InputLabel from '@components/UI/Form/InputLabel';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '@components/UI/Modal';
import { useModal } from '@context/Modal';
import { SnowflakeTagSyncModel } from '@models/SnowflakeTagSyncModel';

const FIELD_GRID_TEMPLATE_COLUMNS = '7rem 1fr';

interface SnowflakeTagSyncProps extends TabContentProps {
  dsGuid: string;
}

const SnowflakeTagSync: React.FC<SnowflakeTagSyncProps> = ({ dsGuid }) => {
  const [updatedData, setUpdatedData] = useState<SnowflakeTagSyncModel | undefined>();
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();

  const { data: initData, isLoading: isInitLoading } = useFetchDataSourceTagSync(dsGuid);

  const refetchTagSync = () => {
    invalidateCache((keys) => [keys.dataSources.dataSourceTagSync(dsGuid)]);
  };

  const {
    error: updateError,
    isLoading: isUpdateLoading,
    mutate: runSnowflakeTagSync,
  } = usePatchDataSourceTagSync(dsGuid, {
    onSuccess: (res) => {
      setUpdatedData(res);
      refetchTagSync();
    },
  });

  const snowflakeTagSyncStatus = initData?.snowflakeTagSyncStatus;
  const isSnowflakeTagSyncEnabled =
    updatedData?.snowflakeTagSyncEnabled || initData?.snowflakeTagSyncEnabled;
  const { handleChange, handleSubmit, setValues, values } = useForm({
    initialValues: {
      snowflake_tag_sync_db_name: '',
      snowflake_tag_sync_enabled: false,
      snowflake_tag_sync_role_name: '',
      snowflake_tag_sync_schema_name: '',
    },
    onSubmit: (submitValues) => {
      runSnowflakeTagSync({ ...submitValues, snowflake_tag_sync_enabled: true });
    },
  });

  useEffect(() => {
    if (initData !== undefined) {
      setValues({
        snowflake_tag_sync_db_name: initData.snowflakeTagSyncDbName,
        snowflake_tag_sync_enabled: initData.snowflakeTagSyncEnabled,
        snowflake_tag_sync_role_name: initData.snowflakeTagSyncRoleName,
        snowflake_tag_sync_schema_name: initData.snowflakeTagSyncSchemaName,
      });
    }
  }, [initData, setValues]);

  useEffect(() => {
    if (!isUpdateLoading && updatedData?.snowflakeTagSyncEnabled) {
      renderInfoToast('Snowflake Tag Sync connected.');
      closeModal(MODAL_IDS.snowflakeTagSync);
    }
  }, [isUpdateLoading, updatedData, MODAL_IDS, closeModal]);

  if (isInitLoading) return <CircularLoader compDisplay="block" mx="auto" my={1} />;

  const modalTargetButton = (btnText: string = 'Enable Tags Sync') => (
    <Button onClick={() => openModal(MODAL_IDS.snowflakeTagSync)}>
      <Box alignItems="center" as="span" compDisplay="flex">
        {btnText}
      </Box>
    </Button>
  );

  const onDisableTagsSyncClick = () => {
    runSnowflakeTagSync(
      { snowflake_tag_sync_enabled: false },
      {
        onSuccess: () => {
          renderInfoToast('Snowflake Tag Sync disabled.');
        },
      },
    );
  };

  const handleCloseModal = () => closeModal(MODAL_IDS.snowflakeTagSync);

  return (
    <Box pt={2}>
      {isSnowflakeTagSyncEnabled ? (
        <>
          {snowflakeTagSyncStatus === 'DONE' && (
            <Box mb={2}>
              <Box compDisplay="inline" mr={1}>
                <Emoji type="check" />
              </Box>
              Tag Sync is enabled.
            </Box>
          )}
          {snowflakeTagSyncStatus === 'PROG' && (
            <>
              <Box mb={2}>
                <Box compDisplay="inline" mr={1}>
                  <Emoji type="progress" />
                </Box>
                Tag Sync is in progress. It may take a few hours for all the tags to be fully
                applied in Snowflake. See our{' '}
                <Link
                  href="https://docs.selectstar.com/integrations/snowflake/snowflake-tag-sync"
                  rel="noopener noreferrer"
                  target="_blank"
                  underline
                >
                  documentation
                </Link>{' '}
                for more info.
              </Box>
              <Button onClick={refetchTagSync}>
                <Box alignItems="center" as="span" compDisplay="flex">
                  Refresh status
                </Box>
              </Button>
            </>
          )}
          {snowflakeTagSyncStatus === 'ERROR' && initData && (
            <Alert mb={2} title="Connection error" type="error">
              {initData.snowflakeTagSyncErrorMessage}
            </Alert>
          )}
          {snowflakeTagSyncStatus === 'UNK' && (
            <Alert mb={2} title="Unknown status" type="warning">
              Please try to sync again.
            </Alert>
          )}
          {snowflakeTagSyncStatus !== 'PROG' && (
            <Button disabled={isUpdateLoading} onClick={onDisableTagsSyncClick}>
              Disable Tags Sync
            </Button>
          )}
        </>
      ) : (
        <>
          <Alert mb={2} type="info">
            In order to enable{' '}
            <Link
              href="https://docs.selectstar.com/integrations/snowflake/snowflake-tag-sync"
              rel="noopener noreferrer"
              target="_blank"
              underline
            >
              Snowflake Tags Sync
            </Link>
            , please update the Select Star service account role to have a{' '}
            <Link
              href="https://docs.snowflake.com/en/user-guide/object-tagging.html#step-1-create-a-custom-role-and-assign-privileges"
              rel="noopener noreferrer"
              target="_blank"
              underline
            >
              custom role
            </Link>{' '}
            for managing tags, as well as usage access for databases and schemas.
          </Alert>
          {modalTargetButton()}
        </>
      )}
      {checkModalOpened(MODAL_IDS.snowflakeTagSync) && (
        <Modal closeOnDimmerClick={false} onClose={handleCloseModal} open useContext>
          <ModalHeader
            onClose={handleCloseModal}
            title={
              <Box alignItems="center" compDisplay="flex">
                <Box mr={1}>Snowflake Tags Sync</Box>
                <DocsButton
                  href="https://docs.selectstar.com/integrations/snowflake/snowflake-tag-sync"
                  rel="noopener noreferrer"
                  target="_blank"
                />
              </Box>
            }
          />
          <Form isLoading={isUpdateLoading} onSubmit={handleSubmit}>
            <ModalContent py={2}>
              <Box compDisplay="flex" flexDirection="column" gap={2}>
                <InputLabel
                  color="gray.700"
                  compDisplay="grid"
                  fontWeight="bold"
                  gridTemplateColumns={FIELD_GRID_TEMPLATE_COLUMNS}
                >
                  Role name
                  <Input
                    autoFocus
                    error={updateError?.data?.snowflake_tag_sync_role_name}
                    name="snowflake_tag_sync_role_name"
                    onChange={handleChange}
                    placeholder="TAG_ADMIN"
                    type="text"
                    value={values.snowflake_tag_sync_role_name}
                  />
                </InputLabel>
                <InputLabel
                  color="gray.700"
                  compDisplay="grid"
                  fontWeight="bold"
                  gridTemplateColumns={FIELD_GRID_TEMPLATE_COLUMNS}
                >
                  Database name
                  <Input
                    error={updateError?.data?.snowflake_tag_sync_db_name}
                    name="snowflake_tag_sync_db_name"
                    onChange={handleChange}
                    placeholder="DB_NAME"
                    type="text"
                    value={values.snowflake_tag_sync_db_name}
                  />
                </InputLabel>
                <InputLabel
                  color="gray.700"
                  compDisplay="grid"
                  fontWeight="bold"
                  gridTemplateColumns={FIELD_GRID_TEMPLATE_COLUMNS}
                >
                  Schema name
                  <Input
                    error={updateError?.data?.snowflake_tag_sync_schema_name}
                    name="snowflake_tag_sync_schema_name"
                    onChange={handleChange}
                    placeholder="PUBLIC"
                    type="text"
                    value={values.snowflake_tag_sync_schema_name}
                  />
                </InputLabel>
              </Box>
            </ModalContent>
            <ModalFooter>
              <Button
                disabled={
                  isUpdateLoading ||
                  !values.snowflake_tag_sync_role_name ||
                  !values.snowflake_tag_sync_db_name ||
                  !values.snowflake_tag_sync_schema_name
                }
                type="submit"
              >
                Enable Tags Sync
              </Button>
            </ModalFooter>
          </Form>
          {updateError?.data?.non_field_errors && (
            <Alert title="Invalid connection configuration" type="error">
              {updateError.data.non_field_errors}
            </Alert>
          )}
        </Modal>
      )}
    </Box>
  );
};

export default SnowflakeTagSync;
