import styled from '@emotion/styled';

import Button from '@components/Button/Button';
import Resizable from '@components/Resizable';
import px from '@styles/mixins/px';

export const MENU_ITEM_WIDTH = 90;
const MENU_ITEM_HEIGHT = 60;

interface StyledAppMainSidebarProps {
  top?: number;
}

export const StyledAppMainSidebar = styled.aside<StyledAppMainSidebarProps>`
  display: flex;
  flex-shrink: 0;
  position: sticky;
  z-index: ${({ theme }) => theme.zIndex.sidebar};
  top: ${({ top }) => `${top}px`};
  height: ${({ top }) => `calc(100vh - ${top}px)`};
`;

export const AppMainSidebarInner = styled.nav`
  background: ${({ theme }) => theme.colors.darkBackground};
  width: ${px(MENU_ITEM_WIDTH)};
  z-index: 1;
  display: flex;
  gap: ${({ theme }) => theme.space(1)};
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

interface AppMainSidebarItemProps {
  isActive: boolean;
  isHovered: boolean;
  to?: string;
}

export const AppMainSidebarMenuItem = styled.button<AppMainSidebarItemProps>`
  align-items: center;
  background-color: ${({ isActive, theme }) => (isActive ? theme.colors.violet : 'transparent')};
  color: white;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  gap: ${({ theme }) => theme.space(0.5)};
  height: ${px(MENU_ITEM_HEIGHT)};
  justify-content: center;
  line-height: ${({ theme }) => theme.typography.lineHeights.default};
  opacity: ${({ isActive, isHovered }) => (isActive || isHovered ? 1 : 0.7)};
  transition: opacity 0.15s ease;
  user-select: none;
  width: ${({ theme }) => `calc(100% - ${theme.space(1.5)})`};
  border-radius: ${({ theme }) => theme.radius.md};
  flex-grow: 0;

  &:hover {
    opacity: 1;
    color: white;
  }

  svg {
    fill: currentColor;
    transform: ${({ isActive }) => (isActive ? 'scale(1.15)' : undefined)};
  }
`;

interface AppMainSidebarSecondLevelNavProps {
  id?: string;
  isPinned: boolean;
  isVisible: boolean;
}

export const AppMainSidebarSecondLevelResizableNav = styled(
  Resizable,
)<AppMainSidebarSecondLevelNavProps>`
  background-color: ${({ theme }) => theme.colors.gray[100]};
  border-right: 1px solid ${({ theme }) => theme.colors.gray[200]};
  box-shadow: 1px 0 6px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  height: 100%;
  left: ${px(MENU_ITEM_WIDTH)};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0.25)};
  padding: ${({ theme }) => theme.space(2, 0)};
  top: 0;
  transform: translateX(${({ isVisible }) => (isVisible ? '0%' : '-105%')});
  transition: 0.1s ease;
  z-index: 0;
  position: absolute !important;

  ${({ theme }) => theme.media.down('sm')`
    max-width: 200px !important;
  `}

  ${({ isPinned, theme }) => theme.media.up('sm')`
    position: ${isPinned ? 'static' : 'absolute'} !important;
  `}
`;

interface AppMainSidebarPinSecondLevelNavProps {
  isActive: boolean;
}

export const AppMainSidebarPinSecondLevelNavButton = styled(
  Button,
)<AppMainSidebarPinSecondLevelNavProps>`
  background-color: ${({ isActive, theme }) => (isActive ? theme.colors.violet : 'white')};
  color: ${({ isActive, theme }) => (isActive ? 'white' : theme.colors.violet)};
  height: 30px;
  padding: 0;
  position: absolute;
  right: -16px;
  top: ${({ theme }) => theme.space(2.375)};
  width: 30px;
  z-index: 1;
`;
