import { Expose, Type } from 'class-transformer';

import { DsUserModel } from '@api/dsusers/DsUserModel';
import { TeamModel } from '@api/teams/TeamModel';
import { UserModel } from '@api/user/UserModel';
import { Reference } from '@models/Reference';

export type UserListTypes = 'downstream_owners' | 'top_users';
export type UserListUserTypes = 'bi' | 'table' | 'team' | 'user';

export type UserListTargetTypes =
  | 'bifolder'
  | 'dashboard'
  | 'document'
  | 'explore'
  | 'lookmlview'
  | 'metric'
  | 'table'
  | 'tableaudatasource'
  | 'tableaufield'
  | 'tableauview'
  | 'tag'
  | 'team'
  | 'term';

export interface UserListData {
  guid: string;
  name: string;
  targetTypes?: UserListTargetTypes[];
  type: UserListTypes;
  userCount: number;
  users?: Reference<DsUserModel | UserModel | TeamModel>[];
}

export class UserListModel {
  static objectType: 'userlist' = 'userlist';

  objectType: 'userlist' = UserListModel.objectType;

  guid!: string;

  @Expose({ name: 'user_list_type' })
  type!: UserListTypes;

  @Expose({ name: 'user_type' })
  userType!: UserListUserTypes;

  name!: string;

  @Expose({ name: 'target_types' })
  targetTypes?: UserListTargetTypes[];

  @Expose({ name: 'user_count' })
  userCount!: number;

  @Type(() => Reference)
  users?: Reference<DsUserModel | UserModel | TeamModel>[];
}
