import React from 'react';
import { DropdownItemProps } from 'semantic-ui-react';

import { BIFolderModel } from '@api/bifolder/BIFolderModel';
import { ColumnModel } from '@api/columns/ColumnModel';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import { SchemaModel } from '@api/schema/SchemaModel';
import { TableModel } from '@api/tables/TableModel';
import { TeamModel } from '@api/teams/TeamModel';
import { UserModel } from '@api/user/UserModel';
import Icon from '@components/UI/Icon';
import { Option } from '@components/UI/Select.v1/types';
import { DataSourceModel } from '@models/DataSourceModel';

import { StyledAvatar, StyledOption } from './Dropdown.styles';

export type ExtendedDropdownItemProps = DropdownItemProps & {
  dataTypeOption?: string;
  name: string;
  parentname?: string;
};

export const getTeamContent = (team: TeamModel) => (
  <StyledOption className="user-option">
    <span className="name">{team.name}</span>
  </StyledOption>
);

export const getUserContent = (user: UserModel) => (
  <StyledOption className="user-option">
    <span className="name">{user.fullName}</span>
    <span className="team">{user.team?.name}</span>
  </StyledOption>
);

export const getDSUserContent = (dsuser: DsUserModel) => (
  <StyledOption className="user-option">
    <span className="name">{dsuser.fullName}</span>
  </StyledOption>
);

export const getDatasouceContent = (datasource: DataSourceModel) => (
  <StyledOption className="table-option">
    <span className="name">{datasource.name}</span>
  </StyledOption>
);
export const getStringOptions = (items: string[]) =>
  items.map((el) => ({
    key: el,
    name: el,
    text: el,
    value: el,
  }));

export const getColumnContent = (column: ColumnModel) => (
  <StyledOption className="table-option">
    <span className="name">
      {`${column.table?.schema?.name}.${column.table?.name}.${column.name}`}
    </span>
  </StyledOption>
);

export const getDsusersOptions = (items: DsUserModel[]) =>
  items.map((du) => ({
    content: getDSUserContent(du),
    image: <StyledAvatar {...du.mappedAvatar} size="1.25rem" />,
    key: du.guid,
    name: du.fullName,
    text: du.fullName,
    value: du.guid,
  }));

export const getDsUsersOptionsV1 = (items: DsUserModel[]): Option[] =>
  items.map((du) => ({
    avatar: du.mappedAvatar,
    text: du.fullName ?? '',
    value: du.guid,
  }));

export const getDatasourceOptions = (items: DataSourceModel[]) =>
  items.map((ds) => ({
    content: getDatasouceContent(ds),
    image: (
      <Icon compDisplay="inline-flex" mr={0.5} name={ds.dataTypes?.icons.dataSource!} size="16px" />
    ),
    key: ds.guid,
    name: ds.name,
    text: ds.name,
    value: ds.guid,
  }));

export const getCollectionsOptions = (items: BIFolderModel[]): Option[] =>
  items.map((folder) => ({
    icon: folder.dataTypes?.icons.dataType,
    text: folder.name,
    value: folder.guid,
  }));

export const getUserOptions = (items: UserModel[]): ExtendedDropdownItemProps[] =>
  items.map((u) => ({
    content: getUserContent(u),
    image: <StyledAvatar {...u.mappedAvatar} size="1.25rem" />,
    key: u.guid,
    name: u.isInvitationPending ? u.email || u.fullName : u.fullName,
    text: u.isInvitationPending ? u.email : u.fullName,
    value: u.guid,
  }));

export const getTeamOptions = (items: TeamModel[]): ExtendedDropdownItemProps[] =>
  items.map((t) => ({
    content: getTeamContent(t),
    image: <StyledAvatar {...t.mappedAvatar} size="1.25rem" />,
    key: t.guid,
    name: t.fullName || '',
    text: t.fullName,
    value: t.guid,
  }));

export const getSchemaOptions = (items: SchemaModel[]): Option[] =>
  items.map((s) => ({
    icon: s.dataTypes?.icons.dataType,
    text: s.name,
    value: s.guid,
  }));

export const getTableOptions = (items: TableModel[]): Option[] =>
  items.map((t) => ({
    icon: t.dataTypes?.icons.dataType,
    text: t.schema?.name ? `${t.schema?.name}.${t.name}` : t.name,
    value: t.guid,
  }));

export const getColumnOptions = (items?: ColumnModel[]) =>
  items?.map((c) => {
    const fullName = `${c.table?.schema.name}.${c.table?.name}.${c.name}`;
    return {
      icon: c.dataTypes?.icons.dataType!,
      original: {
        dataTypes: c.dataTypes,
        fullName,
        guid: c.guid,
      },
      text: fullName,
      value: c.guid,
    };
  }) ?? [];
