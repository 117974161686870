import React from 'react';
import { Redirect } from 'react-router-dom';

import { isIngestionComplete } from '@api/organizations/OrganizationModel';
import CircularLoader from '@components/CircularLoader';
import UnexpectedError from '@components/Error/UnexpectedError';
import { useUserContext } from '@context/User';

const UserRedirect: React.FC = ({ children }) => {
  const { dataSources, error, isOnboarding, isOnboardingComplete, isTos, loading, organization } =
    useUserContext();

  if (loading) {
    return <CircularLoader centered cover />;
  }

  if (!isTos) {
    return <Redirect to="/tos" />;
  }

  if (isOnboarding) {
    return <Redirect to="/onboarding" />;
  }

  if (!isOnboardingComplete) {
    return <Redirect to="/onboarding" />;
  }

  if (!isIngestionComplete(organization, dataSources)) {
    return <Redirect exact to="/incomplete-organization" />;
  }

  if (error) {
    return <UnexpectedError />;
  }

  return <>{children}</>;
};

export default UserRedirect;
