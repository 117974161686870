import React from 'react';

import Box from '@components/Box';
import IconButton from '@components/IconButton';
import Text from '@components/Text';
import Icon from '@components/UI/Icon';
import UsageType from '@components/UsageType';
import theme from '@styles/theme';
import useLocalStorage from '@utils/useLocalStorage';

import { StyledExploreTreeLegend } from './ExploreLegend.styles';

const legendMap = [
  { label: 'Field has been replicated as is', type: 'asis' as const },
  { label: 'Field is aggregated', type: 'aggregated' as const },
  { label: 'Field has been transformed', type: 'transformed' as const },
  { label: 'Field has been used as a filter', type: 'filter' as const },
];

const ExploreLegend = () => {
  const [isExploreLegendOpen, setExploreLegendOpen] = useLocalStorage('isExploreLegendOpen', true);

  return (
    <StyledExploreTreeLegend isOpen={isExploreLegendOpen}>
      <Box alignItems="center" compDisplay="flex" justifyContent="space-between">
        <Text
          color="gray.600"
          fontSize={theme.typography.fontSizes.default}
          fontWeight="bold"
          mb={0}
        >
          Field Usage Legend
        </Text>
        <IconButton
          onClick={() =>
            setExploreLegendOpen((prevIsExploreLegendOpen) => !prevIsExploreLegendOpen)
          }
          title={isExploreLegendOpen ? 'Hide' : 'Show'}
        >
          <Icon
            color={theme.colors.gray[600]}
            name={isExploreLegendOpen ? 'down' : 'up'}
            size="16px"
          />
        </IconButton>
      </Box>
      {isExploreLegendOpen && (
        <Box mt={1}>
          {legendMap?.map((item) => {
            return (
              <Box key={item.type} py={0.25}>
                <UsageType hideTooltips types={[item.type]} />
                <Text color="inherit" fontSize={theme.typography.fontSizes.body2} mb={0} ml={1}>
                  {item.label}
                </Text>
              </Box>
            );
          })}
        </Box>
      )}
    </StyledExploreTreeLegend>
  );
};

export default ExploreLegend;
