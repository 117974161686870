import React, { useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { useFetchInvitation } from '@api/invitations';
import { useFetchSSOAuthURL } from '@api/sso';
import InvitationSignUp from '@components/Forms/InvitationSignUp';
import {
  FullPageVerticallyCenteredLoader,
  IndeterminateLoaderModal,
} from '@components/IndeterminateLoader';
import TextScreen from '@components/TextScreen';
import { renderErrorToast } from '@components/Toast';
import TwoColumnDecorativeComponent from '@components/TwoColumnDecorativeComponent';
import TwoColumnLayout from '@components/TwoColumnLayout';

const InvitationSignUpPage: React.FC = () => {
  const { code } = useParams<{ code: string }>();
  const history = useHistory();
  const [isSignupSuccessful, setSignupSuccessful] = useState(false);
  const [redirectingToSSO, setRedirectingToSSO] = useState(false);

  const { data: invitation, error, isLoading } = useFetchInvitation(code);

  const { isLoading: ssoLoading } = useFetchSSOAuthURL({
    enabled: Boolean(invitation?.email),
    onError: (err) => {
      setRedirectingToSSO(false);
      if (err?.status !== 404) {
        renderErrorToast('Unexpected SSO Redirect Error.');
      }
    },
    onSuccess: (d) => {
      /**
       * If a user ends up having a url auth AND a pending invitation for their email,
       * the SSO callback will provision the user.
       */
      window.location.href = d;
      setRedirectingToSSO(true);
    },
    params: { email: invitation?.email },
    retry: false,
  });

  let leftComponent = (
    <InvitationSignUp
      code={code}
      invitation={invitation}
      isExistingUser={invitation?.user?.guid !== undefined}
      onSuccess={() => setSignupSuccessful(true)}
    />
  );

  if (isLoading || ssoLoading) {
    leftComponent = (
      <FullPageVerticallyCenteredLoader>
        <IndeterminateLoaderModal active indeterminate inline="centered" size="large" />
      </FullPageVerticallyCenteredLoader>
    );
  }

  if (!code || error) {
    // Invalid invitation code or invitation already accepted
    leftComponent = <Redirect to="/" />;
  }

  if (redirectingToSSO) {
    leftComponent = (
      <TextScreen
        text="We found Single Sign On for your Account, redirecting you to get set up."
        title="Sign up with SSO"
      />
    );
  }

  if (isSignupSuccessful) {
    leftComponent = (
      <TextScreen
        onClick={() => history.push('/')}
        text="Your account has been successfully created."
        title="Successfully created account"
      />
    );
  }

  return <TwoColumnLayout left={leftComponent} right={<TwoColumnDecorativeComponent />} />;
};

export default InvitationSignUpPage;
