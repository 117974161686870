import { useCallback, useEffect, useRef } from 'react';

export interface UseWindowScrollProps {
  containerId?: string;
  enabled?: boolean;
  offset?: number;
  onScroll: () => void;
}

const useWindowScroll = ({
  containerId,
  enabled = true,
  offset = 100,
  onScroll,
}: UseWindowScrollProps) => {
  const container = containerId ? document.getElementById(containerId) : document?.scrollingElement;
  const scrollRef = useRef<number>(container?.scrollTop ?? 0);

  const handleScroll = useCallback(
    (e: Event) => {
      const target = containerId
        ? (e.target as HTMLDivElement)
        : (e.target as Document).documentElement;
      const { scrollTop } = target ?? {};
      const diff = Math.abs(scrollRef.current - scrollTop);

      if (diff > offset) {
        onScroll();
        scrollRef.current = scrollTop;
      }
    },
    [containerId, offset, onScroll],
  );

  useEffect(() => {
    if (enabled) {
      const ScrollParent = containerId ? container : window;
      ScrollParent?.addEventListener('scroll', handleScroll);
      return () => ScrollParent?.removeEventListener('scroll', handleScroll);
    }
    return () => {};
  }, [onScroll, enabled, handleScroll, offset, container, containerId]);
};

export default useWindowScroll;
