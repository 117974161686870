import React from 'react';
import { useParams } from 'react-router-dom';

import { useFetchActivity } from '@api/activity';
import { useFetchOrganizationUser } from '@api/organizationUser';
import { useFetchUserDSUsers } from '@api/users';
import Box from '@components/Box';
import NotFoundError from '@components/Error/NotFoundError';
import { GridContainer } from '@components/Grid';
import { Profile } from '@components/Profile';
import ProfileRecentActivity from '@components/ProfileRecentActivity';
import RightNavTitle from '@components/RightNavTitle';
import RightSidebarContainer from '@components/RightSidebar/RightSidebar';
import TabsRouter from '@components/TabsRouter';
import MetadataDecorator from '@utils/MetadataDecorator';
import stripSpaces from '@utils/stripSpaces';

import { ProfileType } from './ProfilePage.types';
import MostQueriedMainTab from './Tabs/MostQueriedMainTab';
import ProfileFavoritesTab from './Tabs/ProfileFavoritesTab';
import ProfileOwnsTab from './Tabs/ProfileOwnsTab';
import ProfileRecentQueriesTab from './Tabs/ProfileRecentQueriesTab';

const activityQuery = stripSpaces(`{
  guid,
  activity_type,
  generated_by,
  performed_on,
  target {
    object_type,
    obj {
      -downstream_objects_counts,
      -upstream_objects_counts
    }
  },
  field
}`);

const connectedUsersQuery = stripSpaces(`{
  guid,
  data_source {
    guid,
    type,
    name
  },
  data_types,
  user {
    guid,
    first_name,
    last_name,
    email,
    avatar,
    team
  },
  name,
  email
}`);

const ProfilePage: React.FC = () => {
  const { guid } = useParams<{ guid: string }>();

  const { data: connectedDSUsers } = useFetchUserDSUsers(guid, {
    enabled: Boolean(guid),
    params: {
      page_size: 10,
      query: connectedUsersQuery,
    },
  });

  const { data: organizationUser, isError } = useFetchOrganizationUser(guid, {
    enabled: Boolean(guid),
  });

  const { data: activityData } = useFetchActivity({
    enabled: Boolean(guid),
    params: {
      page_size: 10,
      query: activityQuery,
      users: guid,
    },
  });

  if (!guid || isError) {
    return <NotFoundError />;
  }

  const tabComponentProps = {
    guid,
    profileType: ProfileType.user,
    stickyHeader: true,
  };

  const tabObjects = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <MostQueriedMainTab {...tabComponentProps} />,
      contentOffset: 2,
      default: true,
      label: 'Most Used',
      route: '/most-queried',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <ProfileRecentQueriesTab {...tabComponentProps} />,
      label: 'Recent Queries',
      route: '/recent-queries',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <ProfileFavoritesTab {...tabComponentProps} />,
      label: 'Favorites',
      route: '/favorites',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <ProfileOwnsTab {...tabComponentProps} />,
      contentOffset: 2,
      label: 'Owns',
      route: '/owns',
    },
  ];

  return (
    <Box compDisplay="flex">
      <MetadataDecorator title={organizationUser?.user.fullName} />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        {organizationUser?.user && (
          <Profile connectedAccounts={connectedDSUsers?.results} item={organizationUser.user} />
        )}
        <TabsRouter config={tabObjects} sticky />
      </GridContainer>
      <RightSidebarContainer>
        <RightNavTitle>Recent Activity</RightNavTitle>
        {activityData?.results?.map((activity) => {
          return <ProfileRecentActivity key={activity.guid} activity={activity} />;
        })}
      </RightSidebarContainer>
    </Box>
  );
};

export default ProfilePage;
