import React, { useState } from 'react';
import type { ModalId } from '@constants';

import { useFetchPiiRuleSync } from '@api/piiRules';
import type { PiiRuleModel } from '@api/piiRules/PiiRuleModel';
import { GridContainer } from '@components/Grid';
import IconButton from '@components/IconButton';
import CreatePiiRuleModal from '@components/Modal/CreatePiiRuleModal';
import DeletePiiRuleModal from '@components/Modal/DeletePiiRuleModal';
import PiiMatchedModal from '@components/Modal/PiiMatchedModal';
import AutomatedPiiTable from '@components/Table/AutomatedPiiTable';
import type { TabRoutesConfig } from '@components/TabsRouter';
import TabsRouter from '@components/TabsRouter';
import Icon from '@components/UI/Icon';
import TitleView from '@components/UI/TitleView';
import { useModal } from '@context/Modal';
import theme from '@styles/theme';
import MetadataDecorator from '@utils/MetadataDecorator';

import AutomatedPiiHistoryTab from './AutomatedPiiHistoryTab/AutomatedPiiHistoryTab';
import Sync from './Sync';

export interface AutomatedPIIPageModalState {
  pii: PiiRuleModel | undefined;
}

const AutomatedPIIPage: React.FC = () => {
  const { data } = useFetchPiiRuleSync();

  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();

  const [modal, setModal] = useState<AutomatedPIIPageModalState>();

  const title = 'Automated PII Detection';

  const handleCloseModal = (modalId: ModalId) => {
    closeModal(modalId);
    setModal({ pii: undefined });
  };

  const tabsRouterConfig: TabRoutesConfig[] = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <AutomatedPiiTable setModal={setModal} />,
      default: true,
      label: 'PII Rule',
      route: '/pii-rules',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <AutomatedPiiHistoryTab />,
      label: 'History',
      route: '/history',
    },
  ];

  return (
    <>
      <MetadataDecorator title={title} />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <TitleView
          action={
            <IconButton
              iconColor={theme.colors.gray[500]}
              iconName="plus"
              iconSize="16px"
              label="Create PII Rule"
              onClick={() => {
                setModal({ pii: undefined });
                openModal(MODAL_IDS.createPII);
              }}
            />
          }
          as="h1"
          compSize="lg"
          icon={<Icon color="currentColor" ml={-0.6} name="pii" size="1.1em" />}
          mb={4}
          subtitle="Manage metadata PII rules to automatically classify your sensitive columns"
          title={title}
        />
        <TabsRouter action={<Sync />} config={tabsRouterConfig} />
      </GridContainer>
      {checkModalOpened(MODAL_IDS.matchPII) && (
        <PiiMatchedModal
          datetime={data?.lastSyncedAt}
          onClose={() => handleCloseModal(MODAL_IDS.matchPII)}
          pii={modal?.pii}
        />
      )}
      {checkModalOpened(MODAL_IDS.deletePII) && (
        <DeletePiiRuleModal
          onClose={() => handleCloseModal(MODAL_IDS.deletePII)}
          pii={modal?.pii}
        />
      )}
      {checkModalOpened(MODAL_IDS.createPII) && (
        <CreatePiiRuleModal
          onClose={() => handleCloseModal(MODAL_IDS.createPII)}
          pii={modal?.pii}
        />
      )}
    </>
  );
};

export default AutomatedPIIPage;
