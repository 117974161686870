import React, { useMemo, useState } from 'react';

import { useFetchTagsHierarchy } from '@api/tags';
import type { Option } from '@components/UI/Select.v1/types';
import { HierarchyData } from '@models/HierarchyModel';

import {
  TagsHierarchyContext,
  TagsHierarchyContextProps,
  TagsSelectOptions,
} from './TagsHierarchyContext';

const processTagOptions = (node: HierarchyData) => {
  const { children, ...tagInfo } = node;

  const option: Option = {
    children: node.children.map(processTagOptions),
    icon: node.icon,
    iconColor: node.color,
    original: tagInfo,
    text: node.name,
    value: node.guid,
  };

  return option;
};

const TagsHierarchyProvider: React.FC = ({ children }) => {
  const [tagsSelectOptions, setTagsSelectOptions] = useState<TagsSelectOptions>({});
  const [tagsHierarchy, setTagsHierarchy] = useState<HierarchyData[]>([]);

  const { isLoading: tagsHierarchyIsLoading } = useFetchTagsHierarchy({
    onSuccess: (tagsData) => {
      let categoryTags;
      let statusTags;

      if (tagsData?.[0]) {
        categoryTags = processTagOptions(tagsData[0]);
      }
      if (tagsData?.[1]) {
        statusTags = processTagOptions(tagsData[1]);
      }
      setTagsHierarchy(tagsData);
      setTagsSelectOptions({ category: categoryTags, status: statusTags });
    },
  });

  const state: TagsHierarchyContextProps = useMemo(() => {
    return {
      tagsHierarchy,
      tagsHierarchyIsLoading,
      tagsSelectOptions,
    };
  }, [tagsHierarchy, tagsHierarchyIsLoading, tagsSelectOptions]);

  return <TagsHierarchyContext.Provider value={state}>{children}</TagsHierarchyContext.Provider>;
};

export default TagsHierarchyProvider;
