import React from 'react';
import { useParams } from 'react-router-dom';

import { useFetchFolders } from '@api/bifolder';
import { AccordionInputItem } from '@components/Accordion';
import { getCollectionsOptions } from '@components/Dropdown/helpers';
import IndeterminateLoader from '@components/IndeterminateLoader';

interface Props {
  update: (guid: string) => void;
}

const CollectionsFilter: React.FC<Props> = ({ update }) => {
  const { dsGuid } = useParams<{ dsGuid: string }>();

  const { data: collections } = useFetchFolders({
    params: {
      datasources: dsGuid,
      query: '{guid,name,description,data_types}',
    },
  });

  if (!dsGuid) {
    return null;
  }

  if (!collections) {
    return <IndeterminateLoader active indeterminate inline="centered" size="mini" />;
  }

  const options = getCollectionsOptions(collections.results);

  return (
    <AccordionInputItem options={options} title="Collections" update={(value) => update(value)} />
  );
};

export default CollectionsFilter;
