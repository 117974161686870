export type ExploreOptionKey =
  | 'showTables'
  | 'showModels'
  | 'showDashboards'
  | 'autoClose'
  | 'showFieldUsage';

export interface ExploreOptionItem {
  label: string;
  tooltip?: string;
  value?: boolean;
}

export type ExploreOptions = {
  autoClose: ExploreOptionItem;
  showDashboards: ExploreOptionItem;
  showFieldUsage: ExploreOptionItem;
  showModels: ExploreOptionItem | undefined;
  showTables: ExploreOptionItem;
};

export const DefaultExploreOptions: ExploreOptions = {
  autoClose: {
    label: 'Auto-close unrelated assets (recommended)',
    value: true,
  },
  showDashboards: {
    label: 'Dashboards',
    tooltip: 'Show dashboards in lineage',
    value: true,
  },
  showModels: {
    label: 'dbt Models',
    tooltip: 'Show dbt models in lineage',
    value: true,
  },
  showTables: {
    label: 'Tables',
    tooltip: 'Show tables in lineage',
    value: true,
  },
  // eslint-disable-next-line
  showFieldUsage: {
    label: 'Load Filter Usage',
    tooltip: 'Load Filter usage fields in lineage',
    value: true,
  },
};
