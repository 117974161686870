import { DashboardModel } from '@api/dashboards/DashboardModel';
import { DsUserModel } from '@api/dsusers/DsUserModel';
import paginatedTransform from '@api/paginatedTransform';
import { QueryModel } from '@api/queries/QueryModel';
import QueryLogRecordModel from '@api/queryLog/QueryLogRecordModel';
import rawTransform from '@api/rawTransform';
import { TableModel } from '@api/tables/TableModel';
import { TeamModel } from '@api/teams/TeamModel';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import { PaginatedResponse } from '@models/PaginatedResponse';

import TimeModel from './TimeModel';
import TotalModel from './TotalModel';
import WarehouseModel from './WarehouseModel';

export const costAnalysisCacheKeys = {
  costAnalysis: ['costAnalysis'],
  dashboards: ['costAnalysis', 'dashboards'],
  queries: ['costAnalysis', 'queries'],
  queryLogs: ['costAnalysis', 'queryLogs'],
  tables: ['costAnalysis', 'tables'],
  teams: ['costAnalysis', 'teams'],
  time: ['costAnalysis', 'time'],
  total: ['costAnalysis', 'total'],
  users: ['costAnalysis', 'users'],
  warehouses: ['costAnalysis', 'warehouses'],
};

const fetchCostAnalysisDashboardsSelect = paginatedTransform(DashboardModel);

export interface UseFetchCostAnalysisDashboardsParams {
  end_date?: string;
  start_date?: string;
  warehouses?: string;
}

export const useFetchCostAnalysisDashboards = (
  options?: UseFetchOptions<PaginatedResponse<DashboardModel>>,
) => {
  return useFetch<PaginatedResponse<DashboardModel>>({
    ...options,
    queryKey: [...costAnalysisCacheKeys.dashboards, options?.params],
    select: fetchCostAnalysisDashboardsSelect,
    url: '/cost-analysis/dashboards/',
  });
};

const fetchCostAnalysisQueriesSelect = paginatedTransform(QueryModel);

export interface UseFetchFetchCostAnalysisQueriesParams {
  end_date?: string;
  start_date?: string;
  warehouses?: string;
}

export const useFetchCostAnalysisQueries = (
  options?: UseFetchOptions<PaginatedResponse<QueryModel>, UseFetchFetchCostAnalysisQueriesParams>,
) => {
  return useFetch<PaginatedResponse<QueryModel>>({
    ...options,
    queryKey: [...costAnalysisCacheKeys.queries, options?.params],
    select: fetchCostAnalysisQueriesSelect,
    url: '/cost-analysis/queries/',
  });
};

const fetchCostAnalysisQueryLogsSelect = paginatedTransform(QueryLogRecordModel);

export interface UseFetchCostAnalysisQueryLogsParams {
  day_of_week?: number;
  end_date?: string;
  hour?: number;
  start_date?: string;
  warehouses?: string;
}

export const useFetchCostAnalysisQueryLogs = (
  options?: UseFetchOptions<PaginatedResponse<QueryModel>, UseFetchCostAnalysisQueryLogsParams>,
) => {
  return useFetch<PaginatedResponse<QueryModel>>({
    ...options,
    queryKey: [...costAnalysisCacheKeys.queryLogs, options?.params],
    select: fetchCostAnalysisQueryLogsSelect,
    url: '/cost-analysis/query-logs/',
  });
};

const fetchCostAnalysisTablesSelect = paginatedTransform(TableModel);

export const useFetchCostAnalysisTables = (
  options?: UseFetchOptions<PaginatedResponse<TableModel>>,
) => {
  return useFetch<PaginatedResponse<TableModel>>({
    ...options,
    queryKey: [...costAnalysisCacheKeys.tables, options?.params],
    select: fetchCostAnalysisTablesSelect,
    url: '/cost-analysis/tables/',
  });
};

const fetchCostAnalysisTeamsSelect = rawTransform(TeamModel);

export const useFetchCostAnalysisTeams = (options?: UseFetchOptions<Array<TeamModel>>) => {
  return useFetch<Array<TeamModel>>({
    ...options,
    queryKey: [...costAnalysisCacheKeys.teams, options?.params],
    select: fetchCostAnalysisTeamsSelect,
    url: '/cost-analysis/teams/',
  });
};
const fetchCostAnalysisTimeSelect = rawTransform(TimeModel);

export const useFetchCostAnalysisTime = (options?: UseFetchOptions<Array<TimeModel>>) => {
  return useFetch<Array<TimeModel>>({
    ...options,
    queryKey: [...costAnalysisCacheKeys.total, options?.params],
    select: fetchCostAnalysisTimeSelect,
    url: '/cost-analysis/time/',
  });
};

const fetchCostAnalysisTotalSelect = rawTransform(TotalModel);

export const useFetchCostAnalysisTotal = (options?: UseFetchOptions<TotalModel>) => {
  return useFetch<TotalModel>({
    ...options,
    queryKey: [...costAnalysisCacheKeys.total, options?.params],
    select: fetchCostAnalysisTotalSelect,
    url: '/cost-analysis/total/',
  });
};

const fetchCostAnalysisUsersSelect = paginatedTransform(DsUserModel);

export const useFetchCostAnalysisUsers = (
  options?: UseFetchOptions<PaginatedResponse<DsUserModel>>,
) => {
  return useFetch<PaginatedResponse<DsUserModel>>({
    ...options,
    queryKey: [...costAnalysisCacheKeys.users, options?.params],
    select: fetchCostAnalysisUsersSelect,
    url: '/cost-analysis/users/',
  });
};

const fetchCostAnalysisWarehousesSelect = paginatedTransform(WarehouseModel);

export const useFetchCostAnalysisWarehouses = (
  options?: UseFetchOptions<PaginatedResponse<WarehouseModel>>,
) => {
  return useFetch<PaginatedResponse<WarehouseModel>>({
    ...options,
    queryKey: [...costAnalysisCacheKeys.warehouses, options?.params],
    select: fetchCostAnalysisWarehousesSelect,
    url: '/cost-analysis/warehouses/',
  });
};
