import React from 'react';

import useCollapseAllNodes from '@components/Explore.v1/useCollapseAllNodes';
import { useExplore } from '@components/Explore.v1/useExplore';

import { StyledCollapseAllNodesButton } from './CollapseAllNodesButton.styles';

const CollapseAllNodesButton = () => {
  const { isCollapseAllButtonEnabled } = useExplore();
  const { collapseAllNodes } = useCollapseAllNodes();

  const handleCollapseAllNodes = () => {
    collapseAllNodes();
  };

  return (
    <StyledCollapseAllNodesButton
      disabled={!isCollapseAllButtonEnabled}
      onClick={handleCollapseAllNodes}
      variant="outlined"
    >
      Collapse Nodes
    </StyledCollapseAllNodesButton>
  );
};

export default CollapseAllNodesButton;
