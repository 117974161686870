import styled from '@emotion/styled';
import type { Property } from 'csstype';

import Resizable from '@components/Resizable';
import Modal, { ModalHeader } from '@components/UI/Modal';

export const SideBarContainer = styled(Resizable)<{
  isVisible: boolean;
}>`
  border-right: 1px solid #cacfd6;
  position: relative;
  background: ${({ isVisible }) => (isVisible ? 'transparent' : '#f2f5f9')};
`;

export const ToggleSidebarButton = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
  display: flex;
  height: 1.75rem;
  justify-content: center;
  margin: 0;
  position: absolute;
  right: 0;
  top: ${({ theme }) => theme.space(2.5)};
  transform: translateX(50%);
  width: 1.75rem;
  z-index: 1;
`;

export const StyledExploreModal = styled(Modal)``;

StyledExploreModal.defaultProps = {
  compHeight: 'calc(100% - 5rem)',
  compWidth: 'calc(100% - 5rem)',
  open: true,
  overflow: 'hidden',
  size: 'unset',
  useContext: true,
};

export const StyledExploreModalHeader = styled(ModalHeader)``;

StyledExploreModalHeader.defaultProps = {
  borderBottom: '1px solid transparent',
};

export const LoadingText = styled.div({
  alignItems: 'center',
  display: 'flex',
  fontSize: '1.5rem',
  height: '80%',
  justifyContent: 'center',
});

export const LineageOptionsPane = styled.div<{ alignSelf?: Property.AlignSelf }>`
  display: flex;
  margin-left: ${({ theme }) => theme.space(2)};
  gap: ${({ theme }) => theme.space(0.5, 2)};
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
`;

export const LineageOption = styled.div({
  display: 'flex',
});
