import styled from '@emotion/styled';

export const StyledDatasourceTabs = styled.div`
  min-width: fit-content;
  height: 100%;
  z-index: 1;
  background-color: #fff;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  .result-count {
    margin: ${({ theme }) => theme.space(0, 2)};
    color: ${({ theme }) => theme.colors.v1.gray[400]};
    display: none;
    white-space: nowrap;
    align-self: center;
    text-align: right;
    width: 100%;

    ${({ theme }) => theme.media.up('lg')`
      display: block;
    `}
  }
`;

export const Tab = styled.div<{ active?: boolean; border?: boolean }>`
  height: 100%;
  min-width: 4rem;
  padding: ${({ theme }) => theme.space(0, 1)};
  border-top: 3px solid #fff;
  border-bottom: ${({ active, border }) =>
    active && border ? '3px solid #0c3476' : '3px solid #fff'};
  color: ${({ active }) => (active ? '#0c3476' : '#6f747c')};
  font-weight: ${({ active, theme }) => (active ? theme.typography.fontWeights.bold : '')};
  font-size: 1rem;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  flex-shrink: 0;
`;

export const StyledFacetContainerV1 = styled.div<{ active?: boolean; border?: boolean }>`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: #f2f5f9;
  flex-wrap: wrap;
  height: 60px;
`;
