import { QuickSearchIndexFilter } from '@api/search/types';

/**
 * TODO: index names should come from the BE
 */
export const filterIndex = [
  'aws_s3_buckets',
  'aws_s3_objects',
  'columns',
  'comments',
  'dashboard_elements',
  'data-sources',
  'data_studio_dashboards',
  'data_studio_folders',
  'databases',
  'databricks_folders',
  'databricks_notebooks',
  'dbt-tests',
  'documents',
  'ds-users',
  'explore_fields',
  'explores',
  'looker_dashboards',
  'looker_folders',
  'looker_groups',
  'looker_looks',
  'lookml_fields',
  'lookml_models',
  'lookml_views',
  'metabase_cards',
  'metabase_dashboards',
  'metabase_folders',
  'metrics',
  'mode_reports',
  'mode_spaces',
  'periscope_dashboards',
  'periscope_folders',
  'power_bi_columns',
  'power_bi_dashboards',
  'power_bi_datasets',
  'power_bi_dataflows',
  'power_bi_folders',
  'power_bi_pages',
  'power_bi_reports',
  'power_bi_tiles',
  'quicksight_bicolumns',
  'quicksight_dashboards',
  'quicksight_bidatasets',
  'quicksight_folders',
  'quicksight_reports',
  'quicksight_bidashboardelements',
  'schemata',
  'sigma_dashboards',
  'sigma_folders',
  'tableau_data_sources',
  'tableau_fields',
  'tableau_projects',
  'tableau_views',
  'tableau_workbooks',
  'tables',
  'tags',
  'teams',
  'terms',
  'thoughtspot_answers',
  'thoughtspot_columns',
  'thoughtspot_data_folders',
  'thoughtspot_liveboards',
  'thoughtspot_tables',
  'thoughtspot_views',
  'thoughtspot_worksheets',
  'users',
] as const;

export type FilterIndex = (typeof filterIndex)[number];

export const defaultMentionFilters: QuickSearchIndexFilter[] = [
  {
    filters: [],
    indexes: [
      'columns',
      'dashboard_elements',
      'data-sources',
      'data_studio_dashboards',
      'databricks_folders',
      'databricks_notebooks',
      'documents',
      'explore_fields',
      'explores',
      'looker_dashboards',
      'looker_looks',
      'lookml_models',
      'lookml_views',
      'metabase_cards',
      'metabase_dashboards',
      'metrics',
      'mode_reports',
      'periscope_dashboards',
      'power_bi_columns',
      'power_bi_dashboards',
      'power_bi_datasets',
      'power_bi_dataflows',
      'power_bi_folders',
      'power_bi_pages',
      'power_bi_reports',
      'power_bi_tiles',
      'quicksight_bicolumns',
      'quicksight_dashboards',
      'quicksight_bidatasets',
      'quicksight_folders',
      'quicksight_reports',
      'quicksight_bidashboardelements',
      'schemata',
      'sigma_dashboards',
      'tableau_data_sources',
      'tableau_fields',
      'tableau_views',
      'tableau_workbooks',
      'tables',
      'tags',
      'teams',
      'terms',
      'thoughtspot_answers',
      'thoughtspot_columns',
      'thoughtspot_data_folders',
      'thoughtspot_liveboards',
      'thoughtspot_tables',
      'thoughtspot_views',
      'thoughtspot_worksheets',
      'users',
    ],
  },
];
