import React from 'react';
import { RenderElementProps } from 'slate-react';

import Box from '@components/Box';
import Text from '@components/Text';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';

import { StyledLinkElement } from '../LinkElement.styles';

export const getClickableLink = (link = '') => {
  if (!link) {
    return '#';
  }
  return link.startsWith('http://') || link.startsWith('https://') ? link : `http://${link}`;
};

const LinkElement = ({ attributes, children, element }: RenderElementProps) => {
  const clickableLink = getClickableLink(element.url);
  return (
    <Tooltip
      action
      content={
        <Box compDisplay="flex" onClick={() => window.open(clickableLink, '_blank')} spacing={0.25}>
          <Text
            as="span"
            color={theme.colors.white}
            fontSize="inherit"
            lineBreak="anywhere"
            textDecoration="underline"
            textDecorationHover="underline"
          >
            {clickableLink}
          </Text>
          <Icon color="white" name="open-new-tab" size="1rem" />
        </Box>
      }
      followCursor={false}
      forceDefaultBackground
      interactive
      offset={[-6, 2]}
      placement="bottom-start"
      {...attributes}
    >
      <StyledLinkElement href={clickableLink} rel="noreferrer" target="_blank">
        <span>{children}</span>
      </StyledLinkElement>
    </Tooltip>
  );
};

export default LinkElement;
