import styled from '@emotion/styled';
import type { ResizableProps } from 're-resizable';
import { Resizable } from 're-resizable';

export const StyledResizable = styled(Resizable as any)<ResizableProps>`
  .resizable-line {
    &:hover,
    &:active {
      background-color: #4c288f;
    }
  }
`;
