"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExcludeMetadata = void 0;
class ExcludeMetadata {
    constructor(target, propertyName, options) {
        this.target = target;
        this.propertyName = propertyName;
        this.options = options;
    }
}
exports.ExcludeMetadata = ExcludeMetadata;
