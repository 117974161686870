import { DataTypesConfig } from '@configs/dataTypes/types';

export enum GroupNodeType {
  bi = 'bi',
  dwh = 'dwh',
}

export interface GroupNodeTypeConfigItem {
  hideColumns?: boolean;
  type: GroupNodeType;
}

export const NODE_TYPE_CONFIG: DataTypesConfig<GroupNodeTypeConfigItem> = {
  bigquery: { default: { type: GroupNodeType.dwh } },
  data_studio: { default: { type: GroupNodeType.bi } },
  databricks: {
    default: { type: GroupNodeType.dwh },
    notebook: { default: { hideColumns: true, type: GroupNodeType.bi } },
  },
  dbt: { default: { type: GroupNodeType.dwh } },
  glue: { default: { type: GroupNodeType.dwh } },
  looker: { default: { type: GroupNodeType.bi } },
  metabase: { default: { type: GroupNodeType.bi } },
  mode: { default: { type: GroupNodeType.bi } },
  mssql: { default: { type: GroupNodeType.dwh } },
  mysql: { default: { type: GroupNodeType.dwh } },
  oracle: { default: { type: GroupNodeType.dwh } },
  periscope: { default: { type: GroupNodeType.bi } },
  postgres: { default: { type: GroupNodeType.dwh } },
  power_bi: { default: { type: GroupNodeType.bi } },
  quicksight: { default: { type: GroupNodeType.bi } },
  redshift: { default: { type: GroupNodeType.dwh } },
  sigma: { default: { type: GroupNodeType.bi } },
  snowflake: { default: { type: GroupNodeType.dwh } },
  tableau: { default: { type: GroupNodeType.bi } },
  thoughtspot: { default: { type: GroupNodeType.bi } },
};
