import React from 'react';

import { useFetchActivity } from '@api/activity';
import Box from '@components/Box';
import { ActivityItem } from '@components/RecentActivity/RecentActivity';
import Skeleton from '@components/Skeleton';
import Text, { defaultParagraphStyles } from '@components/Text';
import { useModal } from '@context/Modal';
import theme from '@styles/theme';
import stripSpaces from '@utils/stripSpaces';

import PageHistoryModal from './PageHistoryModal';

const HISTORY_TO_SHOW = 4;

const query = stripSpaces(`{
	guid,
  activity_type,
  generated_by,
  performed_on,
  message,
  user {
    guid,
    email,
    first_name,
    last_name,
    avatar,
    is_active,
    team {
      name
    }
  },
  target {
    object_type,
    obj {
      -breadcrumbs,
      -downstream_objects_counts,
      -popularity,
      -search_name,
      -tagged_items,
      -upstream_objects_counts
    }
  },
  cached_target_name,
  cached_target_data_type,
  comment,
  field,
  data
}`);

const activityParams = {
  generated_by: 'user',
  include_child_activities: false,
  order: '-performed_on',
  page_size: 5,
  query,
  types: 'create,update,add,delete,comment,tagged',
};

interface DocumentHistoryProps {
  id?: string;
  objectType?: string;
  teams?: string;
}

const PageHistory: React.FC<DocumentHistoryProps> = ({ id, objectType, teams }) => {
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();

  const { data, isLoading } = useFetchActivity({
    enabled: Boolean(id || teams),
    params: {
      ...activityParams,
      generated_by: 'user,ingestion',
      include_child_activities: objectType === 'table',
      targets: id,
      teams,
    },
  });

  const historyToShowMore = data && data?.results?.length > HISTORY_TO_SHOW;

  return (
    <Box>
      <Box compDisplay="inline-flex" compWidth="100%" justifyContent="space-between" mb={2}>
        <Text
          as="h3"
          color="gray.700"
          fontSize={theme.typography.h4.fontSize}
          fontWeight="medium"
          mb={0}
        >
          History
        </Text>
        {historyToShowMore && (
          <Text
            as="span"
            {...defaultParagraphStyles}
            color="gray.400"
            cursor="pointer"
            fontSize="13px"
            onClick={() => openModal(MODAL_IDS.pageHistory)}
            textDecorationHover="underline"
          >
            Show more
          </Text>
        )}
      </Box>
      <Box color={theme.colors.v1.gray[900]}>
        {isLoading && (
          <Skeleton animate={false} borderRadius="sm" compWidth="100%" gap={0.5} p={2}>
            <Skeleton compWidth="100%" grayShade={300} variant="text" />
            <Skeleton compWidth="80%" grayShade={300} variant="text" />
          </Skeleton>
        )}
        {!isLoading && data?.results?.length === 0 && (
          <Text as="div" color="gray.400" fontSize={theme.typography.fontSizes.body1}>
            None detected yet.
          </Text>
        )}
        {data?.results?.slice(0, HISTORY_TO_SHOW)?.map((activity) => (
          <ActivityItem key={activity.guid} activity={activity} />
        ))}
      </Box>
      {checkModalOpened(MODAL_IDS.pageHistory) && (
        <PageHistoryModal
          id={id}
          objectType={objectType}
          onClose={() => closeModal(MODAL_IDS.pageHistory)}
          teams={teams}
        />
      )}
    </Box>
  );
};

export default PageHistory;
