import React from 'react';

import { useTeamsSections } from '@components/AppMainSidebar/TeamsMenu';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import { GridContainer } from '@components/Grid';
import MetadataGrid from '@components/MetadataGrid';
import MetadataGridCard from '@components/MetadataGridCard';
import PageHeaderBar from '@components/PageHeaderBar';
import Avatar from '@components/UI/Avatar';
import AvatarGroup from '@components/UI/AvatarGroup';
import Icon from '@components/UI/Icon';
import theme from '@styles/theme';
import MetadataDecorator from '@utils/MetadataDecorator';
import pluralize from '@utils/pluralize';
import { urlFor } from '@utils/routing';

const TeamsPage: React.FC = () => {
  const { isLoading, sections } = useTeamsSections();

  return (
    <>
      <MetadataDecorator title="Teams" />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <PageHeaderBar title="Teams" />
        {sections.map((section) => (
          <Box key={section.key}>
            <Box fontSize={theme.typography.h2.fontSize} fontWeight="bold" mb={2.5} mt={4}>
              {section.title}
            </Box>
            {isLoading && <CircularLoader centered compSize={4} p={5} />}
            {!isLoading && section.isEmpty && <Box>{section.emptyMessage}</Box>}
            {!isLoading && !section.isEmpty && (
              <MetadataGrid data={section.teams} isLoading={isLoading}>
                {(item) => (
                  <MetadataGridCard
                    data-testid={`tag-card-${item.guid}`}
                    description={item.description}
                    footer={
                      <Box>
                        <Box
                          alignItems="center"
                          color={theme.colors.gray[600]}
                          compDisplay="flex"
                          gap={0.5}
                          mb={1.5}
                        >
                          <Icon color="currentColor" name="team" size="16px" />
                          {pluralize(item?.membersCount, 'Member')}
                        </Box>
                        <AvatarGroup
                          compHeight="24px"
                          items={item.sampleUsers?.map((user) => user.mappedAvatar)}
                          size="24px"
                        />
                      </Box>
                    }
                    icon={<Avatar {...item.mappedAvatar} size="24px" />}
                    iconColor={item.color}
                    minHeight="180px"
                    name={item.name}
                    url={urlFor(item)}
                  />
                )}
              </MetadataGrid>
            )}
          </Box>
        ))}
      </GridContainer>
    </>
  );
};

export default TeamsPage;
