import React from 'react';
import { Redirect } from 'react-router-dom';

import client from '@api';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

const Logout: React.FC = () => {
  const segment = useSegmentContext();

  client.logout();
  segment?.track(SegmentTrackEventName.SignedOut);

  return <Redirect to="/login" />;
};

export default Logout;
