import React, { useEffect, useMemo, useRef, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';

import { useFetchSearch, useFetchSearchSuggestions } from '@api/search';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import NotFoundError from '@components/Error/NotFoundError';
import FiltersSidebar from '@components/FiltersSidebar/FiltersSidebar';
import FullSearchResultTable from '@components/Table/FullSearchResultTable';
import TitleHeader from '@components/Title/TitleHeader';
import Icon from '@components/UI/Icon';
import { useUserContext } from '@context/User';
import { StyledTablesPage } from '@pages/TablesPage/TablesPage.styles';
import { Filter } from '@utils';
import MetadataDecorator from '@utils/MetadataDecorator';

import { StyledGridContainer } from './SearchResultsPage.styles';

const searchResultsRequestConfig = {
  include: undefined,
  order: '_score',
  page: 1,
  page_size: 50,
  tags_search: true,
};

const sortConfig: { [key: string]: string } = {
  _score: '_score',
  description: 'description',
  name: 'name',
  popularity: 'popularity',
};

const SearchResultsPage: React.FC<RouteComponentProps> = React.memo(() => {
  const initialSearchFacets = useRef<{ [key: string]: number } | undefined>(undefined);
  const history = useHistory();
  const { search: searchLocation } = useLocation();
  const params = new URLSearchParams(searchLocation);
  const query = params.get('q') || undefined;
  const [currentQuery, setCurrentQuery] = useState(query);
  const blockFuzzySearch = params.get('fuzzy') === 'false';
  const defaultConfig = cloneDeep(searchResultsRequestConfig);
  const { organization } = useUserContext();
  const config = {
    ...defaultConfig,
    custom_attributes_search: organization?.settings?.useCustomAttributesSearch,
    no_fuzzy: params.get('fuzzy') !== 'true',
  };
  if (!query) history.push('/');

  const FilterService = Filter.useUpdateFilters(config, {}, sortConfig);
  const { filter } = FilterService;

  useEffect(() => {
    if (query !== currentQuery) {
      // If query is updated we must reset the excluded indexes manually.
      FilterService.updateNoFuzzyAndInclude(true, undefined);
      setCurrentQuery(query);
    }
  }, [query, currentQuery, filter, setCurrentQuery]);

  const effectiveConfig = useMemo(() => {
    return { params: Filter.setParams({ q: currentQuery, ...filter }) };
  }, [filter, currentQuery]);

  const { data: searchResults, error } = useFetchSearch({
    ...effectiveConfig,
    onSuccess: (response) => {
      if (!initialSearchFacets.current) {
        initialSearchFacets.current = response.facets;
      }
    },
  });

  const { data: searchSuggestions } = useFetchSearchSuggestions({
    ...effectiveConfig,
    enabled: !filter.no_fuzzy,
  });

  useEffect(() => {
    const noAppliedFilters = !(
      filter.category_tags ||
      filter.status_tags ||
      filter.dbt_tags ||
      filter.popularity ||
      filter.include
    );
    if (
      !blockFuzzySearch &&
      filter.no_fuzzy &&
      noAppliedFilters &&
      searchResults?.data?.length === 0
    ) {
      FilterService.updateNoFuzzy(false);
    }
  }, [searchResults?.data]);

  useEffect(() => {
    initialSearchFacets.current = undefined;
  }, [query]);

  const totalPages =
    searchResults && filter.page_size ? Math.ceil(searchResults.shown / filter.page_size) : 1;

  if (error) {
    return <NotFoundError />;
  }

  if (!searchResults?.data) {
    return (
      <Box alignItems="center" compDisplay="flex" compHeight="100%" justifyContent="center">
        <CircularLoader compSize={7} />
      </Box>
    );
  }

  return (
    <Box compDisplay="flex">
      <MetadataDecorator title={query} />
      <StyledGridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <StyledTablesPage>
          <Box mb={0.5}>
            <TitleHeader style={{ display: 'inline-block' }}>
              <Icon name="search" size="30px" />
              <span className="title big">{query}</span>
              <span className="subtitle">
                {filter.no_fuzzy
                  ? `${searchResults.total} results (${searchResults.shown} shown)`
                  : '0 results'}
              </span>
            </TitleHeader>
          </Box>
          {!filter.no_fuzzy && (
            <Box mb={0.5}>
              Showing {searchResults.total} search results for:&nbsp;
              <strong>{searchSuggestions?.texts.join(', ')}</strong>
            </Box>
          )}
          <Box mb={0.5}>
            <FullSearchResultTable
              data={searchResults.data}
              filterService={FilterService}
              query={query}
              searchSuggestions={searchSuggestions?.texts}
              totalPages={totalPages}
            />
          </Box>
        </StyledTablesPage>
      </StyledGridContainer>
      <React.Suspense fallback={<CircularLoader centered compSize={3} />}>
        <FiltersSidebar
          filterService={FilterService}
          isCategoryTags
          isDbtTags
          isPopularity
          isSearchFilters
          isStatusTags
          query={query}
          searchFacets={initialSearchFacets.current ?? {}}
        />
      </React.Suspense>
    </Box>
  );
});

export default SearchResultsPage;
