import React from 'react';

import { DsUserModel } from '@api/dsusers/DsUserModel';
import { GridContainer } from '@components/Grid';
import Profile from '@components/Profile/Profile';
import type { TabRoutesConfig } from '@components/TabsRouter';
import TabsRouter from '@components/TabsRouter';
import { DataSourceTypesType } from '@models/DataSourceCredentials';
import OwnedDashboardsSubTab from '@pages/ProfilePage/Tabs/OwnedSubTabs/OwnedDashboardsSubTab';
import MetadataDecorator from '@utils/MetadataDecorator';

import MostQueriedTab from './Tabs/MostQueriedTab';

interface BIDSUserPageProps {
  dataSourceType?: DataSourceTypesType;
  dsuser: DsUserModel;
  guid: string;
}

const BIDSUserPage: React.FC<BIDSUserPageProps> = ({ dataSourceType, dsuser, guid }) => {
  const dsuserType = dsuser?.dataSource?.type;

  const tabComponentProps = {
    dsuserType,
    guid,
  };

  const tabsRouterConfig: TabRoutesConfig[] = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <MostQueriedTab {...tabComponentProps} dataSourceType={dataSourceType} />,
      default: true,
      label: dsuserType === 'tableau' ? 'Most Viewed' : 'Most Queried',
      route: dsuserType === 'tableau' ? '/most-viewed' : '/most-queried',
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => <OwnedDashboardsSubTab dsuserCreatedBy={guid} dsuserType={dsuserType} />,
      label: 'Owns',
      route: '/owns',
    },
  ];

  return (
    <>
      <MetadataDecorator title={dsuser?.fullName} />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        {dsuser && <Profile connectedAccounts={[dsuser]} item={dsuser} />}
        <TabsRouter config={tabsRouterConfig} />
      </GridContainer>
    </>
  );
};

export default BIDSUserPage;
