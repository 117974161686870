import React, { useCallback, useEffect, useRef } from 'react';
import uniqBy from 'lodash/uniqBy';
import { useSetRecoilState } from 'recoil';

import { useFetchDocuments } from '@api/documents';
import { DocumentModel } from '@api/documents/DocumentModel';
import invalidateCache from '@api/invalidateCache';
import { metadataType } from '@atoms/MetadataType';
import Box from '@components/Box';
import BulkButtons from '@components/BulkButtons';
import NotFoundError from '@components/Error/NotFoundError';
import DocumentsTable from '@components/Table/DocumentsTable';
import type { DocumentsTableProps } from '@components/Table/DocumentsTable/DocumentsTable';
import { useModal } from '@context/Modal';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import { StyledTablesPage } from '@pages/TablesPage/TablesPage.styles';
import { Filter } from '@utils/index';

import AddNewDoc from '../AddNewDoc/AddNewDoc';
import DeleteDocumentsModal from '../DeleteDocumentsModal';
import { defaultFilterOptions, searchConfig, sortConfig } from '../docsRequestConfigs';

interface BaseDocsTabProps extends Pick<DocumentsTableProps, 'variant'> {}

export const isSearchResult = (filter: Filter.FilterOptions) =>
  filter.search_name ??
  filter.search_description ??
  filter.search_tags ??
  filter.search_technical_owner ??
  filter.search_business_owner;

const BaseDocsTab: React.FC<BaseDocsTabProps> = ({ variant }) => {
  const allFetchedDocuments = useRef<DocumentModel[]>([]);
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();
  const setMetadataType = useSetRecoilState(metadataType);
  const { selected, setSelected, tagsCounts } = useBulkEditSelected<DocumentModel>();
  const { isPbac, permissions } = useObjectPermissionsContext();
  const selectedEditableItems = selected.items.filter((item) =>
    isPbac ? permissions[item.guid]?.isEditable : true,
  );
  const filterService = Filter.useUpdateFilters(
    { ...defaultFilterOptions, doc_type: variant },
    searchConfig,
    sortConfig,
    '',
  );
  const { filter } = filterService;

  const { data, isError } = useFetchDocuments({
    params: Filter.setParams(filter),
  });

  const refetchDocuments = () => invalidateCache((keys) => [keys.documents.all]);

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;
  useEffect(() => {
    setMetadataType('documents');
  }, [setMetadataType]);

  useEffect(() => {
    allFetchedDocuments.current = uniqBy(
      [...(data?.results ?? []), ...allFetchedDocuments.current],
      'guid',
    );
  }, [data?.results]);

  const handleSetSelectRowIds = useCallback(
    (selectedRowIds) => {
      setSelected(allFetchedDocuments.current?.filter((i) => selectedRowIds?.[i.guid]));
    },
    [setSelected],
  );

  const resetDataAfterBulkChange = () => {
    refetchDocuments();
    handleSetSelectRowIds({});
  };

  /**
   * @todo Implement !organization?.settings?.useDocuments.
   */
  if (isError) {
    return <NotFoundError />;
  }

  if (data?.results.length === 0 && !isSearchResult(filter)) {
    return (
      <Box
        alignItems="start"
        compDisplay="flex"
        compHeight="100%"
        compWidth="100%"
        justifyContent="center"
      >
        <AddNewDoc />
      </Box>
    );
  }

  return (
    <>
      <Box>
        <StyledTablesPage>
          <BulkButtons
            canDelete
            canEditOwner
            canEditTags
            isDataSourceEditable
            onEdit={resetDataAfterBulkChange}
            selectedEditableItems={selectedEditableItems}
            selectedItems={selected.items}
            showText
            tagsCounts={tagsCounts}
            toggleDelete={() => openModal(MODAL_IDS.deleteDocumentsBaseDocsTab)}
          />
          <Box mb={0.5}>
            <DocumentsTable
              data={data?.results}
              filterService={filterService}
              isDataSourceEditable
              itemCount={data?.count || 0}
              selectedRowIds={selected.ids}
              setSelectedRowIds={handleSetSelectRowIds}
              totalPages={totalPages}
              variant={variant}
            />
          </Box>
        </StyledTablesPage>
      </Box>
      {checkModalOpened(MODAL_IDS.deleteDocumentsBaseDocsTab) && (
        <DeleteDocumentsModal
          onClose={() => closeModal(MODAL_IDS.deleteDocumentsBaseDocsTab)}
          reloadData={resetDataAfterBulkChange}
          selectedItems={selectedEditableItems}
        />
      )}
    </>
  );
};

export default BaseDocsTab;
