import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Cell } from 'react-table';

import { LookerExploreFieldModel } from '@api/explores/LookerExploreFieldModel';
import ExcerptText from '@components/ExcerptText';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import EditableTaggedItemCell from '@components/Table/Cells/EditableTaggedItemCell';
import LinkedCell, { LinkItem } from '@components/Table/Cells/LinkedCell';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { useUserContext } from '@context/User';
import useCustomAttributesColumns from '@hooks/useCustomAttributesColumns';
import BIColumnPage from '@pages/BIColumnPage';
import { Filter } from '@utils';
import { getPopularityNormalized } from '@utils/popularity';
import { getObjectTypeFromGuid, makeUrl } from '@utils/routing';

import FieldNameTableCell from './FieldNameTableCell';

const initialSortState = [
  {
    desc: true,
    id: 'popularity',
  },
];

interface ExploreFieldTableProps {
  data?: LookerExploreFieldModel[];
  filterService: Filter.FilterServiceInterface;
  isDataSourceEditable: boolean;
  isDimensionSelect?: boolean;
  itemCount?: number;
  selectedRowIds?: { [guid: string]: boolean };
  setSelectedRowIds?: React.Dispatch<React.SetStateAction<{}>>;
  stickyHeader?: boolean;
  totalPages?: number;
}

const ExploreFieldTable: React.FC<ExploreFieldTableProps> = ({
  data,
  filterService,
  isDataSourceEditable,
  isDimensionSelect,
  itemCount = 0,
  selectedRowIds,
  setSelectedRowIds,
  stickyHeader,
  totalPages,
}) => {
  const history = useHistory();
  const { organization } = useUserContext();
  const { guid, itemId } = useParams<{ guid: string; itemId: string }>();
  const [isShowFilter, setShowFilter] = useState(false);
  const [currItem, setCurrItem] = useState<string | undefined>(itemId);
  const toggleFilter = React.useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);
  const customAttributesAssetType = 'looker,explorefield,';

  const { customAttributesColumns } = useCustomAttributesColumns({
    assetType: customAttributesAssetType,
    isDataSourceEditable,
    tableData: data ?? [],
  });

  const getHiddenColumns = () => {
    if (isDimensionSelect) {
      return ['tags', 'view', 'description'];
    }
    return [];
  };

  const { changePage, filter, search, sort } = filterService;

  const columns: ColumnConfig<LookerExploreFieldModel>[] = React.useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ column, row: { original }, state }: Cell<LookerExploreFieldModel>) => (
          <LinkedCell
            column={column}
            item={original.field.view as LinkItem}
            showIcon
            showNameTooltip
            state={state}
          />
        ),
        Header: 'View',
        accessor: 'viewLabel',
        id: 'view',
        width: '110%',
      },
      {
        Cell: (props: Cell<LookerExploreFieldModel>) => {
          const { row } = props;
          const item = row.original;

          return (
            <FieldNameTableCell
              {...props}
              field={item.field}
              fullName={item.fullName}
              guid={item.guid}
              isMentioned={Boolean(item?.isMention ?? item.field?.isMention)}
              isMetric={Boolean(item?.isMetric ?? item.field?.isMetric)}
              noLink={false}
              onClick={() => {
                history.push(makeUrl(item.objectType, item.guid, item.parentGuid));
                setCurrItem(item.guid);
              }}
              tooltipText={item.field.name}
            />
          );
        },
        Header: isDimensionSelect ? `Name (${itemCount})` : `Field (${itemCount})`,
        accessor: (d) => d.field?.name,
        disableHiding: true,
        id: 'name',
        width: isDimensionSelect ? '165%' : '145%',
      },
      {
        Cell: (props: Cell<LookerExploreFieldModel>) => {
          const { row } = props;
          const field = row.original;

          if (isDimensionSelect) {
            return <ExcerptText value={field.description || null} />;
          }

          return (
            <DescriptionCell
              {...props}
              aiDescription={field.aiDescription}
              dataSourceType={field.field.dataTypes?.dataSourceType}
              description={field.description}
              descriptionSource={field.descriptionSource}
              guid={field.field.guid}
              ingestedDescription={field.ingestedDescription}
              isDataSourceEditable={isDataSourceEditable}
              name={field.name}
              parentGuid={field?.parentGuid}
              richtextDescription={field.richtextDescription}
              suggestedDescription={field.suggestedDescription}
              suggestedDescriptionSource={field?.suggestedDescriptionSource}
              suggestedDescriptionSourceObj={field?.suggestedDescriptionSourceObject?.obj}
              truncateDisabled={!organization?.settings?.useShowMoreButton}
              userDescription={field.userDescription}
            />
          );
        },
        Header: 'Description',
        accessor: (d) => d.description,
        id: 'description',
        width: '146.5%',
      },
      ...customAttributesColumns,
      {
        Cell: (props: Cell<LookerExploreFieldModel>) => {
          const { row } = props;
          const field = row.original;

          return (
            <EditableTaggedItemCell
              {...props}
              isDataSourceEditable={isDataSourceEditable}
              obj={field}
            />
          );
        },
        Header: 'Tags',
        accessor: (d) => d.taggedItems,
        disableSortBy: true,
        id: 'tags',
        width: '120%',
      },
      {
        Cell: (props: Cell<LookerExploreFieldModel>) => {
          const { row } = props;
          const field = row.original;
          return <PopularityCell {...props} popularity={field.popularity} />;
        },
        Header: PopularityCellHeader,
        accessor: (d) => getPopularityNormalized(d?.popularity?.popularity),
        disableFilters: true,
        disableResizing: true,
        id: 'popularity',
        sortDescFirst: true,
        width: 120,
      },
    ],
    [isDataSourceEditable, isDimensionSelect, itemCount, customAttributesColumns],
  );

  const getRowId = React.useCallback((row: Partial<LookerExploreFieldModel>) => row.guid!, []);

  return (
    <>
      <TableStyled>
        <Table
          basic="very"
          changePage={changePage}
          className="table-full"
          columns={columns}
          compact
          data={data || []}
          disableColumnFiltering={isDimensionSelect}
          disablePagination={isDimensionSelect}
          disableRowSelect={!isDimensionSelect}
          getRowId={getRowId}
          initialState={{
            hiddenColumns: getHiddenColumns(),
            pageIndex: filter.page ? filter.page - 1 : 0,
            selectedRowIds,
            sortBy: initialSortState,
          }}
          loading={data === undefined}
          manualFilters
          manualPagination
          manualSortBy
          selectable
          setFilters={search}
          setSelectedRowIds={setSelectedRowIds}
          setSortBy={sort}
          showFilter={isShowFilter}
          sortable
          stickyHeader={stickyHeader}
          toggleFilter={toggleFilter}
          totalPages={totalPages}
          unstackable
        />
      </TableStyled>
      {currItem && !isDimensionSelect && (
        <BIColumnPage
          guid={currItem}
          isDataSourceEditable={isDataSourceEditable}
          onClose={() => {
            const type = getObjectTypeFromGuid(guid);
            if (type) {
              const url = makeUrl(type, guid);
              history.push(`${url}/fields`);
            }
            setCurrItem(undefined);
          }}
        />
      )}
    </>
  );
};

export default React.memo<ExploreFieldTableProps>(ExploreFieldTable);
