import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as CSS from 'csstype';
import type { DetailedHTMLProps, TextareaHTMLAttributes } from 'react';

import getColor, { GetColorProps } from '@styles/mixins/getColor';
import type { MakeSpaceProps } from '@styles/mixins/makeSpace';
import makeSpace from '@styles/mixins/makeSpace';
import rootTheme from '@styles/theme';
import type { FontSize, FontWeight } from '@styles/theme/typography';

export type TextareaState = 'idle' | 'error' | 'disabled';

const variants: Record<TextareaState, SerializedStyles> = {
  disabled: css`
    opacity: 0.5;
    border-color: ${rootTheme.colors.gray[200]};
  `,
  error: css`
    border-color: ${rootTheme.colors.error};
  `,
  idle: css`
    border-color: ${rootTheme.colors.gray[200]};
    &:focus,
    &:focus-within {
      border-color: ${rootTheme.colors.inputBorderLightBlue};
    }
  `,
};

const outlineVariants: Record<TextareaState, SerializedStyles> = {
  disabled: css`
    border-color: ${rootTheme.colors.gray[200]};
    opacity: 0.5;
  `,
  error: css`
    border-color: ${rootTheme.colors.error};
  `,
  idle: css`
    border-color: ${rootTheme.colors.gray[200]};
    &:focus,
    &:focus-within {
      border-color: ${rootTheme.colors.inlineInputOutline};
    }
  `,
};

export interface StyledTextareaProps extends MakeSpaceProps {
  borderWidth?: number;
  fontSize?: FontSize;
  outlined?: boolean;
  state?: TextareaState;
}

export const StyledTextarea = styled.div<StyledTextareaProps>`
  background-color: white;
  border-radius: ${({ theme }) => theme.radius.default};
  border-style: solid;
  border-width: ${({ borderWidth }) => `${borderWidth}px`};
  display: flex;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  font-size: ${({ fontSize = 'body2', theme }) => theme.typography.fontSizes[fontSize]};
  position: relative;
  width: 100%;
  transition: border-color 100ms ease, opacity 100ms ease;

  ${({ outlined, state = 'idle' }) => (outlined ? outlineVariants[state] : variants[state])};
  ${({ theme, ...props }) => makeSpace(theme, props)};
`;

export interface StyledTextareaInputProps
  extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  maxHeight?: string;
  placeholderColor?: GetColorProps;
  placeholderFontWeight?: FontWeight;
  resize?: CSS.Property.Resize;
}

export const StyledTextareaInput = styled.textarea<StyledTextareaInputProps>`
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  background: none;
  border: none;
  color: inherit;
  display: block;
  font-size: inherit;
  font-family: inherit;
  letter-spacing: inherit;
  margin: 0;
  outline: none;
  min-height: 2.5em;
  min-width: 100%;
  max-height: ${({ maxHeight }) => maxHeight};
  padding: ${({ theme }) => theme.space(1)};
  resize: ${({ resize = 'both' }) => resize};
  line-height: ${({ theme }) => theme.typography.lineHeights.default};
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::placeholder {
    color: ${({ placeholderColor = 'gray.400' }) => getColor(placeholderColor)};
    font-weight: ${({ placeholderFontWeight = 'regular', theme }) =>
      theme.typography.fontWeights[placeholderFontWeight]};
  }
`;

StyledTextareaInput.defaultProps = {
  rows: 4,
};

const messageVariants: Record<TextareaState, SerializedStyles> = {
  disabled: css`
    color: ${rootTheme.colors.gray[400]};
    opacity: 0.5;
  `,
  error: css`
    color: ${rootTheme.colors.error};
  `,
  idle: css`
    color: ${rootTheme.colors.gray[400]};
  `,
};

interface StyledTextareaMessageProps {
  state?: TextareaState;
}

export const StyledTextareaMessage = styled.div<StyledTextareaMessageProps>`
  font-size: ${({ theme }) => theme.typography.fontSizes.body2};
  padding: ${({ theme }) => theme.space(0.5)};
  pointer-events: none;

  ${({ state = 'idle' }) => messageVariants[state]};
`;
