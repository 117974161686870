import React, { useEffect } from 'react';

import { useDeleteDocuments } from '@api/documents';
import Box from '@components/Box';
import DeleteConfirmationModal from '@components/Modal/DeleteConfirmationModal';
import TableList from '@components/TableList';
import { renderInfoToast } from '@components/Toast';
import { MetadataData } from '@models/MetadataModel';

interface DeleteDocumentsModalProps {
  onClose: () => void;
  reloadData: () => void;
  selectedItems: MetadataData[];
}

const DeleteDocumentsModal: React.FC<DeleteDocumentsModalProps> = ({
  onClose,
  reloadData,
  selectedItems,
}) => {
  const { error, isLoading, isSuccess, mutate } = useDeleteDocuments();

  useEffect(() => {
    if (isSuccess) {
      renderInfoToast('Documents deleted');
      reloadData();
      onClose();
    }
  }, [isSuccess]);

  const handleSubmit = () => {
    const guids = selectedItems.map((item) => item.guid);
    const documentData = { documents: guids };
    mutate(documentData);
  };

  return (
    <DeleteConfirmationModal
      error={error?.message}
      loading={isLoading}
      onClose={onClose}
      onRemove={handleSubmit}
      open
      title="Remove Document"
      useContext
    >
      <Box compDisplay="flex" compWidth="100%" flexDirection="column">
        Are you sure you want to delete this document?
        <TableList items={selectedItems} />
      </Box>
    </DeleteConfirmationModal>
  );
};

export default DeleteDocumentsModal;
