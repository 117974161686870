import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import type { PaginatedResponse } from '@models/PaginatedResponse';

import paginatedTransform from '../paginatedTransform';
import rawTransform from '../rawTransform';

import * as cacheKeys from './cacheKeys';
import IndexModel from './IndexModel';

const fetchIndexesSelect = paginatedTransform(IndexModel);

interface FetchIndexesParams {
  datasources?: string;
  order?: string;
  page?: number;
  page_size?: number;
  query?: string;
  tables?: string;
}

export const useFetchIndexes = (
  options?: UseFetchOptions<PaginatedResponse<IndexModel>, FetchIndexesParams>,
) =>
  useFetch<PaginatedResponse<IndexModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchIndexesSelect,
    url: `/indexes/`,
  });

const fetchIndexSelect = rawTransform(IndexModel);

export const useFetchIndex = (
  id: string,
  options?: UseFetchOptions<IndexModel, FetchIndexesParams>,
) =>
  useFetch<IndexModel>({
    ...options,
    queryKey: [...cacheKeys.index(id), options?.params],
    select: fetchIndexSelect,
    url: `/indexes/${id}/`,
  });
