import styled from '@emotion/styled';

import { GridContainer } from '@components/Grid';
import PageHeaderBar from '@components/PageHeaderBar';

export const StyledTablesPage = styled.div`
  position: relative;

  .selected-counter {
    margin-right: 0.5rem;
  }

  .flex {
    display: flex;
    flex-direction: column;
  }

  .pagination-center {
    align-self: center;
  }
`;

export const StyledLink = styled.a`
  float: right;
`;

export const StyledGridContainer = styled(GridContainer)`
  ${({ theme }) => theme.media.down('md')`
     padding: ${theme.space(2)}
  `}
`;

export const StyledPageHeader = styled(PageHeaderBar)`
  margin-bottom: 0;
`;
