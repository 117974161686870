import paginatedTransform from '@api/paginatedTransform';
import rawTransform from '@api/rawTransform';
import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import useMutation, { UseMutationOptions } from '@hooks/useMutation';
import { MetadataModel } from '@models/MetadataModel';
import { PaginatedResponse } from '@models/PaginatedResponse';

import * as cacheKeys from './cacheKeys';
import { ColumnModel } from './ColumnModel';
import { DownstreamChartsModel } from './DownstreamChartsModel';

type ColumnPaginatedResponse = {
  model_count: number;
  schema_count: number;
  table_count: number;
} & PaginatedResponse<ColumnModel>;

export const fetchColumnsSelect = paginatedTransform(ColumnModel);
export const useFetchColumns = (options?: UseFetchOptions<ColumnPaginatedResponse>) => {
  return useFetch<ColumnPaginatedResponse>({
    ...options,
    queryKey: [...cacheKeys.all, options?.params],
    select: fetchColumnsSelect,
    url: '/columns/',
  });
};

export const fetchColumnSelect = rawTransform(ColumnModel);
export const useFetchColumn = (id: string, options?: UseFetchOptions<ColumnModel>) => {
  return useFetch<ColumnModel>({
    ...options,
    queryKey: cacheKeys.column(id),
    select: fetchColumnSelect,
    url: `/columns/${id}/`,
  });
};

export const usePatchColumn = (id: string, options?: UseMutationOptions<ColumnModel>) => {
  return useMutation<ColumnModel>({
    ...options,
    method: 'PATCH',
    url: `/columns/${id}/`,
  });
};

const fetchSimilarColumnsSelect = paginatedTransform(MetadataModel);
export const useFetchSimilarColumns = (
  id: string,
  options?: UseFetchOptions<PaginatedResponse<MetadataModel>>,
) => {
  return useFetch<PaginatedResponse<MetadataModel>>({
    ...options,
    queryKey: cacheKeys.columnsSimilar(id),
    select: fetchSimilarColumnsSelect,
    url: `/columns/${id}/similar/`,
  });
};

const fetchColumnsDownstreamChartsSelect = rawTransform(DownstreamChartsModel);
export const useFetchColumnsDownstreamCharts = (
  id: string,
  options?: UseFetchOptions<DownstreamChartsModel>,
) => {
  return useFetch<DownstreamChartsModel>({
    ...options,
    queryKey: cacheKeys.columnsDownstreamCharts(id),
    select: fetchColumnsDownstreamChartsSelect,
    url: `/columns/${id}/downstream-charts/`,
  });
};

export const useFetchColumnExportData = (options?: UseFetchOptions) => {
  return useFetch({
    ...options,
    queryKey: [...cacheKeys.exportData, options?.params],
    responseType: 'blob',
    url: `/columns/export/`,
  });
};
