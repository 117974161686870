import React from 'react';

import Box from '@components/Box';
import Message, { errorMessageStyles } from '@components/Message';

interface ErrorMessageProps {
  message?: string;
}

/**
 * @deprecated Use <Alert /> instead.
 */
const ErrorMessage: React.FC<ErrorMessageProps> = ({ message }) => {
  if (!message) {
    return <Box compHeight="1rem" m={0.2} p={0.2} />;
  }

  return (
    <Message m={0.2} p={0.2} {...errorMessageStyles} style={{ width: 'auto' }}>
      {message}
    </Message>
  );
};

export default ErrorMessage;
