import React from 'react';

import invalidateCache from '@api/invalidateCache';
import { SearchModel } from '@api/search/SearchModel';
import type { OwnerType } from '@components/Owners/OwnerItem';
import { Option } from '@components/UI/Select.v1/types';

import { NO_OWNER_OPTION } from './constants';
import OwnerLabel from './OwnerLabel';
import { OwnerDataParamFormatter } from './types';

export const mapOwnerToOption = (user: OwnerType): Option => ({
  avatar: user.mappedAvatar,
  customLabel: <OwnerLabel owner={user} />,
  text: String(user.fullName),
  value: String(user.guid),
});

export const mapSearchToOptions = (searchResults: SearchModel[], currentOptions: Option[]) => {
  const currentOptionsValues = currentOptions.map((option) => option.value);

  const nonExistingSearchResults = searchResults.filter(
    (result) => !currentOptionsValues.includes(result.guid),
  );

  return nonExistingSearchResults.map((result) => ({
    avatar: {
      guid: result.guid,
      isActive: result.isActive,
      isDeleted: false,
      name: result.name,
      src: result.avatar,
    },
    customLabel: (
      <OwnerLabel
        owner={
          {
            fullName: result.name,
            guid: result.guid,
            team: result?.team,
          } as OwnerType
        }
      />
    ),
    props: {
      rank: result.indexPosition,
    },
    text: result.name,
    value: result.guid,
  }));
};

export const invalidateAppOwnersCache = () => {
  invalidateCache((keys) => [
    keys.metadata.all,
    keys.organizationUser.all,
    keys.metrics.all,
    keys.documents.all,
    keys.terms.all,
    keys.databases.all,
    keys.tables.all,
    keys.schemas.all,
  ]);
};

export const getNewOwnerGuid = (value: string | null) =>
  value === NO_OWNER_OPTION.value ? null : value;

export const getBusinessOwnerDataParams: OwnerDataParamFormatter = ({ items, newValue }) => ({
  business_owner: getNewOwnerGuid(newValue),
  items: items.map((item) => item.guid),
});

export const getTechnicalOwnerDataParams: OwnerDataParamFormatter = ({ items, newValue }) => ({
  items: items.map((item) => item.guid),
  technical_owner: getNewOwnerGuid(newValue),
});

export const getTagOwnerDataParams: OwnerDataParamFormatter = ({ newValue }) => ({
  owner: getNewOwnerGuid(newValue),
});
