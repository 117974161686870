import { XYPosition } from 'reactflow';

import { SIZES } from '../../../components/Nodes/config';
import { ExploreNode } from '../../../Explore.types';

import { NodeParser } from './types';

const columnNodeParser: NodeParser = ({
  initialPosition = { x: 0, y: 0 } as XYPosition,
  query,
  rawNode,
}) => {
  const { isBITableColumn } = rawNode.data ?? {};

  const style = {
    height: SIZES.height.column,
    width: isBITableColumn ? SIZES.width.biColumn : SIZES.width.column,
  };

  const columnNode: ExploreNode = {
    ...rawNode,
    data: {
      ...rawNode.data,
      highlightedText: query,
      style,
    },
    position: {
      x: initialPosition.x,
      y: initialPosition.y,
    },
    style,
  };

  return { node: columnNode, nodeHeight: Number(columnNode.style?.height) };
};

export default columnNodeParser;
