import React, { memo, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import type { Cell } from 'react-table';

import { useFetchOrganizationUsers } from '@api/organizationUser';
import DateTime from '@components/DateTime';
import TabError from '@components/TabContent/TabError';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import UserCell from '@components/Table/Cells/UserCell';
import Table from '@components/Table/Table';
import { sortDates } from '@components/Table/Table/sortDates';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { OrganizationUserModel } from '@models/OrganizationUserModel';
import { OrganizationUserRoleMap } from '@models/OrganizationUserRole';
import { Filter } from '@utils';

const searchConfig: { [key: string]: keyof Filter.FilterOptions } = {
  email: 'search_email',
  name: 'search_name',
};

const sortConfig: { [key: string]: string } = {
  email: 'email',
  lastActivity: 'last_activity',
  name: 'name',
  role: 'role',
};

const requestConfig: Filter.FilterOptions = {
  order: '-last_activity',
  page: 1,
  page_size: 25,
  query: `{
    role,
    user{
      guid,
      avatar,
      email,
      first_name,
      last_name,
      last_activity,
      is_active
    }
  }`,
  sortColumn: 'name',
  sortDirection: 'ascending',
};

interface TeamMembersTabProps {}

const TeamMembersTab: React.FC<TeamMembersTabProps> = () => {
  const { guid = '' } = useParams<{ guid?: string }>();
  const FilterService = Filter.useUpdateFilters(
    { ...requestConfig, teams: [guid] },
    searchConfig,
    sortConfig,
    'name',
  );
  const { changePage, filter, search, sort } = FilterService;
  const { data, isError, isLoading } = useFetchOrganizationUsers({
    enabled: Boolean(guid),
    keepPreviousData: true,
    params: Filter.setParams(filter),
  });
  const [isShowFilter, setShowFilter] = useState(false);
  const toggleFilter = useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);
  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  const columns: ColumnConfig<OrganizationUserModel>[] = useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (props: Cell<OrganizationUserModel>) => (
          <UserCell {...props} user={props.row.original.user} />
        ),
        Header: 'Name',
        accessor: (d) => d?.user?.fullName,
        id: 'name',
        width: '30%',
      },
      {
        Header: 'Email',
        accessor: (d) => d.user?.email,
        id: 'email',
        width: '30%',
      },
      {
        Header: 'Role',
        accessor: (d) => d?.role && OrganizationUserRoleMap[d.role],
        disableFilters: true,
        id: 'role',
        width: '20%',
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row: { original } }: Cell<OrganizationUserModel>) => (
          <DateTime datetime={original?.user?.lastActivity} format="fullDateFormat" />
        ),
        Header: 'Last Activity',
        accessor: (d) => d?.user?.lastActivity?.toDate(),
        disableFilters: true,
        id: 'lastActivity',
        sortType: sortDates,
        width: '20%',
      },
    ],
    [],
  );

  if (isError) return <TabError />;

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        columns={columns}
        compact
        data={data?.results ?? []}
        disableColumnFiltering
        disableGlobalFilter
        disableRowSelect
        loading={isLoading}
        manualFilters
        manualPagination
        manualSortBy
        setFilters={search}
        setSortBy={sort}
        showFilter={isShowFilter}
        sortable
        toggleFilter={toggleFilter}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default memo<TeamMembersTabProps>(TeamMembersTab);
