import React, { MouseEvent, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import Box from '@components/Box';
import ErdOptionsDropdown from '@components/ExploreTree/ErdOptionsDropdown';
import Input from '@components/Input/Input.v1';
import QueryModalWithTargetBox from '@components/Modal/QueryModal/QueryModalWithTargetBox';
import Tooltip from '@components/Tooltip';
import Icon from '@components/UI/Icon';
import DataTypesModel from '@models/DataTypesModel';
import TableLastQuery from '@pages/TabbedTableViewPage/TableLastQuery';
import theme from '@styles/theme';

import ExploreTreeContext from './context';
import { CleanIconButton, ColumnsSearchBar } from './ExploreColumns.styles';
import { Direction, OpenLineageCallback } from './types';

interface ExploreHeadingProps {
  chevronLeft: 'none' | 'open' | 'close';
  chevronRight: 'none' | 'open' | 'close';
  componentIdentifier?: string;
  dataTypes?: DataTypesModel;
  exploreType: 'lineage' | 'erd';
  fullTableName?: string;
  guid: string;
  hasDbtModel: boolean | undefined;
  hideCloseButton: boolean;
  isSearchActivated: boolean;
  isTableHidden?: boolean;
  isTablePinned?: boolean;
  onChange: (value: string, key: string) => void;
  onClick: (tableKey: string) => void;
  onSearchIconClick: (key: string) => void;
  openLineage: OpenLineageCallback;
  searchColumnName: string;
  setHoveringCloseLineage: (state: [string, Direction] | null) => void;
  setHoveringTableHeader: (tableKey: string | null) => void;
  showInputSearch: boolean;
  showSearchIcon: boolean;
  suppressTableIds: (keys: string[]) => void;
  tableKey: string;
  tableName: string;
}

const ExploreHeading: React.FC<ExploreHeadingProps> = ({
  chevronLeft,
  chevronRight,
  componentIdentifier,
  dataTypes,
  exploreType,
  fullTableName,
  guid,
  hasDbtModel,
  hideCloseButton,
  isSearchActivated,
  isTableHidden,
  isTablePinned,
  onChange,
  onClick,
  onSearchIconClick,
  openLineage,
  searchColumnName,
  setHoveringCloseLineage,
  setHoveringTableHeader,
  showInputSearch,
  showSearchIcon,
  suppressTableIds,
  tableKey,
  tableName,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { rectHeight, rectWidth, tablesById, xScale, yScale } = useContext(ExploreTreeContext);
  const table = tablesById[tableKey];

  /*
   * This is a fuzzy concept of "leaf", as we are trying to have close buttons almost
   * everywhere. The idea here is that it is "currently a leaf", even if it can open
   * more lineage to one direction.
   */
  const isLeaf = useMemo(() => {
    if (!table) return false;
    const exists = (id: string) => !!tablesById[id];
    const hasTargetLineage = (table.targetTableGuids || []).filter(exists).length > 0;
    const hasSourceLineage = (table.sourceTableGuids || []).filter(exists).length > 0;
    return !(hasSourceLineage && hasTargetLineage);
  }, [table, tablesById]);

  const openLeftLineage = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      openLineage({ direction: 'left', guid: tableKey });
    },
    [openLineage, tableKey],
  );

  const openRightLineage = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      openLineage({ direction: 'right', guid: tableKey });
    },
    [openLineage, tableKey],
  );

  const closeRightLineage = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      suppressTableIds(table!.targetTableGuids!);
    },
    [suppressTableIds, table],
  );

  const closeLeftLineage = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      suppressTableIds(table!.sourceTableGuids!);
    },
    [suppressTableIds, table],
  );

  const openErd = useCallback(() => {
    openLineage({ componentIdentifier, direction: 'all', tables: tableKey });
  }, [componentIdentifier, openLineage, tableKey]);

  const enter = useCallback(() => {
    setHoveringTableHeader(tableKey);
  }, [setHoveringTableHeader, tableKey]);

  const leave = useCallback(() => {
    setHoveringTableHeader(null);
  }, [setHoveringTableHeader]);

  const remove = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      suppressTableIds([tableKey]);
    },
    [tableKey, suppressTableIds],
  );

  const removeHoverLeft = useCallback(() => {
    setHoveringCloseLineage([tableKey, 'left']);
  }, [tableKey, setHoveringCloseLineage]);

  const removeHoverRight = useCallback(() => {
    setHoveringCloseLineage([tableKey, 'right']);
  }, [tableKey, setHoveringCloseLineage]);

  const removeHoverOut = useCallback(() => {
    setHoveringCloseLineage(null);
  }, [setHoveringCloseLineage]);

  useEffect(() => {
    const handleActivateSearch = (e: KeyboardEvent) => {
      if (e.metaKey && e.key === 'f' && showSearchIcon && !isSearchActivated) {
        e.preventDefault();
        onSearchIconClick(guid);
        inputRef?.current?.focus();
      }
    };

    window.addEventListener('keydown', handleActivateSearch);
    return () => {
      window.removeEventListener('keydown', handleActivateSearch);
    };
  }, [showSearchIcon, onSearchIconClick, isSearchActivated, guid]);

  if (!table) return null;

  const showTableEventsQuery = dataTypes?.objectType === 'table';
  const showTableQuery = !showTableEventsQuery && table.query;
  const isImplicit = dataTypes?.dataType === 'implicit';

  return (
    <foreignObject
      height={yScale(rectHeight * (showInputSearch ? 2 : 1))}
      width={xScale(rectWidth)}
      x={0}
      y={0}
    >
      <div className="explore-heading">
        <div
          className="explore-heading-wrapper"
          onMouseEnter={enter}
          onMouseLeave={leave}
          role="button"
          tabIndex={-1}
        >
          {exploreType === 'lineage' && chevronLeft === 'open' && (
            <Tooltip action content="Open upstream sources">
              <button
                className="iconGroup onlyTargeted onlyHovered"
                onClick={openLeftLineage}
                type="button"
              >
                <Icon color={theme.colors.primary} name="left" size="15px" />
              </button>
            </Tooltip>
          )}
          {exploreType === 'lineage' && chevronLeft === 'close' && (
            <Tooltip action content="Close upstream sources">
              <button
                className="iconGroup closeBt onlyTargeted onlyHovered"
                onClick={closeLeftLineage}
                onMouseOut={removeHoverOut}
                onMouseOver={removeHoverLeft}
                type="button"
              >
                <Icon color={theme.colors.primary} name="right" size="15px" />
              </button>
            </Tooltip>
          )}
          {exploreType !== 'erd' && chevronLeft === 'none' && (
            <div className="offset-placeholder" />
          )}
          {exploreType === 'erd' && <div className="erd-offset-placeholder" />}
          <div
            className="icons-container"
            onClick={() => onClick(tableKey)}
            role="button"
            tabIndex={-1}
          >
            <Tooltip content={dataTypes?.tooltips?.dataSource}>
              <Icon name={dataTypes?.icons.dataSource!} size="15px" />
            </Tooltip>
            {hasDbtModel && (
              <Tooltip content={dataTypes?.tooltips?.dataType}>
                <Icon name="dbt" size="15px" />
              </Tooltip>
            )}
            <Tooltip
              content={isImplicit ? 'Temporary table (inferred)' : dataTypes?.tooltips?.dataType}
            >
              <Icon name={dataTypes?.icons.dataType!} size="15px" />
            </Tooltip>
          </div>
          <Tooltip content={fullTableName ?? tableName}>
            <span className="heading-text ellipses" onClick={() => onClick(tableKey)}>
              <span className="ellipses">{tableName}</span>
              <Icon
                className={isTablePinned ? 'pinActive' : 'pin'}
                name={isTablePinned ? 'pin-selected' : 'pin-unselected'}
                size="12px"
              />
            </span>
          </Tooltip>
          {isTablePinned && showTableQuery && (
            <Tooltip action content="Open SQL">
              <Box as="span" compHeight="100%" noDefault>
                <QueryModalWithTargetBox
                  codeString={table.query}
                  dataSourceType={dataTypes?.dataSourceType}
                >
                  <button className="iconGroup" type="button">
                    <Icon name="code-block" size="15px" />
                  </button>
                </QueryModalWithTargetBox>
              </Box>
            </Tooltip>
          )}
          {isTablePinned && showTableEventsQuery && (
            <Tooltip action content="Open SQL">
              <Box as="span" compHeight="100%" noDefault>
                <TableLastQuery dataSourceType={dataTypes?.dataSourceType} guid={guid}>
                  <button className="iconGroup" type="button">
                    <Icon name="code-block" size="15px" />
                  </button>
                </TableLastQuery>
              </Box>
            </Tooltip>
          )}
          {showSearchIcon && (
            <Tooltip action content={showInputSearch ? 'Close search' : 'Open search'}>
              <button
                className="iconGroup"
                data-testid="column-search"
                onClick={() => {
                  onSearchIconClick(guid);

                  if (!showInputSearch) {
                    setTimeout(() => {
                      inputRef?.current?.focus();
                    }, 50);
                  } else {
                    onChange('', guid);
                  }
                }}
                type="button"
              >
                <Icon name="search" size="15px" />
              </button>
            </Tooltip>
          )}
          {exploreType === 'lineage' && (
            <>
              {chevronRight === 'open' && (
                <Tooltip action content="Open downstream targets">
                  <button
                    className="iconGroup onlyTargeted onlyHovered"
                    onClick={openRightLineage}
                    type="button"
                  >
                    <Icon name="right" size="15px" />
                  </button>
                </Tooltip>
              )}
              {chevronRight === 'close' && (
                <Tooltip action content="Close downstream targets">
                  <button
                    className="iconGroup onlyTargeted closeBt"
                    onClick={closeRightLineage}
                    onMouseOut={removeHoverOut}
                    onMouseOver={removeHoverRight}
                    type="button"
                  >
                    <Icon name="left" size="15px" />
                  </button>
                </Tooltip>
              )}
              {isLeaf && !hideCloseButton && (
                <Tooltip action content={`Close table ${table.name}`}>
                  <button className="iconGroup closeBt" onClick={remove} type="button">
                    <Icon name="close" size="12px" />
                  </button>
                </Tooltip>
              )}
            </>
          )}
          {exploreType === 'erd' &&
            (chevronLeft === 'open' || chevronRight === 'open') &&
            !isTableHidden && (
              <ErdOptionsDropdown
                guid={guid}
                optionsSelections={[
                  {
                    id: '0',
                    options: [
                      {
                        icon: 'plus-circle' as const,
                        id: 'open-direct-lineage',
                        label: 'Open connected tables',
                        onClick: openErd,
                      },
                    ],
                  },
                ]}
              >
                {({ onBlur, triggerId }) => (
                  <button className="iconGroup" id={triggerId} onBlur={onBlur} type="button">
                    <Icon name="settings" size="15px" />
                  </button>
                )}
              </ErdOptionsDropdown>
            )}
        </div>
        {showInputSearch && (
          <ColumnsSearchBar>
            <Input
              ref={inputRef}
              compSize="xs"
              endIcon={
                searchColumnName && (
                  <CleanIconButton onClick={() => onChange('', guid)}>
                    <Icon name="close" size="12px" />
                  </CleanIconButton>
                )
              }
              onChange={(e) => onChange(e.target.value, guid)}
              placeholder="Search..."
              value={searchColumnName}
            />
          </ColumnsSearchBar>
        )}
      </div>
    </foreignObject>
  );
};

export default React.memo<ExploreHeadingProps>(ExploreHeading);
