import styled from '@emotion/styled';

import type { StyledBoxProps } from '@components/Box';
import Box from '@components/Box';
import IconButton from '@components/IconButton';

export const StyledColumnConstraintIconButton = styled(IconButton)``;

StyledColumnConstraintIconButton.defaultProps = {
  size: 'xs',
  variant: 'clear',
};

export interface StyledColumnContraintProps extends StyledBoxProps {}

export const StyledColumnConstraint = styled(Box)<StyledColumnContraintProps>`
  ${StyledColumnConstraintIconButton} {
    display: none;
  }
  &:hover {
    ${StyledColumnConstraintIconButton} {
      display: block;
    }
  }
`;

StyledColumnConstraint.defaultProps = {
  alignItems: 'center',
  compDisplay: 'flex',
  gap: 1,
  noDefault: true,
};
