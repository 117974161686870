import React from 'react';

import Box from '@components/Box';
import { GridContainer } from '@components/Grid';
import TitleHeader from '@components/Title/TitleHeader';
import MetadataDecorator from '@utils/MetadataDecorator';

import AdminCustomAttributes from './AdminCustomAttributes';

const AdminCustomAttributePage: React.FC = () => {
  return (
    <>
      <MetadataDecorator title="Custom Attribute" />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <Box mb={5}>
          <TitleHeader>
            <span className="title big">Custom Attribute</span>
          </TitleHeader>
        </Box>
        <AdminCustomAttributes />
      </GridContainer>
    </>
  );
};

export default AdminCustomAttributePage;
