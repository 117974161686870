import styled from '@emotion/styled';
import { Accordion, Dropdown } from 'semantic-ui-react';

export const AccordionItemTitle = styled(Accordion.Title)`
  display: flex;
  align-items: center;
  font-size: 0.8125rem !important;
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  color: #44464a !important;
  height: 0.9735rem;
  margin: 0.06rem 0.3rem 0.3rem 0;
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary} !important;
  padding: 0 !important;
  margin-top: 2rem;
`;

export const AccordionItemContent = styled(Accordion.Content)``;

export const StyledDropdown = styled(Dropdown)`
  &.ui.selection.dropdown,
  &.ui.dropdown > .text,
  &.ui.search.dropdown.active > .text,
  &.ui.search.dropdown.visible > .text {
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
    max-width: 100%;
    min-width: 100%;
  }

  &.ui.search.dropdown.active > input.search,
  &.ui.search.dropdown.visible > input.search {
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  }

  &.ui.dropdown.active,
  &.ui.dropdown .menu {
    border: solid 1px #e2e9f0;
  }
  &.ui.dropdown,
  &.ui.dropdown .menu > .item {
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    line-height: 1.15;
  }
  &.ui.dropdown .menu > .item .header {
    color: #0c3476;
    font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
    font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  }
  &.ui.dropdown .menu > .disabled.item {
    pointer-events: none !important;
    opacity: 1;
    background-color: #f2f5f9;
  }
`;
