import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import { XYPosition } from 'reactflow';

import { InputNodesById } from '../Explore.types';
import {
  CreateStacksResult,
  EdgesById,
  NodesById,
  StackGroups,
} from '../useCreateNodesEdges/algorithm/types';
import { DEFAULT_INITIAL_POSITION, useExplore } from '../useExplore';
import { BiggestConflictEndPerStack, UsageTypesState } from '../useExplore/Explore.context.types';

const MAX_EXPLORE_STATES = 10;

export interface ExploreState {
  biggestConflictEndPerStack: BiggestConflictEndPerStack;
  edgesById: EdgesById;
  initialPosition: XYPosition;
  inputNodesById: InputNodesById;
  isCollapseAllButtonEnabled: boolean;
  isColumnLevelLineage: boolean;
  nodesById: NodesById;
  nodesByStack: StackGroups;
  selectedUsageTypesState?: UsageTypesState;
  stackData: CreateStacksResult | {};
}

type ActionType = 'undo' | 'redo';

interface UndoRedoContextValues {
  currStatePosition: number;
  saveExploreState: (state: ExploreState) => void;
  savedStatesCount: number;
  undoRedoExploreAction: (action: ActionType) => void;
}

const defaultContextValue: UndoRedoContextValues = {
  currStatePosition: 0,
  saveExploreState: () => {},
  savedStatesCount: 0,
  undoRedoExploreAction: () => {},
};

const UndoRedoContext = createContext(defaultContextValue);
export const useUndoRedo = () => useContext(UndoRedoContext);

export interface UndoRedoContextProviderProps {
  children?: React.ReactNode;
}

export const UndoRedoContextProvider = ({ children }: UndoRedoContextProviderProps) => {
  const [currStatePosition, setCurrStatePosition] = useState<number>(
    defaultContextValue.currStatePosition,
  );
  const [states, setStates] = useState<ExploreState[]>([]);

  const {
    selectedUsageTypesState,
    setBiggestConflictEndPerStack,
    setEdgesById,
    setInitialPosition,
    setInputNodesById,
    setIsCollapseAllButtonEnabled,
    setIsColumnLevelLineage,
    setNodesById,
    setNodesByStack,
    setSelectedEdge,
    setSelectedUsageTypesState,
    setStacksData,
  } = useExplore();

  const saveExploreState = useCallback(
    (state: ExploreState) => {
      const statesLength = states.length;
      let newCurrStatePosition = 0;

      if (statesLength > 0 && currStatePosition < MAX_EXPLORE_STATES) {
        setCurrStatePosition((prevCurrStatePosition) => {
          newCurrStatePosition = prevCurrStatePosition + 1;

          return newCurrStatePosition;
        });
      }

      setStates((prevStates) => {
        let prevStatesCopy = [...prevStates];
        const prevStatesLength = prevStates.length;

        if (prevStatesLength >= MAX_EXPLORE_STATES) {
          prevStatesCopy.shift();
        } else if (newCurrStatePosition < prevStatesLength) {
          prevStatesCopy = prevStatesCopy.slice(0, newCurrStatePosition);
        }
        const clonedState = cloneDeep(state);
        prevStatesCopy.push({
          ...clonedState,
          selectedUsageTypesState: {
            shouldNotRecalculate: true,
            usageTypes: selectedUsageTypesState.usageTypes,
          },
        });

        return prevStatesCopy;
      });
    },
    [currStatePosition, selectedUsageTypesState.usageTypes, states.length],
  );

  const undoRedoExploreAction = useCallback(
    (action: ActionType) => {
      const actionModifier = action === 'undo' ? -1 : 1;
      const newCurrStatePosition = states.length > 1 ? currStatePosition + actionModifier : 0;

      const newCurrState = states[newCurrStatePosition];

      if (newCurrState) {
        setCurrStatePosition(newCurrStatePosition);

        setIsColumnLevelLineage(newCurrState.isColumnLevelLineage ?? false);
        setNodesById(newCurrState.nodesById);
        setEdgesById(newCurrState.edgesById);
        setInputNodesById(newCurrState.inputNodesById);
        setIsCollapseAllButtonEnabled(newCurrState.isCollapseAllButtonEnabled);
        setStacksData(
          isEmpty(newCurrState.stackData)
            ? undefined
            : (newCurrState.stackData as CreateStacksResult),
        );
        setNodesByStack(newCurrState.nodesByStack);
        setInitialPosition(newCurrState.initialPosition ?? DEFAULT_INITIAL_POSITION);
        setBiggestConflictEndPerStack(newCurrState.biggestConflictEndPerStack);
        setSelectedEdge(undefined);

        if (newCurrState?.selectedUsageTypesState) {
          setSelectedUsageTypesState(newCurrState.selectedUsageTypesState);
        }
      }
    },
    [
      currStatePosition,
      setBiggestConflictEndPerStack,
      setIsCollapseAllButtonEnabled,
      setEdgesById,
      setInitialPosition,
      setInputNodesById,
      setIsColumnLevelLineage,
      setNodesById,
      setNodesByStack,
      setSelectedEdge,
      setSelectedUsageTypesState,
      setStacksData,
      states,
    ],
  );

  const value = useMemo(
    () => ({
      currStatePosition,
      saveExploreState,
      savedStatesCount: states.length,
      undoRedoExploreAction,
    }),
    [currStatePosition, saveExploreState, states.length, undoRedoExploreAction],
  );

  return <UndoRedoContext.Provider value={value}>{children}</UndoRedoContext.Provider>;
};
