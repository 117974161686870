import React from 'react';

import { MetadataObjectType } from '@atoms';
import CopyFullPathButton from '@components/CopyFullPathButton';
import NotFoundError from '@components/Error/NotFoundError';
import FavoriteButton from '@components/FavoriteButton';
import IconButton from '@components/IconButton';
import DeleteMetricsModal from '@components/Modal/DeleteMetricsModal';
import OpenInDjango from '@components/OpenInDjango';
import PageHeaderBar from '@components/PageHeaderBar';
import type { PageHeaderBarProps } from '@components/PageHeaderBar/PageHeaderBar';
import TaggedItems from '@components/TaggedItems';
import Icon from '@components/UI/Icon';
import { MetadataObjectProvider } from '@context/MetadataObject';
import { useModal } from '@context/Modal';
import useBulkEditSelected from '@hooks/useBulkEditSelected';
import { DataSourceTypesType } from '@models/DataSourceCredentials';
import DataTypesModel from '@models/DataTypesModel';
import { MetadataData } from '@models/MetadataModel';
import DeleteDocumentsModal from '@pages/DocumentsPage/DeleteDocumentsModal';
import theme from '@styles/theme';
import MetadataDecorator from '@utils/MetadataDecorator';

import { StyledGridContainer } from './MetadataObjectTemplate.styles';

export interface MetadataObjectTemplateProps {
  data?: MetadataData;
  dataSourceGuid?: string;
  dataSourceType?: DataSourceTypesType;
  dataTypes?: DataTypesModel;
  deleteAllowed?: boolean;
  guid: string;
  headerProps: PageHeaderBarProps;
  hideTitleToolBox?: boolean;
  isAuthorized: boolean;
  isError: boolean;
  metaTitle?: string;
  objectName: string;
  objectType?: MetadataObjectType;
  onDeleteSuccess?: () => void;
  onTagsEdit: () => void;
  tagsEditAllowed?: boolean;
}

const MetadataObjectTemplate: React.FC<MetadataObjectTemplateProps> = ({
  children,
  data,
  dataSourceGuid,
  dataSourceType,
  dataTypes,
  deleteAllowed,
  guid,
  headerProps,
  hideTitleToolBox,
  isAuthorized,
  isError,
  metaTitle,
  objectName,
  objectType = 'tables',
  onDeleteSuccess,
  tagsEditAllowed = true,
}) => {
  const { selected, tagsCounts } = useBulkEditSelected<MetadataData>({
    defaultSelected: data ? [data] : undefined,
  });
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();
  if (!isAuthorized || isError) {
    return <NotFoundError />;
  }

  return (
    <MetadataObjectProvider
      dataSourceGuid={dataSourceGuid}
      dataSourceType={dataSourceType}
      dataTypes={dataTypes}
      objectType={objectType}
    >
      <StyledGridContainer fluid hPaddingSpace={5} vPaddingSpace={3}>
        <MetadataDecorator title={metaTitle} />
        <PageHeaderBar
          {...headerProps}
          sticky
          titleToolBox={
            !hideTitleToolBox && (
              <>
                {data?.fullName && <CopyFullPathButton text={data.fullName} />}
                {deleteAllowed && (
                  <IconButton
                    data-testid="delete-button"
                    onClick={() => {
                      if (objectType === 'metrics') openModal(MODAL_IDS.deleteMetrics);
                      if (objectType === 'documents' || objectType === 'terms')
                        openModal(MODAL_IDS.deleteDocumentsMetadataObjectTemplate);
                    }}
                  >
                    <Icon color={theme.colors.v1.gray[400]} name="trash" size="16px" />
                  </IconButton>
                )}
                <FavoriteButton objGuid={guid} objName={objectName} />
                <TaggedItems
                  isEditable={tagsEditAllowed}
                  objects={selected.items}
                  taggedItemsCounts={tagsCounts}
                />
                <OpenInDjango guid={data?.guid} size="14px" />
              </>
            )
          }
        />
        {children}
      </StyledGridContainer>
      {deleteAllowed && checkModalOpened(MODAL_IDS.deleteMetrics) && (
        <DeleteMetricsModal
          onClose={() => closeModal(MODAL_IDS.deleteMetrics)}
          onSuccess={onDeleteSuccess}
          selectedItems={data ? [data] : []}
        />
      )}
      {deleteAllowed && checkModalOpened(MODAL_IDS.deleteDocumentsMetadataObjectTemplate) && (
        <DeleteDocumentsModal
          onClose={() => closeModal(MODAL_IDS.deleteDocumentsMetadataObjectTemplate)}
          reloadData={() => onDeleteSuccess?.()}
          selectedItems={data ? [data] : []}
        />
      )}
    </MetadataObjectProvider>
  );
};

export default MetadataObjectTemplate;
