import React, { useCallback, useState } from 'react';
import { Cell } from 'react-table';

import { MentionModel } from '@api/mentions/MentionModel';
import { TermModel } from '@api/terms/TermModel';
import ExcerptText from '@components/ExcerptText';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import TaggedItemCell from '@components/Table/Cells/TaggedItemCell';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { Filter } from '@utils';
import { getPopularityNormalized } from '@utils/popularity';

const initialSortState = [
  {
    desc: false,
    id: 'name',
  },
];

export interface MentionedByTableProps {
  data?: MentionModel[];
  filterService: Filter.FilterServiceInterface;
  isLoading?: boolean;
  itemCount?: number;
  totalPages?: number;
}

const MentionedByTable: React.FC<MentionedByTableProps> = ({
  data,
  filterService,
  isLoading,
  itemCount = 0,
  totalPages,
}) => {
  const [isShowFilter, setShowFilter] = useState(false);
  const toggleFilter = React.useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);
  const { changePage, filter, search, sort } = filterService;

  const columns = React.useMemo(() => {
    const cols: ColumnConfig<MentionModel>[] = [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: (props: Cell<MentionModel>) => {
          const { row } = props;
          const mention = row.original.source?.obj;
          return (
            <LinkedCell
              {...props}
              item={mention}
              itemName={mention.name}
              showIcon
              showNameTooltip
            />
          );
        },
        Header: `Name (${itemCount})`,
        accessor: (d) => d.source?.obj.name,
        disableHiding: true,
        id: 'name',
        width: '110%',
      },
      {
        Cell: ExcerptText,
        Header: 'Description',
        accessor: (d) => d.source?.obj.description,
        id: 'description',
        width: '140%',
      },
      {
        Cell: (props: Cell<MentionModel>) => {
          return <TaggedItemCell {...props} />;
        },
        Header: 'Tags',
        accessor: (d) => d.source?.obj?.taggedItems,
        disableFilters: true,
        disableSortBy: true,
        id: 'tags',
        width: '110%',
      },
      {
        Cell: (props: Cell<MentionModel>) => {
          const { row } = props;
          const mention = row.original.source?.obj;

          return (
            <PopularityCell
              {...props}
              dataTypes={mention.dataTypes}
              popularity={mention.popularity}
            />
          );
        },
        Header: PopularityCellHeader,
        accessor: (d) => getPopularityNormalized(d.source?.obj.popularity?.popularity),
        disableFilters: true,
        disableResizing: true,
        id: 'popularity',
        sortDescFirst: true,
        width: 120,
      },
    ];

    return cols;
  }, [itemCount]);

  const getRowId = useCallback((row: Partial<TermModel>) => row.guid!, []);

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        compact
        data={data || []}
        disableRowSelect
        getRowId={getRowId}
        initialState={{
          hiddenColumns: [],
          pageIndex: filter.page ? filter.page - 1 : 0,
          sortBy: initialSortState,
        }}
        loading={isLoading}
        manualFilters
        manualPagination
        manualSortBy
        setFilters={search}
        setSortBy={sort}
        showFilter={isShowFilter}
        sortable
        stickyHeader
        toggleFilter={toggleFilter}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default MentionedByTable;
