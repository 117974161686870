import React, { cloneElement } from 'react';

import { QueryModel } from '@api/queries/QueryModel';
import Button from '@components/Button/Button';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import { QueryLogRecordModel } from '@models/QueryLogRecordModel';

const defaultChildren = (
  <Button
    backgroundColor="rgba(12, 52, 118, 0.07)"
    color="#0C3476"
    iconCurrentColor
    startIcon={<Icon name="code-block" size="16px" />}
    type="button"
  >
    SQL
  </Button>
);

interface QueryTextProps {
  children?: React.ReactElement;
  codeString?: string;
  dataSourceType?: string;
  editable?: boolean;
  guid?: string;
  onClose?: () => void;
  onCodeStringEdit?: () => void;
  onOpen?: () => void;
  query?: QueryModel;
  queryLogRecords?: QueryLogRecordModel[];
}

const QueryModalWithTargetBox: React.FC<QueryTextProps> = ({
  children = defaultChildren,
  codeString = '',
  dataSourceType,
  editable,
  guid,
  onClose,
  onCodeStringEdit,
  onOpen,
  query,
  queryLogRecords,
}) => {
  const { MODAL_IDS, openModal } = useModal();

  return (
    <>
      {cloneElement(children, {
        onClick: (e: any) => {
          openModal(MODAL_IDS.query, {
            codeString,
            dataSourceType,
            editable,
            metricGuid: guid,
            onClose,
            query,
            queryLogRecords,
            reloadData: onCodeStringEdit,
          });
          children?.props?.onClick?.(e);
          onOpen?.();
        },
      })}
    </>
  );
};

export default QueryModalWithTargetBox;
