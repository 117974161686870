import React from 'react';

import { useFetchTeams } from '@api/teams';
import { TeamModel } from '@api/teams/TeamModel';
import Box from '@components/Box';
import { GridContainer } from '@components/Grid';
import IconButton from '@components/IconButton';
import CreateTeamModal from '@components/Modal/CreateTeamModal';
import AdminTeamsTable from '@components/Table/AdminTeamsTable';
import TitleHeader from '@components/Title/TitleHeader';
import Icon from '@components/UI/Icon';
import TitleView from '@components/UI/TitleView';
import { useModal } from '@context/Modal';
import MetadataDecorator from '@utils/MetadataDecorator';

const AdminTeamsPage: React.FC = () => {
  const { data, refetch } = useFetchTeams();
  const { MODAL_IDS, checkModalOpened, closeModal, openModal } = useModal();
  const totalMemberCount = data?.results?.reduce(
    (totalCount: number, team: TeamModel) => totalCount + (team?.membersCount || 0),
    0,
  );

  return (
    <>
      <MetadataDecorator title="Teams Admin" />
      <GridContainer fluid hPaddingSpace={5} vPaddingSpace={5}>
        <Box maxWidth="100%" mb={8} px={1.5} py={0}>
          <TitleHeader>
            <span className="title big">Teams</span>
          </TitleHeader>
        </Box>
        <Box maxWidth="100%" mb={8} px={1.5} py={0}>
          <TitleView
            action={
              <IconButton label="Create Team" onClick={() => openModal(MODAL_IDS.createTeam)}>
                <Icon name="plus" />
              </IconButton>
            }
            mb={2.5}
            pt={1}
            title="Teams"
          />
          <AdminTeamsTable
            itemCount={data?.results.length}
            memberCount={totalMemberCount}
            teams={data?.results}
          />
        </Box>
      </GridContainer>
      {checkModalOpened(MODAL_IDS.createTeam) && (
        <CreateTeamModal
          onClose={() => {
            closeModal(MODAL_IDS.createTeam);
            refetch();
          }}
        />
      )}
    </>
  );
};

export default AdminTeamsPage;
