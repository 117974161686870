import { DsUserModel } from '@api/dsusers/DsUserModel';
import { SelectValue } from '@components/UI/Select.v1/types';

export const mapDsUserToOption = (dsUser: DsUserModel): SelectValue => {
  const { user } = dsUser;

  if (!user) return undefined;

  return [
    {
      avatar: user.mappedAvatar,
      text: user.fullName,
      value: user.guid,
    },
  ];
};
