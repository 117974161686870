import type { GetBiDashboardElementsListQueryParams } from '@api/openapi.generated';
import { paginatedSelect, rawSelect } from '@api/utils';
import type { UseFetchOptions } from '@hooks/useFetch';
import useFetch from '@hooks/useFetch';
import type { PaginatedResponse } from '@models/PaginatedResponse';

import type { BiDashboardElementModel } from './biDashboardElementModel.v1';
import mapBiDashboardElementModel from './biDashboardElementModel.v1';
import * as cacheKeys from './cacheKeys';

export const fetchBiDashboardElementsSelect = paginatedSelect(mapBiDashboardElementModel);

export const useFetchBiDashboardElements = (
  options: UseFetchOptions<
    PaginatedResponse<BiDashboardElementModel>,
    GetBiDashboardElementsListQueryParams
  >,
) => {
  return useFetch<PaginatedResponse<BiDashboardElementModel>>({
    ...options,
    queryKey: [...cacheKeys.all, options.params],
    select: fetchBiDashboardElementsSelect,
    url: `/bi/dashboard-elements/`,
  });
};

export const fetchBiDashboardElementSelect = rawSelect(mapBiDashboardElementModel);

export const useFetchBiDashboardElement = (
  guid: string,
  options: UseFetchOptions<BiDashboardElementModel, GetBiDashboardElementsListQueryParams>,
) => {
  return useFetch<BiDashboardElementModel>({
    ...options,
    queryKey: [...cacheKeys.biDashboardElement(guid), options.params],
    select: fetchBiDashboardElementSelect,
    url: `/bi/dashboard-elements/${guid}/`,
  });
};
