import React from 'react';

import Button from '@components/Button/Button';
import type { IconProps } from '@components/UI/Icon';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import theme from '@styles/theme';

interface MentionedByModalWithAnchorProps {
  filterByMetrics?: boolean;
  guid: string;
  iconName?: IconProps['name'];
  label?: string;
  textColor?: string;
}

const MentionedByModalWithAnchor: React.FC<MentionedByModalWithAnchorProps> = ({
  filterByMetrics = false,
  guid,
  iconName = 'comment-filled',
  label = 'Mentioned',
  textColor = theme.colors.lightblue,
}) => {
  const { MODAL_IDS, openModal } = useModal();

  return (
    <Button
      backgroundColor="#f2f5f9"
      borderRadius={theme.radius.md}
      color={textColor}
      compSize="xxs"
      fontWeight="bold"
      iconCurrentColor
      onClick={() => {
        openModal(MODAL_IDS.mentionedBy, { filterByMetrics, targetId: guid });
      }}
      spacing={0.5}
      startIcon={<Icon name={iconName} size="12px" />}
      variant="outlined"
    >
      {label}
    </Button>
  );
};

export default MentionedByModalWithAnchor;
