import styled from '@emotion/styled';

import type { GetColorByTypeKey } from './colors';
import { colors, getColorByType } from './colors';

export interface StyledExploreTableFrameProps {
  colorType: GetColorByTypeKey;
  isTableImplicit?: boolean;
}

export const StyledExploreTableFrame = styled.g<StyledExploreTableFrameProps>`
  .table-wrapper,
  .table-wrapper-mock {
    pointer-events: none;
    fill: transparent;
    stroke: ${({ colorType }) => getColorByType(colorType).tableStroke};
  }

  .explore-heading-mock {
    fill: ${({ colorType }) => getColorByType(colorType).default};
  }

  .column-row-mock {
    fill: none;
  }

  &.pinned-column .column-row-mock {
    fill: ${colors.pinnedColumnFill};
  }

  .explore-heading {
    cursor: pointer;
    height: 100%;
    width: 100%;
    background-color: ${({ colorType }) => getColorByType(colorType).default};

    &:hover {
      .heading-text {
        text-decoration: underline;
      }

      .pin {
        display: block;
      }
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
    }

    .explore-heading-wrapper {
      opacity: ${({ isTableImplicit }) => (isTableImplicit ? 0.7 : 1)};
    }

    .iconGroup {
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: transparent;
      stroke: none;
      outline: none;
      flex-grow: 0;
      flex-shrink: 0;

      &:hover {
        background-color: ${({ colorType }) => getColorByType(colorType).iconHover} !important;
      }

      &.closeBt:hover {
        background-color: ${colors.headingIconBGCloseFillHover} !important;
      }

      &.closeBt:hover {
        svg {
          fill: #fff;
        }
      }
    }

    .icons-container {
      align-items: center;
      display: flex;
      flex-shrink: 0;
      gap: ${({ theme }) => theme.space(0.25)};
      height: 100%;
    }

    .heading-text {
      align-items: center;
      color: ${({ theme }) => theme.colors.text};
      display: flex;
      flex-grow: 1;
      font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
      font-size: ${({ theme }) => theme.typography.fontSizes.body1};
      font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
      gap: ${({ theme }) => theme.space(0.5)};
      height: 100%;
      line-height: 1;
      padding: ${({ theme }) => theme.space(0, 0.5)};
    }

    .onlyHovered {
      visibility: hidden;
      pointer-events: none;
    }

    .pin {
      display: none;
    }

    .pinActive,
    .pin {
      margin-top: 2px;
    }

    .offset-placeholder {
      flex-shrink: 0;
      width: 22px;
      margin-right: ${({ theme }) => theme.space(1.25)};
    }

    .erd-offset-placeholder {
      flex-shrink: 0;
      margin-right: ${({ theme }) => theme.space(1.25)};
    }
  }

  &.active,
  &:hover {
    .explore-heading {
      background-color: ${({ colorType }) => getColorByType(colorType).hover};
    }
    .explore-heading-mock {
      fill: ${({ colorType }) => getColorByType(colorType).hover};
    }

    .onlyHovered {
      visibility: visible;
      pointer-events: auto;
    }
  }

  &.active,
  &.hoveredTableTarget {
    .explore-heading {
      border-bottom: 1px solid ${({ colorType }) => getColorByType(colorType).tableStrokeHover};

      .onlyTargeted {
        display: flex;
      }
    }

    .table-wrapper,
    .table-wrapper-mock {
      stroke: ${({ colorType }) => getColorByType(colorType).tableStrokeHover};
    }

    .columnsToggle {
      rect {
        stroke: ${({ colorType }) => getColorByType(colorType).tableStrokeHover};
      }
    }
  }

  &.toBeRemovedTable {
    .table-wrapper,
    .table-wrapper-mock {
      stroke: ${colors.removeArrowColor};
    }
  }

  .columnsToggle {
    display: none;
    cursor: pointer;

    text {
      font-size: 11px;
      font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
      color: #6f747c;
    }

    rect {
      fill: ${colors.tableFill};
      stroke: ${({ colorType }) => getColorByType(colorType).tableStroke};
    }
  }

  &.open {
    .columnsToggle {
      display: block;
    }
  }

  .column-row {
    opacity: ${({ isTableImplicit }) => (isTableImplicit ? 0.3 : 1)};
  }
`;
