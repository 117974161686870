import React from 'react';
import { NO_LINK } from '@configs/urls/config';

import Box from '@components/Box';
import {
  StyledSidebarTreeItem,
  StyledSidebarTreeLabel,
} from '@components/ExploreSidebar/SidebarTreeItem.styles';
import Highlighter from '@components/Highlighter';
import IconButton from '@components/IconButton';
import OpenInNewTabButton from '@components/OpenInNewTabButton';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import Tooltip from '@components/Tooltip';
import Checkbox from '@components/UI/Form/Checkbox';
import Icon from '@components/UI/Icon';
import DataTypesModel from '@models/DataTypesModel';
import { PopularityModel } from '@models/PopularityModel';
import { urlFor } from '@utils/routing';

export interface ErdExploreSidebarTreeItemProps {
  dataTypes?: DataTypesModel;
  enableHighlight?: boolean;
  fullName?: string;
  guid: string;
  hasDbtDwhLink: boolean;
  id: string;
  isChecked?: boolean;
  label: string;
  onCheckboxItemClick?: (event: React.MouseEvent<HTMLInputElement>, id: string) => void;
  onClick: (id: string) => void;
  onSqlButtonClick?: (query?: string) => void;
  popularity?: PopularityModel;
  query: string;
  searchKeyword?: string;
}

const ErdExploreSidebarTreeItem: React.FC<ErdExploreSidebarTreeItemProps> = ({
  dataTypes,
  enableHighlight,
  fullName,
  guid,
  hasDbtDwhLink,
  id,
  isChecked,
  label,
  onCheckboxItemClick,
  onClick,
  onSqlButtonClick,
  popularity,
  query,
  searchKeyword,
}) => {
  const url = urlFor({
    dataType: dataTypes?.dataType,
    dataTypes,
    guid,
    objectType: dataTypes?.objectType,
  });

  return (
    <StyledSidebarTreeItem>
      <Box alignItems="center" compDisplay="flex" flexGrow={1} pl={2} pr={2} py={0.9}>
        <Box mr={1}>
          <Checkbox
            checked={isChecked}
            data-testid={`checkbox-${id}`}
            onClick={(e) => {
              onCheckboxItemClick?.(e, id);
            }}
          />
        </Box>
        <Box alignItems="center" compDisplay="flex" onClick={() => onClick(id)} role="button">
          {dataTypes?.dataSourceType && (
            <Tooltip content={dataTypes?.tooltips?.dataSource}>
              <Icon mr={0.25} name={dataTypes?.icons.dataSource!} size="16px" />
            </Tooltip>
          )}
          {hasDbtDwhLink && (
            <Tooltip content="dbt">
              <Icon mr={0.25} name="dbt" size="16px" />
            </Tooltip>
          )}
          <Tooltip content={dataTypes?.tooltips?.dataType}>
            <Icon name={dataTypes?.icons.dataType!} size="16px" />
          </Tooltip>
          <Tooltip content={fullName}>
            <StyledSidebarTreeLabel data-table-id={id}>
              {enableHighlight && searchKeyword !== undefined ? (
                <Highlighter searchWords={[searchKeyword]} textToHighlight={label} />
              ) : (
                label
              )}
            </StyledSidebarTreeLabel>
          </Tooltip>
          {query && (
            <IconButton onClick={() => onSqlButtonClick?.(query)}>
              <Tooltip action content="Open SQL Query">
                <Icon name="query" size="14px" />
              </Tooltip>
            </IconButton>
          )}
          {url !== NO_LINK && <OpenInNewTabButton url={url} />}
        </Box>
      </Box>
      <Box compDisplay="grid" justifyContent="end" pr={2}>
        {popularity && <PopularityCell popularity={popularity} />}
      </Box>
    </StyledSidebarTreeItem>
  );
};

export default ErdExploreSidebarTreeItem;
