import React, { useMemo, useState } from 'react';
import type { Filter } from '@utils';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Cell } from 'react-table';

import type { BiDashboardElementModel } from '@api/biDashboardElements/biDashboardElementModel.v1';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import EditableTaggedItemCell from '@components/Table/Cells/EditableTaggedItemCell';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import TextCell from '@components/Table/Cells/TextCell';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import { useUserContext } from '@context/User';
import type { PaginatedResponse } from '@models/PaginatedResponse';
import BIColumnPageRouted from '@pages/BIColumnPageRouted';
import type { FilterOptions } from '@utils/filters';

enum ColumnKey {
  description = 'description',
  name = 'name',
  pageName = 'pageName',
  search = 'search',
  tags = 'tags',
}

const DEFAULT_COLUMNS = [
  ColumnKey.search,
  ColumnKey.pageName,
  ColumnKey.name,
  ColumnKey.description,
  ColumnKey.tags,
];

export const BI_DASHBOARD_ELEMENTS_TABLE_SEARCH_CONFIG: PartialRecord<
  ColumnKey,
  keyof FilterOptions
> = {
  [ColumnKey.description]: 'search_description',
  [ColumnKey.name]: 'search_name',
  [ColumnKey.tags]: 'search_tags',
};

export const BI_DASHBOARD_ELEMENTS_TABLE_SORT_CONFIG = {
  [ColumnKey.description]: 'description',
  [ColumnKey.name]: 'name',
  [ColumnKey.pageName]: 'page_name',
};

export interface BiDashboardsElementsTableProps {
  data?: Pick<PaginatedResponse<BiDashboardElementModel>, 'count' | 'results'>;
  filterService: Filter.FilterServiceInterface;
  isDataSourceEditable: boolean;
  isLoading?: boolean;
  visibleColumns?: Array<ColumnKey>;
}
const BiDashboardsElementsTable: React.FC<BiDashboardsElementsTableProps> = ({
  data,
  filterService,
  isDataSourceEditable,
  isLoading,
  visibleColumns = DEFAULT_COLUMNS,
}) => {
  const { organization } = useUserContext();
  const { path } = useRouteMatch();
  const [showFilter, setShowFilter] = useState(false);
  const { changePage, filter, initialTableSortState, search, sort } = filterService;

  const columns = useMemo(() => {
    const all: Record<ColumnKey, ColumnConfig<BiDashboardElementModel>> = {
      [ColumnKey.description]: {
        Cell: ({ column, row: { original }, state }: Cell<BiDashboardElementModel>) => (
          <DescriptionCell
            {...original}
            column={column}
            dataSourceType={original.dataTypes?.dataSourceType}
            isDataSourceEditable={isDataSourceEditable}
            state={state}
            truncateDisabled={!organization?.settings?.useShowMoreButton}
          />
        ),
        Header: 'Description',
        accessor: ColumnKey.description,
        id: ColumnKey.description,
        width: '150%',
      },
      [ColumnKey.name]: {
        Cell: ({ column, row: { original }, state }: Cell<BiDashboardElementModel>) => {
          return (
            <LinkedCell
              column={column}
              customUrl={original.routePath}
              item={original}
              showDataTypeTooltip
              showIcon
              showNameTooltip
              state={state}
            />
          );
        },
        Header: `Visual (${data?.count ?? 0})`,
        accessor: ColumnKey.name,
        disableHiding: true,
        id: ColumnKey.name,
        width: '130%',
      },
      [ColumnKey.pageName]: {
        Cell: ({ column, row: { original } }: Cell<BiDashboardElementModel>) => (
          <TextCell column={column} text={original.pageName} />
        ),
        Header: 'Page',
        accessor: ColumnKey.pageName,
        disableFilters: true,
        id: ColumnKey.pageName,
        width: '110%',
      },
      [ColumnKey.search]: {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: ColumnKey.search,
        width: 32,
      },
      [ColumnKey.tags]: {
        Cell: ({ row: { original } }: Cell<BiDashboardElementModel>) => (
          <EditableTaggedItemCell
            hideCategoryTags
            isDataSourceEditable={isDataSourceEditable}
            obj={original}
          />
        ),
        Header: 'Tags',
        accessor: 'formattedTaggedItems',
        disableSortBy: true,
        id: ColumnKey.tags,
        width: '110%',
      },
    };

    return visibleColumns.map((col) => all[col]);
  }, [data?.count, isDataSourceEditable, organization?.settings, visibleColumns]);

  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  return (
    <>
      <TableStyled>
        <Table
          basic="very"
          changePage={changePage}
          columns={columns}
          compact
          data={data?.results ?? []}
          disableRowSelect
          initialState={{
            pageIndex: filter.page ? filter.page - 1 : 0,
            sortBy: initialTableSortState,
          }}
          loading={isLoading}
          manualFilters
          manualPagination
          manualSortBy
          setFilters={search}
          setSortBy={sort}
          showFilter={showFilter}
          singleLine
          sortable
          stickyHeader
          toggleFilter={() => setShowFilter((prev) => !prev)}
          totalPages={totalPages}
          unstackable
        />
      </TableStyled>
      <Switch>
        <Route path={`${path}/:columnGuid`}>
          <BIColumnPageRouted isDataSourceEditable={isDataSourceEditable} />
        </Route>
      </Switch>
    </>
  );
};

export default React.memo(BiDashboardsElementsTable);
