// Generates a random color from a list of team colors.

export default function teamColorGenerator(text: string | undefined) {
  const teamColors = [
    '#FF7987',
    '#A099E9',
    '#EB8EDF',
    '#5DA283',
    '#9269FF',
    '#4573D2',
    '#45BBDA',
    '#FFB239',
    '#FFD130',
  ];

  const getRandomColorFromList = (string: string | undefined, colors: string[]) => {
    let hash = 0;
    if (!string) return '';
    if (string.length === 0) return '';
    for (let i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
      // Convert to 32bit integer
      hash &= hash;
    }
    const index = Math.abs(hash % colors.length);
    return colors[index];
  };

  return getRandomColorFromList(text, teamColors);
}
