import { Expose, Transform, Type } from 'class-transformer';

import formatNumber from '@utils/formatNumber';

class TimeAggregationModel {
  @Expose({ name: 'day_of_week' })
  dayOfWeek: number = 0;

  @Expose({ name: 'end_time' })
  endTime: string = '';

  hour: number = 0;

  @Expose({ name: 'start_time' })
  startTime: string = '';
}

export interface TimeModelResponse {
  active_user: number;
  aggregation: {
    day_of_week: number;
    end_time: string;

    hour: number;
    start_time: string;
  };
  total_credits_used: number;
  total_run: number;
}

class TimeModel {
  static objectType: 'time' = 'time';

  @Expose({ name: 'active_user' })
  @Transform((value) => value ?? 0)
  activeUser: number = 0;

  get formattedActiveUser() {
    return formatNumber(this.activeUser);
  }

  @Type(() => TimeAggregationModel)
  aggregation?: TimeAggregationModel;

  get guid() {
    return `${this.aggregation?.endTime}${this.totalCreditsUsed}${this.aggregation?.hour}${this.aggregation?.dayOfWeek}`;
  }

  objectType = TimeModel.objectType;

  @Expose({ name: 'total_credits_used' })
  @Transform((value) => value ?? 0)
  totalCreditsUsed: number = 0;

  get formattedTotalCreditsUsed() {
    return formatNumber(this.totalCreditsUsed);
  }

  @Expose({ name: 'total_run' })
  @Transform((value) => value ?? 0)
  totalRun: number = 0;

  get formattedTotalRun() {
    return formatNumber(this.totalRun);
  }
}

export default TimeModel;
