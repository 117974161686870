import styled from '@emotion/styled';

import { statusMap } from '../Status/Status';

export const StyledFailed = styled.div`
  border-radius: ${({ theme }) => theme.radius.lg};
  background: ${() => statusMap.error.color};
  padding: ${({ theme }) => theme.space(0.25, 0.75)};
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
  line-height: normal;
`;
