import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import moment from 'moment';

import { OrganizationData, OrganizationModel } from '@api/organizations/OrganizationModel';
import { TeamModel } from '@api/teams/TeamModel';
import { ObjectType } from '@api/types';
import { UserData, UserModel } from '@api/user/UserModel';
import { UserListModel } from '@api/userLists/UserListModel';
import DataTypesModel from '@models/DataTypesModel';
import { DeletedModel } from '@models/DeletedModel';
import { Reference, ReferenceData } from '@models/Reference';

export interface CommentData {
  commentRecipients?: Reference<UserModel | TeamModel>[];
  commentedBy: UserData;
  commentedOn: moment.Moment;
  guid: string;
  inReplyTo: CommentData;
  message: string;
  organization: OrganizationData;
  pinned: boolean;
  pinnedAt: moment.Moment;
  // Todo: change any to more specific type once we know what is needed.
  replies: CommentData[];
  richtextMessage?: string;
  target: ReferenceData<any>;
}

export class CommentModel {
  static objectType: ObjectType = 'comment';

  objectType: ObjectType = CommentModel.objectType;

  guid!: string;

  message!: string;

  @Expose({ name: 'name' })
  @Transform((value) => (value ? `"${value}"` : undefined))
  name?: string;

  @Expose({ name: 'data_types' })
  @Type(() => DataTypesModel)
  dataTypes?: DataTypesModel = new DataTypesModel();

  @Expose({ name: 'parent_guid' })
  parentGuid!: string;

  @Expose({ name: 'commented_by' })
  @Transform((value) => (value ? plainToClass(UserModel, value) : new DeletedModel()))
  commentedBy!: UserModel;

  @Expose({ name: 'commented_on' })
  @Transform((value) => moment(value))
  commentedOn?: moment.Moment;

  @Type(() => Reference)
  target!: Reference<any>;

  @Type(() => CommentModel)
  replies?: CommentModel[];

  @Expose({ name: 'in_reply_to' })
  @Type(() => CommentModel)
  inReplyTo?: CommentModel;

  @Expose({ name: 'pinned_at' })
  @Transform((value) => moment(value))
  pinnedAt?: moment.Moment;

  pinned?: boolean;

  @Expose({ name: 'richtext_message' })
  richtextMessage!: string;

  @Type(() => OrganizationModel)
  organization?: OrganizationModel;

  @Type(() => Reference)
  @Expose({ name: 'comment_recipients' })
  commentRecipients?: Reference<UserModel | TeamModel | UserListModel>[];
}
