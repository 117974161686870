import { DescriptionSource } from '@api/description/description.types';
import { DescriptionObj } from '@components/DescriptionSelector/DescriptionList/DescriptionList';
import convertStringToSlate from '@components/RichTextEditor/helpers/convertStringToSlate';
import { serializeSlateToPlainText } from '@components/RichTextEditor/helpers/serializationHelpers';
import { useFetchedMentions } from '@context/FetchedMentions';

const useDescriptionOptions = ({
  aiDescription,
  ingestedDescription,
  suggestedDescription,
  suggestedDescriptionSource,
  userDescription,
}: {
  aiDescription?: string;
  ingestedDescription?: string;
  suggestedDescription?: string;
  suggestedDescriptionSource?: string;
  userDescription?: string;
}) => {
  const { getMentionFromCacheById } = useFetchedMentions();

  const descriptions: DescriptionObj[] = [];

  const richToPlainText = (richText: string) => {
    const slateState = convertStringToSlate(richText);
    return serializeSlateToPlainText({ getMentionFromCacheById, nodes: slateState });
  };

  if (userDescription) {
    descriptions.push({
      plainValue: richToPlainText(userDescription),
      source: 'user',
      value: userDescription,
    });
  }

  if (ingestedDescription) {
    descriptions.push({
      plainValue: richToPlainText(ingestedDescription),
      source: 'ingestion',
      value: ingestedDescription,
    });
  }

  if (suggestedDescription) {
    descriptions.push({
      plainValue: richToPlainText(suggestedDescription),
      source: suggestedDescriptionSource as DescriptionSource,
      value: suggestedDescription,
    });
  }

  if (aiDescription) {
    descriptions.push({
      plainValue: aiDescription,
      source: 'ai',
      value: aiDescription,
    });
  }

  return descriptions;
};

export default useDescriptionOptions;
