import useFetch, { UseFetchOptions } from '@hooks/useFetch';
import type { UseMutationOptions } from '@hooks/useMutation';
import useMutation from '@hooks/useMutation';
import { PaginatedResponse } from '@models/PaginatedResponse';

import paginatedTransform from '../paginatedTransform';
import rawTransform from '../rawTransform';

import { CommentModel } from './CommentModel';

export const commentCacheKeys = {
  commentTarget: (id: string) => ['comments', id],
  comments: ['comments'],
};

export const useDeleteComment = (id: string, options?: UseMutationOptions) => {
  return useMutation({
    ...options,
    method: 'DELETE',
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
    url: `/comments/${id}/`,
  });
};

export const useFetchComments = (options?: UseFetchOptions<PaginatedResponse<CommentModel>>) => {
  return useFetch<PaginatedResponse<CommentModel>>({
    ...options,
    queryKey: [commentCacheKeys.comments, options?.params],
    select: paginatedTransform(CommentModel),
    url: `/comments/`,
  });
};

export const usePostComments = (options?: UseMutationOptions<CommentModel>) => {
  return useMutation<CommentModel>({
    ...options,
    method: 'POST',
    onSuccess: (data, variables, context) => {
      const transformedData = rawTransform(CommentModel)(data);
      options?.onSuccess?.(transformedData, variables, context);
    },
    url: '/comments/',
  });
};

export const usePatchComment = (id: string, options?: UseMutationOptions<CommentModel>) => {
  return useMutation<CommentModel>({
    ...options,
    method: 'PATCH',
    onSuccess: (data, variables, context) => {
      const transformedData = rawTransform(CommentModel)(data);
      options?.onSuccess?.(transformedData, variables, context);
    },
    url: `/comments/${id}/`,
  });
};
